import {useSelector} from 'react-redux';
import {createElement, useMemo} from 'react';
import {isEmpty as rrfIsEmpty, isLoaded as rrfIsLoaded} from 'react-redux-firebase';
import {Spin as AntSpin} from 'antd';
import styled from 'styled-components';
import {Redirect} from 'react-router';

const Spin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function WithFirebaseUser(WrappedComponent) {
  return function EnhanceComponent(props) {
    const auth = useSelector(s => s.firebase.auth);
    const isLoaded = useMemo(() => rrfIsLoaded(auth), [auth]);
    const isEmpty = useMemo(() => rrfIsEmpty(auth), [auth]);
    const redirectToAuthProps = {to: '/auth'};

    return isLoaded
      ? isEmpty
        ? createElement(Redirect, redirectToAuthProps)
        : createElement(WrappedComponent, {...props})
      : createElement(Spin, {}, createElement(AntSpin));
  };
}
