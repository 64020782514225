import {Typography} from 'antd';
import styled from 'styled-components';
import * as React from 'react';
import {media} from 'app/styles/media-query';

function TextComponent({children, level = 6, color = 'default', className, ...restProps}) {
  const size = Math.max(1, Math.min(level, 6));
  const textColor = ['light', 'lighter', 'lightest', 'gray', 'dark', 'darker'].includes(color)
    ? color
    : 'default';
  return (
    <Typography.Text
      className={`display-${size} text-${textColor} ${className}`} {...restProps}>{children}</Typography.Text>
  );
}

const Text = styled(TextComponent)`
  &.display-1{
    font-size: ${props => props.theme['$font-size-h1']};
  }
  &.display-2{
    font-size: ${props => props.theme['$font-size-h2']};
  }
  &.display-3{
    font-size: ${props => props.theme['$font-size-h3']};
  }
  &.display-4{
    font-size: ${props => props.theme['$font-size-h4']};
  }
  &.display-5{
    font-size: ${props => props.theme['$font-size-h5']};
  }
  &.display-6{
    font-size: ${props => props.theme['$font-size-h6']};
  }
  
  ${media.down('md')} {
    &.display-1{
      font-size: ${props => props.theme['$font-size-h1-md']};
    }
    &.display-2{
      font-size: ${props => props.theme['$font-size-h2-md']};
    }
    &.display-3{
      font-size: ${props => props.theme['$font-size-h3-md']};
    }
  }
  
  ${media.down('xs')} {
    &.display-1{
      font-size: ${props => props.theme['$font-size-h1-xs']};
    }
    &.display-2{
      font-size: ${props => props.theme['$font-size-h2-xs']};
    }
    &.display-3{
      font-size: ${props => props.theme['$font-size-h3-xs']};
    }
  }
  
  &.text-light{
    color: ${props => props.theme['$gray-light']};
  }
  &.text-lighter{
    color: ${props => props.theme['$gray-lighter']};
  }
  &.text-lightest{
    color: ${props => props.theme['$gray-lightest']};
  }
  &.text-gray{
    color: ${props => props.theme['$gray']};
  }
  &.text-dark{
    color: ${props => props.theme['$gray-dark']};
  }
  &.text-darker{
    color: ${props => props.theme['$gray-darker']};
  }
  &.text-default{
    color: inherit;
  }
`;

export default Text;
