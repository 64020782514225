import React from 'react';
// import {Col, Layout, Menu as AntMenu, Row} from 'antd';
import { Layout, Row, Col, Divider } from 'antd';
import { FacebookFilled, YoutubeFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media } from 'app/styles/media-query';
import Container from 'app/components/container';

import logo from './assets/images/LogoTATAC.svg';

const Header = styled(Layout.Header)`
  & .ant-col {
    & .ant-row-flex {
      max-width: 1200px;
    }
  }
  ${media.down('md')} {
    &.ant-layout-header {
      height: 7rem;
      & .ant-row-flex {
        justify-content: center;
      }
    }
  }
`;

const HiddenCol = styled(Col)`
  ${media.down('xs')} {
    display: none;
  }
`;

const SubMenu = styled(Container)`
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #003259;
  a,
  span {
    margin: 0 0.5rem;
    font-size: 11px;
    font-weight: 300;
    color: #fff;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Logo = styled.img`
  width: auto;
  height: 3.5rem;
  margin: 1rem 0;
  float: left;

  ${media.down('md')} {
    height: 3rem;
  }
`;

// const Menu = styled(AntMenu)`
//   &.ant-menu {
//     background: ${(props) => props.theme['$white']};
//     text-align: right;
//     border-bottom: none;
//     font-size: 1rem;
//     line-height: 1rem;

//     ${media.down('md')} {
//       display: none;
//     }
//   }
// `;

// const MenuItem = styled(AntMenu.Item)`
//   &.ant-menu-item {
//     padding: 0.25rem 0.5rem;
//   }
// `;

// const TobMenu = styled(AntMenu)`
//   &.ant-menu {
//     padding-top: 1rem;
//     text-align: right;
//     background: ${(props) => props.theme['$white']};
//     line-height: 1rem;
//     font-size: 0.625rem;
//     border-bottom: none;

//     ${media.down('sm')} {
//       display: none;
//     }
//   }
// `;

// const MenuCol = styled(Col)`
//   &.ant-col {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//   }

//   > .flex {
//     display: flex;
//     flex: 1;
//   }
// `;

export function TATHeaderComponent() {
  const { t } = useTranslation(['common']);
  console.log(media);
  return (
    <Header>
      <SubMenu>
        <Row type='flex' justify='end'>
          <a href='http://tatacademy.com/th/about-us'>{t('common:about us')}</a>
          <a href='http://tatacademy.com/th/contact-us'>
            {t('common:contact us')}
          </a>
          <a href='http://tatacademy.com/th/subscription'>
            {t('common:subscribe')}
          </a>
          <HiddenCol>
            <Divider
              type='vertical'
              style={{ height: '1.5em', opacity: '0.5' }}
            />
            <span style={{ color: 'white' }}>{t('common:follow us')}</span>
            <a href='https://www.facebook.com/TATAcademy' target='__blank'>
              <FacebookFilled
                style={{ fontSize: '0.875rem', marginTop: '0.05rem' }}
              />
            </a>
            <a
              href='https://www.youtube.com/channel/UC-XxyhVVK5Wqm6WZ0ru48cA'
              target='__blank'
            >
              <YoutubeFilled
                style={{ fontSize: '0.875rem', marginTop: '0.05rem' }}
              />
            </a>
          </HiddenCol>
        </Row>
      </SubMenu>
      <Container>
        <Row type='flex'>
          <Col>
            <a href='https://tatacademy.com/th'>
              <Logo className='logo' src={logo} />
            </a>
          </Col>
        </Row>
      </Container>
    </Header>
  );
}
