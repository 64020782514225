import { Input } from 'antd';
import styled from 'styled-components';

const InputComponent = styled(Input)`
  &.ant-input{
    :focus {
      color: ${props => props.theme['$input-color-focus']} !important;
      background-color: ${props => props.theme['$input-bg-focus']} !important;
      border-color: ${props => props.theme['$blue']};
    }
    
    &.d-none{
      display: none;
    }
  }
`;


export default InputComponent;
