const theme = {
  // Basic colours
  $white: '#fff',
  $black: '#000',
  $red: '#ff1133',
  $orange: '#f0ad4e',
  $yellow: '#ffd500',
  $green: '#5cb85c',
  $blue: '#0066B3',
  $cyan: '#5bc0de',
  $pink: '#ff5b77',
  $purple: '#613d7c',

  // Create grayscale
  '$gray-darker': '#333333',
  '$gray-dark': '#666666',
  '$gray': '#999999',
  '$gray-light': '#cccccc',
  '$gray-lighter': '#efefef',
  '$gray-lightest': '#f9f9f9',
  // components
  // breadcrumb
  '$breadcrumb-padding-x': '0px',
  '$breadcrumb-divider': '">"',
  '$breadcrumb-bg': 'transparent',

  // typology
  '$font-family-sans-serif': `Kanit, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`,
  '$font-size-base': '.75rem',
  '$font-size-lg': '1rem',
  '$font-size-sm': '.625rem',
  '$font-size-xs': '.5rem',

  '$font-size-h1': '2rem',
  '$font-size-h2': '1.75rem',
  '$font-size-h3': '1.5rem',
  '$font-size-h4': '1.25rem',
  '$font-size-h5': '1rem',
  '$font-size-h6': '.75rem',

  '$font-size-h1-md': '1.875rem',
  '$font-size-h2-md': '1.625rem',
  '$font-size-h3-md': '1.325rem',

  '$font-size-h1-xs': '1.75rem',
  '$font-size-h2-xs': '1.5rem',
  '$font-size-h3-xs': '1.3rem',

  '$lead-font-size': '1.625rem',
  '$lead-md-font-size': '1.25rem',
  '$lead-xs-font-size': '1rem',

  // form
  '$input-bg': '#eeeeee',
  '$input-bg-focus': '#ffffff',
  '$input-color-focus': '#000',

  // jumbotron
  '$jumbotron-padding': '0 2rem',
  '$jumbotron-bg': '#0066B3',
};

export default theme;
