import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Col, Layout, Row} from 'antd';

import Providers from './providers';
import EmailSignUp from './email-sign-up';
import TATHero from '../../components/tat-hero';

export function AuthContainer() {

  return (
    <Layout.Content>
      <Row>
        <Col span={24}><TATHero/></Col>
      </Row>
      <Switch>
        <Route exact path='/auth' render={() => <Redirect to='/auth/providers'/>}/>
        <Route exact path='/auth/providers' component={Providers}/>
        <Route exact path='/auth/sign-up/email' component={EmailSignUp}/>
      </Switch>
    </Layout.Content>

  );
}


