import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Breadcrumb as AntdBreadcrumb, Col, Row } from "antd";
import moment from "moment";
import { useFirestore } from "react-redux-firebase";

import Container from "app/components/container";
import { media } from "app/styles/media-query";

const Jumbotron = styled.div`
  color: ${(props) => props.theme["$white"]};
  background-color: ${(props) => props.theme["$blue"]};
  padding: 1rem 0;
`;

const Breadcrumb = styled(AntdBreadcrumb)`
  &.ant-breadcrumb {
    margin-bottom: 1rem;
    font-family: "TATSana Suksa";
    .ant-breadcrumb-link,
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme["$white"]};
      opacity: 0.5;
    }
    a.ant-breadcrumb-link {
      opacity: 0.75;
    }
  }
`;

const LeadParagraph = styled.p`
  padding: 1rem 0;
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme["$lead-font-size"]};
  ${media.down("md")} {
    font-size: ${(props) => props.theme["$lead-md-font-size"]};
  }
  ${media.down("xs")} {
    font-size: ${(props) => props.theme["$lead-xs-font-size"]};
  }
`;

const DateParagraph = styled.p`
  opacity: 0.5;
  color: ${(props) => props.theme["$white"]};
  font-family: "TATSana Suksa";
  font-size: ${(props) => props.theme["$font-size-base"]};
  ${media.down("md")} {
    font-size: ${(props) => props.theme["$font-size-sm"]};
  }
  ${media.down("xs")} {
    font-size: ${(props) => props.theme["$font-size-xs"]};
  }
`;

export function TATHeroComponent() {
  const firestore = useFirestore();
  const [textTmeForm, setTextTmeForm] = useState(null);

  useEffect(() => {
    const onGetFormText = async () => {
      try {
        const jsondata = await firestore
          .collection("tatacademy-content")
          .doc("regisTME")
          .get();
        const { formNameTME } = jsondata.data();

        if (formNameTME) {
          setTextTmeForm(formNameTME);
        }

        return "success";
      } catch (error) {
        console.log(error);
      }
    };

    onGetFormText();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation(["common", "register"]);
  return (
    <Jumbotron fluid>
      <Container>
        <Row>
          <Col xs={24}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item href="https://tatacademy.com/th">
                {t("common:home")}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t("common:tme registration")}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <LeadParagraph>
              {textTmeForm}
              {/* {t('register:hero title')} */}
            </LeadParagraph>
          </Col>
        </Row>

        <Row>
          <Col>
            <DateParagraph>
              {moment().format(
                `วันที่ DD MMMM ${moment().year() + 543} เวลา HH:mm น.`
              )}
            </DateParagraph>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}
