import {createGlobalStyle} from 'styled-components';
import { normalize } from 'styled-normalize'
import './css/index.css';

import KanitExtraLight from './fonts/kanit/Kanit-ExtraLight.ttf';
import KanitExtraLightItalic from './fonts/kanit/Kanit-ExtraLightItalic.ttf';
import KanitLight from './fonts/kanit/Kanit-Light.ttf';
import KanitLightItalic from './fonts/kanit/Kanit-LightItalic.ttf';
import KanitRegular from './fonts/kanit/Kanit-Regular.ttf';
import KanitItalic from './fonts/kanit/Kanit-Italic.ttf';
import KanitMedium from './fonts/kanit/Kanit-Medium.ttf';
import KanitMediumItalic from './fonts/kanit/Kanit-MediumItalic.ttf';
import KanitSemiBold from './fonts/kanit/Kanit-SemiBold.ttf';
import KanitSemiBoldItalic from './fonts/kanit/Kanit-SemiBoldItalic.ttf';
import KanitBold from './fonts/kanit/Kanit-Bold.ttf';
import KanitBoldItalic from './fonts/kanit/Kanit-BoldItalic.ttf';
import KanitExtraBold from './fonts/kanit/Kanit-ExtraBold.ttf';
import KanitExtraBoldItalic from './fonts/kanit/Kanit-ExtraBoldItalic.ttf';

const GlobalStyle = createGlobalStyle`  
${normalize}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitExtraLight}) format('truetype');
  font-weight : 200;
  font-style  : normal;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitExtraLightItalic}) format('truetype');
  font-weight : 200;
  font-style  : italic;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitLight}) format('truetype');
  font-weight : 300;
  font-style  : normal;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitLightItalic}) format('truetype');
  font-weight : 300;
  font-style  : italic;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitRegular}) format('truetype');
  font-weight : 400;
  font-style  : normal;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitItalic}) format('truetype');
  font-weight : 400;
  font-style  : italic;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitMedium}) format('truetype');
  font-weight : 500;
  font-style  : normal;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitMediumItalic}) format('truetype');
  font-weight : 500;
  font-style  : italic;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitSemiBold}) format('truetype');
  font-weight : 600;
  font-style  : normal;
}


@font-face {
  font-family : 'Kanit';
  src         : url(${KanitSemiBoldItalic}) format('truetype');
  font-weight : 600;
  font-style  : italic;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitBold}) format('truetype');
  font-weight : 700;
  font-style  : normal;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitBoldItalic}) format('truetype');
  font-weight : 700;
  font-style  : italic;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitExtraBold}) format('truetype');
  font-weight : 800;
  font-style  : normal;
}

@font-face {
  font-family : 'Kanit';
  src         : url(${KanitExtraBoldItalic}) format('truetype');
  font-weight : 800;
  font-style  : italic;
}

html, body, #root{
  margin: 0px;
  width: 100vw;
  height: auto;
}
`;

export default GlobalStyle;
