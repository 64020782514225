export const location = [
  {
    tambon: "คลองท่อมเหนือ",
    amphoe: "คลองท่อม",
    province: "กระบี่",
    zipcode: 81120,
    district_code: 810402,
    amphoe_code: 8104,
    province_code: 81,
  },
  {
    tambon: "คลองท่อมใต้",
    amphoe: "คลองท่อม",
    province: "กระบี่",
    zipcode: 81120,
    district_code: 810401,
    amphoe_code: 8104,
    province_code: 81,
  },
  {
    tambon: "คลองพน",
    amphoe: "คลองท่อม",
    province: "กระบี่",
    zipcode: 81170,
    district_code: 810403,
    amphoe_code: 8104,
    province_code: 81,
  },
  {
    tambon: "ทรายขาว",
    amphoe: "คลองท่อม",
    province: "กระบี่",
    zipcode: 81170,
    district_code: 810404,
    amphoe_code: 8104,
    province_code: 81,
  },
  {
    tambon: "พรุดินนา",
    amphoe: "คลองท่อม",
    province: "กระบี่",
    zipcode: 81120,
    district_code: 810406,
    amphoe_code: 8104,
    province_code: 81,
  },
  {
    tambon: "ห้วยน้ำขาว",
    amphoe: "คลองท่อม",
    province: "กระบี่",
    zipcode: 81120,
    district_code: 810405,
    amphoe_code: 8104,
    province_code: 81,
  },
  {
    tambon: "เพหลา",
    amphoe: "คลองท่อม",
    province: "กระบี่",
    zipcode: 81120,
    district_code: 810407,
    amphoe_code: 8104,
    province_code: 81,
  },
  {
    tambon: "คีรีวง",
    amphoe: "ปลายพระยา",
    province: "กระบี่",
    zipcode: 81160,
    district_code: 810604,
    amphoe_code: 8106,
    province_code: 81,
  },
  {
    tambon: "ปลายพระยา",
    amphoe: "ปลายพระยา",
    province: "กระบี่",
    zipcode: 81160,
    district_code: 810599,
    amphoe_code: 8106,
    province_code: 81,
  },
  {
    tambon: "เขาต่อ",
    amphoe: "ปลายพระยา",
    province: "กระบี่",
    zipcode: 81160,
    district_code: 810598,
    amphoe_code: 8106,
    province_code: 81,
  },
  {
    tambon: "เขาเขน",
    amphoe: "ปลายพระยา",
    province: "กระบี่",
    zipcode: 81160,
    district_code: 810597,
    amphoe_code: 8106,
    province_code: 81,
  },
  {
    tambon: "ดินอุดม",
    amphoe: "ลำทับ",
    province: "กระบี่",
    zipcode: 81190,
    district_code: 810702,
    amphoe_code: 8107,
    province_code: 81,
  },
  {
    tambon: "ดินแดง",
    amphoe: "ลำทับ",
    province: "กระบี่",
    zipcode: 81190,
    district_code: 810704,
    amphoe_code: 8107,
    province_code: 81,
  },
  {
    tambon: "ทุ่งไทรทอง",
    amphoe: "ลำทับ",
    province: "กระบี่",
    zipcode: 81190,
    district_code: 810703,
    amphoe_code: 8107,
    province_code: 81,
  },
  {
    tambon: "ลำทับ",
    amphoe: "ลำทับ",
    province: "กระบี่",
    zipcode: 81190,
    district_code: 810499,
    amphoe_code: 8107,
    province_code: 81,
  },
  {
    tambon: "คลองยา",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810508,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "คลองหิน",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810504,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "นาเหนือ",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810503,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810509,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "อ่าวลึกน้อย",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810505,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "อ่าวลึกเหนือ",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810506,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "อ่าวลึกใต้",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810501,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "เขาใหญ่",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810507,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "แหลมสัก",
    amphoe: "อ่าวลึก",
    province: "กระบี่",
    zipcode: 81110,
    district_code: 810502,
    amphoe_code: 8105,
    province_code: 81,
  },
  {
    tambon: "คลองยาง",
    amphoe: "เกาะลันตา",
    province: "กระบี่",
    zipcode: 81120,
    district_code: 810304,
    amphoe_code: 8103,
    province_code: 81,
  },
  {
    tambon: "ศาลาด่าน",
    amphoe: "เกาะลันตา",
    province: "กระบี่",
    zipcode: 81150,
    district_code: 810305,
    amphoe_code: 8103,
    province_code: 81,
  },
  {
    tambon: "เกาะกลาง",
    amphoe: "เกาะลันตา",
    province: "กระบี่",
    zipcode: 81120,
    district_code: 810303,
    amphoe_code: 8103,
    province_code: 81,
  },
  {
    tambon: "เกาะลันตาน้อย",
    amphoe: "เกาะลันตา",
    province: "กระบี่",
    zipcode: 81150,
    district_code: 810302,
    amphoe_code: 8103,
    province_code: 81,
  },
  {
    tambon: "เกาะลันตาใหญ่",
    amphoe: "เกาะลันตา",
    province: "กระบี่",
    zipcode: 81150,
    district_code: 810301,
    amphoe_code: 8103,
    province_code: 81,
  },
  {
    tambon: "พรุเตียว",
    amphoe: "เขาพนม",
    province: "กระบี่",
    zipcode: 81140,
    district_code: 810204,
    amphoe_code: 8102,
    province_code: 81,
  },
  {
    tambon: "สินปุน",
    amphoe: "เขาพนม",
    province: "กระบี่",
    zipcode: 80240,
    district_code: 810203,
    amphoe_code: 8102,
    province_code: 81,
  },
  {
    tambon: "หน้าเขา",
    amphoe: "เขาพนม",
    province: "กระบี่",
    zipcode: 81140,
    district_code: 810205,
    amphoe_code: 8102,
    province_code: 81,
  },
  {
    tambon: "เขาดิน",
    amphoe: "เขาพนม",
    province: "กระบี่",
    zipcode: 81140,
    district_code: 810202,
    amphoe_code: 8102,
    province_code: 81,
  },
  {
    tambon: "เขาพนม",
    amphoe: "เขาพนม",
    province: "กระบี่",
    zipcode: 81140,
    district_code: 810201,
    amphoe_code: 8102,
    province_code: 81,
  },
  {
    tambon: "โคกหาร",
    amphoe: "เขาพนม",
    province: "กระบี่",
    zipcode: 80240,
    district_code: 810206,
    amphoe_code: 8102,
    province_code: 81,
  },
  {
    tambon: "กระบี่น้อย",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810103,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "กระบี่ใหญ่",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810102,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "คลองประสงค์",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810118,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "ทับปริก",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810111,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810101,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "หนองทะเล",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81180,
    district_code: 810117,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "อ่าวนาง",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810116,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "เขาคราม",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810105,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "เขาทอง",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810106,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "ไสไทย",
    amphoe: "เมืองกระบี่",
    province: "กระบี่",
    zipcode: 81000,
    district_code: 810115,
    amphoe_code: 8101,
    province_code: 81,
  },
  {
    tambon: "คลองขนาน",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810107,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "คลองเขม้า",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810108,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810110,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "ปกาสัย",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810112,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "ห้วยยูง",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810113,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "เกาะศรีบอยา",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810104,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "เหนือคลอง",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810114,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "โคกยาง",
    amphoe: "เหนือคลอง",
    province: "กระบี่",
    zipcode: 81130,
    district_code: 810109,
    amphoe_code: 8108,
    province_code: 81,
  },
  {
    tambon: "คลองต้นไทร",
    amphoe: "คลองสาน",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101804,
    amphoe_code: 1018,
    province_code: 10,
  },
  {
    tambon: "คลองสาน",
    amphoe: "คลองสาน",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101599,
    amphoe_code: 1018,
    province_code: 10,
  },
  {
    tambon: "บางลำภูล่าง",
    amphoe: "คลองสาน",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101803,
    amphoe_code: 1018,
    province_code: 10,
  },
  {
    tambon: "สมเด็จเจ้าพระยา",
    amphoe: "คลองสาน",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101801,
    amphoe_code: 1018,
    province_code: 10,
  },
  {
    tambon: "ทรายกองดิน",
    amphoe: "คลองสามวา",
    province: "กรุงเทพมหานคร",
    zipcode: 10510,
    district_code: 101004,
    amphoe_code: 1046,
    province_code: 10,
  },
  {
    tambon: "ทรายกองดินใต้",
    amphoe: "คลองสามวา",
    province: "กรุงเทพมหานคร",
    zipcode: 10510,
    district_code: 101005,
    amphoe_code: 1046,
    province_code: 10,
  },
  {
    tambon: "บางชัน",
    amphoe: "คลองสามวา",
    province: "กรุงเทพมหานคร",
    zipcode: 10510,
    district_code: 101003,
    amphoe_code: 1046,
    province_code: 10,
  },
  {
    tambon: "สามวาตะวันตก",
    amphoe: "คลองสามวา",
    province: "กรุงเทพมหานคร",
    zipcode: 10510,
    district_code: 101007,
    amphoe_code: 1046,
    province_code: 10,
  },
  {
    tambon: "สามวาตะวันออก",
    amphoe: "คลองสามวา",
    province: "กรุงเทพมหานคร",
    zipcode: 10510,
    district_code: 101006,
    amphoe_code: 1046,
    province_code: 10,
  },
  {
    tambon: "คลองตัน",
    amphoe: "คลองเตย",
    province: "กรุงเทพมหานคร",
    zipcode: 10110,
    district_code: 100902,
    amphoe_code: 1033,
    province_code: 10,
  },
  {
    tambon: "คลองเตย",
    amphoe: "คลองเตย",
    province: "กรุงเทพมหานคร",
    zipcode: 10110,
    district_code: 100901,
    amphoe_code: 1033,
    province_code: 10,
  },
  {
    tambon: "พระโขนง",
    amphoe: "คลองเตย",
    province: "กรุงเทพมหานคร",
    zipcode: 10110,
    district_code: 100903,
    amphoe_code: 1033,
    province_code: 10,
  },
  {
    tambon: "พระโขนง",
    amphoe: "คลองเตย",
    province: "กรุงเทพมหานคร",
    zipcode: 10260,
    district_code: 100903,
    amphoe_code: 1033,
    province_code: 10,
  },
  {
    tambon: "คันนายาว",
    amphoe: "คันนายาว",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: 100606,
    amphoe_code: 1043,
    province_code: 10,
  },
  {
    tambon: "รามอินทรา",
    amphoe: "คันนายาว",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: false,
    amphoe_code: 1043,
    province_code: 10,
  },
  {
    tambon: "จตุจักร",
    amphoe: "จตุจักร",
    province: "กรุงเทพมหานคร",
    zipcode: 10900,
    district_code: 103005,
    amphoe_code: 1030,
    province_code: 10,
  },
  {
    tambon: "จอมพล",
    amphoe: "จตุจักร",
    province: "กรุงเทพมหานคร",
    zipcode: 10900,
    district_code: 103004,
    amphoe_code: 1030,
    province_code: 10,
  },
  {
    tambon: "จันทรเกษม",
    amphoe: "จตุจักร",
    province: "กรุงเทพมหานคร",
    zipcode: 10900,
    district_code: 103003,
    amphoe_code: 1030,
    province_code: 10,
  },
  {
    tambon: "ลาดยาว",
    amphoe: "จตุจักร",
    province: "กรุงเทพมหานคร",
    zipcode: 10900,
    district_code: 100501,
    amphoe_code: 1030,
    province_code: 10,
  },
  {
    tambon: "เสนานิคม",
    amphoe: "จตุจักร",
    province: "กรุงเทพมหานคร",
    zipcode: 10900,
    district_code: 103002,
    amphoe_code: 1030,
    province_code: 10,
  },
  {
    tambon: "จอมทอง",
    amphoe: "จอมทอง",
    province: "กรุงเทพมหานคร",
    zipcode: 10150,
    district_code: 102102,
    amphoe_code: 1035,
    province_code: 10,
  },
  {
    tambon: "บางขุนเทียน",
    amphoe: "จอมทอง",
    province: "กรุงเทพมหานคร",
    zipcode: 10150,
    district_code: 102103,
    amphoe_code: 1035,
    province_code: 10,
  },
  {
    tambon: "บางค้อ",
    amphoe: "จอมทอง",
    province: "กรุงเทพมหานคร",
    zipcode: 10150,
    district_code: 102101,
    amphoe_code: 1035,
    province_code: 10,
  },
  {
    tambon: "บางมด",
    amphoe: "จอมทอง",
    province: "กรุงเทพมหานคร",
    zipcode: 10150,
    district_code: 102106,
    amphoe_code: 1035,
    province_code: 10,
  },
  {
    tambon: "ดอนเมือง",
    amphoe: "ดอนเมือง",
    province: "กรุงเทพมหานคร",
    zipcode: 10210,
    district_code: false,
    amphoe_code: 1036,
    province_code: 10,
  },
  {
    tambon: "สนามบิน",
    amphoe: "ดอนเมือง",
    province: "กรุงเทพมหานคร",
    zipcode: 10210,
    district_code: false,
    amphoe_code: 1036,
    province_code: 10,
  },
  {
    tambon: "สีกัน",
    amphoe: "ดอนเมือง",
    province: "กรุงเทพมหานคร",
    zipcode: 10210,
    district_code: 100505,
    amphoe_code: 1036,
    province_code: 10,
  },
  {
    tambon: "ดินแดง",
    amphoe: "ดินแดง",
    province: "กรุงเทพมหานคร",
    zipcode: 10400,
    district_code: 101703,
    amphoe_code: 1026,
    province_code: 10,
  },
  {
    tambon: "ดุสิต",
    amphoe: "ดุสิต",
    province: "กรุงเทพมหานคร",
    zipcode: 10300,
    district_code: 100201,
    amphoe_code: 1002,
    province_code: 10,
  },
  {
    tambon: "ถนนนครไชยศรี",
    amphoe: "ดุสิต",
    province: "กรุงเทพมหานคร",
    zipcode: 10300,
    district_code: 100206,
    amphoe_code: 1002,
    province_code: 10,
  },
  {
    tambon: "วชิรพยาบาล",
    amphoe: "ดุสิต",
    province: "กรุงเทพมหานคร",
    zipcode: 10300,
    district_code: 100202,
    amphoe_code: 1002,
    province_code: 10,
  },
  {
    tambon: "สวนจิตรลดา",
    amphoe: "ดุสิต",
    province: "กรุงเทพมหานคร",
    zipcode: 10300,
    district_code: 100203,
    amphoe_code: 1002,
    province_code: 10,
  },
  {
    tambon: "สวนจิตรลดา",
    amphoe: "ดุสิต",
    province: "กรุงเทพมหานคร",
    zipcode: 10303,
    district_code: 100203,
    amphoe_code: 1002,
    province_code: 10,
  },
  {
    tambon: "สี่แยกมหานาค",
    amphoe: "ดุสิต",
    province: "กรุงเทพมหานคร",
    zipcode: 10300,
    district_code: 100204,
    amphoe_code: 1002,
    province_code: 10,
  },
  {
    tambon: "คลองชักพระ",
    amphoe: "ตลิ่งชัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101901,
    amphoe_code: 1019,
    province_code: 10,
  },
  {
    tambon: "ฉิมพลี",
    amphoe: "ตลิ่งชัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101903,
    amphoe_code: 1019,
    province_code: 10,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "ตลิ่งชัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101902,
    amphoe_code: 1019,
    province_code: 10,
  },
  {
    tambon: "บางพรม",
    amphoe: "ตลิ่งชัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101904,
    amphoe_code: 1019,
    province_code: 10,
  },
  {
    tambon: "บางระมาด",
    amphoe: "ตลิ่งชัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101905,
    amphoe_code: 1019,
    province_code: 10,
  },
  {
    tambon: "บางเชือกหนัง",
    amphoe: "ตลิ่งชัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101907,
    amphoe_code: 1019,
    province_code: 10,
  },
  {
    tambon: "ทวีวัฒนา",
    amphoe: "ทวีวัฒนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101906,
    amphoe_code: 1048,
    province_code: 10,
  },
  {
    tambon: "ศาลาธรรมสพน์",
    amphoe: "ทวีวัฒนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10170,
    district_code: 101908,
    amphoe_code: 1048,
    province_code: 10,
  },
  {
    tambon: "ทุ่งครุ",
    amphoe: "ทุ่งครุ",
    province: "กรุงเทพมหานคร",
    zipcode: 10140,
    district_code: 102404,
    amphoe_code: 1049,
    province_code: 10,
  },
  {
    tambon: "บางมด",
    amphoe: "ทุ่งครุ",
    province: "กรุงเทพมหานคร",
    zipcode: 10140,
    district_code: 102106,
    amphoe_code: 1049,
    province_code: 10,
  },
  {
    tambon: "ดาวคะนอง",
    amphoe: "ธนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101506,
    amphoe_code: 1015,
    province_code: 10,
  },
  {
    tambon: "ตลาดพลู",
    amphoe: "ธนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101505,
    amphoe_code: 1015,
    province_code: 10,
  },
  {
    tambon: "บางยี่เรือ",
    amphoe: "ธนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101503,
    amphoe_code: 1015,
    province_code: 10,
  },
  {
    tambon: "บุคคโล",
    amphoe: "ธนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101504,
    amphoe_code: 1015,
    province_code: 10,
  },
  {
    tambon: "วัดกัลยาณ์",
    amphoe: "ธนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101501,
    amphoe_code: 1015,
    province_code: 10,
  },
  {
    tambon: "สำเหร่",
    amphoe: "ธนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101507,
    amphoe_code: 1015,
    province_code: 10,
  },
  {
    tambon: "หิรัญรูจี",
    amphoe: "ธนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101502,
    amphoe_code: 1015,
    province_code: 10,
  },
  {
    tambon: "บางขุนนนท์",
    amphoe: "บางกอกน้อย",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102006,
    amphoe_code: 1020,
    province_code: 10,
  },
  {
    tambon: "บางขุนศรี",
    amphoe: "บางกอกน้อย",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102007,
    amphoe_code: 1020,
    province_code: 10,
  },
  {
    tambon: "บ้านช่างหล่อ",
    amphoe: "บางกอกน้อย",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102005,
    amphoe_code: 1020,
    province_code: 10,
  },
  {
    tambon: "ศิริราช",
    amphoe: "บางกอกน้อย",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102004,
    amphoe_code: 1020,
    province_code: 10,
  },
  {
    tambon: "อรุณอัมรินทร์",
    amphoe: "บางกอกน้อย",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: false,
    amphoe_code: 1020,
    province_code: 10,
  },
  {
    tambon: "วัดท่าพระ",
    amphoe: "บางกอกใหญ่",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101602,
    amphoe_code: 1016,
    province_code: 10,
  },
  {
    tambon: "วัดอรุณ",
    amphoe: "บางกอกใหญ่",
    province: "กรุงเทพมหานคร",
    zipcode: 10600,
    district_code: 101601,
    amphoe_code: 1016,
    province_code: 10,
  },
  {
    tambon: "คลองจั่น",
    amphoe: "บางกะปิ",
    province: "กรุงเทพมหานคร",
    zipcode: 10240,
    district_code: 100601,
    amphoe_code: 1006,
    province_code: 10,
  },
  {
    tambon: "หัวหมาก",
    amphoe: "บางกะปิ",
    province: "กรุงเทพมหานคร",
    zipcode: 10240,
    district_code: 100608,
    amphoe_code: 1006,
    province_code: 10,
  },
  {
    tambon: "หัวหมาก",
    amphoe: "บางกะปิ",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 100608,
    amphoe_code: 1006,
    province_code: 10,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "บางขุนเทียน",
    province: "กรุงเทพมหานคร",
    zipcode: 10150,
    district_code: 102105,
    amphoe_code: 1021,
    province_code: 10,
  },
  {
    tambon: "แสมดำ",
    amphoe: "บางขุนเทียน",
    province: "กรุงเทพมหานคร",
    zipcode: 10150,
    district_code: 102107,
    amphoe_code: 1021,
    province_code: 10,
  },
  {
    tambon: "บางคอแหลม",
    amphoe: "บางคอแหลม",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101207,
    amphoe_code: 1031,
    province_code: 10,
  },
  {
    tambon: "บางโคล่",
    amphoe: "บางคอแหลม",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101206,
    amphoe_code: 1031,
    province_code: 10,
  },
  {
    tambon: "วัดพระยาไกร",
    amphoe: "บางคอแหลม",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101205,
    amphoe_code: 1031,
    province_code: 10,
  },
  {
    tambon: "บางซื่อ",
    amphoe: "บางซื่อ",
    province: "กรุงเทพมหานคร",
    zipcode: 10800,
    district_code: 100205,
    amphoe_code: 1029,
    province_code: 10,
  },
  {
    tambon: "วงศ์สว่าง",
    amphoe: "บางซื่อ",
    province: "กรุงเทพมหานคร",
    zipcode: 10800,
    district_code: false,
    amphoe_code: 1029,
    province_code: 10,
  },
  {
    tambon: "บางนาเหนือ",
    amphoe: "บางนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10260,
    district_code: 100904,
    amphoe_code: 1047,
    province_code: 10,
  },
  {
    tambon: "บางนาใต้",
    amphoe: "บางนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10260,
    district_code: 100904,
    amphoe_code: 1047,
    province_code: 10,
  },
  {
    tambon: "บางบอน",
    amphoe: "บางบอน",
    province: "กรุงเทพมหานคร",
    zipcode: 10150,
    district_code: 102104,
    amphoe_code: 1050,
    province_code: 10,
  },
  {
    tambon: "บางบำหรุ",
    amphoe: "บางพลัด",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102002,
    amphoe_code: 1025,
    province_code: 10,
  },
  {
    tambon: "บางพลัด",
    amphoe: "บางพลัด",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102001,
    amphoe_code: 1025,
    province_code: 10,
  },
  {
    tambon: "บางยี่ขัน",
    amphoe: "บางพลัด",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102008,
    amphoe_code: 1025,
    province_code: 10,
  },
  {
    tambon: "บางอ้อ",
    amphoe: "บางพลัด",
    province: "กรุงเทพมหานคร",
    zipcode: 10700,
    district_code: 102003,
    amphoe_code: 1025,
    province_code: 10,
  },
  {
    tambon: "บางรัก",
    amphoe: "บางรัก",
    province: "กรุงเทพมหานคร",
    zipcode: 10500,
    district_code: 100404,
    amphoe_code: 1004,
    province_code: 10,
  },
  {
    tambon: "มหาพฤฒาราม",
    amphoe: "บางรัก",
    province: "กรุงเทพมหานคร",
    zipcode: 10500,
    district_code: 100401,
    amphoe_code: 1004,
    province_code: 10,
  },
  {
    tambon: "สีลม",
    amphoe: "บางรัก",
    province: "กรุงเทพมหานคร",
    zipcode: 10500,
    district_code: 100402,
    amphoe_code: 1004,
    province_code: 10,
  },
  {
    tambon: "สี่พระยา",
    amphoe: "บางรัก",
    province: "กรุงเทพมหานคร",
    zipcode: 10500,
    district_code: 100405,
    amphoe_code: 1004,
    province_code: 10,
  },
  {
    tambon: "สุริยวงศ์",
    amphoe: "บางรัก",
    province: "กรุงเทพมหานคร",
    zipcode: 10500,
    district_code: 100403,
    amphoe_code: 1004,
    province_code: 10,
  },
  {
    tambon: "ท่าแร้ง",
    amphoe: "บางเขน",
    province: "กรุงเทพมหานคร",
    zipcode: 10220,
    district_code: 100508,
    amphoe_code: 1005,
    province_code: 10,
  },
  {
    tambon: "ท่าแร้ง",
    amphoe: "บางเขน",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: 100508,
    amphoe_code: 1005,
    province_code: 10,
  },
  {
    tambon: "อนุสาวรีย์",
    amphoe: "บางเขน",
    province: "กรุงเทพมหานคร",
    zipcode: 10220,
    district_code: 100502,
    amphoe_code: 1005,
    province_code: 10,
  },
  {
    tambon: "บางแค",
    amphoe: "บางแค",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102203,
    amphoe_code: 1040,
    province_code: 10,
  },
  {
    tambon: "บางแคเหนือ",
    amphoe: "บางแค",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102204,
    amphoe_code: 1040,
    province_code: 10,
  },
  {
    tambon: "บางไผ่",
    amphoe: "บางแค",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102205,
    amphoe_code: 1040,
    province_code: 10,
  },
  {
    tambon: "หลักสอง",
    amphoe: "บางแค",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102301,
    amphoe_code: 1040,
    province_code: 10,
  },
  {
    tambon: "คลองกุ่ม",
    amphoe: "บึงกุ่ม",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: 100604,
    amphoe_code: 1027,
    province_code: 10,
  },
  {
    tambon: "คลองกุ่ม",
    amphoe: "บึงกุ่ม",
    province: "กรุงเทพมหานคร",
    zipcode: 10240,
    district_code: 100604,
    amphoe_code: 1027,
    province_code: 10,
  },
  {
    tambon: "นวมินทร์",
    amphoe: "บึงกุ่ม",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: false,
    amphoe_code: 1027,
    province_code: 10,
  },
  {
    tambon: "นวลจันทร์",
    amphoe: "บึงกุ่ม",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: false,
    amphoe_code: 1027,
    province_code: 10,
  },
  {
    tambon: "ปทุมวัน",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10330,
    district_code: 100703,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "รองเมือง",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10330,
    district_code: 100701,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "ลุมพินี",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10330,
    district_code: 100704,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "วังใหม่",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10330,
    district_code: 100702,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "วังใหม่",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10110,
    district_code: 100702,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "วังใหม่",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 100702,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "วังใหม่",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10400,
    district_code: 100702,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "วังใหม่",
    amphoe: "ปทุมวัน",
    province: "กรุงเทพมหานคร",
    zipcode: 10500,
    district_code: 100702,
    amphoe_code: 1007,
    province_code: 10,
  },
  {
    tambon: "ดอกไม้",
    amphoe: "ประเวศ",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 100909,
    amphoe_code: 1032,
    province_code: 10,
  },
  {
    tambon: "ประเวศ",
    amphoe: "ประเวศ",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 100908,
    amphoe_code: 1032,
    province_code: 10,
  },
  {
    tambon: "หนองบอน",
    amphoe: "ประเวศ",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 100907,
    amphoe_code: 1032,
    province_code: 10,
  },
  {
    tambon: "คลองมหานาค",
    amphoe: "ป้อมปราบศัตรูพ่าย",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: 100803,
    amphoe_code: 1008,
    province_code: 10,
  },
  {
    tambon: "บ้านบาตร",
    amphoe: "ป้อมปราบศัตรูพ่าย",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: 100804,
    amphoe_code: 1008,
    province_code: 10,
  },
  {
    tambon: "ป้อมปราบ",
    amphoe: "ป้อมปราบศัตรูพ่าย",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: 100801,
    amphoe_code: 1008,
    province_code: 10,
  },
  {
    tambon: "วัดเทพศิรินทร์",
    amphoe: "ป้อมปราบศัตรูพ่าย",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: 100802,
    amphoe_code: 1008,
    province_code: 10,
  },
  {
    tambon: "วัดโสมนัส",
    amphoe: "ป้อมปราบศัตรูพ่าย",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: 100805,
    amphoe_code: 1008,
    province_code: 10,
  },
  {
    tambon: "สามเสนใน",
    amphoe: "พญาไท",
    province: "กรุงเทพมหานคร",
    zipcode: 10400,
    district_code: 100299,
    amphoe_code: 1014,
    province_code: 10,
  },
  {
    tambon: "ชนะสงคราม",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100109,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "ตลาดยอด",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100108,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "บวรนิเวศ",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100107,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "บางขุนพรหม",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100111,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "บ้านพานถม",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100110,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "พระบรมมหาราชวัง",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100101,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "วังบูรพาภิรมย์",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100102,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "วัดราชบพิธ",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100103,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "วัดสามพระยา",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100112,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "ศาลเจ้าพ่อเสือ",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100105,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "สำราญราษฎร์",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100104,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "เสาชิงช้า",
    amphoe: "พระนคร",
    province: "กรุงเทพมหานคร",
    zipcode: 10200,
    district_code: 100106,
    amphoe_code: 1001,
    province_code: 10,
  },
  {
    tambon: "บางจาก",
    amphoe: "พระโขนง",
    province: "กรุงเทพมหานคร",
    zipcode: 10260,
    district_code: 100905,
    amphoe_code: 1009,
    province_code: 10,
  },
  {
    tambon: "คลองขวาง",
    amphoe: "ภาษีเจริญ",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102208,
    amphoe_code: 1022,
    province_code: 10,
  },
  {
    tambon: "คูหาสวรรค์",
    amphoe: "ภาษีเจริญ",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102210,
    amphoe_code: 1022,
    province_code: 10,
  },
  {
    tambon: "บางจาก",
    amphoe: "ภาษีเจริญ",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 100905,
    amphoe_code: 1022,
    province_code: 10,
  },
  {
    tambon: "บางด้วน",
    amphoe: "ภาษีเจริญ",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102202,
    amphoe_code: 1022,
    province_code: 10,
  },
  {
    tambon: "บางหว้า",
    amphoe: "ภาษีเจริญ",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102201,
    amphoe_code: 1022,
    province_code: 10,
  },
  {
    tambon: "บางแวก",
    amphoe: "ภาษีเจริญ",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102207,
    amphoe_code: 1022,
    province_code: 10,
  },
  {
    tambon: "ปากคลองภาษีเจริญ",
    amphoe: "ภาษีเจริญ",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102209,
    amphoe_code: 1022,
    province_code: 10,
  },
  {
    tambon: "มีนบุรี",
    amphoe: "มีนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10510,
    district_code: 101001,
    amphoe_code: 1010,
    province_code: 10,
  },
  {
    tambon: "แสนแสบ",
    amphoe: "มีนบุรี",
    province: "กรุงเทพมหานคร",
    zipcode: 10510,
    district_code: 101002,
    amphoe_code: 1010,
    province_code: 10,
  },
  {
    tambon: "ช่องนนทรี",
    amphoe: "ยานนาวา",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101203,
    amphoe_code: 1012,
    province_code: 10,
  },
  {
    tambon: "บางโพงพาง",
    amphoe: "ยานนาวา",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101204,
    amphoe_code: 1012,
    province_code: 10,
  },
  {
    tambon: "ถนนพญาไท",
    amphoe: "ราชเทวี",
    province: "กรุงเทพมหานคร",
    zipcode: 10400,
    district_code: 101405,
    amphoe_code: 1037,
    province_code: 10,
  },
  {
    tambon: "ถนนเพชรบุรี",
    amphoe: "ราชเทวี",
    province: "กรุงเทพมหานคร",
    zipcode: 10400,
    district_code: 101402,
    amphoe_code: 1037,
    province_code: 10,
  },
  {
    tambon: "ทุ่งพญาไท",
    amphoe: "ราชเทวี",
    province: "กรุงเทพมหานคร",
    zipcode: 10400,
    district_code: 101403,
    amphoe_code: 1037,
    province_code: 10,
  },
  {
    tambon: "มักกะสัน",
    amphoe: "ราชเทวี",
    province: "กรุงเทพมหานคร",
    zipcode: 10400,
    district_code: 101404,
    amphoe_code: 1037,
    province_code: 10,
  },
  {
    tambon: "บางปะกอก",
    amphoe: "ราษฎร์บูรณะ",
    province: "กรุงเทพมหานคร",
    zipcode: 10140,
    district_code: 102402,
    amphoe_code: 1024,
    province_code: 10,
  },
  {
    tambon: "ราษฎร์บูรณะ",
    amphoe: "ราษฎร์บูรณะ",
    province: "กรุงเทพมหานคร",
    zipcode: 10140,
    district_code: 102401,
    amphoe_code: 1024,
    province_code: 10,
  },
  {
    tambon: "ขุมทอง",
    amphoe: "ลาดกระบัง",
    province: "กรุงเทพมหานคร",
    zipcode: 10520,
    district_code: 101106,
    amphoe_code: 1011,
    province_code: 10,
  },
  {
    tambon: "คลองสองต้นนุ่น",
    amphoe: "ลาดกระบัง",
    province: "กรุงเทพมหานคร",
    zipcode: 10520,
    district_code: 101102,
    amphoe_code: 1011,
    province_code: 10,
  },
  {
    tambon: "คลองสามประเวศ",
    amphoe: "ลาดกระบัง",
    province: "กรุงเทพมหานคร",
    zipcode: 10520,
    district_code: 101103,
    amphoe_code: 1011,
    province_code: 10,
  },
  {
    tambon: "ทับยาว",
    amphoe: "ลาดกระบัง",
    province: "กรุงเทพมหานคร",
    zipcode: 10520,
    district_code: 101105,
    amphoe_code: 1011,
    province_code: 10,
  },
  {
    tambon: "ลาดกระบัง",
    amphoe: "ลาดกระบัง",
    province: "กรุงเทพมหานคร",
    zipcode: 10520,
    district_code: 101101,
    amphoe_code: 1011,
    province_code: 10,
  },
  {
    tambon: "ลำปลาทิว",
    amphoe: "ลาดกระบัง",
    province: "กรุงเทพมหานคร",
    zipcode: 10520,
    district_code: 101104,
    amphoe_code: 1011,
    province_code: 10,
  },
  {
    tambon: "จรเข้บัว",
    amphoe: "ลาดพร้าว",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: 100607,
    amphoe_code: 1038,
    province_code: 10,
  },
  {
    tambon: "ลาดพร้าว",
    amphoe: "ลาดพร้าว",
    province: "กรุงเทพมหานคร",
    zipcode: 10230,
    district_code: 100603,
    amphoe_code: 1038,
    province_code: 10,
  },
  {
    tambon: "คลองเจ้าคุณสิงห์",
    amphoe: "วังทองหลาง",
    province: "กรุงเทพมหานคร",
    zipcode: 10310,
    district_code: false,
    amphoe_code: 1045,
    province_code: 10,
  },
  {
    tambon: "พลับพลา",
    amphoe: "วังทองหลาง",
    province: "กรุงเทพมหานคร",
    zipcode: 10310,
    district_code: false,
    amphoe_code: 1045,
    province_code: 10,
  },
  {
    tambon: "วังทองหลาง",
    amphoe: "วังทองหลาง",
    province: "กรุงเทพมหานคร",
    zipcode: 10310,
    district_code: 100602,
    amphoe_code: 1045,
    province_code: 10,
  },
  {
    tambon: "สะพานสอง",
    amphoe: "วังทองหลาง",
    province: "กรุงเทพมหานคร",
    zipcode: 10310,
    district_code: false,
    amphoe_code: 1045,
    province_code: 10,
  },
  {
    tambon: "คลองตันเหนือ",
    amphoe: "วัฒนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10110,
    district_code: 103305,
    amphoe_code: 1039,
    province_code: 10,
  },
  {
    tambon: "คลองเตยเหนือ",
    amphoe: "วัฒนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10110,
    district_code: 103304,
    amphoe_code: 1039,
    province_code: 10,
  },
  {
    tambon: "พระโขนงเหนือ",
    amphoe: "วัฒนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10110,
    district_code: 103306,
    amphoe_code: 1039,
    province_code: 10,
  },
  {
    tambon: "พระโขนงเหนือ",
    amphoe: "วัฒนา",
    province: "กรุงเทพมหานคร",
    zipcode: 10260,
    district_code: 103306,
    amphoe_code: 1039,
    province_code: 10,
  },
  {
    tambon: "สวนหลวง",
    amphoe: "สวนหลวง",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 100906,
    amphoe_code: 1034,
    province_code: 10,
  },
  {
    tambon: "อ่อนนุช",
    amphoe: "สวนหลวง",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 103400,
    amphoe_code: 1034,
    province_code: 10,
  },
  {
    tambon: "พัฒนาการ",
    amphoe: "สวนหลวง",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 103403,
    amphoe_code: 1034,
    province_code: 10,
  },
  {
    tambon: "สะพานสูง",
    amphoe: "สะพานสูง",
    province: "กรุงเทพมหานคร",
    zipcode: 10240,
    district_code: 100605,
    amphoe_code: 1044,
    province_code: 10,
  },
  {
    tambon: "สะพานสูง",
    amphoe: "สะพานสูง",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 100605,
    amphoe_code: 1044,
    province_code: 10,
  },
  {
    tambon: "ราษฎร์พัฒนา",
    amphoe: "สะพานสูง",
    province: "กรุงเทพมหานคร",
    zipcode: 10240,
    district_code: 104402,
    amphoe_code: 1044,
    province_code: 10,
  },
  {
    tambon: "ทับช้าง",
    amphoe: "สะพานสูง",
    province: "กรุงเทพมหานคร",
    zipcode: 10250,
    district_code: 104403,
    amphoe_code: 1044,
    province_code: 10,
  },
  {
    tambon: "จักรวรรดิ์",
    amphoe: "สัมพันธวงศ์",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: false,
    amphoe_code: 1013,
    province_code: 10,
  },
  {
    tambon: "ตลาดน้อย",
    amphoe: "สัมพันธวงศ์",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: 101303,
    amphoe_code: 1013,
    province_code: 10,
  },
  {
    tambon: "สัมพันธวงศ์",
    amphoe: "สัมพันธวงศ์",
    province: "กรุงเทพมหานคร",
    zipcode: 10100,
    district_code: 101302,
    amphoe_code: 1013,
    province_code: 10,
  },
  {
    tambon: "ทุ่งมหาเมฆ",
    amphoe: "สาทร",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101208,
    amphoe_code: 1028,
    province_code: 10,
  },
  {
    tambon: "ทุ่งวัดดอน",
    amphoe: "สาทร",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101201,
    amphoe_code: 1028,
    province_code: 10,
  },
  {
    tambon: "ยานนาวา",
    amphoe: "สาทร",
    province: "กรุงเทพมหานคร",
    zipcode: 10120,
    district_code: 101202,
    amphoe_code: 1028,
    province_code: 10,
  },
  {
    tambon: "คลองถนน",
    amphoe: "สายไหม",
    province: "กรุงเทพมหานคร",
    zipcode: 10220,
    district_code: 100503,
    amphoe_code: 1042,
    province_code: 10,
  },
  {
    tambon: "สายไหม",
    amphoe: "สายไหม",
    province: "กรุงเทพมหานคร",
    zipcode: 10220,
    district_code: 100506,
    amphoe_code: 1042,
    province_code: 10,
  },
  {
    tambon: "ออเงิน",
    amphoe: "สายไหม",
    province: "กรุงเทพมหานคร",
    zipcode: 10220,
    district_code: 100509,
    amphoe_code: 1042,
    province_code: 10,
  },
  {
    tambon: "กระทุ่มราย",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100301,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "คลองสิบ",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100303,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "คลองสิบสอง",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100304,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "คู้ฝั่งเหนือ",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100306,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "ลำต้อยติ่ง",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100308,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "ลำผักชี",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100307,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "หนองจอก",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100302,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "โคกแฝด",
    amphoe: "หนองจอก",
    province: "กรุงเทพมหานคร",
    zipcode: 10530,
    district_code: 100305,
    amphoe_code: 1003,
    province_code: 10,
  },
  {
    tambon: "หนองค้างพลู",
    amphoe: "หนองแขม",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102303,
    amphoe_code: 1023,
    province_code: 10,
  },
  {
    tambon: "หนองแขม",
    amphoe: "หนองแขม",
    province: "กรุงเทพมหานคร",
    zipcode: 10160,
    district_code: 102302,
    amphoe_code: 1023,
    province_code: 10,
  },
  {
    tambon: "ตลาดบางเขน",
    amphoe: "หลักสี่",
    province: "กรุงเทพมหานคร",
    zipcode: 10210,
    district_code: 100504,
    amphoe_code: 1041,
    province_code: 10,
  },
  {
    tambon: "ทุ่งสองห้อง",
    amphoe: "หลักสี่",
    province: "กรุงเทพมหานคร",
    zipcode: 10210,
    district_code: 100507,
    amphoe_code: 1041,
    province_code: 10,
  },
  {
    tambon: "บางกะปิ",
    amphoe: "ห้วยขวาง",
    province: "กรุงเทพมหานคร",
    zipcode: 10310,
    district_code: 101702,
    amphoe_code: 1017,
    province_code: 10,
  },
  {
    tambon: "สามเสนนอก",
    amphoe: "ห้วยขวาง",
    province: "กรุงเทพมหานคร",
    zipcode: 10310,
    district_code: 101704,
    amphoe_code: 1017,
    province_code: 10,
  },
  {
    tambon: "ห้วยขวาง",
    amphoe: "ห้วยขวาง",
    province: "กรุงเทพมหานคร",
    zipcode: 10310,
    district_code: 101701,
    amphoe_code: 1017,
    province_code: 10,
  },
  {
    tambon: "กลอนโด",
    amphoe: "ด่านมะขามเตี้ย",
    province: "กาญจนบุรี",
    zipcode: 71260,
    district_code: 710115,
    amphoe_code: 7111,
    province_code: 71,
  },
  {
    tambon: "จรเข้เผือก",
    amphoe: "ด่านมะขามเตี้ย",
    province: "กาญจนบุรี",
    zipcode: 71260,
    district_code: 710114,
    amphoe_code: 7111,
    province_code: 71,
  },
  {
    tambon: "ด่านมะขามเตี้ย",
    amphoe: "ด่านมะขามเตี้ย",
    province: "กาญจนบุรี",
    zipcode: 71260,
    district_code: 710112,
    amphoe_code: 7111,
    province_code: 71,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "ด่านมะขามเตี้ย",
    province: "กาญจนบุรี",
    zipcode: 71260,
    district_code: 711104,
    amphoe_code: 7111,
    province_code: 71,
  },
  {
    tambon: "ชะแล",
    amphoe: "ทองผาภูมิ",
    province: "กาญจนบุรี",
    zipcode: 71180,
    district_code: 710705,
    amphoe_code: 7107,
    province_code: 71,
  },
  {
    tambon: "ท่าขนุน",
    amphoe: "ทองผาภูมิ",
    province: "กาญจนบุรี",
    zipcode: 71180,
    district_code: 710701,
    amphoe_code: 7107,
    province_code: 71,
  },
  {
    tambon: "ปิล๊อก",
    amphoe: "ทองผาภูมิ",
    province: "กาญจนบุรี",
    zipcode: 71180,
    district_code: 710702,
    amphoe_code: 7107,
    province_code: 71,
  },
  {
    tambon: "ลิ่นถิ่น",
    amphoe: "ทองผาภูมิ",
    province: "กาญจนบุรี",
    zipcode: 71180,
    district_code: 710704,
    amphoe_code: 7107,
    province_code: 71,
  },
  {
    tambon: "สหกรณ์นิคม",
    amphoe: "ทองผาภูมิ",
    province: "กาญจนบุรี",
    zipcode: 71180,
    district_code: 710707,
    amphoe_code: 7107,
    province_code: 71,
  },
  {
    tambon: "หินดาด",
    amphoe: "ทองผาภูมิ",
    province: "กาญจนบุรี",
    zipcode: 71180,
    district_code: 710703,
    amphoe_code: 7107,
    province_code: 71,
  },
  {
    tambon: "ห้วยเขย่ง",
    amphoe: "ทองผาภูมิ",
    province: "กาญจนบุรี",
    zipcode: 71180,
    district_code: 710706,
    amphoe_code: 7107,
    province_code: 71,
  },
  {
    tambon: "ดอนขมิ้น",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710509,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ดอนชะเอม",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710503,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ตะคร้ำเอน",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710505,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ท่ามะกา",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710506,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710507,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ท่าเสา",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710202,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ท่าไม้",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710504,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "พงตึก",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710501,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "พระแท่น",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710512,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ยางม่วง",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710502,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "สนามแย้",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 70190,
    district_code: 710515,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "หนองลาน",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710517,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "หวายเหนียว",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710513,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "อุโลกสี่หมื่น",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710510,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "เขาสามสิบหาบ",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710511,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "แสนตอ",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710514,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "โคกตะบอง",
    amphoe: "ท่ามะกา",
    province: "กาญจนบุรี",
    zipcode: 71120,
    district_code: 710508,
    amphoe_code: 7105,
    province_code: 71,
  },
  {
    tambon: "ทุ่งทอง",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710606,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "ท่าตะคร้อ",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71130,
    district_code: 710611,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "ท่าม่วง",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710601,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "ท่าล้อ",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710604,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710609,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "พังตรุ",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710610,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "ม่วงชุม",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710608,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "รางสาลี่",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710612,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "วังขนาย",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710602,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "วังศาลา",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710603,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "หนองขาว",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710605,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "หนองตากยา",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710613,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "เขาน้อย",
    amphoe: "ท่าม่วง",
    province: "กาญจนบุรี",
    zipcode: 71110,
    district_code: 710607,
    amphoe_code: 7106,
    province_code: 71,
  },
  {
    tambon: "ช่องด่าน",
    amphoe: "บ่อพลอย",
    province: "กาญจนบุรี",
    zipcode: 71160,
    district_code: 710308,
    amphoe_code: 7103,
    province_code: 71,
  },
  {
    tambon: "บ่อพลอย",
    amphoe: "บ่อพลอย",
    province: "กาญจนบุรี",
    zipcode: 71160,
    district_code: 710301,
    amphoe_code: 7103,
    province_code: 71,
  },
  {
    tambon: "หนองกร่าง",
    amphoe: "บ่อพลอย",
    province: "กาญจนบุรี",
    zipcode: 71220,
    district_code: 710309,
    amphoe_code: 7103,
    province_code: 71,
  },
  {
    tambon: "หนองกุ่ม",
    amphoe: "บ่อพลอย",
    province: "กาญจนบุรี",
    zipcode: 71160,
    district_code: 710302,
    amphoe_code: 7103,
    province_code: 71,
  },
  {
    tambon: "หนองรี",
    amphoe: "บ่อพลอย",
    province: "กาญจนบุรี",
    zipcode: 71220,
    district_code: 710303,
    amphoe_code: 7103,
    province_code: 71,
  },
  {
    tambon: "หลุมรัง",
    amphoe: "บ่อพลอย",
    province: "กาญจนบุรี",
    zipcode: 71160,
    district_code: 710305,
    amphoe_code: 7103,
    province_code: 71,
  },
  {
    tambon: "ดอนตาเพชร",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71140,
    district_code: 710912,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "ดอนเจดีย์",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71140,
    district_code: 710904,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "ทุ่งสมอ",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71140,
    district_code: 710903,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "พนมทวน",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71140,
    district_code: 710901,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "พังตรุ",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71140,
    district_code: 710610,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "รางหวาย",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71170,
    district_code: 710906,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "หนองสาหร่าย",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71140,
    district_code: 710911,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "หนองโรง",
    amphoe: "พนมทวน",
    province: "กาญจนบุรี",
    zipcode: 71140,
    district_code: 710902,
    amphoe_code: 7109,
    province_code: 71,
  },
  {
    tambon: "ด่านแม่แฉลบ",
    amphoe: "ศรีสวัสดิ์",
    province: "กาญจนบุรี",
    zipcode: 71250,
    district_code: 710402,
    amphoe_code: 7104,
    province_code: 71,
  },
  {
    tambon: "ท่ากระดาน",
    amphoe: "ศรีสวัสดิ์",
    province: "กาญจนบุรี",
    zipcode: 71250,
    district_code: 710404,
    amphoe_code: 7104,
    province_code: 71,
  },
  {
    tambon: "นาสวน",
    amphoe: "ศรีสวัสดิ์",
    province: "กาญจนบุรี",
    zipcode: 71250,
    district_code: 710401,
    amphoe_code: 7104,
    province_code: 71,
  },
  {
    tambon: "หนองเป็ด",
    amphoe: "ศรีสวัสดิ์",
    province: "กาญจนบุรี",
    zipcode: 71250,
    district_code: 710403,
    amphoe_code: 7104,
    province_code: 71,
  },
  {
    tambon: "เขาโจด",
    amphoe: "ศรีสวัสดิ์",
    province: "กาญจนบุรี",
    zipcode: 71220,
    district_code: 710405,
    amphoe_code: 7104,
    province_code: 71,
  },
  {
    tambon: "แม่กระบุง",
    amphoe: "ศรีสวัสดิ์",
    province: "กาญจนบุรี",
    zipcode: 71250,
    district_code: 710406,
    amphoe_code: 7104,
    province_code: 71,
  },
  {
    tambon: "ปรังเผล",
    amphoe: "สังขละบุรี",
    province: "กาญจนบุรี",
    zipcode: 71240,
    district_code: 710802,
    amphoe_code: 7108,
    province_code: 71,
  },
  {
    tambon: "หนองลู",
    amphoe: "สังขละบุรี",
    province: "กาญจนบุรี",
    zipcode: 71240,
    district_code: 710801,
    amphoe_code: 7108,
    province_code: 71,
  },
  {
    tambon: "ไล่โว่",
    amphoe: "สังขละบุรี",
    province: "กาญจนบุรี",
    zipcode: 71240,
    district_code: 710803,
    amphoe_code: 7108,
    province_code: 71,
  },
  {
    tambon: "สมเด็จเจริญ",
    amphoe: "หนองปรือ",
    province: "กาญจนบุรี",
    zipcode: 71220,
    district_code: 710307,
    amphoe_code: 7112,
    province_code: 71,
  },
  {
    tambon: "หนองปรือ",
    amphoe: "หนองปรือ",
    province: "กาญจนบุรี",
    zipcode: 71220,
    district_code: 710304,
    amphoe_code: 7112,
    province_code: 71,
  },
  {
    tambon: "หนองปลาไหล",
    amphoe: "หนองปรือ",
    province: "กาญจนบุรี",
    zipcode: 71220,
    district_code: 710306,
    amphoe_code: 7112,
    province_code: 71,
  },
  {
    tambon: "ดอนแสลบ",
    amphoe: "ห้วยกระเจา",
    province: "กาญจนบุรี",
    zipcode: 71170,
    district_code: 710907,
    amphoe_code: 7113,
    province_code: 71,
  },
  {
    tambon: "วังไผ่",
    amphoe: "ห้วยกระเจา",
    province: "กาญจนบุรี",
    zipcode: 71170,
    district_code: 710910,
    amphoe_code: 7113,
    province_code: 71,
  },
  {
    tambon: "สระลงเรือ",
    amphoe: "ห้วยกระเจา",
    province: "กาญจนบุรี",
    zipcode: 71170,
    district_code: 710909,
    amphoe_code: 7113,
    province_code: 71,
  },
  {
    tambon: "ห้วยกระเจา",
    amphoe: "ห้วยกระเจา",
    province: "กาญจนบุรี",
    zipcode: 71170,
    district_code: 710908,
    amphoe_code: 7113,
    province_code: 71,
  },
  {
    tambon: "ช่องสะเดา",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71190,
    district_code: 710109,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "ท่ามะขาม",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710104,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "บ้านเก่า",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710113,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "บ้านเหนือ",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710101,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "บ้านใต้",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710102,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "ปากแพรก",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710103,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "ลาดหญ้า",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71190,
    district_code: 710107,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "วังด้ง",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71190,
    district_code: 710108,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "วังเย็น",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710116,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "หนองบัว",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71190,
    district_code: 710106,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "หนองหญ้า",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710110,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "เกาะสำโรง",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710111,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "แก่งเสี้ยน",
    amphoe: "เมืองกาญจนบุรี",
    province: "กาญจนบุรี",
    zipcode: 71000,
    district_code: 710105,
    amphoe_code: 7101,
    province_code: 71,
  },
  {
    tambon: "ทุ่งกระบ่ำ",
    amphoe: "เลาขวัญ",
    province: "กาญจนบุรี",
    zipcode: 71210,
    district_code: 711006,
    amphoe_code: 7110,
    province_code: 71,
  },
  {
    tambon: "หนองนกแก้ว",
    amphoe: "เลาขวัญ",
    province: "กาญจนบุรี",
    zipcode: 71210,
    district_code: 711005,
    amphoe_code: 7110,
    province_code: 71,
  },
  {
    tambon: "หนองประดู่",
    amphoe: "เลาขวัญ",
    province: "กาญจนบุรี",
    zipcode: 71210,
    district_code: 711003,
    amphoe_code: 7110,
    province_code: 71,
  },
  {
    tambon: "หนองปลิง",
    amphoe: "เลาขวัญ",
    province: "กาญจนบุรี",
    zipcode: 71210,
    district_code: 711004,
    amphoe_code: 7110,
    province_code: 71,
  },
  {
    tambon: "หนองฝ้าย",
    amphoe: "เลาขวัญ",
    province: "กาญจนบุรี",
    zipcode: 71210,
    district_code: 711007,
    amphoe_code: 7110,
    province_code: 71,
  },
  {
    tambon: "หนองโสน",
    amphoe: "เลาขวัญ",
    province: "กาญจนบุรี",
    zipcode: 71210,
    district_code: 711002,
    amphoe_code: 7110,
    province_code: 71,
  },
  {
    tambon: "เลาขวัญ",
    amphoe: "เลาขวัญ",
    province: "กาญจนบุรี",
    zipcode: 71210,
    district_code: 711001,
    amphoe_code: 7110,
    province_code: 71,
  },
  {
    tambon: "ท่าเสา",
    amphoe: "ไทรโยค",
    province: "กาญจนบุรี",
    zipcode: 71150,
    district_code: 710202,
    amphoe_code: 7102,
    province_code: 71,
  },
  {
    tambon: "บ้องตี้",
    amphoe: "ไทรโยค",
    province: "กาญจนบุรี",
    zipcode: 71150,
    district_code: 710207,
    amphoe_code: 7102,
    province_code: 71,
  },
  {
    tambon: "ลุ่มสุ่ม",
    amphoe: "ไทรโยค",
    province: "กาญจนบุรี",
    zipcode: 71150,
    district_code: 710201,
    amphoe_code: 7102,
    province_code: 71,
  },
  {
    tambon: "วังกระแจะ",
    amphoe: "ไทรโยค",
    province: "กาญจนบุรี",
    zipcode: 71150,
    district_code: 710205,
    amphoe_code: 7102,
    province_code: 71,
  },
  {
    tambon: "ศรีมงคล",
    amphoe: "ไทรโยค",
    province: "กาญจนบุรี",
    zipcode: 71150,
    district_code: 710206,
    amphoe_code: 7102,
    province_code: 71,
  },
  {
    tambon: "สิงห์",
    amphoe: "ไทรโยค",
    province: "กาญจนบุรี",
    zipcode: 71150,
    district_code: 710203,
    amphoe_code: 7102,
    province_code: 71,
  },
  {
    tambon: "ไทรโยค",
    amphoe: "ไทรโยค",
    province: "กาญจนบุรี",
    zipcode: 71150,
    district_code: 710204,
    amphoe_code: 7102,
    province_code: 71,
  },
  {
    tambon: "กมลาไสย",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460301,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "ดงลิง",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460304,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "ธัญญา",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460305,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "หนองแปน",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460308,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "หลักเมือง",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460302,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "เจ้าท่า",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460310,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "โคกสมบูรณ์",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460311,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "โพนงาม",
    amphoe: "กมลาไสย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460303,
    amphoe_code: 4603,
    province_code: 46,
  },
  {
    tambon: "กุดค้าว",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460512,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "กุดหว้า",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460506,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "จุมจัง",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460504,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "นาขาม",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460508,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "นาโก",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460510,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "บัวขาว",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460501,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "สมสะอาด",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460511,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "สามขา",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460507,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "หนองห้าง",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460509,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "เหล่าใหญ่",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460503,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "เหล่าไฮงาม",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460505,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "แจนแลน",
    amphoe: "กุฉินารายณ์",
    province: "กาฬสินธุ์",
    zipcode: 46110,
    district_code: 460502,
    amphoe_code: 4605,
    province_code: 46,
  },
  {
    tambon: "ดินจี่",
    amphoe: "คำม่วง",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 461005,
    amphoe_code: 4610,
    province_code: 46,
  },
  {
    tambon: "ทุ่งคลอง",
    amphoe: "คำม่วง",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 460997,
    amphoe_code: 4610,
    province_code: 46,
  },
  {
    tambon: "นาทัน",
    amphoe: "คำม่วง",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 461007,
    amphoe_code: 4610,
    province_code: 46,
  },
  {
    tambon: "นาบอน",
    amphoe: "คำม่วง",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 461006,
    amphoe_code: 4610,
    province_code: 46,
  },
  {
    tambon: "เนินยาง",
    amphoe: "คำม่วง",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 461009,
    amphoe_code: 4610,
    province_code: 46,
  },
  {
    tambon: "โพน",
    amphoe: "คำม่วง",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 460999,
    amphoe_code: 4610,
    province_code: 46,
  },
  {
    tambon: "ฆ้องชัยพัฒนา",
    amphoe: "ฆ้องชัย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460313,
    amphoe_code: 4618,
    province_code: 46,
  },
  {
    tambon: "ลำชี",
    amphoe: "ฆ้องชัย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460307,
    amphoe_code: 4618,
    province_code: 46,
  },
  {
    tambon: "เหล่ากลาง",
    amphoe: "ฆ้องชัย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 461802,
    amphoe_code: 4618,
    province_code: 46,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "ฆ้องชัย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 460309,
    amphoe_code: 4618,
    province_code: 46,
  },
  {
    tambon: "โนนศิลาเลิง",
    amphoe: "ฆ้องชัย",
    province: "กาฬสินธุ์",
    zipcode: 46130,
    district_code: 461804,
    amphoe_code: 4618,
    province_code: 46,
  },
  {
    tambon: "ดงพยุง",
    amphoe: "ดอนจาน",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460112,
    amphoe_code: 4617,
    province_code: 46,
  },
  {
    tambon: "ดอนจาน",
    amphoe: "ดอนจาน",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460114,
    amphoe_code: 4617,
    province_code: 46,
  },
  {
    tambon: "นาจำปา",
    amphoe: "ดอนจาน",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460118,
    amphoe_code: 4617,
    province_code: 46,
  },
  {
    tambon: "ม่วงนา",
    amphoe: "ดอนจาน",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460110,
    amphoe_code: 4617,
    province_code: 46,
  },
  {
    tambon: "สะอาดไชยศรี",
    amphoe: "ดอนจาน",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460122,
    amphoe_code: 4617,
    province_code: 46,
  },
  {
    tambon: "กุงเก่า",
    amphoe: "ท่าคันโท",
    province: "กาฬสินธุ์",
    zipcode: 46190,
    district_code: 461102,
    amphoe_code: 4611,
    province_code: 46,
  },
  {
    tambon: "กุดจิก",
    amphoe: "ท่าคันโท",
    province: "กาฬสินธุ์",
    zipcode: 46190,
    district_code: 461104,
    amphoe_code: 4611,
    province_code: 46,
  },
  {
    tambon: "ดงสมบูรณ์",
    amphoe: "ท่าคันโท",
    province: "กาฬสินธุ์",
    zipcode: 46190,
    district_code: 461106,
    amphoe_code: 4611,
    province_code: 46,
  },
  {
    tambon: "ท่าคันโท",
    amphoe: "ท่าคันโท",
    province: "กาฬสินธุ์",
    zipcode: 46190,
    district_code: 461101,
    amphoe_code: 4611,
    province_code: 46,
  },
  {
    tambon: "นาตาล",
    amphoe: "ท่าคันโท",
    province: "กาฬสินธุ์",
    zipcode: 46190,
    district_code: 461105,
    amphoe_code: 4611,
    province_code: 46,
  },
  {
    tambon: "ยางอู้ม",
    amphoe: "ท่าคันโท",
    province: "กาฬสินธุ์",
    zipcode: 46190,
    district_code: 461103,
    amphoe_code: 4611,
    province_code: 46,
  },
  {
    tambon: "นาคู",
    amphoe: "นาคู",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460605,
    amphoe_code: 4616,
    province_code: 46,
  },
  {
    tambon: "บ่อแก้ว",
    amphoe: "นาคู",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460607,
    amphoe_code: 4616,
    province_code: 46,
  },
  {
    tambon: "ภูแล่นช้าง",
    amphoe: "นาคู",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460604,
    amphoe_code: 4616,
    province_code: 46,
  },
  {
    tambon: "สายนาวัง",
    amphoe: "นาคู",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460609,
    amphoe_code: 4616,
    province_code: 46,
  },
  {
    tambon: "โนนนาจาน",
    amphoe: "นาคู",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460610,
    amphoe_code: 4616,
    province_code: 46,
  },
  {
    tambon: "นามน",
    amphoe: "นามน",
    province: "กาฬสินธุ์",
    zipcode: 46230,
    district_code: 460198,
    amphoe_code: 4602,
    province_code: 46,
  },
  {
    tambon: "ยอดแกง",
    amphoe: "นามน",
    province: "กาฬสินธุ์",
    zipcode: 46230,
    district_code: 460199,
    amphoe_code: 4602,
    province_code: 46,
  },
  {
    tambon: "สงเปลือย",
    amphoe: "นามน",
    province: "กาฬสินธุ์",
    zipcode: 46230,
    district_code: 460203,
    amphoe_code: 4602,
    province_code: 46,
  },
  {
    tambon: "หนองบัว",
    amphoe: "นามน",
    province: "กาฬสินธุ์",
    zipcode: 46230,
    district_code: 460205,
    amphoe_code: 4602,
    province_code: 46,
  },
  {
    tambon: "หลักเหลี่ยม",
    amphoe: "นามน",
    province: "กาฬสินธุ์",
    zipcode: 46230,
    district_code: 460204,
    amphoe_code: 4602,
    province_code: 46,
  },
  {
    tambon: "คลองขาม",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460711,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "ดอนสมบูรณ์",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460709,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "นาดี",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460713,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "นาเชือก",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460710,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "บัวบาน",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460704,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "ยางตลาด",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460701,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "หนองตอกแป้น",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460715,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "หนองอิเฒ่า",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460708,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "หัวงัว",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460702,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "หัวนาคำ",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460707,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "อิตื้อ",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460706,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "อุ่มเม่า",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460703,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "เขาพระนอน",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460712,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "เว่อ",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460705,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "โนนสูง",
    amphoe: "ยางตลาด",
    province: "กาฬสินธุ์",
    zipcode: 46120,
    district_code: 460714,
    amphoe_code: 4607,
    province_code: 46,
  },
  {
    tambon: "ร่องคำ",
    amphoe: "ร่องคำ",
    province: "กาฬสินธุ์",
    zipcode: 46210,
    district_code: 460401,
    amphoe_code: 4604,
    province_code: 46,
  },
  {
    tambon: "สามัคคี",
    amphoe: "ร่องคำ",
    province: "กาฬสินธุ์",
    zipcode: 46210,
    district_code: 460402,
    amphoe_code: 4604,
    province_code: 46,
  },
  {
    tambon: "เหล่าอ้อย",
    amphoe: "ร่องคำ",
    province: "กาฬสินธุ์",
    zipcode: 46210,
    district_code: 460403,
    amphoe_code: 4604,
    province_code: 46,
  },
  {
    tambon: "ผาเสวย",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461306,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "มหาไชย",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461304,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "ลำห้วยหลัว",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461308,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "ศรีสมเด็จ",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461307,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "สมเด็จ",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461301,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "หนองแวง",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461302,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "หมูม่น",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461305,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "แซงบาดาล",
    amphoe: "สมเด็จ",
    province: "กาฬสินธุ์",
    zipcode: 46150,
    district_code: 461303,
    amphoe_code: 4613,
    province_code: 46,
  },
  {
    tambon: "นามะเขือ",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460903,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "นิคม",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460905,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "ภูสิงห์",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460901,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "สหัสขันธ์",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460902,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "โนนน้ำเกลี้ยง",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460908,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "โนนบุรี",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460907,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "โนนศิลา",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460312,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "โนนแหลมทอง",
    amphoe: "สหัสขันธ์",
    province: "กาฬสินธุ์",
    zipcode: 46140,
    district_code: 460906,
    amphoe_code: 4609,
    province_code: 46,
  },
  {
    tambon: "คำสร้างเที่ยง",
    amphoe: "สามชัย",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 461008,
    amphoe_code: 4615,
    province_code: 46,
  },
  {
    tambon: "สำราญ",
    amphoe: "สามชัย",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 460998,
    amphoe_code: 4615,
    province_code: 46,
  },
  {
    tambon: "สำราญใต้",
    amphoe: "สามชัย",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 461004,
    amphoe_code: 4615,
    province_code: 46,
  },
  {
    tambon: "หนองช้าง",
    amphoe: "สามชัย",
    province: "กาฬสินธุ์",
    zipcode: 46180,
    district_code: 461010,
    amphoe_code: 4615,
    province_code: 46,
  },
  {
    tambon: "ดงมูล",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461207,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "ลำหนองแสน",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461208,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "หนองกุงศรี",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461201,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "หนองบัว",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 460205,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "หนองสรวง",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461204,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "หนองหิน",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461209,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "หนองใหญ่",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461206,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "เสาเล้า",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461205,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "โคกเครือ",
    amphoe: "หนองกุงศรี",
    province: "กาฬสินธุ์",
    zipcode: 46220,
    district_code: 461198,
    amphoe_code: 4612,
    province_code: 46,
  },
  {
    tambon: "คำบง",
    amphoe: "ห้วยผึ้ง",
    province: "กาฬสินธุ์",
    zipcode: 46240,
    district_code: 461401,
    amphoe_code: 4614,
    province_code: 46,
  },
  {
    tambon: "นิคมห้วยผึ้ง",
    amphoe: "ห้วยผึ้ง",
    province: "กาฬสินธุ์",
    zipcode: 46240,
    district_code: 461403,
    amphoe_code: 4614,
    province_code: 46,
  },
  {
    tambon: "หนองอีบุตร",
    amphoe: "ห้วยผึ้ง",
    province: "กาฬสินธุ์",
    zipcode: 46240,
    district_code: 461404,
    amphoe_code: 4614,
    province_code: 46,
  },
  {
    tambon: "ไค้นุ่น",
    amphoe: "ห้วยผึ้ง",
    province: "กาฬสินธุ์",
    zipcode: 46240,
    district_code: 461402,
    amphoe_code: 4614,
    province_code: 46,
  },
  {
    tambon: "กุดโดน",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460803,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "คำเหมือดแก้ว",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460807,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "คำใหญ่",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460802,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "ทรายทอง",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460809,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "บึงนาเรียง",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460804,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "พิมูล",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460806,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "หัวหิน",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460805,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "ห้วยเม็ก",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460801,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "ห้วยเม็ก",
    province: "กาฬสินธุ์",
    zipcode: 46170,
    district_code: 460808,
    amphoe_code: 4608,
    province_code: 46,
  },
  {
    tambon: "กุดปลาค้าว",
    amphoe: "เขาวง",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460611,
    amphoe_code: 4606,
    province_code: 46,
  },
  {
    tambon: "กุดสิมคุ้มใหม่",
    amphoe: "เขาวง",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460606,
    amphoe_code: 4606,
    province_code: 46,
  },
  {
    tambon: "คุ้มเก่า",
    amphoe: "เขาวง",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460601,
    amphoe_code: 4606,
    province_code: 46,
  },
  {
    tambon: "สงเปลือย",
    amphoe: "เขาวง",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460203,
    amphoe_code: 4606,
    province_code: 46,
  },
  {
    tambon: "สระพังทอง",
    amphoe: "เขาวง",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460608,
    amphoe_code: 4606,
    province_code: 46,
  },
  {
    tambon: "หนองผือ",
    amphoe: "เขาวง",
    province: "กาฬสินธุ์",
    zipcode: 46160,
    district_code: 460603,
    amphoe_code: 4606,
    province_code: 46,
  },
  {
    tambon: "กลางหมื่น",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460116,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "กาฬสินธุ์",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460101,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ขมิ้น",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460117,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "นาจารย์",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460120,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "บึงวิชัย",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460108,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ภูดิน",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460113,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ภูปอ",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460111,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ลำคลอง",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460121,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ลำปาว",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460105,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ลำพาน",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460106,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "หนองกุง",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460115,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "หลุบ",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460103,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ห้วยโพธิ์",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460109,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "เชียงเครือ",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460107,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "เหนือ",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460102,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "โพนทอง",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460119,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ไผ่",
    amphoe: "เมืองกาฬสินธุ์",
    province: "กาฬสินธุ์",
    zipcode: 46000,
    district_code: 460104,
    amphoe_code: 4601,
    province_code: 46,
  },
  {
    tambon: "ดอนแตง",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62140,
    district_code: 620408,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "บ่อถ้ำ",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62140,
    district_code: 620407,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "ปางมะค่า",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62140,
    district_code: 620411,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "ป่าพุทรา",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62130,
    district_code: 620404,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "ยางสูง",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62130,
    district_code: 620403,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "วังชะพลู",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62140,
    district_code: 620409,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "วังหามแห",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62140,
    district_code: 620412,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "สลกบาตร",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62140,
    district_code: 620406,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "เกาะตาล",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62130,
    district_code: 620413,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "แสนตอ",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62130,
    district_code: 620405,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "โค้งไผ่",
    amphoe: "ขาณุวรลักษบุรี",
    province: "กำแพงเพชร",
    zipcode: 62140,
    district_code: 620410,
    amphoe_code: 6204,
    province_code: 62,
  },
  {
    tambon: "คลองขลุง",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620501,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "คลองสมบูรณ์",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620516,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "ท่าพุทรา",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620504,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "ท่ามะเขือ",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620502,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "วังบัว",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620513,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "วังยาง",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620506,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "วังแขม",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620507,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "วังไทร",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620509,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "หัวถนน",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620508,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "แม่ลาด",
    amphoe: "คลองขลุง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620505,
    amphoe_code: 6205,
    province_code: 62,
  },
  {
    tambon: "คลองน้ำไหล",
    amphoe: "คลองลาน",
    province: "กำแพงเพชร",
    zipcode: 62180,
    district_code: 620301,
    amphoe_code: 6203,
    province_code: 62,
  },
  {
    tambon: "คลองลานพัฒนา",
    amphoe: "คลองลาน",
    province: "กำแพงเพชร",
    zipcode: 62180,
    district_code: 620303,
    amphoe_code: 6203,
    province_code: 62,
  },
  {
    tambon: "สักงาม",
    amphoe: "คลองลาน",
    province: "กำแพงเพชร",
    zipcode: 62180,
    district_code: 620304,
    amphoe_code: 6203,
    province_code: 62,
  },
  {
    tambon: "โป่งน้ำร้อน",
    amphoe: "คลองลาน",
    province: "กำแพงเพชร",
    zipcode: 62180,
    district_code: 620198,
    amphoe_code: 6203,
    province_code: 62,
  },
  {
    tambon: "ถาวรวัฒนา",
    amphoe: "ทรายทองวัฒนา",
    province: "กำแพงเพชร",
    zipcode: 62190,
    district_code: 620803,
    amphoe_code: 6208,
    province_code: 62,
  },
  {
    tambon: "ทุ่งทราย",
    amphoe: "ทรายทองวัฒนา",
    province: "กำแพงเพชร",
    zipcode: 62190,
    district_code: 620599,
    amphoe_code: 6208,
    province_code: 62,
  },
  {
    tambon: "ทุ่งทอง",
    amphoe: "ทรายทองวัฒนา",
    province: "กำแพงเพชร",
    zipcode: 62190,
    district_code: 620514,
    amphoe_code: 6208,
    province_code: 62,
  },
  {
    tambon: "บึงสามัคคี",
    amphoe: "บึงสามัคคี",
    province: "กำแพงเพชร",
    zipcode: 62210,
    district_code: 620414,
    amphoe_code: 6210,
    province_code: 62,
  },
  {
    tambon: "ระหาน",
    amphoe: "บึงสามัคคี",
    province: "กำแพงเพชร",
    zipcode: 62210,
    district_code: 620402,
    amphoe_code: 6210,
    province_code: 62,
  },
  {
    tambon: "วังชะโอน",
    amphoe: "บึงสามัคคี",
    province: "กำแพงเพชร",
    zipcode: 62210,
    district_code: 620401,
    amphoe_code: 6210,
    province_code: 62,
  },
  {
    tambon: "เทพนิมิต",
    amphoe: "บึงสามัคคี",
    province: "กำแพงเพชร",
    zipcode: 62210,
    district_code: 621004,
    amphoe_code: 6210,
    province_code: 62,
  },
  {
    tambon: "ปางตาไว",
    amphoe: "ปางศิลาทอง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620511,
    amphoe_code: 6209,
    province_code: 62,
  },
  {
    tambon: "หินดาต",
    amphoe: "ปางศิลาทอง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620515,
    amphoe_code: 6209,
    province_code: 62,
  },
  {
    tambon: "โพธิ์ทอง",
    amphoe: "ปางศิลาทอง",
    province: "กำแพงเพชร",
    zipcode: 62120,
    district_code: 620510,
    amphoe_code: 6209,
    province_code: 62,
  },
  {
    tambon: "คลองพิไกร",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620608,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "คุยบ้านโอง",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620607,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "ถ้ำกระต่ายทอง",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620609,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "ท่าไม้",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620603,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "พรานกระต่าย",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620601,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "วังควง",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620604,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "วังตะแบก",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620605,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "หนองหัววัว",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620602,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "ห้วยยั้ง",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620610,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "เขาคีริส",
    amphoe: "พรานกระต่าย",
    province: "กำแพงเพชร",
    zipcode: 62110,
    district_code: 620606,
    amphoe_code: 6206,
    province_code: 62,
  },
  {
    tambon: "จันทิมา",
    amphoe: "ลานกระบือ",
    province: "กำแพงเพชร",
    zipcode: 62170,
    district_code: 620707,
    amphoe_code: 6207,
    province_code: 62,
  },
  {
    tambon: "ช่องลม",
    amphoe: "ลานกระบือ",
    province: "กำแพงเพชร",
    zipcode: 62170,
    district_code: 620702,
    amphoe_code: 6207,
    province_code: 62,
  },
  {
    tambon: "บึงทับแรต",
    amphoe: "ลานกระบือ",
    province: "กำแพงเพชร",
    zipcode: 62170,
    district_code: 620706,
    amphoe_code: 6207,
    province_code: 62,
  },
  {
    tambon: "ประชาสุขสันต์",
    amphoe: "ลานกระบือ",
    province: "กำแพงเพชร",
    zipcode: 62170,
    district_code: 620705,
    amphoe_code: 6207,
    province_code: 62,
  },
  {
    tambon: "ลานกระบือ",
    amphoe: "ลานกระบือ",
    province: "กำแพงเพชร",
    zipcode: 62170,
    district_code: 620701,
    amphoe_code: 6207,
    province_code: 62,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "ลานกระบือ",
    province: "กำแพงเพชร",
    zipcode: 62170,
    district_code: 620703,
    amphoe_code: 6207,
    province_code: 62,
  },
  {
    tambon: "โนนพลวง",
    amphoe: "ลานกระบือ",
    province: "กำแพงเพชร",
    zipcode: 62170,
    district_code: 620704,
    amphoe_code: 6207,
    province_code: 62,
  },
  {
    tambon: "คณฑี",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620111,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "คลองแม่ลาย",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620117,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "ทรงธรรม",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620106,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "ท่าขุนราม",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620115,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "ธำมรงค์",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62160,
    district_code: 620118,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "นครชุม",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620105,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "นาบ่อคำ",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620104,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "นิคมทุ่งโพธิ์ทะเล",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620112,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "ลานดอกไม้",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620107,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "วังทอง",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620114,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620119,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "หนองปลิง",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620110,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "อ่างทอง",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620103,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "เทพนคร",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620113,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620101,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "ไตรตรึงษ์",
    amphoe: "เมืองกำแพงเพชร",
    province: "กำแพงเพชร",
    zipcode: 62160,
    district_code: 620102,
    amphoe_code: 6201,
    province_code: 62,
  },
  {
    tambon: "ลานดอกไม้ตก",
    amphoe: "โกสัมพีนคร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620108,
    amphoe_code: 6211,
    province_code: 62,
  },
  {
    tambon: "เพชรชมภู",
    amphoe: "โกสัมพีนคร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620116,
    amphoe_code: 6211,
    province_code: 62,
  },
  {
    tambon: "โกสัมพี",
    amphoe: "โกสัมพีนคร",
    province: "กำแพงเพชร",
    zipcode: 62000,
    district_code: 620109,
    amphoe_code: 6211,
    province_code: 62,
  },
  {
    tambon: "พานทอง",
    amphoe: "ไทรงาม",
    province: "กำแพงเพชร",
    zipcode: 62150,
    district_code: 620206,
    amphoe_code: 6202,
    province_code: 62,
  },
  {
    tambon: "มหาชัย",
    amphoe: "ไทรงาม",
    province: "กำแพงเพชร",
    zipcode: 62150,
    district_code: 620205,
    amphoe_code: 6202,
    province_code: 62,
  },
  {
    tambon: "หนองคล้า",
    amphoe: "ไทรงาม",
    province: "กำแพงเพชร",
    zipcode: 62150,
    district_code: 620197,
    amphoe_code: 6202,
    province_code: 62,
  },
  {
    tambon: "หนองทอง",
    amphoe: "ไทรงาม",
    province: "กำแพงเพชร",
    zipcode: 62150,
    district_code: 620203,
    amphoe_code: 6202,
    province_code: 62,
  },
  {
    tambon: "หนองแม่แตง",
    amphoe: "ไทรงาม",
    province: "กำแพงเพชร",
    zipcode: 62150,
    district_code: 620207,
    amphoe_code: 6202,
    province_code: 62,
  },
  {
    tambon: "หนองไม้กอง",
    amphoe: "ไทรงาม",
    province: "กำแพงเพชร",
    zipcode: 62150,
    district_code: 620204,
    amphoe_code: 6202,
    province_code: 62,
  },
  {
    tambon: "ไทรงาม",
    amphoe: "ไทรงาม",
    province: "กำแพงเพชร",
    zipcode: 62150,
    district_code: 620199,
    amphoe_code: 6202,
    province_code: 62,
  },
  {
    tambon: "ดูนสาด",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400909,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "น้ำอ้อม",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400911,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "บ้านฝาง",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400206,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "หนองกุงใหญ่",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400902,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "หนองโก",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400901,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "หนองโน",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400910,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "หัวนาคำ",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400912,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "ห้วยยาง",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400906,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "ห้วยโจด",
    amphoe: "กระนวน",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400905,
    amphoe_code: 4009,
    province_code: 40,
  },
  {
    tambon: "กุดเพียขอม",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401802,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "ชนบท",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401801,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "บ้านแท่น",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401805,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "ปอแดง",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401808,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "วังแสง",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401803,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "ศรีบุญเรือง",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401806,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "ห้วยแก",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401804,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "โนนพะยอม",
    amphoe: "ชนบท",
    province: "ขอนแก่น",
    zipcode: 40180,
    district_code: 401807,
    amphoe_code: 4018,
    province_code: 40,
  },
  {
    tambon: "ขัวเรียง",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400505,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "ชุมแพ",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400501,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "นาหนองทุ่ม",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40290,
    district_code: 400503,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "นาเพียง",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400509,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "วังหินลาด",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400508,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "หนองเขียด",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40290,
    district_code: 400510,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "หนองเสาเล้า",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400511,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400506,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40290,
    district_code: 400409,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "โนนหัน",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40290,
    district_code: 400502,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "โนนอุดม",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400504,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "ไชยสอ",
    amphoe: "ชุมแพ",
    province: "ขอนแก่น",
    zipcode: 40130,
    district_code: 400507,
    amphoe_code: 4005,
    province_code: 40,
  },
  {
    tambon: "กระนวน",
    amphoe: "ซำสูง",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400903,
    amphoe_code: 4021,
    province_code: 40,
  },
  {
    tambon: "คำแมด",
    amphoe: "ซำสูง",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400908,
    amphoe_code: 4021,
    province_code: 40,
  },
  {
    tambon: "คูคำ",
    amphoe: "ซำสูง",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400913,
    amphoe_code: 4021,
    province_code: 40,
  },
  {
    tambon: "บ้านโนน",
    amphoe: "ซำสูง",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400904,
    amphoe_code: 4021,
    province_code: 40,
  },
  {
    tambon: "ห้วยเตย",
    amphoe: "ซำสูง",
    province: "ขอนแก่น",
    zipcode: 40170,
    district_code: 400914,
    amphoe_code: 4021,
    province_code: 40,
  },
  {
    tambon: "กุดน้ำใส",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40310,
    district_code: 400712,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "ทรายมูล",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400709,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "ท่ากระเสริม",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400710,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "น้ำพอง",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400701,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "บัวเงิน",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400708,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "บัวใหญ่",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400704,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "บ้านขาม",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400707,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "พังทุย",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400711,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "ม่วงหวาน",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40310,
    district_code: 400706,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "วังชัย",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400702,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "สะอาด",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40310,
    district_code: 400705,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "หนองกุง",
    amphoe: "น้ำพอง",
    province: "ขอนแก่น",
    zipcode: 40140,
    district_code: 400703,
    amphoe_code: 4007,
    province_code: 40,
  },
  {
    tambon: "บ้านฝาง",
    amphoe: "บ้านฝาง",
    province: "ขอนแก่น",
    zipcode: 40270,
    district_code: 400206,
    amphoe_code: 4002,
    province_code: 40,
  },
  {
    tambon: "บ้านเหล่า",
    amphoe: "บ้านฝาง",
    province: "ขอนแก่น",
    zipcode: 40270,
    district_code: 400204,
    amphoe_code: 4002,
    province_code: 40,
  },
  {
    tambon: "ป่ามะนาว",
    amphoe: "บ้านฝาง",
    province: "ขอนแก่น",
    zipcode: 40270,
    district_code: 400205,
    amphoe_code: 4002,
    province_code: 40,
  },
  {
    tambon: "ป่าหวายนั่ง",
    amphoe: "บ้านฝาง",
    province: "ขอนแก่น",
    zipcode: 40270,
    district_code: 400202,
    amphoe_code: 4002,
    province_code: 40,
  },
  {
    tambon: "หนองบัว",
    amphoe: "บ้านฝาง",
    province: "ขอนแก่น",
    zipcode: 40270,
    district_code: 400199,
    amphoe_code: 4002,
    province_code: 40,
  },
  {
    tambon: "โคกงาม",
    amphoe: "บ้านฝาง",
    province: "ขอนแก่น",
    zipcode: 40270,
    district_code: 400207,
    amphoe_code: 4002,
    province_code: 40,
  },
  {
    tambon: "โนนฆ้อง",
    amphoe: "บ้านฝาง",
    province: "ขอนแก่น",
    zipcode: 40270,
    district_code: 400203,
    amphoe_code: 4002,
    province_code: 40,
  },
  {
    tambon: "บ้านแฮด",
    amphoe: "บ้านแฮด",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401003,
    amphoe_code: 4024,
    province_code: 40,
  },
  {
    tambon: "หนองแซง",
    amphoe: "บ้านแฮด",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401012,
    amphoe_code: 4024,
    province_code: 40,
  },
  {
    tambon: "โคกสำราญ",
    amphoe: "บ้านแฮด",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401004,
    amphoe_code: 4024,
    province_code: 40,
  },
  {
    tambon: "โนนสมบูรณ์",
    amphoe: "บ้านแฮด",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401015,
    amphoe_code: 4024,
    province_code: 40,
  },
  {
    tambon: "บ้านลาน",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401009,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "บ้านไผ่",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401001,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "ป่าปอ",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401013,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "ภูเหล็ก",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401011,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "หนองน้ำใส",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401016,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "หัวหนอง",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401017,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "หินตั้ง",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401014,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "เมืองเพีย",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401005,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "แคนเหนือ",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401010,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "ในเมือง",
    amphoe: "บ้านไผ่",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 400101,
    amphoe_code: 4010,
    province_code: 40,
  },
  {
    tambon: "ขามป้อม",
    amphoe: "พระยืน",
    province: "ขอนแก่น",
    zipcode: 40320,
    district_code: 400305,
    amphoe_code: 4003,
    province_code: 40,
  },
  {
    tambon: "บ้านโต้น",
    amphoe: "พระยืน",
    province: "ขอนแก่น",
    zipcode: 40320,
    district_code: 400198,
    amphoe_code: 4003,
    province_code: 40,
  },
  {
    tambon: "พระบุ",
    amphoe: "พระยืน",
    province: "ขอนแก่น",
    zipcode: 40320,
    district_code: 400302,
    amphoe_code: 4003,
    province_code: 40,
  },
  {
    tambon: "พระยืน",
    amphoe: "พระยืน",
    province: "ขอนแก่น",
    zipcode: 40320,
    district_code: 400301,
    amphoe_code: 4003,
    province_code: 40,
  },
  {
    tambon: "หนองแวง",
    amphoe: "พระยืน",
    province: "ขอนแก่น",
    zipcode: 40320,
    district_code: 400304,
    amphoe_code: 4003,
    province_code: 40,
  },
  {
    tambon: "ลอมคอม",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401210,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "หนองมะเขือ",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401205,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "หนองแวงนางเบ้า",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401209,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "หนองแวงโสกพระ",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401206,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "หัวทุ่ง",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401213,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "เก่างิ้ว",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401204,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "เพ็กใหญ่",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401207,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "เมืองพล",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401201,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "โคกสง่า",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401208,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "โจดหนองแก",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401203,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "โนนข่า",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401211,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "โสกนกเต็น",
    amphoe: "พล",
    province: "ขอนแก่น",
    zipcode: 40120,
    district_code: 401212,
    amphoe_code: 4012,
    province_code: 40,
  },
  {
    tambon: "นาฝาย",
    amphoe: "ภูผาม่าน",
    province: "ขอนแก่น",
    zipcode: 40350,
    district_code: 402002,
    amphoe_code: 4020,
    province_code: 40,
  },
  {
    tambon: "ภูผาม่าน",
    amphoe: "ภูผาม่าน",
    province: "ขอนแก่น",
    zipcode: 40350,
    district_code: 402003,
    amphoe_code: 4020,
    province_code: 40,
  },
  {
    tambon: "วังสวาบ",
    amphoe: "ภูผาม่าน",
    province: "ขอนแก่น",
    zipcode: 40350,
    district_code: 402004,
    amphoe_code: 4020,
    province_code: 40,
  },
  {
    tambon: "ห้วยม่วง",
    amphoe: "ภูผาม่าน",
    province: "ขอนแก่น",
    zipcode: 40350,
    district_code: 402005,
    amphoe_code: 4020,
    province_code: 40,
  },
  {
    tambon: "โนนคอม",
    amphoe: "ภูผาม่าน",
    province: "ขอนแก่น",
    zipcode: 40350,
    district_code: 402001,
    amphoe_code: 4020,
    province_code: 40,
  },
  {
    tambon: "กุดขอนแก่น",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401605,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "ดินดำ",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401616,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "ทุ่งชมพู",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401614,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "นาชุมแสง",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401606,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "นาหว้า",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401607,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "บ้านเรือ",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401601,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "ภูเวียง",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401617,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "สงเปือย",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401613,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "หนองกุงธนสาร",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401610,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "หนองกุงเซิน",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401612,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "หว้าทอง",
    amphoe: "ภูเวียง",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401604,
    amphoe_code: 4016,
    province_code: 40,
  },
  {
    tambon: "เขาน้อย",
    amphoe: "เวียงเก่า",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401608,
    amphoe_code: 4029,
    province_code: 40,
  },
  {
    tambon: "เมืองเก่าพัฒนา",
    amphoe: "เวียงเก่า",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401615,
    amphoe_code: 4029,
    province_code: 40,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เวียงเก่า",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 400101,
    amphoe_code: 4029,
    province_code: 40,
  },
  {
    tambon: "กุดเค้า",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401701,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "คำแคน",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401705,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "ท่าศาลา",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401710,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "นาข่า",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401706,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "นางาม",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401707,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "สวนหม่อน",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401702,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "หนองแปน",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401703,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "โพนเพ็ก",
    amphoe: "มัญจาคีรี",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401704,
    amphoe_code: 4017,
    province_code: 40,
  },
  {
    tambon: "ซำยาง",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400605,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "ดงลาน",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400607,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "นาจาน",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400603,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "บริบูรณ์",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400608,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400609,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "ภูห่าน",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400610,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "วังเพิ่ม",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400604,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "ศรีสุข",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400602,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "สีชมพู",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400601,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "หนองแดง",
    amphoe: "สีชมพู",
    province: "ขอนแก่น",
    zipcode: 40220,
    district_code: 400606,
    amphoe_code: 4006,
    province_code: 40,
  },
  {
    tambon: "กุดธาตุ",
    amphoe: "หนองนาคำ",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401609,
    amphoe_code: 4023,
    province_code: 40,
  },
  {
    tambon: "ขนวน",
    amphoe: "หนองนาคำ",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401611,
    amphoe_code: 4023,
    province_code: 40,
  },
  {
    tambon: "บ้านโคก",
    amphoe: "หนองนาคำ",
    province: "ขอนแก่น",
    zipcode: 40150,
    district_code: 401603,
    amphoe_code: 4023,
    province_code: 40,
  },
  {
    tambon: "คึมชาด",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401502,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "ดงเค็ง",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401508,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "ดอนดั่ง",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401510,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "ดอนดู่",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401507,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "ตะกั่วป่า",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401504,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "วังหิน",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401511,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "สำโรง",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401505,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "หนองสองห้อง",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401501,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "หนองเม็ก",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401506,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "หนองไผ่ล้อม",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401512,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "หันโจด",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401509,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "โนนธาตุ",
    amphoe: "หนองสองห้อง",
    province: "ขอนแก่น",
    zipcode: 40190,
    district_code: 401503,
    amphoe_code: 4015,
    province_code: 40,
  },
  {
    tambon: "กุดกว้าง",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40210,
    district_code: 400407,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "จระเข้",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40240,
    district_code: 400405,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "บ้านกง",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40240,
    district_code: 400403,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "บ้านผือ",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40240,
    district_code: 400410,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "บ้านเม็ง",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40210,
    district_code: 400402,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "ยางคำ",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40240,
    district_code: 400404,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "หนองเรือ",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40210,
    district_code: 400401,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "โนนทอง",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40210,
    district_code: 400406,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "โนนทัน",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40210,
    district_code: 400408,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "หนองเรือ",
    province: "ขอนแก่น",
    zipcode: 40210,
    district_code: 400409,
    amphoe_code: 4004,
    province_code: 40,
  },
  {
    tambon: "ทุ่งโป่ง",
    amphoe: "อุบลรัตน์",
    province: "ขอนแก่น",
    zipcode: 40250,
    district_code: 400806,
    amphoe_code: 4008,
    province_code: 40,
  },
  {
    tambon: "นาคำ",
    amphoe: "อุบลรัตน์",
    province: "ขอนแก่น",
    zipcode: 40250,
    district_code: 400804,
    amphoe_code: 4008,
    province_code: 40,
  },
  {
    tambon: "บ้านดง",
    amphoe: "อุบลรัตน์",
    province: "ขอนแก่น",
    zipcode: 40250,
    district_code: 400802,
    amphoe_code: 4008,
    province_code: 40,
  },
  {
    tambon: "ศรีสุขสำราญ",
    amphoe: "อุบลรัตน์",
    province: "ขอนแก่น",
    zipcode: 40250,
    district_code: 400805,
    amphoe_code: 4008,
    province_code: 40,
  },
  {
    tambon: "เขื่อนอุบลรัตน์",
    amphoe: "อุบลรัตน์",
    province: "ขอนแก่น",
    zipcode: 40250,
    district_code: 400803,
    amphoe_code: 4008,
    province_code: 40,
  },
  {
    tambon: "โคกสูง",
    amphoe: "อุบลรัตน์",
    province: "ขอนแก่น",
    zipcode: 40250,
    district_code: 400801,
    amphoe_code: 4008,
    province_code: 40,
  },
  {
    tambon: "คำม่วง",
    amphoe: "เขาสวนกวาง",
    province: "ขอนแก่น",
    zipcode: 40280,
    district_code: 401905,
    amphoe_code: 4019,
    province_code: 40,
  },
  {
    tambon: "ดงเมืองแอม",
    amphoe: "เขาสวนกวาง",
    province: "ขอนแก่น",
    zipcode: 40280,
    district_code: 401902,
    amphoe_code: 4019,
    province_code: 40,
  },
  {
    tambon: "นางิ้ว",
    amphoe: "เขาสวนกวาง",
    province: "ขอนแก่น",
    zipcode: 40280,
    district_code: 401903,
    amphoe_code: 4019,
    province_code: 40,
  },
  {
    tambon: "เขาสวนกวาง",
    amphoe: "เขาสวนกวาง",
    province: "ขอนแก่น",
    zipcode: 40280,
    district_code: 401901,
    amphoe_code: 4019,
    province_code: 40,
  },
  {
    tambon: "โนนสมบูรณ์",
    amphoe: "เขาสวนกวาง",
    province: "ขอนแก่น",
    zipcode: 40280,
    district_code: 401015,
    amphoe_code: 4019,
    province_code: 40,
  },
  {
    tambon: "ขามป้อม",
    amphoe: "เปือยน้อย",
    province: "ขอนแก่น",
    zipcode: 40340,
    district_code: 400305,
    amphoe_code: 4011,
    province_code: 40,
  },
  {
    tambon: "วังม่วง",
    amphoe: "เปือยน้อย",
    province: "ขอนแก่น",
    zipcode: 40340,
    district_code: 401098,
    amphoe_code: 4011,
    province_code: 40,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "เปือยน้อย",
    province: "ขอนแก่น",
    zipcode: 40340,
    district_code: 401096,
    amphoe_code: 4011,
    province_code: 40,
  },
  {
    tambon: "เปือยน้อย",
    amphoe: "เปือยน้อย",
    province: "ขอนแก่น",
    zipcode: 40340,
    district_code: 401099,
    amphoe_code: 4011,
    province_code: 40,
  },
  {
    tambon: "ดอนช้าง",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400112,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "ดอนหัน",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40260,
    district_code: 400113,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "ท่าพระ",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40260,
    district_code: 400104,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "บึงเนียม",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400117,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "บ้านค้อ",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400110,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "บ้านทุ่ม",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400105,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "บ้านหว้า",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400109,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "บ้านเป็ด",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400115,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "พระลับ",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400107,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "ศิลา",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400114,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "สาวะถี",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400108,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "สำราญ",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400102,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "หนองตูม",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400116,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "เมืองเก่า",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400106,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "แดงใหญ่",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400111,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "โคกสี",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400103,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "โนนท่อน",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400118,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40000,
    district_code: 400101,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "มหาวิทยาลัยขอนแก่น",
    amphoe: "เมืองขอนแก่น",
    province: "ขอนแก่น",
    zipcode: 40002,
    district_code: 400101,
    amphoe_code: 4001,
    province_code: 40,
  },
  {
    tambon: "ก้านเหลือง",
    amphoe: "แวงน้อย",
    province: "ขอนแก่น",
    zipcode: 40230,
    district_code: 401294,
    amphoe_code: 4014,
    province_code: 40,
  },
  {
    tambon: "ทางขวาง",
    amphoe: "แวงน้อย",
    province: "ขอนแก่น",
    zipcode: 40230,
    district_code: 401290,
    amphoe_code: 4014,
    province_code: 40,
  },
  {
    tambon: "ท่านางแนว",
    amphoe: "แวงน้อย",
    province: "ขอนแก่น",
    zipcode: 40230,
    district_code: 401403,
    amphoe_code: 4014,
    province_code: 40,
  },
  {
    tambon: "ท่าวัด",
    amphoe: "แวงน้อย",
    province: "ขอนแก่น",
    zipcode: 40230,
    district_code: 401291,
    amphoe_code: 4014,
    province_code: 40,
  },
  {
    tambon: "ละหานนา",
    amphoe: "แวงน้อย",
    province: "ขอนแก่น",
    zipcode: 40230,
    district_code: 401404,
    amphoe_code: 4014,
    province_code: 40,
  },
  {
    tambon: "แวงน้อย",
    amphoe: "แวงน้อย",
    province: "ขอนแก่น",
    zipcode: 40230,
    district_code: 401293,
    amphoe_code: 4014,
    province_code: 40,
  },
  {
    tambon: "คอนฉิม",
    amphoe: "แวงใหญ่",
    province: "ขอนแก่น",
    zipcode: 40330,
    district_code: 401299,
    amphoe_code: 4013,
    province_code: 40,
  },
  {
    tambon: "แวงใหญ่",
    amphoe: "แวงใหญ่",
    province: "ขอนแก่น",
    zipcode: 40330,
    district_code: 401296,
    amphoe_code: 4013,
    province_code: 40,
  },
  {
    tambon: "โนนทอง",
    amphoe: "แวงใหญ่",
    province: "ขอนแก่น",
    zipcode: 40330,
    district_code: 400406,
    amphoe_code: 4013,
    province_code: 40,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "แวงใหญ่",
    province: "ขอนแก่น",
    zipcode: 40330,
    district_code: 400409,
    amphoe_code: 4013,
    province_code: 40,
  },
  {
    tambon: "ใหม่นาเพียง",
    amphoe: "แวงใหญ่",
    province: "ขอนแก่น",
    zipcode: 40330,
    district_code: 401298,
    amphoe_code: 4013,
    province_code: 40,
  },
  {
    tambon: "ซับสมบูรณ์",
    amphoe: "โคกโพธิ์ไชย",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401711,
    amphoe_code: 4022,
    province_code: 40,
  },
  {
    tambon: "นาแพง",
    amphoe: "โคกโพธิ์ไชย",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401712,
    amphoe_code: 4022,
    province_code: 40,
  },
  {
    tambon: "บ้านโคก",
    amphoe: "โคกโพธิ์ไชย",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401603,
    amphoe_code: 4022,
    province_code: 40,
  },
  {
    tambon: "โพธิ์ไชย",
    amphoe: "โคกโพธิ์ไชย",
    province: "ขอนแก่น",
    zipcode: 40160,
    district_code: 401709,
    amphoe_code: 4022,
    province_code: 40,
  },
  {
    tambon: "บ้านหัน",
    amphoe: "โนนศิลา",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401008,
    amphoe_code: 4025,
    province_code: 40,
  },
  {
    tambon: "หนองปลาหมอ",
    amphoe: "โนนศิลา",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401020,
    amphoe_code: 4025,
    province_code: 40,
  },
  {
    tambon: "เปือยใหญ่",
    amphoe: "โนนศิลา",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401006,
    amphoe_code: 4025,
    province_code: 40,
  },
  {
    tambon: "โนนศิลา",
    amphoe: "โนนศิลา",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401007,
    amphoe_code: 4025,
    province_code: 40,
  },
  {
    tambon: "โนนแดง",
    amphoe: "โนนศิลา",
    province: "ขอนแก่น",
    zipcode: 40110,
    district_code: 401019,
    amphoe_code: 4025,
    province_code: 40,
  },
  {
    tambon: "ขลุง",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220201,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "ซึ้ง",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220207,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "ตกพรม",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220211,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "ตรอกนอง",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220210,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "ตะปอน",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220204,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "บางชัน",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220205,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "บ่อ",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220202,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "บ่อเวฬุ",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22150,
    district_code: 220212,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "มาบไพ",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220208,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "วังสรรพรส",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220209,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "วันยาว",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220206,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "เกวียนหัก",
    amphoe: "ขลุง",
    province: "จันทบุรี",
    zipcode: 22110,
    district_code: 220203,
    amphoe_code: 2202,
    province_code: 22,
  },
  {
    tambon: "คลองขุด",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220314,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "ตะกาดเง้า",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220313,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "ทุ่งเบญจา",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220309,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "ท่าใหม่",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220301,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "บ่อพุ",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220304,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "พลอยแหวน",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220305,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "ยายร้า",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220302,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "รำพัน",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220311,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "สองพี่น้อง",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220308,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "สีพยา",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220303,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "เขาบายศรี",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220307,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "เขาวัว",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220306,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "เขาแก้ว",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220324,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "โขมง",
    amphoe: "ท่าใหม่",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220312,
    amphoe_code: 2203,
    province_code: 22,
  },
  {
    tambon: "กระแจะ",
    amphoe: "นายายอาม",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220315,
    amphoe_code: 2209,
    province_code: 22,
  },
  {
    tambon: "ช้างข้าม",
    amphoe: "นายายอาม",
    province: "จันทบุรี",
    zipcode: 22160,
    district_code: 220317,
    amphoe_code: 2209,
    province_code: 22,
  },
  {
    tambon: "นายายอาม",
    amphoe: "นายายอาม",
    province: "จันทบุรี",
    zipcode: 22160,
    district_code: 220318,
    amphoe_code: 2209,
    province_code: 22,
  },
  {
    tambon: "วังโตนด",
    amphoe: "นายายอาม",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220310,
    amphoe_code: 2209,
    province_code: 22,
  },
  {
    tambon: "วังใหม่",
    amphoe: "นายายอาม",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220906,
    amphoe_code: 2209,
    province_code: 22,
  },
  {
    tambon: "สนามไชย",
    amphoe: "นายายอาม",
    province: "จันทบุรี",
    zipcode: 22170,
    district_code: 220316,
    amphoe_code: 2209,
    province_code: 22,
  },
  {
    tambon: "ฉมัน",
    amphoe: "มะขาม",
    province: "จันทบุรี",
    zipcode: 22150,
    district_code: 220506,
    amphoe_code: 2205,
    province_code: 22,
  },
  {
    tambon: "ท่าหลวง",
    amphoe: "มะขาม",
    province: "จันทบุรี",
    zipcode: 22150,
    district_code: 220502,
    amphoe_code: 2205,
    province_code: 22,
  },
  {
    tambon: "ปัถวี",
    amphoe: "มะขาม",
    province: "จันทบุรี",
    zipcode: 22150,
    district_code: 220503,
    amphoe_code: 2205,
    province_code: 22,
  },
  {
    tambon: "มะขาม",
    amphoe: "มะขาม",
    province: "จันทบุรี",
    zipcode: 22150,
    district_code: 220501,
    amphoe_code: 2205,
    province_code: 22,
  },
  {
    tambon: "วังแซ้ม",
    amphoe: "มะขาม",
    province: "จันทบุรี",
    zipcode: 22150,
    district_code: 220504,
    amphoe_code: 2205,
    province_code: 22,
  },
  {
    tambon: "อ่างคีรี",
    amphoe: "มะขาม",
    province: "จันทบุรี",
    zipcode: 22150,
    district_code: 220508,
    amphoe_code: 2205,
    province_code: 22,
  },
  {
    tambon: "ทรายขาว",
    amphoe: "สอยดาว",
    province: "จันทบุรี",
    zipcode: 22180,
    district_code: 220403,
    amphoe_code: 2207,
    province_code: 22,
  },
  {
    tambon: "ทับช้าง",
    amphoe: "สอยดาว",
    province: "จันทบุรี",
    zipcode: 22180,
    district_code: 220408,
    amphoe_code: 2207,
    province_code: 22,
  },
  {
    tambon: "ทุ่งขนาน",
    amphoe: "สอยดาว",
    province: "จันทบุรี",
    zipcode: 22180,
    district_code: 220406,
    amphoe_code: 2207,
    province_code: 22,
  },
  {
    tambon: "ปะตง",
    amphoe: "สอยดาว",
    province: "จันทบุรี",
    zipcode: 22180,
    district_code: 220405,
    amphoe_code: 2207,
    province_code: 22,
  },
  {
    tambon: "สะตอน",
    amphoe: "สอยดาว",
    province: "จันทบุรี",
    zipcode: 22180,
    district_code: 220407,
    amphoe_code: 2207,
    province_code: 22,
  },
  {
    tambon: "คลองพลู",
    amphoe: "เขาคิชฌกูฏ",
    province: "จันทบุรี",
    zipcode: 22210,
    district_code: 220509,
    amphoe_code: 2210,
    province_code: 22,
  },
  {
    tambon: "จันทเขลม",
    amphoe: "เขาคิชฌกูฏ",
    province: "จันทบุรี",
    zipcode: 22210,
    district_code: 221005,
    amphoe_code: 2210,
    province_code: 22,
  },
  {
    tambon: "ชากไทย",
    amphoe: "เขาคิชฌกูฏ",
    province: "จันทบุรี",
    zipcode: 22210,
    district_code: 221001,
    amphoe_code: 2210,
    province_code: 22,
  },
  {
    tambon: "ตะเคียนทอง",
    amphoe: "เขาคิชฌกูฏ",
    province: "จันทบุรี",
    zipcode: 22210,
    district_code: 220507,
    amphoe_code: 2210,
    province_code: 22,
  },
  {
    tambon: "พลวง",
    amphoe: "เขาคิชฌกูฏ",
    province: "จันทบุรี",
    zipcode: 22210,
    district_code: 220505,
    amphoe_code: 2210,
    province_code: 22,
  },
  {
    tambon: "คมบาง",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220105,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "คลองนารายณ์",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220103,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "จันทนิมิต",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220107,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "ตลาด",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220101,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220106,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "บางกะจะ",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220108,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "พลับพลา",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220111,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "วัดใหม่",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220102,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "หนองบัว",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220110,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "เกาะขวาง",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220104,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "แสลง",
    amphoe: "เมืองจันทบุรี",
    province: "จันทบุรี",
    zipcode: 22000,
    district_code: 220109,
    amphoe_code: 2201,
    province_code: 22,
  },
  {
    tambon: "ขุนซ่อง",
    amphoe: "แก่งหางแมว",
    province: "จันทบุรี",
    zipcode: 22160,
    district_code: 220323,
    amphoe_code: 2208,
    province_code: 22,
  },
  {
    tambon: "พวา",
    amphoe: "แก่งหางแมว",
    province: "จันทบุรี",
    zipcode: 22160,
    district_code: 220322,
    amphoe_code: 2208,
    province_code: 22,
  },
  {
    tambon: "สามพี่น้อง",
    amphoe: "แก่งหางแมว",
    province: "จันทบุรี",
    zipcode: 22160,
    district_code: 220320,
    amphoe_code: 2208,
    province_code: 22,
  },
  {
    tambon: "เขาวงกต",
    amphoe: "แก่งหางแมว",
    province: "จันทบุรี",
    zipcode: 22160,
    district_code: 220321,
    amphoe_code: 2208,
    province_code: 22,
  },
  {
    tambon: "แก่งหางแมว",
    amphoe: "แก่งหางแมว",
    province: "จันทบุรี",
    zipcode: 22160,
    district_code: 220319,
    amphoe_code: 2208,
    province_code: 22,
  },
  {
    tambon: "คลองน้ำเค็ม",
    amphoe: "แหลมสิงห์",
    province: "จันทบุรี",
    zipcode: 22190,
    district_code: 220605,
    amphoe_code: 2206,
    province_code: 22,
  },
  {
    tambon: "บางกะไชย",
    amphoe: "แหลมสิงห์",
    province: "จันทบุรี",
    zipcode: 22120,
    district_code: 220607,
    amphoe_code: 2206,
    province_code: 22,
  },
  {
    tambon: "บางสระเก้า",
    amphoe: "แหลมสิงห์",
    province: "จันทบุรี",
    zipcode: 22190,
    district_code: 220606,
    amphoe_code: 2206,
    province_code: 22,
  },
  {
    tambon: "ปากน้ำแหลมสิงห์",
    amphoe: "แหลมสิงห์",
    province: "จันทบุรี",
    zipcode: 22130,
    district_code: 220601,
    amphoe_code: 2206,
    province_code: 22,
  },
  {
    tambon: "พลิ้ว",
    amphoe: "แหลมสิงห์",
    province: "จันทบุรี",
    zipcode: 22190,
    district_code: 220604,
    amphoe_code: 2206,
    province_code: 22,
  },
  {
    tambon: "หนองชิ่ม",
    amphoe: "แหลมสิงห์",
    province: "จันทบุรี",
    zipcode: 22130,
    district_code: 220603,
    amphoe_code: 2206,
    province_code: 22,
  },
  {
    tambon: "เกาะเปริด",
    amphoe: "แหลมสิงห์",
    province: "จันทบุรี",
    zipcode: 22130,
    district_code: 220602,
    amphoe_code: 2206,
    province_code: 22,
  },
  {
    tambon: "คลองใหญ่",
    amphoe: "โป่งน้ำร้อน",
    province: "จันทบุรี",
    zipcode: 22140,
    district_code: 220410,
    amphoe_code: 2204,
    province_code: 22,
  },
  {
    tambon: "ทับไทร",
    amphoe: "โป่งน้ำร้อน",
    province: "จันทบุรี",
    zipcode: 22140,
    district_code: 220401,
    amphoe_code: 2204,
    province_code: 22,
  },
  {
    tambon: "หนองตาคง",
    amphoe: "โป่งน้ำร้อน",
    province: "จันทบุรี",
    zipcode: 22140,
    district_code: 220404,
    amphoe_code: 2204,
    province_code: 22,
  },
  {
    tambon: "เทพนิมิต",
    amphoe: "โป่งน้ำร้อน",
    province: "จันทบุรี",
    zipcode: 22140,
    district_code: 220409,
    amphoe_code: 2204,
    province_code: 22,
  },
  {
    tambon: "โป่งน้ำร้อน",
    amphoe: "โป่งน้ำร้อน",
    province: "จันทบุรี",
    zipcode: 22140,
    district_code: 220402,
    amphoe_code: 2204,
    province_code: 22,
  },
  {
    tambon: "ก้อนแก้ว",
    amphoe: "คลองเขื่อน",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240202,
    amphoe_code: 2411,
    province_code: 24,
  },
  {
    tambon: "คลองเขื่อน",
    amphoe: "คลองเขื่อน",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240203,
    amphoe_code: 2411,
    province_code: 24,
  },
  {
    tambon: "บางตลาด",
    amphoe: "คลองเขื่อน",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240207,
    amphoe_code: 2411,
    province_code: 24,
  },
  {
    tambon: "บางเล่า",
    amphoe: "คลองเขื่อน",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240205,
    amphoe_code: 2411,
    province_code: 24,
  },
  {
    tambon: "บางโรง",
    amphoe: "คลองเขื่อน",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240206,
    amphoe_code: 2411,
    province_code: 24,
  },
  {
    tambon: "คลองตะเกรา",
    amphoe: "ท่าตะเกียบ",
    province: "ฉะเชิงเทรา",
    zipcode: 24160,
    district_code: 240806,
    amphoe_code: 2410,
    province_code: 24,
  },
  {
    tambon: "ท่าตะเกียบ",
    amphoe: "ท่าตะเกียบ",
    province: "ฉะเชิงเทรา",
    zipcode: 24160,
    district_code: 240804,
    amphoe_code: 2410,
    province_code: 24,
  },
  {
    tambon: "ท่าทองหลาง",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240210,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "บางกระเจ็ด",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240208,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "บางคล้า",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240201,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "บางสวน",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240204,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240209,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "สาวชะโงก",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240211,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "หัวไทร",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240214,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "เสม็ดเหนือ",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240212,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "เสม็ดใต้",
    amphoe: "บางคล้า",
    province: "ฉะเชิงเทรา",
    zipcode: 24110,
    district_code: 240213,
    amphoe_code: 2402,
    province_code: 24,
  },
  {
    tambon: "ดอนฉิมพลี",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24170,
    district_code: 240308,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "ดอนเกาะกา",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24170,
    district_code: 240306,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "บางขนาก",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24150,
    district_code: 240302,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "บางน้ำเปรี้ยว",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24150,
    district_code: 240301,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "บึงน้ำรักษ์",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24170,
    district_code: 240305,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "ศาลาแดง",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240309,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "สิงโตทอง",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24150,
    district_code: 240303,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "หมอนทอง",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24150,
    district_code: 240304,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "โพรงอากาศ",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24150,
    district_code: 240310,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "โยธะกา",
    amphoe: "บางน้ำเปรี้ยว",
    province: "ฉะเชิงเทรา",
    zipcode: 24150,
    district_code: 240307,
    amphoe_code: 2403,
    province_code: 24,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240410,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "ท่าสะอ้าน",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240402,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "บางปะกง",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240401,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "บางผึ้ง",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240405,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "บางวัว",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240403,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "บางสมัคร",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24180,
    district_code: 240404,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "บางเกลือ",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24180,
    district_code: 240406,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "พิมพา",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24180,
    district_code: 240409,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "สองคลอง",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240407,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "หนองจอก",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240408,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "หอมศีล",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24180,
    district_code: 240411,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "เขาดิน",
    amphoe: "บางปะกง",
    province: "ฉะเชิงเทรา",
    zipcode: 24130,
    district_code: 240412,
    amphoe_code: 2404,
    province_code: 24,
  },
  {
    tambon: "คลองขุด",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240503,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "คลองบ้านโพธิ์",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240504,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "คลองประเวศ",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240505,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "ดอนทราย",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240506,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "ท่าพลับ",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240508,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "บางกรูด",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240512,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "บางซ่อน",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240511,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "บ้านโพธิ์",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240501,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "ลาดขวาง",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240514,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "สนามจันทร์",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240515,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "สิบเอ็ดศอก",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240517,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "หนองตีนนก",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240509,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "หนองบัว",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240510,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "เกาะไร่",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240502,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "เทพราช",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240507,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "แสนภูดาษ",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240516,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "แหลมประดู่",
    amphoe: "บ้านโพธิ์",
    province: "ฉะเชิงเทรา",
    zipcode: 24140,
    district_code: 240513,
    amphoe_code: 2405,
    province_code: 24,
  },
  {
    tambon: "ท่าถ่าน",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240606,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "บ้านซ่อง",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240602,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "พนมสารคาม",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240603,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "หนองยาว",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240605,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "หนองแหน",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240607,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "เกาะขนุน",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240601,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "เขาหินซ้อน",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240608,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "เมืองเก่า",
    amphoe: "พนมสารคาม",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240604,
    amphoe_code: 2406,
    province_code: 24,
  },
  {
    tambon: "ดงน้อย",
    amphoe: "ราชสาส์น",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240703,
    amphoe_code: 2407,
    province_code: 24,
  },
  {
    tambon: "บางคา",
    amphoe: "ราชสาส์น",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240701,
    amphoe_code: 2407,
    province_code: 24,
  },
  {
    tambon: "เมืองใหม่",
    amphoe: "ราชสาส์น",
    province: "ฉะเชิงเทรา",
    zipcode: 24120,
    district_code: 240702,
    amphoe_code: 2407,
    province_code: 24,
  },
  {
    tambon: "คู้ยายหมี",
    amphoe: "สนามชัยเขต",
    province: "ฉะเชิงเทรา",
    zipcode: 24160,
    district_code: 240801,
    amphoe_code: 2408,
    province_code: 24,
  },
  {
    tambon: "ทุ่งพระยา",
    amphoe: "สนามชัยเขต",
    province: "ฉะเชิงเทรา",
    zipcode: 24160,
    district_code: 240803,
    amphoe_code: 2408,
    province_code: 24,
  },
  {
    tambon: "ท่ากระดาน",
    amphoe: "สนามชัยเขต",
    province: "ฉะเชิงเทรา",
    zipcode: 24160,
    district_code: 240802,
    amphoe_code: 2408,
    province_code: 24,
  },
  {
    tambon: "ลาดกระทิง",
    amphoe: "สนามชัยเขต",
    province: "ฉะเชิงเทรา",
    zipcode: 24160,
    district_code: 240805,
    amphoe_code: 2408,
    province_code: 24,
  },
  {
    tambon: "คลองจุกกระเฌอ",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240107,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "คลองนครเนื่องเขต",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240110,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "คลองนา",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240104,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "คลองหลวงแพ่ง",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240118,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "คลองอุดมชลจร",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240117,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "คลองเปรง",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240116,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "ท่าไข่",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240102,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บางกะไห",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240114,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บางขวัญ",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240109,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บางตีนเป็ด",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240105,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บางพระ",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240113,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บางเตย",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240119,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240108,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บางไผ่",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240106,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240103,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "วังตะเคียน",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240111,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "หนามแดง",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240115,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "หน้าเมือง",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240101,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "โสธร",
    amphoe: "เมืองฉะเชิงเทรา",
    province: "ฉะเชิงเทรา",
    zipcode: 24000,
    district_code: 240112,
    amphoe_code: 2401,
    province_code: 24,
  },
  {
    tambon: "วังเย็น",
    amphoe: "แปลงยาว",
    province: "ฉะเชิงเทรา",
    zipcode: 24190,
    district_code: 240902,
    amphoe_code: 2409,
    province_code: 24,
  },
  {
    tambon: "หนองไม้แก่น",
    amphoe: "แปลงยาว",
    province: "ฉะเชิงเทรา",
    zipcode: 24190,
    district_code: 240904,
    amphoe_code: 2409,
    province_code: 24,
  },
  {
    tambon: "หัวสำโรง",
    amphoe: "แปลงยาว",
    province: "ฉะเชิงเทรา",
    zipcode: 24190,
    district_code: 240903,
    amphoe_code: 2409,
    province_code: 24,
  },
  {
    tambon: "แปลงยาว",
    amphoe: "แปลงยาว",
    province: "ฉะเชิงเทรา",
    zipcode: 24190,
    district_code: 240901,
    amphoe_code: 2409,
    province_code: 24,
  },
  {
    tambon: "ตะเคียนเตี้ย",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200407,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "นาเกลือ",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200408,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "บางละมุง",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200401,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "หนองปรือ",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200402,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "หนองปลาไหล",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200403,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "ห้วยใหญ่",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200406,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "เขาไม้แก้ว",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200405,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "โป่ง",
    amphoe: "บางละมุง",
    province: "ชลบุรี",
    zipcode: 20150,
    district_code: 200404,
    amphoe_code: 2004,
    province_code: 20,
  },
  {
    tambon: "ธาตุทอง",
    amphoe: "บ่อทอง",
    province: "ชลบุรี",
    zipcode: 20270,
    district_code: 201004,
    amphoe_code: 2010,
    province_code: 20,
  },
  {
    tambon: "บ่อกวางทอง",
    amphoe: "บ่อทอง",
    province: "ชลบุรี",
    zipcode: 20270,
    district_code: 200697,
    amphoe_code: 2010,
    province_code: 20,
  },
  {
    tambon: "บ่อทอง",
    amphoe: "บ่อทอง",
    province: "ชลบุรี",
    zipcode: 20270,
    district_code: 200699,
    amphoe_code: 2010,
    province_code: 20,
  },
  {
    tambon: "พลวงทอง",
    amphoe: "บ่อทอง",
    province: "ชลบุรี",
    zipcode: 20270,
    district_code: 201006,
    amphoe_code: 2010,
    province_code: 20,
  },
  {
    tambon: "วัดสุวรรณ",
    amphoe: "บ่อทอง",
    province: "ชลบุรี",
    zipcode: 20270,
    district_code: 200698,
    amphoe_code: 2010,
    province_code: 20,
  },
  {
    tambon: "เกษตรสุวรรณ",
    amphoe: "บ่อทอง",
    province: "ชลบุรี",
    zipcode: 20270,
    district_code: 201005,
    amphoe_code: 2010,
    province_code: 20,
  },
  {
    tambon: "คลองกิ่ว",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20220,
    district_code: 200202,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "บ้านบึง",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20170,
    district_code: 200201,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "มาบไผ่",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20170,
    district_code: 200203,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "หนองชาก",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20170,
    district_code: 200206,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "หนองซ้ำซาก",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20170,
    district_code: 200204,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "หนองบอนแดง",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20170,
    district_code: 200205,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "หนองอิรุณ",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20220,
    district_code: 200207,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "หนองไผ่แก้ว",
    amphoe: "บ้านบึง",
    province: "ชลบุรี",
    zipcode: 20220,
    district_code: 200208,
    amphoe_code: 2002,
    province_code: 20,
  },
  {
    tambon: "กุฎโง้ง",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200609,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "ทุ่งขวาง",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200615,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200611,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "นามะตูม",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200622,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "นาวังหิน",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200617,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "นาเริก",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200605,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "บ้านช้าง",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200618,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "บ้านเซิด",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200604,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "พนัสนิคม",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200601,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "วัดหลวง",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200603,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "วัดโบสถ์",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200608,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "สระสี่เหลี่ยม",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200607,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "หนองขยาด",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200614,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "หนองปรือ",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200402,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "หนองเหียง",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200616,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "หน้าพระธาตุ",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200602,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "หมอนนาง",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200606,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "หัวถนน",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200610,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "โคกเพลาะ",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200620,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "ไร่หลักทอง",
    amphoe: "พนัสนิคม",
    province: "ชลบุรี",
    zipcode: 20140,
    district_code: 200621,
    amphoe_code: 2006,
    province_code: 20,
  },
  {
    tambon: "บางนาง",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200509,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "บางหัก",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200511,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "บ้านเก่า",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200507,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "พานทอง",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200501,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "มาบโป่ง",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200503,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "หนองกะขะ",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200504,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "หนองตำลึง",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200502,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "หนองหงษ์",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200505,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "หน้าประดู่",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200508,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "เกาะลอย",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200510,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "โคกขี้หนอน",
    amphoe: "พานทอง",
    province: "ชลบุรี",
    zipcode: 20160,
    district_code: 200506,
    amphoe_code: 2005,
    province_code: 20,
  },
  {
    tambon: "ทุ่งสุขลา",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20230,
    district_code: 200703,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "บางพระ",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20110,
    district_code: 200707,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "บึง",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20230,
    district_code: 200704,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "บ่อวิน",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20230,
    district_code: 200708,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "ศรีราชา",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20110,
    district_code: 200701,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "สุรศักดิ์",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20110,
    district_code: 200702,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "หนองขาม",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20230,
    district_code: 200705,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "เขาคันทรง",
    amphoe: "ศรีราชา",
    province: "ชลบุรี",
    zipcode: 20110,
    district_code: 200706,
    amphoe_code: 2007,
    province_code: 20,
  },
  {
    tambon: "นาจอมเทียน",
    amphoe: "สัตหีบ",
    province: "ชลบุรี",
    zipcode: 20250,
    district_code: 200902,
    amphoe_code: 2009,
    province_code: 20,
  },
  {
    tambon: "บางเสร่",
    amphoe: "สัตหีบ",
    province: "ชลบุรี",
    zipcode: 20250,
    district_code: 200904,
    amphoe_code: 2009,
    province_code: 20,
  },
  {
    tambon: "พลูตาหลวง",
    amphoe: "สัตหีบ",
    province: "ชลบุรี",
    zipcode: 20180,
    district_code: 200903,
    amphoe_code: 2009,
    province_code: 20,
  },
  {
    tambon: "สัตหีบ",
    amphoe: "สัตหีบ",
    province: "ชลบุรี",
    zipcode: 20180,
    district_code: 200901,
    amphoe_code: 2009,
    province_code: 20,
  },
  {
    tambon: "แสมสาร",
    amphoe: "สัตหีบ",
    province: "ชลบุรี",
    zipcode: 20180,
    district_code: 200905,
    amphoe_code: 2009,
    province_code: 20,
  },
  {
    tambon: "คลองพลู",
    amphoe: "หนองใหญ่",
    province: "ชลบุรี",
    zipcode: 20190,
    district_code: 200298,
    amphoe_code: 2003,
    province_code: 20,
  },
  {
    tambon: "หนองเสือช้าง",
    amphoe: "หนองใหญ่",
    province: "ชลบุรี",
    zipcode: 20190,
    district_code: 200297,
    amphoe_code: 2003,
    province_code: 20,
  },
  {
    tambon: "หนองใหญ่",
    amphoe: "หนองใหญ่",
    province: "ชลบุรี",
    zipcode: 20190,
    district_code: 200299,
    amphoe_code: 2003,
    province_code: 20,
  },
  {
    tambon: "ห้างสูง",
    amphoe: "หนองใหญ่",
    province: "ชลบุรี",
    zipcode: 20190,
    district_code: 200304,
    amphoe_code: 2003,
    province_code: 20,
  },
  {
    tambon: "เขาซก",
    amphoe: "หนองใหญ่",
    province: "ชลบุรี",
    zipcode: 20190,
    district_code: 200305,
    amphoe_code: 2003,
    province_code: 20,
  },
  {
    tambon: "ท่าบุญมี",
    amphoe: "เกาะจันทร์",
    province: "ชลบุรี",
    zipcode: 20240,
    district_code: 200612,
    amphoe_code: 2011,
    province_code: 20,
  },
  {
    tambon: "เกาะจันทร์",
    amphoe: "เกาะจันทร์",
    province: "ชลบุรี",
    zipcode: 20240,
    district_code: 200619,
    amphoe_code: 2011,
    province_code: 20,
  },
  {
    tambon: "ท่าเทววงษ์",
    amphoe: "เกาะสีชัง",
    province: "ชลบุรี",
    zipcode: 20120,
    district_code: 200799,
    amphoe_code: 2008,
    province_code: 20,
  },
  {
    tambon: "คลองตำหรุ",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200112,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "ดอนหัวฬ่อ",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200109,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "นาป่า",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200107,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "บางทราย",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200111,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "บางปลาสร้อย",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200101,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "บ้านปึก",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20130,
    district_code: 200114,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "บ้านสวน",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200105,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "บ้านโขด",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200103,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "มะขามหย่ง",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200102,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "สำนักบก",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200118,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "หนองข้างคอก",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200108,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "หนองรี",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200106,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "หนองไม้แดง",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200110,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "ห้วยกะปิ",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200115,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "ห้วยกะปิ",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20130,
    district_code: 200115,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "อ่างศิลา",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200117,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "เสม็ด",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20000,
    district_code: 200116,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "เหมือง",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20130,
    district_code: 200113,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "แสนสุข",
    amphoe: "เมืองชลบุรี",
    province: "ชลบุรี",
    zipcode: 20130,
    district_code: 200104,
    amphoe_code: 2001,
    province_code: 20,
  },
  {
    tambon: "คุ้งสำเภา",
    amphoe: "มโนรมย์",
    province: "ชัยนาท",
    zipcode: 17110,
    district_code: 180201,
    amphoe_code: 1802,
    province_code: 18,
  },
  {
    tambon: "ท่าฉนวน",
    amphoe: "มโนรมย์",
    province: "ชัยนาท",
    zipcode: 17110,
    district_code: 180204,
    amphoe_code: 1802,
    province_code: 18,
  },
  {
    tambon: "วัดโคก",
    amphoe: "มโนรมย์",
    province: "ชัยนาท",
    zipcode: 17110,
    district_code: 180202,
    amphoe_code: 1802,
    province_code: 18,
  },
  {
    tambon: "ศิลาดาน",
    amphoe: "มโนรมย์",
    province: "ชัยนาท",
    zipcode: 17110,
    district_code: 180203,
    amphoe_code: 1802,
    province_code: 18,
  },
  {
    tambon: "หางน้ำสาคร",
    amphoe: "มโนรมย์",
    province: "ชัยนาท",
    zipcode: 17170,
    district_code: 180205,
    amphoe_code: 1802,
    province_code: 18,
  },
  {
    tambon: "อู่ตะเภา",
    amphoe: "มโนรมย์",
    province: "ชัยนาท",
    zipcode: 17170,
    district_code: 180207,
    amphoe_code: 1802,
    province_code: 18,
  },
  {
    tambon: "ไร่พัฒนา",
    amphoe: "มโนรมย์",
    province: "ชัยนาท",
    zipcode: 17170,
    district_code: 180206,
    amphoe_code: 1802,
    province_code: 18,
  },
  {
    tambon: "บ่อแร่",
    amphoe: "วัดสิงห์",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180307,
    amphoe_code: 1803,
    province_code: 18,
  },
  {
    tambon: "มะขามเฒ่า",
    amphoe: "วัดสิงห์",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180302,
    amphoe_code: 1803,
    province_code: 18,
  },
  {
    tambon: "วังหมัน",
    amphoe: "วัดสิงห์",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180311,
    amphoe_code: 1803,
    province_code: 18,
  },
  {
    tambon: "วัดสิงห์",
    amphoe: "วัดสิงห์",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180301,
    amphoe_code: 1803,
    province_code: 18,
  },
  {
    tambon: "หนองขุ่น",
    amphoe: "วัดสิงห์",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180306,
    amphoe_code: 1803,
    province_code: 18,
  },
  {
    tambon: "หนองน้อย",
    amphoe: "วัดสิงห์",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180303,
    amphoe_code: 1803,
    province_code: 18,
  },
  {
    tambon: "หนองบัว",
    amphoe: "วัดสิงห์",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180304,
    amphoe_code: 1803,
    province_code: 18,
  },
  {
    tambon: "ดงคอน",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180506,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "ดอนกำ",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180507,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "บางขุด",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180505,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "ห้วยกรด",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180503,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "ห้วยกรดพัฒนา",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180508,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "เที่ยงแท้",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180502,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "แพรกศรีราชา",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180501,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "โพงาม",
    amphoe: "สรรคบุรี",
    province: "ชัยนาท",
    zipcode: 17140,
    district_code: 180504,
    amphoe_code: 1805,
    province_code: 18,
  },
  {
    tambon: "ตลุก",
    amphoe: "สรรพยา",
    province: "ชัยนาท",
    zipcode: 17150,
    district_code: 180402,
    amphoe_code: 1804,
    province_code: 18,
  },
  {
    tambon: "บางหลวง",
    amphoe: "สรรพยา",
    province: "ชัยนาท",
    zipcode: 17150,
    district_code: 180406,
    amphoe_code: 1804,
    province_code: 18,
  },
  {
    tambon: "สรรพยา",
    amphoe: "สรรพยา",
    province: "ชัยนาท",
    zipcode: 17150,
    district_code: 180401,
    amphoe_code: 1804,
    province_code: 18,
  },
  {
    tambon: "หาดอาษา",
    amphoe: "สรรพยา",
    province: "ชัยนาท",
    zipcode: 17150,
    district_code: 180407,
    amphoe_code: 1804,
    province_code: 18,
  },
  {
    tambon: "เขาแก้ว",
    amphoe: "สรรพยา",
    province: "ชัยนาท",
    zipcode: 17150,
    district_code: 180403,
    amphoe_code: 1804,
    province_code: 18,
  },
  {
    tambon: "โพนางดำตก",
    amphoe: "สรรพยา",
    province: "ชัยนาท",
    zipcode: 17150,
    district_code: 180404,
    amphoe_code: 1804,
    province_code: 18,
  },
  {
    tambon: "โพนางดำออก",
    amphoe: "สรรพยา",
    province: "ชัยนาท",
    zipcode: 17150,
    district_code: 180405,
    amphoe_code: 1804,
    province_code: 18,
  },
  {
    tambon: "กุดจอก",
    amphoe: "หนองมะโมง",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180308,
    amphoe_code: 1807,
    province_code: 18,
  },
  {
    tambon: "วังตะเคียน",
    amphoe: "หนองมะโมง",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180309,
    amphoe_code: 1807,
    province_code: 18,
  },
  {
    tambon: "สะพานหิน",
    amphoe: "หนองมะโมง",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180310,
    amphoe_code: 1807,
    province_code: 18,
  },
  {
    tambon: "หนองมะโมง",
    amphoe: "หนองมะโมง",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180305,
    amphoe_code: 1807,
    province_code: 18,
  },
  {
    tambon: "บ้านเชี่ยน",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180602,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "วังไก่เถื่อน",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180608,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "สามง่ามท่าโบสถ์",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17160,
    district_code: 180611,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "หนองแซง",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17160,
    district_code: 180606,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "หันคา",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180601,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "ห้วยงู",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17160,
    district_code: 180607,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "เด่นใหญ่",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180609,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "ไพรนกยูง",
    amphoe: "หันคา",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180605,
    amphoe_code: 1806,
    province_code: 18,
  },
  {
    tambon: "กะบกเตี้ย",
    amphoe: "เนินขาม",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180610,
    amphoe_code: 1808,
    province_code: 18,
  },
  {
    tambon: "สุขเดือนห้า",
    amphoe: "เนินขาม",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180604,
    amphoe_code: 1808,
    province_code: 18,
  },
  {
    tambon: "เนินขาม",
    amphoe: "เนินขาม",
    province: "ชัยนาท",
    zipcode: 17130,
    district_code: 180603,
    amphoe_code: 1808,
    province_code: 18,
  },
  {
    tambon: "ชัยนาท",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180104,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "ท่าชัย",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180103,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "ธรรมามูล",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180107,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "นางลือ",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180109,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "บ้านกล้วย",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180102,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "หาดท่าเสา",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17120,
    district_code: 180106,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "เขาท่าพระ",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180105,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "เสือโฮก",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180108,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองชัยนาท",
    province: "ชัยนาท",
    zipcode: 17000,
    district_code: 180101,
    amphoe_code: 1801,
    province_code: 18,
  },
  {
    tambon: "คอนสวรรค์",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360301,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "ช่องสามหมอ",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360303,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "บ้านโสก",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360306,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "ยางหวาย",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360302,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "ศรีสำราญ",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360309,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "หนองขาม",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360308,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "ห้วยไร่",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360305,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "โคกมั่งงอย",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360307,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "คอนสวรรค์",
    province: "ชัยภูมิ",
    zipcode: 36140,
    district_code: 360304,
    amphoe_code: 3603,
    province_code: 36,
  },
  {
    tambon: "คอนสาร",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361301,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "ดงกลาง",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361308,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "ดงบัง",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361306,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "ทุ่งนาเลา",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361307,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "ทุ่งพระ",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361302,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "ทุ่งลุยลาย",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361305,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "ห้วยยาง",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361304,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "โนนคูณ",
    amphoe: "คอนสาร",
    province: "ชัยภูมิ",
    zipcode: 36180,
    district_code: 361303,
    amphoe_code: 3613,
    province_code: 36,
  },
  {
    tambon: "กุดน้ำใส",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360605,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "บ้านกอก",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360601,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "บ้านขาม",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360603,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "ละหาน",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360607,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "ส้มป่อย",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360613,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "หนองบัวบาน",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360602,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "หนองบัวโคก",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36220,
    district_code: 360611,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "หนองบัวใหญ่",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360610,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "หนองโดน",
    amphoe: "จัตุรัส",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360606,
    amphoe_code: 3606,
    province_code: 36,
  },
  {
    tambon: "ซับใหญ่",
    amphoe: "ซับใหญ่",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360614,
    amphoe_code: 3616,
    province_code: 36,
  },
  {
    tambon: "ตะโกทอง",
    amphoe: "ซับใหญ่",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360616,
    amphoe_code: 3616,
    province_code: 36,
  },
  {
    tambon: "ท่ากูบ",
    amphoe: "ซับใหญ่",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360612,
    amphoe_code: 3616,
    province_code: 36,
  },
  {
    tambon: "บ้านชวน",
    amphoe: "บำเหน็จณรงค์",
    province: "ชัยภูมิ",
    zipcode: 36160,
    district_code: 360701,
    amphoe_code: 3607,
    province_code: 36,
  },
  {
    tambon: "บ้านตาล",
    amphoe: "บำเหน็จณรงค์",
    province: "ชัยภูมิ",
    zipcode: 36220,
    district_code: 360703,
    amphoe_code: 3607,
    province_code: 36,
  },
  {
    tambon: "บ้านเพชร",
    amphoe: "บำเหน็จณรงค์",
    province: "ชัยภูมิ",
    zipcode: 36160,
    district_code: 360702,
    amphoe_code: 3607,
    province_code: 36,
  },
  {
    tambon: "หัวทะเล",
    amphoe: "บำเหน็จณรงค์",
    province: "ชัยภูมิ",
    zipcode: 36220,
    district_code: 360704,
    amphoe_code: 3607,
    province_code: 36,
  },
  {
    tambon: "เกาะมะนาว",
    amphoe: "บำเหน็จณรงค์",
    province: "ชัยภูมิ",
    zipcode: 36160,
    district_code: 360706,
    amphoe_code: 3607,
    province_code: 36,
  },
  {
    tambon: "โคกเพชรพัฒนา",
    amphoe: "บำเหน็จณรงค์",
    province: "ชัยภูมิ",
    zipcode: 36160,
    district_code: 360707,
    amphoe_code: 3607,
    province_code: 36,
  },
  {
    tambon: "โคกเริงรมย์",
    amphoe: "บำเหน็จณรงค์",
    province: "ชัยภูมิ",
    zipcode: 36160,
    district_code: 360705,
    amphoe_code: 3607,
    province_code: 36,
  },
  {
    tambon: "ชีบน",
    amphoe: "บ้านเขว้า",
    province: "ชัยภูมิ",
    zipcode: 36170,
    district_code: 360204,
    amphoe_code: 3602,
    province_code: 36,
  },
  {
    tambon: "ตลาดแร้ง",
    amphoe: "บ้านเขว้า",
    province: "ชัยภูมิ",
    zipcode: 36170,
    district_code: 360202,
    amphoe_code: 3602,
    province_code: 36,
  },
  {
    tambon: "บ้านเขว้า",
    amphoe: "บ้านเขว้า",
    province: "ชัยภูมิ",
    zipcode: 36170,
    district_code: 360201,
    amphoe_code: 3602,
    province_code: 36,
  },
  {
    tambon: "ภูแลนคา",
    amphoe: "บ้านเขว้า",
    province: "ชัยภูมิ",
    zipcode: 36170,
    district_code: 360205,
    amphoe_code: 3602,
    province_code: 36,
  },
  {
    tambon: "ลุ่มลำชี",
    amphoe: "บ้านเขว้า",
    province: "ชัยภูมิ",
    zipcode: 36170,
    district_code: 360203,
    amphoe_code: 3602,
    province_code: 36,
  },
  {
    tambon: "โนนแดง",
    amphoe: "บ้านเขว้า",
    province: "ชัยภูมิ",
    zipcode: 36170,
    district_code: 360206,
    amphoe_code: 3602,
    province_code: 36,
  },
  {
    tambon: "บ้านเต่า",
    amphoe: "บ้านแท่น",
    province: "ชัยภูมิ",
    zipcode: 36190,
    district_code: 361104,
    amphoe_code: 3611,
    province_code: 36,
  },
  {
    tambon: "บ้านแท่น",
    amphoe: "บ้านแท่น",
    province: "ชัยภูมิ",
    zipcode: 36190,
    district_code: 361101,
    amphoe_code: 3611,
    province_code: 36,
  },
  {
    tambon: "สระพัง",
    amphoe: "บ้านแท่น",
    province: "ชัยภูมิ",
    zipcode: 36190,
    district_code: 361103,
    amphoe_code: 3611,
    province_code: 36,
  },
  {
    tambon: "สามสวน",
    amphoe: "บ้านแท่น",
    province: "ชัยภูมิ",
    zipcode: 36190,
    district_code: 361102,
    amphoe_code: 3611,
    province_code: 36,
  },
  {
    tambon: "หนองคู",
    amphoe: "บ้านแท่น",
    province: "ชัยภูมิ",
    zipcode: 36190,
    district_code: 361105,
    amphoe_code: 3611,
    province_code: 36,
  },
  {
    tambon: "บ้านเจียง",
    amphoe: "ภักดีชุมพล",
    province: "ชัยภูมิ",
    zipcode: 36260,
    district_code: 360199,
    amphoe_code: 3614,
    province_code: 36,
  },
  {
    tambon: "วังทอง",
    amphoe: "ภักดีชุมพล",
    province: "ชัยภูมิ",
    zipcode: 36260,
    district_code: 360510,
    amphoe_code: 3614,
    province_code: 36,
  },
  {
    tambon: "เจาทอง",
    amphoe: "ภักดีชุมพล",
    province: "ชัยภูมิ",
    zipcode: 36260,
    district_code: 360198,
    amphoe_code: 3614,
    province_code: 36,
  },
  {
    tambon: "แหลมทอง",
    amphoe: "ภักดีชุมพล",
    province: "ชัยภูมิ",
    zipcode: 36260,
    district_code: 361404,
    amphoe_code: 3614,
    province_code: 36,
  },
  {
    tambon: "กวางโจน",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361002,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "กุดยม",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361005,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "ธาตุทอง",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361010,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "บ้านดอน",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361011,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "บ้านเพชร",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 360702,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "บ้านแก้ง",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361004,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "ผักปัง",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361001,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "หนองคอนไทย",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361003,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "หนองตูม",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361008,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 360804,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "โอโล",
    amphoe: "ภูเขียว",
    province: "ชัยภูมิ",
    zipcode: 36110,
    district_code: 361009,
    amphoe_code: 3610,
    province_code: 36,
  },
  {
    tambon: "วังตะเฆ่",
    amphoe: "หนองบัวระเหว",
    province: "ชัยภูมิ",
    zipcode: 36250,
    district_code: 360802,
    amphoe_code: 3608,
    province_code: 36,
  },
  {
    tambon: "หนองบัวระเหว",
    amphoe: "หนองบัวระเหว",
    province: "ชัยภูมิ",
    zipcode: 36250,
    district_code: 360801,
    amphoe_code: 3608,
    province_code: 36,
  },
  {
    tambon: "ห้วยแย้",
    amphoe: "หนองบัวระเหว",
    province: "ชัยภูมิ",
    zipcode: 36250,
    district_code: 360803,
    amphoe_code: 3608,
    province_code: 36,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "หนองบัวระเหว",
    province: "ชัยภูมิ",
    zipcode: 36250,
    district_code: 360804,
    amphoe_code: 3608,
    province_code: 36,
  },
  {
    tambon: "โสกปลาดุก",
    amphoe: "หนองบัวระเหว",
    province: "ชัยภูมิ",
    zipcode: 36250,
    district_code: 360805,
    amphoe_code: 3608,
    province_code: 36,
  },
  {
    tambon: "กุดชุมแสง",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360502,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "คูเมือง",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360508,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "ถ้ำวัวแดง",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360503,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "ท่าใหญ่",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360509,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "นางแดด",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360504,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "วังชมภู",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360511,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "หนองบัวแดง",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360501,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "หนองแวง",
    amphoe: "หนองบัวแดง",
    province: "ชัยภูมิ",
    zipcode: 36210,
    district_code: 360507,
    amphoe_code: 3605,
    province_code: 36,
  },
  {
    tambon: "กุดเลาะ",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360405,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "บ้านบัว",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360410,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360401,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "บ้านหัน",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360402,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "บ้านเดื่อ",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360403,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "บ้านเป้า",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360404,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "สระโพนทอง",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360407,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "หนองข่า",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360408,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "หนองโพนงาม",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360409,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "โนนกอก",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360406,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "โนนทอง",
    amphoe: "เกษตรสมบูรณ์",
    province: "ชัยภูมิ",
    zipcode: 36120,
    district_code: 360412,
    amphoe_code: 3604,
    province_code: 36,
  },
  {
    tambon: "นายางกลัก",
    amphoe: "เทพสถิต",
    province: "ชัยภูมิ",
    zipcode: 36230,
    district_code: 360797,
    amphoe_code: 3609,
    province_code: 36,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "เทพสถิต",
    province: "ชัยภูมิ",
    zipcode: 36230,
    district_code: 360796,
    amphoe_code: 3609,
    province_code: 36,
  },
  {
    tambon: "วะตะแบก",
    amphoe: "เทพสถิต",
    province: "ชัยภูมิ",
    zipcode: 36230,
    district_code: 360799,
    amphoe_code: 3609,
    province_code: 36,
  },
  {
    tambon: "ห้วยยายจิ๋ว",
    amphoe: "เทพสถิต",
    province: "ชัยภูมิ",
    zipcode: 36230,
    district_code: 360798,
    amphoe_code: 3609,
    province_code: 36,
  },
  {
    tambon: "โป่งนก",
    amphoe: "เทพสถิต",
    province: "ชัยภูมิ",
    zipcode: 36230,
    district_code: 360905,
    amphoe_code: 3609,
    province_code: 36,
  },
  {
    tambon: "กะฮาด",
    amphoe: "เนินสง่า",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360609,
    amphoe_code: 3615,
    province_code: 36,
  },
  {
    tambon: "ตาเนิน",
    amphoe: "เนินสง่า",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360608,
    amphoe_code: 3615,
    province_code: 36,
  },
  {
    tambon: "รังงาม",
    amphoe: "เนินสง่า",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360615,
    amphoe_code: 3615,
    province_code: 36,
  },
  {
    tambon: "หนองฉิม",
    amphoe: "เนินสง่า",
    province: "ชัยภูมิ",
    zipcode: 36130,
    district_code: 360604,
    amphoe_code: 3615,
    province_code: 36,
  },
  {
    tambon: "กุดตุ้ม",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360106,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ชีลอง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360107,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ซับสีทอง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360119,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ท่าหินโงม",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360113,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "นาฝาย",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360104,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "นาเสียว",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360109,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "บุ่งคล้า",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360118,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "บ้านค่าย",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36240,
    district_code: 360105,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "บ้านเล่า",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360108,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "รอบเมือง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360102,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ลาดใหญ่",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360111,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "หนองนาแซง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360110,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36240,
    district_code: 360112,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ห้วยต้อน",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360114,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ห้วยบง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360115,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "โคกสูง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360117,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "โนนสำราญ",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36240,
    district_code: 360116,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "โพนทอง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360103,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองชัยภูมิ",
    province: "ชัยภูมิ",
    zipcode: 36000,
    district_code: 360101,
    amphoe_code: 3601,
    province_code: 36,
  },
  {
    tambon: "ช่องสามหมอ",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 360303,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "ท่ามะไฟหวาน",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 361209,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "นาหนองทุ่ม",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 361203,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "บ้านแก้ง",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 361004,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "หนองขาม",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 360308,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "หนองสังข์",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 361205,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 360112,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "หลุบคา",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 361206,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "เก่าย่าดี",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 361208,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "โคกกุง",
    amphoe: "แก้งคร้อ",
    province: "ชัยภูมิ",
    zipcode: 36150,
    district_code: 361207,
    amphoe_code: 3612,
    province_code: 36,
  },
  {
    tambon: "ช่องไม้แก้ว",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: 86220,
    district_code: 860804,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "ตะโก",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: 86220,
    district_code: 860803,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "ทุ่งตะไคร",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: 86220,
    district_code: 860802,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "ปากตะโก",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: 86220,
    district_code: 860801,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "หมู่เกาะศรีอบยา",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะขี้นก",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะคราม",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะคางสือ",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะมะพร้าว",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะมุก",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะยอ",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะรางบรรทัด",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "เกาะสูบ",
    amphoe: "ทุ่งตะโก",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8608,
    province_code: 86,
  },
  {
    tambon: "คุริง",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860202,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "ทรัพย์อนันต์",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860209,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860206,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "ท่าแซะ",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860201,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "นากระตาม",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860204,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "รับร่อ",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86190,
    district_code: 860205,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "สลุย",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860203,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "สองพี่น้อง",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860210,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "หงษ์เจริญ",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86140,
    district_code: 860207,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "หินแก้ว",
    amphoe: "ท่าแซะ",
    province: "ชุมพร",
    zipcode: 86190,
    district_code: 860208,
    amphoe_code: 8602,
    province_code: 86,
  },
  {
    tambon: "ชุมโค",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: 86160,
    district_code: 860304,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "ดอนยาง",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: 86210,
    district_code: 860305,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "ทะเลทรัพย์",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: 86160,
    district_code: 860302,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "บางสน",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: 86160,
    district_code: 860301,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "ปากคลอง",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: 86210,
    district_code: 860306,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "สะพลี",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: 86230,
    district_code: 860303,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะซีกง",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะพระ",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะยอ",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะรัง",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะร้านเป็ด",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะร้านไก่",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะเอียง",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เกาะไข่",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "เขาไชยราช",
    amphoe: "ปะทิว",
    province: "ชุมพร",
    zipcode: 86210,
    district_code: 860307,
    amphoe_code: 8603,
    province_code: 86,
  },
  {
    tambon: "ปังหวาน",
    amphoe: "พะโต๊ะ",
    province: "ชุมพร",
    zipcode: 86180,
    district_code: 860603,
    amphoe_code: 8606,
    province_code: 86,
  },
  {
    tambon: "ปากทรง",
    amphoe: "พะโต๊ะ",
    province: "ชุมพร",
    zipcode: 86180,
    district_code: 860602,
    amphoe_code: 8606,
    province_code: 86,
  },
  {
    tambon: "พระรักษ์",
    amphoe: "พะโต๊ะ",
    province: "ชุมพร",
    zipcode: 86180,
    district_code: 860604,
    amphoe_code: 8606,
    province_code: 86,
  },
  {
    tambon: "พะโต๊ะ",
    amphoe: "พะโต๊ะ",
    province: "ชุมพร",
    zipcode: 86180,
    district_code: 860601,
    amphoe_code: 8606,
    province_code: 86,
  },
  {
    tambon: "ทุ่งคาวัด",
    amphoe: "ละแม",
    province: "ชุมพร",
    zipcode: 86170,
    district_code: 860504,
    amphoe_code: 8605,
    province_code: 86,
  },
  {
    tambon: "ทุ่งหลวง",
    amphoe: "ละแม",
    province: "ชุมพร",
    zipcode: 86170,
    district_code: 860502,
    amphoe_code: 8605,
    province_code: 86,
  },
  {
    tambon: "ละแม",
    amphoe: "ละแม",
    province: "ชุมพร",
    zipcode: 86170,
    district_code: 860501,
    amphoe_code: 8605,
    province_code: 86,
  },
  {
    tambon: "สวนแตง",
    amphoe: "ละแม",
    province: "ชุมพร",
    zipcode: 86170,
    district_code: 860503,
    amphoe_code: 8605,
    province_code: 86,
  },
  {
    tambon: "ครน",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860707,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "ด่านสวี",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860706,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "ทุ่งระยะ",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860703,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "ท่าหิน",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860704,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "นาสัก",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860709,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860701,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "ปากแพรก",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860705,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "วิสัยใต้",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860708,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "สวี",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860702,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะกระ",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะกุลา",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะทองแก้ว",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะบาตร์",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะพัดหวายเล็ก",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะพัดหวายใหญ่",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะยูง",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะหนู",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะแมว",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เกาะแรด",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เขาค่าย",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860711,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "เขาทะลุ",
    amphoe: "สวี",
    province: "ชุมพร",
    zipcode: 86130,
    district_code: 860710,
    amphoe_code: 8607,
    province_code: 86,
  },
  {
    tambon: "ขันเงิน",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860402,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "ท่ามะพลา",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860403,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "นาขา",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860404,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "นาพญา",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860405,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "บางน้ำจืด",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86150,
    district_code: 860408,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "บางมะพร้าว",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860407,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "บ้านควน",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860406,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86150,
    district_code: 860102,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "พ้อแดง",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860410,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "วังตะกอ",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860412,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "หลังสวน",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860401,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "หาดยาย",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860413,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "เกาะพิทักษ์",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "แหลมทราย",
    amphoe: "หลังสวน",
    province: "ชุมพร",
    zipcode: 86110,
    district_code: 860411,
    amphoe_code: 8604,
    province_code: 86,
  },
  {
    tambon: "ขุนกระทิง",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86190,
    district_code: 860113,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "ตากแดด",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860107,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "ถ้ำสิงห์",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86100,
    district_code: 860117,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "ทุ่งคา",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86100,
    district_code: 860114,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "ท่าตะเภา",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860101,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "ท่ายาง",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860103,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "นาชะอัง",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860106,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "นาทุ่ง",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860105,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "บางลึก",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860108,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "บางหมาก",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860104,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "บ้านนา",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86190,
    district_code: 860112,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86120,
    district_code: 860102,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "วังใหม่",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86190,
    district_code: 860111,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "วังไผ่",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860110,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "วิสัยเหนือ",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86100,
    district_code: 860115,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "หาดทรายรี",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86120,
    district_code: 860116,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "หาดพันไกร",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: 86000,
    district_code: 860109,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะกา",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะกินหลักง้ำ",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะงามเล็ก",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะงามใหญ่",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะทองหลาง",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะทะลุ",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะมะพร้าว",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะมัตโพน",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะมาตรา",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะรังกาจิว",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะละวะ",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะสาก",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะหลักแรด",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะอีแรด",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "เกาะเสม็ด",
    amphoe: "เมืองชุมพร",
    province: "ชุมพร",
    zipcode: "",
    district_code: false,
    amphoe_code: 8601,
    province_code: 86,
  },
  {
    tambon: "กันตัง",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920201,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "กันตังใต้",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920206,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "คลองชีล้อม",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920214,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "คลองลุ",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920208,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "ควนธานี",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920202,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "นาเกลือ",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920212,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "บางสัก",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920211,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "บางหมาก",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920203,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "บางเป้า",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920204,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "บ่อน้ำร้อน",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920210,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "ย่านซื่อ",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920209,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "วังวน",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920205,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "เกาะลิบง",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920213,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "โคกยาง",
    amphoe: "กันตัง",
    province: "ตรัง",
    zipcode: 92110,
    district_code: 920207,
    amphoe_code: 9202,
    province_code: 92,
  },
  {
    tambon: "ช่อง",
    amphoe: "นาโยง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920121,
    amphoe_code: 9208,
    province_code: 92,
  },
  {
    tambon: "นาข้าวเสีย",
    amphoe: "นาโยง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920111,
    amphoe_code: 9208,
    province_code: 92,
  },
  {
    tambon: "นาหมื่นศรี",
    amphoe: "นาโยง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920112,
    amphoe_code: 9208,
    province_code: 92,
  },
  {
    tambon: "นาโยงเหนือ",
    amphoe: "นาโยง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920116,
    amphoe_code: 9208,
    province_code: 92,
  },
  {
    tambon: "ละมอ",
    amphoe: "นาโยง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920103,
    amphoe_code: 9208,
    province_code: 92,
  },
  {
    tambon: "โคกสะบ้า",
    amphoe: "นาโยง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920102,
    amphoe_code: 9208,
    province_code: 92,
  },
  {
    tambon: "ทุ่งยาว",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92180,
    district_code: 920402,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92120,
    district_code: 920401,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "ท่าพญา",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920412,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "บางด้วน",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920404,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "บ้านนา",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920407,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "ปะเหลียน",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92180,
    district_code: 920403,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "ลิพัง",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92180,
    district_code: 920410,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "สุโสะ",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92120,
    district_code: 920409,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "เกาะสุกร",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92120,
    district_code: 920411,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "แหลมสอม",
    amphoe: "ปะเหลียน",
    province: "ตรัง",
    zipcode: 92180,
    district_code: 920413,
    amphoe_code: 9204,
    province_code: 92,
  },
  {
    tambon: "ทุ่งกระบือ",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920306,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "ทุ่งค่าย",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920307,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "นาชุมเห็ด",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920303,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "ย่านตาขาว",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920301,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "หนองบ่อ",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920302,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "เกาะเปียะ",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920308,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "โพรงจระเข้",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920305,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "ในควน",
    amphoe: "ย่านตาขาว",
    province: "ตรัง",
    zipcode: 92140,
    district_code: 920304,
    amphoe_code: 9203,
    province_code: 92,
  },
  {
    tambon: "คลองปาง",
    amphoe: "รัษฎา",
    province: "ตรัง",
    zipcode: 92160,
    district_code: 920612,
    amphoe_code: 9209,
    province_code: 92,
  },
  {
    tambon: "ควนเมา",
    amphoe: "รัษฎา",
    province: "ตรัง",
    zipcode: 92160,
    district_code: 920613,
    amphoe_code: 9209,
    province_code: 92,
  },
  {
    tambon: "หนองบัว",
    amphoe: "รัษฎา",
    province: "ตรัง",
    zipcode: 92160,
    district_code: 920604,
    amphoe_code: 9209,
    province_code: 92,
  },
  {
    tambon: "หนองปรือ",
    amphoe: "รัษฎา",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920603,
    amphoe_code: 9209,
    province_code: 92,
  },
  {
    tambon: "เขาไพร",
    amphoe: "รัษฎา",
    province: "ตรัง",
    zipcode: 92160,
    district_code: 920618,
    amphoe_code: 9209,
    province_code: 92,
  },
  {
    tambon: "ท่าสะบ้า",
    amphoe: "วังวิเศษ",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920595,
    amphoe_code: 9207,
    province_code: 92,
  },
  {
    tambon: "วังมะปราง",
    amphoe: "วังวิเศษ",
    province: "ตรัง",
    zipcode: 92220,
    district_code: 920598,
    amphoe_code: 9207,
    province_code: 92,
  },
  {
    tambon: "วังมะปรางเหนือ",
    amphoe: "วังวิเศษ",
    province: "ตรัง",
    zipcode: 92220,
    district_code: 920705,
    amphoe_code: 9207,
    province_code: 92,
  },
  {
    tambon: "อ่าวตง",
    amphoe: "วังวิเศษ",
    province: "ตรัง",
    zipcode: 92220,
    district_code: 920597,
    amphoe_code: 9207,
    province_code: 92,
  },
  {
    tambon: "เขาวิเศษ",
    amphoe: "วังวิเศษ",
    province: "ตรัง",
    zipcode: 92220,
    district_code: 920599,
    amphoe_code: 9207,
    province_code: 92,
  },
  {
    tambon: "กะลาเส",
    amphoe: "สิเกา",
    province: "ตรัง",
    zipcode: 92150,
    district_code: 920503,
    amphoe_code: 9205,
    province_code: 92,
  },
  {
    tambon: "นาเมืองเพชร",
    amphoe: "สิเกา",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920505,
    amphoe_code: 9205,
    province_code: 92,
  },
  {
    tambon: "บ่อหิน",
    amphoe: "สิเกา",
    province: "ตรัง",
    zipcode: 92150,
    district_code: 920501,
    amphoe_code: 9205,
    province_code: 92,
  },
  {
    tambon: "เขาไม้แก้ว",
    amphoe: "สิเกา",
    province: "ตรัง",
    zipcode: 92150,
    district_code: 920502,
    amphoe_code: 9205,
    province_code: 92,
  },
  {
    tambon: "ไม้ฝาด",
    amphoe: "สิเกา",
    province: "ตรัง",
    zipcode: 92150,
    district_code: 920504,
    amphoe_code: 9205,
    province_code: 92,
  },
  {
    tambon: "ตะเสะ",
    amphoe: "หาดสำราญ",
    province: "ตรัง",
    zipcode: 92120,
    district_code: 920406,
    amphoe_code: 9210,
    province_code: 92,
  },
  {
    tambon: "บ้าหวี",
    amphoe: "หาดสำราญ",
    province: "ตรัง",
    zipcode: 92120,
    district_code: 920408,
    amphoe_code: 9210,
    province_code: 92,
  },
  {
    tambon: "หาดสำราญ",
    amphoe: "หาดสำราญ",
    province: "ตรัง",
    zipcode: 92120,
    district_code: 920405,
    amphoe_code: 9210,
    province_code: 92,
  },
  {
    tambon: "ทุ่งต่อ",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920620,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ท่างิ้ว",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920614,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "นาวง",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92210,
    district_code: 920616,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "บางกุ้ง",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92210,
    district_code: 920606,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "บางดี",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92210,
    district_code: 920605,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ปากคม",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920611,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ปากแจ่ม",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92190,
    district_code: 920610,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ลำภูรา",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92190,
    district_code: 920615,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "วังคีรี",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92210,
    district_code: 920621,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "หนองช้างแล่น",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920602,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ห้วยนาง",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920617,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ห้วยยอด",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920601,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "เขากอบ",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920607,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "เขาขาว",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920608,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "เขาปูน",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920609,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ในเตา",
    amphoe: "ห้วยยอด",
    province: "ตรัง",
    zipcode: 92130,
    district_code: 920619,
    amphoe_code: 9206,
    province_code: 92,
  },
  {
    tambon: "ควนปริง",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920107,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "ทับเที่ยง",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920101,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "นาตาล่วง",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920117,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "นาท่ามเหนือ",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92190,
    district_code: 920119,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "นาท่ามใต้",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92190,
    district_code: 920120,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "นาบินหลา",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920106,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "นาพละ",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920104,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "นาโต๊ะหมิง",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920113,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "นาโยงใต้",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92170,
    district_code: 920108,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "น้ำผุด",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920115,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "บางรัก",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920109,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "บ้านควน",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920105,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "บ้านโพธิ์",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920118,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "หนองตรุด",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920114,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "โคกหล่อ",
    amphoe: "เมืองตรัง",
    province: "ตรัง",
    zipcode: 92000,
    district_code: 920110,
    amphoe_code: 9201,
    province_code: 92,
  },
  {
    tambon: "คลองใหญ่",
    amphoe: "คลองใหญ่",
    province: "ตราด",
    zipcode: 23110,
    district_code: 230201,
    amphoe_code: 2302,
    province_code: 23,
  },
  {
    tambon: "หาดเล็ก",
    amphoe: "คลองใหญ่",
    province: "ตราด",
    zipcode: 23110,
    district_code: 230203,
    amphoe_code: 2302,
    province_code: 23,
  },
  {
    tambon: "ไม้รูด",
    amphoe: "คลองใหญ่",
    province: "ตราด",
    zipcode: 23110,
    district_code: 230202,
    amphoe_code: 2302,
    province_code: 23,
  },
  {
    tambon: "ช้างทูน",
    amphoe: "บ่อไร่",
    province: "ตราด",
    zipcode: 23140,
    district_code: 230398,
    amphoe_code: 2304,
    province_code: 23,
  },
  {
    tambon: "ด่านชุมพล",
    amphoe: "บ่อไร่",
    province: "ตราด",
    zipcode: 23140,
    district_code: 230396,
    amphoe_code: 2304,
    province_code: 23,
  },
  {
    tambon: "นนทรีย์",
    amphoe: "บ่อไร่",
    province: "ตราด",
    zipcode: 23140,
    district_code: 230405,
    amphoe_code: 2304,
    province_code: 23,
  },
  {
    tambon: "บ่อพลอย",
    amphoe: "บ่อไร่",
    province: "ตราด",
    zipcode: 23140,
    district_code: 230399,
    amphoe_code: 2304,
    province_code: 23,
  },
  {
    tambon: "หนองบอน",
    amphoe: "บ่อไร่",
    province: "ตราด",
    zipcode: 23140,
    district_code: 230397,
    amphoe_code: 2304,
    province_code: 23,
  },
  {
    tambon: "เกาะกูด",
    amphoe: "เกาะกูด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230506,
    amphoe_code: 2306,
    province_code: 23,
  },
  {
    tambon: "เกาะหมาก",
    amphoe: "เกาะกูด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230505,
    amphoe_code: 2306,
    province_code: 23,
  },
  {
    tambon: "เกาะช้าง",
    amphoe: "เกาะช้าง",
    province: "ตราด",
    zipcode: 23170,
    district_code: 230504,
    amphoe_code: 2307,
    province_code: 23,
  },
  {
    tambon: "เกาะช้างใต้",
    amphoe: "เกาะช้าง",
    province: "ตราด",
    zipcode: 23170,
    district_code: 230508,
    amphoe_code: 2307,
    province_code: 23,
  },
  {
    tambon: "ทุ่งนนทรี",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23130,
    district_code: 230308,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "ท่าโสม",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23150,
    district_code: 230304,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "ประณีต",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23150,
    district_code: 230306,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "วังตะเคียน",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23130,
    district_code: 230303,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "สะตอ",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23150,
    district_code: 230305,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "เขาสมิง",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23130,
    district_code: 230301,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "เทพนิมิต",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23150,
    district_code: 230307,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "แสนตุ้ง",
    amphoe: "เขาสมิง",
    province: "ตราด",
    zipcode: 23150,
    district_code: 230302,
    amphoe_code: 2303,
    province_code: 23,
  },
  {
    tambon: "ชำราก",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230113,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "ตะกาง",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230112,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "ท่ากุ่ม",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230111,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "ท่าพริก",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230110,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "บางพระ",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230101,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "วังกระแจะ",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230107,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "หนองคันทรง",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230104,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "หนองเสม็ด",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230102,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "หนองโสน",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230103,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "ห้วงน้ำขาว",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230105,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "ห้วยแร้ง",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230108,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "อ่าวใหญ่",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230106,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "เนินทราย",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230109,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "แหลมกลัด",
    amphoe: "เมืองตราด",
    province: "ตราด",
    zipcode: 23000,
    district_code: 230114,
    amphoe_code: 2301,
    province_code: 23,
  },
  {
    tambon: "คลองใหญ่",
    amphoe: "แหลมงอบ",
    province: "ตราด",
    zipcode: 23120,
    district_code: 230201,
    amphoe_code: 2305,
    province_code: 23,
  },
  {
    tambon: "น้ำเชี่ยว",
    amphoe: "แหลมงอบ",
    province: "ตราด",
    zipcode: 23120,
    district_code: 230502,
    amphoe_code: 2305,
    province_code: 23,
  },
  {
    tambon: "บางปิด",
    amphoe: "แหลมงอบ",
    province: "ตราด",
    zipcode: 23120,
    district_code: 230503,
    amphoe_code: 2305,
    province_code: 23,
  },
  {
    tambon: "แหลมงอบ",
    amphoe: "แหลมงอบ",
    province: "ตราด",
    zipcode: 23120,
    district_code: 230501,
    amphoe_code: 2305,
    province_code: 23,
  },
  {
    tambon: "ท่าสองยาง",
    amphoe: "ท่าสองยาง",
    province: "ตาก",
    zipcode: 63150,
    district_code: 630501,
    amphoe_code: 6305,
    province_code: 63,
  },
  {
    tambon: "แม่ต้าน",
    amphoe: "ท่าสองยาง",
    province: "ตาก",
    zipcode: 63150,
    district_code: 630502,
    amphoe_code: 6305,
    province_code: 63,
  },
  {
    tambon: "แม่วะหลวง",
    amphoe: "ท่าสองยาง",
    province: "ตาก",
    zipcode: 63150,
    district_code: 630505,
    amphoe_code: 6305,
    province_code: 63,
  },
  {
    tambon: "แม่สอง",
    amphoe: "ท่าสองยาง",
    province: "ตาก",
    zipcode: 63150,
    district_code: 630503,
    amphoe_code: 6305,
    province_code: 63,
  },
  {
    tambon: "แม่หละ",
    amphoe: "ท่าสองยาง",
    province: "ตาก",
    zipcode: 63150,
    district_code: 630504,
    amphoe_code: 6305,
    province_code: 63,
  },
  {
    tambon: "แม่อุสุ",
    amphoe: "ท่าสองยาง",
    province: "ตาก",
    zipcode: 63150,
    district_code: 630506,
    amphoe_code: 6305,
    province_code: 63,
  },
  {
    tambon: "ตากตก",
    amphoe: "บ้านตาก",
    province: "ตาก",
    zipcode: 63120,
    district_code: 630204,
    amphoe_code: 6302,
    province_code: 63,
  },
  {
    tambon: "ตากออก",
    amphoe: "บ้านตาก",
    province: "ตาก",
    zipcode: 63120,
    district_code: 630201,
    amphoe_code: 6302,
    province_code: 63,
  },
  {
    tambon: "ทุ่งกระเชาะ",
    amphoe: "บ้านตาก",
    province: "ตาก",
    zipcode: 63120,
    district_code: 630206,
    amphoe_code: 6302,
    province_code: 63,
  },
  {
    tambon: "ท้องฟ้า",
    amphoe: "บ้านตาก",
    province: "ตาก",
    zipcode: 63120,
    district_code: 630207,
    amphoe_code: 6302,
    province_code: 63,
  },
  {
    tambon: "สมอโคน",
    amphoe: "บ้านตาก",
    province: "ตาก",
    zipcode: 63120,
    district_code: 630202,
    amphoe_code: 6302,
    province_code: 63,
  },
  {
    tambon: "เกาะตะเภา",
    amphoe: "บ้านตาก",
    province: "ตาก",
    zipcode: 63120,
    district_code: 630205,
    amphoe_code: 6302,
    province_code: 63,
  },
  {
    tambon: "แม่สลิด",
    amphoe: "บ้านตาก",
    province: "ตาก",
    zipcode: 63120,
    district_code: 630203,
    amphoe_code: 6302,
    province_code: 63,
  },
  {
    tambon: "คีรีราษฎร์",
    amphoe: "พบพระ",
    province: "ตาก",
    zipcode: 63160,
    district_code: 630703,
    amphoe_code: 6307,
    province_code: 63,
  },
  {
    tambon: "ช่องแคบ",
    amphoe: "พบพระ",
    province: "ตาก",
    zipcode: 63160,
    district_code: 630702,
    amphoe_code: 6307,
    province_code: 63,
  },
  {
    tambon: "พบพระ",
    amphoe: "พบพระ",
    province: "ตาก",
    zipcode: 63160,
    district_code: 630701,
    amphoe_code: 6307,
    province_code: 63,
  },
  {
    tambon: "รวมไทยพัฒนา",
    amphoe: "พบพระ",
    province: "ตาก",
    zipcode: 63160,
    district_code: 630705,
    amphoe_code: 6307,
    province_code: 63,
  },
  {
    tambon: "วาเล่ย์",
    amphoe: "พบพระ",
    province: "ตาก",
    zipcode: 63160,
    district_code: 630704,
    amphoe_code: 6307,
    province_code: 63,
  },
  {
    tambon: "นาโบสถ์",
    amphoe: "วังเจ้า",
    province: "ตาก",
    zipcode: 63180,
    district_code: 630116,
    amphoe_code: 6309,
    province_code: 63,
  },
  {
    tambon: "ประดาง",
    amphoe: "วังเจ้า",
    province: "ตาก",
    zipcode: 63180,
    district_code: 630117,
    amphoe_code: 6309,
    province_code: 63,
  },
  {
    tambon: "เชียงทอง",
    amphoe: "วังเจ้า",
    province: "ตาก",
    zipcode: 63180,
    district_code: 630110,
    amphoe_code: 6309,
    province_code: 63,
  },
  {
    tambon: "บ้านนา",
    amphoe: "สามเงา",
    province: "ตาก",
    zipcode: 63130,
    district_code: 630305,
    amphoe_code: 6303,
    province_code: 63,
  },
  {
    tambon: "ยกกระบัตร",
    amphoe: "สามเงา",
    province: "ตาก",
    zipcode: 63130,
    district_code: 630303,
    amphoe_code: 6303,
    province_code: 63,
  },
  {
    tambon: "ย่านรี",
    amphoe: "สามเงา",
    province: "ตาก",
    zipcode: 63130,
    district_code: 630304,
    amphoe_code: 6303,
    province_code: 63,
  },
  {
    tambon: "วังจันทร์",
    amphoe: "สามเงา",
    province: "ตาก",
    zipcode: 63130,
    district_code: 630306,
    amphoe_code: 6303,
    province_code: 63,
  },
  {
    tambon: "วังหมัน",
    amphoe: "สามเงา",
    province: "ตาก",
    zipcode: 63130,
    district_code: 630302,
    amphoe_code: 6303,
    province_code: 63,
  },
  {
    tambon: "สามเงา",
    amphoe: "สามเงา",
    province: "ตาก",
    zipcode: 63130,
    district_code: 630301,
    amphoe_code: 6303,
    province_code: 63,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "อุ้มผาง",
    province: "ตาก",
    zipcode: 63170,
    district_code: 630102,
    amphoe_code: 6308,
    province_code: 63,
  },
  {
    tambon: "อุ้มผาง",
    amphoe: "อุ้มผาง",
    province: "ตาก",
    zipcode: 63170,
    district_code: 630801,
    amphoe_code: 6308,
    province_code: 63,
  },
  {
    tambon: "แม่กลอง",
    amphoe: "อุ้มผาง",
    province: "ตาก",
    zipcode: 63170,
    district_code: 630806,
    amphoe_code: 6308,
    province_code: 63,
  },
  {
    tambon: "แม่จัน",
    amphoe: "อุ้มผาง",
    province: "ตาก",
    zipcode: 63170,
    district_code: 630804,
    amphoe_code: 6308,
    province_code: 63,
  },
  {
    tambon: "แม่ละมุ้ง",
    amphoe: "อุ้มผาง",
    province: "ตาก",
    zipcode: 63170,
    district_code: 630805,
    amphoe_code: 6308,
    province_code: 63,
  },
  {
    tambon: "โมโกร",
    amphoe: "อุ้มผาง",
    province: "ตาก",
    zipcode: 63170,
    district_code: 630803,
    amphoe_code: 6308,
    province_code: 63,
  },
  {
    tambon: "ตลุกกลางทุ่ง",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630115,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "น้ำรึม",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630108,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "ป่ามะม่วง",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630112,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "ระแหง",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630101,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "วังประจบ",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630114,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "วังหิน",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630109,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "หนองบัวเหนือ",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630105,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "หนองบัวใต้",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630113,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630102,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "หัวเดียด",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630104,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "เชียงเงิน",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630103,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "แม่ท้อ",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630111,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "โป่งแดง",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630107,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "ไม้งาม",
    amphoe: "เมืองตาก",
    province: "ตาก",
    zipcode: 63000,
    district_code: 630106,
    amphoe_code: 6301,
    province_code: 63,
  },
  {
    tambon: "ขะเนจื้อ",
    amphoe: "แม่ระมาด",
    province: "ตาก",
    zipcode: 63140,
    district_code: 630403,
    amphoe_code: 6304,
    province_code: 63,
  },
  {
    tambon: "พระธาตุ",
    amphoe: "แม่ระมาด",
    province: "ตาก",
    zipcode: 63140,
    district_code: 630406,
    amphoe_code: 6304,
    province_code: 63,
  },
  {
    tambon: "สามหมื่น",
    amphoe: "แม่ระมาด",
    province: "ตาก",
    zipcode: 63140,
    district_code: 630405,
    amphoe_code: 6304,
    province_code: 63,
  },
  {
    tambon: "แม่จะเรา",
    amphoe: "แม่ระมาด",
    province: "ตาก",
    zipcode: 63140,
    district_code: 630402,
    amphoe_code: 6304,
    province_code: 63,
  },
  {
    tambon: "แม่ตื่น",
    amphoe: "แม่ระมาด",
    province: "ตาก",
    zipcode: 63140,
    district_code: 630404,
    amphoe_code: 6304,
    province_code: 63,
  },
  {
    tambon: "แม่ระมาด",
    amphoe: "แม่ระมาด",
    province: "ตาก",
    zipcode: 63140,
    district_code: 630401,
    amphoe_code: 6304,
    province_code: 63,
  },
  {
    tambon: "ด่านแม่ละเมา",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630609,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "ท่าสายลวด",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630606,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "พระธาตุผาแดง",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630610,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "พะวอ",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630603,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "มหาวัน",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630608,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "แม่กาษา",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630605,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "แม่กุ",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630602,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "แม่ตาว",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630604,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "แม่ปะ",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630607,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "แม่สอด",
    amphoe: "แม่สอด",
    province: "ตาก",
    zipcode: 63110,
    district_code: 630601,
    amphoe_code: 6306,
    province_code: 63,
  },
  {
    tambon: "ทองหลาง",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260305,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "บางอ้อ",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260306,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "บ้านนา",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260301,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "บ้านพริก",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260303,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "บ้านพร้าว",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260302,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "ป่าขะ",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260308,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "พิกุลออก",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260307,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "ศรีกะอาง",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260310,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "อาษา",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260304,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "เขาเพิ่ม",
    amphoe: "บ้านนา",
    province: "นครนายก",
    zipcode: 26110,
    district_code: 260309,
    amphoe_code: 2603,
    province_code: 26,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "ปากพลี",
    province: "นครนายก",
    zipcode: 26130,
    district_code: 260205,
    amphoe_code: 2602,
    province_code: 26,
  },
  {
    tambon: "นาหินลาด",
    amphoe: "ปากพลี",
    province: "นครนายก",
    zipcode: 26130,
    district_code: 260207,
    amphoe_code: 2602,
    province_code: 26,
  },
  {
    tambon: "ปากพลี",
    amphoe: "ปากพลี",
    province: "นครนายก",
    zipcode: 26130,
    district_code: 260203,
    amphoe_code: 2602,
    province_code: 26,
  },
  {
    tambon: "หนองแสง",
    amphoe: "ปากพลี",
    province: "นครนายก",
    zipcode: 26130,
    district_code: 260206,
    amphoe_code: 2602,
    province_code: 26,
  },
  {
    tambon: "เกาะหวาย",
    amphoe: "ปากพลี",
    province: "นครนายก",
    zipcode: 26130,
    district_code: 260201,
    amphoe_code: 2602,
    province_code: 26,
  },
  {
    tambon: "เกาะโพธิ์",
    amphoe: "ปากพลี",
    province: "นครนายก",
    zipcode: 26130,
    district_code: 260202,
    amphoe_code: 2602,
    province_code: 26,
  },
  {
    tambon: "โคกกรวด",
    amphoe: "ปากพลี",
    province: "นครนายก",
    zipcode: 26130,
    district_code: 260204,
    amphoe_code: 2602,
    province_code: 26,
  },
  {
    tambon: "คลองใหญ่",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260411,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "ชุมพล",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260410,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "ทรายมูล",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260406,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "บางปลากด",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260407,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "บางลูกเสือ",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260408,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "บางสมบูรณ์",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260405,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "บึงศาล",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260402,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "พระอาจารย์",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260401,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "ศีรษะกระบือ",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260403,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "องครักษ์",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260409,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "โพธิ์แทน",
    amphoe: "องครักษ์",
    province: "นครนายก",
    zipcode: 26120,
    district_code: 260404,
    amphoe_code: 2604,
    province_code: 26,
  },
  {
    tambon: "ดงละคร",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260108,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "ดอนยอ",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260106,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260102,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "ท่าทราย",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260105,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "นครนายก",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260101,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "บ้านใหญ่",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260103,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "พรหมณี",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260113,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "วังกระโจม",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260104,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "ศรีจุฬา",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260107,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "ศรีนาวา",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260109,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "สาริกา",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260110,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "หินตั้ง",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260111,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "เขาพระ",
    amphoe: "เมืองนครนายก",
    province: "นครนายก",
    zipcode: 26000,
    district_code: 260112,
    amphoe_code: 2601,
    province_code: 26,
  },
  {
    tambon: "กระตีบ",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73180,
    district_code: 730202,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "กำแพงแสน",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730212,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ดอนข่อย",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730208,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ทุ่งกระพังโหม",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730201,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ทุ่งขวาง",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730205,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ทุ่งบัว",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730207,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ทุ่งลูกนก",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730203,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "รางพิกุล",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730213,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "วังน้ำเขียว",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730215,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "สระพัฒนา",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73180,
    district_code: 730209,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "สระสี่มุม",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730206,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "หนองกระทุ่ม",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730214,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ห้วยขวาง",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730204,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ห้วยม่วง",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73180,
    district_code: 730211,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ห้วยหมอนทอง",
    amphoe: "กำแพงแสน",
    province: "นครปฐม",
    zipcode: 73140,
    district_code: 730210,
    amphoe_code: 7302,
    province_code: 73,
  },
  {
    tambon: "ดอนพุทรา",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730404,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "ดอนรวก",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730406,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "บ้านหลวง",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730405,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "ลำลูกบัว",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730408,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "ลำเหย",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730403,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "สามง่าม",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730401,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "ห้วยด้วน",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730407,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "ห้วยพระ",
    amphoe: "ดอนตูม",
    province: "นครปฐม",
    zipcode: 73150,
    district_code: 730402,
    amphoe_code: 7304,
    province_code: 73,
  },
  {
    tambon: "ขุนแก้ว",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730307,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "งิ้วราย",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730323,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ดอนแฝก",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730317,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ท่ากระชับ",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730306,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ท่าตำหนัก",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730304,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ท่าพระยา",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730308,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "นครชัยศรี",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730301,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "บางกระเบา",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730302,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "บางพระ",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730320,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "บางระกำ",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730310,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730305,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "บางแก้วฟ้า",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730321,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "พะเนียด",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730309,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ลานตากฟ้า",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730322,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "วัดละมุด",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730319,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "วัดสำโรง",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730316,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "วัดแค",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730303,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ศรีมหาโพธิ์",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730314,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ศรีษะทอง",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730312,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "สัมปทวน",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730315,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ห้วยพลู",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730318,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "แหลมบัว",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730313,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "โคกพระเจดีย์",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730311,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "ไทยาวาส",
    amphoe: "นครชัยศรี",
    province: "นครปฐม",
    zipcode: 73120,
    district_code: 730324,
    amphoe_code: 7303,
    province_code: 73,
  },
  {
    tambon: "คลองนกกระทุง",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730512,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "ดอนตูม",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730509,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "นราภิรมย์",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730513,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "นิลเพชร",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730510,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "บัวปากท่า",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730511,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "บางปลา",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730502,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "บางภาษี",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730504,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "บางระกำ",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730310,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "บางหลวง",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73190,
    district_code: 730503,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "บางเลน",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730501,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "บางไทรป่า",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730506,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "ลำพญา",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730514,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "หินมูล",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73190,
    district_code: 730507,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "ไทรงาม",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730508,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "ไผ่หูช้าง",
    amphoe: "บางเลน",
    province: "นครปฐม",
    zipcode: 73130,
    district_code: 730515,
    amphoe_code: 7305,
    province_code: 73,
  },
  {
    tambon: "คลองโยง",
    amphoe: "พุทธมณฑล",
    province: "นครปฐม",
    zipcode: 73170,
    district_code: 730327,
    amphoe_code: 7307,
    province_code: 73,
  },
  {
    tambon: "มหาสวัสดิ์",
    amphoe: "พุทธมณฑล",
    province: "นครปฐม",
    zipcode: 73170,
    district_code: 730326,
    amphoe_code: 7307,
    province_code: 73,
  },
  {
    tambon: "ศาลายา",
    amphoe: "พุทธมณฑล",
    province: "นครปฐม",
    zipcode: 73170,
    district_code: 730325,
    amphoe_code: 7307,
    province_code: 73,
  },
  {
    tambon: "กระทุ่มล้ม",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73220,
    district_code: 730610,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "คลองจินดา",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730613,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "คลองใหม่",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730611,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "ตลาดจินดา",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730612,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "ทรงคนอง",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73210,
    district_code: 730602,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730601,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "ท่าตลาด",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730609,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "บางกระทึก",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73210,
    district_code: 730604,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "บางช้าง",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730607,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "บางเตย",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73210,
    district_code: 730605,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730615,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "ยายชา",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730614,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "สามพราน",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730606,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "หอมเกร็ด",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73110,
    district_code: 730603,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "อ้อมใหญ่",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73160,
    district_code: 730616,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "ไร่ขิง",
    amphoe: "สามพราน",
    province: "นครปฐม",
    zipcode: 73210,
    district_code: 730608,
    amphoe_code: 7306,
    province_code: 73,
  },
  {
    tambon: "ดอนยายหอม",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730108,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ตาก้อง",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730105,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ถนนขาด",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730109,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ทัพหลวง",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730123,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ทุ่งน้อย",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730115,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ธรรมศาลา",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730104,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "นครปฐม",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730111,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "บางแขม",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730102,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "บ่อพลับ",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730110,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730125,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "พระปฐมเจดีย์",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730101,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "พระประโทน",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730103,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "มาบแค",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730106,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ลำพยา",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730119,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "วังตะกู",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730112,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "วังเย็น",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730117,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "สนามจันทร์",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730107,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "สระกะเทียม",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730120,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "สวนป่าน",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730121,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "สามควายเผือก",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730114,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "หนองงูเหลือม",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730124,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "หนองดินแดง",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730116,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "หนองปากโลง",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730113,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ห้วยจรเข้",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730122,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "โพรงมะเดื่อ",
    amphoe: "เมืองนครปฐม",
    province: "นครปฐม",
    zipcode: 73000,
    district_code: 730118,
    amphoe_code: 7301,
    province_code: 73,
  },
  {
    tambon: "ท่าจำปา",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480303,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "ท่าอุเทน",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480301,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "พนอม",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480305,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "พะทาย",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480306,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "รามราช",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480312,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "หนองเทา",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480314,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "เวินพระบาท",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480311,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "โนนตาล",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480302,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "ไชยบุรี",
    amphoe: "ท่าอุเทน",
    province: "นครพนม",
    zipcode: 48120,
    district_code: 480304,
    amphoe_code: 4803,
    province_code: 48,
  },
  {
    tambon: "กุดฉิม",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480511,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "ดอนนางหงส์",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480507,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "ธาตุพนม",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480501,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "ธาตุพนมเหนือ",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480512,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "นาถ่อน",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480505,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "นาหนาด",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480510,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "น้ำก่ำ",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480508,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "ฝั่งแดง",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480502,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "พระกลางทุ่ง",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480504,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "อุ่มเหม้า",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480509,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "แสนพัน",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480506,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "โพนแพง",
    amphoe: "ธาตุพนม",
    province: "นครพนม",
    zipcode: 48110,
    district_code: 480503,
    amphoe_code: 4805,
    province_code: 48,
  },
  {
    tambon: "ดอนเตย",
    amphoe: "นาทม",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480407,
    amphoe_code: 4811,
    province_code: 48,
  },
  {
    tambon: "นาทม",
    amphoe: "นาทม",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480405,
    amphoe_code: 4811,
    province_code: 48,
  },
  {
    tambon: "หนองซน",
    amphoe: "นาทม",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480406,
    amphoe_code: 4811,
    province_code: 48,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "นาหว้า",
    province: "นครพนม",
    zipcode: 48180,
    district_code: 480906,
    amphoe_code: 4809,
    province_code: 48,
  },
  {
    tambon: "นาคูณใหญ่",
    amphoe: "นาหว้า",
    province: "นครพนม",
    zipcode: 48180,
    district_code: 480904,
    amphoe_code: 4809,
    province_code: 48,
  },
  {
    tambon: "นางัว",
    amphoe: "นาหว้า",
    province: "นครพนม",
    zipcode: 48180,
    district_code: 480408,
    amphoe_code: 4809,
    province_code: 48,
  },
  {
    tambon: "นาหว้า",
    amphoe: "นาหว้า",
    province: "นครพนม",
    zipcode: 48180,
    district_code: 480901,
    amphoe_code: 4809,
    province_code: 48,
  },
  {
    tambon: "บ้านเสียว",
    amphoe: "นาหว้า",
    province: "นครพนม",
    zipcode: 48180,
    district_code: 480903,
    amphoe_code: 4809,
    province_code: 48,
  },
  {
    tambon: "เหล่าพัฒนา",
    amphoe: "นาหว้า",
    province: "นครพนม",
    zipcode: 48180,
    district_code: 480905,
    amphoe_code: 4809,
    province_code: 48,
  },
  {
    tambon: "ก้านเหลือง",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480707,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "คำพี้",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480713,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "นาคู่",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480704,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "นาเลียง",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480709,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "นาแก",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480701,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "บ้านแก้ง",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480712,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "พระซอง",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480702,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "พิมาน",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480705,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "พุ่มแก",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480706,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "สีชมพู",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480715,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "หนองบ่อ",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480708,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "หนองสังข์",
    amphoe: "นาแก",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480703,
    amphoe_code: 4807,
    province_code: 48,
  },
  {
    tambon: "นางัว",
    amphoe: "บ้านแพง",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480408,
    amphoe_code: 4804,
    province_code: 48,
  },
  {
    tambon: "นาเข",
    amphoe: "บ้านแพง",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480409,
    amphoe_code: 4804,
    province_code: 48,
  },
  {
    tambon: "บ้านแพง",
    amphoe: "บ้านแพง",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480401,
    amphoe_code: 4804,
    province_code: 48,
  },
  {
    tambon: "หนองแวง",
    amphoe: "บ้านแพง",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480404,
    amphoe_code: 4804,
    province_code: 48,
  },
  {
    tambon: "โพนทอง",
    amphoe: "บ้านแพง",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480403,
    amphoe_code: 4804,
    province_code: 48,
  },
  {
    tambon: "ไผ่ล้อม",
    amphoe: "บ้านแพง",
    province: "นครพนม",
    zipcode: 48140,
    district_code: 480402,
    amphoe_code: 4804,
    province_code: 48,
  },
  {
    tambon: "กุตาไก้",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480203,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "นามะเขือ",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480207,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "ปลาปาก",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480201,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "มหาชัย",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480206,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "หนองฮี",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480202,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "หนองเทาใหญ่",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480208,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "โคกสว่าง",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480204,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "โคกสูง",
    amphoe: "ปลาปาก",
    province: "นครพนม",
    zipcode: 48160,
    district_code: 480205,
    amphoe_code: 4802,
    province_code: 48,
  },
  {
    tambon: "ยอดชาด",
    amphoe: "วังยาง",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480714,
    amphoe_code: 4812,
    province_code: 48,
  },
  {
    tambon: "วังยาง",
    amphoe: "วังยาง",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480711,
    amphoe_code: 4812,
    province_code: 48,
  },
  {
    tambon: "หนองโพธิ์",
    amphoe: "วังยาง",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480716,
    amphoe_code: 4812,
    province_code: 48,
  },
  {
    tambon: "โคกสี",
    amphoe: "วังยาง",
    province: "นครพนม",
    zipcode: 48130,
    district_code: 480710,
    amphoe_code: 4812,
    province_code: 48,
  },
  {
    tambon: "ท่าบ่อสงคราม",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480805,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "นาคำ",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480807,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "นาเดื่อ",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480802,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "บ้านข่า",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480806,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "บ้านเอื้อง",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480803,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "ศรีสงคราม",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480801,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "สามผง",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480804,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "หาดแพง",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480809,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "โพนสว่าง",
    amphoe: "ศรีสงคราม",
    province: "นครพนม",
    zipcode: 48150,
    district_code: 480808,
    amphoe_code: 4808,
    province_code: 48,
  },
  {
    tambon: "กุรุคุ",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480105,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "ขามเฒ่า",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480108,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "คำเตย",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480111,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "ดงขวาง",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480113,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "ท่าค้อ",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480110,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "นาทราย",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480103,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "นาราชควาย",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480104,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480109,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "บ้านผึ้ง",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480106,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "วังตามัว",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480114,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "หนองญาติ",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480112,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "หนองแสง",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480102,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "อาจสามารถ",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480107,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "โพธิ์ตาก",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480115,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองนครพนม",
    province: "นครพนม",
    zipcode: 48000,
    district_code: 480101,
    amphoe_code: 4801,
    province_code: 48,
  },
  {
    tambon: "ท่าลาด",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480603,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "นาขาม",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480609,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "นางาม",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480604,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "หนองย่างชิ้น",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480607,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "เรณู",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480601,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "เรณูใต้",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480608,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "โคกหินแฮ่",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480605,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "โพนทอง",
    amphoe: "เรณูนคร",
    province: "นครพนม",
    zipcode: 48170,
    district_code: 480403,
    amphoe_code: 4806,
    province_code: 48,
  },
  {
    tambon: "นาขมิ้น",
    amphoe: "โพนสวรรค์",
    province: "นครพนม",
    zipcode: 48190,
    district_code: 480307,
    amphoe_code: 4810,
    province_code: 48,
  },
  {
    tambon: "นาหัวบ่อ",
    amphoe: "โพนสวรรค์",
    province: "นครพนม",
    zipcode: 48190,
    district_code: 480313,
    amphoe_code: 4810,
    province_code: 48,
  },
  {
    tambon: "นาใน",
    amphoe: "โพนสวรรค์",
    province: "นครพนม",
    zipcode: 48190,
    district_code: 481007,
    amphoe_code: 4810,
    province_code: 48,
  },
  {
    tambon: "บ้านค้อ",
    amphoe: "โพนสวรรค์",
    province: "นครพนม",
    zipcode: 48190,
    district_code: 480310,
    amphoe_code: 4810,
    province_code: 48,
  },
  {
    tambon: "โพนจาน",
    amphoe: "โพนสวรรค์",
    province: "นครพนม",
    zipcode: 48190,
    district_code: 481006,
    amphoe_code: 4810,
    province_code: 48,
  },
  {
    tambon: "โพนบก",
    amphoe: "โพนสวรรค์",
    province: "นครพนม",
    zipcode: 48190,
    district_code: 480308,
    amphoe_code: 4810,
    province_code: 48,
  },
  {
    tambon: "โพนสวรรค์",
    amphoe: "โพนสวรรค์",
    province: "นครพนม",
    zipcode: 48190,
    district_code: 480309,
    amphoe_code: 4810,
    province_code: 48,
  },
  {
    tambon: "ขามทะเลสอ",
    amphoe: "ขามทะเลสอ",
    province: "นครราชสีมา",
    zipcode: 30280,
    district_code: 301901,
    amphoe_code: 3019,
    province_code: 30,
  },
  {
    tambon: "บึงอ้อ",
    amphoe: "ขามทะเลสอ",
    province: "นครราชสีมา",
    zipcode: 30280,
    district_code: 301905,
    amphoe_code: 3019,
    province_code: 30,
  },
  {
    tambon: "พันดุง",
    amphoe: "ขามทะเลสอ",
    province: "นครราชสีมา",
    zipcode: 30280,
    district_code: 301903,
    amphoe_code: 3019,
    province_code: 30,
  },
  {
    tambon: "หนองสรวง",
    amphoe: "ขามทะเลสอ",
    province: "นครราชสีมา",
    zipcode: 30280,
    district_code: 301904,
    amphoe_code: 3019,
    province_code: 30,
  },
  {
    tambon: "โป่งแดง",
    amphoe: "ขามทะเลสอ",
    province: "นครราชสีมา",
    zipcode: 30280,
    district_code: 301902,
    amphoe_code: 3019,
    province_code: 30,
  },
  {
    tambon: "ขามสะแกแสง",
    amphoe: "ขามสะแกแสง",
    province: "นครราชสีมา",
    zipcode: 30290,
    district_code: 301101,
    amphoe_code: 3011,
    province_code: 30,
  },
  {
    tambon: "ชีวึก",
    amphoe: "ขามสะแกแสง",
    province: "นครราชสีมา",
    zipcode: 30290,
    district_code: 301104,
    amphoe_code: 3011,
    province_code: 30,
  },
  {
    tambon: "พะงาด",
    amphoe: "ขามสะแกแสง",
    province: "นครราชสีมา",
    zipcode: 30290,
    district_code: 301105,
    amphoe_code: 3011,
    province_code: 30,
  },
  {
    tambon: "หนองหัวฟาน",
    amphoe: "ขามสะแกแสง",
    province: "นครราชสีมา",
    zipcode: 30290,
    district_code: 301106,
    amphoe_code: 3011,
    province_code: 30,
  },
  {
    tambon: "เมืองนาท",
    amphoe: "ขามสะแกแสง",
    province: "นครราชสีมา",
    zipcode: 30290,
    district_code: 301103,
    amphoe_code: 3011,
    province_code: 30,
  },
  {
    tambon: "เมืองเกษตร",
    amphoe: "ขามสะแกแสง",
    province: "นครราชสีมา",
    zipcode: 30290,
    district_code: 301107,
    amphoe_code: 3011,
    province_code: 30,
  },
  {
    tambon: "โนนเมือง",
    amphoe: "ขามสะแกแสง",
    province: "นครราชสีมา",
    zipcode: 30290,
    district_code: 301102,
    amphoe_code: 3011,
    province_code: 30,
  },
  {
    tambon: "ขามสมบูรณ์",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300410,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "คูขาด",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300402,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "ดอนใหญ่",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300409,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "ตาจั่น",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300404,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "บ้านปรางค์",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300405,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "หนองบัว",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300407,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "หนองมะนาว",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300406,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "เทพาลัย",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300403,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "เมืองคง",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300401,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "โนนเต็ง",
    amphoe: "คง",
    province: "นครราชสีมา",
    zipcode: 30260,
    district_code: 300408,
    amphoe_code: 3004,
    province_code: 30,
  },
  {
    tambon: "ครบุรี",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300203,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "ครบุรีใต้",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300210,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "จระเข้หิน",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300205,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "ตะแบกบาน",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300211,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300113,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "มาบตะโกเอน",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300206,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "ลำเพียก",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300209,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "สระว่านพระยา",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300212,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "อรพิมพ์",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300207,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "เฉลียง",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300202,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "แชะ",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300201,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "โคกกระชาย",
    amphoe: "ครบุรี",
    province: "นครราชสีมา",
    zipcode: 30250,
    district_code: 300204,
    amphoe_code: 3002,
    province_code: 30,
  },
  {
    tambon: "คลองเมือง",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300611,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "จักราช",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300601,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "ทองหลาง",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300603,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "ศรีละกอ",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300610,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "สีสุก",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300604,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "หนองขาม",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300605,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "หนองพลวง",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300607,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "หินโคน",
    amphoe: "จักราช",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300613,
    amphoe_code: 3006,
    province_code: 30,
  },
  {
    tambon: "ชุมพวง",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301701,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "ตลาดไทร",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301306,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "ท่าลาด",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301703,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "ประสุข",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301702,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "สาหร่าย",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301704,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "หนองหลัก",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301714,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "โนนตูม",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301716,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "โนนยอ",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301717,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "โนนรัง",
    amphoe: "ชุมพวง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301710,
    amphoe_code: 3017,
    province_code: 30,
  },
  {
    tambon: "กุดพิมาน",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300801,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "ด่านขุนทด",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300802,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "ด่านนอก",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300803,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "ด่านใน",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300804,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "ตะเคียน",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300805,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "บ้านเก่า",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300806,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "บ้านแปรง",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 36220,
    district_code: 300807,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "พันชนะ",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300808,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "สระจรเข้",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300809,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "หนองกราด",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300810,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "หนองบัวตะเกียด",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300811,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "หนองบัวละคร",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300812,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "หนองไทร",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 36220,
    district_code: 300818,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "หินดาด",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300813,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "ห้วยบง",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300815,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "โนนเมืองพัฒนา",
    amphoe: "ด่านขุนทด",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300817,
    amphoe_code: 3008,
    province_code: 30,
  },
  {
    tambon: "บัวลาย",
    amphoe: "บัวลาย",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301210,
    amphoe_code: 3030,
    province_code: 30,
  },
  {
    tambon: "หนองหว้า",
    amphoe: "บัวลาย",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301209,
    amphoe_code: 3030,
    province_code: 30,
  },
  {
    tambon: "เมืองพะไล",
    amphoe: "บัวลาย",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301222,
    amphoe_code: 3030,
    province_code: 30,
  },
  {
    tambon: "โนนจาน",
    amphoe: "บัวลาย",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301216,
    amphoe_code: 3030,
    province_code: 30,
  },
  {
    tambon: "กุดจอก",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301214,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "ขุนทอง",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301220,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "ดอนตะหนิน",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301206,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "ด่านช้าง",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301215,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "บัวใหญ่",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301201,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "หนองบัวสะอาด",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301207,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "หนองแจ้งใหญ่",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301224,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "ห้วยยาง",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301203,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "เสมาใหญ่",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301204,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "โนนทองหลาง",
    amphoe: "บัวใหญ่",
    province: "นครราชสีมา",
    zipcode: 30120,
    district_code: 301208,
    amphoe_code: 3012,
    province_code: 30,
  },
  {
    tambon: "ช่อระกา",
    amphoe: "บ้านเหลื่อม",
    province: "นครราชสีมา",
    zipcode: 30350,
    district_code: 300504,
    amphoe_code: 3005,
    province_code: 30,
  },
  {
    tambon: "บ้านเหลื่อม",
    amphoe: "บ้านเหลื่อม",
    province: "นครราชสีมา",
    zipcode: 30350,
    district_code: 300501,
    amphoe_code: 3005,
    province_code: 30,
  },
  {
    tambon: "วังโพธิ์",
    amphoe: "บ้านเหลื่อม",
    province: "นครราชสีมา",
    zipcode: 30350,
    district_code: 300502,
    amphoe_code: 3005,
    province_code: 30,
  },
  {
    tambon: "โคกกระเบื้อง",
    amphoe: "บ้านเหลื่อม",
    province: "นครราชสีมา",
    zipcode: 30350,
    district_code: 300503,
    amphoe_code: 3005,
    province_code: 30,
  },
  {
    tambon: "กระทุ่มราย",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301303,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "ดอนมัน",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301310,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "ตลาดไทร",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301306,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "ทุ่งสว่าง",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301315,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "นางรำ",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301313,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "ประทาย",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301301,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "วังไม้แดง",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301304,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "หนองค่าย",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301308,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "หนองพลวง",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 300607,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "หันห้วยทราย",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301309,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "เมืองโดน",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301318,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "โคกกลาง",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301317,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "โนนเพ็ด",
    amphoe: "ประทาย",
    province: "นครราชสีมา",
    zipcode: 30180,
    district_code: 301314,
    amphoe_code: 3013,
    province_code: 30,
  },
  {
    tambon: "งิ้ว",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301410,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "ดอน",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301407,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "ตะขบ",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301405,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "ตะคุ",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301402,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "ตูม",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301409,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "ธงชัยเหนือ",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301417,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "นกออก",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301406,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "บ่อปลาทอง",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301420,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "ภูหลวง",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301416,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "ลำนางแก้ว",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301412,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "สะแกราช",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301411,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "สำโรง",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 300904,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "สุขเกษม",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301418,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "เกษมทรัพย์",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301419,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "เมืองปัก",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301401,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "โคกไทย",
    amphoe: "ปักธงชัย",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301403,
    amphoe_code: 3014,
    province_code: 30,
  },
  {
    tambon: "กลางดง",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30320,
    district_code: 302102,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "ขนงพระ",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302107,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "คลองม่วง",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302109,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "จันทึก",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302103,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "ปากช่อง",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302101,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "พญาเย็น",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30320,
    district_code: 302112,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "วังกะทะ",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302104,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "วังไทร",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302111,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "หนองน้ำแดง",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302110,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "หนองสาหร่าย",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302106,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "หมูสี",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302105,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "โป่งตาลอง",
    amphoe: "ปากช่อง",
    province: "นครราชสีมา",
    zipcode: 30130,
    district_code: 302108,
    amphoe_code: 3021,
    province_code: 30,
  },
  {
    tambon: "ทัพรั้ง",
    amphoe: "พระทองคำ",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300912,
    amphoe_code: 3028,
    province_code: 30,
  },
  {
    tambon: "พังเทียม",
    amphoe: "พระทองคำ",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300910,
    amphoe_code: 3028,
    province_code: 30,
  },
  {
    tambon: "มาบกราด",
    amphoe: "พระทองคำ",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300915,
    amphoe_code: 3028,
    province_code: 30,
  },
  {
    tambon: "สระพระ",
    amphoe: "พระทองคำ",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300911,
    amphoe_code: 3028,
    province_code: 30,
  },
  {
    tambon: "หนองหอย",
    amphoe: "พระทองคำ",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300913,
    amphoe_code: 3028,
    province_code: 30,
  },
  {
    tambon: "กระชอน",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301509,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "กระเบื้องใหญ่",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301504,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "ชีวาน",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301507,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "ดงใหญ่",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301510,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "ท่าหลวง",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301505,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "ธารละหลอด",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301511,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "นิคมสร้างตนเอง",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301508,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "รังกาใหญ่",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301506,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "สัมฤทธิ์",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301502,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "หนองระเวียง",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 300106,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "โบสถ์",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 301503,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "ในเมือง",
    amphoe: "พิมาย",
    province: "นครราชสีมา",
    zipcode: 30110,
    district_code: 300101,
    amphoe_code: 3015,
    province_code: 30,
  },
  {
    tambon: "ขุย",
    amphoe: "ลำทะเมนชัย",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301707,
    amphoe_code: 3029,
    province_code: 30,
  },
  {
    tambon: "ช่องแมว",
    amphoe: "ลำทะเมนชัย",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301706,
    amphoe_code: 3029,
    province_code: 30,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "ลำทะเมนชัย",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301711,
    amphoe_code: 3029,
    province_code: 30,
  },
  {
    tambon: "ไพล",
    amphoe: "ลำทะเมนชัย",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301715,
    amphoe_code: 3029,
    province_code: 30,
  },
  {
    tambon: "ระเริง",
    amphoe: "วังน้ำเขียว",
    province: "นครราชสีมา",
    zipcode: 30150,
    district_code: 301414,
    amphoe_code: 3025,
    province_code: 30,
  },
  {
    tambon: "วังน้ำเขียว",
    amphoe: "วังน้ำเขียว",
    province: "นครราชสีมา",
    zipcode: 30370,
    district_code: 301408,
    amphoe_code: 3025,
    province_code: 30,
  },
  {
    tambon: "วังหมี",
    amphoe: "วังน้ำเขียว",
    province: "นครราชสีมา",
    zipcode: 30370,
    district_code: 301413,
    amphoe_code: 3025,
    province_code: 30,
  },
  {
    tambon: "อุดมทรัพย์",
    amphoe: "วังน้ำเขียว",
    province: "นครราชสีมา",
    zipcode: 30370,
    district_code: 301415,
    amphoe_code: 3025,
    province_code: 30,
  },
  {
    tambon: "ไทยสามัคคี",
    amphoe: "วังน้ำเขียว",
    province: "นครราชสีมา",
    zipcode: 30370,
    district_code: 302505,
    amphoe_code: 3025,
    province_code: 30,
  },
  {
    tambon: "กฤษณา",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302003,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "กุดน้อย",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302006,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "คลองไผ่",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30340,
    district_code: 302010,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "ดอนเมือง",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302011,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "บ้านหัน",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302002,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "มิตรภาพ",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302009,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "ลาดบัวขาว",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30340,
    district_code: 302004,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "วังโรงใหญ่",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302008,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "สีคิ้ว",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302001,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "หนองน้ำใส",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302007,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "หนองบัวน้อย",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302012,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "หนองหญ้าขาว",
    amphoe: "สีคิ้ว",
    province: "นครราชสีมา",
    zipcode: 30140,
    district_code: 302005,
    amphoe_code: 3020,
    province_code: 30,
  },
  {
    tambon: "สามเมือง",
    amphoe: "สีดา",
    province: "นครราชสีมา",
    zipcode: 30430,
    district_code: 301218,
    amphoe_code: 3031,
    province_code: 30,
  },
  {
    tambon: "สีดา",
    amphoe: "สีดา",
    province: "นครราชสีมา",
    zipcode: 30430,
    district_code: 301211,
    amphoe_code: 3031,
    province_code: 30,
  },
  {
    tambon: "หนองตาดใหญ่",
    amphoe: "สีดา",
    province: "นครราชสีมา",
    zipcode: 30430,
    district_code: 301221,
    amphoe_code: 3031,
    province_code: 30,
  },
  {
    tambon: "โนนประดู่",
    amphoe: "สีดา",
    province: "นครราชสีมา",
    zipcode: 30430,
    district_code: 301223,
    amphoe_code: 3031,
    province_code: 30,
  },
  {
    tambon: "โพนทอง",
    amphoe: "สีดา",
    province: "นครราชสีมา",
    zipcode: 30430,
    district_code: 301212,
    amphoe_code: 3031,
    province_code: 30,
  },
  {
    tambon: "กุดจิก",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30380,
    district_code: 301811,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "นากลาง",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30380,
    district_code: 301809,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "บุ่งขี้เหล็ก",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301804,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "มะเกลือเก่า",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301807,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "มะเกลือใหม่",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301808,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "สูงเนิน",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301801,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "หนองตะไก้",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30380,
    district_code: 301810,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "เสมา",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301802,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "โคราช",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301803,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "โค้งยาง",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301806,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "โนนค่า",
    amphoe: "สูงเนิน",
    province: "นครราชสีมา",
    zipcode: 30170,
    district_code: 301805,
    amphoe_code: 3018,
    province_code: 30,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 300113,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "ลุงเขว้า",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 302207,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "สารภี",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 302202,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "หนองตะไก้",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 301810,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "หนองบุนนาก",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 302201,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "หนองหัวแรต",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 302204,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "หนองไม้ไผ่",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 302208,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "แหลมทอง",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 302205,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "ไทยเจริญ",
    amphoe: "หนองบุญมาก",
    province: "นครราชสีมา",
    zipcode: 30410,
    district_code: 302203,
    amphoe_code: 3022,
    province_code: 30,
  },
  {
    tambon: "กงรถ",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301607,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "งิ้ว",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301410,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "ตะโก",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301609,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "ทับสวาย",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301602,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "หลุ่งตะเคียน",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301604,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "หลุ่งประดู่",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301608,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "หินดาด",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 300813,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "ห้วยแคน",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301610,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "ห้วยแถลง",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301601,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "เมืองพลับพลา",
    amphoe: "ห้วยแถลง",
    province: "นครราชสีมา",
    zipcode: 30240,
    district_code: 301603,
    amphoe_code: 3016,
    province_code: 30,
  },
  {
    tambon: "ช้างทอง",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300612,
    amphoe_code: 3032,
    province_code: 30,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300602,
    amphoe_code: 3032,
    province_code: 30,
  },
  {
    tambon: "พระพุทธ",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300609,
    amphoe_code: 3032,
    province_code: 30,
  },
  {
    tambon: "หนองงูเหลือม",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300606,
    amphoe_code: 3032,
    province_code: 30,
  },
  {
    tambon: "หนองยาง",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครราชสีมา",
    zipcode: 30230,
    district_code: 300608,
    amphoe_code: 3032,
    province_code: 30,
  },
  {
    tambon: "บึงปรือ",
    amphoe: "เทพารักษ์",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300819,
    amphoe_code: 3026,
    province_code: 30,
  },
  {
    tambon: "วังยายทอง",
    amphoe: "เทพารักษ์",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 302604,
    amphoe_code: 3026,
    province_code: 30,
  },
  {
    tambon: "สำนักตะคร้อ",
    amphoe: "เทพารักษ์",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300814,
    amphoe_code: 3026,
    province_code: 30,
  },
  {
    tambon: "หนองแวง",
    amphoe: "เทพารักษ์",
    province: "นครราชสีมา",
    zipcode: 30210,
    district_code: 300816,
    amphoe_code: 3026,
    province_code: 30,
  },
  {
    tambon: "จอหอ",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30310,
    district_code: 300116,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "ตลาด",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30310,
    district_code: 300122,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "บ้านเกาะ",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300112,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "บ้านโพธิ์",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30310,
    district_code: 300115,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300113,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "ปรุใหญ่",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300107,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "พลกรัง",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300109,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "พะเนา",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300123,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "พุดซา",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300114,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "มะเริง",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300105,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "สีมุม",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300121,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "สุรนารี",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300120,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หนองกระทุ่ม",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300124,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หนองจะบก",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300103,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หนองบัวศาลา",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300119,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หนองระเวียง",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300106,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หนองไข่น้ำ",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30310,
    district_code: 300125,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หนองไผ่ล้อม",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300110,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หมื่นไวย",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300108,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "หัวทะเล",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300111,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "โคกกรวด",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30280,
    district_code: 300117,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "โคกสูง",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30310,
    district_code: 300104,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "โพธิ์กลาง",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300102,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300101,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "ไชยมงคล",
    amphoe: "เมืองนครราชสีมา",
    province: "นครราชสีมา",
    zipcode: 30000,
    district_code: 300118,
    amphoe_code: 3001,
    province_code: 30,
  },
  {
    tambon: "กระเบื้องนอก",
    amphoe: "เมืองยาง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301708,
    amphoe_code: 3027,
    province_code: 30,
  },
  {
    tambon: "ละหานปลาค้าว",
    amphoe: "เมืองยาง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301712,
    amphoe_code: 3027,
    province_code: 30,
  },
  {
    tambon: "เมืองยาง",
    amphoe: "เมืองยาง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301709,
    amphoe_code: 3027,
    province_code: 30,
  },
  {
    tambon: "โนนอุดม",
    amphoe: "เมืองยาง",
    province: "นครราชสีมา",
    zipcode: 30270,
    district_code: 301713,
    amphoe_code: 3027,
    province_code: 30,
  },
  {
    tambon: "กุดโบสถ์",
    amphoe: "เสิงสาง",
    province: "นครราชสีมา",
    zipcode: 30330,
    district_code: 300304,
    amphoe_code: 3003,
    province_code: 30,
  },
  {
    tambon: "บ้านราษฎร์",
    amphoe: "เสิงสาง",
    province: "นครราชสีมา",
    zipcode: 30330,
    district_code: 300306,
    amphoe_code: 3003,
    province_code: 30,
  },
  {
    tambon: "สระตะเคียน",
    amphoe: "เสิงสาง",
    province: "นครราชสีมา",
    zipcode: 30330,
    district_code: 300302,
    amphoe_code: 3003,
    province_code: 30,
  },
  {
    tambon: "สุขไพบูลย์",
    amphoe: "เสิงสาง",
    province: "นครราชสีมา",
    zipcode: 30330,
    district_code: 300305,
    amphoe_code: 3003,
    province_code: 30,
  },
  {
    tambon: "เสิงสาง",
    amphoe: "เสิงสาง",
    province: "นครราชสีมา",
    zipcode: 30330,
    district_code: 300301,
    amphoe_code: 3003,
    province_code: 30,
  },
  {
    tambon: "โนนสมบูรณ์",
    amphoe: "เสิงสาง",
    province: "นครราชสีมา",
    zipcode: 30330,
    district_code: 300303,
    amphoe_code: 3003,
    province_code: 30,
  },
  {
    tambon: "บึงพะไล",
    amphoe: "แก้งสนามนาง",
    province: "นครราชสีมา",
    zipcode: 30440,
    district_code: 301205,
    amphoe_code: 3023,
    province_code: 30,
  },
  {
    tambon: "บึงสำโรง",
    amphoe: "แก้งสนามนาง",
    province: "นครราชสีมา",
    zipcode: 30440,
    district_code: 302305,
    amphoe_code: 3023,
    province_code: 30,
  },
  {
    tambon: "สีสุก",
    amphoe: "แก้งสนามนาง",
    province: "นครราชสีมา",
    zipcode: 30440,
    district_code: 300604,
    amphoe_code: 3023,
    province_code: 30,
  },
  {
    tambon: "แก้งสนามนาง",
    amphoe: "แก้งสนามนาง",
    province: "นครราชสีมา",
    zipcode: 30440,
    district_code: 301213,
    amphoe_code: 3023,
    province_code: 30,
  },
  {
    tambon: "โนนสำราญ",
    amphoe: "แก้งสนามนาง",
    province: "นครราชสีมา",
    zipcode: 30440,
    district_code: 301219,
    amphoe_code: 3023,
    province_code: 30,
  },
  {
    tambon: "กระโทก",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300701,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ด่านเกวียน",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300710,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ทุ่งอรุณ",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300704,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ท่าจะหลุง",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300706,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ท่าลาดขาว",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300705,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ท่าอ่าง",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300703,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ท่าเยี่ยม",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300707,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "พลับพลา",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300702,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ละลมใหม่พัฒนา",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300709,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "โชคชัย",
    amphoe: "โชคชัย",
    province: "นครราชสีมา",
    zipcode: 30190,
    district_code: 300708,
    amphoe_code: 3007,
    province_code: 30,
  },
  {
    tambon: "ขามเฒ่า",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301011,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "จันอัด",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301010,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ดอนชมพู",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301005,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ดอนหวาย",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301015,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ด่านคล้า",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301012,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ธารปราสาท",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30420,
    district_code: 301006,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "บิง",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301004,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "พลสงคราม",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301009,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "มะค่า",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 300914,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ลำคอหงษ์",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301013,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ลำมูล",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301016,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "หลุมข้าว",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301007,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "เมืองปราสาท",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301014,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "โตนด",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301003,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "โนนสูง",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301001,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ใหม่",
    amphoe: "โนนสูง",
    province: "นครราชสีมา",
    zipcode: 30160,
    district_code: 301002,
    amphoe_code: 3010,
    province_code: 30,
  },
  {
    tambon: "ดอนยาวใหญ่",
    amphoe: "โนนแดง",
    province: "นครราชสีมา",
    zipcode: 30360,
    district_code: 301316,
    amphoe_code: 3024,
    province_code: 30,
  },
  {
    tambon: "วังหิน",
    amphoe: "โนนแดง",
    province: "นครราชสีมา",
    zipcode: 30360,
    district_code: 301305,
    amphoe_code: 3024,
    province_code: 30,
  },
  {
    tambon: "สำพะเนียง",
    amphoe: "โนนแดง",
    province: "นครราชสีมา",
    zipcode: 30360,
    district_code: 301312,
    amphoe_code: 3024,
    province_code: 30,
  },
  {
    tambon: "โนนตาเถร",
    amphoe: "โนนแดง",
    province: "นครราชสีมา",
    zipcode: 30360,
    district_code: 301311,
    amphoe_code: 3024,
    province_code: 30,
  },
  {
    tambon: "โนนแดง",
    amphoe: "โนนแดง",
    province: "นครราชสีมา",
    zipcode: 30360,
    district_code: 301302,
    amphoe_code: 3024,
    province_code: 30,
  },
  {
    tambon: "กำปัง",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300903,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "ค้างพลู",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300905,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "ด่านจาก",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300902,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "ถนนโพธิ์",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300909,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "บัลลังก์",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300907,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "บ้านวัง",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300906,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "มะค่า",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300914,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "สายออ",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300908,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "สำโรง",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300904,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "โนนไทย",
    amphoe: "โนนไทย",
    province: "นครราชสีมา",
    zipcode: 30220,
    district_code: 300901,
    amphoe_code: 3009,
    province_code: 30,
  },
  {
    tambon: "ขนอม",
    amphoe: "ขนอม",
    province: "นครศรีธรรมราช",
    zipcode: 80210,
    district_code: 801501,
    amphoe_code: 8015,
    province_code: 80,
  },
  {
    tambon: "ควนทอง",
    amphoe: "ขนอม",
    province: "นครศรีธรรมราช",
    zipcode: 80210,
    district_code: 801502,
    amphoe_code: 8015,
    province_code: 80,
  },
  {
    tambon: "ท้องเนียน",
    amphoe: "ขนอม",
    province: "นครศรีธรรมราช",
    zipcode: 80210,
    district_code: 801503,
    amphoe_code: 8015,
    province_code: 80,
  },
  {
    tambon: "ควนหนองคว้า",
    amphoe: "จุฬาภรณ์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801311,
    amphoe_code: 8019,
    province_code: 80,
  },
  {
    tambon: "ทุ่งโพธิ์",
    amphoe: "จุฬาภรณ์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801310,
    amphoe_code: 8019,
    province_code: 80,
  },
  {
    tambon: "นาหมอบุญ",
    amphoe: "จุฬาภรณ์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801309,
    amphoe_code: 8019,
    province_code: 80,
  },
  {
    tambon: "บ้านควนมุด",
    amphoe: "จุฬาภรณ์",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800712,
    amphoe_code: 8019,
    province_code: 80,
  },
  {
    tambon: "บ้านชะอวด",
    amphoe: "จุฬาภรณ์",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800713,
    amphoe_code: 8019,
    province_code: 80,
  },
  {
    tambon: "สามตำบล",
    amphoe: "จุฬาภรณ์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801307,
    amphoe_code: 8019,
    province_code: 80,
  },
  {
    tambon: "กะเปียด",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800406,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "จันดี",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80250,
    district_code: 800416,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "ฉวาง",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80150,
    district_code: 800401,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "นากะชะ",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80150,
    district_code: 800407,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "นาเขลียง",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800415,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "นาแว",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800404,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "ละอาย",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80250,
    district_code: 800403,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "ห้วยปริก",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800409,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "ไม้เรียง",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800405,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "ไสหร้า",
    amphoe: "ฉวาง",
    province: "นครศรีธรรมราช",
    zipcode: 80150,
    district_code: 800410,
    amphoe_code: 8004,
    province_code: 80,
  },
  {
    tambon: "ขอนหาด",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800707,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "ควนหนองหงษ์",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800709,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "ชะอวด",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800701,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "ท่าประจะ",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800703,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "ท่าเสม็ด",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800702,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "นางหลง",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800711,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "บ้านตูล",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800706,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "วังอ่าง",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800705,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "เกาะขันธ์",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800708,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "เขาพระทอง",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800710,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "เคร็ง",
    amphoe: "ชะอวด",
    province: "นครศรีธรรมราช",
    zipcode: 80180,
    district_code: 800704,
    amphoe_code: 8007,
    province_code: 80,
  },
  {
    tambon: "ช้างกลาง",
    amphoe: "ช้างกลาง",
    province: "นครศรีธรรมราช",
    zipcode: 80250,
    district_code: 800402,
    amphoe_code: 8022,
    province_code: 80,
  },
  {
    tambon: "สวนขัน",
    amphoe: "ช้างกลาง",
    province: "นครศรีธรรมราช",
    zipcode: 80250,
    district_code: 800412,
    amphoe_code: 8022,
    province_code: 80,
  },
  {
    tambon: "หลักช้าง",
    amphoe: "ช้างกลาง",
    province: "นครศรีธรรมราช",
    zipcode: 80250,
    district_code: 800411,
    amphoe_code: 8022,
    province_code: 80,
  },
  {
    tambon: "คลองเส",
    amphoe: "ถ้ำพรรณรา",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800413,
    amphoe_code: 8018,
    province_code: 80,
  },
  {
    tambon: "ดุสิต",
    amphoe: "ถ้ำพรรณรา",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800414,
    amphoe_code: 8018,
    province_code: 80,
  },
  {
    tambon: "ถ้ำพรรณรา",
    amphoe: "ถ้ำพรรณรา",
    province: "นครศรีธรรมราช",
    zipcode: 80260,
    district_code: 800408,
    amphoe_code: 8018,
    province_code: 80,
  },
  {
    tambon: "กะปาง",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80310,
    district_code: 800908,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "ควนกรด",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800904,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "ชะมาย",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800902,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "ถ้ำใหญ่",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800911,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "ที่วัง",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800909,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "นาหลวงเสน",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800906,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800912,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "นาไม้ไผ่",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800905,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "น้ำตก",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800910,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "ปากแพรก",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800901,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "หนองหงส์",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800903,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "เขาขาว",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800913,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "เขาโร",
    amphoe: "ทุ่งสง",
    province: "นครศรีธรรมราช",
    zipcode: 80110,
    district_code: 800907,
    amphoe_code: 8009,
    province_code: 80,
  },
  {
    tambon: "กรุงหยัน",
    amphoe: "ทุ่งใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80240,
    district_code: 801107,
    amphoe_code: 8011,
    province_code: 80,
  },
  {
    tambon: "กุแหระ",
    amphoe: "ทุ่งใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80240,
    district_code: 801104,
    amphoe_code: 8011,
    province_code: 80,
  },
  {
    tambon: "ทุ่งสัง",
    amphoe: "ทุ่งใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80240,
    district_code: 801102,
    amphoe_code: 8011,
    province_code: 80,
  },
  {
    tambon: "ทุ่งใหญ่",
    amphoe: "ทุ่งใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80240,
    district_code: 801103,
    amphoe_code: 8011,
    province_code: 80,
  },
  {
    tambon: "ท่ายาง",
    amphoe: "ทุ่งใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80240,
    district_code: 801101,
    amphoe_code: 8011,
    province_code: 80,
  },
  {
    tambon: "บางรูป",
    amphoe: "ทุ่งใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80240,
    district_code: 801106,
    amphoe_code: 8011,
    province_code: 80,
  },
  {
    tambon: "ปริก",
    amphoe: "ทุ่งใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80240,
    district_code: 801105,
    amphoe_code: 8011,
    province_code: 80,
  },
  {
    tambon: "กลาย",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800802,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "ดอนตะโก",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800810,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800811,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "ท่าขึ้น",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800803,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "ท่าศาลา",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800801,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800806,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "หัวตะพาน",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800804,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "โพธิ์ทอง",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800813,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "โมคลาน",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800807,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "ไทยบุรี",
    amphoe: "ท่าศาลา",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800809,
    amphoe_code: 8008,
    province_code: 80,
  },
  {
    tambon: "กรุงชิง",
    amphoe: "นบพิตำ",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800812,
    amphoe_code: 8021,
    province_code: 80,
  },
  {
    tambon: "กะหรอ",
    amphoe: "นบพิตำ",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800805,
    amphoe_code: 8021,
    province_code: 80,
  },
  {
    tambon: "นบพิตำ",
    amphoe: "นบพิตำ",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800808,
    amphoe_code: 8021,
    province_code: 80,
  },
  {
    tambon: "นาเหรง",
    amphoe: "นบพิตำ",
    province: "นครศรีธรรมราช",
    zipcode: 80160,
    district_code: 800814,
    amphoe_code: 8021,
    province_code: 80,
  },
  {
    tambon: "ทุ่งสง",
    amphoe: "นาบอน",
    province: "นครศรีธรรมราช",
    zipcode: 80220,
    district_code: 800998,
    amphoe_code: 8010,
    province_code: 80,
  },
  {
    tambon: "นาบอน",
    amphoe: "นาบอน",
    province: "นครศรีธรรมราช",
    zipcode: 80220,
    district_code: 800999,
    amphoe_code: 8010,
    province_code: 80,
  },
  {
    tambon: "แก้วแสน",
    amphoe: "นาบอน",
    province: "นครศรีธรรมราช",
    zipcode: 80220,
    district_code: 800997,
    amphoe_code: 8010,
    province_code: 80,
  },
  {
    tambon: "บางขัน",
    amphoe: "บางขัน",
    province: "นครศรีธรรมราช",
    zipcode: 80360,
    district_code: 800996,
    amphoe_code: 8017,
    province_code: 80,
  },
  {
    tambon: "บ้านนิคม",
    amphoe: "บางขัน",
    province: "นครศรีธรรมราช",
    zipcode: 80360,
    district_code: 801704,
    amphoe_code: 8017,
    province_code: 80,
  },
  {
    tambon: "บ้านลำนาว",
    amphoe: "บางขัน",
    province: "นครศรีธรรมราช",
    zipcode: 80360,
    district_code: 800995,
    amphoe_code: 8017,
    province_code: 80,
  },
  {
    tambon: "วังหิน",
    amphoe: "บางขัน",
    province: "นครศรีธรรมราช",
    zipcode: 80360,
    district_code: 800994,
    amphoe_code: 8017,
    province_code: 80,
  },
  {
    tambon: "ขนาบนาก",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 801218,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "คลองกระบือ",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80370,
    district_code: 801205,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "คลองน้อย",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80330,
    district_code: 801202,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ชะเมา",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80330,
    district_code: 801204,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ท่าพยา",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 801216,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "บางตะพง",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80370,
    district_code: 801213,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "บางพระ",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 801212,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "บางศาลา",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80370,
    district_code: 801211,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "บ้านเพิง",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 801215,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80370,
    district_code: 801207,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ปากพนัง",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 801201,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ปากพนังฝั่งตะวันตก",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80370,
    district_code: 801210,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ปากพนังฝั่งตะวันออก",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 801214,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ปากแพรก",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 800901,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ป่าระกำ",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80370,
    district_code: 801203,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "หูล่อง",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80370,
    district_code: 801208,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "เกาะทวด",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80330,
    district_code: 801206,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "แหลมตะลุมพุก",
    amphoe: "ปากพนัง",
    province: "นครศรีธรรมราช",
    zipcode: 80140,
    district_code: 801209,
    amphoe_code: 8012,
    province_code: 80,
  },
  {
    tambon: "ทอนหงส์",
    amphoe: "พรหมคีรี",
    province: "นครศรีธรรมราช",
    zipcode: 80320,
    district_code: 800204,
    amphoe_code: 8002,
    province_code: 80,
  },
  {
    tambon: "นาเรียง",
    amphoe: "พรหมคีรี",
    province: "นครศรีธรรมราช",
    zipcode: 80320,
    district_code: 800205,
    amphoe_code: 8002,
    province_code: 80,
  },
  {
    tambon: "บ้านเกาะ",
    amphoe: "พรหมคีรี",
    province: "นครศรีธรรมราช",
    zipcode: 80320,
    district_code: 800199,
    amphoe_code: 8002,
    province_code: 80,
  },
  {
    tambon: "พรหมโลก",
    amphoe: "พรหมคีรี",
    province: "นครศรีธรรมราช",
    zipcode: 80320,
    district_code: 800196,
    amphoe_code: 8002,
    province_code: 80,
  },
  {
    tambon: "อินคีรี",
    amphoe: "พรหมคีรี",
    province: "นครศรีธรรมราช",
    zipcode: 80320,
    district_code: 800195,
    amphoe_code: 8002,
    province_code: 80,
  },
  {
    tambon: "ช้างซ้าย",
    amphoe: "พระพรหม",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800110,
    amphoe_code: 8020,
    province_code: 80,
  },
  {
    tambon: "ท้ายสำเภา",
    amphoe: "พระพรหม",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800117,
    amphoe_code: 8020,
    province_code: 80,
  },
  {
    tambon: "นาพรุ",
    amphoe: "พระพรหม",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800109,
    amphoe_code: 8020,
    province_code: 80,
  },
  {
    tambon: "นาสาร",
    amphoe: "พระพรหม",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800111,
    amphoe_code: 8020,
    province_code: 80,
  },
  {
    tambon: "กะทูน",
    amphoe: "พิปูน",
    province: "นครศรีธรรมราช",
    zipcode: 80270,
    district_code: 800502,
    amphoe_code: 8005,
    province_code: 80,
  },
  {
    tambon: "ควนกลาง",
    amphoe: "พิปูน",
    province: "นครศรีธรรมราช",
    zipcode: 80270,
    district_code: 800505,
    amphoe_code: 8005,
    province_code: 80,
  },
  {
    tambon: "พิปูน",
    amphoe: "พิปูน",
    province: "นครศรีธรรมราช",
    zipcode: 80270,
    district_code: 800501,
    amphoe_code: 8005,
    province_code: 80,
  },
  {
    tambon: "ยางค้อม",
    amphoe: "พิปูน",
    province: "นครศรีธรรมราช",
    zipcode: 80270,
    district_code: 800504,
    amphoe_code: 8005,
    province_code: 80,
  },
  {
    tambon: "เขาพระ",
    amphoe: "พิปูน",
    province: "นครศรีธรรมราช",
    zipcode: 80270,
    district_code: 800503,
    amphoe_code: 8005,
    province_code: 80,
  },
  {
    tambon: "ควนชุม",
    amphoe: "ร่อนพิบูลย์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801306,
    amphoe_code: 8013,
    province_code: 80,
  },
  {
    tambon: "ควนพัง",
    amphoe: "ร่อนพิบูลย์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801305,
    amphoe_code: 8013,
    province_code: 80,
  },
  {
    tambon: "ควนเกย",
    amphoe: "ร่อนพิบูลย์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801304,
    amphoe_code: 8013,
    province_code: 80,
  },
  {
    tambon: "ร่อนพิบูลย์",
    amphoe: "ร่อนพิบูลย์",
    province: "นครศรีธรรมราช",
    zipcode: 80130,
    district_code: 801301,
    amphoe_code: 8013,
    province_code: 80,
  },
  {
    tambon: "หินตก",
    amphoe: "ร่อนพิบูลย์",
    province: "นครศรีธรรมราช",
    zipcode: 80350,
    district_code: 801302,
    amphoe_code: 8013,
    province_code: 80,
  },
  {
    tambon: "เสาธง",
    amphoe: "ร่อนพิบูลย์",
    province: "นครศรีธรรมราช",
    zipcode: 80350,
    district_code: 801303,
    amphoe_code: 8013,
    province_code: 80,
  },
  {
    tambon: "กำโลน",
    amphoe: "ลานสกา",
    province: "นครศรีธรรมราช",
    zipcode: 80230,
    district_code: 800304,
    amphoe_code: 8003,
    province_code: 80,
  },
  {
    tambon: "ขุนทะเล",
    amphoe: "ลานสกา",
    province: "นครศรีธรรมราช",
    zipcode: 80230,
    district_code: 800305,
    amphoe_code: 8003,
    province_code: 80,
  },
  {
    tambon: "ท่าดี",
    amphoe: "ลานสกา",
    province: "นครศรีธรรมราช",
    zipcode: 80230,
    district_code: 800303,
    amphoe_code: 8003,
    province_code: 80,
  },
  {
    tambon: "ลานสกา",
    amphoe: "ลานสกา",
    province: "นครศรีธรรมราช",
    zipcode: 80230,
    district_code: 800302,
    amphoe_code: 8003,
    province_code: 80,
  },
  {
    tambon: "เขาแก้ว",
    amphoe: "ลานสกา",
    province: "นครศรีธรรมราช",
    zipcode: 80230,
    district_code: 800301,
    amphoe_code: 8003,
    province_code: 80,
  },
  {
    tambon: "ฉลอง",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80120,
    district_code: 801403,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "ทุ่งปรัง",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80120,
    district_code: 801402,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "ทุ่งใส",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80120,
    district_code: 801409,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "สิชล",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80120,
    district_code: 801401,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "สี่ขีด",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80120,
    district_code: 801406,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "เขาน้อย",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80120,
    district_code: 801408,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "เทพราช",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80340,
    district_code: 801407,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "เปลี่ยน",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80120,
    district_code: 801405,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "เสาเภา",
    amphoe: "สิชล",
    province: "นครศรีธรรมราช",
    zipcode: 80340,
    district_code: 801404,
    amphoe_code: 8014,
    province_code: 80,
  },
  {
    tambon: "ควนชะลิก",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801609,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "ทรายขาว",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801603,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "ท่าซอม",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801608,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "บางนบ",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801607,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "บ้านราม",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801606,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "รามแก้ว",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801610,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "หน้าสตน",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801602,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "หัวไทร",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801601,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "เกาะเพชร",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801611,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "เขาพังไกร",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801605,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "แหลม",
    amphoe: "หัวไทร",
    province: "นครศรีธรรมราช",
    zipcode: 80170,
    district_code: 801604,
    amphoe_code: 8016,
    province_code: 80,
  },
  {
    tambon: "ดอนตรอ",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครศรีธรรมราช",
    zipcode: 80290,
    district_code: 800608,
    amphoe_code: 8023,
    province_code: 80,
  },
  {
    tambon: "ทางพูน",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครศรีธรรมราช",
    zipcode: 80290,
    district_code: 801308,
    amphoe_code: 8023,
    province_code: 80,
  },
  {
    tambon: "สวนหลวง",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800609,
    amphoe_code: 8023,
    province_code: 80,
  },
  {
    tambon: "เชียรเขา",
    amphoe: "เฉลิมพระเกียรติ",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800602,
    amphoe_code: 8023,
    province_code: 80,
  },
  {
    tambon: "การะเกด",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800611,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "ท่าขนาน",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800603,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "ท้องลำเจียก",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800607,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800604,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "บ้านเนิน",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800605,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "เขาพระบาท",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800612,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "เชียรใหญ่",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800601,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "เสือหึง",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800610,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "แม่เจ้าอยู่หัว",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800613,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "ไสหมาก",
    amphoe: "เชียรใหญ่",
    province: "นครศรีธรรมราช",
    zipcode: 80190,
    district_code: 800606,
    amphoe_code: 8006,
    province_code: 80,
  },
  {
    tambon: "กำแพงเซา",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80280,
    district_code: 800112,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "คลัง",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800103,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ท่างิ้ว",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80280,
    district_code: 800116,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ท่าซัก",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800121,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ท่าวัง",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800102,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800122,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ท่าไร่",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800106,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "นาทราย",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80280,
    district_code: 800108,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "นาเคียน",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800115,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "บางจาก",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80330,
    district_code: 800119,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ปากนคร",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800107,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ปากพูน",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800120,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "มะม่วงสองต้น",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800114,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "โพธิ์เสด็จ",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800118,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800101,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ไชยมนตรี",
    amphoe: "เมืองนครศรีธรรมราช",
    province: "นครศรีธรรมราช",
    zipcode: 80000,
    district_code: 800113,
    amphoe_code: 8001,
    province_code: 80,
  },
  {
    tambon: "ชุมตาบง",
    amphoe: "ชุมตาบง",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601308,
    amphoe_code: 6015,
    province_code: 60,
  },
  {
    tambon: "ปางสวรรค์",
    amphoe: "ชุมตาบง",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601306,
    amphoe_code: 6015,
    province_code: 60,
  },
  {
    tambon: "ฆะมัง",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600311,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "ชุมแสง",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600301,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "ทับกฤช",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60250,
    district_code: 600302,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "ทับกฤชใต้",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60250,
    district_code: 600312,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "ท่าไม้",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600305,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "บางเคียน",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600306,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "พันลาน",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60250,
    district_code: 600308,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "พิกุล",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600303,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "หนองกระเจา",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600307,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "เกยไชย",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600304,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "โคกหม้อ",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600309,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "ไผ่สิงห์",
    amphoe: "ชุมแสง",
    province: "นครสวรรค์",
    zipcode: 60120,
    district_code: 600310,
    amphoe_code: 6003,
    province_code: 60,
  },
  {
    tambon: "ตากฟ้า",
    amphoe: "ตากฟ้า",
    province: "นครสวรรค์",
    zipcode: 60190,
    district_code: 601201,
    amphoe_code: 6012,
    province_code: 60,
  },
  {
    tambon: "พุนกยูง",
    amphoe: "ตากฟ้า",
    province: "นครสวรรค์",
    zipcode: 60190,
    district_code: 601205,
    amphoe_code: 6012,
    province_code: 60,
  },
  {
    tambon: "ลำพยนต์",
    amphoe: "ตากฟ้า",
    province: "นครสวรรค์",
    zipcode: 60190,
    district_code: 601202,
    amphoe_code: 6012,
    province_code: 60,
  },
  {
    tambon: "สุขสำราญ",
    amphoe: "ตากฟ้า",
    province: "นครสวรรค์",
    zipcode: 60190,
    district_code: 601203,
    amphoe_code: 6012,
    province_code: 60,
  },
  {
    tambon: "หนองพิกุล",
    amphoe: "ตากฟ้า",
    province: "นครสวรรค์",
    zipcode: 60190,
    district_code: 601204,
    amphoe_code: 6012,
    province_code: 60,
  },
  {
    tambon: "อุดมธัญญา",
    amphoe: "ตากฟ้า",
    province: "นครสวรรค์",
    zipcode: 60190,
    district_code: 601206,
    amphoe_code: 6012,
    province_code: 60,
  },
  {
    tambon: "เขาชายธง",
    amphoe: "ตากฟ้า",
    province: "นครสวรรค์",
    zipcode: 60190,
    district_code: 601207,
    amphoe_code: 6012,
    province_code: 60,
  },
  {
    tambon: "จันเสน",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60260,
    district_code: 600703,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "ช่องแค",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60210,
    district_code: 600702,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "ตาคลี",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60140,
    district_code: 600701,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "พรหมนิมิต",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60210,
    district_code: 600710,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "ลาดทิพรส",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60260,
    district_code: 600709,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "สร้อยทอง",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60210,
    district_code: 600708,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "หนองหม้อ",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60140,
    district_code: 600707,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "หนองโพ",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60140,
    district_code: 600706,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "หัวหวาย",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60140,
    district_code: 600705,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "ห้วยหอม",
    amphoe: "ตาคลี",
    province: "นครสวรรค์",
    zipcode: 60210,
    district_code: 600704,
    amphoe_code: 6007,
    province_code: 60,
  },
  {
    tambon: "ดอนคา",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600806,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "ทำนบ",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600807,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "ท่าตะโก",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600801,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "พนมรอก",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600802,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "พนมเศษ",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600809,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "วังมหากร",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600805,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "วังใหญ่",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600808,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "สายลำโพง",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600804,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600810,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "หัวถนน",
    amphoe: "ท่าตะโก",
    province: "นครสวรรค์",
    zipcode: 60160,
    district_code: 600803,
    amphoe_code: 6008,
    province_code: 60,
  },
  {
    tambon: "ด่านช้าง",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600509,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "ตาขีด",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600507,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "ตาสัง",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600508,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "ท่างิ้ว",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600501,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "บางตาหงาย",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600502,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600506,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "บึงปลาทู",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600512,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "บ้านแดน",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600505,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "หนองกรด",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600114,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "หนองตางู",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600511,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "หูกวาง",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600503,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "อ่างทอง",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600504,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "เจริญผล",
    amphoe: "บรรพตพิสัย",
    province: "นครสวรรค์",
    zipcode: 60180,
    district_code: 600513,
    amphoe_code: 6005,
    province_code: 60,
  },
  {
    tambon: "ท่าน้ำอ้อย",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601008,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "นิคมเขาบ่อแก้ว",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601003,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "น้ำทรง",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601009,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "พยุหะ",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601001,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "ม่วงหัก",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601004,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "ยางขาว",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601005,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "ย่านมัทรี",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601006,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "สระทะเล",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601011,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "เขากะลา",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601010,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "เขาทอง",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601007,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "เนินมะกอก",
    amphoe: "พยุหะคีรี",
    province: "นครสวรรค์",
    zipcode: 60130,
    district_code: 601002,
    amphoe_code: 6010,
    province_code: 60,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601109,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "มาบแก",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601106,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "ลาดยาว",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601101,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "วังม้า",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601103,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "วังเมือง",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601104,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "ศาลเจ้าไก่ต่อ",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601116,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601117,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "สร้อยละคร",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601105,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "หนองนมวัว",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601108,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "หนองยาว",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601107,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "ห้วยน้ำหอม",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601102,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "เนินขี้เหล็ก",
    amphoe: "ลาดยาว",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601110,
    amphoe_code: 6011,
    province_code: 60,
  },
  {
    tambon: "ทุ่งทอง",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600408,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "ธารทหาร",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600403,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "วังบ่อ",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600409,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "หนองกลับ",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600402,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "หนองบัว",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600401,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "ห้วยถั่วเหนือ",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600406,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "ห้วยถั่วใต้",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600405,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "ห้วยร่วม",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600404,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "ห้วยใหญ่",
    amphoe: "หนองบัว",
    province: "นครสวรรค์",
    zipcode: 60110,
    district_code: 600407,
    amphoe_code: 6004,
    province_code: 60,
  },
  {
    tambon: "มหาโพธิ",
    amphoe: "เก้าเลี้ยว",
    province: "นครสวรรค์",
    zipcode: 60230,
    district_code: 600601,
    amphoe_code: 6006,
    province_code: 60,
  },
  {
    tambon: "หนองเต่า",
    amphoe: "เก้าเลี้ยว",
    province: "นครสวรรค์",
    zipcode: 60230,
    district_code: 600603,
    amphoe_code: 6006,
    province_code: 60,
  },
  {
    tambon: "หัวดง",
    amphoe: "เก้าเลี้ยว",
    province: "นครสวรรค์",
    zipcode: 60230,
    district_code: 600605,
    amphoe_code: 6006,
    province_code: 60,
  },
  {
    tambon: "เก้าเลี้ยว",
    amphoe: "เก้าเลี้ยว",
    province: "นครสวรรค์",
    zipcode: 60230,
    district_code: 600602,
    amphoe_code: 6006,
    province_code: 60,
  },
  {
    tambon: "เขาดิน",
    amphoe: "เก้าเลี้ยว",
    province: "นครสวรรค์",
    zipcode: 60230,
    district_code: 600604,
    amphoe_code: 6006,
    province_code: 60,
  },
  {
    tambon: "กลางแดด",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600102,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "ตะเคียนเลื่อน",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600105,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "นครสวรรค์ตก",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600106,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "นครสวรรค์ออก",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600107,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "บางพระหลวง",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600108,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "บางม่วง",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600109,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "บึงเสนาท",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600117,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "บ้านมะเกลือ",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600110,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "บ้านแก่ง",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600111,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "ปากน้ำโพ",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600101,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "พระนอน",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600112,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "วัดไทรย์",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: false,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "หนองกรด",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60240,
    district_code: 600114,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "หนองกระโดน",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60240,
    district_code: 600115,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "หนองปลิง",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600116,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "เกรียงไกร",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600103,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "แควใหญ่",
    amphoe: "เมืองนครสวรรค์",
    province: "นครสวรรค์",
    zipcode: 60000,
    district_code: 600104,
    amphoe_code: 6001,
    province_code: 60,
  },
  {
    tambon: "วังซ่าน",
    amphoe: "แม่วงก์",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601304,
    amphoe_code: 6013,
    province_code: 60,
  },
  {
    tambon: "เขาชนกัน",
    amphoe: "แม่วงก์",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601305,
    amphoe_code: 6013,
    province_code: 60,
  },
  {
    tambon: "แม่วงก์",
    amphoe: "แม่วงก์",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601301,
    amphoe_code: 6013,
    province_code: 60,
  },
  {
    tambon: "แม่เล่ย์",
    amphoe: "แม่วงก์",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601303,
    amphoe_code: 6013,
    province_code: 60,
  },
  {
    tambon: "แม่เปิน",
    amphoe: "แม่เปิน",
    province: "นครสวรรค์",
    zipcode: 60150,
    district_code: 601307,
    amphoe_code: 6014,
    province_code: 60,
  },
  {
    tambon: "นากลาง",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600205,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "บางประมุง",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600204,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "บางมะฝ่อ",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600203,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "ยางตาล",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600202,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "ศาลาแดง",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600206,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "หาดสูง",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600209,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "เนินกว้าว",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600207,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "เนินศาลา",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600208,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "โกรกพระ",
    amphoe: "โกรกพระ",
    province: "นครสวรรค์",
    zipcode: 60170,
    district_code: 600201,
    amphoe_code: 6002,
    province_code: 60,
  },
  {
    tambon: "ตะคร้อ",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600904,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "นาขอม",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600907,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "วังข่อย",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600906,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "วังน้ำลัด",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600903,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "สำโรงชัย",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600902,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "โคกเดื่อ",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600901,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "โพธิ์ประสาท",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600905,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "ไพศาลี",
    amphoe: "ไพศาลี",
    province: "นครสวรรค์",
    zipcode: 60220,
    district_code: 600908,
    amphoe_code: 6009,
    province_code: 60,
  },
  {
    tambon: "บางกรวย",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120202,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "บางขนุน",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120204,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "บางขุนกอง",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120205,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "บางคูเวียง",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120206,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "บางสีทอง",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120203,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "ปลายบาง",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120208,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "มหาสวัสดิ์",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120207,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "วัดชลอ",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120201,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "ศาลากลาง",
    amphoe: "บางกรวย",
    province: "นนทบุรี",
    zipcode: 11130,
    district_code: 120209,
    amphoe_code: 1202,
    province_code: 12,
  },
  {
    tambon: "บางคูรัด",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120404,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "บางบัวทอง",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120402,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "บางรักพัฒนา",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120408,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "บางรักใหญ่",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120403,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "พิมลราช",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120407,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "ละหาร",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120405,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "ลำโพ",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120406,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "โสนลอย",
    amphoe: "บางบัวทอง",
    province: "นนทบุรี",
    zipcode: 11110,
    district_code: 120401,
    amphoe_code: 1204,
    province_code: 12,
  },
  {
    tambon: "บางม่วง",
    amphoe: "บางใหญ่",
    province: "นนทบุรี",
    zipcode: 11140,
    district_code: 120301,
    amphoe_code: 1203,
    province_code: 12,
  },
  {
    tambon: "บางเลน",
    amphoe: "บางใหญ่",
    province: "นนทบุรี",
    zipcode: 11140,
    district_code: 120303,
    amphoe_code: 1203,
    province_code: 12,
  },
  {
    tambon: "บางแม่นาง",
    amphoe: "บางใหญ่",
    province: "นนทบุรี",
    zipcode: 11140,
    district_code: 120302,
    amphoe_code: 1203,
    province_code: 12,
  },
  {
    tambon: "บางใหญ่",
    amphoe: "บางใหญ่",
    province: "นนทบุรี",
    zipcode: 11140,
    district_code: 120305,
    amphoe_code: 1203,
    province_code: 12,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "บางใหญ่",
    province: "นนทบุรี",
    zipcode: 11140,
    district_code: 120306,
    amphoe_code: 1203,
    province_code: 12,
  },
  {
    tambon: "เสาธงหิน",
    amphoe: "บางใหญ่",
    province: "นนทบุรี",
    zipcode: 11140,
    district_code: 120304,
    amphoe_code: 1203,
    province_code: 12,
  },
  {
    tambon: "คลองข่อย",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120610,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "คลองพระอุดม",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120606,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "คลองเกลือ",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120612,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "ท่าอิฐ",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120607,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "บางตลาด",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120602,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "บางตะไนย์",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120605,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "บางพลับ",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120611,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "บางพูด",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120604,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120306,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "ปากเกร็ด",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120601,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "อ้อมเกร็ด",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120609,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "เกาะเกร็ด",
    amphoe: "ปากเกร็ด",
    province: "นนทบุรี",
    zipcode: 11120,
    district_code: 120608,
    amphoe_code: 1206,
    province_code: 12,
  },
  {
    tambon: "ตลาดขวัญ",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120102,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "ท่าทราย",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120105,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "บางกระสอ",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120104,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "บางกร่าง",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120108,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "บางรักน้อย",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120110,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "บางศรีเมือง",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120107,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "บางเขน",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120103,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "บางไผ่",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120106,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "สวนใหญ่",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120101,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "ไทรม้า",
    amphoe: "เมืองนนทบุรี",
    province: "นนทบุรี",
    zipcode: 11000,
    district_code: 120109,
    amphoe_code: 1201,
    province_code: 12,
  },
  {
    tambon: "ขุนศรี",
    amphoe: "ไทรน้อย",
    province: "นนทบุรี",
    zipcode: 11150,
    district_code: 120505,
    amphoe_code: 1205,
    province_code: 12,
  },
  {
    tambon: "คลองขวาง",
    amphoe: "ไทรน้อย",
    province: "นนทบุรี",
    zipcode: 11150,
    district_code: 120506,
    amphoe_code: 1205,
    province_code: 12,
  },
  {
    tambon: "ทวีวัฒนา",
    amphoe: "ไทรน้อย",
    province: "นนทบุรี",
    zipcode: 11150,
    district_code: 120507,
    amphoe_code: 1205,
    province_code: 12,
  },
  {
    tambon: "ราษฎร์นิยม",
    amphoe: "ไทรน้อย",
    province: "นนทบุรี",
    zipcode: 11150,
    district_code: 120502,
    amphoe_code: 1205,
    province_code: 12,
  },
  {
    tambon: "หนองเพรางาย",
    amphoe: "ไทรน้อย",
    province: "นนทบุรี",
    zipcode: 11150,
    district_code: 120503,
    amphoe_code: 1205,
    province_code: 12,
  },
  {
    tambon: "ไทรน้อย",
    amphoe: "ไทรน้อย",
    province: "นนทบุรี",
    zipcode: 11150,
    district_code: 120501,
    amphoe_code: 1205,
    province_code: 12,
  },
  {
    tambon: "ไทรใหญ่",
    amphoe: "ไทรน้อย",
    province: "นนทบุรี",
    zipcode: 11150,
    district_code: 120504,
    amphoe_code: 1205,
    province_code: 12,
  },
  {
    tambon: "จะแนะ",
    amphoe: "จะแนะ",
    province: "นราธิวาส",
    zipcode: 96220,
    district_code: 960599,
    amphoe_code: 9612,
    province_code: 96,
  },
  {
    tambon: "ช้างเผือก",
    amphoe: "จะแนะ",
    province: "นราธิวาส",
    zipcode: 96220,
    district_code: 961204,
    amphoe_code: 9612,
    province_code: 96,
  },
  {
    tambon: "ดุซงญอ",
    amphoe: "จะแนะ",
    province: "นราธิวาส",
    zipcode: 96220,
    district_code: 960598,
    amphoe_code: 9612,
    province_code: 96,
  },
  {
    tambon: "ผดุงมาตร",
    amphoe: "จะแนะ",
    province: "นราธิวาส",
    zipcode: 96220,
    district_code: 961203,
    amphoe_code: 9612,
    province_code: 96,
  },
  {
    tambon: "นานาค",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960207,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "บางขุนทอง",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960205,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "พร่อน",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960203,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "ศาลาใหม่",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960204,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "เกาะสะท้อน",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960206,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "เจ๊ะเห",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960201,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "โฆษิต",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960208,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "ไพรวัน",
    amphoe: "ตากใบ",
    province: "นราธิวาส",
    zipcode: 96110,
    district_code: 960202,
    amphoe_code: 9602,
    province_code: 96,
  },
  {
    tambon: "กาเยาะมาตี",
    amphoe: "บาเจาะ",
    province: "นราธิวาส",
    zipcode: 96170,
    district_code: 960303,
    amphoe_code: 9603,
    province_code: 96,
  },
  {
    tambon: "บาเจาะ",
    amphoe: "บาเจาะ",
    province: "นราธิวาส",
    zipcode: 96170,
    district_code: 960301,
    amphoe_code: 9603,
    province_code: 96,
  },
  {
    tambon: "บาเระเหนือ",
    amphoe: "บาเจาะ",
    province: "นราธิวาส",
    zipcode: 96170,
    district_code: 960305,
    amphoe_code: 9603,
    province_code: 96,
  },
  {
    tambon: "บาเระใต้",
    amphoe: "บาเจาะ",
    province: "นราธิวาส",
    zipcode: 96170,
    district_code: 960306,
    amphoe_code: 9603,
    province_code: 96,
  },
  {
    tambon: "ปะลุกาสาเมาะ",
    amphoe: "บาเจาะ",
    province: "นราธิวาส",
    zipcode: 96170,
    district_code: 960304,
    amphoe_code: 9603,
    province_code: 96,
  },
  {
    tambon: "ลุโบะสาวอ",
    amphoe: "บาเจาะ",
    province: "นราธิวาส",
    zipcode: 96170,
    district_code: 960302,
    amphoe_code: 9603,
    province_code: 96,
  },
  {
    tambon: "จอเบาะ",
    amphoe: "ยี่งอ",
    province: "นราธิวาส",
    zipcode: 96180,
    district_code: 960403,
    amphoe_code: 9604,
    province_code: 96,
  },
  {
    tambon: "ตะปอเยาะ",
    amphoe: "ยี่งอ",
    province: "นราธิวาส",
    zipcode: 96180,
    district_code: 960406,
    amphoe_code: 9604,
    province_code: 96,
  },
  {
    tambon: "ยี่งอ",
    amphoe: "ยี่งอ",
    province: "นราธิวาส",
    zipcode: 96180,
    district_code: 960401,
    amphoe_code: 9604,
    province_code: 96,
  },
  {
    tambon: "ละหาร",
    amphoe: "ยี่งอ",
    province: "นราธิวาส",
    zipcode: 96180,
    district_code: 960402,
    amphoe_code: 9604,
    province_code: 96,
  },
  {
    tambon: "ลุโบะบายะ",
    amphoe: "ยี่งอ",
    province: "นราธิวาส",
    zipcode: 96180,
    district_code: 960404,
    amphoe_code: 9604,
    province_code: 96,
  },
  {
    tambon: "ลุโบะบือซา",
    amphoe: "ยี่งอ",
    province: "นราธิวาส",
    zipcode: 96180,
    district_code: 960405,
    amphoe_code: 9604,
    province_code: 96,
  },
  {
    tambon: "กาลิซา",
    amphoe: "ระแงะ",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960507,
    amphoe_code: 9605,
    province_code: 96,
  },
  {
    tambon: "ตันหยงมัส",
    amphoe: "ระแงะ",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960501,
    amphoe_code: 9605,
    province_code: 96,
  },
  {
    tambon: "ตันหยงลิมอ",
    amphoe: "ระแงะ",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960502,
    amphoe_code: 9605,
    province_code: 96,
  },
  {
    tambon: "บองอ",
    amphoe: "ระแงะ",
    province: "นราธิวาส",
    zipcode: 96220,
    district_code: 960506,
    amphoe_code: 9605,
    province_code: 96,
  },
  {
    tambon: "บาโงสะโต",
    amphoe: "ระแงะ",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960508,
    amphoe_code: 9605,
    province_code: 96,
  },
  {
    tambon: "มะรือโบตก",
    amphoe: "ระแงะ",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960510,
    amphoe_code: 9605,
    province_code: 96,
  },
  {
    tambon: "เฉลิม",
    amphoe: "ระแงะ",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960509,
    amphoe_code: 9605,
    province_code: 96,
  },
  {
    tambon: "บาตง",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960605,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "รือเสาะ",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960601,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "รือเสาะออก",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960607,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "ลาโละ",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960606,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "สามัคคี",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960604,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "สาวอ",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960602,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "สุวารี",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960609,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "เรียง",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960603,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "โคกสะตอ",
    amphoe: "รือเสาะ",
    province: "นราธิวาส",
    zipcode: 96150,
    district_code: 960608,
    amphoe_code: 9606,
    province_code: 96,
  },
  {
    tambon: "กาหลง",
    amphoe: "ศรีสาคร",
    province: "นราธิวาส",
    zipcode: 96210,
    district_code: 960705,
    amphoe_code: 9607,
    province_code: 96,
  },
  {
    tambon: "ซากอ",
    amphoe: "ศรีสาคร",
    province: "นราธิวาส",
    zipcode: 96210,
    district_code: 960701,
    amphoe_code: 9607,
    province_code: 96,
  },
  {
    tambon: "ตะมะยูง",
    amphoe: "ศรีสาคร",
    province: "นราธิวาส",
    zipcode: 96210,
    district_code: 960698,
    amphoe_code: 9607,
    province_code: 96,
  },
  {
    tambon: "ศรีบรรพต",
    amphoe: "ศรีสาคร",
    province: "นราธิวาส",
    zipcode: 96210,
    district_code: 960706,
    amphoe_code: 9607,
    province_code: 96,
  },
  {
    tambon: "ศรีสาคร",
    amphoe: "ศรีสาคร",
    province: "นราธิวาส",
    zipcode: 96210,
    district_code: 960703,
    amphoe_code: 9607,
    province_code: 96,
  },
  {
    tambon: "เชิงคีรี",
    amphoe: "ศรีสาคร",
    province: "นราธิวาส",
    zipcode: 96210,
    district_code: 960704,
    amphoe_code: 9607,
    province_code: 96,
  },
  {
    tambon: "ภูเขาทอง",
    amphoe: "สุคิริน",
    province: "นราธิวาส",
    zipcode: 96190,
    district_code: 960904,
    amphoe_code: 9609,
    province_code: 96,
  },
  {
    tambon: "มาโมง",
    amphoe: "สุคิริน",
    province: "นราธิวาส",
    zipcode: 96190,
    district_code: 960901,
    amphoe_code: 9609,
    province_code: 96,
  },
  {
    tambon: "ร่มไทร",
    amphoe: "สุคิริน",
    province: "นราธิวาส",
    zipcode: 96190,
    district_code: 960905,
    amphoe_code: 9609,
    province_code: 96,
  },
  {
    tambon: "สุคิริน",
    amphoe: "สุคิริน",
    province: "นราธิวาส",
    zipcode: 96190,
    district_code: 960902,
    amphoe_code: 9609,
    province_code: 96,
  },
  {
    tambon: "เกียร์",
    amphoe: "สุคิริน",
    province: "นราธิวาส",
    zipcode: 96190,
    district_code: 960903,
    amphoe_code: 9609,
    province_code: 96,
  },
  {
    tambon: "กาวะ",
    amphoe: "สุไหงปาดี",
    province: "นราธิวาส",
    zipcode: 96140,
    district_code: 961106,
    amphoe_code: 9611,
    province_code: 96,
  },
  {
    tambon: "ปะลุรู",
    amphoe: "สุไหงปาดี",
    province: "นราธิวาส",
    zipcode: 96140,
    district_code: 961101,
    amphoe_code: 9611,
    province_code: 96,
  },
  {
    tambon: "ริโก๋",
    amphoe: "สุไหงปาดี",
    province: "นราธิวาส",
    zipcode: 96140,
    district_code: 961105,
    amphoe_code: 9611,
    province_code: 96,
  },
  {
    tambon: "สากอ",
    amphoe: "สุไหงปาดี",
    province: "นราธิวาส",
    zipcode: 96140,
    district_code: 961104,
    amphoe_code: 9611,
    province_code: 96,
  },
  {
    tambon: "สุไหงปาดี",
    amphoe: "สุไหงปาดี",
    province: "นราธิวาส",
    zipcode: 96140,
    district_code: 961102,
    amphoe_code: 9611,
    province_code: 96,
  },
  {
    tambon: "โต๊ะเด็ง",
    amphoe: "สุไหงปาดี",
    province: "นราธิวาส",
    zipcode: 96140,
    district_code: 961103,
    amphoe_code: 9611,
    province_code: 96,
  },
  {
    tambon: "ปาเสมัส",
    amphoe: "สุไหงโก-ลก",
    province: "นราธิวาส",
    zipcode: 96120,
    district_code: 961002,
    amphoe_code: 9610,
    province_code: 96,
  },
  {
    tambon: "ปูโยะ",
    amphoe: "สุไหงโก-ลก",
    province: "นราธิวาส",
    zipcode: 96120,
    district_code: 961004,
    amphoe_code: 9610,
    province_code: 96,
  },
  {
    tambon: "มูโนะ",
    amphoe: "สุไหงโก-ลก",
    province: "นราธิวาส",
    zipcode: 96120,
    district_code: 961003,
    amphoe_code: 9610,
    province_code: 96,
  },
  {
    tambon: "สุไหงโก-ลก",
    amphoe: "สุไหงโก-ลก",
    province: "นราธิวาส",
    zipcode: 96120,
    district_code: 961001,
    amphoe_code: 9610,
    province_code: 96,
  },
  {
    tambon: "จวบ",
    amphoe: "เจาะไอร้อง",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960503,
    amphoe_code: 9613,
    province_code: 96,
  },
  {
    tambon: "บูกิต",
    amphoe: "เจาะไอร้อง",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 960505,
    amphoe_code: 9613,
    province_code: 96,
  },
  {
    tambon: "มะรือโบออก",
    amphoe: "เจาะไอร้อง",
    province: "นราธิวาส",
    zipcode: 96130,
    district_code: 961303,
    amphoe_code: 9613,
    province_code: 96,
  },
  {
    tambon: "กะลุวอ",
    amphoe: "เมืองนราธิวาส",
    province: "นราธิวาส",
    zipcode: 96000,
    district_code: 960105,
    amphoe_code: 9601,
    province_code: 96,
  },
  {
    tambon: "กะลุวอเหนือ",
    amphoe: "เมืองนราธิวาส",
    province: "นราธิวาส",
    zipcode: 96000,
    district_code: 960106,
    amphoe_code: 9601,
    province_code: 96,
  },
  {
    tambon: "บางนาค",
    amphoe: "เมืองนราธิวาส",
    province: "นราธิวาส",
    zipcode: 96000,
    district_code: 960101,
    amphoe_code: 9601,
    province_code: 96,
  },
  {
    tambon: "บางปอ",
    amphoe: "เมืองนราธิวาส",
    province: "นราธิวาส",
    zipcode: 96000,
    district_code: 960104,
    amphoe_code: 9601,
    province_code: 96,
  },
  {
    tambon: "มะนังตายอ",
    amphoe: "เมืองนราธิวาส",
    province: "นราธิวาส",
    zipcode: 96000,
    district_code: 960103,
    amphoe_code: 9601,
    province_code: 96,
  },
  {
    tambon: "ลำภู",
    amphoe: "เมืองนราธิวาส",
    province: "นราธิวาส",
    zipcode: 96000,
    district_code: 960102,
    amphoe_code: 9601,
    province_code: 96,
  },
  {
    tambon: "โคกเคียน",
    amphoe: "เมืองนราธิวาส",
    province: "นราธิวาส",
    zipcode: 96000,
    district_code: 960107,
    amphoe_code: 9601,
    province_code: 96,
  },
  {
    tambon: "กายูคละ",
    amphoe: "แว้ง",
    province: "นราธิวาส",
    zipcode: 96160,
    district_code: 960802,
    amphoe_code: 9608,
    province_code: 96,
  },
  {
    tambon: "ฆอเลาะ",
    amphoe: "แว้ง",
    province: "นราธิวาส",
    zipcode: 96160,
    district_code: 960803,
    amphoe_code: 9608,
    province_code: 96,
  },
  {
    tambon: "เอราวัณ",
    amphoe: "แว้ง",
    province: "นราธิวาส",
    zipcode: 96160,
    district_code: 960806,
    amphoe_code: 9608,
    province_code: 96,
  },
  {
    tambon: "แม่ดง",
    amphoe: "แว้ง",
    province: "นราธิวาส",
    zipcode: 96160,
    district_code: 960805,
    amphoe_code: 9608,
    province_code: 96,
  },
  {
    tambon: "แว้ง",
    amphoe: "แว้ง",
    province: "นราธิวาส",
    zipcode: 96160,
    district_code: 960801,
    amphoe_code: 9608,
    province_code: 96,
  },
  {
    tambon: "โละจูด",
    amphoe: "แว้ง",
    province: "นราธิวาส",
    zipcode: 96160,
    district_code: 960804,
    amphoe_code: 9608,
    province_code: 96,
  },
  {
    tambon: "งอบ",
    amphoe: "ทุ่งช้าง",
    province: "น่าน",
    zipcode: 55130,
    district_code: 550802,
    amphoe_code: 5508,
    province_code: 55,
  },
  {
    tambon: "ทุ่งช้าง",
    amphoe: "ทุ่งช้าง",
    province: "น่าน",
    zipcode: 55130,
    district_code: 550804,
    amphoe_code: 5508,
    province_code: 55,
  },
  {
    tambon: "ปอน",
    amphoe: "ทุ่งช้าง",
    province: "น่าน",
    zipcode: 55130,
    district_code: 550801,
    amphoe_code: 5508,
    province_code: 55,
  },
  {
    tambon: "และ",
    amphoe: "ทุ่งช้าง",
    province: "น่าน",
    zipcode: 55130,
    district_code: 550803,
    amphoe_code: 5508,
    province_code: 55,
  },
  {
    tambon: "จอมพระ",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550607,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ตาลชุม",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550605,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ท่าวังผา",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550609,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ป่าคา",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550602,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ผาตอ",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550603,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ผาทอง",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550610,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ยม",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550604,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ริม",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550601,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "ศรีภูมิ",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550606,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "แสนทอง",
    amphoe: "ท่าวังผา",
    province: "น่าน",
    zipcode: 55140,
    district_code: 550608,
    amphoe_code: 5506,
    province_code: 55,
  },
  {
    tambon: "นาน้อย",
    amphoe: "นาน้อย",
    province: "น่าน",
    zipcode: 55150,
    district_code: 550401,
    amphoe_code: 5504,
    province_code: 55,
  },
  {
    tambon: "น้ำตก",
    amphoe: "นาน้อย",
    province: "น่าน",
    zipcode: 55150,
    district_code: 550407,
    amphoe_code: 5504,
    province_code: 55,
  },
  {
    tambon: "บัวใหญ่",
    amphoe: "นาน้อย",
    province: "น่าน",
    zipcode: 55150,
    district_code: 550406,
    amphoe_code: 5504,
    province_code: 55,
  },
  {
    tambon: "ศรีษะเกษ",
    amphoe: "นาน้อย",
    province: "น่าน",
    zipcode: 55150,
    district_code: 550403,
    amphoe_code: 5504,
    province_code: 55,
  },
  {
    tambon: "สถาน",
    amphoe: "นาน้อย",
    province: "น่าน",
    zipcode: 55150,
    district_code: 550404,
    amphoe_code: 5504,
    province_code: 55,
  },
  {
    tambon: "สันทะ",
    amphoe: "นาน้อย",
    province: "น่าน",
    zipcode: 55150,
    district_code: 550405,
    amphoe_code: 5504,
    province_code: 55,
  },
  {
    tambon: "เชียงของ",
    amphoe: "นาน้อย",
    province: "น่าน",
    zipcode: 55150,
    district_code: 550402,
    amphoe_code: 5504,
    province_code: 55,
  },
  {
    tambon: "นาทะนุง",
    amphoe: "นาหมื่น",
    province: "น่าน",
    zipcode: 55180,
    district_code: 551001,
    amphoe_code: 5510,
    province_code: 55,
  },
  {
    tambon: "บ่อแก้ว",
    amphoe: "นาหมื่น",
    province: "น่าน",
    zipcode: 55180,
    district_code: 551002,
    amphoe_code: 5510,
    province_code: 55,
  },
  {
    tambon: "ปิงหลวง",
    amphoe: "นาหมื่น",
    province: "น่าน",
    zipcode: 55180,
    district_code: 551004,
    amphoe_code: 5510,
    province_code: 55,
  },
  {
    tambon: "เมืองลี",
    amphoe: "นาหมื่น",
    province: "น่าน",
    zipcode: 55180,
    district_code: 551003,
    amphoe_code: 5510,
    province_code: 55,
  },
  {
    tambon: "ดงพญา",
    amphoe: "บ่อเกลือ",
    province: "น่าน",
    zipcode: 55220,
    district_code: 551205,
    amphoe_code: 5512,
    province_code: 55,
  },
  {
    tambon: "บ่อเกลือเหนือ",
    amphoe: "บ่อเกลือ",
    province: "น่าน",
    zipcode: 55220,
    district_code: 550507,
    amphoe_code: 5512,
    province_code: 55,
  },
  {
    tambon: "บ่อเกลือใต้",
    amphoe: "บ่อเกลือ",
    province: "น่าน",
    zipcode: 55220,
    district_code: 550508,
    amphoe_code: 5512,
    province_code: 55,
  },
  {
    tambon: "ภูฟ้า",
    amphoe: "บ่อเกลือ",
    province: "น่าน",
    zipcode: 55220,
    district_code: 551204,
    amphoe_code: 5512,
    province_code: 55,
  },
  {
    tambon: "บ้านพี้",
    amphoe: "บ้านหลวง",
    province: "น่าน",
    zipcode: 55190,
    district_code: 550304,
    amphoe_code: 5503,
    province_code: 55,
  },
  {
    tambon: "บ้านฟ้า",
    amphoe: "บ้านหลวง",
    province: "น่าน",
    zipcode: 55190,
    district_code: 550198,
    amphoe_code: 5503,
    province_code: 55,
  },
  {
    tambon: "ป่าคาหลวง",
    amphoe: "บ้านหลวง",
    province: "น่าน",
    zipcode: 55190,
    district_code: 550196,
    amphoe_code: 5503,
    province_code: 55,
  },
  {
    tambon: "สวด",
    amphoe: "บ้านหลวง",
    province: "น่าน",
    zipcode: 55190,
    district_code: 550303,
    amphoe_code: 5503,
    province_code: 55,
  },
  {
    tambon: "ปัว",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550501,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "ป่ากลาง",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550513,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "ภูคา",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550511,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "วรนคร",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550514,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "ศิลาเพชร",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550505,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "ศิลาแลง",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550504,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "สกาด",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550512,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "สถาน",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550404,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "อวน",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550506,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "เจดีย์ชัย",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550510,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "แงง",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550502,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "ไชยวัฒนา",
    amphoe: "ปัว",
    province: "น่าน",
    zipcode: 55120,
    district_code: 550509,
    amphoe_code: 5505,
    province_code: 55,
  },
  {
    tambon: "ท่าน้าว",
    amphoe: "ภูเพียง",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550112,
    amphoe_code: 5514,
    province_code: 55,
  },
  {
    tambon: "นาปัง",
    amphoe: "ภูเพียง",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550113,
    amphoe_code: 5514,
    province_code: 55,
  },
  {
    tambon: "น้ำเกี๋ยน",
    amphoe: "ภูเพียง",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550118,
    amphoe_code: 5514,
    province_code: 55,
  },
  {
    tambon: "น้ำแก่น",
    amphoe: "ภูเพียง",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550115,
    amphoe_code: 5514,
    province_code: 55,
  },
  {
    tambon: "ฝายแก้ว",
    amphoe: "ภูเพียง",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550110,
    amphoe_code: 5514,
    province_code: 55,
  },
  {
    tambon: "ม่วงตึ๊ด",
    amphoe: "ภูเพียง",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550111,
    amphoe_code: 5514,
    province_code: 55,
  },
  {
    tambon: "เมืองจัง",
    amphoe: "ภูเพียง",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550114,
    amphoe_code: 5514,
    province_code: 55,
  },
  {
    tambon: "ชนแดน",
    amphoe: "สองแคว",
    province: "น่าน",
    zipcode: 55160,
    district_code: 551302,
    amphoe_code: 5513,
    province_code: 55,
  },
  {
    tambon: "นาไร่หลวง",
    amphoe: "สองแคว",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550998,
    amphoe_code: 5513,
    province_code: 55,
  },
  {
    tambon: "ยอด",
    amphoe: "สองแคว",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550999,
    amphoe_code: 5513,
    province_code: 55,
  },
  {
    tambon: "ดู่พงษ์",
    amphoe: "สันติสุข",
    province: "น่าน",
    zipcode: 55210,
    district_code: 550199,
    amphoe_code: 5511,
    province_code: 55,
  },
  {
    tambon: "ป่าแลวหลวง",
    amphoe: "สันติสุข",
    province: "น่าน",
    zipcode: 55210,
    district_code: 551102,
    amphoe_code: 5511,
    province_code: 55,
  },
  {
    tambon: "พงษ์",
    amphoe: "สันติสุข",
    province: "น่าน",
    zipcode: 55210,
    district_code: 550201,
    amphoe_code: 5511,
    province_code: 55,
  },
  {
    tambon: "ขุนน่าน",
    amphoe: "เฉลิมพระเกียรติ",
    province: "น่าน",
    zipcode: 55130,
    district_code: 551203,
    amphoe_code: 5515,
    province_code: 55,
  },
  {
    tambon: "ห้วยโก๋น",
    amphoe: "เฉลิมพระเกียรติ",
    province: "น่าน",
    zipcode: 55130,
    district_code: 550805,
    amphoe_code: 5515,
    province_code: 55,
  },
  {
    tambon: "พญาแก้ว",
    amphoe: "เชียงกลาง",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550908,
    amphoe_code: 5509,
    province_code: 55,
  },
  {
    tambon: "พระธาตุ",
    amphoe: "เชียงกลาง",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550904,
    amphoe_code: 5509,
    province_code: 55,
  },
  {
    tambon: "พระพุทธบาท",
    amphoe: "เชียงกลาง",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550909,
    amphoe_code: 5509,
    province_code: 55,
  },
  {
    tambon: "เชียงกลาง",
    amphoe: "เชียงกลาง",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550899,
    amphoe_code: 5509,
    province_code: 55,
  },
  {
    tambon: "เชียงคาน",
    amphoe: "เชียงกลาง",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550903,
    amphoe_code: 5509,
    province_code: 55,
  },
  {
    tambon: "เปือ",
    amphoe: "เชียงกลาง",
    province: "น่าน",
    zipcode: 55160,
    district_code: 550898,
    amphoe_code: 5509,
    province_code: 55,
  },
  {
    tambon: "กองควาย",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550109,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "ดู่ใต้",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550108,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "ถืมตอง",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550105,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "นาซาว",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550107,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "บ่อ",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550102,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "ผาสิงห์",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550103,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "สวก",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550116,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "สะเนียน",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550117,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "เรือง",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550106,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "ในเวียง",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550101,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "ไชยสถาน",
    amphoe: "เมืองน่าน",
    province: "น่าน",
    zipcode: 55000,
    district_code: 550104,
    amphoe_code: 5501,
    province_code: 55,
  },
  {
    tambon: "กลางเวียง",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550701,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ขึ่ง",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550702,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "จอมจันทร์",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550715,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ตาลชุม",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550605,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ทุ่งศรีทอง",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550717,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "นาเหลือง",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550705,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "น้ำปั้ว",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550708,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "น้ำมวบ",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550707,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ปงสนุก",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550710,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ยาบหัวนา",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550709,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ส้าน",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550706,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ส้านนาหนองใหม่",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550712,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "อ่ายนาไลย",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550711,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "แม่ขะนิง",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550713,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "แม่สา",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550716,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "แม่สาคร",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550714,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "ไหล่น่าน",
    amphoe: "เวียงสา",
    province: "น่าน",
    zipcode: 55110,
    district_code: 550703,
    amphoe_code: 5507,
    province_code: 55,
  },
  {
    tambon: "น้ำปาย",
    amphoe: "แม่จริม",
    province: "น่าน",
    zipcode: 55170,
    district_code: 550205,
    amphoe_code: 5502,
    province_code: 55,
  },
  {
    tambon: "น้ำพาง",
    amphoe: "แม่จริม",
    province: "น่าน",
    zipcode: 55170,
    district_code: 550204,
    amphoe_code: 5502,
    province_code: 55,
  },
  {
    tambon: "หนองแดง",
    amphoe: "แม่จริม",
    province: "น่าน",
    zipcode: 55170,
    district_code: 550202,
    amphoe_code: 5502,
    province_code: 55,
  },
  {
    tambon: "หมอเมือง",
    amphoe: "แม่จริม",
    province: "น่าน",
    zipcode: 55170,
    district_code: 550197,
    amphoe_code: 5502,
    province_code: 55,
  },
  {
    tambon: "แม่จริม",
    amphoe: "แม่จริม",
    province: "น่าน",
    zipcode: 55170,
    district_code: 550206,
    amphoe_code: 5502,
    province_code: 55,
  },
  {
    tambon: "ดงบัง",
    amphoe: "บึงโขงหลง",
    province: "บึงกาฬ",
    zipcode: 38220,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ท่าดอกคำ",
    amphoe: "บึงโขงหลง",
    province: "บึงกาฬ",
    zipcode: 38220,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "บึงโขงหลง",
    amphoe: "บึงโขงหลง",
    province: "บึงกาฬ",
    zipcode: 38220,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โพธิ์หมากแข้ง",
    amphoe: "บึงโขงหลง",
    province: "บึงกาฬ",
    zipcode: 38220,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "บุ่งคล้า",
    amphoe: "บุ่งคล้า",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "หนองเดิ่น",
    amphoe: "บุ่งคล้า",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โคกกว้าง",
    amphoe: "บุ่งคล้า",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "นากั้ง",
    amphoe: "ปากคาด",
    province: "บึงกาฬ",
    zipcode: 38190,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "นาดง",
    amphoe: "ปากคาด",
    province: "บึงกาฬ",
    zipcode: 38190,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ปากคาด",
    amphoe: "ปากคาด",
    province: "บึงกาฬ",
    zipcode: 38190,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "สมสนุก",
    amphoe: "ปากคาด",
    province: "บึงกาฬ",
    zipcode: 38190,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "หนองยอง",
    amphoe: "ปากคาด",
    province: "บึงกาฬ",
    zipcode: 38190,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โนนศิลา",
    amphoe: "ปากคาด",
    province: "บึงกาฬ",
    zipcode: 38190,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ดอนหญ้านาง",
    amphoe: "พรเจริญ",
    province: "บึงกาฬ",
    zipcode: 38180,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ป่าแฝก",
    amphoe: "พรเจริญ",
    province: "บึงกาฬ",
    zipcode: 38180,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "พรเจริญ",
    amphoe: "พรเจริญ",
    province: "บึงกาฬ",
    zipcode: 38180,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "วังชมภู",
    amphoe: "พรเจริญ",
    province: "บึงกาฬ",
    zipcode: 38180,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ศรีชมภู",
    amphoe: "พรเจริญ",
    province: "บึงกาฬ",
    zipcode: 38180,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ศรีสำราญ",
    amphoe: "พรเจริญ",
    province: "บึงกาฬ",
    zipcode: 38180,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "หนองหัวช้าง",
    amphoe: "พรเจริญ",
    province: "บึงกาฬ",
    zipcode: 38180,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ชุมภูพร",
    amphoe: "ศรีวิไล",
    province: "บึงกาฬ",
    zipcode: 38210,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "นาสะแบง",
    amphoe: "ศรีวิไล",
    province: "บึงกาฬ",
    zipcode: 38210,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "นาสิงห์",
    amphoe: "ศรีวิไล",
    province: "บึงกาฬ",
    zipcode: 38210,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "นาแสง",
    amphoe: "ศรีวิไล",
    province: "บึงกาฬ",
    zipcode: 38210,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ศรีวิไล",
    amphoe: "ศรีวิไล",
    province: "บึงกาฬ",
    zipcode: 38210,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ซาง",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ท่ากกแดง",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ท่าสะอาด",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "น้ำจั้น",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "บ้านต้อง",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ป่งไฮ",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "หนองทุ่ม",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "เซกา",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โสกก่าม",
    amphoe: "เซกา",
    province: "บึงกาฬ",
    zipcode: 38150,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "คำนาดี",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ชัยพร",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "นาสวรรค์",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "บึงกาฬ",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "วิศิษฐ์",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โนนสว่าง",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "หนองเลิง",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "หอคำ",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โคกก่อง",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โนนสมบูรณ์",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โป่งเปือย",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ไคสี",
    amphoe: "เมืองบึงกาฬ",
    province: "บึงกาฬ",
    zipcode: 38000,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "คำแก้ว",
    amphoe: "โซ่พิสัย",
    province: "บึงกาฬ",
    zipcode: 38170,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ถ้ำเจริญ",
    amphoe: "โซ่พิสัย",
    province: "บึงกาฬ",
    zipcode: 38170,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "บัวตูม",
    amphoe: "โซ่พิสัย",
    province: "บึงกาฬ",
    zipcode: 38170,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "ศรีชมภู",
    amphoe: "โซ่พิสัย",
    province: "บึงกาฬ",
    zipcode: 38170,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "หนองพันทา",
    amphoe: "โซ่พิสัย",
    province: "บึงกาฬ",
    zipcode: 38170,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "เหล่าทอง",
    amphoe: "โซ่พิสัย",
    province: "บึงกาฬ",
    zipcode: 38170,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "โซ่",
    amphoe: "โซ่พิสัย",
    province: "บึงกาฬ",
    zipcode: 38170,
    district_code: false,
    amphoe_code: false,
    province_code: 97,
  },
  {
    tambon: "กระสัง",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310127,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "กันทรารมย์",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310310,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "ชุมแสง",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310307,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "บ้านปรือ",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310308,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "ลำดวน",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310302,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "ศรีภูมิ",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310311,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "สองชั้น",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310303,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "สูงเนิน",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310304,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "หนองเต็ง",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310305,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "ห้วยสำราญ",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310309,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "เมืองไผ่",
    amphoe: "กระสัง",
    province: "บุรีรัมย์",
    zipcode: 31160,
    district_code: 310306,
    amphoe_code: 3103,
    province_code: 31,
  },
  {
    tambon: "คูเมือง",
    amphoe: "คูเมือง",
    province: "บุรีรัมย์",
    zipcode: 31190,
    district_code: 310201,
    amphoe_code: 3102,
    province_code: 31,
  },
  {
    tambon: "ตูมใหญ่",
    amphoe: "คูเมือง",
    province: "บุรีรัมย์",
    zipcode: 31190,
    district_code: 310206,
    amphoe_code: 3102,
    province_code: 31,
  },
  {
    tambon: "บ้านแพ",
    amphoe: "คูเมือง",
    province: "บุรีรัมย์",
    zipcode: 31190,
    district_code: 310203,
    amphoe_code: 3102,
    province_code: 31,
  },
  {
    tambon: "ปะเคียบ",
    amphoe: "คูเมือง",
    province: "บุรีรัมย์",
    zipcode: 31190,
    district_code: 310198,
    amphoe_code: 3102,
    province_code: 31,
  },
  {
    tambon: "พรสำราญ",
    amphoe: "คูเมือง",
    province: "บุรีรัมย์",
    zipcode: 31190,
    district_code: 310204,
    amphoe_code: 3102,
    province_code: 31,
  },
  {
    tambon: "หนองขมาร",
    amphoe: "คูเมือง",
    province: "บุรีรัมย์",
    zipcode: 31190,
    district_code: 310207,
    amphoe_code: 3102,
    province_code: 31,
  },
  {
    tambon: "หินเหล็กไฟ",
    amphoe: "คูเมือง",
    province: "บุรีรัมย์",
    zipcode: 31190,
    district_code: 310205,
    amphoe_code: 3102,
    province_code: 31,
  },
  {
    tambon: "ชำนิ",
    amphoe: "ชำนิ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310404,
    amphoe_code: 3118,
    province_code: 31,
  },
  {
    tambon: "ช่อผกา",
    amphoe: "ชำนิ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310422,
    amphoe_code: 3118,
    province_code: 31,
  },
  {
    tambon: "ละลวด",
    amphoe: "ชำนิ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310423,
    amphoe_code: 3118,
    province_code: 31,
  },
  {
    tambon: "หนองปล่อง",
    amphoe: "ชำนิ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310407,
    amphoe_code: 3118,
    province_code: 31,
  },
  {
    tambon: "เมืองยาง",
    amphoe: "ชำนิ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310410,
    amphoe_code: 3118,
    province_code: 31,
  },
  {
    tambon: "โคกสนวน",
    amphoe: "ชำนิ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 311806,
    amphoe_code: 3118,
    province_code: 31,
  },
  {
    tambon: "ก้านเหลือง",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310415,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "ชุมแสง",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310307,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "ถนนหัก",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310413,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "ทรัพย์พระยา",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310418,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "ทุ่งแสงทอง",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310426,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "นางรอง",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310401,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "บ้านสิงห์",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310416,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "ลำไทรโยง",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310417,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "สะเดา",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310403,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "หนองกง",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310408,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "หนองยายพิมพ์",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310424,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "หนองโบสถ์",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310406,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "หนองโสน",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310427,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "หนองไทร",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310414,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "หัวถนน",
    amphoe: "นางรอง",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310425,
    amphoe_code: 3104,
    province_code: 31,
  },
  {
    tambon: "ดอนกอก",
    amphoe: "นาโพธิ์",
    province: "บุรีรัมย์",
    zipcode: 31230,
    district_code: 311304,
    amphoe_code: 3113,
    province_code: 31,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "นาโพธิ์",
    province: "บุรีรัมย์",
    zipcode: 31230,
    district_code: 311301,
    amphoe_code: 3113,
    province_code: 31,
  },
  {
    tambon: "บ้านคู",
    amphoe: "นาโพธิ์",
    province: "บุรีรัมย์",
    zipcode: 31230,
    district_code: 311302,
    amphoe_code: 3113,
    province_code: 31,
  },
  {
    tambon: "บ้านดู่",
    amphoe: "นาโพธิ์",
    province: "บุรีรัมย์",
    zipcode: 31230,
    district_code: 311303,
    amphoe_code: 3113,
    province_code: 31,
  },
  {
    tambon: "ศรีสว่าง",
    amphoe: "นาโพธิ์",
    province: "บุรีรัมย์",
    zipcode: 31230,
    district_code: 311305,
    amphoe_code: 3113,
    province_code: 31,
  },
  {
    tambon: "จันทบเพชร",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310808,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "บึงเจริญ",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310807,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "บ้านกรวด",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310801,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "ปราสาท",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310111,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "สายตะกู",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310805,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "หนองไม้งาม",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310803,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "หินลาด",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310806,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "เขาดินเหนือ",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310809,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "โนนเจริญ",
    amphoe: "บ้านกรวด",
    province: "บุรีรัมย์",
    zipcode: 31180,
    district_code: 310802,
    amphoe_code: 3108,
    province_code: 31,
  },
  {
    tambon: "บ้านด่าน",
    amphoe: "บ้านด่าน",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310109,
    amphoe_code: 3121,
    province_code: 31,
  },
  {
    tambon: "ปราสาท",
    amphoe: "บ้านด่าน",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310111,
    amphoe_code: 3121,
    province_code: 31,
  },
  {
    tambon: "วังเหนือ",
    amphoe: "บ้านด่าน",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310123,
    amphoe_code: 3121,
    province_code: 31,
  },
  {
    tambon: "โนนขวาง",
    amphoe: "บ้านด่าน",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310115,
    amphoe_code: 3121,
    province_code: 31,
  },
  {
    tambon: "กู่สวนแตง",
    amphoe: "บ้านใหม่ไชยพจน์",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310911,
    amphoe_code: 3119,
    province_code: 31,
  },
  {
    tambon: "ทองหลาง",
    amphoe: "บ้านใหม่ไชยพจน์",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310905,
    amphoe_code: 3119,
    province_code: 31,
  },
  {
    tambon: "หนองเยือง",
    amphoe: "บ้านใหม่ไชยพจน์",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310912,
    amphoe_code: 3119,
    province_code: 31,
  },
  {
    tambon: "หนองแวง",
    amphoe: "บ้านใหม่ไชยพจน์",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310607,
    amphoe_code: 3119,
    province_code: 31,
  },
  {
    tambon: "แดงใหญ่",
    amphoe: "บ้านใหม่ไชยพจน์",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 311903,
    amphoe_code: 3119,
    province_code: 31,
  },
  {
    tambon: "จรเข้มาก",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310706,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "ตะโกตาพิ",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310714,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "บ้านไทร",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310703,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "ประทัดบุ",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310720,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "ประโคนชัย",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310701,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "ปังกู",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310707,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "ละเวี้ย",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310705,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "สี่เหลี่ยม",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310721,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "หนองบอน",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310716,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "เขาคอก",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310715,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "แสลงโทน",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310702,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "โคกตูม",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310719,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "โคกมะขาม",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310718,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "โคกม้า",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310710,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "โคกย่าง",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310708,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "ไพศาล",
    amphoe: "ประโคนชัย",
    province: "บุรีรัมย์",
    zipcode: 31140,
    district_code: 310713,
    amphoe_code: 3107,
    province_code: 31,
  },
  {
    tambon: "ปะคำ",
    amphoe: "ปะคำ",
    province: "บุรีรัมย์",
    zipcode: 31220,
    district_code: 311201,
    amphoe_code: 3112,
    province_code: 31,
  },
  {
    tambon: "หนองบัว",
    amphoe: "ปะคำ",
    province: "บุรีรัมย์",
    zipcode: 31220,
    district_code: 311203,
    amphoe_code: 3112,
    province_code: 31,
  },
  {
    tambon: "หูทำนบ",
    amphoe: "ปะคำ",
    province: "บุรีรัมย์",
    zipcode: 31220,
    district_code: 311205,
    amphoe_code: 3112,
    province_code: 31,
  },
  {
    tambon: "โคกมะม่วง",
    amphoe: "ปะคำ",
    province: "บุรีรัมย์",
    zipcode: 31220,
    district_code: 311204,
    amphoe_code: 3112,
    province_code: 31,
  },
  {
    tambon: "ไทยเจริญ",
    amphoe: "ปะคำ",
    province: "บุรีรัมย์",
    zipcode: 31220,
    district_code: 310699,
    amphoe_code: 3112,
    province_code: 31,
  },
  {
    tambon: "จันดุม",
    amphoe: "พลับพลาชัย",
    province: "บุรีรัมย์",
    zipcode: 31250,
    district_code: 310704,
    amphoe_code: 3115,
    province_code: 31,
  },
  {
    tambon: "ป่าชัน",
    amphoe: "พลับพลาชัย",
    province: "บุรีรัมย์",
    zipcode: 31250,
    district_code: 310711,
    amphoe_code: 3115,
    province_code: 31,
  },
  {
    tambon: "สะเดา",
    amphoe: "พลับพลาชัย",
    province: "บุรีรัมย์",
    zipcode: 31250,
    district_code: 310403,
    amphoe_code: 3115,
    province_code: 31,
  },
  {
    tambon: "สำโรง",
    amphoe: "พลับพลาชัย",
    province: "บุรีรัมย์",
    zipcode: 31250,
    district_code: 310717,
    amphoe_code: 3115,
    province_code: 31,
  },
  {
    tambon: "โคกขมิ้น",
    amphoe: "พลับพลาชัย",
    province: "บุรีรัมย์",
    zipcode: 31250,
    district_code: 310709,
    amphoe_code: 3115,
    province_code: 31,
  },
  {
    tambon: "บ้านจาน",
    amphoe: "พุทไธสง",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310903,
    amphoe_code: 3109,
    province_code: 31,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "พุทไธสง",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310108,
    amphoe_code: 3109,
    province_code: 31,
  },
  {
    tambon: "บ้านเป้า",
    amphoe: "พุทไธสง",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310906,
    amphoe_code: 3109,
    province_code: 31,
  },
  {
    tambon: "บ้านแวง",
    amphoe: "พุทไธสง",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310907,
    amphoe_code: 3109,
    province_code: 31,
  },
  {
    tambon: "พุทไธสง",
    amphoe: "พุทไธสง",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310901,
    amphoe_code: 3109,
    province_code: 31,
  },
  {
    tambon: "มะเฟือง",
    amphoe: "พุทไธสง",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310902,
    amphoe_code: 3109,
    province_code: 31,
  },
  {
    tambon: "หายโศก",
    amphoe: "พุทไธสง",
    province: "บุรีรัมย์",
    zipcode: 31120,
    district_code: 310910,
    amphoe_code: 3109,
    province_code: 31,
  },
  {
    tambon: "ตาจง",
    amphoe: "ละหานทราย",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310603,
    amphoe_code: 3106,
    province_code: 31,
  },
  {
    tambon: "ละหานทราย",
    amphoe: "ละหานทราย",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310601,
    amphoe_code: 3106,
    province_code: 31,
  },
  {
    tambon: "สำโรงใหม่",
    amphoe: "ละหานทราย",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310604,
    amphoe_code: 3106,
    province_code: 31,
  },
  {
    tambon: "หนองตะครอง",
    amphoe: "ละหานทราย",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310610,
    amphoe_code: 3106,
    province_code: 31,
  },
  {
    tambon: "หนองแวง",
    amphoe: "ละหานทราย",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310607,
    amphoe_code: 3106,
    province_code: 31,
  },
  {
    tambon: "โคกว่าน",
    amphoe: "ละหานทราย",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310611,
    amphoe_code: 3106,
    province_code: 31,
  },
  {
    tambon: "ตลาดโพธิ์",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311005,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "ทะเมนชัย",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311004,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "บุโพธิ์",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311015,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 310108,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "ผไทรินทร์",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311011,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "ลำปลายมาศ",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311001,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "หนองกะทิง",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311006,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "หนองคู",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311002,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "หนองบัวโคก",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311014,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "หนองโดน",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311016,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "หินโคน",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311013,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "เมืองแฝก",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311009,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "แสลงพัน",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311003,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "โคกกลาง",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311007,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "โคกล่าม",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311012,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "ลำปลายมาศ",
    province: "บุรีรัมย์",
    zipcode: 31130,
    district_code: 311008,
    amphoe_code: 3110,
    province_code: 31,
  },
  {
    tambon: "กระสัง",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 310127,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "ชุมแสง",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 310307,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "ดอนมนต์",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311109,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "ทุ่งวัง",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311103,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "ท่าม่วง",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311111,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "นิคม",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311102,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "ร่อนทอง",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311106,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "สตึก",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311101,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "สนามชัย",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311114,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "สะแก",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311112,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "หนองใหญ่",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311105,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "เมืองแก",
    amphoe: "สตึก",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311104,
    amphoe_code: 3111,
    province_code: 31,
  },
  {
    tambon: "ดอนอะราง",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310496,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "ทุ่งกระตาดพัฒนา",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310506,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "ทุ่งกระเต็น",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310507,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "ท่าโพธิ์ชัย",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310508,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "บุกระสัง",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310510,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "หนองกี่",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310499,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "เมืองไผ่",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310306,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "เย้ยปราสาท",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310498,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "โคกสว่าง",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310505,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "โคกสูง",
    amphoe: "หนองกี่",
    province: "บุรีรัมย์",
    zipcode: 31210,
    district_code: 310509,
    amphoe_code: 3105,
    province_code: 31,
  },
  {
    tambon: "สระทอง",
    amphoe: "หนองหงส์",
    province: "บุรีรัมย์",
    zipcode: 31240,
    district_code: 311407,
    amphoe_code: 3114,
    province_code: 31,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "หนองหงส์",
    province: "บุรีรัมย์",
    zipcode: 31240,
    district_code: 311099,
    amphoe_code: 3114,
    province_code: 31,
  },
  {
    tambon: "หนองชัยศรี",
    amphoe: "หนองหงส์",
    province: "บุรีรัมย์",
    zipcode: 31240,
    district_code: 311404,
    amphoe_code: 3114,
    province_code: 31,
  },
  {
    tambon: "ห้วยหิน",
    amphoe: "หนองหงส์",
    province: "บุรีรัมย์",
    zipcode: 31240,
    district_code: 311098,
    amphoe_code: 3114,
    province_code: 31,
  },
  {
    tambon: "เมืองฝ้าย",
    amphoe: "หนองหงส์",
    province: "บุรีรัมย์",
    zipcode: 31240,
    district_code: 311406,
    amphoe_code: 3114,
    province_code: 31,
  },
  {
    tambon: "เสาเดียว",
    amphoe: "หนองหงส์",
    province: "บุรีรัมย์",
    zipcode: 31240,
    district_code: 311405,
    amphoe_code: 3114,
    province_code: 31,
  },
  {
    tambon: "ไทยสามัคคี",
    amphoe: "หนองหงส์",
    province: "บุรีรัมย์",
    zipcode: 31240,
    district_code: 311097,
    amphoe_code: 3114,
    province_code: 31,
  },
  {
    tambon: "ตาเสา",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310116,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "บ้านตะโก",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310124,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "สนวน",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310121,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "สามแวง",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310110,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "ห้วยราช",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310107,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "ห้วยราชา",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 311608,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "เมืองโพธิ์",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 311607,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "โคกเหล็ก",
    amphoe: "ห้วยราช",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 311606,
    amphoe_code: 3116,
    province_code: 31,
  },
  {
    tambon: "ตาเป๊ก",
    amphoe: "เฉลิมพระเกียรติ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310402,
    amphoe_code: 3123,
    province_code: 31,
  },
  {
    tambon: "ถาวร",
    amphoe: "เฉลิมพระเกียรติ",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310602,
    amphoe_code: 3123,
    province_code: 31,
  },
  {
    tambon: "ยายแย้มวัฒนา",
    amphoe: "เฉลิมพระเกียรติ",
    province: "บุรีรัมย์",
    zipcode: 31170,
    district_code: 310606,
    amphoe_code: 3123,
    province_code: 31,
  },
  {
    tambon: "อีสานเขต",
    amphoe: "เฉลิมพระเกียรติ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310419,
    amphoe_code: 3123,
    province_code: 31,
  },
  {
    tambon: "เจริญสุข",
    amphoe: "เฉลิมพระเกียรติ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310411,
    amphoe_code: 3123,
    province_code: 31,
  },
  {
    tambon: "กระสัง",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310127,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "กลันทา",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310126,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "ชุมเห็ด",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310120,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "ถลุงเหล็ก",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310113,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "บัวทอง",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310119,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "บ้านบัว",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310104,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310108,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "พระครู",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310112,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "ลุมปุ๊ก",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310117,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "สวายจีก",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310106,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "สองห้อง",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310118,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "สะแกซำ",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310125,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "สะแกโพรง",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310105,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "หนองตาด",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310114,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "หลักเขต",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310122,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "อิสาณ",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310102,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "เมืองฝาง",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310128,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "เสม็ด",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310103,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองบุรีรัมย์",
    province: "บุรีรัมย์",
    zipcode: 31000,
    district_code: 310101,
    amphoe_code: 3101,
    province_code: 31,
  },
  {
    tambon: "ดงพลอง",
    amphoe: "แคนดง",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311108,
    amphoe_code: 3122,
    province_code: 31,
  },
  {
    tambon: "สระบัว",
    amphoe: "แคนดง",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311113,
    amphoe_code: 3122,
    province_code: 31,
  },
  {
    tambon: "หัวฝาย",
    amphoe: "แคนดง",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311116,
    amphoe_code: 3122,
    province_code: 31,
  },
  {
    tambon: "แคนดง",
    amphoe: "แคนดง",
    province: "บุรีรัมย์",
    zipcode: 31150,
    district_code: 311107,
    amphoe_code: 3122,
    province_code: 31,
  },
  {
    tambon: "ลำนางรอง",
    amphoe: "โนนดินแดง",
    province: "บุรีรัมย์",
    zipcode: 31260,
    district_code: 310608,
    amphoe_code: 3120,
    province_code: 31,
  },
  {
    tambon: "ส้มป่อย",
    amphoe: "โนนดินแดง",
    province: "บุรีรัมย์",
    zipcode: 31260,
    district_code: 310609,
    amphoe_code: 3120,
    province_code: 31,
  },
  {
    tambon: "โนนดินแดง",
    amphoe: "โนนดินแดง",
    province: "บุรีรัมย์",
    zipcode: 31260,
    district_code: 310605,
    amphoe_code: 3120,
    province_code: 31,
  },
  {
    tambon: "ดงอีจาน",
    amphoe: "โนนสุวรรณ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310420,
    amphoe_code: 3117,
    province_code: 31,
  },
  {
    tambon: "ทุ่งจังหัน",
    amphoe: "โนนสุวรรณ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310409,
    amphoe_code: 3117,
    province_code: 31,
  },
  {
    tambon: "โกรกแก้ว",
    amphoe: "โนนสุวรรณ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310421,
    amphoe_code: 3117,
    province_code: 31,
  },
  {
    tambon: "โนนสุวรรณ",
    amphoe: "โนนสุวรรณ",
    province: "บุรีรัมย์",
    zipcode: 31110,
    district_code: 310412,
    amphoe_code: 3117,
    province_code: 31,
  },
  {
    tambon: "คลองสอง",
    amphoe: "คลองหลวง",
    province: "ปทุมธานี",
    zipcode: 12120,
    district_code: 130202,
    amphoe_code: 1302,
    province_code: 13,
  },
  {
    tambon: "คลองสาม",
    amphoe: "คลองหลวง",
    province: "ปทุมธานี",
    zipcode: 12120,
    district_code: 130203,
    amphoe_code: 1302,
    province_code: 13,
  },
  {
    tambon: "คลองสี่",
    amphoe: "คลองหลวง",
    province: "ปทุมธานี",
    zipcode: 12120,
    district_code: 130204,
    amphoe_code: 1302,
    province_code: 13,
  },
  {
    tambon: "คลองหก",
    amphoe: "คลองหลวง",
    province: "ปทุมธานี",
    zipcode: 12120,
    district_code: 130206,
    amphoe_code: 1302,
    province_code: 13,
  },
  {
    tambon: "คลองหนึ่ง",
    amphoe: "คลองหลวง",
    province: "ปทุมธานี",
    zipcode: 12120,
    district_code: 130201,
    amphoe_code: 1302,
    province_code: 13,
  },
  {
    tambon: "คลองห้า",
    amphoe: "คลองหลวง",
    province: "ปทุมธานี",
    zipcode: 12120,
    district_code: 130205,
    amphoe_code: 1302,
    province_code: 13,
  },
  {
    tambon: "คลองเจ็ด",
    amphoe: "คลองหลวง",
    province: "ปทุมธานี",
    zipcode: 12120,
    district_code: 130207,
    amphoe_code: 1302,
    province_code: 13,
  },
  {
    tambon: "บึงน้ำรักษ์",
    amphoe: "ธัญบุรี",
    province: "ปทุมธานี",
    zipcode: 12110,
    district_code: 130306,
    amphoe_code: 1303,
    province_code: 13,
  },
  {
    tambon: "บึงยี่โถ",
    amphoe: "ธัญบุรี",
    province: "ปทุมธานี",
    zipcode: 12130,
    district_code: 130302,
    amphoe_code: 1303,
    province_code: 13,
  },
  {
    tambon: "บึงสนั่น",
    amphoe: "ธัญบุรี",
    province: "ปทุมธานี",
    zipcode: 12110,
    district_code: 130305,
    amphoe_code: 1303,
    province_code: 13,
  },
  {
    tambon: "ประชาธิปัตย์",
    amphoe: "ธัญบุรี",
    province: "ปทุมธานี",
    zipcode: 12130,
    district_code: 130301,
    amphoe_code: 1303,
    province_code: 13,
  },
  {
    tambon: "รังสิต",
    amphoe: "ธัญบุรี",
    province: "ปทุมธานี",
    zipcode: 12110,
    district_code: 130303,
    amphoe_code: 1303,
    province_code: 13,
  },
  {
    tambon: "ลำผักกูด",
    amphoe: "ธัญบุรี",
    province: "ปทุมธานี",
    zipcode: 12110,
    district_code: 130304,
    amphoe_code: 1303,
    province_code: 13,
  },
  {
    tambon: "คลองพระอุดม",
    amphoe: "ลาดหลุมแก้ว",
    province: "ปทุมธานี",
    zipcode: 12140,
    district_code: 130505,
    amphoe_code: 1305,
    province_code: 13,
  },
  {
    tambon: "คูขวาง",
    amphoe: "ลาดหลุมแก้ว",
    province: "ปทุมธานี",
    zipcode: 12140,
    district_code: 130504,
    amphoe_code: 1305,
    province_code: 13,
  },
  {
    tambon: "คูบางหลวง",
    amphoe: "ลาดหลุมแก้ว",
    province: "ปทุมธานี",
    zipcode: 12140,
    district_code: 130503,
    amphoe_code: 1305,
    province_code: 13,
  },
  {
    tambon: "บ่อเงิน",
    amphoe: "ลาดหลุมแก้ว",
    province: "ปทุมธานี",
    zipcode: 12140,
    district_code: 130506,
    amphoe_code: 1305,
    province_code: 13,
  },
  {
    tambon: "ระแหง",
    amphoe: "ลาดหลุมแก้ว",
    province: "ปทุมธานี",
    zipcode: 12140,
    district_code: 130501,
    amphoe_code: 1305,
    province_code: 13,
  },
  {
    tambon: "ลาดหลุมแก้ว",
    amphoe: "ลาดหลุมแก้ว",
    province: "ปทุมธานี",
    zipcode: 12140,
    district_code: 130502,
    amphoe_code: 1305,
    province_code: 13,
  },
  {
    tambon: "หน้าไม้",
    amphoe: "ลาดหลุมแก้ว",
    province: "ปทุมธานี",
    zipcode: 12140,
    district_code: 130507,
    amphoe_code: 1305,
    province_code: 13,
  },
  {
    tambon: "คูคต",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12130,
    district_code: 130601,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "บึงคอไห",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12150,
    district_code: 130607,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "บึงคำพร้อย",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12150,
    district_code: 130603,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "บึงทองหลาง",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12150,
    district_code: 130605,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "พืชอุดม",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12150,
    district_code: 130608,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "ลาดสวาย",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12150,
    district_code: 130602,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "ลำลูกกา",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12150,
    district_code: 130604,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "ลำไทร",
    amphoe: "ลำลูกกา",
    province: "ปทุมธานี",
    zipcode: 12150,
    district_code: 130606,
    amphoe_code: 1306,
    province_code: 13,
  },
  {
    tambon: "กระแชง",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130704,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "คลองควาย",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130702,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "ท้ายเกาะ",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130711,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "บางกระบือ",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130710,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "บางเตย",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130701,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "บางโพธิ์เหนือ",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130705,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "บ้านงิ้ว",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130708,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "บ้านปทุม",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130707,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "สามโคก",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130703,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "เชียงรากน้อย",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130709,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "เชียงรากใหญ่",
    amphoe: "สามโคก",
    province: "ปทุมธานี",
    zipcode: 12160,
    district_code: 130706,
    amphoe_code: 1307,
    province_code: 13,
  },
  {
    tambon: "นพรัตน์",
    amphoe: "หนองเสือ",
    province: "ปทุมธานี",
    zipcode: 12170,
    district_code: 130407,
    amphoe_code: 1304,
    province_code: 13,
  },
  {
    tambon: "บึงกาสาม",
    amphoe: "หนองเสือ",
    province: "ปทุมธานี",
    zipcode: 12170,
    district_code: 130403,
    amphoe_code: 1304,
    province_code: 13,
  },
  {
    tambon: "บึงชำอ้อ",
    amphoe: "หนองเสือ",
    province: "ปทุมธานี",
    zipcode: 12170,
    district_code: 130404,
    amphoe_code: 1304,
    province_code: 13,
  },
  {
    tambon: "บึงบอน",
    amphoe: "หนองเสือ",
    province: "ปทุมธานี",
    zipcode: 12170,
    district_code: 130402,
    amphoe_code: 1304,
    province_code: 13,
  },
  {
    tambon: "บึงบา",
    amphoe: "หนองเสือ",
    province: "ปทุมธานี",
    zipcode: 12170,
    district_code: 130401,
    amphoe_code: 1304,
    province_code: 13,
  },
  {
    tambon: "ศาลาครุ",
    amphoe: "หนองเสือ",
    province: "ปทุมธานี",
    zipcode: 12170,
    district_code: 130406,
    amphoe_code: 1304,
    province_code: 13,
  },
  {
    tambon: "หนองสามวัง",
    amphoe: "หนองเสือ",
    province: "ปทุมธานี",
    zipcode: 12170,
    district_code: 130405,
    amphoe_code: 1304,
    province_code: 13,
  },
  {
    tambon: "บางกะดี",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130112,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บางขะแยง",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130106,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บางคูวัด",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130107,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บางปรอก",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130101,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บางพูด",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130110,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บางพูน",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130111,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บางหลวง",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130108,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บางเดื่อ",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130109,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บ้านกระแชง",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130105,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130103,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บ้านฉาง",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130104,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130102,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "สวนพริกไทย",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130113,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "หลักหก",
    amphoe: "เมืองปทุมธานี",
    province: "ปทุมธานี",
    zipcode: 12000,
    district_code: 130114,
    amphoe_code: 1301,
    province_code: 13,
  },
  {
    tambon: "กุยบุรี",
    amphoe: "กุยบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77150,
    district_code: 770201,
    amphoe_code: 7702,
    province_code: 77,
  },
  {
    tambon: "กุยเหนือ",
    amphoe: "กุยบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77150,
    district_code: 770202,
    amphoe_code: 7702,
    province_code: 77,
  },
  {
    tambon: "ดอนยายหนู",
    amphoe: "กุยบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77150,
    district_code: 770204,
    amphoe_code: 7702,
    province_code: 77,
  },
  {
    tambon: "สามกระทาย",
    amphoe: "กุยบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77150,
    district_code: 770206,
    amphoe_code: 7702,
    province_code: 77,
  },
  {
    tambon: "หาดขาม",
    amphoe: "กุยบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77150,
    district_code: 770207,
    amphoe_code: 7702,
    province_code: 77,
  },
  {
    tambon: "เขาแดง",
    amphoe: "กุยบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77150,
    district_code: 770203,
    amphoe_code: 7702,
    province_code: 77,
  },
  {
    tambon: "ทับสะแก",
    amphoe: "ทับสะแก",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77130,
    district_code: 770301,
    amphoe_code: 7703,
    province_code: 77,
  },
  {
    tambon: "นาหูกวาง",
    amphoe: "ทับสะแก",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77130,
    district_code: 770303,
    amphoe_code: 7703,
    province_code: 77,
  },
  {
    tambon: "ห้วยยาง",
    amphoe: "ทับสะแก",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77130,
    district_code: 770305,
    amphoe_code: 7703,
    province_code: 77,
  },
  {
    tambon: "อ่างทอง",
    amphoe: "ทับสะแก",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77130,
    district_code: 770302,
    amphoe_code: 7703,
    province_code: 77,
  },
  {
    tambon: "เขาล้าน",
    amphoe: "ทับสะแก",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77130,
    district_code: 770304,
    amphoe_code: 7703,
    province_code: 77,
  },
  {
    tambon: "แสงอรุณ",
    amphoe: "ทับสะแก",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77130,
    district_code: 770306,
    amphoe_code: 7703,
    province_code: 77,
  },
  {
    tambon: "กำเนิดนพคุณ",
    amphoe: "บางสะพาน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77140,
    district_code: 770401,
    amphoe_code: 7704,
    province_code: 77,
  },
  {
    tambon: "ชัยเกษม",
    amphoe: "บางสะพาน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77190,
    district_code: 770405,
    amphoe_code: 7704,
    province_code: 77,
  },
  {
    tambon: "ทองมงคล",
    amphoe: "บางสะพาน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77230,
    district_code: 770406,
    amphoe_code: 7704,
    province_code: 77,
  },
  {
    tambon: "ธงชัย",
    amphoe: "บางสะพาน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77190,
    district_code: 770404,
    amphoe_code: 7704,
    province_code: 77,
  },
  {
    tambon: "พงศ์ประศาสน์",
    amphoe: "บางสะพาน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77140,
    district_code: 770402,
    amphoe_code: 7704,
    province_code: 77,
  },
  {
    tambon: "ร่อนทอง",
    amphoe: "บางสะพาน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77230,
    district_code: 770403,
    amphoe_code: 7704,
    province_code: 77,
  },
  {
    tambon: "แม่รำพึง",
    amphoe: "บางสะพาน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77140,
    district_code: 770407,
    amphoe_code: 7704,
    province_code: 77,
  },
  {
    tambon: "ช้างแรก",
    amphoe: "บางสะพานน้อย",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77170,
    district_code: 770504,
    amphoe_code: 7705,
    province_code: 77,
  },
  {
    tambon: "ทรายทอง",
    amphoe: "บางสะพานน้อย",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77170,
    district_code: 770503,
    amphoe_code: 7705,
    province_code: 77,
  },
  {
    tambon: "บางสะพาน",
    amphoe: "บางสะพานน้อย",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77170,
    district_code: 770502,
    amphoe_code: 7705,
    province_code: 77,
  },
  {
    tambon: "ปากแพรก",
    amphoe: "บางสะพานน้อย",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77170,
    district_code: 770501,
    amphoe_code: 7705,
    province_code: 77,
  },
  {
    tambon: "ไชยราช",
    amphoe: "บางสะพานน้อย",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77170,
    district_code: 770505,
    amphoe_code: 7705,
    province_code: 77,
  },
  {
    tambon: "ปราณบุรี",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77120,
    district_code: 770601,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "ปราณบุรี",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77160,
    district_code: 770601,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "ปากน้ำปราณ",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77220,
    district_code: 770604,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "วังก์พง",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77120,
    district_code: 770608,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "วังก์พง",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77160,
    district_code: 770608,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "หนองตาแต้ม",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77120,
    district_code: 770607,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "หนองตาแต้ม",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77160,
    district_code: 770607,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "เขาจ้าว",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77120,
    district_code: 770609,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "เขาจ้าว",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77160,
    district_code: 770609,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "เขาน้อย",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77120,
    district_code: 770602,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "เขาน้อย",
    amphoe: "ปราณบุรี",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77160,
    district_code: 770602,
    amphoe_code: 7706,
    province_code: 77,
  },
  {
    tambon: "ศาลาลัย",
    amphoe: "สามร้อยยอด",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77180,
    district_code: 770804,
    amphoe_code: 7708,
    province_code: 77,
  },
  {
    tambon: "ศิลาลอย",
    amphoe: "สามร้อยยอด",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77180,
    district_code: 770603,
    amphoe_code: 7708,
    province_code: 77,
  },
  {
    tambon: "สามร้อยยอด",
    amphoe: "สามร้อยยอด",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77120,
    district_code: 770605,
    amphoe_code: 7708,
    province_code: 77,
  },
  {
    tambon: "สามร้อยยอด",
    amphoe: "สามร้อยยอด",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77160,
    district_code: 770605,
    amphoe_code: 7708,
    province_code: 77,
  },
  {
    tambon: "ไร่เก่า",
    amphoe: "สามร้อยยอด",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77180,
    district_code: 770606,
    amphoe_code: 7708,
    province_code: 77,
  },
  {
    tambon: "ไร่ใหม่",
    amphoe: "สามร้อยยอด",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77180,
    district_code: 770205,
    amphoe_code: 7708,
    province_code: 77,
  },
  {
    tambon: "ทับใต้",
    amphoe: "หัวหิน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77110,
    district_code: 770705,
    amphoe_code: 7707,
    province_code: 77,
  },
  {
    tambon: "บึงนคร",
    amphoe: "หัวหิน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77110,
    district_code: 770707,
    amphoe_code: 7707,
    province_code: 77,
  },
  {
    tambon: "หนองพลับ",
    amphoe: "หัวหิน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77110,
    district_code: 770704,
    amphoe_code: 7707,
    province_code: 77,
  },
  {
    tambon: "หนองแก",
    amphoe: "หัวหิน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77110,
    district_code: 770702,
    amphoe_code: 7707,
    province_code: 77,
  },
  {
    tambon: "หัวหิน",
    amphoe: "หัวหิน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77110,
    district_code: 770701,
    amphoe_code: 7707,
    province_code: 77,
  },
  {
    tambon: "หินเหล็กไฟ",
    amphoe: "หัวหิน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77110,
    district_code: 770703,
    amphoe_code: 7707,
    province_code: 77,
  },
  {
    tambon: "ห้วยสัตว์ใหญ่",
    amphoe: "หัวหิน",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77110,
    district_code: 770706,
    amphoe_code: 7707,
    province_code: 77,
  },
  {
    tambon: "คลองวาฬ",
    amphoe: "เมืองประจวบคีรีขันธ์",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77000,
    district_code: 770103,
    amphoe_code: 7701,
    province_code: 77,
  },
  {
    tambon: "บ่อนอก",
    amphoe: "เมืองประจวบคีรีขันธ์",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77210,
    district_code: 770106,
    amphoe_code: 7701,
    province_code: 77,
  },
  {
    tambon: "ประจวบคีรีขันธ์",
    amphoe: "เมืองประจวบคีรีขันธ์",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77000,
    district_code: 770101,
    amphoe_code: 7701,
    province_code: 77,
  },
  {
    tambon: "ห้วยทราย",
    amphoe: "เมืองประจวบคีรีขันธ์",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77000,
    district_code: 770104,
    amphoe_code: 7701,
    province_code: 77,
  },
  {
    tambon: "อ่าวน้อย",
    amphoe: "เมืองประจวบคีรีขันธ์",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77210,
    district_code: 770105,
    amphoe_code: 7701,
    province_code: 77,
  },
  {
    tambon: "เกาะหลัก",
    amphoe: "เมืองประจวบคีรีขันธ์",
    province: "ประจวบคีรีขันธ์",
    zipcode: 77000,
    district_code: 770102,
    amphoe_code: 7701,
    province_code: 77,
  },
  {
    tambon: "กบินทร์",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250201,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "นนทรี",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250204,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "นาแขม",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250212,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "บ่อทอง",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250210,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "บ้านนา",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250209,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "ย่านรี",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250205,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "ลาดตะเคียน",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250208,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "วังดาล",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250203,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "วังตะเคียน",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250206,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "วังท่าช้าง",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250214,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "หนองกี่",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250211,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "หาดนางแก้ว",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250207,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "เขาไม้แก้ว",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25110,
    district_code: 250213,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "เมืองเก่า",
    amphoe: "กบินทร์บุรี",
    province: "ปราจีนบุรี",
    zipcode: 25240,
    district_code: 250202,
    amphoe_code: 2502,
    province_code: 25,
  },
  {
    tambon: "ทุ่งโพธิ์",
    amphoe: "นาดี",
    province: "ปราจีนบุรี",
    zipcode: 25220,
    district_code: 250299,
    amphoe_code: 2503,
    province_code: 25,
  },
  {
    tambon: "นาดี",
    amphoe: "นาดี",
    province: "ปราจีนบุรี",
    zipcode: 25220,
    district_code: 250297,
    amphoe_code: 2503,
    province_code: 25,
  },
  {
    tambon: "บุพราหมณ์",
    amphoe: "นาดี",
    province: "ปราจีนบุรี",
    zipcode: 25220,
    district_code: 250306,
    amphoe_code: 2503,
    province_code: 25,
  },
  {
    tambon: "สะพานหิน",
    amphoe: "นาดี",
    province: "ปราจีนบุรี",
    zipcode: 25220,
    district_code: 250296,
    amphoe_code: 2503,
    province_code: 25,
  },
  {
    tambon: "สำพันตา",
    amphoe: "นาดี",
    province: "ปราจีนบุรี",
    zipcode: 25220,
    district_code: 250302,
    amphoe_code: 2503,
    province_code: 25,
  },
  {
    tambon: "แก่งดินสอ",
    amphoe: "นาดี",
    province: "ปราจีนบุรี",
    zipcode: 25220,
    district_code: 250305,
    amphoe_code: 2503,
    province_code: 25,
  },
  {
    tambon: "กระทุ่มแพ้ว",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250609,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บางกระเบา",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250602,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บางขาม",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250608,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บางปลาร้า",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250607,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บางพลวง",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250606,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บางยาง",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250604,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บางเตย",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250603,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บางแตน",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250605,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "บ้านสร้าง",
    amphoe: "บ้านสร้าง",
    province: "ปราจีนบุรี",
    zipcode: 25150,
    district_code: 250601,
    amphoe_code: 2506,
    province_code: 25,
  },
  {
    tambon: "คำโตนด",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250706,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "ดงบัง",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250705,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "บุฝ้าย",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250707,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "บ้านหอย",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250703,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "ประจันตคาม",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250701,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "หนองแก้ว",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250708,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "หนองแสง",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250704,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "เกาะลอย",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250702,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "โพธิ์งาม",
    amphoe: "ประจันตคาม",
    province: "ปราจีนบุรี",
    zipcode: 25130,
    district_code: 250709,
    amphoe_code: 2507,
    province_code: 25,
  },
  {
    tambon: "กรอกสมบูรณ์",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250810,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "ดงกระทงยาม",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250806,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "ท่าตูม",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250804,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "บางกุ้ง",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250805,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "บ้านทาม",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250803,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "ศรีมหาโพธิ",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250801,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "สัมพันธ์",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250802,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "หนองโพรง",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250807,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "หัวหว้า",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250808,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "หาดยาง",
    amphoe: "ศรีมหาโพธิ",
    province: "ปราจีนบุรี",
    zipcode: 25140,
    district_code: 250809,
    amphoe_code: 2508,
    province_code: 25,
  },
  {
    tambon: "คู้ลำพัน",
    amphoe: "ศรีมโหสถ",
    province: "ปราจีนบุรี",
    zipcode: 25190,
    district_code: 250896,
    amphoe_code: 2509,
    province_code: 25,
  },
  {
    tambon: "โคกปีบ",
    amphoe: "ศรีมโหสถ",
    province: "ปราจีนบุรี",
    zipcode: 25190,
    district_code: 250897,
    amphoe_code: 2509,
    province_code: 25,
  },
  {
    tambon: "โคกไทย",
    amphoe: "ศรีมโหสถ",
    province: "ปราจีนบุรี",
    zipcode: 25190,
    district_code: 250898,
    amphoe_code: 2509,
    province_code: 25,
  },
  {
    tambon: "ไผ่ชะเลือด",
    amphoe: "ศรีมโหสถ",
    province: "ปราจีนบุรี",
    zipcode: 25190,
    district_code: 250899,
    amphoe_code: 2509,
    province_code: 25,
  },
  {
    tambon: "ดงขี้เหล็ก",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250111,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "ดงพระราม",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250107,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "ท่างาม",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250105,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "บางบริบูรณ์",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250106,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "บางเดชะ",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250104,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "บ้านพระ",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25230,
    district_code: 250108,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "รอบเมือง",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250102,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "วัดโบสถ์",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250103,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "หน้าเมือง",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250101,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "เนินหอม",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25230,
    district_code: 250112,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "โคกไม้ลาย",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25230,
    district_code: 250109,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "โนนห้อม",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25000,
    district_code: 250113,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "ไม้เค็ด",
    amphoe: "เมืองปราจีนบุรี",
    province: "ปราจีนบุรี",
    zipcode: 25230,
    district_code: 250110,
    amphoe_code: 2501,
    province_code: 25,
  },
  {
    tambon: "กะรุบี",
    amphoe: "กะพ้อ",
    province: "ปัตตานี",
    zipcode: 94230,
    district_code: 941101,
    amphoe_code: 9411,
    province_code: 94,
  },
  {
    tambon: "ตะโละดือรามัน",
    amphoe: "กะพ้อ",
    province: "ปัตตานี",
    zipcode: 94230,
    district_code: 941102,
    amphoe_code: 9411,
    province_code: 94,
  },
  {
    tambon: "ปล่องหอย",
    amphoe: "กะพ้อ",
    province: "ปัตตานี",
    zipcode: 94230,
    district_code: 941103,
    amphoe_code: 9411,
    province_code: 94,
  },
  {
    tambon: "ตะโละแมะนา",
    amphoe: "ทุ่งยางแดง",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940601,
    amphoe_code: 9406,
    province_code: 94,
  },
  {
    tambon: "น้ำดำ",
    amphoe: "ทุ่งยางแดง",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940603,
    amphoe_code: 9406,
    province_code: 94,
  },
  {
    tambon: "ปากู",
    amphoe: "ทุ่งยางแดง",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940604,
    amphoe_code: 9406,
    province_code: 94,
  },
  {
    tambon: "พิเทน",
    amphoe: "ทุ่งยางแดง",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940602,
    amphoe_code: 9406,
    province_code: 94,
  },
  {
    tambon: "ควน",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94190,
    district_code: 940405,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "คอกกระบือ",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940407,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "ดอน",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94190,
    district_code: 940404,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940402,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "ท่าน้ำ",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940406,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940409,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "บ้านนอก",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940403,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "บ้านน้ำบ่อ",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940410,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "ปะนาเระ",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940401,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "พ่อมิ่ง",
    amphoe: "ปะนาเระ",
    province: "ปัตตานี",
    zipcode: 94130,
    district_code: 940408,
    amphoe_code: 9404,
    province_code: 94,
  },
  {
    tambon: "กระหวะ",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94190,
    district_code: 940504,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "กระเสาะ",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940507,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "ตรัง",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940503,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "ถนน",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940502,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "ปะโด",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940509,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "ปานัน",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940513,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "มายอ",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940501,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "ลางา",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94190,
    district_code: 940506,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "ลุโบะยิไร",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940505,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "สะกำ",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940512,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "สาคอบน",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940510,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "สาคอใต้",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940511,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "เกาะจัน",
    amphoe: "มายอ",
    province: "ปัตตานี",
    zipcode: 94140,
    district_code: 940508,
    amphoe_code: 9405,
    province_code: 94,
  },
  {
    tambon: "กระโด",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941008,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "กอลำ",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941011,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "คลองใหม่",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941009,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "ประจัน",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941003,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "ปิตูมุดี",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941006,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "ยะรัง",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941001,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "ระแว้ง",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941005,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "วัด",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941007,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "สะดาวา",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941002,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "สะนอ",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941004,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "เขาตูม",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941012,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "เมาะมาวี",
    amphoe: "ยะรัง",
    province: "ปัตตานี",
    zipcode: 94160,
    district_code: 941010,
    amphoe_code: 9410,
    province_code: 94,
  },
  {
    tambon: "จะรัง",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940917,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ตอหลัง",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940905,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ตะโละ",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940901,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ตะโละกาโปร์",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940902,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ตันหยงจึงงา",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94190,
    district_code: 940904,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ตันหยงดาลอ",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940903,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ตาลีอายร์",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940907,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ตาแกะ",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940906,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "บางปู",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940909,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "บาโลย",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94190,
    district_code: 940913,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ปิยามุมัง",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940911,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ปุลากง",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940912,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "มะนังยง",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940915,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ยามู",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940908,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "ราตาปันยัง",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940916,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "สาบัน",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940914,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "หนองแรต",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940910,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "แหลมโพธิ์",
    amphoe: "ยะหริ่ง",
    province: "ปัตตานี",
    zipcode: 94150,
    district_code: 940918,
    amphoe_code: 9409,
    province_code: 94,
  },
  {
    tambon: "กะดุนง",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940707,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "ตะบิ้ง",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940702,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "ตะลุบัน",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940701,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "ทุ่งคล้า",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94190,
    district_code: 940711,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "บางเก่า",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940704,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "บือเระ",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940705,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "ปะเสยะวอ",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940703,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "มะนังดาลำ",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940709,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "ละหาร",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940708,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "เตราะบอน",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940706,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "แป้น",
    amphoe: "สายบุรี",
    province: "ปัตตานี",
    zipcode: 94110,
    district_code: 940710,
    amphoe_code: 9407,
    province_code: 94,
  },
  {
    tambon: "คอลอตันหยง",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940302,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "ดอนรัก",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940303,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "ดาโต๊ะ",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940304,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "ตุยง",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940305,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "ท่ากำชำ",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940306,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "บางตาวา",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940309,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "บางเขา",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940308,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "บ่อทอง",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940307,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "ปุโละปุโย",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940310,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "ยาบี",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940311,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "ลิปะสะโง",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940312,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "เกาะเปาะ",
    amphoe: "หนองจิก",
    province: "ปัตตานี",
    zipcode: 94170,
    district_code: 940301,
    amphoe_code: 9403,
    province_code: 94,
  },
  {
    tambon: "กะมิยอ",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940107,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "คลองมานิง",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940106,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "จะบังติกอ",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940103,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "ตะลุโบะ",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940111,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "ตันหยงลุโละ",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940105,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "บานา",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940104,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "บาราเฮาะ",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940112,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "บาราโหม",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940108,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "ปะกาฮะรัง",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940109,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "ปุยุด",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940113,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "รูสะมิแล",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940110,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "สะบารัง",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940101,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "อาเนาะรู",
    amphoe: "เมืองปัตตานี",
    province: "ปัตตานี",
    zipcode: 94000,
    district_code: 940102,
    amphoe_code: 9401,
    province_code: 94,
  },
  {
    tambon: "ป่าไร่",
    amphoe: "แม่ลาน",
    province: "ปัตตานี",
    zipcode: 94180,
    district_code: 940210,
    amphoe_code: 9412,
    province_code: 94,
  },
  {
    tambon: "ม่วงเตี้ย",
    amphoe: "แม่ลาน",
    province: "ปัตตานี",
    zipcode: 94180,
    district_code: 940212,
    amphoe_code: 9412,
    province_code: 94,
  },
  {
    tambon: "แม่ลาน",
    amphoe: "แม่ลาน",
    province: "ปัตตานี",
    zipcode: 94180,
    district_code: 940209,
    amphoe_code: 9412,
    province_code: 94,
  },
  {
    tambon: "ควนโนรี",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94180,
    district_code: 940214,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "ช้างให้ตก",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940215,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "ทรายขาว",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940205,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "ทุ่งพลา",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94180,
    district_code: 940208,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940211,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "นาประดู่",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94180,
    district_code: 940206,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "นาเกตุ",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940213,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "บางโกระ",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940203,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "ปากล่อ",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94180,
    district_code: 940207,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "ป่าบอน",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940204,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "มะกรูด",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940202,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "โคกโพธิ์",
    amphoe: "โคกโพธิ์",
    province: "ปัตตานี",
    zipcode: 94120,
    district_code: 940201,
    amphoe_code: 9402,
    province_code: 94,
  },
  {
    tambon: "ดอนทราย",
    amphoe: "ไม้แก่น",
    province: "ปัตตานี",
    zipcode: 94220,
    district_code: 940804,
    amphoe_code: 9408,
    province_code: 94,
  },
  {
    tambon: "ตะโละไกรทอง",
    amphoe: "ไม้แก่น",
    province: "ปัตตานี",
    zipcode: 94220,
    district_code: 940803,
    amphoe_code: 9408,
    province_code: 94,
  },
  {
    tambon: "ไทรทอง",
    amphoe: "ไม้แก่น",
    province: "ปัตตานี",
    zipcode: 94220,
    district_code: 940801,
    amphoe_code: 9408,
    province_code: 94,
  },
  {
    tambon: "ไม้แก่น",
    amphoe: "ไม้แก่น",
    province: "ปัตตานี",
    zipcode: 94220,
    district_code: 940802,
    amphoe_code: 9408,
    province_code: 94,
  },
  {
    tambon: "จำปา",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140199,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "ท่าหลวง",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 18270,
    district_code: 140203,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "ท่าเจ้าสนุก",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140210,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140201,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "บ้านร่อม",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140204,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "ปากท่า",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140208,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "วังแดง",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140206,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "ศาลาลอย",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140205,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "หนองขนาก",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140209,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "โพธิ์เอน",
    amphoe: "ท่าเรือ",
    province: "พระนครศรีอยุธยา",
    zipcode: 13130,
    district_code: 140207,
    amphoe_code: 1402,
    province_code: 14,
  },
  {
    tambon: "คลองสะแก",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140310,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140302,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "นครหลวง",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140301,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "บางพระครู",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140307,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "บางระกำ",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140306,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "บ่อโพง",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140303,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "บ้านชุ้ง",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140304,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "ปากจั่น",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140305,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "พระนอน",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140312,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "สามไถ",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140311,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "หนองปลิง",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140309,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "แม่ลา",
    amphoe: "นครหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13260,
    district_code: 140308,
    amphoe_code: 1403,
    province_code: 14,
  },
  {
    tambon: "บางซ้าย",
    amphoe: "บางซ้าย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13270,
    district_code: 141301,
    amphoe_code: 1413,
    province_code: 14,
  },
  {
    tambon: "ปลายกลัด",
    amphoe: "บางซ้าย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13270,
    district_code: 141304,
    amphoe_code: 1413,
    province_code: 14,
  },
  {
    tambon: "วังพัฒนา",
    amphoe: "บางซ้าย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13270,
    district_code: 141306,
    amphoe_code: 1413,
    province_code: 14,
  },
  {
    tambon: "เต่าเล่า",
    amphoe: "บางซ้าย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13270,
    district_code: 141303,
    amphoe_code: 1413,
    province_code: 14,
  },
  {
    tambon: "เทพมงคล",
    amphoe: "บางซ้าย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13270,
    district_code: 141305,
    amphoe_code: 1413,
    province_code: 14,
  },
  {
    tambon: "แก้วฟ้า",
    amphoe: "บางซ้าย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13270,
    district_code: 141302,
    amphoe_code: 1413,
    province_code: 14,
  },
  {
    tambon: "กบเจา",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140506,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "ทางช้าง",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140510,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "น้ำเต้า",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140509,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "บางชะนี",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140515,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "บางบาล",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140501,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "บางหลวง",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140512,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "บางหลวงโดด",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140513,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "บางหัก",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140514,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "บ้านกุ่ม",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140516,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "บ้านคลัง",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140507,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "พระขาว",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140508,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "มหาพราหมณ์",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140505,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "วัดตะกู",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140511,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "วัดยม",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140502,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "สะพานไทย",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140504,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "ไทรน้อย",
    amphoe: "บางบาล",
    province: "พระนครศรีอยุธยา",
    zipcode: 13250,
    district_code: 140503,
    amphoe_code: 1405,
    province_code: 14,
  },
  {
    tambon: "ขยาย",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140702,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "ขวัญเมือง",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140712,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "ตานิม",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140709,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "ตาลเอน",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140716,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "ทับน้ำ",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140710,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "ทางกลาง",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140705,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "บางนางร้า",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140708,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "บางปะหัน",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140701,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "บางเดื่อ",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140703,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "บางเพลิง",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140706,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "บ้านขล้อ",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140717,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "บ้านม้า",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140417,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "บ้านลี่",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140713,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "พุทเลา",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140715,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "หันสัง",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140707,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "เสาธง",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140704,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "โพธิ์สามต้น",
    amphoe: "บางปะหัน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13220,
    district_code: 140714,
    amphoe_code: 1407,
    province_code: 14,
  },
  {
    tambon: "ขนอนหลวง",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140618,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "คลองจิก",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140606,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "คุ้งลาน",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140614,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "ตลาดเกรียบ",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140617,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140615,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บางกระสั้น",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140605,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บางประแดง",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140609,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บ้านกรด",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140604,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บ้านพลับ",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140612,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บ้านสร้าง",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 140616,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บ้านหว้า",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140607,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บ้านเลน",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140601,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บ้านแป้ง",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140404,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "บ้านโพ",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140603,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "วัดยม",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140502,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "สามเรือน",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140610,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "เกาะเกิด",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13160,
    district_code: 140611,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "เชียงรากน้อย",
    amphoe: "บางปะอิน",
    province: "พระนครศรีอยุธยา",
    zipcode: 13180,
    district_code: 140422,
    amphoe_code: 1406,
    province_code: 14,
  },
  {
    tambon: "กกแก้วบูรพา",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140415,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "กระแชง",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140410,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "ช่างเหล็ก",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140409,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "ช้างน้อย",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140412,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "ช้างใหญ่",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13290,
    district_code: 140420,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "บางพลี",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140402,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "บางยี่โท",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140406,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "บางไทร",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140401,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "บ้านกลึง",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140411,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "บ้านม้า",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140417,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "บ้านเกาะ",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140116,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "บ้านแป้ง",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140404,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "ราชคราม",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13290,
    district_code: 140419,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "สนามชัย",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140403,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "หน้าไม้",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140405,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "ห่อหมก",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140413,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "เชียงรากน้อย",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13290,
    district_code: 140422,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "แคตก",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140408,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "แคออก",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140407,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "โคกช้าง",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140423,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "โพแตง",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13290,
    district_code: 140421,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "ไผ่พระ",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140414,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "ไม้ตรา",
    amphoe: "บางไทร",
    province: "พระนครศรีอยุธยา",
    zipcode: 13190,
    district_code: 140416,
    amphoe_code: 1404,
    province_code: 14,
  },
  {
    tambon: "คลองน้อย",
    amphoe: "บ้านแพรก",
    province: "พระนครศรีอยุธยา",
    zipcode: 13240,
    district_code: 141604,
    amphoe_code: 1416,
    province_code: 14,
  },
  {
    tambon: "บ้านแพรก",
    amphoe: "บ้านแพรก",
    province: "พระนครศรีอยุธยา",
    zipcode: 13240,
    district_code: 141601,
    amphoe_code: 1416,
    province_code: 14,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "บ้านแพรก",
    province: "พระนครศรีอยุธยา",
    zipcode: 13240,
    district_code: 140115,
    amphoe_code: 1416,
    province_code: 14,
  },
  {
    tambon: "สองห้อง",
    amphoe: "บ้านแพรก",
    province: "พระนครศรีอยุธยา",
    zipcode: 13240,
    district_code: 141605,
    amphoe_code: 1416,
    province_code: 14,
  },
  {
    tambon: "สำพะเนียง",
    amphoe: "บ้านแพรก",
    province: "พระนครศรีอยุธยา",
    zipcode: 13240,
    district_code: 141603,
    amphoe_code: 1416,
    province_code: 14,
  },
  {
    tambon: "กุฎี",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140809,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "จักราช",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13280,
    district_code: 140812,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "ดอนลาน",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13280,
    district_code: 140807,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "ตาลาน",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140805,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "ท่าดินแดง",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140806,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "นาคู",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13280,
    district_code: 140808,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "บ้านแค",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140803,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "บ้านใหญ่",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140816,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "ผักไห่",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140801,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "ลาดชิด",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140814,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "ลาดน้ำเค็ม",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140804,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "ลำตะเคียน",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13280,
    district_code: 140810,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "หนองน้ำใหญ่",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13280,
    district_code: 140813,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "หน้าโคก",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140815,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "อมฤต",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140802,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "โคกช้าง",
    amphoe: "ผักไห่",
    province: "พระนครศรีอยุธยา",
    zipcode: 13120,
    district_code: 140423,
    amphoe_code: 1408,
    province_code: 14,
  },
  {
    tambon: "กะมัง",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140102,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "คลองตะเคียน",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140111,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "คลองสระบัว",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140118,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "คลองสวนพลู",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140117,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "ท่าวาสุกรี",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140105,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "บ้านป้อม",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140120,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "บ้านรุน",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140121,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "บ้านเกาะ",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140116,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140115,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "ประตูชัย",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140101,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "ปากกราน",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140107,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "ภูเขาทอง",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140108,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "ลุมพลี",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140114,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "วัดตูม",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140112,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "สวนพริก",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140110,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "สำเภาล่ม",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140109,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "หอรัตนไชย",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140103,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "หันตรา",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140113,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "หัวรอ",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140104,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "เกาะเรียน",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140119,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "ไผ่ลิง",
    amphoe: "พระนครศรีอยุธยา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13000,
    district_code: 140106,
    amphoe_code: 1401,
    province_code: 14,
  },
  {
    tambon: "กระจิว",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140907,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "ดอนหญ้านาง",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140905,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "พระแก้ว",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140908,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "ภาชี",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140901,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "ระโสม",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140903,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "หนองน้ำใส",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140904,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "โคกม่วง",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140902,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "ไผ่ล้อม",
    amphoe: "ภาชี",
    province: "พระนครศรีอยุธยา",
    zipcode: 13140,
    district_code: 140906,
    amphoe_code: 1409,
    province_code: 14,
  },
  {
    tambon: "กะทุ่ม",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141502,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "ท่าตอ",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141511,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "น้ำเต้า",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 140509,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "บางนา",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141505,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "บ้านขวาง",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141510,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "บ้านนา",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141509,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 140115,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "พิตเพียน",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141508,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "มหาราช",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141503,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "หัวไผ่",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141501,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "เจ้าปลุก",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141507,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "โรงช้าง",
    amphoe: "มหาราช",
    province: "พระนครศรีอยุธยา",
    zipcode: 13150,
    district_code: 141506,
    amphoe_code: 1415,
    province_code: 14,
  },
  {
    tambon: "คลองพระยาบันลือ",
    amphoe: "ลาดบัวหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13230,
    district_code: 141007,
    amphoe_code: 1410,
    province_code: 14,
  },
  {
    tambon: "คู้สลอด",
    amphoe: "ลาดบัวหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13230,
    district_code: 141006,
    amphoe_code: 1410,
    province_code: 14,
  },
  {
    tambon: "พระยาบันลือ",
    amphoe: "ลาดบัวหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13230,
    district_code: 141004,
    amphoe_code: 1410,
    province_code: 14,
  },
  {
    tambon: "ลาดบัวหลวง",
    amphoe: "ลาดบัวหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13230,
    district_code: 141001,
    amphoe_code: 1410,
    province_code: 14,
  },
  {
    tambon: "สามเมือง",
    amphoe: "ลาดบัวหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13230,
    district_code: 141003,
    amphoe_code: 1410,
    province_code: 14,
  },
  {
    tambon: "สิงหนาท",
    amphoe: "ลาดบัวหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13230,
    district_code: 141005,
    amphoe_code: 1410,
    province_code: 14,
  },
  {
    tambon: "หลักชัย",
    amphoe: "ลาดบัวหลวง",
    province: "พระนครศรีอยุธยา",
    zipcode: 13230,
    district_code: 141002,
    amphoe_code: 1410,
    province_code: 14,
  },
  {
    tambon: "ข้าวงาม",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141109,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "ชะแมบ",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141110,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "บ่อตาโล่",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141102,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "พยอม",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141106,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "ลำตาเสา",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141101,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "ลำไทร",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141104,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "วังจุฬา",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141108,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "วังน้อย",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141103,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "สนับทึบ",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141105,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "หันตะเภา",
    amphoe: "วังน้อย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13170,
    district_code: 141107,
    amphoe_code: 1411,
    province_code: 14,
  },
  {
    tambon: "ข้าวเม่า",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141411,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "คานหาม",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141401,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "ธนู",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141410,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "บ้านช้าง",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141402,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "บ้านหีบ",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141404,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "สามบัณฑิต",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141403,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "หนองน้ำส้ม",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141408,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "หนองไม้ซุง",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141405,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "อุทัย",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141406,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "เสนา",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141201,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "โพสาวหาญ",
    amphoe: "อุทัย",
    province: "พระนครศรีอยุธยา",
    zipcode: 13210,
    district_code: 141409,
    amphoe_code: 1414,
    province_code: 14,
  },
  {
    tambon: "ชายนา",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141212,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "ดอนทอง",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141215,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "บางนมโค",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141205,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "บ้านกระทุ่ม",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141210,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "บ้านหลวง",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141216,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "บ้านแถว",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141211,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "บ้านแพน",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141202,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "บ้านโพธิ์",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141208,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "มารวิชัย",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141207,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "รางจรเข้",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141209,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "ลาดงา",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141214,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "สามกอ",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141204,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "สามตุ่ม",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141213,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "หัวเวียง",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141206,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "เจ้าเจ็ด",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141203,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "เจ้าเสด็จ",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141217,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "เสนา",
    amphoe: "เสนา",
    province: "พระนครศรีอยุธยา",
    zipcode: 13110,
    district_code: 141201,
    amphoe_code: 1412,
    province_code: 14,
  },
  {
    tambon: "จุน",
    amphoe: "จุน",
    province: "พะเยา",
    zipcode: 56150,
    district_code: 560202,
    amphoe_code: 5602,
    province_code: 56,
  },
  {
    tambon: "ทุ่งรวงทอง",
    amphoe: "จุน",
    province: "พะเยา",
    zipcode: 56150,
    district_code: 560205,
    amphoe_code: 5602,
    province_code: 56,
  },
  {
    tambon: "พระธาตุขิงแกง",
    amphoe: "จุน",
    province: "พะเยา",
    zipcode: 56150,
    district_code: 560207,
    amphoe_code: 5602,
    province_code: 56,
  },
  {
    tambon: "ลอ",
    amphoe: "จุน",
    province: "พะเยา",
    zipcode: 56150,
    district_code: 560203,
    amphoe_code: 5602,
    province_code: 56,
  },
  {
    tambon: "หงส์หิน",
    amphoe: "จุน",
    province: "พะเยา",
    zipcode: 56150,
    district_code: 560204,
    amphoe_code: 5602,
    province_code: 56,
  },
  {
    tambon: "ห้วยข้าวก่ำ",
    amphoe: "จุน",
    province: "พะเยา",
    zipcode: 56150,
    district_code: 560201,
    amphoe_code: 5602,
    province_code: 56,
  },
  {
    tambon: "ห้วยยางขาม",
    amphoe: "จุน",
    province: "พะเยา",
    zipcode: 56150,
    district_code: 560206,
    amphoe_code: 5602,
    province_code: 56,
  },
  {
    tambon: "คือเวียง",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560512,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "ดงสุวรรณ",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560509,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "ดอกคำใต้",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560501,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "ดอนศรีชุม",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560502,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "บุญเกิด",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560510,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "บ้านถ้ำ",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560503,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "บ้านปิน",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560504,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "ป่าซาง",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560507,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "สว่างอารมณ์",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560511,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "สันโค้ง",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560506,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "หนองหล่ม",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560508,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "ห้วยลาน",
    amphoe: "ดอกคำใต้",
    province: "พะเยา",
    zipcode: 56120,
    district_code: 560505,
    amphoe_code: 5605,
    province_code: 56,
  },
  {
    tambon: "ขุนควร",
    amphoe: "ปง",
    province: "พะเยา",
    zipcode: 56140,
    district_code: 560607,
    amphoe_code: 5606,
    province_code: 56,
  },
  {
    tambon: "ควร",
    amphoe: "ปง",
    province: "พะเยา",
    zipcode: 56140,
    district_code: 560602,
    amphoe_code: 5606,
    province_code: 56,
  },
  {
    tambon: "งิม",
    amphoe: "ปง",
    province: "พะเยา",
    zipcode: 56140,
    district_code: 560604,
    amphoe_code: 5606,
    province_code: 56,
  },
  {
    tambon: "นาปรัง",
    amphoe: "ปง",
    province: "พะเยา",
    zipcode: 56140,
    district_code: 560606,
    amphoe_code: 5606,
    province_code: 56,
  },
  {
    tambon: "ปง",
    amphoe: "ปง",
    province: "พะเยา",
    zipcode: 56140,
    district_code: 560601,
    amphoe_code: 5606,
    province_code: 56,
  },
  {
    tambon: "ผาช้างน้อย",
    amphoe: "ปง",
    province: "พะเยา",
    zipcode: 56140,
    district_code: 560605,
    amphoe_code: 5606,
    province_code: 56,
  },
  {
    tambon: "ออย",
    amphoe: "ปง",
    province: "พะเยา",
    zipcode: 56140,
    district_code: 560603,
    amphoe_code: 5606,
    province_code: 56,
  },
  {
    tambon: "ดงเจน",
    amphoe: "ภูกามยาว",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560103,
    amphoe_code: 5609,
    province_code: 56,
  },
  {
    tambon: "ห้วยแก้ว",
    amphoe: "ภูกามยาว",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560109,
    amphoe_code: 5609,
    province_code: 56,
  },
  {
    tambon: "แม่อิง",
    amphoe: "ภูกามยาว",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560117,
    amphoe_code: 5609,
    province_code: 56,
  },
  {
    tambon: "ทุ่งกล้วย",
    amphoe: "ภูซาง",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560302,
    amphoe_code: 5608,
    province_code: 56,
  },
  {
    tambon: "ป่าสัก",
    amphoe: "ภูซาง",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560315,
    amphoe_code: 5608,
    province_code: 56,
  },
  {
    tambon: "ภูซาง",
    amphoe: "ภูซาง",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560305,
    amphoe_code: 5608,
    province_code: 56,
  },
  {
    tambon: "สบบง",
    amphoe: "ภูซาง",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560303,
    amphoe_code: 5608,
    province_code: 56,
  },
  {
    tambon: "เชียงแรง",
    amphoe: "ภูซาง",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560304,
    amphoe_code: 5608,
    province_code: 56,
  },
  {
    tambon: "ทุ่งผาสุข",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560314,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "น้ำแวน",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560306,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "ฝายกวาง",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560308,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "ร่มเย็น",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560310,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "หย่วน",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560301,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "อ่างทอง",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560313,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "เจดีย์คำ",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560309,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "เชียงบาน",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560311,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "เวียง",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560101,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "แม่ลาว",
    amphoe: "เชียงคำ",
    province: "พะเยา",
    zipcode: 56110,
    district_code: 560312,
    amphoe_code: 5603,
    province_code: 56,
  },
  {
    tambon: "บ้านมาง",
    amphoe: "เชียงม่วน",
    province: "พะเยา",
    zipcode: 56160,
    district_code: 560402,
    amphoe_code: 5604,
    province_code: 56,
  },
  {
    tambon: "สระ",
    amphoe: "เชียงม่วน",
    province: "พะเยา",
    zipcode: 56160,
    district_code: 560403,
    amphoe_code: 5604,
    province_code: 56,
  },
  {
    tambon: "เชียงม่วน",
    amphoe: "เชียงม่วน",
    province: "พะเยา",
    zipcode: 56160,
    district_code: 560401,
    amphoe_code: 5604,
    province_code: 56,
  },
  {
    tambon: "จำป่าหวาย",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560112,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "ท่าจำปี",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560116,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "ท่าวังทอง",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560113,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "บ้านตุ่น",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560105,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "บ้านต๊ำ",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560106,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "บ้านต๋อม",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560107,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "บ้านสาง",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560115,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560111,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "สันป่าม่วง",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560118,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "เวียง",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560101,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "แม่กา",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560110,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "แม่ต๋ำ",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560102,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "แม่นาเรือ",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560104,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "แม่ปืม",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560108,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "แม่ใส",
    amphoe: "เมืองพะเยา",
    province: "พะเยา",
    zipcode: 56000,
    district_code: 560114,
    amphoe_code: 5601,
    province_code: 56,
  },
  {
    tambon: "บ้านเหล่า",
    amphoe: "แม่ใจ",
    province: "พะเยา",
    zipcode: 56130,
    district_code: 560705,
    amphoe_code: 5607,
    province_code: 56,
  },
  {
    tambon: "ป่าแฝก",
    amphoe: "แม่ใจ",
    province: "พะเยา",
    zipcode: 56130,
    district_code: 560704,
    amphoe_code: 5607,
    province_code: 56,
  },
  {
    tambon: "ศรีถ้อย",
    amphoe: "แม่ใจ",
    province: "พะเยา",
    zipcode: 56130,
    district_code: 560702,
    amphoe_code: 5607,
    province_code: 56,
  },
  {
    tambon: "เจริญราษฎร์",
    amphoe: "แม่ใจ",
    province: "พะเยา",
    zipcode: 56130,
    district_code: 560706,
    amphoe_code: 5607,
    province_code: 56,
  },
  {
    tambon: "แม่สุก",
    amphoe: "แม่ใจ",
    province: "พะเยา",
    zipcode: 56130,
    district_code: 560703,
    amphoe_code: 5607,
    province_code: 56,
  },
  {
    tambon: "แม่ใจ",
    amphoe: "แม่ใจ",
    province: "พะเยา",
    zipcode: 56130,
    district_code: 560701,
    amphoe_code: 5607,
    province_code: 56,
  },
  {
    tambon: "กะปง",
    amphoe: "กะปง",
    province: "พังงา",
    zipcode: 82170,
    district_code: 820301,
    amphoe_code: 8203,
    province_code: 82,
  },
  {
    tambon: "ท่านา",
    amphoe: "กะปง",
    province: "พังงา",
    zipcode: 82170,
    district_code: 820302,
    amphoe_code: 8203,
    province_code: 82,
  },
  {
    tambon: "รมณีย์",
    amphoe: "กะปง",
    province: "พังงา",
    zipcode: 82170,
    district_code: 820305,
    amphoe_code: 8203,
    province_code: 82,
  },
  {
    tambon: "เหมาะ",
    amphoe: "กะปง",
    province: "พังงา",
    zipcode: 82170,
    district_code: 820303,
    amphoe_code: 8203,
    province_code: 82,
  },
  {
    tambon: "เหล",
    amphoe: "กะปง",
    province: "พังงา",
    zipcode: 82170,
    district_code: 820304,
    amphoe_code: 8203,
    province_code: 82,
  },
  {
    tambon: "คุระ",
    amphoe: "คุระบุรี",
    province: "พังงา",
    zipcode: 82150,
    district_code: 820601,
    amphoe_code: 8206,
    province_code: 82,
  },
  {
    tambon: "บางวัน",
    amphoe: "คุระบุรี",
    province: "พังงา",
    zipcode: 82150,
    district_code: 820602,
    amphoe_code: 8206,
    province_code: 82,
  },
  {
    tambon: "เกาะพระทอง",
    amphoe: "คุระบุรี",
    province: "พังงา",
    zipcode: 82150,
    district_code: 820603,
    amphoe_code: 8206,
    province_code: 82,
  },
  {
    tambon: "แม่นางขาว",
    amphoe: "คุระบุรี",
    province: "พังงา",
    zipcode: 82150,
    district_code: 820605,
    amphoe_code: 8206,
    province_code: 82,
  },
  {
    tambon: "กระโสม",
    amphoe: "ตะกั่วทุ่ง",
    province: "พังงา",
    zipcode: 82130,
    district_code: 820402,
    amphoe_code: 8204,
    province_code: 82,
  },
  {
    tambon: "กะไหล",
    amphoe: "ตะกั่วทุ่ง",
    province: "พังงา",
    zipcode: 82130,
    district_code: 820403,
    amphoe_code: 8204,
    province_code: 82,
  },
  {
    tambon: "คลองเคียน",
    amphoe: "ตะกั่วทุ่ง",
    province: "พังงา",
    zipcode: 82130,
    district_code: 820407,
    amphoe_code: 8204,
    province_code: 82,
  },
  {
    tambon: "ถ้ำ",
    amphoe: "ตะกั่วทุ่ง",
    province: "พังงา",
    zipcode: 82130,
    district_code: 820401,
    amphoe_code: 8204,
    province_code: 82,
  },
  {
    tambon: "ท่าอยู่",
    amphoe: "ตะกั่วทุ่ง",
    province: "พังงา",
    zipcode: 82130,
    district_code: 820404,
    amphoe_code: 8204,
    province_code: 82,
  },
  {
    tambon: "หล่อยูง",
    amphoe: "ตะกั่วทุ่ง",
    province: "พังงา",
    zipcode: 82140,
    district_code: 820405,
    amphoe_code: 8204,
    province_code: 82,
  },
  {
    tambon: "โคกกลอย",
    amphoe: "ตะกั่วทุ่ง",
    province: "พังงา",
    zipcode: 82140,
    district_code: 820406,
    amphoe_code: 8204,
    province_code: 82,
  },
  {
    tambon: "คึกคัก",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82220,
    district_code: 820507,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "ตะกั่วป่า",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82110,
    district_code: 820501,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "ตำตัว",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82110,
    district_code: 820505,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "บางนายสี",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82110,
    district_code: 820502,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "บางม่วง",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82190,
    district_code: 820504,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "บางไทร",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82110,
    district_code: 820503,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "เกาะคอเขา",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82190,
    district_code: 820508,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "โคกเคียน",
    amphoe: "ตะกั่วป่า",
    province: "พังงา",
    zipcode: 82110,
    district_code: 820506,
    amphoe_code: 8205,
    province_code: 82,
  },
  {
    tambon: "ถ้ำทองหลาง",
    amphoe: "ทับปุด",
    province: "พังงา",
    zipcode: 82180,
    district_code: 820704,
    amphoe_code: 8207,
    province_code: 82,
  },
  {
    tambon: "ทับปุด",
    amphoe: "ทับปุด",
    province: "พังงา",
    zipcode: 82180,
    district_code: 820701,
    amphoe_code: 8207,
    province_code: 82,
  },
  {
    tambon: "บางเหรียง",
    amphoe: "ทับปุด",
    province: "พังงา",
    zipcode: 82180,
    district_code: 820706,
    amphoe_code: 8207,
    province_code: 82,
  },
  {
    tambon: "บ่อแสน",
    amphoe: "ทับปุด",
    province: "พังงา",
    zipcode: 82180,
    district_code: 820703,
    amphoe_code: 8207,
    province_code: 82,
  },
  {
    tambon: "มะรุ่ย",
    amphoe: "ทับปุด",
    province: "พังงา",
    zipcode: 82180,
    district_code: 820702,
    amphoe_code: 8207,
    province_code: 82,
  },
  {
    tambon: "โคกเจริญ",
    amphoe: "ทับปุด",
    province: "พังงา",
    zipcode: 82180,
    district_code: 820705,
    amphoe_code: 8207,
    province_code: 82,
  },
  {
    tambon: "ทุ่งมะพร้าว",
    amphoe: "ท้ายเหมือง",
    province: "พังงา",
    zipcode: 82120,
    district_code: 820804,
    amphoe_code: 8208,
    province_code: 82,
  },
  {
    tambon: "ท้ายเหมือง",
    amphoe: "ท้ายเหมือง",
    province: "พังงา",
    zipcode: 82120,
    district_code: 820801,
    amphoe_code: 8208,
    province_code: 82,
  },
  {
    tambon: "นาเตย",
    amphoe: "ท้ายเหมือง",
    province: "พังงา",
    zipcode: 82120,
    district_code: 820802,
    amphoe_code: 8208,
    province_code: 82,
  },
  {
    tambon: "บางทอง",
    amphoe: "ท้ายเหมือง",
    province: "พังงา",
    zipcode: 82120,
    district_code: 820803,
    amphoe_code: 8208,
    province_code: 82,
  },
  {
    tambon: "ลำภี",
    amphoe: "ท้ายเหมือง",
    province: "พังงา",
    zipcode: 82120,
    district_code: 820805,
    amphoe_code: 8208,
    province_code: 82,
  },
  {
    tambon: "ลำแก่น",
    amphoe: "ท้ายเหมือง",
    province: "พังงา",
    zipcode: 82210,
    district_code: 820806,
    amphoe_code: 8208,
    province_code: 82,
  },
  {
    tambon: "พรุใน",
    amphoe: "เกาะยาว",
    province: "พังงา",
    zipcode: 83000,
    district_code: 820203,
    amphoe_code: 8202,
    province_code: 82,
  },
  {
    tambon: "เกาะยาวน้อย",
    amphoe: "เกาะยาว",
    province: "พังงา",
    zipcode: 82160,
    district_code: 820199,
    amphoe_code: 8202,
    province_code: 82,
  },
  {
    tambon: "เกาะยาวใหญ่",
    amphoe: "เกาะยาว",
    province: "พังงา",
    zipcode: 82160,
    district_code: 820198,
    amphoe_code: 8202,
    province_code: 82,
  },
  {
    tambon: "ตากแดด",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820105,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "ถ้ำน้ำผุด",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820103,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "ทุ่งคาโงก",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820107,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "ท้ายช้าง",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820101,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "นบปริง",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820102,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "บางเตย",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820104,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "ป่ากอ",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820109,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "สองแพรก",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820106,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "เกาะปันหยี",
    amphoe: "เมืองพังงา",
    province: "พังงา",
    zipcode: 82000,
    district_code: 820108,
    amphoe_code: 8201,
    province_code: 82,
  },
  {
    tambon: "กงหรา",
    amphoe: "กงหรา",
    province: "พัทลุง",
    zipcode: 93180,
    district_code: 930201,
    amphoe_code: 9302,
    province_code: 93,
  },
  {
    tambon: "คลองทรายขาว",
    amphoe: "กงหรา",
    province: "พัทลุง",
    zipcode: 93180,
    district_code: 930204,
    amphoe_code: 9302,
    province_code: 93,
  },
  {
    tambon: "คลองเฉลิม",
    amphoe: "กงหรา",
    province: "พัทลุง",
    zipcode: 93180,
    district_code: 930203,
    amphoe_code: 9302,
    province_code: 93,
  },
  {
    tambon: "ชะรัด",
    amphoe: "กงหรา",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930202,
    amphoe_code: 9302,
    province_code: 93,
  },
  {
    tambon: "สมหวัง",
    amphoe: "กงหรา",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930205,
    amphoe_code: 9302,
    province_code: 93,
  },
  {
    tambon: "ควนขนุน",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930302,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "ชะมวง",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930513,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "ดอนทราย",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930510,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "ทะเลน้อย",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93150,
    district_code: 930502,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "นาขยาด",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930504,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "ปันแต",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930508,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "พนมวังก์",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930505,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "พนางตุง",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93150,
    district_code: 930512,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "มะกอกเหนือ",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93150,
    district_code: 930511,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "แพรกหา",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930516,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "แหลมโตนด",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930506,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "โตนดด้วน",
    amphoe: "ควนขนุน",
    province: "พัทลุง",
    zipcode: 93110,
    district_code: 930509,
    amphoe_code: 9305,
    province_code: 93,
  },
  {
    tambon: "คลองใหญ่",
    amphoe: "ตะโหมด",
    province: "พัทลุง",
    zipcode: 93160,
    district_code: 930396,
    amphoe_code: 9304,
    province_code: 93,
  },
  {
    tambon: "ตะโหมด",
    amphoe: "ตะโหมด",
    province: "พัทลุง",
    zipcode: 93160,
    district_code: 930397,
    amphoe_code: 9304,
    province_code: 93,
  },
  {
    tambon: "แม่ขรี",
    amphoe: "ตะโหมด",
    province: "พัทลุง",
    zipcode: 93160,
    district_code: 930399,
    amphoe_code: 9304,
    province_code: 93,
  },
  {
    tambon: "ท่ามะเดื่อ",
    amphoe: "บางแก้ว",
    province: "พัทลุง",
    zipcode: 93140,
    district_code: 930303,
    amphoe_code: 9309,
    province_code: 93,
  },
  {
    tambon: "นาปะขอ",
    amphoe: "บางแก้ว",
    province: "พัทลุง",
    zipcode: 93140,
    district_code: 930304,
    amphoe_code: 9309,
    province_code: 93,
  },
  {
    tambon: "โคกสัก",
    amphoe: "บางแก้ว",
    province: "พัทลุง",
    zipcode: 93140,
    district_code: 930308,
    amphoe_code: 9309,
    province_code: 93,
  },
  {
    tambon: "ดอนทราย",
    amphoe: "ปากพะยูน",
    province: "พัทลุง",
    zipcode: 93120,
    district_code: 930510,
    amphoe_code: 9306,
    province_code: 93,
  },
  {
    tambon: "ดอนประดู่",
    amphoe: "ปากพะยูน",
    province: "พัทลุง",
    zipcode: 93120,
    district_code: 930602,
    amphoe_code: 9306,
    province_code: 93,
  },
  {
    tambon: "ปากพะยูน",
    amphoe: "ปากพะยูน",
    province: "พัทลุง",
    zipcode: 93120,
    district_code: 930601,
    amphoe_code: 9306,
    province_code: 93,
  },
  {
    tambon: "ฝาละมี",
    amphoe: "ปากพะยูน",
    province: "พัทลุง",
    zipcode: 93120,
    district_code: 930605,
    amphoe_code: 9306,
    province_code: 93,
  },
  {
    tambon: "หารเทา",
    amphoe: "ปากพะยูน",
    province: "พัทลุง",
    zipcode: 93120,
    district_code: 930606,
    amphoe_code: 9306,
    province_code: 93,
  },
  {
    tambon: "เกาะนางคำ",
    amphoe: "ปากพะยูน",
    province: "พัทลุง",
    zipcode: 93120,
    district_code: 930603,
    amphoe_code: 9306,
    province_code: 93,
  },
  {
    tambon: "เกาะหมาก",
    amphoe: "ปากพะยูน",
    province: "พัทลุง",
    zipcode: 93120,
    district_code: 930604,
    amphoe_code: 9306,
    province_code: 93,
  },
  {
    tambon: "ทุ่งนารี",
    amphoe: "ป่าบอน",
    province: "พัทลุง",
    zipcode: 93170,
    district_code: 930804,
    amphoe_code: 9308,
    province_code: 93,
  },
  {
    tambon: "ป่าบอน",
    amphoe: "ป่าบอน",
    province: "พัทลุง",
    zipcode: 93170,
    district_code: 930699,
    amphoe_code: 9308,
    province_code: 93,
  },
  {
    tambon: "วังใหม่",
    amphoe: "ป่าบอน",
    province: "พัทลุง",
    zipcode: 93170,
    district_code: 930806,
    amphoe_code: 9308,
    province_code: 93,
  },
  {
    tambon: "หนองธง",
    amphoe: "ป่าบอน",
    province: "พัทลุง",
    zipcode: 93170,
    district_code: 930803,
    amphoe_code: 9308,
    province_code: 93,
  },
  {
    tambon: "โคกทราย",
    amphoe: "ป่าบอน",
    province: "พัทลุง",
    zipcode: 93170,
    district_code: 930698,
    amphoe_code: 9308,
    province_code: 93,
  },
  {
    tambon: "บ้านพร้าว",
    amphoe: "ป่าพะยอม",
    province: "พัทลุง",
    zipcode: 93210,
    district_code: 930514,
    amphoe_code: 9310,
    province_code: 93,
  },
  {
    tambon: "ป่าพะยอม",
    amphoe: "ป่าพะยอม",
    province: "พัทลุง",
    zipcode: 93210,
    district_code: 930507,
    amphoe_code: 9310,
    province_code: 93,
  },
  {
    tambon: "ลานข่อย",
    amphoe: "ป่าพะยอม",
    province: "พัทลุง",
    zipcode: 93210,
    district_code: 930515,
    amphoe_code: 9310,
    province_code: 93,
  },
  {
    tambon: "เกาะเต่า",
    amphoe: "ป่าพะยอม",
    province: "พัทลุง",
    zipcode: 93210,
    district_code: 930503,
    amphoe_code: 9310,
    province_code: 93,
  },
  {
    tambon: "ชุมพล",
    amphoe: "ศรีนครินทร์",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930118,
    amphoe_code: 9311,
    province_code: 93,
  },
  {
    tambon: "บ้านนา",
    amphoe: "ศรีนครินทร์",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930102,
    amphoe_code: 9311,
    province_code: 93,
  },
  {
    tambon: "ลำสินธุ์",
    amphoe: "ศรีนครินทร์",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930116,
    amphoe_code: 9311,
    province_code: 93,
  },
  {
    tambon: "อ่างทอง",
    amphoe: "ศรีนครินทร์",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930117,
    amphoe_code: 9311,
    province_code: 93,
  },
  {
    tambon: "ตะแพน",
    amphoe: "ศรีบรรพต",
    province: "พัทลุง",
    zipcode: 93190,
    district_code: 930703,
    amphoe_code: 9307,
    province_code: 93,
  },
  {
    tambon: "เขาปู่",
    amphoe: "ศรีบรรพต",
    province: "พัทลุง",
    zipcode: 93190,
    district_code: 930702,
    amphoe_code: 9307,
    province_code: 93,
  },
  {
    tambon: "เขาย่า",
    amphoe: "ศรีบรรพต",
    province: "พัทลุง",
    zipcode: 93190,
    district_code: 930701,
    amphoe_code: 9307,
    province_code: 93,
  },
  {
    tambon: "ควนขนุน",
    amphoe: "เขาชัยสน",
    province: "พัทลุง",
    zipcode: 93130,
    district_code: 930302,
    amphoe_code: 9303,
    province_code: 93,
  },
  {
    tambon: "จองถนน",
    amphoe: "เขาชัยสน",
    province: "พัทลุง",
    zipcode: 93130,
    district_code: 930305,
    amphoe_code: 9303,
    province_code: 93,
  },
  {
    tambon: "หานโพธิ์",
    amphoe: "เขาชัยสน",
    province: "พัทลุง",
    zipcode: 93130,
    district_code: 930306,
    amphoe_code: 9303,
    province_code: 93,
  },
  {
    tambon: "เขาชัยสน",
    amphoe: "เขาชัยสน",
    province: "พัทลุง",
    zipcode: 93130,
    district_code: 930301,
    amphoe_code: 9303,
    province_code: 93,
  },
  {
    tambon: "โคกม่วง",
    amphoe: "เขาชัยสน",
    province: "พัทลุง",
    zipcode: 93130,
    district_code: 930307,
    amphoe_code: 9303,
    province_code: 93,
  },
  {
    tambon: "ควนมะพร้าว",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930111,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "คูหาสวรรค์",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930101,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "ชัยบุรี",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930113,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "ตำนาน",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930110,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "ท่ามิหรำ",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930104,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "ท่าแค",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930108,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "นาท่อม",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930106,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "นาโหนด",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930114,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "ปรางหมู่",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930107,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "พญาขัน",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930115,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "ร่มเมือง",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930112,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "ลำปำ",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930109,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "เขาเจียก",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930103,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "โคกชะงาย",
    amphoe: "เมืองพัทลุง",
    province: "พัทลุง",
    zipcode: 93000,
    district_code: 930105,
    amphoe_code: 9301,
    province_code: 93,
  },
  {
    tambon: "วังงิ้ว",
    amphoe: "ดงเจริญ",
    province: "พิจิตร",
    zipcode: 66210,
    district_code: 660513,
    amphoe_code: 6611,
    province_code: 66,
  },
  {
    tambon: "วังงิ้วใต้",
    amphoe: "ดงเจริญ",
    province: "พิจิตร",
    zipcode: 66210,
    district_code: 660515,
    amphoe_code: 6611,
    province_code: 66,
  },
  {
    tambon: "สำนักขุนเณร",
    amphoe: "ดงเจริญ",
    province: "พิจิตร",
    zipcode: 66210,
    district_code: 660510,
    amphoe_code: 6611,
    province_code: 66,
  },
  {
    tambon: "ห้วยพุก",
    amphoe: "ดงเจริญ",
    province: "พิจิตร",
    zipcode: 66210,
    district_code: 660511,
    amphoe_code: 6611,
    province_code: 66,
  },
  {
    tambon: "ห้วยร่วม",
    amphoe: "ดงเจริญ",
    province: "พิจิตร",
    zipcode: 66210,
    district_code: 660512,
    amphoe_code: 6611,
    province_code: 66,
  },
  {
    tambon: "คลองคูณ",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660408,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "งิ้วราย",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660402,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ดงตะขบ",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660407,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ตะพานหิน",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660401,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ทับหมัน",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660412,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ทุ่งโพธิ์",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66150,
    district_code: 660406,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "วังสำโรง",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660409,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "วังหลุม",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66150,
    district_code: 660411,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "วังหว้า",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660410,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "หนองพยอม",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660405,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ห้วยเกตุ",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660403,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ไทรโรงโขน",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660404,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ไผ่หลวง",
    amphoe: "ตะพานหิน",
    province: "พิจิตร",
    zipcode: 66110,
    district_code: 660413,
    amphoe_code: 6604,
    province_code: 66,
  },
  {
    tambon: "ทับคล้อ",
    amphoe: "ทับคล้อ",
    province: "พิจิตร",
    zipcode: 66150,
    district_code: 660499,
    amphoe_code: 6608,
    province_code: 66,
  },
  {
    tambon: "ท้ายทุ่ง",
    amphoe: "ทับคล้อ",
    province: "พิจิตร",
    zipcode: 66150,
    district_code: 660496,
    amphoe_code: 6608,
    province_code: 66,
  },
  {
    tambon: "เขาทราย",
    amphoe: "ทับคล้อ",
    province: "พิจิตร",
    zipcode: 66230,
    district_code: 660498,
    amphoe_code: 6608,
    province_code: 66,
  },
  {
    tambon: "เขาเจ็ดลูก",
    amphoe: "ทับคล้อ",
    province: "พิจิตร",
    zipcode: 66230,
    district_code: 660497,
    amphoe_code: 6608,
    province_code: 66,
  },
  {
    tambon: "บางมูลนาก",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660501,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "บางไผ่",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660502,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "ภูมิ",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660506,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "ลำประดา",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660514,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "วังกรด",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660507,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "วังตะกู",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66210,
    district_code: 660509,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "วังสำโรง",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660409,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "หอไกร",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660503,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "ห้วยเขน",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660508,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "เนินมะกอก",
    amphoe: "บางมูลนาก",
    province: "พิจิตร",
    zipcode: 66120,
    district_code: 660504,
    amphoe_code: 6605,
    province_code: 66,
  },
  {
    tambon: "บางลาย",
    amphoe: "บึงนาราง",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660609,
    amphoe_code: 6610,
    province_code: 66,
  },
  {
    tambon: "บึงนาราง",
    amphoe: "บึงนาราง",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660610,
    amphoe_code: 6610,
    province_code: 66,
  },
  {
    tambon: "ห้วยแก้ว",
    amphoe: "บึงนาราง",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660616,
    amphoe_code: 6610,
    province_code: 66,
  },
  {
    tambon: "แหลมรัง",
    amphoe: "บึงนาราง",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660615,
    amphoe_code: 6610,
    province_code: 66,
  },
  {
    tambon: "โพธิ์ไทรงาม",
    amphoe: "บึงนาราง",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660614,
    amphoe_code: 6610,
    province_code: 66,
  },
  {
    tambon: "บึงบัว",
    amphoe: "วชิรบารมี",
    province: "พิจิตร",
    zipcode: 66140,
    district_code: 660709,
    amphoe_code: 6612,
    province_code: 66,
  },
  {
    tambon: "บ้านนา",
    amphoe: "วชิรบารมี",
    province: "พิจิตร",
    zipcode: 66140,
    district_code: 660705,
    amphoe_code: 6612,
    province_code: 66,
  },
  {
    tambon: "วังโมกข์",
    amphoe: "วชิรบารมี",
    province: "พิจิตร",
    zipcode: 66140,
    district_code: 660708,
    amphoe_code: 6612,
    province_code: 66,
  },
  {
    tambon: "หนองหลุม",
    amphoe: "วชิรบารมี",
    province: "พิจิตร",
    zipcode: 66220,
    district_code: 660704,
    amphoe_code: 6612,
    province_code: 66,
  },
  {
    tambon: "วังทรายพูน",
    amphoe: "วังทรายพูน",
    province: "พิจิตร",
    zipcode: 66180,
    district_code: 660199,
    amphoe_code: 6602,
    province_code: 66,
  },
  {
    tambon: "หนองปลาไหล",
    amphoe: "วังทรายพูน",
    province: "พิจิตร",
    zipcode: 66180,
    district_code: 660198,
    amphoe_code: 6602,
    province_code: 66,
  },
  {
    tambon: "หนองปล้อง",
    amphoe: "วังทรายพูน",
    province: "พิจิตร",
    zipcode: 66180,
    district_code: 660114,
    amphoe_code: 6602,
    province_code: 66,
  },
  {
    tambon: "หนองพระ",
    amphoe: "วังทรายพูน",
    province: "พิจิตร",
    zipcode: 66180,
    district_code: 660197,
    amphoe_code: 6602,
    province_code: 66,
  },
  {
    tambon: "คลองทราย",
    amphoe: "สากเหล็ก",
    province: "พิจิตร",
    zipcode: 66160,
    district_code: 660118,
    amphoe_code: 6609,
    province_code: 66,
  },
  {
    tambon: "ท่าเยี่ยม",
    amphoe: "สากเหล็ก",
    province: "พิจิตร",
    zipcode: 66160,
    district_code: 660117,
    amphoe_code: 6609,
    province_code: 66,
  },
  {
    tambon: "วังทับไทร",
    amphoe: "สากเหล็ก",
    province: "พิจิตร",
    zipcode: 66160,
    district_code: 660905,
    amphoe_code: 6609,
    province_code: 66,
  },
  {
    tambon: "สากเหล็ก",
    amphoe: "สากเหล็ก",
    province: "พิจิตร",
    zipcode: 66160,
    district_code: 660116,
    amphoe_code: 6609,
    province_code: 66,
  },
  {
    tambon: "หนองหญ้าไทร",
    amphoe: "สากเหล็ก",
    province: "พิจิตร",
    zipcode: 66160,
    district_code: 660904,
    amphoe_code: 6609,
    province_code: 66,
  },
  {
    tambon: "กำแพงดิน",
    amphoe: "สามง่าม",
    province: "พิจิตร",
    zipcode: 66220,
    district_code: 660702,
    amphoe_code: 6607,
    province_code: 66,
  },
  {
    tambon: "รังนก",
    amphoe: "สามง่าม",
    province: "พิจิตร",
    zipcode: 66140,
    district_code: 660703,
    amphoe_code: 6607,
    province_code: 66,
  },
  {
    tambon: "สามง่าม",
    amphoe: "สามง่าม",
    province: "พิจิตร",
    zipcode: 66140,
    district_code: 660701,
    amphoe_code: 6607,
    province_code: 66,
  },
  {
    tambon: "หนองโสน",
    amphoe: "สามง่าม",
    province: "พิจิตร",
    zipcode: 66140,
    district_code: 660707,
    amphoe_code: 6607,
    province_code: 66,
  },
  {
    tambon: "เนินปอ",
    amphoe: "สามง่าม",
    province: "พิจิตร",
    zipcode: 66140,
    district_code: 660706,
    amphoe_code: 6607,
    province_code: 66,
  },
  {
    tambon: "คลองคะเชนทร์",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660106,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ฆะมัง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660111,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ดงกลาง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66170,
    district_code: 660120,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ดงป่าคำ",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66170,
    district_code: 660112,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ท่าหลวง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660109,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ท่าฬ่อ",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660104,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "บ้านบุ่ง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660110,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ปากทาง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660105,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ป่ามะคาบ",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660115,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ย่านยาว",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660103,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "สายคำโห้",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660119,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "หัวดง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66170,
    district_code: 660113,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "เมืองเก่า",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660108,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "โรงช้าง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660107,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660101,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ไผ่ขวาง",
    amphoe: "เมืองพิจิตร",
    province: "พิจิตร",
    zipcode: 66000,
    district_code: 660102,
    amphoe_code: 6601,
    province_code: 66,
  },
  {
    tambon: "ทะนง",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660603,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "ทุ่งน้อย",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660605,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "ท่าขมิ้น",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660606,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "ท่านั่ง",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660611,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "ท่าบัว",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660604,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "ท่าเสา",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660607,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "ท้ายน้ำ",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660602,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "บางคลาน",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660608,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "บ้านน้อย",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660612,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "วัดขวาง",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660613,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "โพทะเล",
    amphoe: "โพทะเล",
    province: "พิจิตร",
    zipcode: 66130,
    district_code: 660601,
    amphoe_code: 6606,
    province_code: 66,
  },
  {
    tambon: "ดงเสือเหลือง",
    amphoe: "โพธิ์ประทับช้าง",
    province: "พิจิตร",
    zipcode: 66190,
    district_code: 660305,
    amphoe_code: 6603,
    province_code: 66,
  },
  {
    tambon: "ทุ่งใหญ่",
    amphoe: "โพธิ์ประทับช้าง",
    province: "พิจิตร",
    zipcode: 66190,
    district_code: 660307,
    amphoe_code: 6603,
    province_code: 66,
  },
  {
    tambon: "วังจิก",
    amphoe: "โพธิ์ประทับช้าง",
    province: "พิจิตร",
    zipcode: 66190,
    district_code: 660193,
    amphoe_code: 6603,
    province_code: 66,
  },
  {
    tambon: "เนินสว่าง",
    amphoe: "โพธิ์ประทับช้าง",
    province: "พิจิตร",
    zipcode: 66190,
    district_code: 660306,
    amphoe_code: 6603,
    province_code: 66,
  },
  {
    tambon: "โพธิ์ประทับช้าง",
    amphoe: "โพธิ์ประทับช้าง",
    province: "พิจิตร",
    zipcode: 66190,
    district_code: 660194,
    amphoe_code: 6603,
    province_code: 66,
  },
  {
    tambon: "ไผ่ท่าโพ",
    amphoe: "โพธิ์ประทับช้าง",
    province: "พิจิตร",
    zipcode: 66190,
    district_code: 660195,
    amphoe_code: 6603,
    province_code: 66,
  },
  {
    tambon: "ไผ่รอบ",
    amphoe: "โพธิ์ประทับช้าง",
    province: "พิจิตร",
    zipcode: 66190,
    district_code: 660192,
    amphoe_code: 6603,
    province_code: 66,
  },
  {
    tambon: "ชาติตระการ",
    amphoe: "ชาติตระการ",
    province: "พิษณุโลก",
    zipcode: 65170,
    district_code: 650302,
    amphoe_code: 6503,
    province_code: 65,
  },
  {
    tambon: "ท่าสะแก",
    amphoe: "ชาติตระการ",
    province: "พิษณุโลก",
    zipcode: 65170,
    district_code: 650306,
    amphoe_code: 6503,
    province_code: 65,
  },
  {
    tambon: "บ่อภาค",
    amphoe: "ชาติตระการ",
    province: "พิษณุโลก",
    zipcode: 65170,
    district_code: 650305,
    amphoe_code: 6503,
    province_code: 65,
  },
  {
    tambon: "บ้านดง",
    amphoe: "ชาติตระการ",
    province: "พิษณุโลก",
    zipcode: 65170,
    district_code: 650304,
    amphoe_code: 6503,
    province_code: 65,
  },
  {
    tambon: "ป่าแดง",
    amphoe: "ชาติตระการ",
    province: "พิษณุโลก",
    zipcode: 65170,
    district_code: 650301,
    amphoe_code: 6503,
    province_code: 65,
  },
  {
    tambon: "สวนเมี่ยง",
    amphoe: "ชาติตระการ",
    province: "พิษณุโลก",
    zipcode: 65170,
    district_code: 650303,
    amphoe_code: 6503,
    province_code: 65,
  },
  {
    tambon: "นครชุม",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650206,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "นครไทย",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650201,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "นาบัว",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650205,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "น้ำกุ่ม",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650207,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "บ่อโพธิ์",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650209,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "บ้านพร้าว",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650210,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "บ้านแยง",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650203,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "ยางโกลน",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650208,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "หนองกะท้าว",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650202,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "ห้วยเฮี้ย",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650211,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "เนินเพิ่ม",
    amphoe: "นครไทย",
    province: "พิษณุโลก",
    zipcode: 65120,
    district_code: 650204,
    amphoe_code: 6502,
    province_code: 65,
  },
  {
    tambon: "ท่าตาล",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65110,
    district_code: 650505,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "นครป่าหมาก",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65110,
    district_code: 650507,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "บางกระทุ่ม",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65110,
    district_code: 650501,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65110,
    district_code: 650502,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "วัดตายม",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65210,
    district_code: 650509,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "สนามคลี",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65110,
    district_code: 650504,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "เนินกุ่ม",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65210,
    district_code: 650508,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "โคกสลุด",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65110,
    district_code: 650503,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "ไผ่ล้อม",
    amphoe: "บางกระทุ่ม",
    province: "พิษณุโลก",
    zipcode: 65110,
    district_code: 650506,
    amphoe_code: 6505,
    province_code: 65,
  },
  {
    tambon: "คุยม่วง",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65240,
    district_code: 650411,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "ชุมแสงสงคราม",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65240,
    district_code: 650407,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "ท่านางงาม",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650410,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "นิคมพัฒนา",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650408,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "บางระกำ",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650401,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "บึงกอก",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650405,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "บ่อทอง",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650409,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "ปลักแรด",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650402,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "พันเสา",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650403,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "วังอิทก",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650404,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "หนองกุลา",
    amphoe: "บางระกำ",
    province: "พิษณุโลก",
    zipcode: 65140,
    district_code: 650406,
    amphoe_code: 6504,
    province_code: 65,
  },
  {
    tambon: "ดงประคำ",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65180,
    district_code: 650612,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "ตลุกเทียม",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65180,
    district_code: 650607,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "ทับยายเชียง",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65150,
    district_code: 650611,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65150,
    district_code: 650602,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "พรหมพิราม",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65150,
    district_code: 650601,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "มะตูม",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65150,
    district_code: 650604,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "มะต้อง",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65180,
    district_code: 650610,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "วงฆ้อง",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65180,
    district_code: 650603,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "วังวน",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65150,
    district_code: 650608,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "ศรีภิรมย์",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65180,
    district_code: 650606,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "หนองแขม",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65150,
    district_code: 650609,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "หอกลอง",
    amphoe: "พรหมพิราม",
    province: "พิษณุโลก",
    zipcode: 65150,
    district_code: 650605,
    amphoe_code: 6506,
    province_code: 65,
  },
  {
    tambon: "ชัยนาม",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650810,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "ดินทอง",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650811,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "ท่าหมื่นราม",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650807,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65220,
    district_code: 650804,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "พันชาลี",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650802,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "วังทอง",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650801,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "วังนกแอ่น",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650808,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "วังพิกุล",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650805,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "หนองพระ",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650809,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "แก่งโสภา",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65220,
    district_code: 650806,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "แม่ระกา",
    amphoe: "วังทอง",
    province: "พิษณุโลก",
    zipcode: 65130,
    district_code: 650803,
    amphoe_code: 6508,
    province_code: 65,
  },
  {
    tambon: "คันโช้ง",
    amphoe: "วัดโบสถ์",
    province: "พิษณุโลก",
    zipcode: 65160,
    district_code: 650706,
    amphoe_code: 6507,
    province_code: 65,
  },
  {
    tambon: "ท่างาม",
    amphoe: "วัดโบสถ์",
    province: "พิษณุโลก",
    zipcode: 65160,
    district_code: 650702,
    amphoe_code: 6507,
    province_code: 65,
  },
  {
    tambon: "ท้อแท้",
    amphoe: "วัดโบสถ์",
    province: "พิษณุโลก",
    zipcode: 65160,
    district_code: 650703,
    amphoe_code: 6507,
    province_code: 65,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "วัดโบสถ์",
    province: "พิษณุโลก",
    zipcode: 65160,
    district_code: 650704,
    amphoe_code: 6507,
    province_code: 65,
  },
  {
    tambon: "วัดโบสถ์",
    amphoe: "วัดโบสถ์",
    province: "พิษณุโลก",
    zipcode: 65160,
    district_code: 650701,
    amphoe_code: 6507,
    province_code: 65,
  },
  {
    tambon: "หินลาด",
    amphoe: "วัดโบสถ์",
    province: "พิษณุโลก",
    zipcode: 65160,
    district_code: 650705,
    amphoe_code: 6507,
    province_code: 65,
  },
  {
    tambon: "ชมพู",
    amphoe: "เนินมะปราง",
    province: "พิษณุโลก",
    zipcode: 65190,
    district_code: 650899,
    amphoe_code: 6509,
    province_code: 65,
  },
  {
    tambon: "บ้านน้อยซุ้มขี้เหล็ก",
    amphoe: "เนินมะปราง",
    province: "พิษณุโลก",
    zipcode: 65190,
    district_code: 650895,
    amphoe_code: 6509,
    province_code: 65,
  },
  {
    tambon: "บ้านมุง",
    amphoe: "เนินมะปราง",
    province: "พิษณุโลก",
    zipcode: 65190,
    district_code: 650898,
    amphoe_code: 6509,
    province_code: 65,
  },
  {
    tambon: "วังยาง",
    amphoe: "เนินมะปราง",
    province: "พิษณุโลก",
    zipcode: 65190,
    district_code: 650907,
    amphoe_code: 6509,
    province_code: 65,
  },
  {
    tambon: "วังโพรง",
    amphoe: "เนินมะปราง",
    province: "พิษณุโลก",
    zipcode: 65190,
    district_code: 650896,
    amphoe_code: 6509,
    province_code: 65,
  },
  {
    tambon: "เนินมะปราง",
    amphoe: "เนินมะปราง",
    province: "พิษณุโลก",
    zipcode: 65190,
    district_code: 650906,
    amphoe_code: 6509,
    province_code: 65,
  },
  {
    tambon: "ไทรย้อย",
    amphoe: "เนินมะปราง",
    province: "พิษณุโลก",
    zipcode: 65190,
    district_code: 650897,
    amphoe_code: 6509,
    province_code: 65,
  },
  {
    tambon: "งิ้วงาม",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65230,
    district_code: 650120,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "จอมทอง",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650112,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "ดอนทอง",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650108,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "ท่าทอง",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650105,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "ท่าโพธิ์",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650106,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "บึงพระ",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650118,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "บ้านกร่าง",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650113,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "บ้านคลอง",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650114,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "บ้านป่า",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650109,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "ปากโทก",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650110,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "พลายชุมพล",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650115,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "มะขามสูง",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650116,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "วังน้ำคู้",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65230,
    district_code: 650102,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "วัดจันทร์",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650103,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "วัดพริก",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65230,
    district_code: 650104,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "สมอแข",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650107,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "หัวรอ",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650111,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "อรัญญิก",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650117,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650101,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "ไผ่ขอดอน",
    amphoe: "เมืองพิษณุโลก",
    province: "พิษณุโลก",
    zipcode: 65000,
    district_code: 650119,
    amphoe_code: 6501,
    province_code: 65,
  },
  {
    tambon: "กมลา",
    amphoe: "กะทู้",
    province: "ภูเก็ต",
    zipcode: 83120,
    district_code: 830203,
    amphoe_code: 8302,
    province_code: 83,
  },
  {
    tambon: "กะทู้",
    amphoe: "กะทู้",
    province: "ภูเก็ต",
    zipcode: 83120,
    district_code: 830201,
    amphoe_code: 8302,
    province_code: 83,
  },
  {
    tambon: "ป่าตอง",
    amphoe: "กะทู้",
    province: "ภูเก็ต",
    zipcode: 83150,
    district_code: 830202,
    amphoe_code: 8302,
    province_code: 83,
  },
  {
    tambon: "ป่าคลอก",
    amphoe: "ถลาง",
    province: "ภูเก็ต",
    zipcode: 83110,
    district_code: 830304,
    amphoe_code: 8303,
    province_code: 83,
  },
  {
    tambon: "ศรีสุนทร",
    amphoe: "ถลาง",
    province: "ภูเก็ต",
    zipcode: 83110,
    district_code: 830302,
    amphoe_code: 8303,
    province_code: 83,
  },
  {
    tambon: "สาคู",
    amphoe: "ถลาง",
    province: "ภูเก็ต",
    zipcode: 83110,
    district_code: 830306,
    amphoe_code: 8303,
    province_code: 83,
  },
  {
    tambon: "เชิงทะเล",
    amphoe: "ถลาง",
    province: "ภูเก็ต",
    zipcode: 83110,
    district_code: 830303,
    amphoe_code: 8303,
    province_code: 83,
  },
  {
    tambon: "เทพกระษัตรี",
    amphoe: "ถลาง",
    province: "ภูเก็ต",
    zipcode: 83110,
    district_code: 830301,
    amphoe_code: 8303,
    province_code: 83,
  },
  {
    tambon: "ไม้ขาว",
    amphoe: "ถลาง",
    province: "ภูเก็ต",
    zipcode: 83110,
    district_code: 830305,
    amphoe_code: 8303,
    province_code: 83,
  },
  {
    tambon: "กะรน",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83100,
    district_code: 830108,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "ฉลอง",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83000,
    district_code: 830106,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "ฉลอง",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83130,
    district_code: 830106,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "ตลาดเหนือ",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83000,
    district_code: 830102,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "ตลาดใหญ่",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83000,
    district_code: 830101,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "รัษฎา",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83000,
    district_code: 830104,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "ราไวย์",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83100,
    district_code: 830107,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "ราไวย์",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83130,
    district_code: 830107,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "วิชิต",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83000,
    district_code: 830105,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "เกาะแก้ว",
    amphoe: "เมืองภูเก็ต",
    province: "ภูเก็ต",
    zipcode: 83000,
    district_code: 830103,
    amphoe_code: 8301,
    province_code: 83,
  },
  {
    tambon: "กุดใส้จ่อ",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440409,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "ขามเฒ่าพัฒนา",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440410,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "ขามเรียง",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440406,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "คันธารราษฎร์",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440402,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "ท่าขอนยาง",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440404,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "นาสีนวน",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440405,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "มะค่า",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440403,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "ศรีสุข",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440408,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "เขวาใหญ่",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440407,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "โคกพระ",
    amphoe: "กันทรวิชัย",
    province: "มหาสารคาม",
    zipcode: 44150,
    district_code: 440401,
    amphoe_code: 4404,
    province_code: 44,
  },
  {
    tambon: "กุดรัง",
    amphoe: "กุดรัง",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440603,
    amphoe_code: 4412,
    province_code: 44,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "กุดรัง",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440612,
    amphoe_code: 4412,
    province_code: 44,
  },
  {
    tambon: "หนองแวง",
    amphoe: "กุดรัง",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440614,
    amphoe_code: 4412,
    province_code: 44,
  },
  {
    tambon: "ห้วยเตย",
    amphoe: "กุดรัง",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440617,
    amphoe_code: 4412,
    province_code: 44,
  },
  {
    tambon: "เลิงแฝก",
    amphoe: "กุดรัง",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440609,
    amphoe_code: 4412,
    province_code: 44,
  },
  {
    tambon: "กุดปลาดุก",
    amphoe: "ชื่นชม",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440509,
    amphoe_code: 4413,
    province_code: 44,
  },
  {
    tambon: "ชื่นชม",
    amphoe: "ชื่นชม",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440502,
    amphoe_code: 4413,
    province_code: 44,
  },
  {
    tambon: "หนองกุง",
    amphoe: "ชื่นชม",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440204,
    amphoe_code: 4413,
    province_code: 44,
  },
  {
    tambon: "เหล่าดอกไม้",
    amphoe: "ชื่นชม",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440504,
    amphoe_code: 4413,
    province_code: 44,
  },
  {
    tambon: "กู่สันตรัตน์",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 441008,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "ดงดวน",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 441005,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "ดงบัง",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 441004,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "ดงยาง",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 441007,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "นาดูน",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 440998,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "พระธาตุ",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 441009,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "หนองคู",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 440999,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 440997,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "หัวดง",
    amphoe: "นาดูน",
    province: "มหาสารคาม",
    zipcode: 44180,
    district_code: 441006,
    amphoe_code: 4410,
    province_code: 44,
  },
  {
    tambon: "นาเชือก",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440701,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "ปอพาน",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440706,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "สันป่าตอง",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440710,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "สำโรง",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440702,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "หนองกุง",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440204,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "หนองเม็ก",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440707,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "หนองเรือ",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440708,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "หนองแดง",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440703,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "หนองโพธิ์",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440705,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "เขวาไร่",
    amphoe: "นาเชือก",
    province: "มหาสารคาม",
    zipcode: 44170,
    district_code: 440304,
    amphoe_code: 4407,
    province_code: 44,
  },
  {
    tambon: "กำพี้",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440606,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "ดอนงัว",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440620,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "บรบือ",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440601,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "บัวมาศ",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440611,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "บ่อใหญ่",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440602,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "ยาง",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440616,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "วังใหม่",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440615,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "วังไชย",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440604,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "หนองคูขาด",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440613,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "หนองจิก",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440610,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "หนองม่วง",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440605,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "หนองสิม",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440618,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "หนองโก",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440619,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "โนนราษี",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440607,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "โนนแดง",
    amphoe: "บรบือ",
    province: "มหาสารคาม",
    zipcode: 44130,
    district_code: 440608,
    amphoe_code: 4406,
    province_code: 44,
  },
  {
    tambon: "ก้ามปู",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440802,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "นาสีนวล",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440805,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "ปะหลาน",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440801,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "ภารแอ่น",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440820,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "ราษฎร์พัฒนา",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440818,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "ราษฎร์เจริญ",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440809,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "ลานสะแก",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440815,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "หนองบัว",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440308,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "หนองบัวแก้ว",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440810,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "เมืองเตา",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440812,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "เมืองเสือ",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440819,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "เม็กดำ",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440804,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "เวียงชัย",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440816,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "เวียงสะอาด",
    amphoe: "พยัคฆภูมิพิสัย",
    province: "มหาสารคาม",
    zipcode: 44110,
    district_code: 440803,
    amphoe_code: 4408,
    province_code: 44,
  },
  {
    tambon: "ขามเรียน",
    amphoe: "ยางสีสุราช",
    province: "มหาสารคาม",
    zipcode: 44210,
    district_code: 440808,
    amphoe_code: 4411,
    province_code: 44,
  },
  {
    tambon: "ดงเมือง",
    amphoe: "ยางสีสุราช",
    province: "มหาสารคาม",
    zipcode: 44210,
    district_code: 440806,
    amphoe_code: 4411,
    province_code: 44,
  },
  {
    tambon: "นาภู",
    amphoe: "ยางสีสุราช",
    province: "มหาสารคาม",
    zipcode: 44210,
    district_code: 440811,
    amphoe_code: 4411,
    province_code: 44,
  },
  {
    tambon: "บ้านกู่",
    amphoe: "ยางสีสุราช",
    province: "มหาสารคาม",
    zipcode: 44210,
    district_code: 440813,
    amphoe_code: 4411,
    province_code: 44,
  },
  {
    tambon: "ยางสีสุราช",
    amphoe: "ยางสีสุราช",
    province: "มหาสารคาม",
    zipcode: 44210,
    district_code: 440814,
    amphoe_code: 4411,
    province_code: 44,
  },
  {
    tambon: "หนองบัวสันตุ",
    amphoe: "ยางสีสุราช",
    province: "มหาสารคาม",
    zipcode: 44210,
    district_code: 441107,
    amphoe_code: 4411,
    province_code: 44,
  },
  {
    tambon: "แวงดง",
    amphoe: "ยางสีสุราช",
    province: "มหาสารคาม",
    zipcode: 44210,
    district_code: 440807,
    amphoe_code: 4411,
    province_code: 44,
  },
  {
    tambon: "ขามป้อม",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440902,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "งัวบา",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440908,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "ดงใหญ่",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440904,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "นาข่า",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440909,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "บ้านหวาย",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440910,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "ประชาพัฒนา",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440912,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "หนองทุ่ม",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440913,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "หนองแสง",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440901,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "หนองแสน",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440914,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "หนองไฮ",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440911,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "หัวเรือ",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440906,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "เสือโก้ก",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440903,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "แคน",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440907,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "โคกสีทองหลาง",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440915,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "โพธิ์ชัย",
    amphoe: "วาปีปทุม",
    province: "มหาสารคาม",
    zipcode: 44120,
    district_code: 440905,
    amphoe_code: 4409,
    province_code: 44,
  },
  {
    tambon: "กู่ทอง",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440506,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "ดอนเงิน",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440505,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "นาทอง",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440507,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "หนองซอน",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440503,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "เชียงยืน",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440501,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "เสือเฒ่า",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440508,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "เหล่าบัวบาน",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440512,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "โพนทอง",
    amphoe: "เชียงยืน",
    province: "มหาสารคาม",
    zipcode: 44160,
    district_code: 440511,
    amphoe_code: 4405,
    province_code: 44,
  },
  {
    tambon: "ดอนหว่าน",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440106,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "ตลาด",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440101,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "ท่าตูม",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440103,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "ท่าสองคอน",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440109,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "บัวค้อ",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440114,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "ลาดพัฒนา",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440110,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "หนองปลิง",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440111,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "หนองโน",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440113,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "ห้วยแอ่ง",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440112,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "เกิ้ง",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440107,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "เขวา",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440102,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "แก่งเลิงจาน",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440108,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "แวงน่าง",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440104,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "โคกก่อ",
    amphoe: "เมืองมหาสารคาม",
    province: "มหาสารคาม",
    zipcode: 44000,
    district_code: 440105,
    amphoe_code: 4401,
    province_code: 44,
  },
  {
    tambon: "มิตรภาพ",
    amphoe: "แกดำ",
    province: "มหาสารคาม",
    zipcode: 44190,
    district_code: 440203,
    amphoe_code: 4402,
    province_code: 44,
  },
  {
    tambon: "วังแสง",
    amphoe: "แกดำ",
    province: "มหาสารคาม",
    zipcode: 44190,
    district_code: 440202,
    amphoe_code: 4402,
    province_code: 44,
  },
  {
    tambon: "หนองกุง",
    amphoe: "แกดำ",
    province: "มหาสารคาม",
    zipcode: 44190,
    district_code: 440204,
    amphoe_code: 4402,
    province_code: 44,
  },
  {
    tambon: "แกดำ",
    amphoe: "แกดำ",
    province: "มหาสารคาม",
    zipcode: 44190,
    district_code: 440201,
    amphoe_code: 4402,
    province_code: 44,
  },
  {
    tambon: "โนนภิบาล",
    amphoe: "แกดำ",
    province: "มหาสารคาม",
    zipcode: 44190,
    district_code: 440205,
    amphoe_code: 4402,
    province_code: 44,
  },
  {
    tambon: "ดอนกลาง",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440317,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "ยางท่าแจ้ง",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440313,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "ยางน้อย",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440302,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "วังยาว",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440303,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "หนองกุงสวรรค์",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440315,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "หนองบอน",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440311,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "หนองบัว",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440308,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "หนองเหล็ก",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440307,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "หัวขวาง",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440301,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "เขวาไร่",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440304,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "เขื่อน",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440310,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "เลิงใต้",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440316,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "เหล่า",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440309,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "แก้งแก",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440306,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "แพง",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440305,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "แห่ใต้",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440314,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "โพนงาม",
    amphoe: "โกสุมพิสัย",
    province: "มหาสารคาม",
    zipcode: 44140,
    district_code: 440312,
    amphoe_code: 4403,
    province_code: 44,
  },
  {
    tambon: "คำชะอี",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490504,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "คำบก",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490512,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "น้ำเที่ยง",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490514,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "บ้านค้อ",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490506,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "บ้านซ่ง",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490503,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "บ้านเหล่า",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490507,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "หนองเอี่ยน",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490505,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "เหล่าสร้างถ่อ",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490511,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "โพนงาม",
    amphoe: "คำชะอี",
    province: "มุกดาหาร",
    zipcode: 49110,
    district_code: 490508,
    amphoe_code: 4905,
    province_code: 49,
  },
  {
    tambon: "กกตูม",
    amphoe: "ดงหลวง",
    province: "มุกดาหาร",
    zipcode: 49140,
    district_code: 490403,
    amphoe_code: 4904,
    province_code: 49,
  },
  {
    tambon: "ชะโนดน้อย",
    amphoe: "ดงหลวง",
    province: "มุกดาหาร",
    zipcode: 49140,
    district_code: 490405,
    amphoe_code: 4904,
    province_code: 49,
  },
  {
    tambon: "ดงหลวง",
    amphoe: "ดงหลวง",
    province: "มุกดาหาร",
    zipcode: 49140,
    district_code: 490401,
    amphoe_code: 4904,
    province_code: 49,
  },
  {
    tambon: "พังแดง",
    amphoe: "ดงหลวง",
    province: "มุกดาหาร",
    zipcode: 49140,
    district_code: 490406,
    amphoe_code: 4904,
    province_code: 49,
  },
  {
    tambon: "หนองบัว",
    amphoe: "ดงหลวง",
    province: "มุกดาหาร",
    zipcode: 49140,
    district_code: 490402,
    amphoe_code: 4904,
    province_code: 49,
  },
  {
    tambon: "หนองแคน",
    amphoe: "ดงหลวง",
    province: "มุกดาหาร",
    zipcode: 49140,
    district_code: 490404,
    amphoe_code: 4904,
    province_code: 49,
  },
  {
    tambon: "ดอนตาล",
    amphoe: "ดอนตาล",
    province: "มุกดาหาร",
    zipcode: 49120,
    district_code: 490301,
    amphoe_code: 4903,
    province_code: 49,
  },
  {
    tambon: "นาสะเม็ง",
    amphoe: "ดอนตาล",
    province: "มุกดาหาร",
    zipcode: 49120,
    district_code: 490306,
    amphoe_code: 4903,
    province_code: 49,
  },
  {
    tambon: "บ้านบาก",
    amphoe: "ดอนตาล",
    province: "มุกดาหาร",
    zipcode: 49120,
    district_code: 490305,
    amphoe_code: 4903,
    province_code: 49,
  },
  {
    tambon: "บ้านแก้ง",
    amphoe: "ดอนตาล",
    province: "มุกดาหาร",
    zipcode: 49120,
    district_code: 490307,
    amphoe_code: 4903,
    province_code: 49,
  },
  {
    tambon: "ป่าไร่",
    amphoe: "ดอนตาล",
    province: "มุกดาหาร",
    zipcode: 49120,
    district_code: 490303,
    amphoe_code: 4903,
    province_code: 49,
  },
  {
    tambon: "เหล่าหมี",
    amphoe: "ดอนตาล",
    province: "มุกดาหาร",
    zipcode: 49120,
    district_code: 490304,
    amphoe_code: 4903,
    province_code: 49,
  },
  {
    tambon: "โพธิ์ไทร",
    amphoe: "ดอนตาล",
    province: "มุกดาหาร",
    zipcode: 49120,
    district_code: 490302,
    amphoe_code: 4903,
    province_code: 49,
  },
  {
    tambon: "กกแดง",
    amphoe: "นิคมคำสร้อย",
    province: "มุกดาหาร",
    zipcode: 49130,
    district_code: 490195,
    amphoe_code: 4902,
    province_code: 49,
  },
  {
    tambon: "นากอก",
    amphoe: "นิคมคำสร้อย",
    province: "มุกดาหาร",
    zipcode: 49130,
    district_code: 490196,
    amphoe_code: 4902,
    province_code: 49,
  },
  {
    tambon: "นาอุดม",
    amphoe: "นิคมคำสร้อย",
    province: "มุกดาหาร",
    zipcode: 49130,
    district_code: 490205,
    amphoe_code: 4902,
    province_code: 49,
  },
  {
    tambon: "นิคมคำสร้อย",
    amphoe: "นิคมคำสร้อย",
    province: "มุกดาหาร",
    zipcode: 49130,
    district_code: 490201,
    amphoe_code: 4902,
    province_code: 49,
  },
  {
    tambon: "ร่มเกล้า",
    amphoe: "นิคมคำสร้อย",
    province: "มุกดาหาร",
    zipcode: 49130,
    district_code: 490207,
    amphoe_code: 4902,
    province_code: 49,
  },
  {
    tambon: "หนองแวง",
    amphoe: "นิคมคำสร้อย",
    province: "มุกดาหาร",
    zipcode: 49130,
    district_code: 490194,
    amphoe_code: 4902,
    province_code: 49,
  },
  {
    tambon: "โชคชัย",
    amphoe: "นิคมคำสร้อย",
    province: "มุกดาหาร",
    zipcode: 49130,
    district_code: 490206,
    amphoe_code: 4902,
    province_code: 49,
  },
  {
    tambon: "บ้านเป้า",
    amphoe: "หนองสูง",
    province: "มุกดาหาร",
    zipcode: 49160,
    district_code: 490510,
    amphoe_code: 4907,
    province_code: 49,
  },
  {
    tambon: "ภูวง",
    amphoe: "หนองสูง",
    province: "มุกดาหาร",
    zipcode: 49160,
    district_code: 490513,
    amphoe_code: 4907,
    province_code: 49,
  },
  {
    tambon: "หนองสูง",
    amphoe: "หนองสูง",
    province: "มุกดาหาร",
    zipcode: 49160,
    district_code: 490502,
    amphoe_code: 4907,
    province_code: 49,
  },
  {
    tambon: "หนองสูงเหนือ",
    amphoe: "หนองสูง",
    province: "มุกดาหาร",
    zipcode: 49160,
    district_code: 490706,
    amphoe_code: 4907,
    province_code: 49,
  },
  {
    tambon: "หนองสูงใต้",
    amphoe: "หนองสูง",
    province: "มุกดาหาร",
    zipcode: 49160,
    district_code: 490501,
    amphoe_code: 4907,
    province_code: 49,
  },
  {
    tambon: "โนนยาง",
    amphoe: "หนองสูง",
    province: "มุกดาหาร",
    zipcode: 49160,
    district_code: 490509,
    amphoe_code: 4907,
    province_code: 49,
  },
  {
    tambon: "ชะโนด",
    amphoe: "หว้านใหญ่",
    province: "มุกดาหาร",
    zipcode: 49150,
    district_code: 490604,
    amphoe_code: 4906,
    province_code: 49,
  },
  {
    tambon: "ดงหมู",
    amphoe: "หว้านใหญ่",
    province: "มุกดาหาร",
    zipcode: 49150,
    district_code: 490605,
    amphoe_code: 4906,
    province_code: 49,
  },
  {
    tambon: "บางทรายน้อย",
    amphoe: "หว้านใหญ่",
    province: "มุกดาหาร",
    zipcode: 49150,
    district_code: 490198,
    amphoe_code: 4906,
    province_code: 49,
  },
  {
    tambon: "ป่งขาม",
    amphoe: "หว้านใหญ่",
    province: "มุกดาหาร",
    zipcode: 49150,
    district_code: 490602,
    amphoe_code: 4906,
    province_code: 49,
  },
  {
    tambon: "หว้านใหญ่",
    amphoe: "หว้านใหญ่",
    province: "มุกดาหาร",
    zipcode: 49150,
    district_code: 490199,
    amphoe_code: 4906,
    province_code: 49,
  },
  {
    tambon: "กุดแข้",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490113,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "คำป่าหลาย",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490109,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "คำอาฮวน",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490110,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "ดงมอน",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490112,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "ดงเย็น",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490111,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "นาสีนวน",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490108,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "นาโสก",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490107,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "บางทรายใหญ่",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490104,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "บ้านโคก",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490103,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "ผึ่งแดด",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490106,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "มุกดาหาร",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490101,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "ศรีบุญเรือง",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490102,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "โพนทราย",
    amphoe: "เมืองมุกดาหาร",
    province: "มุกดาหาร",
    zipcode: 49000,
    district_code: 490105,
    amphoe_code: 4901,
    province_code: 49,
  },
  {
    tambon: "กรงปินัง",
    amphoe: "กรงปินัง",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950113,
    amphoe_code: 9508,
    province_code: 95,
  },
  {
    tambon: "ปุโรง",
    amphoe: "กรงปินัง",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950105,
    amphoe_code: 9508,
    province_code: 95,
  },
  {
    tambon: "สะเอะ",
    amphoe: "กรงปินัง",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950107,
    amphoe_code: 9508,
    province_code: 95,
  },
  {
    tambon: "ห้วยกระทิง",
    amphoe: "กรงปินัง",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950117,
    amphoe_code: 9508,
    province_code: 95,
  },
  {
    tambon: "กาบัง",
    amphoe: "กาบัง",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950505,
    amphoe_code: 9507,
    province_code: 95,
  },
  {
    tambon: "บาละ",
    amphoe: "กาบัง",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950509,
    amphoe_code: 9507,
    province_code: 95,
  },
  {
    tambon: "คีรีเขต",
    amphoe: "ธารโต",
    province: "ยะลา",
    zipcode: 95150,
    district_code: 950404,
    amphoe_code: 9504,
    province_code: 95,
  },
  {
    tambon: "ธารโต",
    amphoe: "ธารโต",
    province: "ยะลา",
    zipcode: 95150,
    district_code: 950399,
    amphoe_code: 9504,
    province_code: 95,
  },
  {
    tambon: "บ้านแหร",
    amphoe: "ธารโต",
    province: "ยะลา",
    zipcode: 95150,
    district_code: 950398,
    amphoe_code: 9504,
    province_code: 95,
  },
  {
    tambon: "แม่หวาด",
    amphoe: "ธารโต",
    province: "ยะลา",
    zipcode: 95170,
    district_code: 950397,
    amphoe_code: 9504,
    province_code: 95,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "บันนังสตา",
    province: "ยะลา",
    zipcode: 95130,
    district_code: 950305,
    amphoe_code: 9503,
    province_code: 95,
  },
  {
    tambon: "ตาเนาะปูเต๊ะ",
    amphoe: "บันนังสตา",
    province: "ยะลา",
    zipcode: 95130,
    district_code: 950303,
    amphoe_code: 9503,
    province_code: 95,
  },
  {
    tambon: "ถ้ำทะลุ",
    amphoe: "บันนังสตา",
    province: "ยะลา",
    zipcode: 95130,
    district_code: 950304,
    amphoe_code: 9503,
    province_code: 95,
  },
  {
    tambon: "บันนังสตา",
    amphoe: "บันนังสตา",
    province: "ยะลา",
    zipcode: 95130,
    district_code: 950301,
    amphoe_code: 9503,
    province_code: 95,
  },
  {
    tambon: "บาเจาะ",
    amphoe: "บันนังสตา",
    province: "ยะลา",
    zipcode: 95130,
    district_code: 950302,
    amphoe_code: 9503,
    province_code: 95,
  },
  {
    tambon: "เขื่อนบางลาง",
    amphoe: "บันนังสตา",
    province: "ยะลา",
    zipcode: 95130,
    district_code: 950306,
    amphoe_code: 9503,
    province_code: 95,
  },
  {
    tambon: "กาตอง",
    amphoe: "ยะหา",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950508,
    amphoe_code: 9505,
    province_code: 95,
  },
  {
    tambon: "ตาชี",
    amphoe: "ยะหา",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950506,
    amphoe_code: 9505,
    province_code: 95,
  },
  {
    tambon: "บาโงยซิแน",
    amphoe: "ยะหา",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950507,
    amphoe_code: 9505,
    province_code: 95,
  },
  {
    tambon: "บาโร๊ะ",
    amphoe: "ยะหา",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950504,
    amphoe_code: 9505,
    province_code: 95,
  },
  {
    tambon: "ปะแต",
    amphoe: "ยะหา",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950503,
    amphoe_code: 9505,
    province_code: 95,
  },
  {
    tambon: "ยะหา",
    amphoe: "ยะหา",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950501,
    amphoe_code: 9505,
    province_code: 95,
  },
  {
    tambon: "ละแอ",
    amphoe: "ยะหา",
    province: "ยะลา",
    zipcode: 95120,
    district_code: 950502,
    amphoe_code: 9505,
    province_code: 95,
  },
  {
    tambon: "กอตอตือร๊ะ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950604,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "กายูบอเกาะ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950601,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "กาลอ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950603,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "กาลูปัง",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950602,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "จะกว๊ะ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950607,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "ตะโล๊ะหะลอ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950616,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "ท่าธง",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950608,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "บาลอ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950610,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "บาโงย",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950611,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "บือมัง",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950612,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "ยะต๊ะ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950613,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "วังพญา",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950614,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "อาซ่อง",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950615,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "เกะรอ",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950606,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "เนินงาม",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950609,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "โกตาบารู",
    amphoe: "รามัน",
    province: "ยะลา",
    zipcode: 95140,
    district_code: 950605,
    amphoe_code: 9506,
    province_code: 95,
  },
  {
    tambon: "ตาเนาะแมเราะ",
    amphoe: "เบตง",
    province: "ยะลา",
    zipcode: 95110,
    district_code: 950203,
    amphoe_code: 9502,
    province_code: 95,
  },
  {
    tambon: "ธารน้ำทิพย์",
    amphoe: "เบตง",
    province: "ยะลา",
    zipcode: 95110,
    district_code: 950205,
    amphoe_code: 9502,
    province_code: 95,
  },
  {
    tambon: "ยะรม",
    amphoe: "เบตง",
    province: "ยะลา",
    zipcode: 95110,
    district_code: 950202,
    amphoe_code: 9502,
    province_code: 95,
  },
  {
    tambon: "อัยเยอร์เวง",
    amphoe: "เบตง",
    province: "ยะลา",
    zipcode: 95110,
    district_code: 950204,
    amphoe_code: 9502,
    province_code: 95,
  },
  {
    tambon: "เบตง",
    amphoe: "เบตง",
    province: "ยะลา",
    zipcode: 95110,
    district_code: 950201,
    amphoe_code: 9502,
    province_code: 95,
  },
  {
    tambon: "ตาเซะ",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950118,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "ท่าสาป",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950108,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "บันนังสาเรง",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950115,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "บุดี",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950102,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "พร่อน",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95160,
    district_code: 950114,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "ยะลา",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950106,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "ยุโป",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950103,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "ลำพะยา",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95160,
    district_code: 950111,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "ลำใหม่",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95160,
    district_code: 950109,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "ลิดล",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95160,
    district_code: 950104,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "สะเตง",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950101,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "สะเตงนอก",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950116,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "หน้าถ้ำ",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950110,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "เปาะเส้ง",
    amphoe: "เมืองยะลา",
    province: "ยะลา",
    zipcode: 95000,
    district_code: 950112,
    amphoe_code: 9501,
    province_code: 95,
  },
  {
    tambon: "กำแมด",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350303,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "กุดชุม",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350301,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "คำน้ำสร้าง",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350308,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "นาโส่",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350304,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "หนองหมี",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350306,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "หนองแหน",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350309,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "ห้วยแก้ง",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350305,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "โนนเปือย",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350302,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "โพนงาม",
    amphoe: "กุดชุม",
    province: "ยโสธร",
    zipcode: 35140,
    district_code: 350307,
    amphoe_code: 3503,
    province_code: 35,
  },
  {
    tambon: "กุดกุง",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350410,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "กู่จาน",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350408,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "ดงเจริญ",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350413,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "ดงแคนใหญ่",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35180,
    district_code: 350407,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "ทุ่งมน",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350405,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "นาคำ",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35180,
    district_code: 350406,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "นาแก",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35180,
    district_code: 350409,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "ย่อ",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350402,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "ลุมพุก",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350401,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "สงเปือย",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350403,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "เหล่าไฮ",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350411,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "แคนน้อย",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35180,
    district_code: 350412,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "โพนทัน",
    amphoe: "คำเขื่อนแก้ว",
    province: "ยโสธร",
    zipcode: 35110,
    district_code: 350404,
    amphoe_code: 3504,
    province_code: 35,
  },
  {
    tambon: "กุดน้ำใส",
    amphoe: "ค้อวัง",
    province: "ยโสธร",
    zipcode: 35160,
    district_code: 350698,
    amphoe_code: 3507,
    province_code: 35,
  },
  {
    tambon: "ค้อวัง",
    amphoe: "ค้อวัง",
    province: "ยโสธร",
    zipcode: 35160,
    district_code: 350696,
    amphoe_code: 3507,
    province_code: 35,
  },
  {
    tambon: "น้ำอ้อม",
    amphoe: "ค้อวัง",
    province: "ยโสธร",
    zipcode: 35160,
    district_code: 350697,
    amphoe_code: 3507,
    province_code: 35,
  },
  {
    tambon: "ฟ้าห่วน",
    amphoe: "ค้อวัง",
    province: "ยโสธร",
    zipcode: 35160,
    district_code: 350699,
    amphoe_code: 3507,
    province_code: 35,
  },
  {
    tambon: "ดงมะไฟ",
    amphoe: "ทรายมูล",
    province: "ยโสธร",
    zipcode: 35170,
    district_code: 350197,
    amphoe_code: 3502,
    province_code: 35,
  },
  {
    tambon: "ดู่ลาด",
    amphoe: "ทรายมูล",
    province: "ยโสธร",
    zipcode: 35170,
    district_code: 350202,
    amphoe_code: 3502,
    province_code: 35,
  },
  {
    tambon: "ทรายมูล",
    amphoe: "ทรายมูล",
    province: "ยโสธร",
    zipcode: 35170,
    district_code: 350199,
    amphoe_code: 3502,
    province_code: 35,
  },
  {
    tambon: "นาเวียง",
    amphoe: "ทรายมูล",
    province: "ยโสธร",
    zipcode: 35170,
    district_code: 350196,
    amphoe_code: 3502,
    province_code: 35,
  },
  {
    tambon: "ไผ่",
    amphoe: "ทรายมูล",
    province: "ยโสธร",
    zipcode: 35170,
    district_code: 350205,
    amphoe_code: 3502,
    province_code: 35,
  },
  {
    tambon: "กระจาย",
    amphoe: "ป่าติ้ว",
    province: "ยโสธร",
    zipcode: 35150,
    district_code: 350502,
    amphoe_code: 3505,
    province_code: 35,
  },
  {
    tambon: "ศรีฐาน",
    amphoe: "ป่าติ้ว",
    province: "ยโสธร",
    zipcode: 35150,
    district_code: 350505,
    amphoe_code: 3505,
    province_code: 35,
  },
  {
    tambon: "เชียงเพ็ง",
    amphoe: "ป่าติ้ว",
    province: "ยโสธร",
    zipcode: 35150,
    district_code: 350504,
    amphoe_code: 3505,
    province_code: 35,
  },
  {
    tambon: "โคกนาโก",
    amphoe: "ป่าติ้ว",
    province: "ยโสธร",
    zipcode: 35150,
    district_code: 350503,
    amphoe_code: 3505,
    province_code: 35,
  },
  {
    tambon: "โพธิ์ไทร",
    amphoe: "ป่าติ้ว",
    province: "ยโสธร",
    zipcode: 35150,
    district_code: 350501,
    amphoe_code: 3505,
    province_code: 35,
  },
  {
    tambon: "คูเมือง",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350603,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "บากเรือ",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350605,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "บึงแก",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350608,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "ผือฮี",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350604,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "พระเสาร์",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350609,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "ฟ้าหยาด",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350601,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "ม่วง",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350606,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "สงยาง",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350610,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "หัวเมือง",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350602,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "โนนทราย",
    amphoe: "มหาชนะชัย",
    province: "ยโสธร",
    zipcode: 35130,
    district_code: 350607,
    amphoe_code: 3506,
    province_code: 35,
  },
  {
    tambon: "ขั้นไดใหญ่",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350108,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "ขุมเงิน",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350115,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "ค้อเหนือ",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350105,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "ดู่ทุ่ง",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350106,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "ตาดทอง",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350103,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "ทุ่งนางโอก",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350116,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "ทุ่งแต้",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350109,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "นาสะไมย์",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350111,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "น้ำคำใหญ่",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350102,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "สำราญ",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350104,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "สิงห์",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350110,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "หนองคู",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350114,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "หนองหิน",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350113,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "หนองเป็ด",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350118,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "หนองเรือ",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350117,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "เขื่องคำ",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350112,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "เดิด",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350107,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองยโสธร",
    province: "ยโสธร",
    zipcode: 35000,
    district_code: 350101,
    amphoe_code: 3501,
    province_code: 35,
  },
  {
    tambon: "กุดเชียงหมี",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350807,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "กุดแห่",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350811,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "บุ่งค้า",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350802,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "ศรีแก้ว",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350814,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "สร้างมิ่ง",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350813,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "สวาท",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350803,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "สามัคคี",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350806,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "สามแยก",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350810,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "ห้องแซง",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350805,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "โคกสำราญ",
    amphoe: "เลิงนกทา",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350812,
    amphoe_code: 3508,
    province_code: 35,
  },
  {
    tambon: "คำเตย",
    amphoe: "ไทยเจริญ",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350808,
    amphoe_code: 3509,
    province_code: 35,
  },
  {
    tambon: "คำไผ่",
    amphoe: "ไทยเจริญ",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350809,
    amphoe_code: 3509,
    province_code: 35,
  },
  {
    tambon: "น้ำคำ",
    amphoe: "ไทยเจริญ",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350801,
    amphoe_code: 3509,
    province_code: 35,
  },
  {
    tambon: "ส้มผ่อ",
    amphoe: "ไทยเจริญ",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350804,
    amphoe_code: 3509,
    province_code: 35,
  },
  {
    tambon: "ไทยเจริญ",
    amphoe: "ไทยเจริญ",
    province: "ยโสธร",
    zipcode: 35120,
    district_code: 350815,
    amphoe_code: 3509,
    province_code: 35,
  },
  {
    tambon: "จ.ป.ร.",
    amphoe: "กระบุรี",
    province: "ระนอง",
    zipcode: 85110,
    district_code: 850406,
    amphoe_code: 8504,
    province_code: 85,
  },
  {
    tambon: "น้ำจืด",
    amphoe: "กระบุรี",
    province: "ระนอง",
    zipcode: 85110,
    district_code: 850401,
    amphoe_code: 8504,
    province_code: 85,
  },
  {
    tambon: "น้ำจืดน้อย",
    amphoe: "กระบุรี",
    province: "ระนอง",
    zipcode: 85110,
    district_code: 850402,
    amphoe_code: 8504,
    province_code: 85,
  },
  {
    tambon: "บางใหญ่",
    amphoe: "กระบุรี",
    province: "ระนอง",
    zipcode: 85110,
    district_code: 850407,
    amphoe_code: 8504,
    province_code: 85,
  },
  {
    tambon: "ปากจั่น",
    amphoe: "กระบุรี",
    province: "ระนอง",
    zipcode: 85110,
    district_code: 850404,
    amphoe_code: 8504,
    province_code: 85,
  },
  {
    tambon: "มะมุ",
    amphoe: "กระบุรี",
    province: "ระนอง",
    zipcode: 85110,
    district_code: 850403,
    amphoe_code: 8504,
    province_code: 85,
  },
  {
    tambon: "ลำเลียง",
    amphoe: "กระบุรี",
    province: "ระนอง",
    zipcode: 85110,
    district_code: 850405,
    amphoe_code: 8504,
    province_code: 85,
  },
  {
    tambon: "กะเปอร์",
    amphoe: "กะเปอร์",
    province: "ระนอง",
    zipcode: 85120,
    district_code: 850302,
    amphoe_code: 8503,
    province_code: 85,
  },
  {
    tambon: "บางหิน",
    amphoe: "กะเปอร์",
    province: "ระนอง",
    zipcode: 85120,
    district_code: 850305,
    amphoe_code: 8503,
    province_code: 85,
  },
  {
    tambon: "บ้านนา",
    amphoe: "กะเปอร์",
    province: "ระนอง",
    zipcode: 85120,
    district_code: 850304,
    amphoe_code: 8503,
    province_code: 85,
  },
  {
    tambon: "ม่วงกลวง",
    amphoe: "กะเปอร์",
    province: "ระนอง",
    zipcode: 85120,
    district_code: 850301,
    amphoe_code: 8503,
    province_code: 85,
  },
  {
    tambon: "เชี่ยวเหลียง",
    amphoe: "กะเปอร์",
    province: "ระนอง",
    zipcode: 85120,
    district_code: 850303,
    amphoe_code: 8503,
    province_code: 85,
  },
  {
    tambon: "บางพระเหนือ",
    amphoe: "ละอุ่น",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850204,
    amphoe_code: 8502,
    province_code: 85,
  },
  {
    tambon: "บางพระใต้",
    amphoe: "ละอุ่น",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850203,
    amphoe_code: 8502,
    province_code: 85,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "ละอุ่น",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850205,
    amphoe_code: 8502,
    province_code: 85,
  },
  {
    tambon: "ละอุ่นเหนือ",
    amphoe: "ละอุ่น",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850202,
    amphoe_code: 8502,
    province_code: 85,
  },
  {
    tambon: "ละอุ่นใต้",
    amphoe: "ละอุ่น",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850201,
    amphoe_code: 8502,
    province_code: 85,
  },
  {
    tambon: "ในวงเหนือ",
    amphoe: "ละอุ่น",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850206,
    amphoe_code: 8502,
    province_code: 85,
  },
  {
    tambon: "ในวงใต้",
    amphoe: "ละอุ่น",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850207,
    amphoe_code: 8502,
    province_code: 85,
  },
  {
    tambon: "กำพวน",
    amphoe: "สุขสำราญ",
    province: "ระนอง",
    zipcode: 85120,
    district_code: 850307,
    amphoe_code: 8505,
    province_code: 85,
  },
  {
    tambon: "นาคา",
    amphoe: "สุขสำราญ",
    province: "ระนอง",
    zipcode: 85120,
    district_code: 850306,
    amphoe_code: 8505,
    province_code: 85,
  },
  {
    tambon: "ทรายแดง",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85130,
    district_code: 850108,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "บางนอน",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850106,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "บางริ้น",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850104,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850105,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "ราชกรูด",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850102,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "หงาว",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850103,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "หาดส้มแป้น",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850107,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "เกาะพยาม",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850109,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "เขานิเวศน์",
    amphoe: "เมืองระนอง",
    province: "ระนอง",
    zipcode: 85000,
    district_code: 850101,
    amphoe_code: 8501,
    province_code: 85,
  },
  {
    tambon: "นิคมพัฒนา",
    amphoe: "นิคมพัฒนา",
    province: "ระยอง",
    zipcode: 21180,
    district_code: 210510,
    amphoe_code: 2108,
    province_code: 21,
  },
  {
    tambon: "พนานิคม",
    amphoe: "นิคมพัฒนา",
    province: "ระยอง",
    zipcode: 21180,
    district_code: 210509,
    amphoe_code: 2108,
    province_code: 21,
  },
  {
    tambon: "มะขามคู่",
    amphoe: "นิคมพัฒนา",
    province: "ระยอง",
    zipcode: 21180,
    district_code: 210511,
    amphoe_code: 2108,
    province_code: 21,
  },
  {
    tambon: "มาบข่า",
    amphoe: "นิคมพัฒนา",
    province: "ระยอง",
    zipcode: 21180,
    district_code: 210508,
    amphoe_code: 2108,
    province_code: 21,
  },
  {
    tambon: "ชากบก",
    amphoe: "บ้านค่าย",
    province: "ระยอง",
    zipcode: 21120,
    district_code: 210507,
    amphoe_code: 2105,
    province_code: 21,
  },
  {
    tambon: "ตาขัน",
    amphoe: "บ้านค่าย",
    province: "ระยอง",
    zipcode: 21120,
    district_code: 210504,
    amphoe_code: 2105,
    province_code: 21,
  },
  {
    tambon: "บางบุตร",
    amphoe: "บ้านค่าย",
    province: "ระยอง",
    zipcode: 21120,
    district_code: 210505,
    amphoe_code: 2105,
    province_code: 21,
  },
  {
    tambon: "บ้านค่าย",
    amphoe: "บ้านค่าย",
    province: "ระยอง",
    zipcode: 21120,
    district_code: 210501,
    amphoe_code: 2105,
    province_code: 21,
  },
  {
    tambon: "หนองตะพาน",
    amphoe: "บ้านค่าย",
    province: "ระยอง",
    zipcode: 21120,
    district_code: 210503,
    amphoe_code: 2105,
    province_code: 21,
  },
  {
    tambon: "หนองบัว",
    amphoe: "บ้านค่าย",
    province: "ระยอง",
    zipcode: 21120,
    district_code: 210506,
    amphoe_code: 2105,
    province_code: 21,
  },
  {
    tambon: "หนองละลอก",
    amphoe: "บ้านค่าย",
    province: "ระยอง",
    zipcode: 21120,
    district_code: 210502,
    amphoe_code: 2105,
    province_code: 21,
  },
  {
    tambon: "บ้านฉาง",
    amphoe: "บ้านฉาง",
    province: "ระยอง",
    zipcode: 21130,
    district_code: 210203,
    amphoe_code: 2102,
    province_code: 21,
  },
  {
    tambon: "พลา",
    amphoe: "บ้านฉาง",
    province: "ระยอง",
    zipcode: 21130,
    district_code: 210199,
    amphoe_code: 2102,
    province_code: 21,
  },
  {
    tambon: "สำนักท้อน",
    amphoe: "บ้านฉาง",
    province: "ระยอง",
    zipcode: 21130,
    district_code: 210198,
    amphoe_code: 2102,
    province_code: 21,
  },
  {
    tambon: "ตาสิทธิ์",
    amphoe: "ปลวกแดง",
    province: "ระยอง",
    zipcode: 21140,
    district_code: 210598,
    amphoe_code: 2106,
    province_code: 21,
  },
  {
    tambon: "ปลวกแดง",
    amphoe: "ปลวกแดง",
    province: "ระยอง",
    zipcode: 21140,
    district_code: 210599,
    amphoe_code: 2106,
    province_code: 21,
  },
  {
    tambon: "มาบยางพร",
    amphoe: "ปลวกแดง",
    province: "ระยอง",
    zipcode: 21140,
    district_code: 210595,
    amphoe_code: 2106,
    province_code: 21,
  },
  {
    tambon: "ละหาร",
    amphoe: "ปลวกแดง",
    province: "ระยอง",
    zipcode: 21140,
    district_code: 210597,
    amphoe_code: 2106,
    province_code: 21,
  },
  {
    tambon: "หนองไร่",
    amphoe: "ปลวกแดง",
    province: "ระยอง",
    zipcode: 21140,
    district_code: 210594,
    amphoe_code: 2106,
    province_code: 21,
  },
  {
    tambon: "แม่น้ำคู้",
    amphoe: "ปลวกแดง",
    province: "ระยอง",
    zipcode: 21140,
    district_code: 210596,
    amphoe_code: 2106,
    province_code: 21,
  },
  {
    tambon: "ชุมแสง",
    amphoe: "วังจันทร์",
    province: "ระยอง",
    zipcode: 21210,
    district_code: 210398,
    amphoe_code: 2104,
    province_code: 21,
  },
  {
    tambon: "ป่ายุบใน",
    amphoe: "วังจันทร์",
    province: "ระยอง",
    zipcode: 21210,
    district_code: 210403,
    amphoe_code: 2104,
    province_code: 21,
  },
  {
    tambon: "พลงตาเอี่ยม",
    amphoe: "วังจันทร์",
    province: "ระยอง",
    zipcode: 21210,
    district_code: 210404,
    amphoe_code: 2104,
    province_code: 21,
  },
  {
    tambon: "วังจันทร์",
    amphoe: "วังจันทร์",
    province: "ระยอง",
    zipcode: 21210,
    district_code: 210399,
    amphoe_code: 2104,
    province_code: 21,
  },
  {
    tambon: "ชำฆ้อ",
    amphoe: "เขาชะเมา",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210315,
    amphoe_code: 2107,
    province_code: 21,
  },
  {
    tambon: "น้ำเป็น",
    amphoe: "เขาชะเมา",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210314,
    amphoe_code: 2107,
    province_code: 21,
  },
  {
    tambon: "ห้วยทับมอญ",
    amphoe: "เขาชะเมา",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210316,
    amphoe_code: 2107,
    province_code: 21,
  },
  {
    tambon: "เขาน้อย",
    amphoe: "เขาชะเมา",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210319,
    amphoe_code: 2107,
    province_code: 21,
  },
  {
    tambon: "กะเฉด",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21100,
    district_code: 210110,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "ตะพง",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210103,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "ทับมา",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210111,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "ท่าประดู่",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210101,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "นาตาขวัญ",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210108,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "น้ำคอก",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210112,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "บ้านแลง",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210107,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210104,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "มาบตาพุด",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21150,
    district_code: 210114,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "สำนักทอง",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21100,
    district_code: 210115,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "ห้วยโป่ง",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21150,
    district_code: 210113,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "เชิงเนิน",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210102,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "เนินพระ",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21000,
    district_code: 210109,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "เนินพระ",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21150,
    district_code: 210109,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "เพ",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21160,
    district_code: 210105,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "แกลง",
    amphoe: "เมืองระยอง",
    province: "ระยอง",
    zipcode: 21160,
    district_code: 210106,
    amphoe_code: 2101,
    province_code: 21,
  },
  {
    tambon: "กระแสบน",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210307,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "กร่ำ",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21190,
    district_code: 210305,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "กองดิน",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 22160,
    district_code: 210310,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "คลองปูน",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21170,
    district_code: 210311,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "ชากพง",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21190,
    district_code: 210306,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "ชากโดน",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210303,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "ทางเกวียน",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210301,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "ทุ่งควายกิน",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210309,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "บ้านนา",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210308,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "ปากน้ำกระแส",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21170,
    district_code: 210313,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "พังราด",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21170,
    district_code: 210312,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "วังหว้า",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210302,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "สองสลึง",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210318,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "ห้วยยาง",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210317,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "เนินฆ้อ",
    amphoe: "แกลง",
    province: "ระยอง",
    zipcode: 21110,
    district_code: 210304,
    amphoe_code: 2103,
    province_code: 21,
  },
  {
    tambon: "จอมบึง",
    amphoe: "จอมบึง",
    province: "ราชบุรี",
    zipcode: 70150,
    district_code: 700201,
    amphoe_code: 7002,
    province_code: 70,
  },
  {
    tambon: "ด่านทับตะโก",
    amphoe: "จอมบึง",
    province: "ราชบุรี",
    zipcode: 70150,
    district_code: 700204,
    amphoe_code: 7002,
    province_code: 70,
  },
  {
    tambon: "ปากช่อง",
    amphoe: "จอมบึง",
    province: "ราชบุรี",
    zipcode: 70150,
    district_code: 700202,
    amphoe_code: 7002,
    province_code: 70,
  },
  {
    tambon: "รางบัว",
    amphoe: "จอมบึง",
    province: "ราชบุรี",
    zipcode: 70150,
    district_code: 700206,
    amphoe_code: 7002,
    province_code: 70,
  },
  {
    tambon: "เบิกไพร",
    amphoe: "จอมบึง",
    province: "ราชบุรี",
    zipcode: 70150,
    district_code: 700203,
    amphoe_code: 7002,
    province_code: 70,
  },
  {
    tambon: "แก้มอ้น",
    amphoe: "จอมบึง",
    province: "ราชบุรี",
    zipcode: 70150,
    district_code: 700205,
    amphoe_code: 7002,
    province_code: 70,
  },
  {
    tambon: "ขุนพิทักษ์",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700412,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ดอนกรวย",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700405,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ดอนคลัง",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700406,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ดอนไผ่",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700413,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ดำเนินสะดวก",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700401,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ตาหลวง",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700404,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ท่านัด",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700411,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "บัวงาม",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70210,
    district_code: 700407,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700122,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ประสาทสิทธิ์",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70210,
    district_code: 700402,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ศรีสุราษฎร์",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700403,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "สี่หมื่น",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700410,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "แพงพวย",
    amphoe: "ดำเนินสะดวก",
    province: "ราชบุรี",
    zipcode: 70130,
    district_code: 700409,
    amphoe_code: 7004,
    province_code: 70,
  },
  {
    tambon: "ดอนคา",
    amphoe: "บางแพ",
    province: "ราชบุรี",
    zipcode: 70160,
    district_code: 700606,
    amphoe_code: 7006,
    province_code: 70,
  },
  {
    tambon: "ดอนใหญ่",
    amphoe: "บางแพ",
    province: "ราชบุรี",
    zipcode: 70160,
    district_code: 700605,
    amphoe_code: 7006,
    province_code: 70,
  },
  {
    tambon: "บางแพ",
    amphoe: "บางแพ",
    province: "ราชบุรี",
    zipcode: 70160,
    district_code: 700601,
    amphoe_code: 7006,
    province_code: 70,
  },
  {
    tambon: "วังเย็น",
    amphoe: "บางแพ",
    province: "ราชบุรี",
    zipcode: 70160,
    district_code: 700602,
    amphoe_code: 7006,
    province_code: 70,
  },
  {
    tambon: "วัดแก้ว",
    amphoe: "บางแพ",
    province: "ราชบุรี",
    zipcode: 70160,
    district_code: 700604,
    amphoe_code: 7006,
    province_code: 70,
  },
  {
    tambon: "หัวโพ",
    amphoe: "บางแพ",
    province: "ราชบุรี",
    zipcode: 70160,
    district_code: 700603,
    amphoe_code: 7006,
    province_code: 70,
  },
  {
    tambon: "โพหัก",
    amphoe: "บางแพ",
    province: "ราชบุรี",
    zipcode: 70160,
    district_code: 700607,
    amphoe_code: 7006,
    province_code: 70,
  },
  {
    tambon: "บ้านคา",
    amphoe: "บ้านคา",
    province: "ราชบุรี",
    zipcode: 70180,
    district_code: 700305,
    amphoe_code: 7010,
    province_code: 70,
  },
  {
    tambon: "บ้านบึง",
    amphoe: "บ้านคา",
    province: "ราชบุรี",
    zipcode: 70180,
    district_code: 700303,
    amphoe_code: 7010,
    province_code: 70,
  },
  {
    tambon: "หนองพันจันทร์",
    amphoe: "บ้านคา",
    province: "ราชบุรี",
    zipcode: 70180,
    district_code: 700306,
    amphoe_code: 7010,
    province_code: 70,
  },
  {
    tambon: "กรับใหญ่",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70190,
    district_code: 700503,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "คุ้งพยอม",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700511,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "ดอนกระเบื้อง",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700507,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "ท่าผา",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700502,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "นครชุมน์",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700509,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "บ้านม่วง",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700510,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "บ้านโป่ง",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700501,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "ปากแรต",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700504,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "ลาดบัวขาว",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700515,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "สวนกล้วย",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700508,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "หนองกบ",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700505,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "หนองปลาหมอ",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700512,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "หนองอ้อ",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700506,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "เขาขลุง",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700513,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "เบิกไพร",
    amphoe: "บ้านโป่ง",
    province: "ราชบุรี",
    zipcode: 70110,
    district_code: 700203,
    amphoe_code: 7005,
    province_code: 70,
  },
  {
    tambon: "ดอนทราย",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700708,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "ทุ่งหลวง",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700801,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "บ่อกระดาน",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700809,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "ปากท่อ",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700805,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "ป่าไก่",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700806,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "ยางหัก",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700810,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "วังมะนาว",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700802,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "วัดยางงาม",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700807,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "วันดาว",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700811,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "หนองกระทุ่ม",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700804,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "ห้วยยางโทน",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700812,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "อ่างหิน",
    amphoe: "ปากท่อ",
    province: "ราชบุรี",
    zipcode: 70140,
    district_code: 700808,
    amphoe_code: 7008,
    province_code: 70,
  },
  {
    tambon: "จอมประทัด",
    amphoe: "วัดเพลง",
    province: "ราชบุรี",
    zipcode: 70170,
    district_code: 700902,
    amphoe_code: 7009,
    province_code: 70,
  },
  {
    tambon: "วัดเพลง",
    amphoe: "วัดเพลง",
    province: "ราชบุรี",
    zipcode: 70170,
    district_code: 700903,
    amphoe_code: 7009,
    province_code: 70,
  },
  {
    tambon: "เกาะศาลพระ",
    amphoe: "วัดเพลง",
    province: "ราชบุรี",
    zipcode: 70170,
    district_code: 700901,
    amphoe_code: 7009,
    province_code: 70,
  },
  {
    tambon: "ตะนาวศรี",
    amphoe: "สวนผึ้ง",
    province: "ราชบุรี",
    zipcode: 70180,
    district_code: 700307,
    amphoe_code: 7003,
    province_code: 70,
  },
  {
    tambon: "ท่าเคย",
    amphoe: "สวนผึ้ง",
    province: "ราชบุรี",
    zipcode: 70180,
    district_code: 700304,
    amphoe_code: 7003,
    province_code: 70,
  },
  {
    tambon: "ป่าหวาย",
    amphoe: "สวนผึ้ง",
    province: "ราชบุรี",
    zipcode: 70180,
    district_code: 700297,
    amphoe_code: 7003,
    province_code: 70,
  },
  {
    tambon: "สวนผึ้ง",
    amphoe: "สวนผึ้ง",
    province: "ราชบุรี",
    zipcode: 70180,
    district_code: 700299,
    amphoe_code: 7003,
    province_code: 70,
  },
  {
    tambon: "คุ้งกระถิน",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700107,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "คุ้งน้ำวน",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700106,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "คูบัว",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700120,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "ดอนตะโก",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700103,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "ดอนแร่",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700113,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "ท่าราบ",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700121,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "น้ำพุ",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700112,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "บางป่า",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700118,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700122,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "พงสวาย",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700119,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "พิกุลทอง",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700111,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "สามเรือน",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700110,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "หนองกลางนา",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700104,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "หน้าเมือง",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700101,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "หลุมดิน",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700117,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "หินกอง",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700114,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "ห้วยไผ่",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700105,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "อ่างทอง",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700108,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "เกาะพลับพลา",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700116,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "เขาแร้ง",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700115,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "เจดีย์หัก",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700102,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "โคกหม้อ",
    amphoe: "เมืองราชบุรี",
    province: "ราชบุรี",
    zipcode: 70000,
    district_code: 700109,
    amphoe_code: 7001,
    province_code: 70,
  },
  {
    tambon: "คลองข่อย",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700710,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "คลองตาคต",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700705,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "ชำแระ",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700711,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "ดอนกระเบื้อง",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700507,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "ดอนทราย",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700708,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "ท่าชุมพล",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700713,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "ธรรมเสน",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700717,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "นางแก้ว",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700716,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "บางโตนด",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700714,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "บ้านฆ้อง",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700706,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "บ้านสิงห์",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700707,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "บ้านเลือก",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700704,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "สร้อยฟ้า",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700712,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "หนองกวาง",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700719,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "หนองโพ",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700703,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "เขาชะงุ้ม",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700718,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "เจ็ดเสมียน",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700709,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "เตาปูน",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700715,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "โพธาราม",
    amphoe: "โพธาราม",
    province: "ราชบุรี",
    zipcode: 70120,
    district_code: 700701,
    amphoe_code: 7007,
    province_code: 70,
  },
  {
    tambon: "ดงกลาง",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450407,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "ดงแดง",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450406,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "ดู่น้อย",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450411,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "น้ำใส",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450405,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "ป่าสังข์",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450408,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "ลิ้นฟ้า",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450410,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "ศรีโคตร",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450412,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "หนองผือ",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450402,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "หัวช้าง",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450401,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "อีง่อง",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450409,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "เมืองหงส์",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450403,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "โคกล่าม",
    amphoe: "จตุรพักตรพิมาน",
    province: "ร้อยเอ็ด",
    zipcode: 45180,
    district_code: 450404,
    amphoe_code: false,
    province_code: 45,
  },
  {
    tambon: "จังหาร",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450115,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "ดงสิงห์",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450111,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "ดินดำ",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450116,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "ปาฝา",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450107,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "ผักแว่น",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 451707,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "ม่วงลาด",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450113,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "ยางใหญ่",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 451706,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "แสนชาติ",
    amphoe: "จังหาร",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 451708,
    amphoe_code: 4517,
    province_code: 45,
  },
  {
    tambon: "ทุ่งเขาหลวง",
    amphoe: "ทุ่งเขาหลวง",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450523,
    amphoe_code: 4520,
    province_code: 45,
  },
  {
    tambon: "บึงงาม",
    amphoe: "ทุ่งเขาหลวง",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450512,
    amphoe_code: 4520,
    province_code: 45,
  },
  {
    tambon: "มะบ้า",
    amphoe: "ทุ่งเขาหลวง",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450509,
    amphoe_code: 4520,
    province_code: 45,
  },
  {
    tambon: "เทอดไทย",
    amphoe: "ทุ่งเขาหลวง",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450516,
    amphoe_code: 4520,
    province_code: 45,
  },
  {
    tambon: "เหล่า",
    amphoe: "ทุ่งเขาหลวง",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450508,
    amphoe_code: 4520,
    province_code: 45,
  },
  {
    tambon: "ธงธานี",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450502,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "ธวัชบุรี",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450504,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "นิเวศน์",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450501,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "บึงนคร",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450520,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "มะอึ",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450507,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "ราชธานี",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450522,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "หนองพอก",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450524,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450503,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "อุ่มเม้า",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450506,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "เขวาทุ่ง",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450510,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "เมืองน้อย",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450517,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "ไพศาล",
    amphoe: "ธวัชบุรี",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450515,
    amphoe_code: 4505,
    province_code: 45,
  },
  {
    tambon: "ขี้เหล็ก",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450308,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "ดอกล้ำ",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450302,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "บัวแดง",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450301,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "สระบัว",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450306,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "หนองแคน",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450303,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "โนนสง่า",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450307,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "โนนสวรรค์",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450305,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "โพนสูง",
    amphoe: "ปทุมรัตต์",
    province: "ร้อยเอ็ด",
    zipcode: 45190,
    district_code: 450304,
    amphoe_code: 4503,
    province_code: 45,
  },
  {
    tambon: "กุดน้ำใส",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450603,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "คำไฮ",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450613,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "ค้อใหญ่",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450615,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "ชานุวรรณ",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450617,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "นานวล",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450612,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "พนมไพร",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450601,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "วารีสวัสดิ์",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450606,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450614,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "หนองทัพไทย",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450604,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "แสนสุข",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450602,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "โคกสว่าง",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450607,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "โพธิ์ชัย",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450611,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "โพธิ์ใหญ่",
    amphoe: "พนมไพร",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450605,
    amphoe_code: 4506,
    province_code: 45,
  },
  {
    tambon: "บ้านบาก",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 451608,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "ศรีสมเด็จ",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450119,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "สวนจิก",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45280,
    district_code: 450112,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "หนองแวงควง",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 451607,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "หนองใหญ่",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450121,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "เมืองเปลือย",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450122,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "โพธิ์ทอง",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450114,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "โพธิ์สัย",
    amphoe: "ศรีสมเด็จ",
    province: "ร้อยเอ็ด",
    zipcode: 45280,
    district_code: 451606,
    amphoe_code: 4516,
    province_code: 45,
  },
  {
    tambon: "จำปาขัน",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451115,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "ช้างเผือก",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451112,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "ดอกไม้",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451102,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "ทุ่งกุลา",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451113,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "ทุ่งศรีเมือง",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451114,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "ทุ่งหลวง",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451108,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "นาใหญ่",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451103,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "น้ำคำ",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451110,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "บ่อพันขัน",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451107,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "สระคู",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451101,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "หัวช้าง",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 450401,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "หัวโทน",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451106,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "หินกอง",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451104,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "ห้วยหินลาด",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451111,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "เมืองทุ่ง",
    amphoe: "สุวรรณภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45130,
    district_code: 451105,
    amphoe_code: 4511,
    province_code: 45,
  },
  {
    tambon: "กกโพธิ์",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450904,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "ท่าสีดา",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450909,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "บึงงาม",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450512,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "ผาน้ำย้อย",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450908,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "ภูเขาทอง",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450903,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "รอบเมือง",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450102,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "หนองขุ่นใหญ่",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450906,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "หนองพอก",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450524,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "โคกสว่าง",
    amphoe: "หนองพอก",
    province: "ร้อยเอ็ด",
    zipcode: 45210,
    district_code: 450607,
    amphoe_code: 4509,
    province_code: 45,
  },
  {
    tambon: "ดูกอึ่ง",
    amphoe: "หนองฮี",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450610,
    amphoe_code: 4519,
    province_code: 45,
  },
  {
    tambon: "สาวแห",
    amphoe: "หนองฮี",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450616,
    amphoe_code: 4519,
    province_code: 45,
  },
  {
    tambon: "หนองฮี",
    amphoe: "หนองฮี",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450608,
    amphoe_code: 4519,
    province_code: 45,
  },
  {
    tambon: "เด่นราษฎร์",
    amphoe: "หนองฮี",
    province: "ร้อยเอ็ด",
    zipcode: 45140,
    district_code: 450609,
    amphoe_code: 4519,
    province_code: 45,
  },
  {
    tambon: "ขี้เหล็ก",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 450308,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "บ้านดู่",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451410,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "บ้านแจ้ง",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451403,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "หนองขาม",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451406,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "หนองบัว",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451408,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "หนองหมื่นถ่าน",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451405,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "หน่อม",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451404,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "อาจสามารถ",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451401,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "โพนเมือง",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451402,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "โหรา",
    amphoe: "อาจสามารถ",
    province: "ร้อยเอ็ด",
    zipcode: 45160,
    district_code: 451407,
    amphoe_code: 4514,
    province_code: 45,
  },
  {
    tambon: "กำแพง",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450208,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "กู่กาสิงห์",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450209,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "ดงครั่งน้อย",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450213,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "ดงครั่งใหญ่",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450205,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "ทุ่งทอง",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450212,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "น้ำอ้อม",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450210,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "บ้านฝาง",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450206,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "สิงห์โคก",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450204,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "หนองแวง",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450118,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "เกษตรวิสัย",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450201,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "เมืองบัว",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450202,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "เหล่าหลวง",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450203,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "โนนสว่าง",
    amphoe: "เกษตรวิสัย",
    province: "ร้อยเอ็ด",
    zipcode: 45150,
    district_code: 450211,
    amphoe_code: 4502,
    province_code: 45,
  },
  {
    tambon: "บ้านเขือง",
    amphoe: "เชียงขวัญ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450513,
    amphoe_code: 4518,
    province_code: 45,
  },
  {
    tambon: "พระธาตุ",
    amphoe: "เชียงขวัญ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450511,
    amphoe_code: 4518,
    province_code: 45,
  },
  {
    tambon: "พระเจ้า",
    amphoe: "เชียงขวัญ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450514,
    amphoe_code: 4518,
    province_code: 45,
  },
  {
    tambon: "พลับพลา",
    amphoe: "เชียงขวัญ",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450521,
    amphoe_code: 4518,
    province_code: 45,
  },
  {
    tambon: "หมูม้น",
    amphoe: "เชียงขวัญ",
    province: "ร้อยเอ็ด",
    zipcode: 45170,
    district_code: 450505,
    amphoe_code: 4518,
    province_code: 45,
  },
  {
    tambon: "เชียงขวัญ",
    amphoe: "เชียงขวัญ",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450519,
    amphoe_code: 4518,
    province_code: 45,
  },
  {
    tambon: "ชมสะอาด",
    amphoe: "เมยวดี",
    province: "ร้อยเอ็ด",
    zipcode: 45250,
    district_code: 451504,
    amphoe_code: 4515,
    province_code: 45,
  },
  {
    tambon: "ชุมพร",
    amphoe: "เมยวดี",
    province: "ร้อยเอ็ด",
    zipcode: 45250,
    district_code: 450794,
    amphoe_code: 4515,
    province_code: 45,
  },
  {
    tambon: "บุ่งเลิศ",
    amphoe: "เมยวดี",
    province: "ร้อยเอ็ด",
    zipcode: 45250,
    district_code: 451503,
    amphoe_code: 4515,
    province_code: 45,
  },
  {
    tambon: "เมยวดี",
    amphoe: "เมยวดี",
    province: "ร้อยเอ็ด",
    zipcode: 45250,
    district_code: 450795,
    amphoe_code: 4515,
    province_code: 45,
  },
  {
    tambon: "ขอนแก่น",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450104,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "ดงลาน",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450120,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450105,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "ปอภาร",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: false,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "รอบเมือง",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450102,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "สะอาดสมบูรณ์",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450106,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "สีแก้ว",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450108,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "หนองแก้ว",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450117,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "หนองแวง",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450118,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "เมืองทอง",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450125,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "เหนือเมือง",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450103,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "แคนใหญ่",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450123,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "โนนตาล",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450124,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "โนนรัง",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450110,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองร้อยเอ็ด",
    province: "ร้อยเอ็ด",
    zipcode: 45000,
    district_code: 450101,
    amphoe_code: 4501,
    province_code: 45,
  },
  {
    tambon: "กกกุง",
    amphoe: "เมืองสรวง",
    province: "ร้อยเอ็ด",
    zipcode: 45220,
    district_code: 451204,
    amphoe_code: 4512,
    province_code: 45,
  },
  {
    tambon: "คูเมือง",
    amphoe: "เมืองสรวง",
    province: "ร้อยเอ็ด",
    zipcode: 45220,
    district_code: 451203,
    amphoe_code: 4512,
    province_code: 45,
  },
  {
    tambon: "หนองผือ",
    amphoe: "เมืองสรวง",
    province: "ร้อยเอ็ด",
    zipcode: 45220,
    district_code: 450402,
    amphoe_code: 4512,
    province_code: 45,
  },
  {
    tambon: "หนองหิน",
    amphoe: "เมืองสรวง",
    province: "ร้อยเอ็ด",
    zipcode: 45220,
    district_code: 451202,
    amphoe_code: 4512,
    province_code: 45,
  },
  {
    tambon: "เมืองสรวง",
    amphoe: "เมืองสรวง",
    province: "ร้อยเอ็ด",
    zipcode: 45220,
    district_code: 451205,
    amphoe_code: 4512,
    province_code: 45,
  },
  {
    tambon: "กลาง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451001,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "ขวัญเมือง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451017,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "ขวาว",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451008,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "ท่าม่วง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451007,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "นางาม",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451002,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "นาเมือง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451005,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "นาเลิง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451012,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "นาแซง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451004,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "บึงเกลือ",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451018,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "พรสวรรค์",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451016,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "ภูเงิน",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451010,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "วังหลวง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451006,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "ศรีวิลัย",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451014,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451015,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "เกาะแก้ว",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451011,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "เมืองไพร",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451003,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "เหล่าน้อย",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 451013,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "โพธิ์ทอง",
    amphoe: "เสลภูมิ",
    province: "ร้อยเอ็ด",
    zipcode: 45120,
    district_code: 450114,
    amphoe_code: 4510,
    province_code: 45,
  },
  {
    tambon: "ขามเปี้ย",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450801,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "คำพอุง",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450806,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "ดอนโอง",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450808,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "บัวคำ",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450803,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "สะอาด",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450805,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "หนองตาไก้",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450807,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "อัคคะคำ",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450797,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "เชียงใหม่",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450798,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "โพธิ์ศรี",
    amphoe: "โพธิ์ชัย",
    province: "ร้อยเอ็ด",
    zipcode: 45230,
    district_code: 450809,
    amphoe_code: 4508,
    province_code: 45,
  },
  {
    tambon: "ท่าหาดยาว",
    amphoe: "โพนทราย",
    province: "ร้อยเอ็ด",
    zipcode: 45240,
    district_code: 451305,
    amphoe_code: 4513,
    province_code: 45,
  },
  {
    tambon: "ยางคำ",
    amphoe: "โพนทราย",
    province: "ร้อยเอ็ด",
    zipcode: 45240,
    district_code: 451304,
    amphoe_code: 4513,
    province_code: 45,
  },
  {
    tambon: "ศรีสว่าง",
    amphoe: "โพนทราย",
    province: "ร้อยเอ็ด",
    zipcode: 45240,
    district_code: 451303,
    amphoe_code: 4513,
    province_code: 45,
  },
  {
    tambon: "สามขา",
    amphoe: "โพนทราย",
    province: "ร้อยเอ็ด",
    zipcode: 45240,
    district_code: 451302,
    amphoe_code: 4513,
    province_code: 45,
  },
  {
    tambon: "โพนทราย",
    amphoe: "โพนทราย",
    province: "ร้อยเอ็ด",
    zipcode: 45240,
    district_code: 451301,
    amphoe_code: 4513,
    province_code: 45,
  },
  {
    tambon: "คำนาดี",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450710,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "นาอุดม",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450703,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "พรมสวรรค์",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450711,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "วังสามัคคี",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450713,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "สระนกแก้ว",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450712,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "สว่าง",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450704,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "หนองใหญ่",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450121,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "อุ่มเม่า",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450709,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "แวง",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450701,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "โคกกกม่วง",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450702,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "โคกสูง",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450714,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "โนนชัยศรี",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450707,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "โพธิ์ทอง",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450114,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "โพธิ์ศรีสว่าง",
    amphoe: "โพนทอง",
    province: "ร้อยเอ็ด",
    zipcode: 45110,
    district_code: 450708,
    amphoe_code: 4507,
    province_code: 45,
  },
  {
    tambon: "ชัยนารายณ์",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160402,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ชัยบาดาล",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160418,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ซับตะเคียน",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160409,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ท่าดินดำ",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160407,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ท่ามะนาว",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160414,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "นาโสม",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15190,
    district_code: 160410,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "นิคมลำนารายณ์",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160417,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "บัวชุม",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160406,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "บ้านใหม่สามัคคี",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160419,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "มะกอกหวาน",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160408,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ม่วงค่อม",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160405,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ลำนารายณ์",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160401,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ศิลาทิพย์",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160403,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "หนองยายโต๊ะ",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160411,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ห้วยหิน",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160404,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "เกาะรัง",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160412,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "เขาแหลม",
    amphoe: "ชัยบาดาล",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 160422,
    amphoe_code: 1604,
    province_code: 16,
  },
  {
    tambon: "ท่าวุ้ง",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160501,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "บางคู้",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160502,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "บางงา",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160505,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "บางลี่",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160504,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "บ้านเบิก",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160510,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "มุจลินท์",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160511,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "ลาดสาลี่",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160509,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "หัวสำโรง",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160508,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "เขาสมอคอน",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15180,
    district_code: 160507,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "โคกสลุด",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160506,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "โพตลาดแก้ว",
    amphoe: "ท่าวุ้ง",
    province: "ลพบุรี",
    zipcode: 15150,
    district_code: 160503,
    amphoe_code: 1605,
    province_code: 16,
  },
  {
    tambon: "ซับจำปา",
    amphoe: "ท่าหลวง",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160497,
    amphoe_code: 1607,
    province_code: 16,
  },
  {
    tambon: "ทะเลวังวัด",
    amphoe: "ท่าหลวง",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160705,
    amphoe_code: 1607,
    province_code: 16,
  },
  {
    tambon: "ท่าหลวง",
    amphoe: "ท่าหลวง",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160499,
    amphoe_code: 1607,
    province_code: 16,
  },
  {
    tambon: "หนองผักแว่น",
    amphoe: "ท่าหลวง",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160496,
    amphoe_code: 1607,
    province_code: 16,
  },
  {
    tambon: "หัวลำ",
    amphoe: "ท่าหลวง",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160706,
    amphoe_code: 1607,
    province_code: 16,
  },
  {
    tambon: "แก่งผักกูด",
    amphoe: "ท่าหลวง",
    province: "ลพบุรี",
    zipcode: 15230,
    district_code: 160498,
    amphoe_code: 1607,
    province_code: 16,
  },
  {
    tambon: "ชอนม่วง",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160615,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "ดงพลับ",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160604,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "ดอนดึง",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160614,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "บางกะพี้",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160610,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "บางขาม",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15180,
    district_code: 160613,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "บางพึ่ง",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160608,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "บ้านกล้วย",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160603,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "บ้านชี",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15180,
    district_code: 160605,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "บ้านทราย",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160602,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "บ้านหมี่",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160619,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "พุคา",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160606,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "มหาสอน",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160618,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "สนามแจง",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160622,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "สายห้วยแก้ว",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160617,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "หนองกระเบียน",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160616,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "หนองทรายขาว",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160609,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "หนองเต่า",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160611,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "หนองเมือง",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160621,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "หินปัก",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160607,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "เชียงงา",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160620,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "โพนทอง",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160612,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "ไผ่ใหญ่",
    amphoe: "บ้านหมี่",
    province: "ลพบุรี",
    zipcode: 15110,
    district_code: 160601,
    amphoe_code: 1606,
    province_code: 16,
  },
  {
    tambon: "ชอนน้อย",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15140,
    district_code: 160206,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "ช่องสาริกา",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15220,
    district_code: 160202,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "ดีลัง",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15220,
    district_code: 160204,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "น้ำสุด",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15140,
    district_code: 160209,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "พัฒนานิคม",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15140,
    district_code: 160201,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "มะนาวหวาน",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15140,
    district_code: 160203,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "หนองบัว",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15140,
    district_code: 160207,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "ห้วยขุนราม",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 18220,
    district_code: 160208,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "โคกสลุง",
    amphoe: "พัฒนานิคม",
    province: "ลพบุรี",
    zipcode: 15140,
    district_code: 160205,
    amphoe_code: 1602,
    province_code: 16,
  },
  {
    tambon: "กุดตาเพชร",
    amphoe: "ลำสนธิ",
    province: "ลพบุรี",
    zipcode: 15190,
    district_code: 160415,
    amphoe_code: 1610,
    province_code: 16,
  },
  {
    tambon: "ซับสมบูรณ์",
    amphoe: "ลำสนธิ",
    province: "ลพบุรี",
    zipcode: 15190,
    district_code: 160420,
    amphoe_code: 1610,
    province_code: 16,
  },
  {
    tambon: "ลำสนธิ",
    amphoe: "ลำสนธิ",
    province: "ลพบุรี",
    zipcode: 15190,
    district_code: 160416,
    amphoe_code: 1610,
    province_code: 16,
  },
  {
    tambon: "หนองรี",
    amphoe: "ลำสนธิ",
    province: "ลพบุรี",
    zipcode: 15190,
    district_code: 160413,
    amphoe_code: 1610,
    province_code: 16,
  },
  {
    tambon: "เขาน้อย",
    amphoe: "ลำสนธิ",
    province: "ลพบุรี",
    zipcode: 15130,
    district_code: 161006,
    amphoe_code: 1610,
    province_code: 16,
  },
  {
    tambon: "เขารวก",
    amphoe: "ลำสนธิ",
    province: "ลพบุรี",
    zipcode: 15190,
    district_code: 160421,
    amphoe_code: 1610,
    province_code: 16,
  },
  {
    tambon: "ทุ่งท่าช้าง",
    amphoe: "สระโบสถ์",
    province: "ลพบุรี",
    zipcode: 15240,
    district_code: 160397,
    amphoe_code: 1608,
    province_code: 16,
  },
  {
    tambon: "นิยมชัย",
    amphoe: "สระโบสถ์",
    province: "ลพบุรี",
    zipcode: 15240,
    district_code: 160805,
    amphoe_code: 1608,
    province_code: 16,
  },
  {
    tambon: "มหาโพธิ",
    amphoe: "สระโบสถ์",
    province: "ลพบุรี",
    zipcode: 15240,
    district_code: 160802,
    amphoe_code: 1608,
    province_code: 16,
  },
  {
    tambon: "สระโบสถ์",
    amphoe: "สระโบสถ์",
    province: "ลพบุรี",
    zipcode: 15240,
    district_code: 160399,
    amphoe_code: 1608,
    province_code: 16,
  },
  {
    tambon: "ห้วยใหญ่",
    amphoe: "สระโบสถ์",
    province: "ลพบุรี",
    zipcode: 15240,
    district_code: 160804,
    amphoe_code: 1608,
    province_code: 16,
  },
  {
    tambon: "ชอนสมบูรณ์",
    amphoe: "หนองม่วง",
    province: "ลพบุรี",
    zipcode: 15170,
    district_code: 160315,
    amphoe_code: 1611,
    province_code: 16,
  },
  {
    tambon: "ชอนสารเดช",
    amphoe: "หนองม่วง",
    province: "ลพบุรี",
    zipcode: 15170,
    district_code: 160311,
    amphoe_code: 1611,
    province_code: 16,
  },
  {
    tambon: "ดงดินแดง",
    amphoe: "หนองม่วง",
    province: "ลพบุรี",
    zipcode: 15170,
    district_code: 160319,
    amphoe_code: 1611,
    province_code: 16,
  },
  {
    tambon: "บ่อทอง",
    amphoe: "หนองม่วง",
    province: "ลพบุรี",
    zipcode: 15170,
    district_code: 160313,
    amphoe_code: 1611,
    province_code: 16,
  },
  {
    tambon: "ยางโทน",
    amphoe: "หนองม่วง",
    province: "ลพบุรี",
    zipcode: 15170,
    district_code: 160314,
    amphoe_code: 1611,
    province_code: 16,
  },
  {
    tambon: "หนองม่วง",
    amphoe: "หนองม่วง",
    province: "ลพบุรี",
    zipcode: 15170,
    district_code: 160312,
    amphoe_code: 1611,
    province_code: 16,
  },
  {
    tambon: "กกโก",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160103,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "งิ้วราย",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160110,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ดอนโพธิ์",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160111,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ตะลุง",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160112,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ถนนใหญ่",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160125,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ทะเลชุบศร",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160101,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ท่าศาลา",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160115,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ท่าหิน",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160102,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ท่าแค",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160114,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ท้ายตลาด",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160119,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "นิคมสร้างตนเอง",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160116,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "บางขันหมาก",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160117,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "บ้านข่อย",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160118,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "ป่าตาล",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160120,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "พรหมมาสตร์",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160121,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "สี่คลอง",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160124,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "เขาพระงาม",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15160,
    district_code: 160105,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "เขาสามยอด",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160106,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "โก่งธนู",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 13240,
    district_code: 160104,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "โคกกะเทียม",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15160,
    district_code: 160107,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "โคกตูม",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15210,
    district_code: 160109,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "โคกลำพาน",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160108,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "โพธิ์ตรุ",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160123,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "โพธิ์เก้าต้น",
    amphoe: "เมืองลพบุรี",
    province: "ลพบุรี",
    zipcode: 15000,
    district_code: 160122,
    amphoe_code: 1601,
    province_code: 16,
  },
  {
    tambon: "คลองเกตุ",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160306,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "ดงมะรุม",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160310,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "ถลุงเหล็ก",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160303,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "วังขอนขว้าง",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160318,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "วังจั่น",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160320,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "วังเพลิง",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160309,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "สะแกราบ",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160307,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "หนองแขม",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160322,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "หลุมข้าว",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160304,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "ห้วยโป่ง",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160305,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "เกาะแก้ว",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160302,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "เพนียด",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160308,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "โคกสำโรง",
    amphoe: "โคกสำโรง",
    province: "ลพบุรี",
    zipcode: 15120,
    district_code: 160301,
    amphoe_code: 1603,
    province_code: 16,
  },
  {
    tambon: "ยางราก",
    amphoe: "โคกเจริญ",
    province: "ลพบุรี",
    zipcode: 15250,
    district_code: 160317,
    amphoe_code: 1609,
    province_code: 16,
  },
  {
    tambon: "วังทอง",
    amphoe: "โคกเจริญ",
    province: "ลพบุรี",
    zipcode: 15250,
    district_code: 160323,
    amphoe_code: 1609,
    province_code: 16,
  },
  {
    tambon: "หนองมะค่า",
    amphoe: "โคกเจริญ",
    province: "ลพบุรี",
    zipcode: 15250,
    district_code: 160321,
    amphoe_code: 1609,
    province_code: 16,
  },
  {
    tambon: "โคกเจริญ",
    amphoe: "โคกเจริญ",
    province: "ลพบุรี",
    zipcode: 15250,
    district_code: 160316,
    amphoe_code: 1609,
    province_code: 16,
  },
  {
    tambon: "โคกแสมสาร",
    amphoe: "โคกเจริญ",
    province: "ลพบุรี",
    zipcode: 15250,
    district_code: 160905,
    amphoe_code: 1609,
    province_code: 16,
  },
  {
    tambon: "นาแก",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520506,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "บ้านร้อง",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520504,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "บ้านหวด",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520509,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "บ้านอ้อน",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520507,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "บ้านแหง",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520508,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "บ้านโป่ง",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520503,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "ปงเตา",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520505,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "หลวงเหนือ",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520501,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "หลวงใต้",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520502,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "แม่ตีบ",
    amphoe: "งาว",
    province: "ลำปาง",
    zipcode: 52110,
    district_code: 520510,
    amphoe_code: 5205,
    province_code: 52,
  },
  {
    tambon: "ทุ่งฮั้ว",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520701,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "ร่องเคาะ",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520704,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "วังซ้าย",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520706,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "วังทรายคำ",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520708,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "วังทอง",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520705,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "วังเหนือ",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520702,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "วังแก้ว",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520707,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "วังใต้",
    amphoe: "วังเหนือ",
    province: "ลำปาง",
    zipcode: 52140,
    district_code: 520703,
    amphoe_code: 5207,
    province_code: 52,
  },
  {
    tambon: "นายาง",
    amphoe: "สบปราบ",
    province: "ลำปาง",
    zipcode: 52170,
    district_code: 521104,
    amphoe_code: 5211,
    province_code: 52,
  },
  {
    tambon: "สบปราบ",
    amphoe: "สบปราบ",
    province: "ลำปาง",
    zipcode: 52170,
    district_code: 521101,
    amphoe_code: 5211,
    province_code: 52,
  },
  {
    tambon: "สมัย",
    amphoe: "สบปราบ",
    province: "ลำปาง",
    zipcode: 52170,
    district_code: 521102,
    amphoe_code: 5211,
    province_code: 52,
  },
  {
    tambon: "แม่กัวะ",
    amphoe: "สบปราบ",
    province: "ลำปาง",
    zipcode: 52170,
    district_code: 521103,
    amphoe_code: 5211,
    province_code: 52,
  },
  {
    tambon: "ปงยางคก",
    amphoe: "ห้างฉัตร",
    province: "ลำปาง",
    zipcode: 52190,
    district_code: 521204,
    amphoe_code: 5212,
    province_code: 52,
  },
  {
    tambon: "วอแก้ว",
    amphoe: "ห้างฉัตร",
    province: "ลำปาง",
    zipcode: 52190,
    district_code: 521207,
    amphoe_code: 5212,
    province_code: 52,
  },
  {
    tambon: "หนองหล่ม",
    amphoe: "ห้างฉัตร",
    province: "ลำปาง",
    zipcode: 52190,
    district_code: 521202,
    amphoe_code: 5212,
    province_code: 52,
  },
  {
    tambon: "ห้างฉัตร",
    amphoe: "ห้างฉัตร",
    province: "ลำปาง",
    zipcode: 52190,
    district_code: 521201,
    amphoe_code: 5212,
    province_code: 52,
  },
  {
    tambon: "เมืองยาว",
    amphoe: "ห้างฉัตร",
    province: "ลำปาง",
    zipcode: 52190,
    district_code: 521203,
    amphoe_code: 5212,
    province_code: 52,
  },
  {
    tambon: "เวียงตาล",
    amphoe: "ห้างฉัตร",
    province: "ลำปาง",
    zipcode: 52190,
    district_code: 521205,
    amphoe_code: 5212,
    province_code: 52,
  },
  {
    tambon: "แม่สัน",
    amphoe: "ห้างฉัตร",
    province: "ลำปาง",
    zipcode: 52190,
    district_code: 521206,
    amphoe_code: 5212,
    province_code: 52,
  },
  {
    tambon: "ท่าผา",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520308,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "นาแก้ว",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520302,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "นาแส่ง",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520307,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "ลำปางหลวง",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520301,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "วังพร้าว",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520304,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "ศาลา",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520305,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "เกาะคา",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520306,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "ใหม่พัฒนา",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520309,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "ไหล่หิน",
    amphoe: "เกาะคา",
    province: "ลำปาง",
    zipcode: 52130,
    district_code: 520303,
    amphoe_code: 5203,
    province_code: 52,
  },
  {
    tambon: "นาโป่ง",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52160,
    district_code: 520806,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "ล้อมแรด",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52160,
    district_code: 520801,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "เถินบุรี",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52160,
    district_code: 520808,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "เวียงมอก",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52160,
    district_code: 520805,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "แม่ถอด",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52160,
    district_code: 520807,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "แม่ปะ",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52160,
    district_code: 520803,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "แม่มอก",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52160,
    district_code: 520804,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "แม่วะ",
    amphoe: "เถิน",
    province: "ลำปาง",
    zipcode: 52230,
    district_code: 520802,
    amphoe_code: 5208,
    province_code: 52,
  },
  {
    tambon: "ทุ่งกว๋าว",
    amphoe: "เมืองปาน",
    province: "ลำปาง",
    zipcode: 52240,
    district_code: 520697,
    amphoe_code: 5213,
    province_code: 52,
  },
  {
    tambon: "บ้านขอ",
    amphoe: "เมืองปาน",
    province: "ลำปาง",
    zipcode: 52240,
    district_code: 520698,
    amphoe_code: 5213,
    province_code: 52,
  },
  {
    tambon: "หัวเมือง",
    amphoe: "เมืองปาน",
    province: "ลำปาง",
    zipcode: 52240,
    district_code: 521305,
    amphoe_code: 5213,
    province_code: 52,
  },
  {
    tambon: "เมืองปาน",
    amphoe: "เมืองปาน",
    province: "ลำปาง",
    zipcode: 52240,
    district_code: 520699,
    amphoe_code: 5213,
    province_code: 52,
  },
  {
    tambon: "แจ้ซ้อน",
    amphoe: "เมืองปาน",
    province: "ลำปาง",
    zipcode: 52240,
    district_code: 520696,
    amphoe_code: 5213,
    province_code: 52,
  },
  {
    tambon: "กล้วยแพะ",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520107,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "ชมพู",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520106,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "ต้นธงชัย",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520117,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "ทุ่งฝาย",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520112,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "นิคมพัฒนา",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520118,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "บุญนาคพัฒนา",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520119,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "บ่อแฮ้ว",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520116,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "บ้านค่า",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520115,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "บ้านเป้า",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520114,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "บ้านเสด็จ",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520110,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "บ้านเอื้อม",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520113,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "บ้านแลง",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520109,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "ปงแสนทอง",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520108,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "พระบาท",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520105,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "พิชัย",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520111,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "สบตุ๋ย",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520104,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "สวนดอก",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52100,
    district_code: 520103,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "หัวเวียง",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520102,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "เวียงเหนือ",
    amphoe: "เมืองลำปาง",
    province: "ลำปาง",
    zipcode: 52000,
    district_code: 520101,
    amphoe_code: 5201,
    province_code: 52,
  },
  {
    tambon: "ทุ่งงาม",
    amphoe: "เสริมงาม",
    province: "ลำปาง",
    zipcode: 52210,
    district_code: 520401,
    amphoe_code: 5204,
    province_code: 52,
  },
  {
    tambon: "เสริมกลาง",
    amphoe: "เสริมงาม",
    province: "ลำปาง",
    zipcode: 52210,
    district_code: 520404,
    amphoe_code: 5204,
    province_code: 52,
  },
  {
    tambon: "เสริมขวา",
    amphoe: "เสริมงาม",
    province: "ลำปาง",
    zipcode: 52210,
    district_code: 520402,
    amphoe_code: 5204,
    province_code: 52,
  },
  {
    tambon: "เสริมซ้าย",
    amphoe: "เสริมงาม",
    province: "ลำปาง",
    zipcode: 52210,
    district_code: 520403,
    amphoe_code: 5204,
    province_code: 52,
  },
  {
    tambon: "ทุ่งผึ้ง",
    amphoe: "แจ้ห่ม",
    province: "ลำปาง",
    zipcode: 52120,
    district_code: 520606,
    amphoe_code: 5206,
    province_code: 52,
  },
  {
    tambon: "บ้านสา",
    amphoe: "แจ้ห่ม",
    province: "ลำปาง",
    zipcode: 52120,
    district_code: 520602,
    amphoe_code: 5206,
    province_code: 52,
  },
  {
    tambon: "ปงดอน",
    amphoe: "แจ้ห่ม",
    province: "ลำปาง",
    zipcode: 52120,
    district_code: 520603,
    amphoe_code: 5206,
    province_code: 52,
  },
  {
    tambon: "วิเชตนคร",
    amphoe: "แจ้ห่ม",
    province: "ลำปาง",
    zipcode: 52120,
    district_code: 520607,
    amphoe_code: 5206,
    province_code: 52,
  },
  {
    tambon: "เมืองมาย",
    amphoe: "แจ้ห่ม",
    province: "ลำปาง",
    zipcode: 52120,
    district_code: 520605,
    amphoe_code: 5206,
    province_code: 52,
  },
  {
    tambon: "แจ้ห่ม",
    amphoe: "แจ้ห่ม",
    province: "ลำปาง",
    zipcode: 52120,
    district_code: 520601,
    amphoe_code: 5206,
    province_code: 52,
  },
  {
    tambon: "แม่สุก",
    amphoe: "แจ้ห่ม",
    province: "ลำปาง",
    zipcode: 52120,
    district_code: 520604,
    amphoe_code: 5206,
    province_code: 52,
  },
  {
    tambon: "ดอนไฟ",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521007,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "นาครัว",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521002,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "น้ำโจ้",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521006,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "บ้านกิ่ว",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521004,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "บ้านบอม",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521005,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "ป่าตัน",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521003,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "วังเงิน",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521010,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "สันดอนแก้ว",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521011,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "หัวเสือ",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521008,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "แม่ทะ",
    amphoe: "แม่ทะ",
    province: "ลำปาง",
    zipcode: 52150,
    district_code: 521001,
    amphoe_code: 5210,
    province_code: 52,
  },
  {
    tambon: "ผาปัง",
    amphoe: "แม่พริก",
    province: "ลำปาง",
    zipcode: 52180,
    district_code: 520902,
    amphoe_code: 5209,
    province_code: 52,
  },
  {
    tambon: "พระบาทวังตวง",
    amphoe: "แม่พริก",
    province: "ลำปาง",
    zipcode: 52230,
    district_code: 520904,
    amphoe_code: 5209,
    province_code: 52,
  },
  {
    tambon: "แม่ปุ",
    amphoe: "แม่พริก",
    province: "ลำปาง",
    zipcode: 52180,
    district_code: 520903,
    amphoe_code: 5209,
    province_code: 52,
  },
  {
    tambon: "แม่พริก",
    amphoe: "แม่พริก",
    province: "ลำปาง",
    zipcode: 52180,
    district_code: 520901,
    amphoe_code: 5209,
    province_code: 52,
  },
  {
    tambon: "จางเหนือ",
    amphoe: "แม่เมาะ",
    province: "ลำปาง",
    zipcode: 52220,
    district_code: 520203,
    amphoe_code: 5202,
    province_code: 52,
  },
  {
    tambon: "นาสัก",
    amphoe: "แม่เมาะ",
    province: "ลำปาง",
    zipcode: 52220,
    district_code: 520198,
    amphoe_code: 5202,
    province_code: 52,
  },
  {
    tambon: "บ้านดง",
    amphoe: "แม่เมาะ",
    province: "ลำปาง",
    zipcode: 52220,
    district_code: 520199,
    amphoe_code: 5202,
    province_code: 52,
  },
  {
    tambon: "สบป้าด",
    amphoe: "แม่เมาะ",
    province: "ลำปาง",
    zipcode: 52220,
    district_code: 520205,
    amphoe_code: 5202,
    province_code: 52,
  },
  {
    tambon: "แม่เมาะ",
    amphoe: "แม่เมาะ",
    province: "ลำปาง",
    zipcode: 52220,
    district_code: 520204,
    amphoe_code: 5202,
    province_code: 52,
  },
  {
    tambon: "ตะเคียนปม",
    amphoe: "ทุ่งหัวช้าง",
    province: "ลำพูน",
    zipcode: 51160,
    district_code: 510503,
    amphoe_code: 5105,
    province_code: 51,
  },
  {
    tambon: "ทุ่งหัวช้าง",
    amphoe: "ทุ่งหัวช้าง",
    province: "ลำพูน",
    zipcode: 51160,
    district_code: 510499,
    amphoe_code: 5105,
    province_code: 51,
  },
  {
    tambon: "บ้านปวง",
    amphoe: "ทุ่งหัวช้าง",
    province: "ลำพูน",
    zipcode: 51160,
    district_code: 510498,
    amphoe_code: 5105,
    province_code: 51,
  },
  {
    tambon: "บ้านธิ",
    amphoe: "บ้านธิ",
    province: "ลำพูน",
    zipcode: 51180,
    district_code: 510114,
    amphoe_code: 5107,
    province_code: 51,
  },
  {
    tambon: "ห้วยยาบ",
    amphoe: "บ้านธิ",
    province: "ลำพูน",
    zipcode: 51180,
    district_code: 510115,
    amphoe_code: 5107,
    province_code: 51,
  },
  {
    tambon: "บ้านโฮ่ง",
    amphoe: "บ้านโฮ่ง",
    province: "ลำพูน",
    zipcode: 51130,
    district_code: 510301,
    amphoe_code: 5103,
    province_code: 51,
  },
  {
    tambon: "ป่าพลู",
    amphoe: "บ้านโฮ่ง",
    province: "ลำพูน",
    zipcode: 51130,
    district_code: 510302,
    amphoe_code: 5103,
    province_code: 51,
  },
  {
    tambon: "ศรีเตี้ย",
    amphoe: "บ้านโฮ่ง",
    province: "ลำพูน",
    zipcode: 51130,
    district_code: 510304,
    amphoe_code: 5103,
    province_code: 51,
  },
  {
    tambon: "หนองปลาสะวาย",
    amphoe: "บ้านโฮ่ง",
    province: "ลำพูน",
    zipcode: 51130,
    district_code: 510305,
    amphoe_code: 5103,
    province_code: 51,
  },
  {
    tambon: "เหล่ายาว",
    amphoe: "บ้านโฮ่ง",
    province: "ลำพูน",
    zipcode: 51130,
    district_code: 510303,
    amphoe_code: 5103,
    province_code: 51,
  },
  {
    tambon: "ท่าตุ้ม",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510607,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "นครเจดีย์",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510611,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "น้ำดิบ",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510608,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "บ้านเรือน",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510605,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "ปากบ่อง",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510601,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "ป่าซาง",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510602,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "มะกอก",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510606,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "ม่วงน้อย",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510604,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "แม่แรง",
    amphoe: "ป่าซาง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510603,
    amphoe_code: 5106,
    province_code: 51,
  },
  {
    tambon: "ก้อ",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510405,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "ดงดำ",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510404,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "นาทราย",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510403,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "ป่าไผ่",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510408,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "ลี้",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510401,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "ศรีวิชัย",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510409,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "แม่ตืน",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510402,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "แม่ลาน",
    amphoe: "ลี้",
    province: "ลำพูน",
    zipcode: 51110,
    district_code: 510406,
    amphoe_code: 5104,
    province_code: 51,
  },
  {
    tambon: "ต้นธง",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510107,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510112,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "บ้านแป้น",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510108,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "ประตูป่า",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510105,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "ป่าสัก",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510110,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "มะเขือแจ้",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510113,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "ริมปิง",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510106,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "ศรีบัวบาน",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510116,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "หนองช้างคืน",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51150,
    district_code: 510104,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "หนองหนาม",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510117,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "อุโมงค์",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51150,
    district_code: 510103,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "เวียงยอง",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510111,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "เหมืองง่า",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510102,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "เหมืองจี้",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510109,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองลำพูน",
    province: "ลำพูน",
    zipcode: 51000,
    district_code: 510101,
    amphoe_code: 5101,
    province_code: 51,
  },
  {
    tambon: "วังผาง",
    amphoe: "เวียงหนองล่อง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510609,
    amphoe_code: 5108,
    province_code: 51,
  },
  {
    tambon: "หนองยวง",
    amphoe: "เวียงหนองล่อง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510612,
    amphoe_code: 5108,
    province_code: 51,
  },
  {
    tambon: "หนองล่อง",
    amphoe: "เวียงหนองล่อง",
    province: "ลำพูน",
    zipcode: 51120,
    district_code: 510610,
    amphoe_code: 5108,
    province_code: 51,
  },
  {
    tambon: "ทากาศ",
    amphoe: "แม่ทา",
    province: "ลำพูน",
    zipcode: 51170,
    district_code: 510203,
    amphoe_code: 5102,
    province_code: 51,
  },
  {
    tambon: "ทาขุมเงิน",
    amphoe: "แม่ทา",
    province: "ลำพูน",
    zipcode: 51170,
    district_code: 510204,
    amphoe_code: 5102,
    province_code: 51,
  },
  {
    tambon: "ทาทุ่งหลวง",
    amphoe: "แม่ทา",
    province: "ลำพูน",
    zipcode: 51170,
    district_code: 510205,
    amphoe_code: 5102,
    province_code: 51,
  },
  {
    tambon: "ทาปลาดุก",
    amphoe: "แม่ทา",
    province: "ลำพูน",
    zipcode: 51140,
    district_code: 510201,
    amphoe_code: 5102,
    province_code: 51,
  },
  {
    tambon: "ทาสบเส้า",
    amphoe: "แม่ทา",
    province: "ลำพูน",
    zipcode: 51140,
    district_code: 510202,
    amphoe_code: 5102,
    province_code: 51,
  },
  {
    tambon: "ทาแม่ลอบ",
    amphoe: "แม่ทา",
    province: "ลำพูน",
    zipcode: 51170,
    district_code: 510206,
    amphoe_code: 5102,
    province_code: 51,
  },
  {
    tambon: "กระแชง",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330414,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "กุดเสลา",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330402,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "ขนุน",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330420,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "จานใหญ่",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330411,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "ชำ",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330413,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "ตระกาจ",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330409,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "ทุ่งใหญ่",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330424,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "น้ำอ้อม",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330406,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "บึงมะลู",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330401,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "ภูผาหมอก",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330425,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "ภูเงิน",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330412,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "รุง",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330408,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "ละลาย",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330407,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "สวนกล้วย",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330421,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "สังเม็ก",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330405,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "หนองหญ้าลาด",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330416,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "เมือง",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330403,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "เวียงเหนือ",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330423,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "เสาธงชัย",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330419,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "โนนสำราญ",
    amphoe: "กันทรลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330415,
    amphoe_code: 3304,
    province_code: 33,
  },
  {
    tambon: "คำเนียม",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330320,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "จาน",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330105,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "ดูน",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330301,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "ดู่",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330313,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "ทาม",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330307,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "บัวน้อย",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330311,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "ผักแพว",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330314,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "ยาง",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330304,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "ละทาย",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330308,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "หนองบัว",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330312,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "หนองหัวช้าง",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330303,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "หนองแก้ว",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330121,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "หนองแวง",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330305,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "อีปาด",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330310,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "เมืองน้อย",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330309,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "โนนสัง",
    amphoe: "กันทรารมย์",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330302,
    amphoe_code: 3303,
    province_code: 33,
  },
  {
    tambon: "กฤษณา",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330524,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "กันทรารมย์",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330501,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "จะกง",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330502,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ดองกำเม็ด",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330504,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ตะเคียน",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330513,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ตาอุด",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330508,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "นิคมพัฒนา",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330515,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ปราสาท",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330518,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ปรือใหญ่",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330506,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ลมศักดิ์",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330525,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ศรีตระกูล",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330527,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ศรีสะอาด",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330528,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "สะเดาใหญ่",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330507,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "สำโรงตาเจ็น",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330521,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "หนองฉลอง",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330526,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "หัวเสือ",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330511,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ห้วยสำราญ",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330522,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ห้วยเหนือ",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330509,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ห้วยใต้",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330510,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "โคกเพชร",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330517,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "โสน",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330505,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "ใจดี",
    amphoe: "ขุขันธ์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330503,
    amphoe_code: 3305,
    province_code: 33,
  },
  {
    tambon: "กระหวัน",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330806,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "กันทรอม",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330809,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "ขุนหาญ",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330807,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "บักดอง",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330802,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "พราน",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330803,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "ภูฝ้าย",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330810,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "สิ",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330801,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "ห้วยจันทร์",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330812,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "โนนสูง",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330808,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "โพธิ์กระสังข์",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330811,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "โพธิ์วงศ์",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330804,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "ไพร",
    amphoe: "ขุนหาญ",
    province: "ศรีสะเกษ",
    zipcode: 33150,
    district_code: 330805,
    amphoe_code: 3308,
    province_code: 33,
  },
  {
    tambon: "คูบ",
    amphoe: "น้ำเกลี้ยง",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 331506,
    amphoe_code: 3315,
    province_code: 33,
  },
  {
    tambon: "ตองปิด",
    amphoe: "น้ำเกลี้ยง",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330394,
    amphoe_code: 3315,
    province_code: 33,
  },
  {
    tambon: "น้ำเกลี้ยง",
    amphoe: "น้ำเกลี้ยง",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330318,
    amphoe_code: 3315,
    province_code: 33,
  },
  {
    tambon: "รุ่งระวี",
    amphoe: "น้ำเกลี้ยง",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 331505,
    amphoe_code: 3315,
    province_code: 33,
  },
  {
    tambon: "ละเอาะ",
    amphoe: "น้ำเกลี้ยง",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330317,
    amphoe_code: 3315,
    province_code: 33,
  },
  {
    tambon: "เขิน",
    amphoe: "น้ำเกลี้ยง",
    province: "ศรีสะเกษ",
    zipcode: 33130,
    district_code: 330319,
    amphoe_code: 3315,
    province_code: 33,
  },
  {
    tambon: "บึงบูรพ์",
    amphoe: "บึงบูรพ์",
    province: "ศรีสะเกษ",
    zipcode: 33220,
    district_code: 331102,
    amphoe_code: 3311,
    province_code: 33,
  },
  {
    tambon: "เป๊าะ",
    amphoe: "บึงบูรพ์",
    province: "ศรีสะเกษ",
    zipcode: 33220,
    district_code: 331099,
    amphoe_code: 3311,
    province_code: 33,
  },
  {
    tambon: "กู่",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330702,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "ดู่",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330313,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "ตูม",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330499,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "พิมาย",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330701,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "พิมายเหนือ",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330710,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "สมอ",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330705,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "สวาย",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330709,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "สำโรงปราสาท",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330707,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "หนองเชียงทูน",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330703,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "โพธิ์ศรี",
    amphoe: "ปรางค์กู่",
    province: "ศรีสะเกษ",
    zipcode: 33170,
    district_code: 330706,
    amphoe_code: 3307,
    province_code: 33,
  },
  {
    tambon: "ตำแย",
    amphoe: "พยุห์",
    province: "ศรีสะเกษ",
    zipcode: 33230,
    district_code: 330114,
    amphoe_code: 3320,
    province_code: 33,
  },
  {
    tambon: "พยุห์",
    amphoe: "พยุห์",
    province: "ศรีสะเกษ",
    zipcode: 33230,
    district_code: 330110,
    amphoe_code: 3320,
    province_code: 33,
  },
  {
    tambon: "พรหมสวัสดิ์",
    amphoe: "พยุห์",
    province: "ศรีสะเกษ",
    zipcode: 33230,
    district_code: 330109,
    amphoe_code: 3320,
    province_code: 33,
  },
  {
    tambon: "หนองค้า",
    amphoe: "พยุห์",
    province: "ศรีสะเกษ",
    zipcode: 33230,
    district_code: 330128,
    amphoe_code: 3320,
    province_code: 33,
  },
  {
    tambon: "โนนเพ็ก",
    amphoe: "พยุห์",
    province: "ศรีสะเกษ",
    zipcode: 33230,
    district_code: 330108,
    amphoe_code: 3320,
    province_code: 33,
  },
  {
    tambon: "ดงรัก",
    amphoe: "ภูสิงห์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330523,
    amphoe_code: 3317,
    province_code: 33,
  },
  {
    tambon: "ตะเคียนราม",
    amphoe: "ภูสิงห์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330519,
    amphoe_code: 3317,
    province_code: 33,
  },
  {
    tambon: "ละลม",
    amphoe: "ภูสิงห์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330512,
    amphoe_code: 3317,
    province_code: 33,
  },
  {
    tambon: "ห้วยตามอญ",
    amphoe: "ภูสิงห์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330516,
    amphoe_code: 3317,
    province_code: 33,
  },
  {
    tambon: "ห้วยตึ๊กชู",
    amphoe: "ภูสิงห์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 331703,
    amphoe_code: 3317,
    province_code: 33,
  },
  {
    tambon: "โคกตาล",
    amphoe: "ภูสิงห์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 330514,
    amphoe_code: 3317,
    province_code: 33,
  },
  {
    tambon: "ไพรพัฒนา",
    amphoe: "ภูสิงห์",
    province: "ศรีสะเกษ",
    zipcode: 33140,
    district_code: 331707,
    amphoe_code: 3317,
    province_code: 33,
  },
  {
    tambon: "กุดเมืองฮาม",
    amphoe: "ยางชุมน้อย",
    province: "ศรีสะเกษ",
    zipcode: 33190,
    district_code: 330205,
    amphoe_code: 3302,
    province_code: 33,
  },
  {
    tambon: "คอนกาม",
    amphoe: "ยางชุมน้อย",
    province: "ศรีสะเกษ",
    zipcode: 33190,
    district_code: 330199,
    amphoe_code: 3302,
    province_code: 33,
  },
  {
    tambon: "บึงบอน",
    amphoe: "ยางชุมน้อย",
    province: "ศรีสะเกษ",
    zipcode: 33190,
    district_code: 330206,
    amphoe_code: 3302,
    province_code: 33,
  },
  {
    tambon: "ยางชุมน้อย",
    amphoe: "ยางชุมน้อย",
    province: "ศรีสะเกษ",
    zipcode: 33190,
    district_code: 330201,
    amphoe_code: 3302,
    province_code: 33,
  },
  {
    tambon: "ยางชุมใหญ่",
    amphoe: "ยางชุมน้อย",
    province: "ศรีสะเกษ",
    zipcode: 33190,
    district_code: 330207,
    amphoe_code: 3302,
    province_code: 33,
  },
  {
    tambon: "ลิ้นฟ้า",
    amphoe: "ยางชุมน้อย",
    province: "ศรีสะเกษ",
    zipcode: 33190,
    district_code: 330202,
    amphoe_code: 3302,
    province_code: 33,
  },
  {
    tambon: "โนนคูณ",
    amphoe: "ยางชุมน้อย",
    province: "ศรีสะเกษ",
    zipcode: 33190,
    district_code: 330204,
    amphoe_code: 3302,
    province_code: 33,
  },
  {
    tambon: "จิกสังข์ทอง",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330906,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "ดู่",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330313,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "ด่าน",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330907,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "บัวหุ่ง",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330910,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "สร้างปี่",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330915,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "ส้มป่อย",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330912,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "หนองหมี",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330913,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "หนองอึ่ง",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330909,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "หนองแค",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330903,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "หว้านคำ",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330914,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "เมืองคง",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330901,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "เมืองแคน",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330902,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "ไผ่",
    amphoe: "ราษีไศล",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330911,
    amphoe_code: 3309,
    province_code: 33,
  },
  {
    tambon: "ดวนใหญ่",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 330120,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "ทุ่งสว่าง",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 331606,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "ธาตุ",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 330113,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "บุสูง",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 330117,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "บ่อแก้ว",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 330125,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "วังหิน",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 331607,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "ศรีสำราญ",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 330126,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "โพนยาง",
    amphoe: "วังหิน",
    province: "ศรีสะเกษ",
    zipcode: 33270,
    district_code: 331608,
    amphoe_code: 3316,
    province_code: 33,
  },
  {
    tambon: "ตูม",
    amphoe: "ศรีรัตนะ",
    province: "ศรีสะเกษ",
    zipcode: 33240,
    district_code: 330499,
    amphoe_code: 3314,
    province_code: 33,
  },
  {
    tambon: "พิงพวย",
    amphoe: "ศรีรัตนะ",
    province: "ศรีสะเกษ",
    zipcode: 33240,
    district_code: 330497,
    amphoe_code: 3314,
    province_code: 33,
  },
  {
    tambon: "ศรีแก้ว",
    amphoe: "ศรีรัตนะ",
    province: "ศรีสะเกษ",
    zipcode: 33240,
    district_code: 330498,
    amphoe_code: 3314,
    province_code: 33,
  },
  {
    tambon: "ศรีโนนงาม",
    amphoe: "ศรีรัตนะ",
    province: "ศรีสะเกษ",
    zipcode: 33240,
    district_code: 331406,
    amphoe_code: 3314,
    province_code: 33,
  },
  {
    tambon: "สระเยาว์",
    amphoe: "ศรีรัตนะ",
    province: "ศรีสะเกษ",
    zipcode: 33240,
    district_code: 330496,
    amphoe_code: 3314,
    province_code: 33,
  },
  {
    tambon: "สะพุง",
    amphoe: "ศรีรัตนะ",
    province: "ศรีสะเกษ",
    zipcode: 33240,
    district_code: 331407,
    amphoe_code: 3314,
    province_code: 33,
  },
  {
    tambon: "เสื่องข้าว",
    amphoe: "ศรีรัตนะ",
    province: "ศรีสะเกษ",
    zipcode: 33240,
    district_code: 331405,
    amphoe_code: 3314,
    province_code: 33,
  },
  {
    tambon: "กุง",
    amphoe: "ศิลาลาด",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330904,
    amphoe_code: 3322,
    province_code: 33,
  },
  {
    tambon: "คลีกลิ้ง",
    amphoe: "ศิลาลาด",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330905,
    amphoe_code: 3322,
    province_code: 33,
  },
  {
    tambon: "หนองบัวดง",
    amphoe: "ศิลาลาด",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330917,
    amphoe_code: 3322,
    province_code: 33,
  },
  {
    tambon: "โจดม่วง",
    amphoe: "ศิลาลาด",
    province: "ศรีสะเกษ",
    zipcode: 33160,
    district_code: 330916,
    amphoe_code: 3322,
    province_code: 33,
  },
  {
    tambon: "กล้วยกว้าง",
    amphoe: "ห้วยทับทัน",
    province: "ศรีสะเกษ",
    zipcode: 33210,
    district_code: 331097,
    amphoe_code: 3312,
    province_code: 33,
  },
  {
    tambon: "จานแสนไชย",
    amphoe: "ห้วยทับทัน",
    province: "ศรีสะเกษ",
    zipcode: 33210,
    district_code: 331205,
    amphoe_code: 3312,
    province_code: 33,
  },
  {
    tambon: "ปราสาท",
    amphoe: "ห้วยทับทัน",
    province: "ศรีสะเกษ",
    zipcode: 33210,
    district_code: 330518,
    amphoe_code: 3312,
    province_code: 33,
  },
  {
    tambon: "ผักไหม",
    amphoe: "ห้วยทับทัน",
    province: "ศรีสะเกษ",
    zipcode: 33210,
    district_code: 331096,
    amphoe_code: 3312,
    province_code: 33,
  },
  {
    tambon: "ห้วยทับทัน",
    amphoe: "ห้วยทับทัน",
    province: "ศรีสะเกษ",
    zipcode: 33210,
    district_code: 331098,
    amphoe_code: 3312,
    province_code: 33,
  },
  {
    tambon: "เมืองหลวง",
    amphoe: "ห้วยทับทัน",
    province: "ศรีสะเกษ",
    zipcode: 33210,
    district_code: 331202,
    amphoe_code: 3312,
    province_code: 33,
  },
  {
    tambon: "กำแพง",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331001,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "ก้านเหลือง",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331003,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "ขะยูง",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331008,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "ตาเกษ",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331010,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "ทุ่งไชย",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331004,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "ปะอาว",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331017,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "รังแร้ง",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331012,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "สระกำแพงใหญ่",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331022,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "สำโรง",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331005,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "หนองห้าง",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331018,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "หนองไฮ",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 330119,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "หัวช้าง",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331011,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "อี่หล่ำ",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331002,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "แขม",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331006,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "แข้",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331015,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "แต้",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331014,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "โคกจาน",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331025,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "โคกหล่าม",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331024,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "โพธิ์ชัย",
    amphoe: "อุทุมพรพิสัย",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331016,
    amphoe_code: 3310,
    province_code: 33,
  },
  {
    tambon: "ท่าคล้อ",
    amphoe: "เบญจลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330418,
    amphoe_code: 3319,
    province_code: 33,
  },
  {
    tambon: "หนองงูเหลือม",
    amphoe: "เบญจลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330417,
    amphoe_code: 3319,
    province_code: 33,
  },
  {
    tambon: "หนองหว้า",
    amphoe: "เบญจลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330404,
    amphoe_code: 3319,
    province_code: 33,
  },
  {
    tambon: "หนองฮาง",
    amphoe: "เบญจลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330422,
    amphoe_code: 3319,
    province_code: 33,
  },
  {
    tambon: "เสียว",
    amphoe: "เบญจลักษ์",
    province: "ศรีสะเกษ",
    zipcode: 33110,
    district_code: 330410,
    amphoe_code: 3319,
    province_code: 33,
  },
  {
    tambon: "ตาโกน",
    amphoe: "เมืองจันทร์",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331009,
    amphoe_code: 3318,
    province_code: 33,
  },
  {
    tambon: "หนองใหญ่",
    amphoe: "เมืองจันทร์",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331023,
    amphoe_code: 3318,
    province_code: 33,
  },
  {
    tambon: "เมืองจันทร์",
    amphoe: "เมืองจันทร์",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331013,
    amphoe_code: 3318,
    province_code: 33,
  },
  {
    tambon: "คูซอด",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330103,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "จาน",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330105,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "ซำ",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330104,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "ตะดอบ",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330106,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "ทุ่ม",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330118,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "น้ำคำ",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330122,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "หญ้าปล้อง",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330116,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "หนองครก",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330107,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "หนองแก้ว",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330121,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330127,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "หนองไฮ",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330119,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "หมากเขียบ",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330124,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "เมืองเหนือ",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330101,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "เมืองใต้",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330102,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "โพธิ์",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330123,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "โพนข่า",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330111,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "โพนค้อ",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330112,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "โพนเขวา",
    amphoe: "เมืองศรีสะเกษ",
    province: "ศรีสะเกษ",
    zipcode: 33000,
    district_code: 330115,
    amphoe_code: 3301,
    province_code: 33,
  },
  {
    tambon: "บก",
    amphoe: "โนนคูณ",
    province: "ศรีสะเกษ",
    zipcode: 33250,
    district_code: 330398,
    amphoe_code: 3313,
    province_code: 33,
  },
  {
    tambon: "หนองกุง",
    amphoe: "โนนคูณ",
    province: "ศรีสะเกษ",
    zipcode: 33250,
    district_code: 330396,
    amphoe_code: 3313,
    province_code: 33,
  },
  {
    tambon: "เหล่ากวาง",
    amphoe: "โนนคูณ",
    province: "ศรีสะเกษ",
    zipcode: 33250,
    district_code: 331305,
    amphoe_code: 3313,
    province_code: 33,
  },
  {
    tambon: "โนนค้อ",
    amphoe: "โนนคูณ",
    province: "ศรีสะเกษ",
    zipcode: 33250,
    district_code: 330399,
    amphoe_code: 3313,
    province_code: 33,
  },
  {
    tambon: "โพธิ์",
    amphoe: "โนนคูณ",
    province: "ศรีสะเกษ",
    zipcode: 33250,
    district_code: 330123,
    amphoe_code: 3313,
    province_code: 33,
  },
  {
    tambon: "ผือใหญ่",
    amphoe: "โพธิ์ศรีสุวรรณ",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331026,
    amphoe_code: 3321,
    province_code: 33,
  },
  {
    tambon: "หนองม้า",
    amphoe: "โพธิ์ศรีสุวรรณ",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331021,
    amphoe_code: 3321,
    province_code: 33,
  },
  {
    tambon: "อีเซ",
    amphoe: "โพธิ์ศรีสุวรรณ",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331027,
    amphoe_code: 3321,
    province_code: 33,
  },
  {
    tambon: "เสียว",
    amphoe: "โพธิ์ศรีสุวรรณ",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 330410,
    amphoe_code: 3321,
    province_code: 33,
  },
  {
    tambon: "โดด",
    amphoe: "โพธิ์ศรีสุวรรณ",
    province: "ศรีสะเกษ",
    zipcode: 33120,
    district_code: 331019,
    amphoe_code: 3321,
    province_code: 33,
  },
  {
    tambon: "ดินแดง",
    amphoe: "ไพรบึง",
    province: "ศรีสะเกษ",
    zipcode: 33180,
    district_code: 330602,
    amphoe_code: 3306,
    province_code: 33,
  },
  {
    tambon: "ปราสาทเยอ",
    amphoe: "ไพรบึง",
    province: "ศรีสะเกษ",
    zipcode: 33180,
    district_code: 330603,
    amphoe_code: 3306,
    province_code: 33,
  },
  {
    tambon: "สำโรงพลัน",
    amphoe: "ไพรบึง",
    province: "ศรีสะเกษ",
    zipcode: 33180,
    district_code: 330604,
    amphoe_code: 3306,
    province_code: 33,
  },
  {
    tambon: "สุขสวัสดิ์",
    amphoe: "ไพรบึง",
    province: "ศรีสะเกษ",
    zipcode: 33180,
    district_code: 330605,
    amphoe_code: 3306,
    province_code: 33,
  },
  {
    tambon: "โนนปูน",
    amphoe: "ไพรบึง",
    province: "ศรีสะเกษ",
    zipcode: 33180,
    district_code: 330606,
    amphoe_code: 3306,
    province_code: 33,
  },
  {
    tambon: "ไพรบึง",
    amphoe: "ไพรบึง",
    province: "ศรีสะเกษ",
    zipcode: 33180,
    district_code: 330601,
    amphoe_code: 3306,
    province_code: 33,
  },
  {
    tambon: "กุดบาก",
    amphoe: "กุดบาก",
    province: "สกลนคร",
    zipcode: 47180,
    district_code: 470301,
    amphoe_code: 4703,
    province_code: 47,
  },
  {
    tambon: "กุดไห",
    amphoe: "กุดบาก",
    province: "สกลนคร",
    zipcode: 47180,
    district_code: 470305,
    amphoe_code: 4703,
    province_code: 47,
  },
  {
    tambon: "นาม่อง",
    amphoe: "กุดบาก",
    province: "สกลนคร",
    zipcode: 47180,
    district_code: 470303,
    amphoe_code: 4703,
    province_code: 47,
  },
  {
    tambon: "กุสุมาลย์",
    amphoe: "กุสุมาลย์",
    province: "สกลนคร",
    zipcode: 47210,
    district_code: 470201,
    amphoe_code: 4702,
    province_code: 47,
  },
  {
    tambon: "นาเพียง",
    amphoe: "กุสุมาลย์",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 470203,
    amphoe_code: 4702,
    province_code: 47,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "กุสุมาลย์",
    province: "สกลนคร",
    zipcode: 47210,
    district_code: 470202,
    amphoe_code: 4702,
    province_code: 47,
  },
  {
    tambon: "อุ่มจาน",
    amphoe: "กุสุมาลย์",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 470205,
    amphoe_code: 4702,
    province_code: 47,
  },
  {
    tambon: "โพธิ์ไพศาล",
    amphoe: "กุสุมาลย์",
    province: "สกลนคร",
    zipcode: 47210,
    district_code: false,
    amphoe_code: 4702,
    province_code: 47,
  },
  {
    tambon: "คำตากล้า",
    amphoe: "คำตากล้า",
    province: "สกลนคร",
    zipcode: 47250,
    district_code: 470901,
    amphoe_code: 4709,
    province_code: 47,
  },
  {
    tambon: "นาแต้",
    amphoe: "คำตากล้า",
    province: "สกลนคร",
    zipcode: 47250,
    district_code: 470903,
    amphoe_code: 4709,
    province_code: 47,
  },
  {
    tambon: "หนองบัวสิม",
    amphoe: "คำตากล้า",
    province: "สกลนคร",
    zipcode: 47250,
    district_code: 470902,
    amphoe_code: 4709,
    province_code: 47,
  },
  {
    tambon: "แพด",
    amphoe: "คำตากล้า",
    province: "สกลนคร",
    zipcode: 47250,
    district_code: 470904,
    amphoe_code: 4709,
    province_code: 47,
  },
  {
    tambon: "นิคมน้ำอูน",
    amphoe: "นิคมน้ำอูน",
    province: "สกลนคร",
    zipcode: 47270,
    district_code: 470701,
    amphoe_code: 4707,
    province_code: 47,
  },
  {
    tambon: "สุวรรณคาม",
    amphoe: "นิคมน้ำอูน",
    province: "สกลนคร",
    zipcode: 47270,
    district_code: 470704,
    amphoe_code: 4707,
    province_code: 47,
  },
  {
    tambon: "หนองบัว",
    amphoe: "นิคมน้ำอูน",
    province: "สกลนคร",
    zipcode: 47270,
    district_code: 470703,
    amphoe_code: 4707,
    province_code: 47,
  },
  {
    tambon: "หนองปลิง",
    amphoe: "นิคมน้ำอูน",
    province: "สกลนคร",
    zipcode: 47270,
    district_code: 470702,
    amphoe_code: 4707,
    province_code: 47,
  },
  {
    tambon: "ดงหม้อทอง",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471003,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "ดงหม้อทองใต้",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471005,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "ดงเหนือ",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471004,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "บ่อแก้ว",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471009,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "มาย",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471002,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "ม่วง",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471001,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "หนองกวั่ง",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471008,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "ห้วยหลัว",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471006,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "บ้านม่วง",
    province: "สกลนคร",
    zipcode: 47140,
    district_code: 471007,
    amphoe_code: 4710,
    province_code: 47,
  },
  {
    tambon: "ช้างมิ่ง",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470406,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "นาหัวบ่อ",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47220,
    district_code: 470404,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "นาใน",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470407,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "บะฮี",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470409,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "พรรณา",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470401,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "พอกน้อย",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47220,
    district_code: 470403,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "วังยาง",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470402,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "สว่าง",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470408,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "เชิงชุม",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470410,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "ไร่",
    amphoe: "พรรณานิคม",
    province: "สกลนคร",
    zipcode: 47130,
    district_code: 470405,
    amphoe_code: 4704,
    province_code: 47,
  },
  {
    tambon: "ต้นผึ้ง",
    amphoe: "พังโคน",
    province: "สกลนคร",
    zipcode: 47160,
    district_code: 470505,
    amphoe_code: 4705,
    province_code: 47,
  },
  {
    tambon: "พังโคน",
    amphoe: "พังโคน",
    province: "สกลนคร",
    zipcode: 47160,
    district_code: 470501,
    amphoe_code: 4705,
    province_code: 47,
  },
  {
    tambon: "ม่วงไข่",
    amphoe: "พังโคน",
    province: "สกลนคร",
    zipcode: 47160,
    district_code: 470502,
    amphoe_code: 4705,
    province_code: 47,
  },
  {
    tambon: "แร่",
    amphoe: "พังโคน",
    province: "สกลนคร",
    zipcode: 47160,
    district_code: 470503,
    amphoe_code: 4705,
    province_code: 47,
  },
  {
    tambon: "ไฮหย่อง",
    amphoe: "พังโคน",
    province: "สกลนคร",
    zipcode: 47160,
    district_code: 470504,
    amphoe_code: 4705,
    province_code: 47,
  },
  {
    tambon: "กกปลาซิว",
    amphoe: "ภูพาน",
    province: "สกลนคร",
    zipcode: 47180,
    district_code: 470110,
    amphoe_code: 4718,
    province_code: 47,
  },
  {
    tambon: "สร้างค้อ",
    amphoe: "ภูพาน",
    province: "สกลนคร",
    zipcode: 47180,
    district_code: 470304,
    amphoe_code: 4718,
    province_code: 47,
  },
  {
    tambon: "หลุบเลา",
    amphoe: "ภูพาน",
    province: "สกลนคร",
    zipcode: 47180,
    district_code: 470306,
    amphoe_code: 4718,
    province_code: 47,
  },
  {
    tambon: "โคกภู",
    amphoe: "ภูพาน",
    province: "สกลนคร",
    zipcode: 47180,
    district_code: 470302,
    amphoe_code: 4718,
    province_code: 47,
  },
  {
    tambon: "กุดเรือคำ",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470813,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "ขัวก่าย",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470803,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "คอนสวรรค์",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470812,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "คูสะคาม",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470805,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "ธาตุ",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470806,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "นาคำ",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470811,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "นาซอ",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470809,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "วานรนิวาส",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470801,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "ศรีวิชัย",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470808,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "หนองสนม",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470804,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "หนองแวง",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470807,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "หนองแวงใต้",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470814,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "อินทร์แปลง",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470810,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "เดื่อศรีคันไชย",
    amphoe: "วานรนิวาส",
    province: "สกลนคร",
    zipcode: 47120,
    district_code: 470802,
    amphoe_code: 4708,
    province_code: 47,
  },
  {
    tambon: "คำบ่อ",
    amphoe: "วาริชภูมิ",
    province: "สกลนคร",
    zipcode: 47150,
    district_code: 470604,
    amphoe_code: 4706,
    province_code: 47,
  },
  {
    tambon: "ค้อเขียว",
    amphoe: "วาริชภูมิ",
    province: "สกลนคร",
    zipcode: 47150,
    district_code: 470605,
    amphoe_code: 4706,
    province_code: 47,
  },
  {
    tambon: "ปลาโหล",
    amphoe: "วาริชภูมิ",
    province: "สกลนคร",
    zipcode: 47150,
    district_code: 470602,
    amphoe_code: 4706,
    province_code: 47,
  },
  {
    tambon: "วาริชภูมิ",
    amphoe: "วาริชภูมิ",
    province: "สกลนคร",
    zipcode: 47150,
    district_code: 470601,
    amphoe_code: 4706,
    province_code: 47,
  },
  {
    tambon: "หนองลาด",
    amphoe: "วาริชภูมิ",
    province: "สกลนคร",
    zipcode: 47150,
    district_code: 470118,
    amphoe_code: 4706,
    province_code: 47,
  },
  {
    tambon: "คำสะอาด",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471203,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "ค้อใต้",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471212,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "ตาลเนิ้ง",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47240,
    district_code: 471217,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "ตาลโกน",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47240,
    district_code: 471216,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "ทรายมูล",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471215,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "ธาตุทอง",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47240,
    district_code: 471220,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "บงเหนือ",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471206,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "บงใต้",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471211,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "บ้านต้าย",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471204,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "บ้านถ่อน",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471221,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "พันนา",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47240,
    district_code: 471213,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "สว่างแดนดิน",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471201,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471210,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "แวง",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47240,
    district_code: 471214,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "โคกสี",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471208,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "โพนสูง",
    amphoe: "สว่างแดนดิน",
    province: "สกลนคร",
    zipcode: 47110,
    district_code: 471207,
    amphoe_code: 4712,
    province_code: 47,
  },
  {
    tambon: "ท่าศิลา",
    amphoe: "ส่องดาว",
    province: "สกลนคร",
    zipcode: 47190,
    district_code: 471302,
    amphoe_code: 4713,
    province_code: 47,
  },
  {
    tambon: "ปทุมวาปี",
    amphoe: "ส่องดาว",
    province: "สกลนคร",
    zipcode: 47190,
    district_code: 471304,
    amphoe_code: 4713,
    province_code: 47,
  },
  {
    tambon: "วัฒนา",
    amphoe: "ส่องดาว",
    province: "สกลนคร",
    zipcode: 47190,
    district_code: 471303,
    amphoe_code: 4713,
    province_code: 47,
  },
  {
    tambon: "ส่องดาว",
    amphoe: "ส่องดาว",
    province: "สกลนคร",
    zipcode: 47190,
    district_code: 471301,
    amphoe_code: 4713,
    province_code: 47,
  },
  {
    tambon: "ท่าก้อน",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471105,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "นาฮี",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471106,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "บะหว้า",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471107,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "วาใหญ่",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471103,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "สามัคคีพัฒนา",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471108,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "อากาศ",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471101,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "โพนงาม",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471104,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "โพนแพง",
    amphoe: "อากาศอำนวย",
    province: "สกลนคร",
    zipcode: 47170,
    district_code: 471102,
    amphoe_code: 4711,
    province_code: 47,
  },
  {
    tambon: "ทุ่งแก",
    amphoe: "เจริญศิลป์",
    province: "สกลนคร",
    zipcode: 47290,
    district_code: 471209,
    amphoe_code: 4716,
    province_code: 47,
  },
  {
    tambon: "บ้านเหล่า",
    amphoe: "เจริญศิลป์",
    province: "สกลนคร",
    zipcode: 47290,
    district_code: 471202,
    amphoe_code: 4716,
    province_code: 47,
  },
  {
    tambon: "หนองแปน",
    amphoe: "เจริญศิลป์",
    province: "สกลนคร",
    zipcode: 47290,
    district_code: 471219,
    amphoe_code: 4716,
    province_code: 47,
  },
  {
    tambon: "เจริญศิลป์",
    amphoe: "เจริญศิลป์",
    province: "สกลนคร",
    zipcode: 47290,
    district_code: 471205,
    amphoe_code: 4716,
    province_code: 47,
  },
  {
    tambon: "โคกศิลา",
    amphoe: "เจริญศิลป์",
    province: "สกลนคร",
    zipcode: 47290,
    district_code: 471218,
    amphoe_code: 4716,
    province_code: 47,
  },
  {
    tambon: "จันทร์เพ็ญ",
    amphoe: "เต่างอย",
    province: "สกลนคร",
    zipcode: 47260,
    district_code: 471404,
    amphoe_code: 4714,
    province_code: 47,
  },
  {
    tambon: "นาตาล",
    amphoe: "เต่างอย",
    province: "สกลนคร",
    zipcode: 47260,
    district_code: 471403,
    amphoe_code: 4714,
    province_code: 47,
  },
  {
    tambon: "บึงทวาย",
    amphoe: "เต่างอย",
    province: "สกลนคร",
    zipcode: 47260,
    district_code: 471402,
    amphoe_code: 4714,
    province_code: 47,
  },
  {
    tambon: "เต่างอย",
    amphoe: "เต่างอย",
    province: "สกลนคร",
    zipcode: 47260,
    district_code: 470199,
    amphoe_code: 4714,
    province_code: 47,
  },
  {
    tambon: "ขมิ้น",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47220,
    district_code: 470102,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "งิ้วด่อน",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470103,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ดงชน",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470111,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ดงมะไฟ",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470115,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ท่าแร่",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 470107,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ธาตุนาเวง",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470116,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ธาตุเชิงชุม",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470101,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "พังขว้าง",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470113,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ม่วงลาย",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470109,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "หนองลาด",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47220,
    district_code: 470118,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ห้วยยาง",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470112,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ฮางโฮง",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470120,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "เชียงเครือ",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470106,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "เหล่าปอแดง",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470117,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "โคกก่อง",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470121,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "โนนหอม",
    amphoe: "เมืองสกลนคร",
    province: "สกลนคร",
    zipcode: 47000,
    district_code: 470104,
    amphoe_code: 4701,
    province_code: 47,
  },
  {
    tambon: "ด่านม่วงคำ",
    amphoe: "โคกศรีสุพรรณ",
    province: "สกลนคร",
    zipcode: 47280,
    district_code: 471503,
    amphoe_code: 4715,
    province_code: 47,
  },
  {
    tambon: "ตองโขบ",
    amphoe: "โคกศรีสุพรรณ",
    province: "สกลนคร",
    zipcode: 47280,
    district_code: 470198,
    amphoe_code: 4715,
    province_code: 47,
  },
  {
    tambon: "เหล่าโพนค้อ",
    amphoe: "โคกศรีสุพรรณ",
    province: "สกลนคร",
    zipcode: 47280,
    district_code: 470197,
    amphoe_code: 4715,
    province_code: 47,
  },
  {
    tambon: "แมดนาท่ม",
    amphoe: "โคกศรีสุพรรณ",
    province: "สกลนคร",
    zipcode: 47280,
    district_code: 471504,
    amphoe_code: 4715,
    province_code: 47,
  },
  {
    tambon: "นาตงวัฒนา",
    amphoe: "โพนนาแก้ว",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 470105,
    amphoe_code: 4717,
    province_code: 47,
  },
  {
    tambon: "นาแก้ว",
    amphoe: "โพนนาแก้ว",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 470114,
    amphoe_code: 4717,
    province_code: 47,
  },
  {
    tambon: "บ้านแป้น",
    amphoe: "โพนนาแก้ว",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 470119,
    amphoe_code: 4717,
    province_code: 47,
  },
  {
    tambon: "บ้านโพน",
    amphoe: "โพนนาแก้ว",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 470108,
    amphoe_code: 4717,
    province_code: 47,
  },
  {
    tambon: "เชียงสือ",
    amphoe: "โพนนาแก้ว",
    province: "สกลนคร",
    zipcode: 47230,
    district_code: 471705,
    amphoe_code: 4717,
    province_code: 47,
  },
  {
    tambon: "กระแสสินธุ์",
    amphoe: "กระแสสินธุ์",
    province: "สงขลา",
    zipcode: 90270,
    district_code: 900804,
    amphoe_code: 9008,
    province_code: 90,
  },
  {
    tambon: "เกาะใหญ่",
    amphoe: "กระแสสินธุ์",
    province: "สงขลา",
    zipcode: 90270,
    district_code: 900799,
    amphoe_code: 9008,
    province_code: 90,
  },
  {
    tambon: "เชิงแส",
    amphoe: "กระแสสินธุ์",
    province: "สงขลา",
    zipcode: 90270,
    district_code: 900797,
    amphoe_code: 9008,
    province_code: 90,
  },
  {
    tambon: "โรง",
    amphoe: "กระแสสินธุ์",
    province: "สงขลา",
    zipcode: 90270,
    district_code: 900798,
    amphoe_code: 9008,
    province_code: 90,
  },
  {
    tambon: "คลองหลา",
    amphoe: "คลองหอยโข่ง",
    province: "สงขลา",
    zipcode: 90115,
    district_code: 901604,
    amphoe_code: 9016,
    province_code: 90,
  },
  {
    tambon: "คลองหอยโข่ง",
    amphoe: "คลองหอยโข่ง",
    province: "สงขลา",
    zipcode: 90230,
    district_code: 901106,
    amphoe_code: 9016,
    province_code: 90,
  },
  {
    tambon: "ทุ่งลาน",
    amphoe: "คลองหอยโข่ง",
    province: "สงขลา",
    zipcode: 90230,
    district_code: 901109,
    amphoe_code: 9016,
    province_code: 90,
  },
  {
    tambon: "โคกม่วง",
    amphoe: "คลองหอยโข่ง",
    province: "สงขลา",
    zipcode: 90230,
    district_code: 901121,
    amphoe_code: 9016,
    province_code: 90,
  },
  {
    tambon: "โคกม่วง",
    amphoe: "คลองหอยโข่ง",
    province: "สงขลา",
    zipcode: 90115,
    district_code: 901121,
    amphoe_code: 9016,
    province_code: 90,
  },
  {
    tambon: "ควนโส",
    amphoe: "ควนเนียง",
    province: "สงขลา",
    zipcode: 90220,
    district_code: 900905,
    amphoe_code: 9013,
    province_code: 90,
  },
  {
    tambon: "บางเหรียง",
    amphoe: "ควนเนียง",
    province: "สงขลา",
    zipcode: 90220,
    district_code: 900907,
    amphoe_code: 9013,
    province_code: 90,
  },
  {
    tambon: "รัตภูมิ",
    amphoe: "ควนเนียง",
    province: "สงขลา",
    zipcode: 90220,
    district_code: 900906,
    amphoe_code: 9013,
    province_code: 90,
  },
  {
    tambon: "ห้วยลึก",
    amphoe: "ควนเนียง",
    province: "สงขลา",
    zipcode: 90220,
    district_code: 900908,
    amphoe_code: 9013,
    province_code: 90,
  },
  {
    tambon: "ขุนตัดหวาย",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900308,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "คลองเปียะ",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900313,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "คู",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900311,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "จะโหนง",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900310,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900314,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "ท่าหมอไทร",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900309,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "นาทับ",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900306,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "นาหว้า",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900305,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "น้ำขาว",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900307,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "บ้านนา",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900301,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "ป่าชิง",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900302,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "สะกอม",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900304,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "สะพานไม้แก่น",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900303,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "แค",
    amphoe: "จะนะ",
    province: "สงขลา",
    zipcode: 90130,
    district_code: 900312,
    amphoe_code: 9003,
    province_code: 90,
  },
  {
    tambon: "คลองกวาง",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900410,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "คลองทราย",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900404,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "ฉาง",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900402,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "ทับช้าง",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900408,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "ท่าประดู่",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900406,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "นาทวี",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900401,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "นาหมอศรี",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900403,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "ประกอบ",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900409,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "ปลักหนู",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900405,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "สะท้อน",
    amphoe: "นาทวี",
    province: "สงขลา",
    zipcode: 90160,
    district_code: 900407,
    amphoe_code: 9004,
    province_code: 90,
  },
  {
    tambon: "คลองหรัง",
    amphoe: "นาหม่อม",
    province: "สงขลา",
    zipcode: 90310,
    district_code: 901196,
    amphoe_code: 9012,
    province_code: 90,
  },
  {
    tambon: "ทุ่งขมิ้น",
    amphoe: "นาหม่อม",
    province: "สงขลา",
    zipcode: 90310,
    district_code: 901197,
    amphoe_code: 9012,
    province_code: 90,
  },
  {
    tambon: "นาหม่อม",
    amphoe: "นาหม่อม",
    province: "สงขลา",
    zipcode: 90310,
    district_code: 901199,
    amphoe_code: 9012,
    province_code: 90,
  },
  {
    tambon: "พิจิตร",
    amphoe: "นาหม่อม",
    province: "สงขลา",
    zipcode: 90310,
    district_code: 901198,
    amphoe_code: 9012,
    province_code: 90,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "บางกล่ำ",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901110,
    amphoe_code: 9014,
    province_code: 90,
  },
  {
    tambon: "บางกล่ำ",
    amphoe: "บางกล่ำ",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901115,
    amphoe_code: 9014,
    province_code: 90,
  },
  {
    tambon: "บ้านหาร",
    amphoe: "บางกล่ำ",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901117,
    amphoe_code: 9014,
    province_code: 90,
  },
  {
    tambon: "แม่ทอม",
    amphoe: "บางกล่ำ",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901119,
    amphoe_code: 9014,
    province_code: 90,
  },
  {
    tambon: "คลองแดน",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900702,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "ตะเครียะ",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900703,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "ท่าบอน",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900704,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "บ่อตรุ",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900706,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "บ้านขาว",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900711,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "บ้านใหม่",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900705,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "ปากแตระ",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900707,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "พังยาง",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900708,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "ระวะ",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900709,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "ระโนด",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900701,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "วัดสน",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900710,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "แดนสงวน",
    amphoe: "ระโนด",
    province: "สงขลา",
    zipcode: 90140,
    district_code: 900712,
    amphoe_code: 9007,
    province_code: 90,
  },
  {
    tambon: "กำแพงเพชร",
    amphoe: "รัตภูมิ",
    province: "สงขลา",
    zipcode: 90180,
    district_code: 900901,
    amphoe_code: 9009,
    province_code: 90,
  },
  {
    tambon: "ควนรู",
    amphoe: "รัตภูมิ",
    province: "สงขลา",
    zipcode: 90220,
    district_code: 900904,
    amphoe_code: 9009,
    province_code: 90,
  },
  {
    tambon: "คูหาใต้",
    amphoe: "รัตภูมิ",
    province: "สงขลา",
    zipcode: 90180,
    district_code: 900903,
    amphoe_code: 9009,
    province_code: 90,
  },
  {
    tambon: "ท่าชะมวง",
    amphoe: "รัตภูมิ",
    province: "สงขลา",
    zipcode: 90180,
    district_code: 900902,
    amphoe_code: 9009,
    province_code: 90,
  },
  {
    tambon: "เขาพระ",
    amphoe: "รัตภูมิ",
    province: "สงขลา",
    zipcode: 90180,
    district_code: 900909,
    amphoe_code: 9009,
    province_code: 90,
  },
  {
    tambon: "กระดังงา",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900202,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "คลองรี",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900206,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "คูขุด",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900207,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "จะทิ้งพระ",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900201,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "ชุมพล",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900205,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "ดีหลวง",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900204,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "ท่าหิน",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900208,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "บ่อดาน",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900211,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "บ่อแดง",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900210,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "วัดจันทร์",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900209,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "สนามชัย",
    amphoe: "สทิงพระ",
    province: "สงขลา",
    zipcode: 90190,
    district_code: 900203,
    amphoe_code: 9002,
    province_code: 90,
  },
  {
    tambon: "คูหา",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900606,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "จะแหน",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900605,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "ทุ่งพอ",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900602,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "ธารคีรี",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900609,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "บาโหย",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900608,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "บ้านโหนด",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900604,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "สะบ้าย้อย",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900601,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "เขาแดง",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900607,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "เปียน",
    amphoe: "สะบ้าย้อย",
    province: "สงขลา",
    zipcode: 90210,
    district_code: 900603,
    amphoe_code: 9006,
    province_code: 90,
  },
  {
    tambon: "ทุ่งหมอ",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90240,
    district_code: 901005,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "ท่าโพธิ์",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90170,
    district_code: 901006,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "ปริก",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90120,
    district_code: 901002,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "ปาดังเบซาร์",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90240,
    district_code: 901007,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "พังลา",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90170,
    district_code: 901003,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "สะเดา",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90120,
    district_code: 901001,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "สำนักขาม",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90320,
    district_code: 901008,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "สำนักแต้ว",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90120,
    district_code: 901004,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "เขามีเกียรติ",
    amphoe: "สะเดา",
    province: "สงขลา",
    zipcode: 90170,
    district_code: 901009,
    amphoe_code: 9010,
    province_code: 90,
  },
  {
    tambon: "ชะแล้",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90330,
    district_code: 900112,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "ชิงโค",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90280,
    district_code: 900107,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "ทำนบ",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90280,
    district_code: 900109,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "บางเขียด",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90330,
    district_code: 900116,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "ปากรอ",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90330,
    district_code: 900113,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "ป่าขาด",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90330,
    district_code: 900114,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "ม่วงงาม",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90330,
    district_code: 900117,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "รำแดง",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90330,
    district_code: 900110,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "วัดขนุน",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90330,
    district_code: 900111,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "สทิงหม้อ",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90280,
    district_code: 900108,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "หัวเขา",
    amphoe: "สิงหนคร",
    province: "สงขลา",
    zipcode: 90280,
    district_code: 900115,
    amphoe_code: 9015,
    province_code: 90,
  },
  {
    tambon: "คลองอู่ตะเภา",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901107,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "คลองแห",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901105,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "ควนลัง",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901102,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "คอหงส์",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901104,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "คูเต่า",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901103,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "ฉลุง",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901108,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "ทุ่งตำเสา",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901112,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "ทุ่งใหญ่",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901111,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901113,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "น้ำน้อย",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901114,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "บ้านพรุ",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90250,
    district_code: 901116,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "พะตง",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90230,
    district_code: 901118,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "หาดใหญ่",
    amphoe: "หาดใหญ่",
    province: "สงขลา",
    zipcode: 90110,
    district_code: 901101,
    amphoe_code: 9011,
    province_code: 90,
  },
  {
    tambon: "ท่าม่วง",
    amphoe: "เทพา",
    province: "สงขลา",
    zipcode: 90150,
    district_code: 900505,
    amphoe_code: 9005,
    province_code: 90,
  },
  {
    tambon: "ปากบาง",
    amphoe: "เทพา",
    province: "สงขลา",
    zipcode: 90150,
    district_code: 900502,
    amphoe_code: 9005,
    province_code: 90,
  },
  {
    tambon: "ลำไพล",
    amphoe: "เทพา",
    province: "สงขลา",
    zipcode: 90260,
    district_code: 900504,
    amphoe_code: 9005,
    province_code: 90,
  },
  {
    tambon: "วังใหญ่",
    amphoe: "เทพา",
    province: "สงขลา",
    zipcode: 90260,
    district_code: 900506,
    amphoe_code: 9005,
    province_code: 90,
  },
  {
    tambon: "สะกอม",
    amphoe: "เทพา",
    province: "สงขลา",
    zipcode: 90150,
    district_code: 900304,
    amphoe_code: 9005,
    province_code: 90,
  },
  {
    tambon: "เกาะสะบ้า",
    amphoe: "เทพา",
    province: "สงขลา",
    zipcode: 90150,
    district_code: 900503,
    amphoe_code: 9005,
    province_code: 90,
  },
  {
    tambon: "เทพา",
    amphoe: "เทพา",
    province: "สงขลา",
    zipcode: 90150,
    district_code: 900501,
    amphoe_code: 9005,
    province_code: 90,
  },
  {
    tambon: "ทุ่งหวัง",
    amphoe: "เมืองสงขลา",
    province: "สงขลา",
    zipcode: 90000,
    district_code: 900105,
    amphoe_code: 9001,
    province_code: 90,
  },
  {
    tambon: "บ่อยาง",
    amphoe: "เมืองสงขลา",
    province: "สงขลา",
    zipcode: 90000,
    district_code: 900101,
    amphoe_code: 9001,
    province_code: 90,
  },
  {
    tambon: "พะวง",
    amphoe: "เมืองสงขลา",
    province: "สงขลา",
    zipcode: 90100,
    district_code: 900104,
    amphoe_code: 9001,
    province_code: 90,
  },
  {
    tambon: "เกาะยอ",
    amphoe: "เมืองสงขลา",
    province: "สงขลา",
    zipcode: 90100,
    district_code: 900106,
    amphoe_code: 9001,
    province_code: 90,
  },
  {
    tambon: "เกาะแต้ว",
    amphoe: "เมืองสงขลา",
    province: "สงขลา",
    zipcode: 90000,
    district_code: 900103,
    amphoe_code: 9001,
    province_code: 90,
  },
  {
    tambon: "เขารูปช้าง",
    amphoe: "เมืองสงขลา",
    province: "สงขลา",
    zipcode: 90000,
    district_code: 900102,
    amphoe_code: 9001,
    province_code: 90,
  },
  {
    tambon: "ควนกาหลง",
    amphoe: "ควนกาหลง",
    province: "สตูล",
    zipcode: 91130,
    district_code: 910302,
    amphoe_code: 9103,
    province_code: 91,
  },
  {
    tambon: "ทุ่งนุ้ย",
    amphoe: "ควนกาหลง",
    province: "สตูล",
    zipcode: 91130,
    district_code: 910301,
    amphoe_code: 9103,
    province_code: 91,
  },
  {
    tambon: "อุไดเจริญ",
    amphoe: "ควนกาหลง",
    province: "สตูล",
    zipcode: 91130,
    district_code: false,
    amphoe_code: 9103,
    province_code: 91,
  },
  {
    tambon: "ควนสตอ",
    amphoe: "ควนโดน",
    province: "สตูล",
    zipcode: 91160,
    district_code: 910202,
    amphoe_code: 9102,
    province_code: 91,
  },
  {
    tambon: "ควนโดน",
    amphoe: "ควนโดน",
    province: "สตูล",
    zipcode: 91160,
    district_code: 910201,
    amphoe_code: 9102,
    province_code: 91,
  },
  {
    tambon: "ย่านซื่อ",
    amphoe: "ควนโดน",
    province: "สตูล",
    zipcode: 91160,
    district_code: 910203,
    amphoe_code: 9102,
    province_code: 91,
  },
  {
    tambon: "วังประจัน",
    amphoe: "ควนโดน",
    province: "สตูล",
    zipcode: 91160,
    district_code: 910204,
    amphoe_code: 9102,
    province_code: 91,
  },
  {
    tambon: "ขอนคลาน",
    amphoe: "ทุ่งหว้า",
    province: "สตูล",
    zipcode: 91120,
    district_code: 910603,
    amphoe_code: 9106,
    province_code: 91,
  },
  {
    tambon: "ทุ่งบุหลัง",
    amphoe: "ทุ่งหว้า",
    province: "สตูล",
    zipcode: 91120,
    district_code: 910604,
    amphoe_code: 9106,
    province_code: 91,
  },
  {
    tambon: "ทุ่งหว้า",
    amphoe: "ทุ่งหว้า",
    province: "สตูล",
    zipcode: 91120,
    district_code: 910601,
    amphoe_code: 9106,
    province_code: 91,
  },
  {
    tambon: "นาทอน",
    amphoe: "ทุ่งหว้า",
    province: "สตูล",
    zipcode: 91120,
    district_code: 910602,
    amphoe_code: 9106,
    province_code: 91,
  },
  {
    tambon: "ป่าแก่บ่อหิน",
    amphoe: "ทุ่งหว้า",
    province: "สตูล",
    zipcode: 91120,
    district_code: 910605,
    amphoe_code: 9106,
    province_code: 91,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "ท่าแพ",
    province: "สตูล",
    zipcode: 91150,
    district_code: 910404,
    amphoe_code: 9104,
    province_code: 91,
  },
  {
    tambon: "ท่าแพ",
    amphoe: "ท่าแพ",
    province: "สตูล",
    zipcode: 91150,
    district_code: 910199,
    amphoe_code: 9104,
    province_code: 91,
  },
  {
    tambon: "สาคร",
    amphoe: "ท่าแพ",
    province: "สตูล",
    zipcode: 91150,
    district_code: 910403,
    amphoe_code: 9104,
    province_code: 91,
  },
  {
    tambon: "แป-ระ",
    amphoe: "ท่าแพ",
    province: "สตูล",
    zipcode: 91150,
    district_code: 910402,
    amphoe_code: 9104,
    province_code: 91,
  },
  {
    tambon: "นิคมพัฒนา",
    amphoe: "มะนัง",
    province: "สตูล",
    zipcode: 91130,
    district_code: 910304,
    amphoe_code: 9107,
    province_code: 91,
  },
  {
    tambon: "ปาล์มพัฒนา",
    amphoe: "มะนัง",
    province: "สตูล",
    zipcode: 91130,
    district_code: 910305,
    amphoe_code: 9107,
    province_code: 91,
  },
  {
    tambon: "กำแพง",
    amphoe: "ละงู",
    province: "สตูล",
    zipcode: 91110,
    district_code: 910501,
    amphoe_code: 9105,
    province_code: 91,
  },
  {
    tambon: "น้ำผุด",
    amphoe: "ละงู",
    province: "สตูล",
    zipcode: 91110,
    district_code: 910505,
    amphoe_code: 9105,
    province_code: 91,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "ละงู",
    province: "สตูล",
    zipcode: 91110,
    district_code: 910504,
    amphoe_code: 9105,
    province_code: 91,
  },
  {
    tambon: "ละงู",
    amphoe: "ละงู",
    province: "สตูล",
    zipcode: 91110,
    district_code: 910502,
    amphoe_code: 9105,
    province_code: 91,
  },
  {
    tambon: "เขาขาว",
    amphoe: "ละงู",
    province: "สตูล",
    zipcode: 91110,
    district_code: 910503,
    amphoe_code: 9105,
    province_code: 91,
  },
  {
    tambon: "แหลมสน",
    amphoe: "ละงู",
    province: "สตูล",
    zipcode: 91110,
    district_code: 910506,
    amphoe_code: 9105,
    province_code: 91,
  },
  {
    tambon: "คลองขุด",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910102,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "ควนขัน",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910103,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "ควนโพธิ์",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91140,
    district_code: 910111,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "ฉลุง",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91140,
    district_code: 910105,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "ตันหยงโป",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910107,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "ตำมะลัง",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910109,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "บ้านควน",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91140,
    district_code: 910104,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "ปูยู",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910110,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "พิมาน",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910101,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "เกตรี",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91140,
    district_code: 910112,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "เกาะสาหร่าย",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910106,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "เจ๊ะบิลัง",
    amphoe: "เมืองสตูล",
    province: "สตูล",
    zipcode: 91000,
    district_code: 910108,
    amphoe_code: 9101,
    province_code: 91,
  },
  {
    tambon: "คลองด่าน",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10550,
    district_code: 110205,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "คลองนิยมยาตรา",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10560,
    district_code: 110208,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "คลองสวน",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10560,
    district_code: 110206,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "บางบ่อ",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10560,
    district_code: 110201,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "บางพลีน้อย",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10560,
    district_code: 110203,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "บางเพรียง",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10560,
    district_code: 110204,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "บ้านระกาศ",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10560,
    district_code: 110202,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "เปร็ง",
    amphoe: "บางบ่อ",
    province: "สมุทรปราการ",
    zipcode: 10560,
    district_code: 110207,
    amphoe_code: 1102,
    province_code: 11,
  },
  {
    tambon: "บางปลา",
    amphoe: "บางพลี",
    province: "สมุทรปราการ",
    zipcode: 10540,
    district_code: 110303,
    amphoe_code: 1103,
    province_code: 11,
  },
  {
    tambon: "บางพลีใหญ่",
    amphoe: "บางพลี",
    province: "สมุทรปราการ",
    zipcode: 10540,
    district_code: 110301,
    amphoe_code: 1103,
    province_code: 11,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "บางพลี",
    province: "สมุทรปราการ",
    zipcode: 10540,
    district_code: 110302,
    amphoe_code: 1103,
    province_code: 11,
  },
  {
    tambon: "บางโฉลง",
    amphoe: "บางพลี",
    province: "สมุทรปราการ",
    zipcode: 10540,
    district_code: 110304,
    amphoe_code: 1103,
    province_code: 11,
  },
  {
    tambon: "ราชาเทวะ",
    amphoe: "บางพลี",
    province: "สมุทรปราการ",
    zipcode: 10540,
    district_code: 110308,
    amphoe_code: 1103,
    province_code: 11,
  },
  {
    tambon: "หนองปรือ",
    amphoe: "บางพลี",
    province: "สมุทรปราการ",
    zipcode: 10540,
    district_code: 110309,
    amphoe_code: 1103,
    province_code: 11,
  },
  {
    tambon: "บางเสาธง",
    amphoe: "บางเสาธง",
    province: "สมุทรปราการ",
    zipcode: 10570,
    district_code: 110305,
    amphoe_code: 1106,
    province_code: 11,
  },
  {
    tambon: "ศีรษะจรเข้น้อย",
    amphoe: "บางเสาธง",
    province: "สมุทรปราการ",
    zipcode: 10570,
    district_code: 110602,
    amphoe_code: 1106,
    province_code: 11,
  },
  {
    tambon: "ศีรษะจรเข้ใหญ่",
    amphoe: "บางเสาธง",
    province: "สมุทรปราการ",
    zipcode: 10570,
    district_code: 110603,
    amphoe_code: 1106,
    province_code: 11,
  },
  {
    tambon: "ตลาด",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110401,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "ทรงคนอง",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110413,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางกระสอบ",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110411,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางกอบัว",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110412,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางกะเจ้า",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110409,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางครุ",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110404,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางจาก",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110403,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางน้ำผึ้ง",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110410,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางพึ่ง",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110402,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางยอ",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110408,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางหญ้าแพรก",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110405,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "บางหัวเสือ",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110406,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "สำโรง",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110414,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "สำโรงกลาง",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110415,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "สำโรงใต้",
    amphoe: "พระประแดง",
    province: "สมุทรปราการ",
    zipcode: 10130,
    district_code: 110407,
    amphoe_code: 1104,
    province_code: 11,
  },
  {
    tambon: "นาเกลือ",
    amphoe: "พระสมุทรเจดีย์",
    province: "สมุทรปราการ",
    zipcode: 10290,
    district_code: 110105,
    amphoe_code: 1105,
    province_code: 11,
  },
  {
    tambon: "บ้านคลองสวน",
    amphoe: "พระสมุทรเจดีย์",
    province: "สมุทรปราการ",
    zipcode: 10290,
    district_code: 110198,
    amphoe_code: 1105,
    province_code: 11,
  },
  {
    tambon: "ปากคลองบางปลากด",
    amphoe: "พระสมุทรเจดีย์",
    province: "สมุทรปราการ",
    zipcode: 10290,
    district_code: 110109,
    amphoe_code: 1105,
    province_code: 11,
  },
  {
    tambon: "แหลมฟ้าผ่า",
    amphoe: "พระสมุทรเจดีย์",
    province: "สมุทรปราการ",
    zipcode: 10290,
    district_code: 110106,
    amphoe_code: 1105,
    province_code: 11,
  },
  {
    tambon: "ในคลองบางปลากด",
    amphoe: "พระสมุทรเจดีย์",
    province: "สมุทรปราการ",
    zipcode: 10290,
    district_code: 110107,
    amphoe_code: 1105,
    province_code: 11,
  },
  {
    tambon: "ท้ายบ้าน",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10280,
    district_code: 110104,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "ท้ายบ้านใหม่",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10280,
    district_code: 110116,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "บางด้วน",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10270,
    district_code: 110113,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "บางปู",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10280,
    district_code: 110112,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "บางปูใหม่",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10280,
    district_code: 110108,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "บางเมือง",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10270,
    district_code: 110103,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "บางเมืองใหม่",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10270,
    district_code: 110114,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "บางโปรง",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10270,
    district_code: 110111,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10270,
    district_code: 110101,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "สำโรงเหนือ",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10270,
    district_code: 110102,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "เทพารักษ์",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10270,
    district_code: 110115,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "แพรกษา",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10280,
    district_code: 110110,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "แพรกษาใหม่",
    amphoe: "เมืองสมุทรปราการ",
    province: "สมุทรปราการ",
    zipcode: 10280,
    district_code: 110117,
    amphoe_code: 1101,
    province_code: 11,
  },
  {
    tambon: "กระดังงา",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750201,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "จอมปลวก",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750209,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "ดอนมะโนรา",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750206,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บางกระบือ",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750212,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บางกุ้ง",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750208,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บางคนที",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750205,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บางนกแขวก",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750210,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บางพรม",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750207,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บางยี่รงค์",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750203,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บางสะแก",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750202,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "บ้านปราโมทย์",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750213,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "ยายแพง",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750211,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "โรงหีบ",
    amphoe: "บางคนที",
    province: "สมุทรสงคราม",
    zipcode: 75120,
    district_code: 750204,
    amphoe_code: 7502,
    province_code: 75,
  },
  {
    tambon: "ท่าคา",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750303,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "บางช้าง",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750306,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "บางนางลี่",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750312,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "บางแค",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750309,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "ปลายโพงพาง",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750308,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "ยี่สาร",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750311,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "วัดประดู่",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750304,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "สวนหลวง",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750302,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "อัมพวา",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750301,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "เหมืองใหม่",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750305,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "แควอ้อม",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750307,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "แพรกหนามแดง",
    amphoe: "อัมพวา",
    province: "สมุทรสงคราม",
    zipcode: 75110,
    district_code: 750310,
    amphoe_code: 7503,
    province_code: 75,
  },
  {
    tambon: "คลองเขิน",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750108,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "คลองโคน",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750109,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "ท้ายหาด",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750106,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "นางตะเคียน",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750110,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "บางขันแตก",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750102,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "บางจะเกร็ง",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750111,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750105,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "บ้านปรก",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750104,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "ลาดใหญ่",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750103,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "แม่กลอง",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750101,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "แหลมใหญ่",
    amphoe: "เมืองสมุทรสงคราม",
    province: "สมุทรสงคราม",
    zipcode: 75000,
    district_code: 750107,
    amphoe_code: 7501,
    province_code: 75,
  },
  {
    tambon: "คลองมะเดื่อ",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740206,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "ดอนไก่ดี",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740208,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "ตลาดกระทุ่มแบน",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740201,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "ท่าเสา",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740210,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "ท่าไม้",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740203,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "บางยาง",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740205,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "สวนหลวง",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740204,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "หนองนกไข่",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740207,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "อ้อมน้อย",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74130,
    district_code: 740202,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "แคราย",
    amphoe: "กระทุ่มแบน",
    province: "สมุทรสาคร",
    zipcode: 74110,
    district_code: 740209,
    amphoe_code: 7402,
    province_code: 74,
  },
  {
    tambon: "คลองตัน",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740309,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "บ้านแพ้ว",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740301,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "ยกกระบัตร",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740303,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "สวนส้ม",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740311,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "หนองบัว",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740306,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "หนองสองห้อง",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740305,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "หลักสอง",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740307,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "หลักสาม",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740302,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "อำแพง",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740310,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "เกษตรพัฒนา",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740312,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "เจ็ดริ้ว",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740308,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "โรงเข้",
    amphoe: "บ้านแพ้ว",
    province: "สมุทรสาคร",
    zipcode: 74120,
    district_code: 740304,
    amphoe_code: 7403,
    province_code: 74,
  },
  {
    tambon: "กาหลง",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740106,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "คอกกระบือ",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740111,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "ชัยมงคล",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740118,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "ท่าจีน",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740108,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "ท่าฉลอม",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740102,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "ท่าทราย",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740110,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "นาดี",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740109,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "นาโคก",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740107,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "บางกระเจ้า",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740116,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "บางน้ำจืด",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740112,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "บางหญ้าแพรก",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740117,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "บางโทรัด",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740105,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "บ้านบ่อ",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740104,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "บ้านเกาะ",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740115,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "พันท้ายนรสิงห์",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740113,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "มหาชัย",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740101,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "โกรกกราก",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740103,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "โคกขาม",
    amphoe: "เมืองสมุทรสาคร",
    province: "สมุทรสาคร",
    zipcode: 74000,
    district_code: 740114,
    amphoe_code: 7401,
    province_code: 74,
  },
  {
    tambon: "ดงตะงาว",
    amphoe: "ดอนพุด",
    province: "สระบุรี",
    zipcode: 18210,
    district_code: 190696,
    amphoe_code: 1907,
    province_code: 19,
  },
  {
    tambon: "ดอนพุด",
    amphoe: "ดอนพุด",
    province: "สระบุรี",
    zipcode: 18210,
    district_code: 190699,
    amphoe_code: 1907,
    province_code: 19,
  },
  {
    tambon: "บ้านหลวง",
    amphoe: "ดอนพุด",
    province: "สระบุรี",
    zipcode: 18210,
    district_code: 190697,
    amphoe_code: 1907,
    province_code: 19,
  },
  {
    tambon: "ไผ่หลิ่ว",
    amphoe: "ดอนพุด",
    province: "สระบุรี",
    zipcode: 18210,
    district_code: 190698,
    amphoe_code: 1907,
    province_code: 19,
  },
  {
    tambon: "ตลาดน้อย",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190604,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "บางโขมด",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190602,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "บ้านครัว",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18270,
    district_code: 190608,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "บ้านหมอ",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190601,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "สร่างโศก",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190603,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "หนองบัว",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190609,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "หรเทพ",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190605,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "โคกใหญ่",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190606,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "ไผ่ขวาง",
    amphoe: "บ้านหมอ",
    province: "สระบุรี",
    zipcode: 18130,
    district_code: 190607,
    amphoe_code: 1906,
    province_code: 19,
  },
  {
    tambon: "ขุนโขลน",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190902,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "ธารเกษม",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190903,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "นายาว",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190904,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "พระพุทธบาท",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190901,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "พุกร่าง",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190908,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "พุคำจาน",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190905,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "หนองแก",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190909,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "ห้วยป่าหวาย",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190907,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "เขาวง",
    amphoe: "พระพุทธบาท",
    province: "สระบุรี",
    zipcode: 18120,
    district_code: 190906,
    amphoe_code: 1909,
    province_code: 19,
  },
  {
    tambon: "ซับสนุ่น",
    amphoe: "มวกเหล็ก",
    province: "สระบุรี",
    zipcode: 18220,
    district_code: 191109,
    amphoe_code: 1911,
    province_code: 19,
  },
  {
    tambon: "มวกเหล็ก",
    amphoe: "มวกเหล็ก",
    province: "สระบุรี",
    zipcode: 18180,
    district_code: 191101,
    amphoe_code: 1911,
    province_code: 19,
  },
  {
    tambon: "มิตรภาพ",
    amphoe: "มวกเหล็ก",
    province: "สระบุรี",
    zipcode: 18180,
    district_code: 191102,
    amphoe_code: 1911,
    province_code: 19,
  },
  {
    tambon: "ลำพญากลาง",
    amphoe: "มวกเหล็ก",
    province: "สระบุรี",
    zipcode: 30130,
    district_code: 191107,
    amphoe_code: 1911,
    province_code: 19,
  },
  {
    tambon: "ลำสมพุง",
    amphoe: "มวกเหล็ก",
    province: "สระบุรี",
    zipcode: 30130,
    district_code: 191105,
    amphoe_code: 1911,
    province_code: 19,
  },
  {
    tambon: "หนองย่างเสือ",
    amphoe: "มวกเหล็ก",
    province: "สระบุรี",
    zipcode: 18180,
    district_code: 191104,
    amphoe_code: 1911,
    province_code: 19,
  },
  {
    tambon: "คำพราน",
    amphoe: "วังม่วง",
    province: "สระบุรี",
    zipcode: 18220,
    district_code: 191106,
    amphoe_code: 1912,
    province_code: 19,
  },
  {
    tambon: "วังม่วง",
    amphoe: "วังม่วง",
    province: "สระบุรี",
    zipcode: 18220,
    district_code: 191108,
    amphoe_code: 1912,
    province_code: 19,
  },
  {
    tambon: "แสลงพัน",
    amphoe: "วังม่วง",
    province: "สระบุรี",
    zipcode: 18220,
    district_code: 191103,
    amphoe_code: 1912,
    province_code: 19,
  },
  {
    tambon: "คลองเรือ",
    amphoe: "วิหารแดง",
    province: "สระบุรี",
    zipcode: 18150,
    district_code: 190403,
    amphoe_code: 1904,
    province_code: 19,
  },
  {
    tambon: "บ้านลำ",
    amphoe: "วิหารแดง",
    province: "สระบุรี",
    zipcode: 18150,
    district_code: 190402,
    amphoe_code: 1904,
    province_code: 19,
  },
  {
    tambon: "วิหารแดง",
    amphoe: "วิหารแดง",
    province: "สระบุรี",
    zipcode: 18150,
    district_code: 190404,
    amphoe_code: 1904,
    province_code: 19,
  },
  {
    tambon: "หนองสรวง",
    amphoe: "วิหารแดง",
    province: "สระบุรี",
    zipcode: 18150,
    district_code: 190405,
    amphoe_code: 1904,
    province_code: 19,
  },
  {
    tambon: "หนองหมู",
    amphoe: "วิหารแดง",
    province: "สระบุรี",
    zipcode: 18150,
    district_code: 190401,
    amphoe_code: 1904,
    province_code: 19,
  },
  {
    tambon: "เจริญธรรม",
    amphoe: "วิหารแดง",
    province: "สระบุรี",
    zipcode: 18150,
    district_code: 190406,
    amphoe_code: 1904,
    province_code: 19,
  },
  {
    tambon: "กุ่มหัก",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190302,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "คชสิทธิ์",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18250,
    district_code: 190303,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "บัวลอย",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18230,
    district_code: 190306,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองจรเข้",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190314,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองจิก",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18230,
    district_code: 190313,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองนาก",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18230,
    district_code: 190315,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองปลาหมอ",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190316,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองปลิง",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190317,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองแขม",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190312,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองแค",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190301,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองโรง",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190318,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "หนองไข่น้ำ",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190311,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "ห้วยขมิ้น",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18230,
    district_code: 190309,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "ห้วยทราย",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18230,
    district_code: 190310,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "โคกตูม",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18250,
    district_code: 190304,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "โคกแย้",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18230,
    district_code: 190305,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "โพนทอง",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18250,
    district_code: 190308,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "ไผ่ต่ำ",
    amphoe: "หนองแค",
    province: "สระบุรี",
    zipcode: 18140,
    district_code: 190307,
    amphoe_code: 1903,
    province_code: 19,
  },
  {
    tambon: "ม่วงหวาน",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190508,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "หนองกบ",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190505,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "หนองควายโซ",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190502,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "หนองสีดา",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190504,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "หนองหัวโพ",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190503,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "หนองแซง",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190501,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "เขาดิน",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190509,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190507,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "ไก่เส่า",
    amphoe: "หนองแซง",
    province: "สระบุรี",
    zipcode: 18170,
    district_code: 190506,
    amphoe_code: 1905,
    province_code: 19,
  },
  {
    tambon: "ดอนทอง",
    amphoe: "หนองโดน",
    province: "สระบุรี",
    zipcode: 18190,
    district_code: 190803,
    amphoe_code: 1908,
    province_code: 19,
  },
  {
    tambon: "บ้านกลับ",
    amphoe: "หนองโดน",
    province: "สระบุรี",
    zipcode: 18190,
    district_code: 190802,
    amphoe_code: 1908,
    province_code: 19,
  },
  {
    tambon: "บ้านโปร่ง",
    amphoe: "หนองโดน",
    province: "สระบุรี",
    zipcode: 18190,
    district_code: 190804,
    amphoe_code: 1908,
    province_code: 19,
  },
  {
    tambon: "หนองโดน",
    amphoe: "หนองโดน",
    province: "สระบุรี",
    zipcode: 18190,
    district_code: 190801,
    amphoe_code: 1908,
    province_code: 19,
  },
  {
    tambon: "บ้านแก้ง",
    amphoe: "เฉลิมพระเกียรติ",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190115,
    amphoe_code: 1913,
    province_code: 19,
  },
  {
    tambon: "ผึ้งรวง",
    amphoe: "เฉลิมพระเกียรติ",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190116,
    amphoe_code: 1913,
    province_code: 19,
  },
  {
    tambon: "พุแค",
    amphoe: "เฉลิมพระเกียรติ",
    province: "สระบุรี",
    zipcode: 18240,
    district_code: 190103,
    amphoe_code: 1913,
    province_code: 19,
  },
  {
    tambon: "หน้าพระลาน",
    amphoe: "เฉลิมพระเกียรติ",
    province: "สระบุรี",
    zipcode: 18240,
    district_code: 190102,
    amphoe_code: 1913,
    province_code: 19,
  },
  {
    tambon: "ห้วยบง",
    amphoe: "เฉลิมพระเกียรติ",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190104,
    amphoe_code: 1913,
    province_code: 19,
  },
  {
    tambon: "เขาดินพัฒนา",
    amphoe: "เฉลิมพระเกียรติ",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190117,
    amphoe_code: 1913,
    province_code: 19,
  },
  {
    tambon: "กุดนกเปล้า",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190112,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "ดาวเรือง",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190105,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190113,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "ตะกุด",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190114,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "นาโฉง",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190106,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "ปากข้าวสาร",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190110,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "ปากเพรียว",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190101,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "หนองปลาไหล",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190111,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "หนองยาว",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190109,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "หนองโน",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190108,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "โคกสว่าง",
    amphoe: "เมืองสระบุรี",
    province: "สระบุรี",
    zipcode: 18000,
    district_code: 190107,
    amphoe_code: 1901,
    province_code: 19,
  },
  {
    tambon: "งิ้วงาม",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191004,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "ต้นตาล",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191006,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191007,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191002,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "พระยาทด",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191008,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "ม่วงงาม",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191009,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "ศาลารีไทย",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191005,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "สวนดอกไม้",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191012,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "หัวปลวก",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191003,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "เมืองเก่า",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191011,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "เริงราง",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191010,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "เสาไห้",
    amphoe: "เสาไห้",
    province: "สระบุรี",
    zipcode: 18160,
    district_code: 191001,
    amphoe_code: 1910,
    province_code: 19,
  },
  {
    tambon: "ชะอม",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190210,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "ชำผักแพว",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190213,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "ตาลเดี่ยว",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190203,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "ทับกวาง",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18260,
    district_code: 190202,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "ท่าคล้อ",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190205,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "ท่าตูม",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190209,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "ท่ามะปราง",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190215,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "บ้านธาตุ",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190207,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "บ้านป่า",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190208,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "สองคอน",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190211,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "หินซ้อน",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190206,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "ห้วยแห้ง",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190204,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "เตาปูน",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190212,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "แก่งคอย",
    amphoe: "แก่งคอย",
    province: "สระบุรี",
    zipcode: 18110,
    district_code: 190201,
    amphoe_code: 1902,
    province_code: 19,
  },
  {
    tambon: "คลองหาด",
    amphoe: "คลองหาด",
    province: "สระแก้ว",
    zipcode: 27260,
    district_code: 270201,
    amphoe_code: 2702,
    province_code: 27,
  },
  {
    tambon: "คลองไก่เถื่อน",
    amphoe: "คลองหาด",
    province: "สระแก้ว",
    zipcode: 27260,
    district_code: 270205,
    amphoe_code: 2702,
    province_code: 27,
  },
  {
    tambon: "ซับมะกรูด",
    amphoe: "คลองหาด",
    province: "สระแก้ว",
    zipcode: 27260,
    district_code: 270203,
    amphoe_code: 2702,
    province_code: 27,
  },
  {
    tambon: "เบญจขร",
    amphoe: "คลองหาด",
    province: "สระแก้ว",
    zipcode: 27260,
    district_code: 270206,
    amphoe_code: 2702,
    province_code: 27,
  },
  {
    tambon: "ไทยอุดม",
    amphoe: "คลองหาด",
    province: "สระแก้ว",
    zipcode: 27260,
    district_code: 270202,
    amphoe_code: 2702,
    province_code: 27,
  },
  {
    tambon: "ไทรทอง",
    amphoe: "คลองหาด",
    province: "สระแก้ว",
    zipcode: 27260,
    district_code: 270207,
    amphoe_code: 2702,
    province_code: 27,
  },
  {
    tambon: "ไทรเดี่ยว",
    amphoe: "คลองหาด",
    province: "สระแก้ว",
    zipcode: 27260,
    district_code: 270204,
    amphoe_code: 2702,
    province_code: 27,
  },
  {
    tambon: "ตาพระยา",
    amphoe: "ตาพระยา",
    province: "สระแก้ว",
    zipcode: 27180,
    district_code: 270301,
    amphoe_code: 2703,
    province_code: 27,
  },
  {
    tambon: "ทัพราช",
    amphoe: "ตาพระยา",
    province: "สระแก้ว",
    zipcode: 27180,
    district_code: 270306,
    amphoe_code: 2703,
    province_code: 27,
  },
  {
    tambon: "ทัพเสด็จ",
    amphoe: "ตาพระยา",
    province: "สระแก้ว",
    zipcode: 27180,
    district_code: 270302,
    amphoe_code: 2703,
    province_code: 27,
  },
  {
    tambon: "ทัพไทย",
    amphoe: "ตาพระยา",
    province: "สระแก้ว",
    zipcode: 27180,
    district_code: 270307,
    amphoe_code: 2703,
    province_code: 27,
  },
  {
    tambon: "โคคลาน",
    amphoe: "ตาพระยา",
    province: "สระแก้ว",
    zipcode: 27180,
    district_code: 270309,
    amphoe_code: 2703,
    province_code: 27,
  },
  {
    tambon: "คลองหินปูน",
    amphoe: "วังน้ำเย็น",
    province: "สระแก้ว",
    zipcode: 27210,
    district_code: 270405,
    amphoe_code: 2704,
    province_code: 27,
  },
  {
    tambon: "ตาหลังใน",
    amphoe: "วังน้ำเย็น",
    province: "สระแก้ว",
    zipcode: 27210,
    district_code: 270403,
    amphoe_code: 2704,
    province_code: 27,
  },
  {
    tambon: "ทุ่งมหาเจริญ",
    amphoe: "วังน้ำเย็น",
    province: "สระแก้ว",
    zipcode: 27210,
    district_code: 270406,
    amphoe_code: 2704,
    province_code: 27,
  },
  {
    tambon: "วังน้ำเย็น",
    amphoe: "วังน้ำเย็น",
    province: "สระแก้ว",
    zipcode: 27210,
    district_code: 270401,
    amphoe_code: 2704,
    province_code: 27,
  },
  {
    tambon: "วังทอง",
    amphoe: "วังสมบูรณ์",
    province: "สระแก้ว",
    zipcode: 27250,
    district_code: 270407,
    amphoe_code: 2709,
    province_code: 27,
  },
  {
    tambon: "วังสมบูรณ์",
    amphoe: "วังสมบูรณ์",
    province: "สระแก้ว",
    zipcode: 27250,
    district_code: 270402,
    amphoe_code: 2709,
    province_code: 27,
  },
  {
    tambon: "วังใหม่",
    amphoe: "วังสมบูรณ์",
    province: "สระแก้ว",
    zipcode: 27250,
    district_code: 270404,
    amphoe_code: 2709,
    province_code: 27,
  },
  {
    tambon: "ช่องกุ่ม",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270506,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "ท่าเกวียน",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270502,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "ผักขะ",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270503,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "วัฒนานคร",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270501,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "หนองตะเคียนบอน",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270510,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "หนองน้ำใส",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270505,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "หนองหมากฝ้าย",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270509,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "หนองแวง",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270304,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "ห้วยโจด",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270511,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "แซร์ออ",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270508,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "โนนหมากเค็ง",
    amphoe: "วัฒนานคร",
    province: "สระแก้ว",
    zipcode: 27160,
    district_code: 270504,
    amphoe_code: 2705,
    province_code: 27,
  },
  {
    tambon: "คลองทับจันทร์",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270611,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "คลองน้ำใส",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270604,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "ทับพริก",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270607,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270605,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "บ้านด่าน",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270613,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "บ้านใหม่หนองไทร",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270608,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "ป่าไร่",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270606,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "ผ่านศึก",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270609,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "ฟากห้วย",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270612,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "หนองสังข์",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270610,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "หันทราย",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270603,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "อรัญประเทศ",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270601,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "เมืองไผ่",
    amphoe: "อรัญประเทศ",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270602,
    amphoe_code: 2706,
    province_code: 27,
  },
  {
    tambon: "พระเพลิง",
    amphoe: "เขาฉกรรจ์",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270110,
    amphoe_code: 2707,
    province_code: 27,
  },
  {
    tambon: "หนองหว้า",
    amphoe: "เขาฉกรรจ์",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270109,
    amphoe_code: 2707,
    province_code: 27,
  },
  {
    tambon: "เขาฉกรรจ์",
    amphoe: "เขาฉกรรจ์",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270107,
    amphoe_code: 2707,
    province_code: 27,
  },
  {
    tambon: "เขาสามสิบ",
    amphoe: "เขาฉกรรจ์",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270112,
    amphoe_code: 2707,
    province_code: 27,
  },
  {
    tambon: "ท่าเกษม",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270106,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "ท่าแยก",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270105,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "บ้านแก้ง",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270102,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "ศาลาลำดวน",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270103,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "สระขวัญ",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270108,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270101,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "หนองบอน",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270111,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "โคกปี่ฆ้อง",
    amphoe: "เมืองสระแก้ว",
    province: "สระแก้ว",
    zipcode: 27000,
    district_code: 270104,
    amphoe_code: 2701,
    province_code: 27,
  },
  {
    tambon: "หนองม่วง",
    amphoe: "โคกสูง",
    province: "สระแก้ว",
    zipcode: 27180,
    district_code: 270305,
    amphoe_code: 2708,
    province_code: 27,
  },
  {
    tambon: "หนองแวง",
    amphoe: "โคกสูง",
    province: "สระแก้ว",
    zipcode: 27180,
    district_code: 270304,
    amphoe_code: 2708,
    province_code: 27,
  },
  {
    tambon: "โคกสูง",
    amphoe: "โคกสูง",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270303,
    amphoe_code: 2708,
    province_code: 27,
  },
  {
    tambon: "โนนหมากมุ่น",
    amphoe: "โคกสูง",
    province: "สระแก้ว",
    zipcode: 27120,
    district_code: 270308,
    amphoe_code: 2708,
    province_code: 27,
  },
  {
    tambon: "คอทราย",
    amphoe: "ค่ายบางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16150,
    district_code: 170305,
    amphoe_code: 1703,
    province_code: 17,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "ค่ายบางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16150,
    district_code: 170304,
    amphoe_code: 1703,
    province_code: 17,
  },
  {
    tambon: "บางระจัน",
    amphoe: "ค่ายบางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16150,
    district_code: 170302,
    amphoe_code: 1703,
    province_code: 17,
  },
  {
    tambon: "หนองกระทุ่ม",
    amphoe: "ค่ายบางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16150,
    district_code: 170306,
    amphoe_code: 1703,
    province_code: 17,
  },
  {
    tambon: "โพทะเล",
    amphoe: "ค่ายบางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16150,
    district_code: 170301,
    amphoe_code: 1703,
    province_code: 17,
  },
  {
    tambon: "โพสังโฆ",
    amphoe: "ค่ายบางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16150,
    district_code: 170303,
    amphoe_code: 1703,
    province_code: 17,
  },
  {
    tambon: "ถอนสมอ",
    amphoe: "ท่าช้าง",
    province: "สิงห์บุรี",
    zipcode: 16140,
    district_code: 170501,
    amphoe_code: 1705,
    province_code: 17,
  },
  {
    tambon: "พิกุลทอง",
    amphoe: "ท่าช้าง",
    province: "สิงห์บุรี",
    zipcode: 16140,
    district_code: 170504,
    amphoe_code: 1705,
    province_code: 17,
  },
  {
    tambon: "วิหารขาว",
    amphoe: "ท่าช้าง",
    province: "สิงห์บุรี",
    zipcode: 16140,
    district_code: 170503,
    amphoe_code: 1705,
    province_code: 17,
  },
  {
    tambon: "โพประจักษ์",
    amphoe: "ท่าช้าง",
    province: "สิงห์บุรี",
    zipcode: 16140,
    district_code: 170502,
    amphoe_code: 1705,
    province_code: 17,
  },
  {
    tambon: "บ้านจ่า",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170206,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "พักทัน",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170207,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "สระแจง",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170208,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "สิงห์",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170201,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "เชิงกลัด",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170203,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "แม่ลา",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170205,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "โพชนไก่",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170204,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "ไม้ดัด",
    amphoe: "บางระจัน",
    province: "สิงห์บุรี",
    zipcode: 16130,
    district_code: 170202,
    amphoe_code: 1702,
    province_code: 17,
  },
  {
    tambon: "บางน้ำเชี่ยว",
    amphoe: "พรหมบุรี",
    province: "สิงห์บุรี",
    zipcode: 16120,
    district_code: 170403,
    amphoe_code: 1704,
    province_code: 17,
  },
  {
    tambon: "บ้านหม้อ",
    amphoe: "พรหมบุรี",
    province: "สิงห์บุรี",
    zipcode: 16120,
    district_code: 170404,
    amphoe_code: 1704,
    province_code: 17,
  },
  {
    tambon: "บ้านแป้ง",
    amphoe: "พรหมบุรี",
    province: "สิงห์บุรี",
    zipcode: 16120,
    district_code: 170405,
    amphoe_code: 1704,
    province_code: 17,
  },
  {
    tambon: "พรหมบุรี",
    amphoe: "พรหมบุรี",
    province: "สิงห์บุรี",
    zipcode: 16160,
    district_code: 170402,
    amphoe_code: 1704,
    province_code: 17,
  },
  {
    tambon: "พระงาม",
    amphoe: "พรหมบุรี",
    province: "สิงห์บุรี",
    zipcode: 16120,
    district_code: 170401,
    amphoe_code: 1704,
    province_code: 17,
  },
  {
    tambon: "หัวป่า",
    amphoe: "พรหมบุรี",
    province: "สิงห์บุรี",
    zipcode: 16120,
    district_code: 170406,
    amphoe_code: 1704,
    province_code: 17,
  },
  {
    tambon: "โรงช้าง",
    amphoe: "พรหมบุรี",
    province: "สิงห์บุรี",
    zipcode: 16120,
    district_code: 170407,
    amphoe_code: 1704,
    province_code: 17,
  },
  {
    tambon: "งิ้วราย",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170604,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "ชีน้ำร้าย",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170605,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "ทองเอน",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170608,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "ทับยา",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170603,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "ท่างาม",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170606,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "น้ำตาล",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170607,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "ประศุก",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170602,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "ห้วยชัน",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170609,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "อินทร์บุรี",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170601,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "โพธิ์ชัย",
    amphoe: "อินทร์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16110,
    district_code: 170610,
    amphoe_code: 1706,
    province_code: 17,
  },
  {
    tambon: "จักรสีห์",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170107,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "ต้นโพธิ์",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170106,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "บางกระบือ",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170108,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "บางพุทรา",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170101,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "บางมัญ",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170102,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "ม่วงหมู่",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170104,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "หัวไผ่",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170105,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "โพกรวม",
    amphoe: "เมืองสิงห์บุรี",
    province: "สิงห์บุรี",
    zipcode: 16000,
    district_code: 170103,
    amphoe_code: 1701,
    province_code: 17,
  },
  {
    tambon: "ดอนเจดีย์",
    amphoe: "ดอนเจดีย์",
    province: "สุพรรณบุรี",
    zipcode: 72170,
    district_code: 720601,
    amphoe_code: 7206,
    province_code: 72,
  },
  {
    tambon: "ทะเลบก",
    amphoe: "ดอนเจดีย์",
    province: "สุพรรณบุรี",
    zipcode: 72250,
    district_code: 720605,
    amphoe_code: 7206,
    province_code: 72,
  },
  {
    tambon: "สระกระโจม",
    amphoe: "ดอนเจดีย์",
    province: "สุพรรณบุรี",
    zipcode: 72250,
    district_code: 720604,
    amphoe_code: 7206,
    province_code: 72,
  },
  {
    tambon: "หนองสาหร่าย",
    amphoe: "ดอนเจดีย์",
    province: "สุพรรณบุรี",
    zipcode: 72170,
    district_code: 720602,
    amphoe_code: 7206,
    province_code: 72,
  },
  {
    tambon: "ไร่รถ",
    amphoe: "ดอนเจดีย์",
    province: "สุพรรณบุรี",
    zipcode: 72170,
    district_code: 720603,
    amphoe_code: 7206,
    province_code: 72,
  },
  {
    tambon: "ด่านช้าง",
    amphoe: "ด่านช้าง",
    province: "สุพรรณบุรี",
    zipcode: 72180,
    district_code: 720298,
    amphoe_code: 7203,
    province_code: 72,
  },
  {
    tambon: "นิคมกระเสียว",
    amphoe: "ด่านช้าง",
    province: "สุพรรณบุรี",
    zipcode: 72180,
    district_code: 720306,
    amphoe_code: 7203,
    province_code: 72,
  },
  {
    tambon: "วังคัน",
    amphoe: "ด่านช้าง",
    province: "สุพรรณบุรี",
    zipcode: 72180,
    district_code: 720305,
    amphoe_code: 7203,
    province_code: 72,
  },
  {
    tambon: "วังยาว",
    amphoe: "ด่านช้าง",
    province: "สุพรรณบุรี",
    zipcode: 72180,
    district_code: 720307,
    amphoe_code: 7203,
    province_code: 72,
  },
  {
    tambon: "หนองมะค่าโมง",
    amphoe: "ด่านช้าง",
    province: "สุพรรณบุรี",
    zipcode: 72180,
    district_code: 720299,
    amphoe_code: 7203,
    province_code: 72,
  },
  {
    tambon: "ห้วยขมิ้น",
    amphoe: "ด่านช้าง",
    province: "สุพรรณบุรี",
    zipcode: 72180,
    district_code: 720297,
    amphoe_code: 7203,
    province_code: 72,
  },
  {
    tambon: "องค์พระ",
    amphoe: "ด่านช้าง",
    province: "สุพรรณบุรี",
    zipcode: 72180,
    district_code: 720296,
    amphoe_code: 7203,
    province_code: 72,
  },
  {
    tambon: "กฤษณา",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720405,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "จรเข้ใหญ่",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720409,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "ตะค่า",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720403,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "บางปลาม้า",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720402,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "บางใหญ่",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720404,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "บ้านแหลม",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720410,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "มะขามล้ม",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720411,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "วังน้ำเย็น",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720412,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "วัดดาว",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720414,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "วัดโบสถ์",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720413,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "สาลี",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720406,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "องครักษ์",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720408,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "โคกคราม",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720401,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "ไผ่กองดิน",
    amphoe: "บางปลาม้า",
    province: "สุพรรณบุรี",
    zipcode: 72150,
    district_code: 720407,
    amphoe_code: 7204,
    province_code: 72,
  },
  {
    tambon: "ดอนปรู",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720505,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "บางงาม",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720504,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "บ้านกร่าง",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720502,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "ปลายนา",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720506,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "มดแดง",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720503,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "วังน้ำซับ",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720508,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "วังยาง",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720509,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "วังหว้า",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720507,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "ศรีประจันต์",
    amphoe: "ศรีประจันต์",
    province: "สุพรรณบุรี",
    zipcode: 72140,
    district_code: 720501,
    amphoe_code: 7205,
    province_code: 72,
  },
  {
    tambon: "ดอนมะนาว",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720715,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "ต้นตาล",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720710,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "ทุ่งคอก",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720712,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "บางตะเคียน",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720704,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "บางตาเถร",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720703,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "บางพลับ",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720707,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "บางเลน",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720702,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "บ่อสุพรรณ",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72190,
    district_code: 720714,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "บ้านกุ่ม",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720705,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "บ้านช้าง",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720709,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "ศรีสำราญ",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720711,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "สองพี่น้อง",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720701,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "หนองบ่อ",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72190,
    district_code: 720713,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "หัวโพธิ์",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720706,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "เนินพระปรางค์",
    amphoe: "สองพี่น้อง",
    province: "สุพรรณบุรี",
    zipcode: 72110,
    district_code: 720708,
    amphoe_code: 7207,
    province_code: 72,
  },
  {
    tambon: "กระเสียว",
    amphoe: "สามชุก",
    province: "สุพรรณบุรี",
    zipcode: 72130,
    district_code: 720807,
    amphoe_code: 7208,
    province_code: 72,
  },
  {
    tambon: "บ้านสระ",
    amphoe: "สามชุก",
    province: "สุพรรณบุรี",
    zipcode: 72130,
    district_code: 720805,
    amphoe_code: 7208,
    province_code: 72,
  },
  {
    tambon: "ย่านยาว",
    amphoe: "สามชุก",
    province: "สุพรรณบุรี",
    zipcode: 72130,
    district_code: 720801,
    amphoe_code: 7208,
    province_code: 72,
  },
  {
    tambon: "วังลึก",
    amphoe: "สามชุก",
    province: "สุพรรณบุรี",
    zipcode: 72130,
    district_code: 720802,
    amphoe_code: 7208,
    province_code: 72,
  },
  {
    tambon: "สามชุก",
    amphoe: "สามชุก",
    province: "สุพรรณบุรี",
    zipcode: 72130,
    district_code: 720803,
    amphoe_code: 7208,
    province_code: 72,
  },
  {
    tambon: "หนองผักนาก",
    amphoe: "สามชุก",
    province: "สุพรรณบุรี",
    zipcode: 72130,
    district_code: 720804,
    amphoe_code: 7208,
    province_code: 72,
  },
  {
    tambon: "หนองสะเดา",
    amphoe: "สามชุก",
    province: "สุพรรณบุรี",
    zipcode: 72130,
    district_code: 720806,
    amphoe_code: 7208,
    province_code: 72,
  },
  {
    tambon: "ทัพหลวง",
    amphoe: "หนองหญ้าไซ",
    province: "สุพรรณบุรี",
    zipcode: 72240,
    district_code: 721006,
    amphoe_code: 7210,
    province_code: 72,
  },
  {
    tambon: "หนองขาม",
    amphoe: "หนองหญ้าไซ",
    province: "สุพรรณบุรี",
    zipcode: 72240,
    district_code: 721005,
    amphoe_code: 7210,
    province_code: 72,
  },
  {
    tambon: "หนองราชวัตร",
    amphoe: "หนองหญ้าไซ",
    province: "สุพรรณบุรี",
    zipcode: 72240,
    district_code: 720898,
    amphoe_code: 7210,
    province_code: 72,
  },
  {
    tambon: "หนองหญ้าไซ",
    amphoe: "หนองหญ้าไซ",
    province: "สุพรรณบุรี",
    zipcode: 72240,
    district_code: 720899,
    amphoe_code: 7210,
    province_code: 72,
  },
  {
    tambon: "หนองโพธิ์",
    amphoe: "หนองหญ้าไซ",
    province: "สุพรรณบุรี",
    zipcode: 72240,
    district_code: 720897,
    amphoe_code: 7210,
    province_code: 72,
  },
  {
    tambon: "แจงงาม",
    amphoe: "หนองหญ้าไซ",
    province: "สุพรรณบุรี",
    zipcode: 72240,
    district_code: 720896,
    amphoe_code: 7210,
    province_code: 72,
  },
  {
    tambon: "กระจัน",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720913,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "จรเข้สามพัน",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 71170,
    district_code: 720903,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "ดอนคา",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720908,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "ดอนมะเกลือ",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72220,
    district_code: 720906,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "บ้านดอน",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720904,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "บ้านโข้ง",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720910,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "พลับพลาไชย",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720909,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "ยุ้งทะลาย",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720905,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "สระพังลาน",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72220,
    district_code: 720912,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "สระยายโสม",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72220,
    district_code: 720902,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "หนองโอ่ง",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720907,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "อู่ทอง",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720901,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "เจดีย์",
    amphoe: "อู่ทอง",
    province: "สุพรรณบุรี",
    zipcode: 72160,
    district_code: 720911,
    amphoe_code: 7209,
    province_code: 72,
  },
  {
    tambon: "ทุ่งคลี",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720206,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "นางบวช",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720203,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "บ่อกรุ",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720210,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720205,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "ป่าสะแก",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720212,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "ยางนอน",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720213,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "วังศรีราช",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720211,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "หนองกระทุ่ม",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720214,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "หัวนา",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720209,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "หัวเขา",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720208,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "เขาดิน",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720204,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "เขาพระ",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720201,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "เดิมบาง",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720202,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "โคกช้าง",
    amphoe: "เดิมบางนางบวช",
    province: "สุพรรณบุรี",
    zipcode: 72120,
    district_code: 720207,
    amphoe_code: 7202,
    province_code: 72,
  },
  {
    tambon: "ดอนกำยาน",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720110,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ดอนตาล",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720107,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ดอนมะสังข์",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720108,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ดอนโพธิ์ทอง",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720111,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72230,
    district_code: 720114,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ทับตีเหล็ก",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720103,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ท่าพี่เลี้ยง",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720101,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ท่าระหัด",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720104,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "บางกุ้ง",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72210,
    district_code: 720115,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "บ้านโพธิ์",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720112,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "พิหารแดง",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720109,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "รั้วใหญ่",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720102,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ศาลาขาว",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72210,
    district_code: 720116,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "สนามคลี",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72230,
    district_code: 720120,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "สนามชัย",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720118,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72230,
    district_code: 720113,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "สวนแตง",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72210,
    district_code: 720117,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "โคกโคเฒ่า",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720106,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "โพธิ์พระยา",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720119,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "ไผ่ขวาง",
    amphoe: "เมืองสุพรรณบุรี",
    province: "สุพรรณบุรี",
    zipcode: 72000,
    district_code: 720105,
    amphoe_code: 7201,
    province_code: 72,
  },
  {
    tambon: "กรูด",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840205,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "กะแดะ",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840203,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "คลองสระ",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840213,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ช้างขวา",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840210,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ช้างซ้าย",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840206,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ตะเคียนทอง",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840209,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ทุ่งกง",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84290,
    district_code: 840204,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ทุ่งรัง",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84290,
    district_code: 840212,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ท่าทอง",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840202,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ท่าทองใหม่",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84290,
    district_code: 840201,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ท่าอุแท",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840211,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "ป่าร่อน",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840208,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "พลายวาส",
    amphoe: "กาญจนดิษฐ์",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840207,
    amphoe_code: 8402,
    province_code: 84,
  },
  {
    tambon: "กะเปา",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840806,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "ถ้ำสิงขร",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840809,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "ท่ากระดาน",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840807,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "ท่าขนอน",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840801,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "น้ำหัก",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840803,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "บ้านทำเนียบ",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840810,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "บ้านยาง",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840802,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "ย่านยาว",
    amphoe: "คีรีรัฐนิคม",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840808,
    amphoe_code: 8408,
    province_code: 84,
  },
  {
    tambon: "คลองน้อย",
    amphoe: "ชัยบุรี",
    province: "สุราษฎร์ธานี",
    zipcode: 84350,
    district_code: 840107,
    amphoe_code: 8418,
    province_code: 84,
  },
  {
    tambon: "ชัยบุรี",
    amphoe: "ชัยบุรี",
    province: "สุราษฎร์ธานี",
    zipcode: 84350,
    district_code: 841698,
    amphoe_code: 8418,
    province_code: 84,
  },
  {
    tambon: "สองแพรก",
    amphoe: "ชัยบุรี",
    province: "สุราษฎร์ธานี",
    zipcode: 84350,
    district_code: 841699,
    amphoe_code: 8418,
    province_code: 84,
  },
  {
    tambon: "ไทรทอง",
    amphoe: "ชัยบุรี",
    province: "สุราษฎร์ธานี",
    zipcode: 84350,
    district_code: 841804,
    amphoe_code: 8418,
    province_code: 84,
  },
  {
    tambon: "ชลคราม",
    amphoe: "ดอนสัก",
    province: "สุราษฎร์ธานี",
    zipcode: 84160,
    district_code: 840302,
    amphoe_code: 8403,
    province_code: 84,
  },
  {
    tambon: "ดอนสัก",
    amphoe: "ดอนสัก",
    province: "สุราษฎร์ธานี",
    zipcode: 84220,
    district_code: 840301,
    amphoe_code: 8403,
    province_code: 84,
  },
  {
    tambon: "ปากแพรก",
    amphoe: "ดอนสัก",
    province: "สุราษฎร์ธานี",
    zipcode: 84340,
    district_code: 840304,
    amphoe_code: 8403,
    province_code: 84,
  },
  {
    tambon: "ไชยคราม",
    amphoe: "ดอนสัก",
    province: "สุราษฎร์ธานี",
    zipcode: 84220,
    district_code: 840303,
    amphoe_code: 8403,
    province_code: 84,
  },
  {
    tambon: "คลองไทร",
    amphoe: "ท่าฉาง",
    province: "สุราษฎร์ธานี",
    zipcode: 84150,
    district_code: 841103,
    amphoe_code: 8411,
    province_code: 84,
  },
  {
    tambon: "ท่าฉาง",
    amphoe: "ท่าฉาง",
    province: "สุราษฎร์ธานี",
    zipcode: 84150,
    district_code: 841101,
    amphoe_code: 8411,
    province_code: 84,
  },
  {
    tambon: "ท่าเคย",
    amphoe: "ท่าฉาง",
    province: "สุราษฎร์ธานี",
    zipcode: 84150,
    district_code: 841102,
    amphoe_code: 8411,
    province_code: 84,
  },
  {
    tambon: "ปากฉลุย",
    amphoe: "ท่าฉาง",
    province: "สุราษฎร์ธานี",
    zipcode: 84150,
    district_code: 841106,
    amphoe_code: 8411,
    province_code: 84,
  },
  {
    tambon: "เขาถ่าน",
    amphoe: "ท่าฉาง",
    province: "สุราษฎร์ธานี",
    zipcode: 84150,
    district_code: 841104,
    amphoe_code: 8411,
    province_code: 84,
  },
  {
    tambon: "เสวียด",
    amphoe: "ท่าฉาง",
    province: "สุราษฎร์ธานี",
    zipcode: 84150,
    district_code: 841105,
    amphoe_code: 8411,
    province_code: 84,
  },
  {
    tambon: "คลองพา",
    amphoe: "ท่าชนะ",
    province: "สุราษฎร์ธานี",
    zipcode: 84170,
    district_code: 840706,
    amphoe_code: 8407,
    province_code: 84,
  },
  {
    tambon: "คันธุลี",
    amphoe: "ท่าชนะ",
    province: "สุราษฎร์ธานี",
    zipcode: 84170,
    district_code: 840704,
    amphoe_code: 8407,
    province_code: 84,
  },
  {
    tambon: "ท่าชนะ",
    amphoe: "ท่าชนะ",
    province: "สุราษฎร์ธานี",
    zipcode: 84170,
    district_code: 840701,
    amphoe_code: 8407,
    province_code: 84,
  },
  {
    tambon: "ประสงค์",
    amphoe: "ท่าชนะ",
    province: "สุราษฎร์ธานี",
    zipcode: 84170,
    district_code: 840703,
    amphoe_code: 8407,
    province_code: 84,
  },
  {
    tambon: "วัง",
    amphoe: "ท่าชนะ",
    province: "สุราษฎร์ธานี",
    zipcode: 84170,
    district_code: 840705,
    amphoe_code: 8407,
    province_code: 84,
  },
  {
    tambon: "สมอทอง",
    amphoe: "ท่าชนะ",
    province: "สุราษฎร์ธานี",
    zipcode: 84170,
    district_code: 840702,
    amphoe_code: 8407,
    province_code: 84,
  },
  {
    tambon: "พรุไทย",
    amphoe: "บ้านตาขุน",
    province: "สุราษฎร์ธานี",
    zipcode: 84230,
    district_code: 840903,
    amphoe_code: 8409,
    province_code: 84,
  },
  {
    tambon: "พะแสง",
    amphoe: "บ้านตาขุน",
    province: "สุราษฎร์ธานี",
    zipcode: 84230,
    district_code: 840902,
    amphoe_code: 8409,
    province_code: 84,
  },
  {
    tambon: "เขาพัง",
    amphoe: "บ้านตาขุน",
    province: "สุราษฎร์ธานี",
    zipcode: 84230,
    district_code: 840904,
    amphoe_code: 8409,
    province_code: 84,
  },
  {
    tambon: "เขาวง",
    amphoe: "บ้านตาขุน",
    province: "สุราษฎร์ธานี",
    zipcode: 84230,
    district_code: 840901,
    amphoe_code: 8409,
    province_code: 84,
  },
  {
    tambon: "คลองปราบ",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841208,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "ควนศรี",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84270,
    district_code: 841206,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "ควนสุบรรณ",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841207,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "ทุ่งเตา",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841203,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "ทุ่งเตาใหม่",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841210,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "ท่าชี",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841205,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "นาสาร",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841201,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "น้ำพุ",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841209,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "พรุพี",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84270,
    district_code: 841202,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "ลำพูน",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841204,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "เพิ่มพูนทรัพย์",
    amphoe: "บ้านนาสาร",
    province: "สุราษฎร์ธานี",
    zipcode: 84120,
    district_code: 841211,
    amphoe_code: 8412,
    province_code: 84,
  },
  {
    tambon: "ทรัพย์ทวี",
    amphoe: "บ้านนาเดิม",
    province: "สุราษฎร์ธานี",
    zipcode: 84240,
    district_code: 841303,
    amphoe_code: 8413,
    province_code: 84,
  },
  {
    tambon: "ท่าเรือ",
    amphoe: "บ้านนาเดิม",
    province: "สุราษฎร์ธานี",
    zipcode: 84240,
    district_code: 841298,
    amphoe_code: 8413,
    province_code: 84,
  },
  {
    tambon: "นาใต้",
    amphoe: "บ้านนาเดิม",
    province: "สุราษฎร์ธานี",
    zipcode: 84240,
    district_code: 841304,
    amphoe_code: 8413,
    province_code: 84,
  },
  {
    tambon: "บ้านนา",
    amphoe: "บ้านนาเดิม",
    province: "สุราษฎร์ธานี",
    zipcode: 84240,
    district_code: 841299,
    amphoe_code: 8413,
    province_code: 84,
  },
  {
    tambon: "คลองชะอุ่น",
    amphoe: "พนม",
    province: "สุราษฎร์ธานี",
    zipcode: 84250,
    district_code: 841006,
    amphoe_code: 8410,
    province_code: 84,
  },
  {
    tambon: "คลองศก",
    amphoe: "พนม",
    province: "สุราษฎร์ธานี",
    zipcode: 84250,
    district_code: 841003,
    amphoe_code: 8410,
    province_code: 84,
  },
  {
    tambon: "ต้นยวน",
    amphoe: "พนม",
    province: "สุราษฎร์ธานี",
    zipcode: 84250,
    district_code: 841002,
    amphoe_code: 8410,
    province_code: 84,
  },
  {
    tambon: "พนม",
    amphoe: "พนม",
    province: "สุราษฎร์ธานี",
    zipcode: 84250,
    district_code: 841001,
    amphoe_code: 8410,
    province_code: 84,
  },
  {
    tambon: "พลูเถื่อน",
    amphoe: "พนม",
    province: "สุราษฎร์ธานี",
    zipcode: 84250,
    district_code: 841004,
    amphoe_code: 8410,
    province_code: 84,
  },
  {
    tambon: "พังกาญจน์",
    amphoe: "พนม",
    province: "สุราษฎร์ธานี",
    zipcode: 84250,
    district_code: 841005,
    amphoe_code: 8410,
    province_code: 84,
  },
  {
    tambon: "บางสวรรค์",
    amphoe: "พระแสง",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841603,
    amphoe_code: 8416,
    province_code: 84,
  },
  {
    tambon: "สาคู",
    amphoe: "พระแสง",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841607,
    amphoe_code: 8416,
    province_code: 84,
  },
  {
    tambon: "สินปุน",
    amphoe: "พระแสง",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841602,
    amphoe_code: 8416,
    province_code: 84,
  },
  {
    tambon: "สินเจริญ",
    amphoe: "พระแสง",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841605,
    amphoe_code: 8416,
    province_code: 84,
  },
  {
    tambon: "อิปัน",
    amphoe: "พระแสง",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841601,
    amphoe_code: 8416,
    province_code: 84,
  },
  {
    tambon: "ไทรขึง",
    amphoe: "พระแสง",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841604,
    amphoe_code: 8416,
    province_code: 84,
  },
  {
    tambon: "ไทรโสภา",
    amphoe: "พระแสง",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841606,
    amphoe_code: 8416,
    province_code: 84,
  },
  {
    tambon: "กรูด",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 840205,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "ตะปาน",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841716,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841701,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "ท่าสะท้อน",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841702,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "ท่าโรงช้าง",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841706,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "น้ำรอบ",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841711,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "บางงอน",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841709,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "บางมะเดื่อ",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841704,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "บางเดือน",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841705,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "พุนพิน",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841708,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "มะลวน",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841712,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "ลีเล็ด",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841703,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "ศรีวิชัย",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841710,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "หนองไทร",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841714,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "หัวเตย",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841713,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "เขาหัวควาย",
    amphoe: "พุนพิน",
    province: "สุราษฎร์ธานี",
    zipcode: 84130,
    district_code: 841715,
    amphoe_code: 8417,
    province_code: 84,
  },
  {
    tambon: "ตะกุกเหนือ",
    amphoe: "วิภาวดี",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840805,
    amphoe_code: 8419,
    province_code: 84,
  },
  {
    tambon: "ตะกุกใต้",
    amphoe: "วิภาวดี",
    province: "สุราษฎร์ธานี",
    zipcode: 84180,
    district_code: 840804,
    amphoe_code: 8419,
    province_code: 84,
  },
  {
    tambon: "บ้านใต้",
    amphoe: "เกาะพะงัน",
    province: "สุราษฎร์ธานี",
    zipcode: 84280,
    district_code: 840502,
    amphoe_code: 8405,
    province_code: 84,
  },
  {
    tambon: "เกาะพะงัน",
    amphoe: "เกาะพะงัน",
    province: "สุราษฎร์ธานี",
    zipcode: 84280,
    district_code: 840501,
    amphoe_code: 8405,
    province_code: 84,
  },
  {
    tambon: "เกาะเต่า",
    amphoe: "เกาะพะงัน",
    province: "สุราษฎร์ธานี",
    zipcode: 84280,
    district_code: 840503,
    amphoe_code: 8405,
    province_code: 84,
  },
  {
    tambon: "เกาะเต่า",
    amphoe: "เกาะพะงัน",
    province: "สุราษฎร์ธานี",
    zipcode: 84360,
    district_code: 840503,
    amphoe_code: 8405,
    province_code: 84,
  },
  {
    tambon: "ตลิ่งงาม",
    amphoe: "เกาะสมุย",
    province: "สุราษฎร์ธานี",
    zipcode: 84140,
    district_code: 840403,
    amphoe_code: 8404,
    province_code: 84,
  },
  {
    tambon: "บ่อผุด",
    amphoe: "เกาะสมุย",
    province: "สุราษฎร์ธานี",
    zipcode: 84320,
    district_code: 840406,
    amphoe_code: 8404,
    province_code: 84,
  },
  {
    tambon: "มะเร็ต",
    amphoe: "เกาะสมุย",
    province: "สุราษฎร์ธานี",
    zipcode: 84310,
    district_code: 840405,
    amphoe_code: 8404,
    province_code: 84,
  },
  {
    tambon: "ลิปะน้อย",
    amphoe: "เกาะสมุย",
    province: "สุราษฎร์ธานี",
    zipcode: 84140,
    district_code: 840402,
    amphoe_code: 8404,
    province_code: 84,
  },
  {
    tambon: "หน้าเมือง",
    amphoe: "เกาะสมุย",
    province: "สุราษฎร์ธานี",
    zipcode: 84140,
    district_code: 840404,
    amphoe_code: 8404,
    province_code: 84,
  },
  {
    tambon: "อ่างทอง",
    amphoe: "เกาะสมุย",
    province: "สุราษฎร์ธานี",
    zipcode: 84140,
    district_code: 840401,
    amphoe_code: 8404,
    province_code: 84,
  },
  {
    tambon: "แม่น้ำ",
    amphoe: "เกาะสมุย",
    province: "สุราษฎร์ธานี",
    zipcode: 84330,
    district_code: 840407,
    amphoe_code: 8404,
    province_code: 84,
  },
  {
    tambon: "บ้านเสด็จ",
    amphoe: "เคียนซา",
    province: "สุราษฎร์ธานี",
    zipcode: 84260,
    district_code: 841405,
    amphoe_code: 8414,
    province_code: 84,
  },
  {
    tambon: "พ่วงพรมคร",
    amphoe: "เคียนซา",
    province: "สุราษฎร์ธานี",
    zipcode: 84210,
    district_code: 841402,
    amphoe_code: 8414,
    province_code: 84,
  },
  {
    tambon: "อรัญคามวารี",
    amphoe: "เคียนซา",
    province: "สุราษฎร์ธานี",
    zipcode: 84260,
    district_code: 841404,
    amphoe_code: 8414,
    province_code: 84,
  },
  {
    tambon: "เขาตอก",
    amphoe: "เคียนซา",
    province: "สุราษฎร์ธานี",
    zipcode: 84260,
    district_code: 841403,
    amphoe_code: 8414,
    province_code: 84,
  },
  {
    tambon: "เคียนซา",
    amphoe: "เคียนซา",
    province: "สุราษฎร์ธานี",
    zipcode: 84260,
    district_code: 841401,
    amphoe_code: 8414,
    province_code: 84,
  },
  {
    tambon: "ขุนทะเล",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84100,
    district_code: 840104,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "คลองฉนาก",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840111,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "คลองน้อย",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840107,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "ตลาด",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840101,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "บางกุ้ง",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840110,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "บางชนะ",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840106,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "บางโพธิ์",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840109,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "บางใบไม้",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840105,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "บางไทร",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840108,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "มะขามเตี้ย",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840102,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "วัดประดู่",
    amphoe: "เมืองสุราษฎร์ธานี",
    province: "สุราษฎร์ธานี",
    zipcode: 84000,
    district_code: 840103,
    amphoe_code: 8401,
    province_code: 84,
  },
  {
    tambon: "คลองฉนวน",
    amphoe: "เวียงสระ",
    province: "สุราษฎร์ธานี",
    zipcode: 84190,
    district_code: 841503,
    amphoe_code: 8415,
    province_code: 84,
  },
  {
    tambon: "ทุ่งหลวง",
    amphoe: "เวียงสระ",
    province: "สุราษฎร์ธานี",
    zipcode: 84190,
    district_code: 841504,
    amphoe_code: 8415,
    province_code: 84,
  },
  {
    tambon: "บ้านส้อง",
    amphoe: "เวียงสระ",
    province: "สุราษฎร์ธานี",
    zipcode: 84190,
    district_code: 841502,
    amphoe_code: 8415,
    province_code: 84,
  },
  {
    tambon: "เขานิพันธ์",
    amphoe: "เวียงสระ",
    province: "สุราษฎร์ธานี",
    zipcode: 84190,
    district_code: 841505,
    amphoe_code: 8415,
    province_code: 84,
  },
  {
    tambon: "เวียงสระ",
    amphoe: "เวียงสระ",
    province: "สุราษฎร์ธานี",
    zipcode: 84190,
    district_code: 841501,
    amphoe_code: 8415,
    province_code: 84,
  },
  {
    tambon: "ตลาดไชยา",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840601,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "ตะกรบ",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840607,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "ทุ่ง",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840605,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "ปากหมาก",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840609,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "ป่าเว",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840606,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "พุมเรียง",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840602,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "เลม็ด",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840603,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "เวียง",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840604,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "โมถ่าย",
    amphoe: "ไชยา",
    province: "สุราษฎร์ธานี",
    zipcode: 84110,
    district_code: 840608,
    amphoe_code: 8406,
    province_code: 84,
  },
  {
    tambon: "กาบเชิง",
    amphoe: "กาบเชิง",
    province: "สุรินทร์",
    zipcode: 32210,
    district_code: 320599,
    amphoe_code: 3206,
    province_code: 32,
  },
  {
    tambon: "คูตัน",
    amphoe: "กาบเชิง",
    province: "สุรินทร์",
    zipcode: 32210,
    district_code: 320596,
    amphoe_code: 3206,
    province_code: 32,
  },
  {
    tambon: "ด่าน",
    amphoe: "กาบเชิง",
    province: "สุรินทร์",
    zipcode: 32210,
    district_code: 320595,
    amphoe_code: 3206,
    province_code: 32,
  },
  {
    tambon: "ตะเคียน",
    amphoe: "กาบเชิง",
    province: "สุรินทร์",
    zipcode: 32210,
    district_code: 320610,
    amphoe_code: 3206,
    province_code: 32,
  },
  {
    tambon: "แนงมุด",
    amphoe: "กาบเชิง",
    province: "สุรินทร์",
    zipcode: 32210,
    district_code: 320606,
    amphoe_code: 3206,
    province_code: 32,
  },
  {
    tambon: "โคกตะเคียน",
    amphoe: "กาบเชิง",
    province: "สุรินทร์",
    zipcode: 32210,
    district_code: 320607,
    amphoe_code: 3206,
    province_code: 32,
  },
  {
    tambon: "กระหาด",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320403,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "จอมพระ",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320401,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "ชุมแสง",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320408,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "บุแกรง",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320404,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "บ้านผือ",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320406,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "ลุ่มระวี",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320407,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "หนองสนิท",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320405,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "เป็นสุข",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320409,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "เมืองลีง",
    amphoe: "จอมพระ",
    province: "สุรินทร์",
    zipcode: 32180,
    district_code: 320402,
    amphoe_code: 3204,
    province_code: 32,
  },
  {
    tambon: "กระเบื้อง",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320208,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "ชุมพลบุรี",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320201,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "นาหนองไผ่",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320202,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "ยะวึก",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320205,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "ศรีณรงค์",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320204,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "สระขุด",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320207,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "หนองเรือ",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320209,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "เมืองบัว",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320206,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "ไพรขลา",
    amphoe: "ชุมพลบุรี",
    province: "สุรินทร์",
    zipcode: 32190,
    district_code: 320203,
    amphoe_code: 3202,
    province_code: 32,
  },
  {
    tambon: "กระโพ",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320302,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "ทุ่งกุลา",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320310,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "ท่าตูม",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320301,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "บะ",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320306,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "บัวโคก",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320308,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "พรมเทพ",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320303,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "หนองบัว",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320307,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "หนองเมธี",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320309,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "เมืองแก",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320305,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "โพนครก",
    amphoe: "ท่าตูม",
    province: "สุรินทร์",
    zipcode: 32120,
    district_code: 320304,
    amphoe_code: 3203,
    province_code: 32,
  },
  {
    tambon: "จรัส",
    amphoe: "บัวเชด",
    province: "สุรินทร์",
    zipcode: 32230,
    district_code: 321303,
    amphoe_code: 3213,
    province_code: 32,
  },
  {
    tambon: "ตาวัง",
    amphoe: "บัวเชด",
    province: "สุรินทร์",
    zipcode: 32230,
    district_code: 321304,
    amphoe_code: 3213,
    province_code: 32,
  },
  {
    tambon: "บัวเชด",
    amphoe: "บัวเชด",
    province: "สุรินทร์",
    zipcode: 32230,
    district_code: 321301,
    amphoe_code: 3213,
    province_code: 32,
  },
  {
    tambon: "สะเดา",
    amphoe: "บัวเชด",
    province: "สุรินทร์",
    zipcode: 32230,
    district_code: 321302,
    amphoe_code: 3213,
    province_code: 32,
  },
  {
    tambon: "สำเภาลูน",
    amphoe: "บัวเชด",
    province: "สุรินทร์",
    zipcode: 32230,
    district_code: 321306,
    amphoe_code: 3213,
    province_code: 32,
  },
  {
    tambon: "อาโพน",
    amphoe: "บัวเชด",
    province: "สุรินทร์",
    zipcode: 32230,
    district_code: 321305,
    amphoe_code: 3213,
    province_code: 32,
  },
  {
    tambon: "กังแอน",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320501,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "กันตวจระมวล",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320516,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ตานี",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320514,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ตาเบา",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320506,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ทมอ",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320502,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ทุ่งมน",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320505,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "บ้านพลวง",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320515,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "บ้านไทร",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320510,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ประทัดบุ",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320518,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ปราสาททนง",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320513,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ปรือ",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320504,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "สมุด",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320517,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "หนองใหญ่",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320507,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "เชื้อเพลิง",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320512,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "โคกยาง",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320508,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320509,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "โชคนาสาม",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320511,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "ไพล",
    amphoe: "ปราสาท",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320503,
    amphoe_code: 3205,
    province_code: 32,
  },
  {
    tambon: "จีกแดก",
    amphoe: "พนมดงรัก",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320609,
    amphoe_code: 3214,
    province_code: 32,
  },
  {
    tambon: "ตาเมียง",
    amphoe: "พนมดงรัก",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320608,
    amphoe_code: 3214,
    province_code: 32,
  },
  {
    tambon: "บักได",
    amphoe: "พนมดงรัก",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320598,
    amphoe_code: 3214,
    province_code: 32,
  },
  {
    tambon: "โคกกลาง",
    amphoe: "พนมดงรัก",
    province: "สุรินทร์",
    zipcode: 32140,
    district_code: 320597,
    amphoe_code: 3214,
    province_code: 32,
  },
  {
    tambon: "กุดขาคีม",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320714,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "ดอนแรด",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320704,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "ทับใหญ่",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320716,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "ธาตุ",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320702,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "น้ำเขียว",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320713,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "ยางสว่าง",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320715,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "รัตนบุรี",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320701,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "หนองบัวทอง",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320705,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "หนองบัวบาน",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320706,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "เบิด",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320711,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "แก",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320703,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "ไผ่",
    amphoe: "รัตนบุรี",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320709,
    amphoe_code: 3207,
    province_code: 32,
  },
  {
    tambon: "ตระเปียงเตีย",
    amphoe: "ลำดวน",
    province: "สุรินทร์",
    zipcode: 32220,
    district_code: 321105,
    amphoe_code: 3211,
    province_code: 32,
  },
  {
    tambon: "ตรำดม",
    amphoe: "ลำดวน",
    province: "สุรินทร์",
    zipcode: 32220,
    district_code: 321104,
    amphoe_code: 3211,
    province_code: 32,
  },
  {
    tambon: "ลำดวน",
    amphoe: "ลำดวน",
    province: "สุรินทร์",
    zipcode: 32220,
    district_code: 321101,
    amphoe_code: 3211,
    province_code: 32,
  },
  {
    tambon: "อู่โลก",
    amphoe: "ลำดวน",
    province: "สุรินทร์",
    zipcode: 32220,
    district_code: 321103,
    amphoe_code: 3211,
    province_code: 32,
  },
  {
    tambon: "โชคเหนือ",
    amphoe: "ลำดวน",
    province: "สุรินทร์",
    zipcode: 32220,
    district_code: 321102,
    amphoe_code: 3211,
    province_code: 32,
  },
  {
    tambon: "ณรงค์",
    amphoe: "ศรีณรงค์",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321003,
    amphoe_code: 3215,
    province_code: 32,
  },
  {
    tambon: "ตรวจ",
    amphoe: "ศรีณรงค์",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321005,
    amphoe_code: 3215,
    province_code: 32,
  },
  {
    tambon: "ศรีสุข",
    amphoe: "ศรีณรงค์",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321014,
    amphoe_code: 3215,
    province_code: 32,
  },
  {
    tambon: "หนองแวง",
    amphoe: "ศรีณรงค์",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321016,
    amphoe_code: 3215,
    province_code: 32,
  },
  {
    tambon: "แจนแวน",
    amphoe: "ศรีณรงค์",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321004,
    amphoe_code: 3215,
    province_code: 32,
  },
  {
    tambon: "กุดหวาย",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320911,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "ขวาวใหญ่",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320912,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "คาละแมะ",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320907,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "จารพัต",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320903,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "ช่างปี่",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320910,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "ตรมไพร",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320914,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "ตรึม",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320902,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "นารุ่ง",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320913,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "ผักไหม",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320915,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "ยาง",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320904,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "ระแงง",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320901,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "หนองขวาว",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320909,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "หนองบัว",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320307,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "หนองเหล็ก",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320908,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "แตล",
    amphoe: "ศีขรภูมิ",
    province: "สุรินทร์",
    zipcode: 32110,
    district_code: 320905,
    amphoe_code: 3209,
    province_code: 32,
  },
  {
    tambon: "นานวน",
    amphoe: "สนม",
    province: "สุรินทร์",
    zipcode: 32160,
    district_code: 320797,
    amphoe_code: 3208,
    province_code: 32,
  },
  {
    tambon: "สนม",
    amphoe: "สนม",
    province: "สุรินทร์",
    zipcode: 32160,
    district_code: 320795,
    amphoe_code: 3208,
    province_code: 32,
  },
  {
    tambon: "หนองระฆัง",
    amphoe: "สนม",
    province: "สุรินทร์",
    zipcode: 32160,
    district_code: 320796,
    amphoe_code: 3208,
    province_code: 32,
  },
  {
    tambon: "หนองอียอ",
    amphoe: "สนม",
    province: "สุรินทร์",
    zipcode: 32160,
    district_code: 320807,
    amphoe_code: 3208,
    province_code: 32,
  },
  {
    tambon: "หัวงัว",
    amphoe: "สนม",
    province: "สุรินทร์",
    zipcode: 32160,
    district_code: 320806,
    amphoe_code: 3208,
    province_code: 32,
  },
  {
    tambon: "แคน",
    amphoe: "สนม",
    province: "สุรินทร์",
    zipcode: 32160,
    district_code: 320799,
    amphoe_code: 3208,
    province_code: 32,
  },
  {
    tambon: "โพนโก",
    amphoe: "สนม",
    province: "สุรินทร์",
    zipcode: 32160,
    district_code: 320798,
    amphoe_code: 3208,
    province_code: 32,
  },
  {
    tambon: "กระเทียม",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321009,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "ขอนแตก",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321002,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "ดม",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321006,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "ตาคง",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321013,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "ตาตุม",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321011,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "ทับทัน",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321012,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "บ้านจารย์",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321008,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "บ้านชบ",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321015,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "พระแก้ว",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321007,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "สะกาด",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321010,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "สังขะ",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321001,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "เทพรักษา",
    amphoe: "สังขะ",
    province: "สุรินทร์",
    zipcode: 32150,
    district_code: 321017,
    amphoe_code: 3210,
    province_code: 32,
  },
  {
    tambon: "กระออม",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321203,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "ประดู่",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321210,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "ศรีสุข",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321014,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "สะโน",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321209,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "สำโรงทาบ",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321201,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "หนองฮะ",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321204,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "หนองไผ่ล้อม",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321202,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "หมื่นศรี",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321207,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "เกาะแก้ว",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321206,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "เสม็จ",
    amphoe: "สำโรงทาบ",
    province: "สุรินทร์",
    zipcode: 32170,
    district_code: 321208,
    amphoe_code: 3212,
    province_code: 32,
  },
  {
    tambon: "ตากูก",
    amphoe: "เขวาสินรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320108,
    amphoe_code: 3216,
    province_code: 32,
  },
  {
    tambon: "บึง",
    amphoe: "เขวาสินรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320115,
    amphoe_code: 3216,
    province_code: 32,
  },
  {
    tambon: "บ้านแร่",
    amphoe: "เขวาสินรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320123,
    amphoe_code: 3216,
    province_code: 32,
  },
  {
    tambon: "ปราสาททอง",
    amphoe: "เขวาสินรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320124,
    amphoe_code: 3216,
    province_code: 32,
  },
  {
    tambon: "เขวาสินรินทร์",
    amphoe: "เขวาสินรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320117,
    amphoe_code: 3216,
    province_code: 32,
  },
  {
    tambon: "กาเกาะ",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320126,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "คอโค",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320112,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "ตระแสง",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320122,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "ตั้งใจ",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320102,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "ตาอ็อง",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320107,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "ท่าสว่าง",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320105,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "นอกเมือง",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320111,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "นาดี",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320104,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "นาบัว",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320118,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "บุฤาษี",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320121,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "ราม",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320120,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "สลักได",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320106,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "สวาย",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320113,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "สำโรง",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320109,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "เฉนียง",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320114,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "เทนมีย์",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320116,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "เพี้ยราม",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320103,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "เมืองที",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320119,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "แกใหญ่",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320110,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "แสลงพันธ์",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320125,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองสุรินทร์",
    province: "สุรินทร์",
    zipcode: 32000,
    district_code: 320101,
    amphoe_code: 3201,
    province_code: 32,
  },
  {
    tambon: "คำผง",
    amphoe: "โนนนารายณ์",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320717,
    amphoe_code: 3217,
    province_code: 32,
  },
  {
    tambon: "ระเวียง",
    amphoe: "โนนนารายณ์",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320712,
    amphoe_code: 3217,
    province_code: 32,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "โนนนารายณ์",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320707,
    amphoe_code: 3217,
    province_code: 32,
  },
  {
    tambon: "หนองเทพ",
    amphoe: "โนนนารายณ์",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320708,
    amphoe_code: 3217,
    province_code: 32,
  },
  {
    tambon: "โนน",
    amphoe: "โนนนารายณ์",
    province: "สุรินทร์",
    zipcode: 32130,
    district_code: 320710,
    amphoe_code: 3217,
    province_code: 32,
  },
  {
    tambon: "กกแรต",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640408,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "กง",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640401,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "ดงเดือย",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640406,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "ท่าฉนวน",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640409,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "บ้านกร่าง",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640402,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "บ้านใหม่สุขเกษม",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640411,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "ป่าแฝก",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640407,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "หนองตูม",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640410,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "ไกรกลาง",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640404,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "ไกรนอก",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640403,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "ไกรใน",
    amphoe: "กงไกรลาศ",
    province: "สุโขทัย",
    zipcode: 64170,
    district_code: 640405,
    amphoe_code: 6404,
    province_code: 64,
  },
  {
    tambon: "ทุ่งยางเมือง",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640310,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "ทุ่งหลวง",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640302,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "นาเชิงคีรี",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640307,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "บ้านน้ำพุ",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640309,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "บ้านป้อม",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640303,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "ศรีคีรีมาศ",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640305,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "สามพวง",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640304,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "หนองกระดิ่ง",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640308,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "หนองจิก",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640306,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "โตนด",
    amphoe: "คีรีมาศ",
    province: "สุโขทัย",
    zipcode: 64160,
    district_code: 640301,
    amphoe_code: 6403,
    province_code: 64,
  },
  {
    tambon: "กลางดง",
    amphoe: "ทุ่งเสลี่ยม",
    province: "สุโขทัย",
    zipcode: 64150,
    district_code: 640904,
    amphoe_code: 6409,
    province_code: 64,
  },
  {
    tambon: "ทุ่งเสลี่ยม",
    amphoe: "ทุ่งเสลี่ยม",
    province: "สุโขทัย",
    zipcode: 64150,
    district_code: 640903,
    amphoe_code: 6409,
    province_code: 64,
  },
  {
    tambon: "บ้านใหม่ไชยมงคล",
    amphoe: "ทุ่งเสลี่ยม",
    province: "สุโขทัย",
    zipcode: 64230,
    district_code: 640901,
    amphoe_code: 6409,
    province_code: 64,
  },
  {
    tambon: "เขาแก้วศรีสมบูรณ์",
    amphoe: "ทุ่งเสลี่ยม",
    province: "สุโขทัย",
    zipcode: 64230,
    district_code: 640905,
    amphoe_code: 6409,
    province_code: 64,
  },
  {
    tambon: "ไทยชนะศึก",
    amphoe: "ทุ่งเสลี่ยม",
    province: "สุโขทัย",
    zipcode: 64150,
    district_code: 640902,
    amphoe_code: 6409,
    province_code: 64,
  },
  {
    tambon: "ตลิ่งชัน",
    amphoe: "บ้านด่านลานหอย",
    province: "สุโขทัย",
    zipcode: 64140,
    district_code: 640205,
    amphoe_code: 6402,
    province_code: 64,
  },
  {
    tambon: "บ้านด่าน",
    amphoe: "บ้านด่านลานหอย",
    province: "สุโขทัย",
    zipcode: 64140,
    district_code: 640202,
    amphoe_code: 6402,
    province_code: 64,
  },
  {
    tambon: "ลานหอย",
    amphoe: "บ้านด่านลานหอย",
    province: "สุโขทัย",
    zipcode: 64140,
    district_code: 640201,
    amphoe_code: 6402,
    province_code: 64,
  },
  {
    tambon: "วังตะคร้อ",
    amphoe: "บ้านด่านลานหอย",
    province: "สุโขทัย",
    zipcode: 64140,
    district_code: 640203,
    amphoe_code: 6402,
    province_code: 64,
  },
  {
    tambon: "วังน้ำขาว",
    amphoe: "บ้านด่านลานหอย",
    province: "สุโขทัย",
    zipcode: 64140,
    district_code: 640204,
    amphoe_code: 6402,
    province_code: 64,
  },
  {
    tambon: "วังลึก",
    amphoe: "บ้านด่านลานหอย",
    province: "สุโขทัย",
    zipcode: 64140,
    district_code: 640207,
    amphoe_code: 6402,
    province_code: 64,
  },
  {
    tambon: "หนองหญ้าปล้อง",
    amphoe: "บ้านด่านลานหอย",
    province: "สุโขทัย",
    zipcode: 64140,
    district_code: 640206,
    amphoe_code: 6402,
    province_code: 64,
  },
  {
    tambon: "คลองมะพลับ",
    amphoe: "ศรีนคร",
    province: "สุโขทัย",
    zipcode: 64180,
    district_code: 640796,
    amphoe_code: 6408,
    province_code: 64,
  },
  {
    tambon: "นครเดิฐ",
    amphoe: "ศรีนคร",
    province: "สุโขทัย",
    zipcode: 64180,
    district_code: 640798,
    amphoe_code: 6408,
    province_code: 64,
  },
  {
    tambon: "น้ำขุม",
    amphoe: "ศรีนคร",
    province: "สุโขทัย",
    zipcode: 64180,
    district_code: 640797,
    amphoe_code: 6408,
    province_code: 64,
  },
  {
    tambon: "ศรีนคร",
    amphoe: "ศรีนคร",
    province: "สุโขทัย",
    zipcode: 64180,
    district_code: 640799,
    amphoe_code: 6408,
    province_code: 64,
  },
  {
    tambon: "หนองบัว",
    amphoe: "ศรีนคร",
    province: "สุโขทัย",
    zipcode: 64180,
    district_code: 640805,
    amphoe_code: 6408,
    province_code: 64,
  },
  {
    tambon: "ดงคู่",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640509,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "ท่าชัย",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64190,
    district_code: 640507,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "บ้านตึก",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640505,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "บ้านแก่ง",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640510,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "ป่างิ้ว",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640502,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "ศรีสัชนาลัย",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64190,
    district_code: 640508,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "สารจิตร",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640511,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "หนองอ้อ",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640506,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "หาดเสี้ยว",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640501,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "แม่สำ",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640503,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "แม่สิน",
    amphoe: "ศรีสัชนาลัย",
    province: "สุโขทัย",
    zipcode: 64130,
    district_code: 640504,
    amphoe_code: 6405,
    province_code: 64,
  },
  {
    tambon: "คลองตาล",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640601,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "ทับผึ้ง",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640610,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "นาขุนไกร",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640606,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "บ้านซ่าน",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640611,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "บ้านนา",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640604,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640609,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "ราวต้นจันทร์",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640613,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "วังทอง",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640605,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "วังลึก",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640207,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "วังใหญ่",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640612,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "วัดเกาะ",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640608,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "สามเรือน",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640603,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "เกาะตาเลี้ยง",
    amphoe: "ศรีสำโรง",
    province: "สุโขทัย",
    zipcode: 64120,
    district_code: 640607,
    amphoe_code: 6406,
    province_code: 64,
  },
  {
    tambon: "คลองกระจง",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640703,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "คลองยาง",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640708,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "ท่าทอง",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640710,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "นาทุ่ง",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640707,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "ปากน้ำ",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640711,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "ป่ากุมเกาะ",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640712,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "ย่านยาว",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640706,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "วังพิณพาทย์",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640704,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "วังไม้ขอน",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640705,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "หนองกลับ",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640714,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "เมืองบางขลัง",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640713,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "เมืองบางยม",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640709,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "เมืองสวรรคโลก",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640701,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "ในเมือง",
    amphoe: "สวรรคโลก",
    province: "สุโขทัย",
    zipcode: 64110,
    district_code: 640702,
    amphoe_code: 6407,
    province_code: 64,
  },
  {
    tambon: "ตาลเตี้ย",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64220,
    district_code: 640108,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "ธานี",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64000,
    district_code: 640101,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "บ้านกล้วย",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64000,
    district_code: 640106,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "บ้านสวน",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64220,
    district_code: 640102,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "บ้านหลุม",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64000,
    district_code: 640107,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "ปากพระ",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64000,
    district_code: 640109,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "ปากแคว",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64000,
    district_code: 640104,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "ยางซ้าย",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64000,
    district_code: 640105,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "วังทองแดง",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64210,
    district_code: 640110,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "เมืองเก่า",
    amphoe: "เมืองสุโขทัย",
    province: "สุโขทัย",
    zipcode: 64210,
    district_code: 640103,
    amphoe_code: 6401,
    province_code: 64,
  },
  {
    tambon: "กองนาง",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430203,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "ท่าบ่อ",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430201,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "นาข่า",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430208,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "น้ำโมง",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430202,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "บ้านถ่อน",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430206,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "บ้านว่าน",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430207,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "บ้านเดื่อ",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430109,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "หนองนาง",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430210,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "โคกคอน",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430204,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "โพนสา",
    amphoe: "ท่าบ่อ",
    province: "หนองคาย",
    zipcode: 43110,
    district_code: 430209,
    amphoe_code: 4302,
    province_code: 43,
  },
  {
    tambon: "นาทับไฮ",
    amphoe: "รัตนวาปี",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430514,
    amphoe_code: 4316,
    province_code: 43,
  },
  {
    tambon: "บ้านต้อน",
    amphoe: "รัตนวาปี",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430520,
    amphoe_code: 4316,
    province_code: 43,
  },
  {
    tambon: "พระบาทนาสิงห์",
    amphoe: "รัตนวาปี",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430516,
    amphoe_code: 4316,
    province_code: 43,
  },
  {
    tambon: "รัตนวาปี",
    amphoe: "รัตนวาปี",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430505,
    amphoe_code: 4316,
    province_code: 43,
  },
  {
    tambon: "โพนแพง",
    amphoe: "รัตนวาปี",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430511,
    amphoe_code: 4316,
    province_code: 43,
  },
  {
    tambon: "บ้านหม้อ",
    amphoe: "ศรีเชียงใหม่",
    province: "หนองคาย",
    zipcode: 43130,
    district_code: 430703,
    amphoe_code: 4307,
    province_code: 43,
  },
  {
    tambon: "พระพุทธบาท",
    amphoe: "ศรีเชียงใหม่",
    province: "หนองคาย",
    zipcode: 43130,
    district_code: 430704,
    amphoe_code: 4307,
    province_code: 43,
  },
  {
    tambon: "พานพร้าว",
    amphoe: "ศรีเชียงใหม่",
    province: "หนองคาย",
    zipcode: 43130,
    district_code: 430701,
    amphoe_code: 4307,
    province_code: 43,
  },
  {
    tambon: "หนองปลาปาก",
    amphoe: "ศรีเชียงใหม่",
    province: "หนองคาย",
    zipcode: 43130,
    district_code: 430705,
    amphoe_code: 4307,
    province_code: 43,
  },
  {
    tambon: "คอกช้าง",
    amphoe: "สระใคร",
    province: "หนองคาย",
    zipcode: 43100,
    district_code: 430115,
    amphoe_code: 4314,
    province_code: 43,
  },
  {
    tambon: "บ้านฝาง",
    amphoe: "สระใคร",
    province: "หนองคาย",
    zipcode: 43100,
    district_code: 430114,
    amphoe_code: 4314,
    province_code: 43,
  },
  {
    tambon: "สระใคร",
    amphoe: "สระใคร",
    province: "หนองคาย",
    zipcode: 43100,
    district_code: 430112,
    amphoe_code: 4314,
    province_code: 43,
  },
  {
    tambon: "นางิ้ว",
    amphoe: "สังคม",
    province: "หนองคาย",
    zipcode: 43160,
    district_code: 430804,
    amphoe_code: 4308,
    province_code: 43,
  },
  {
    tambon: "บ้านม่วง",
    amphoe: "สังคม",
    province: "หนองคาย",
    zipcode: 43160,
    district_code: 430803,
    amphoe_code: 4308,
    province_code: 43,
  },
  {
    tambon: "ผาตั้ง",
    amphoe: "สังคม",
    province: "หนองคาย",
    zipcode: 43160,
    district_code: 430802,
    amphoe_code: 4308,
    province_code: 43,
  },
  {
    tambon: "สังคม",
    amphoe: "สังคม",
    province: "หนองคาย",
    zipcode: 43160,
    district_code: 430805,
    amphoe_code: 4308,
    province_code: 43,
  },
  {
    tambon: "แก้งไก่",
    amphoe: "สังคม",
    province: "หนองคาย",
    zipcode: 43160,
    district_code: 430801,
    amphoe_code: 4308,
    province_code: 43,
  },
  {
    tambon: "นาดี",
    amphoe: "เฝ้าไร่",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430518,
    amphoe_code: 4315,
    province_code: 43,
  },
  {
    tambon: "วังหลวง",
    amphoe: "เฝ้าไร่",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430515,
    amphoe_code: 4315,
    province_code: 43,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "เฝ้าไร่",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430510,
    amphoe_code: 4315,
    province_code: 43,
  },
  {
    tambon: "อุดมพร",
    amphoe: "เฝ้าไร่",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430517,
    amphoe_code: 4315,
    province_code: 43,
  },
  {
    tambon: "เฝ้าไร่",
    amphoe: "เฝ้าไร่",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430512,
    amphoe_code: 4315,
    province_code: 43,
  },
  {
    tambon: "กวนวัน",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430104,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "ค่ายบกหวาน",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43100,
    district_code: 430110,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "บ้านเดื่อ",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430109,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "ปะโค",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430117,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "พระธาตุบังพวน",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43100,
    district_code: 430113,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "มีชัย",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430102,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "วัดธาตุ",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430106,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "สองห้อง",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43100,
    district_code: 430111,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "สีกาย",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430119,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "หนองกอมเกาะ",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430116,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "หาดคำ",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430107,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "หินโงม",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430108,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "เมืองหมี",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430118,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "เวียงคุก",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430105,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "โพธิ์ชัย",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430103,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองหนองคาย",
    province: "หนองคาย",
    zipcode: 43000,
    district_code: 430101,
    amphoe_code: 4301,
    province_code: 43,
  },
  {
    tambon: "ด่านศรีสุข",
    amphoe: "โพธิ์ตาก",
    province: "หนองคาย",
    zipcode: 43130,
    district_code: 430707,
    amphoe_code: 4317,
    province_code: 43,
  },
  {
    tambon: "โพธิ์ตาก",
    amphoe: "โพธิ์ตาก",
    province: "หนองคาย",
    zipcode: 43130,
    district_code: 430702,
    amphoe_code: 4317,
    province_code: 43,
  },
  {
    tambon: "โพนทอง",
    amphoe: "โพธิ์ตาก",
    province: "หนองคาย",
    zipcode: 43130,
    district_code: 430706,
    amphoe_code: 4317,
    province_code: 43,
  },
  {
    tambon: "กุดบง",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430503,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "จุมพล",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430501,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "ชุมช้าง",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430504,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "ทุ่งหลวง",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430506,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "นาหนัง",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430508,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "บ้านผือ",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430521,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "บ้านโพธิ์",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430513,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "วัดหลวง",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430502,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "สร้างนางขาว",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430522,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "เซิม",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430509,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "เหล่าต่างคำ",
    amphoe: "โพนพิสัย",
    province: "หนองคาย",
    zipcode: 43120,
    district_code: 430507,
    amphoe_code: 4305,
    province_code: 43,
  },
  {
    tambon: "กุดดินจี่",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39350,
    district_code: 390205,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "กุดแห่",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390213,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "ดงสวรรค์",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39350,
    district_code: 390211,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "ด่านช้าง",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390202,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "นากลาง",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390201,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "ฝั่งแดง",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390206,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "อุทัยสวรรค์",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390210,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "เก่ากลอย",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39350,
    district_code: 390207,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "โนนเมือง",
    amphoe: "นากลาง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390209,
    amphoe_code: 3902,
    province_code: 39,
  },
  {
    tambon: "นาเหล่า",
    amphoe: "นาวัง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390203,
    amphoe_code: 3906,
    province_code: 39,
  },
  {
    tambon: "นาแก",
    amphoe: "นาวัง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390204,
    amphoe_code: 3906,
    province_code: 39,
  },
  {
    tambon: "วังทอง",
    amphoe: "นาวัง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390208,
    amphoe_code: 3906,
    province_code: 39,
  },
  {
    tambon: "วังปลาป้อม",
    amphoe: "นาวัง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390212,
    amphoe_code: 3906,
    province_code: 39,
  },
  {
    tambon: "เทพคีรี",
    amphoe: "นาวัง",
    province: "หนองบัวลำภู",
    zipcode: 39170,
    district_code: 390214,
    amphoe_code: 3906,
    province_code: 39,
  },
  {
    tambon: "กุดสะเทียน",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390404,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "ทรายทอง",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390411,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "นากอก",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390405,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "ยางหล่อ",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390407,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "ศรีบุญเรือง",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390402,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "หนองกุงแก้ว",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390409,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "หนองบัวใต้",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390403,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "หนองแก",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390410,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "หันนางาม",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390412,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "เมืองใหม่",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390401,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "โนนม่วง",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390408,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "ศรีบุญเรือง",
    province: "หนองบัวลำภู",
    zipcode: 39180,
    district_code: 390406,
    amphoe_code: 3904,
    province_code: 39,
  },
  {
    tambon: "กุดผึ้ง",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390508,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "ดงมะไฟ",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390505,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "นาดี",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390296,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "นาด่าน",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390504,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "นาสี",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390297,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "บุญทัน",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390507,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "บ้านโคก",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390298,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "สุวรรณคูหา",
    amphoe: "สุวรรณคูหา",
    province: "หนองบัวลำภู",
    zipcode: 39270,
    district_code: 390506,
    amphoe_code: 3905,
    province_code: 39,
  },
  {
    tambon: "กุดจิก",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390111,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "นาคำไฮ",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390113,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "นามะเฟือง",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390107,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "บ้านขาม",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390106,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "บ้านพร้าว",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390108,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "ป่าไม้งาม",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390114,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "ลำภู",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390110,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "หนองบัว",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390101,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "หนองภัยศูนย์",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390102,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "หนองสวรรค์",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390104,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "หนองหว้า",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390115,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "หัวนา",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390105,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "โนนขมิ้น",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390109,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "โนนทัน",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390112,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "โพธิ์ชัย",
    amphoe: "เมืองหนองบัวลำภู",
    province: "หนองบัวลำภู",
    zipcode: 39000,
    district_code: 390103,
    amphoe_code: 3901,
    province_code: 39,
  },
  {
    tambon: "กุดดู่",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390304,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "นิคมพัฒนา",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390309,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "บ้านค้อ",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390305,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "บ้านถิ่น",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390302,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "ปางกู่",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390310,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "หนองเรือ",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390303,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "โคกม่วง",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390308,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "โคกใหญ่",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390307,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "โนนสัง",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390301,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "โนนเมือง",
    amphoe: "โนนสัง",
    province: "หนองบัวลำภู",
    zipcode: 39140,
    district_code: 390209,
    amphoe_code: 3903,
    province_code: 39,
  },
  {
    tambon: "คำเขื่อนแก้ว",
    amphoe: "ชานุมาน",
    province: "อำนาจเจริญ",
    zipcode: 37210,
    district_code: 370203,
    amphoe_code: 3702,
    province_code: 37,
  },
  {
    tambon: "ชานุมาน",
    amphoe: "ชานุมาน",
    province: "อำนาจเจริญ",
    zipcode: 37210,
    district_code: 370201,
    amphoe_code: 3702,
    province_code: 37,
  },
  {
    tambon: "ป่าก่อ",
    amphoe: "ชานุมาน",
    province: "อำนาจเจริญ",
    zipcode: 37210,
    district_code: 370205,
    amphoe_code: 3702,
    province_code: 37,
  },
  {
    tambon: "โคกก่ง",
    amphoe: "ชานุมาน",
    province: "อำนาจเจริญ",
    zipcode: 37210,
    district_code: 370204,
    amphoe_code: 3702,
    province_code: 37,
  },
  {
    tambon: "โคกสาร",
    amphoe: "ชานุมาน",
    province: "อำนาจเจริญ",
    zipcode: 37210,
    district_code: 370202,
    amphoe_code: 3702,
    province_code: 37,
  },
  {
    tambon: "คำโพน",
    amphoe: "ปทุมราชวงศา",
    province: "อำนาจเจริญ",
    zipcode: 37110,
    district_code: 370302,
    amphoe_code: 3703,
    province_code: 37,
  },
  {
    tambon: "นาป่าแซง",
    amphoe: "ปทุมราชวงศา",
    province: "อำนาจเจริญ",
    zipcode: 37110,
    district_code: 370307,
    amphoe_code: 3703,
    province_code: 37,
  },
  {
    tambon: "นาหว้า",
    amphoe: "ปทุมราชวงศา",
    province: "อำนาจเจริญ",
    zipcode: 37110,
    district_code: 370303,
    amphoe_code: 3703,
    province_code: 37,
  },
  {
    tambon: "ลือ",
    amphoe: "ปทุมราชวงศา",
    province: "อำนาจเจริญ",
    zipcode: 37110,
    district_code: 370304,
    amphoe_code: 3703,
    province_code: 37,
  },
  {
    tambon: "หนองข่า",
    amphoe: "ปทุมราชวงศา",
    province: "อำนาจเจริญ",
    zipcode: 37110,
    district_code: 370299,
    amphoe_code: 3703,
    province_code: 37,
  },
  {
    tambon: "ห้วย",
    amphoe: "ปทุมราชวงศา",
    province: "อำนาจเจริญ",
    zipcode: 37110,
    district_code: 370305,
    amphoe_code: 3703,
    province_code: 37,
  },
  {
    tambon: "โนนงาม",
    amphoe: "ปทุมราชวงศา",
    province: "อำนาจเจริญ",
    zipcode: 37110,
    district_code: 370306,
    amphoe_code: 3703,
    province_code: 37,
  },
  {
    tambon: "จานลาน",
    amphoe: "พนา",
    province: "อำนาจเจริญ",
    zipcode: 37180,
    district_code: 370402,
    amphoe_code: 3704,
    province_code: 37,
  },
  {
    tambon: "พนา",
    amphoe: "พนา",
    province: "อำนาจเจริญ",
    zipcode: 37180,
    district_code: 370401,
    amphoe_code: 3704,
    province_code: 37,
  },
  {
    tambon: "พระเหลา",
    amphoe: "พนา",
    province: "อำนาจเจริญ",
    zipcode: 37180,
    district_code: 370404,
    amphoe_code: 3704,
    province_code: 37,
  },
  {
    tambon: "ไม้กลอน",
    amphoe: "พนา",
    province: "อำนาจเจริญ",
    zipcode: 37180,
    district_code: 370403,
    amphoe_code: 3704,
    province_code: 37,
  },
  {
    tambon: "ดงบัง",
    amphoe: "ลืออำนาจ",
    province: "อำนาจเจริญ",
    zipcode: 37120,
    district_code: 370199,
    amphoe_code: 3707,
    province_code: 37,
  },
  {
    tambon: "ดงมะยาง",
    amphoe: "ลืออำนาจ",
    province: "อำนาจเจริญ",
    zipcode: 37120,
    district_code: 370191,
    amphoe_code: 3707,
    province_code: 37,
  },
  {
    tambon: "อำนาจ",
    amphoe: "ลืออำนาจ",
    province: "อำนาจเจริญ",
    zipcode: 37120,
    district_code: 370198,
    amphoe_code: 3707,
    province_code: 37,
  },
  {
    tambon: "เปือย",
    amphoe: "ลืออำนาจ",
    province: "อำนาจเจริญ",
    zipcode: 37120,
    district_code: 370192,
    amphoe_code: 3707,
    province_code: 37,
  },
  {
    tambon: "แมด",
    amphoe: "ลืออำนาจ",
    province: "อำนาจเจริญ",
    zipcode: 37120,
    district_code: 370706,
    amphoe_code: 3707,
    province_code: 37,
  },
  {
    tambon: "โคกกลาง",
    amphoe: "ลืออำนาจ",
    province: "อำนาจเจริญ",
    zipcode: 37120,
    district_code: 370707,
    amphoe_code: 3707,
    province_code: 37,
  },
  {
    tambon: "ไร่ขี",
    amphoe: "ลืออำนาจ",
    province: "อำนาจเจริญ",
    zipcode: 37120,
    district_code: 370195,
    amphoe_code: 3707,
    province_code: 37,
  },
  {
    tambon: "คำพระ",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370602,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "จิกดู่",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370607,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "รัตนวารี",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370608,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "สร้างถ่อน้อย",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370606,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "หนองแก้ว",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370604,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "หัวตะพาน",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370601,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "เค็งใหญ่",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370603,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "โพนเมืองน้อย",
    amphoe: "หัวตะพาน",
    province: "อำนาจเจริญ",
    zipcode: 37240,
    district_code: 370605,
    amphoe_code: 3706,
    province_code: 37,
  },
  {
    tambon: "กุดปลาดุก",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370116,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "คึมใหญ่",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370107,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "ดอนเมย",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370117,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "นาจิก",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370103,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "นาผือ",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370108,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "นายม",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370118,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "นาวัง",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370110,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "นาหมอม้า",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370111,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "นาแต้",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370119,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "น้ำปลีก",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370109,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "บุ่ง",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370101,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "ปลาค้าว",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370104,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "สร้างนกทา",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370106,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "หนองมะแซว",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370115,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "ห้วยไร่",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370114,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "เหล่าพรวน",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370105,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "โนนหนามแท่ง",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370113,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "โนนโพธิ์",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370112,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "ไก่คำ",
    amphoe: "เมืองอำนาจเจริญ",
    province: "อำนาจเจริญ",
    zipcode: 37000,
    district_code: 370102,
    amphoe_code: 3701,
    province_code: 37,
  },
  {
    tambon: "นาเวียง",
    amphoe: "เสนางคนิคม",
    province: "อำนาจเจริญ",
    zipcode: 37290,
    district_code: 370194,
    amphoe_code: 3705,
    province_code: 37,
  },
  {
    tambon: "หนองสามสี",
    amphoe: "เสนางคนิคม",
    province: "อำนาจเจริญ",
    zipcode: 37290,
    district_code: 370506,
    amphoe_code: 3705,
    province_code: 37,
  },
  {
    tambon: "หนองไฮ",
    amphoe: "เสนางคนิคม",
    province: "อำนาจเจริญ",
    zipcode: 37290,
    district_code: 370193,
    amphoe_code: 3705,
    province_code: 37,
  },
  {
    tambon: "เสนางคนิคม",
    amphoe: "เสนางคนิคม",
    province: "อำนาจเจริญ",
    zipcode: 37290,
    district_code: 370197,
    amphoe_code: 3705,
    province_code: 37,
  },
  {
    tambon: "โพนทอง",
    amphoe: "เสนางคนิคม",
    province: "อำนาจเจริญ",
    zipcode: 37290,
    district_code: 370190,
    amphoe_code: 3705,
    province_code: 37,
  },
  {
    tambon: "ไร่สีสุก",
    amphoe: "เสนางคนิคม",
    province: "อำนาจเจริญ",
    zipcode: 37290,
    district_code: 370196,
    amphoe_code: 3705,
    province_code: 37,
  },
  {
    tambon: "กุดจับ",
    amphoe: "กุดจับ",
    province: "อุดรธานี",
    zipcode: 41250,
    district_code: 410198,
    amphoe_code: 4102,
    province_code: 41,
  },
  {
    tambon: "ขอนยูง",
    amphoe: "กุดจับ",
    province: "อุดรธานี",
    zipcode: 41250,
    district_code: 410190,
    amphoe_code: 4102,
    province_code: 41,
  },
  {
    tambon: "ตาลเลียน",
    amphoe: "กุดจับ",
    province: "อุดรธานี",
    zipcode: 41250,
    district_code: 410207,
    amphoe_code: 4102,
    province_code: 41,
  },
  {
    tambon: "ปะโค",
    amphoe: "กุดจับ",
    province: "อุดรธานี",
    zipcode: 41250,
    district_code: 410191,
    amphoe_code: 4102,
    province_code: 41,
  },
  {
    tambon: "สร้างก่อ",
    amphoe: "กุดจับ",
    province: "อุดรธานี",
    zipcode: 41250,
    district_code: 410205,
    amphoe_code: 4102,
    province_code: 41,
  },
  {
    tambon: "เชียงเพ็ง",
    amphoe: "กุดจับ",
    province: "อุดรธานี",
    zipcode: 41250,
    district_code: 410197,
    amphoe_code: 4102,
    province_code: 41,
  },
  {
    tambon: "เมืองเพีย",
    amphoe: "กุดจับ",
    province: "อุดรธานี",
    zipcode: 41250,
    district_code: 410206,
    amphoe_code: 4102,
    province_code: 41,
  },
  {
    tambon: "กุมภวาปี",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410415,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "ตูมใต้",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410401,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "ท่าลี่",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410414,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "ปะโค",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41370,
    district_code: 410191,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "ผาสุก",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41370,
    district_code: 410413,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "พันดอน",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41370,
    district_code: 410402,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "สีออ",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410410,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "หนองหว้า",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410194,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "ห้วยเกิ้ง",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410407,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "เชียงแหว",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410406,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "เวียงคำ",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410403,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "เสอเพลอ",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41370,
    district_code: 410409,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "แชแล",
    amphoe: "กุมภวาปี",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410404,
    amphoe_code: 4104,
    province_code: 41,
  },
  {
    tambon: "คอนสาย",
    amphoe: "กู่แก้ว",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410603,
    amphoe_code: 4124,
    province_code: 41,
  },
  {
    tambon: "ค้อใหญ่",
    amphoe: "กู่แก้ว",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410616,
    amphoe_code: 4124,
    province_code: 41,
  },
  {
    tambon: "บ้านจีต",
    amphoe: "กู่แก้ว",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410604,
    amphoe_code: 4124,
    province_code: 41,
  },
  {
    tambon: "โนนทองอินทร์",
    amphoe: "กู่แก้ว",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410619,
    amphoe_code: 4124,
    province_code: 41,
  },
  {
    tambon: "ทุ่งฝน",
    amphoe: "ทุ่งฝน",
    province: "อุดรธานี",
    zipcode: 41310,
    district_code: 410697,
    amphoe_code: 4107,
    province_code: 41,
  },
  {
    tambon: "ทุ่งใหญ่",
    amphoe: "ทุ่งฝน",
    province: "อุดรธานี",
    zipcode: 41310,
    district_code: 410696,
    amphoe_code: 4107,
    province_code: 41,
  },
  {
    tambon: "นาชุมแสง",
    amphoe: "ทุ่งฝน",
    province: "อุดรธานี",
    zipcode: 41310,
    district_code: 410703,
    amphoe_code: 4107,
    province_code: 41,
  },
  {
    tambon: "นาทม",
    amphoe: "ทุ่งฝน",
    province: "อุดรธานี",
    zipcode: 41310,
    district_code: 410704,
    amphoe_code: 4107,
    province_code: 41,
  },
  {
    tambon: "นายูง",
    amphoe: "นายูง",
    province: "อุดรธานี",
    zipcode: 41380,
    district_code: 410906,
    amphoe_code: 4122,
    province_code: 41,
  },
  {
    tambon: "นาแค",
    amphoe: "นายูง",
    province: "อุดรธานี",
    zipcode: 41380,
    district_code: 411804,
    amphoe_code: 4122,
    province_code: 41,
  },
  {
    tambon: "บ้านก้อง",
    amphoe: "นายูง",
    province: "อุดรธานี",
    zipcode: 41380,
    district_code: 411809,
    amphoe_code: 4122,
    province_code: 41,
  },
  {
    tambon: "โนนทอง",
    amphoe: "นายูง",
    province: "อุดรธานี",
    zipcode: 41380,
    district_code: 411705,
    amphoe_code: 4122,
    province_code: 41,
  },
  {
    tambon: "นางัว",
    amphoe: "น้ำโสม",
    province: "อุดรธานี",
    zipcode: 41210,
    district_code: 411801,
    amphoe_code: 4118,
    province_code: 41,
  },
  {
    tambon: "น้ำโสม",
    amphoe: "น้ำโสม",
    province: "อุดรธานี",
    zipcode: 41210,
    district_code: 411802,
    amphoe_code: 4118,
    province_code: 41,
  },
  {
    tambon: "บ้านหยวก",
    amphoe: "น้ำโสม",
    province: "อุดรธานี",
    zipcode: 41210,
    district_code: 411806,
    amphoe_code: 4118,
    province_code: 41,
  },
  {
    tambon: "ศรีสำราญ",
    amphoe: "น้ำโสม",
    province: "อุดรธานี",
    zipcode: 41210,
    district_code: 411810,
    amphoe_code: 4118,
    province_code: 41,
  },
  {
    tambon: "สามัคคี",
    amphoe: "น้ำโสม",
    province: "อุดรธานี",
    zipcode: 41210,
    district_code: 411812,
    amphoe_code: 4118,
    province_code: 41,
  },
  {
    tambon: "หนองแวง",
    amphoe: "น้ำโสม",
    province: "อุดรธานี",
    zipcode: 41210,
    district_code: 411713,
    amphoe_code: 4118,
    province_code: 41,
  },
  {
    tambon: "โสมเยี่ยม",
    amphoe: "น้ำโสม",
    province: "อุดรธานี",
    zipcode: 41210,
    district_code: 411807,
    amphoe_code: 4118,
    province_code: 41,
  },
  {
    tambon: "ดงเย็น",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411103,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "ถ่อนนาลับ",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411109,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "นาคำ",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411113,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "นาไหม",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411108,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "บ้านจันทร์",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: false,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "บ้านชัย",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411107,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "บ้านดุง",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411102,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "บ้านตาด",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 410105,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "บ้านม่วง",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411111,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "วังทอง",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411110,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "ศรีสุทโธ",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411101,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "อ้อมกอ",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 411105,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "โพนสูง",
    amphoe: "บ้านดุง",
    province: "อุดรธานี",
    zipcode: 41190,
    district_code: 410698,
    amphoe_code: 4111,
    province_code: 41,
  },
  {
    tambon: "กลางใหญ่",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411708,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "ข้าวสาร",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411706,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "คำด้วง",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411710,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "คำบง",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411704,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "จำปาโมง",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411707,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "บ้านค้อ",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411605,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "บ้านผือ",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411701,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "หนองหัวคู",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411711,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "หนองแวง",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411713,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "หายโศก",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411702,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "เขือน้ำ",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411703,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "เมืองพาน",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411709,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "โนนทอง",
    amphoe: "บ้านผือ",
    province: "อุดรธานี",
    zipcode: 41160,
    district_code: 411705,
    amphoe_code: 4117,
    province_code: 41,
  },
  {
    tambon: "นาม่วง",
    amphoe: "ประจักษ์ศิลปาคม",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410412,
    amphoe_code: 4125,
    province_code: 41,
  },
  {
    tambon: "ห้วยสามพาด",
    amphoe: "ประจักษ์ศิลปาคม",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410408,
    amphoe_code: 4125,
    province_code: 41,
  },
  {
    tambon: "อุ่มจาน",
    amphoe: "ประจักษ์ศิลปาคม",
    province: "อุดรธานี",
    zipcode: 41110,
    district_code: 410405,
    amphoe_code: 4125,
    province_code: 41,
  },
  {
    tambon: "ดอนกลอย",
    amphoe: "พิบูลย์รักษ์",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410613,
    amphoe_code: 4123,
    province_code: 41,
  },
  {
    tambon: "นาทราย",
    amphoe: "พิบูลย์รักษ์",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410615,
    amphoe_code: 4123,
    province_code: 41,
  },
  {
    tambon: "บ้านแดง",
    amphoe: "พิบูลย์รักษ์",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410608,
    amphoe_code: 4123,
    province_code: 41,
  },
  {
    tambon: "คำโคกสูง",
    amphoe: "วังสามหมอ",
    province: "อุดรธานี",
    zipcode: 41280,
    district_code: 411005,
    amphoe_code: 4110,
    province_code: 41,
  },
  {
    tambon: "บะยาว",
    amphoe: "วังสามหมอ",
    province: "อุดรธานี",
    zipcode: 41280,
    district_code: 411003,
    amphoe_code: 4110,
    province_code: 41,
  },
  {
    tambon: "ผาสุก",
    amphoe: "วังสามหมอ",
    province: "อุดรธานี",
    zipcode: 41280,
    district_code: 410413,
    amphoe_code: 4110,
    province_code: 41,
  },
  {
    tambon: "วังสามหมอ",
    amphoe: "วังสามหมอ",
    province: "อุดรธานี",
    zipcode: 41280,
    district_code: 411006,
    amphoe_code: 4110,
    province_code: 41,
  },
  {
    tambon: "หนองกุงทับม้า",
    amphoe: "วังสามหมอ",
    province: "อุดรธานี",
    zipcode: 41280,
    district_code: 411001,
    amphoe_code: 4110,
    province_code: 41,
  },
  {
    tambon: "หนองหญ้าไซ",
    amphoe: "วังสามหมอ",
    province: "อุดรธานี",
    zipcode: 41280,
    district_code: 411002,
    amphoe_code: 4110,
    province_code: 41,
  },
  {
    tambon: "จำปี",
    amphoe: "ศรีธาตุ",
    province: "อุดรธานี",
    zipcode: 41230,
    district_code: 410902,
    amphoe_code: 4109,
    province_code: 41,
  },
  {
    tambon: "ตาดทอง",
    amphoe: "ศรีธาตุ",
    province: "อุดรธานี",
    zipcode: 41230,
    district_code: 410907,
    amphoe_code: 4109,
    province_code: 41,
  },
  {
    tambon: "นายูง",
    amphoe: "ศรีธาตุ",
    province: "อุดรธานี",
    zipcode: 41230,
    district_code: 410906,
    amphoe_code: 4109,
    province_code: 41,
  },
  {
    tambon: "บ้านโปร่ง",
    amphoe: "ศรีธาตุ",
    province: "อุดรธานี",
    zipcode: 41230,
    district_code: 410903,
    amphoe_code: 4109,
    province_code: 41,
  },
  {
    tambon: "ศรีธาตุ",
    amphoe: "ศรีธาตุ",
    province: "อุดรธานี",
    zipcode: 41230,
    district_code: 410901,
    amphoe_code: 4109,
    province_code: 41,
  },
  {
    tambon: "หนองนกเขียน",
    amphoe: "ศรีธาตุ",
    province: "อุดรธานี",
    zipcode: 41230,
    district_code: 410905,
    amphoe_code: 4109,
    province_code: 41,
  },
  {
    tambon: "หัวนาคำ",
    amphoe: "ศรีธาตุ",
    province: "อุดรธานี",
    zipcode: 41230,
    district_code: 410904,
    amphoe_code: 4109,
    province_code: 41,
  },
  {
    tambon: "นาสะอาด",
    amphoe: "สร้างคอม",
    province: "อุดรธานี",
    zipcode: 41260,
    district_code: 412005,
    amphoe_code: 4120,
    province_code: 41,
  },
  {
    tambon: "บ้านยวด",
    amphoe: "สร้างคอม",
    province: "อุดรธานี",
    zipcode: 41260,
    district_code: 411998,
    amphoe_code: 4120,
    province_code: 41,
  },
  {
    tambon: "บ้านหินโงม",
    amphoe: "สร้างคอม",
    province: "อุดรธานี",
    zipcode: 41260,
    district_code: 412006,
    amphoe_code: 4120,
    province_code: 41,
  },
  {
    tambon: "บ้านโคก",
    amphoe: "สร้างคอม",
    province: "อุดรธานี",
    zipcode: 41260,
    district_code: 411498,
    amphoe_code: 4120,
    province_code: 41,
  },
  {
    tambon: "สร้างคอม",
    amphoe: "สร้างคอม",
    province: "อุดรธานี",
    zipcode: 41260,
    district_code: 411999,
    amphoe_code: 4120,
    province_code: 41,
  },
  {
    tambon: "เชียงดา",
    amphoe: "สร้างคอม",
    province: "อุดรธานี",
    zipcode: 41260,
    district_code: 411997,
    amphoe_code: 4120,
    province_code: 41,
  },
  {
    tambon: "กุดหมากไฟ",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41220,
    district_code: 410304,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "น้ำพ่น",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41360,
    district_code: 410305,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "หนองบัวบาน",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41360,
    district_code: 410306,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "หนองวัวซอ",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41360,
    district_code: 410308,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "หนองอ้อ",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41220,
    district_code: 410302,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "หมากหญ้า",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41360,
    district_code: 410301,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "อูบมุง",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41220,
    district_code: 410303,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "โนนหวาย",
    amphoe: "หนองวัวซอ",
    province: "อุดรธานี",
    zipcode: 41220,
    district_code: 410307,
    amphoe_code: 4103,
    province_code: 41,
  },
  {
    tambon: "ดอนหายโศก",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410617,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "บ้านยา",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41320,
    district_code: 410610,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "บ้านเชียง",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41320,
    district_code: 410609,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "ผักตบ",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410612,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "พังงู",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410605,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "สร้อยพร้าว",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410607,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "สะแบง",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410606,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "หนองสระปลา",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41320,
    district_code: 410618,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "หนองหาน",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410601,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "หนองเม็ก",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410602,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410121,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "โพนงาม",
    amphoe: "หนองหาน",
    province: "อุดรธานี",
    zipcode: 41130,
    district_code: 410611,
    amphoe_code: 4106,
    province_code: 41,
  },
  {
    tambon: "ทับกุง",
    amphoe: "หนองแสง",
    province: "อุดรธานี",
    zipcode: 41340,
    district_code: 412104,
    amphoe_code: 4121,
    province_code: 41,
  },
  {
    tambon: "นาดี",
    amphoe: "หนองแสง",
    province: "อุดรธานี",
    zipcode: 41340,
    district_code: 410111,
    amphoe_code: 4121,
    province_code: 41,
  },
  {
    tambon: "หนองแสง",
    amphoe: "หนองแสง",
    province: "อุดรธานี",
    zipcode: 41340,
    district_code: 410498,
    amphoe_code: 4121,
    province_code: 41,
  },
  {
    tambon: "แสงสว่าง",
    amphoe: "หนองแสง",
    province: "อุดรธานี",
    zipcode: 41340,
    district_code: 410499,
    amphoe_code: 4121,
    province_code: 41,
  },
  {
    tambon: "จอมศรี",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411908,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "นาบัว",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411906,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "นาพู่",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411903,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "บ้านธาตุ",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411902,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "บ้านเหล่า",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411907,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "สร้างแป้น",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411911,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "สุมเส้า",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411905,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "เชียงหวาง",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411904,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "เตาไห",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411909,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "เพ็ญ",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 411901,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "โคกกลาง",
    amphoe: "เพ็ญ",
    province: "อุดรธานี",
    zipcode: 41150,
    district_code: 410506,
    amphoe_code: 4119,
    province_code: 41,
  },
  {
    tambon: "กุดสระ",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410110,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "นากว้าง",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410120,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "นาข่า",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410116,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "นาดี",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410111,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "นิคมสงเคราะห์",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410102,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "บ้านขาว",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410103,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "บ้านจั่น",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410117,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "บ้านตาด",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410105,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "บ้านเลื่อม",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410112,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "สามพร้าว",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410114,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "หนองขอนกว้าง",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410118,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "หนองนาคำ",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410109,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "หนองบัว",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410104,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41330,
    district_code: 410121,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "หนองไฮ",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410115,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "หมากแข้ง",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410101,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "หมูม่น",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410107,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "เชียงพิณ",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410113,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "เชียงยืน",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410108,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41000,
    district_code: 410119,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "โนนสูง",
    amphoe: "เมืองอุดรธานี",
    province: "อุดรธานี",
    zipcode: 41330,
    district_code: 410106,
    amphoe_code: 4101,
    province_code: 41,
  },
  {
    tambon: "ทมนางาม",
    amphoe: "โนนสะอาด",
    province: "อุดรธานี",
    zipcode: 41240,
    district_code: 410504,
    amphoe_code: 4105,
    province_code: 41,
  },
  {
    tambon: "บุ่งแก้ว",
    amphoe: "โนนสะอาด",
    province: "อุดรธานี",
    zipcode: 41240,
    district_code: 410497,
    amphoe_code: 4105,
    province_code: 41,
  },
  {
    tambon: "หนองกุงศรี",
    amphoe: "โนนสะอาด",
    province: "อุดรธานี",
    zipcode: 41240,
    district_code: 410505,
    amphoe_code: 4105,
    province_code: 41,
  },
  {
    tambon: "โคกกลาง",
    amphoe: "โนนสะอาด",
    province: "อุดรธานี",
    zipcode: 41240,
    district_code: 410506,
    amphoe_code: 4105,
    province_code: 41,
  },
  {
    tambon: "โนนสะอาด",
    amphoe: "โนนสะอาด",
    province: "อุดรธานี",
    zipcode: 41240,
    district_code: 410495,
    amphoe_code: 4105,
    province_code: 41,
  },
  {
    tambon: "โพธิ์ศรีสำราญ",
    amphoe: "โนนสะอาด",
    province: "อุดรธานี",
    zipcode: 41240,
    district_code: 410496,
    amphoe_code: 4105,
    province_code: 41,
  },
  {
    tambon: "คำเลาะ",
    amphoe: "ไชยวาน",
    province: "อุดรธานี",
    zipcode: 41290,
    district_code: 410803,
    amphoe_code: 4108,
    province_code: 41,
  },
  {
    tambon: "หนองหลัก",
    amphoe: "ไชยวาน",
    province: "อุดรธานี",
    zipcode: 41290,
    district_code: 410694,
    amphoe_code: 4108,
    province_code: 41,
  },
  {
    tambon: "โพนสูง",
    amphoe: "ไชยวาน",
    province: "อุดรธานี",
    zipcode: 41290,
    district_code: 410698,
    amphoe_code: 4108,
    province_code: 41,
  },
  {
    tambon: "ไชยวาน",
    amphoe: "ไชยวาน",
    province: "อุดรธานี",
    zipcode: 41290,
    district_code: 410699,
    amphoe_code: 4108,
    province_code: 41,
  },
  {
    tambon: "ข่อยสูง",
    amphoe: "ตรอน",
    province: "อุตรดิตถ์",
    zipcode: 53140,
    district_code: 530205,
    amphoe_code: 5302,
    province_code: 53,
  },
  {
    tambon: "น้ำอ่าง",
    amphoe: "ตรอน",
    province: "อุตรดิตถ์",
    zipcode: 53140,
    district_code: 530204,
    amphoe_code: 5302,
    province_code: 53,
  },
  {
    tambon: "บ้านแก่ง",
    amphoe: "ตรอน",
    province: "อุตรดิตถ์",
    zipcode: 53140,
    district_code: 530202,
    amphoe_code: 5302,
    province_code: 53,
  },
  {
    tambon: "วังแดง",
    amphoe: "ตรอน",
    province: "อุตรดิตถ์",
    zipcode: 53140,
    district_code: 530201,
    amphoe_code: 5302,
    province_code: 53,
  },
  {
    tambon: "หาดสองแคว",
    amphoe: "ตรอน",
    province: "อุตรดิตถ์",
    zipcode: 53140,
    district_code: 530203,
    amphoe_code: 5302,
    province_code: 53,
  },
  {
    tambon: "น้ำพี้",
    amphoe: "ทองแสนขัน",
    province: "อุตรดิตถ์",
    zipcode: 53230,
    district_code: 530296,
    amphoe_code: 5309,
    province_code: 53,
  },
  {
    tambon: "บ่อทอง",
    amphoe: "ทองแสนขัน",
    province: "อุตรดิตถ์",
    zipcode: 53230,
    district_code: 530297,
    amphoe_code: 5309,
    province_code: 53,
  },
  {
    tambon: "ป่าคาย",
    amphoe: "ทองแสนขัน",
    province: "อุตรดิตถ์",
    zipcode: 53230,
    district_code: 530299,
    amphoe_code: 5309,
    province_code: 53,
  },
  {
    tambon: "ผักขวง",
    amphoe: "ทองแสนขัน",
    province: "อุตรดิตถ์",
    zipcode: 53230,
    district_code: 530298,
    amphoe_code: 5309,
    province_code: 53,
  },
  {
    tambon: "จริม",
    amphoe: "ท่าปลา",
    province: "อุตรดิตถ์",
    zipcode: 53150,
    district_code: 530304,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "ท่าปลา",
    amphoe: "ท่าปลา",
    province: "อุตรดิตถ์",
    zipcode: 53150,
    district_code: 530301,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "ท่าแฝก",
    amphoe: "น้ำปาด",
    province: "อุตรดิตถ์",
    zipcode: 53110,
    district_code: 530306,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "นางพญา",
    amphoe: "ท่าปลา",
    province: "อุตรดิตถ์",
    zipcode: 53150,
    district_code: 530307,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "น้ำหมัน",
    amphoe: "ท่าปลา",
    province: "อุตรดิตถ์",
    zipcode: 53150,
    district_code: 530305,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "ผาเลือด",
    amphoe: "ท่าปลา",
    province: "อุตรดิตถ์",
    zipcode: 53190,
    district_code: 530303,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "ร่วมจิต",
    amphoe: "ท่าปลา",
    province: "อุตรดิตถ์",
    zipcode: 53190,
    district_code: 530308,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "หาดล้า",
    amphoe: "ท่าปลา",
    province: "อุตรดิตถ์",
    zipcode: 53190,
    district_code: 530302,
    amphoe_code: 5303,
    province_code: 53,
  },
  {
    tambon: "น้ำไคร้",
    amphoe: "น้ำปาด",
    province: "อุตรดิตถ์",
    zipcode: 53110,
    district_code: 530404,
    amphoe_code: 5304,
    province_code: 53,
  },
  {
    tambon: "น้ำไผ่",
    amphoe: "น้ำปาด",
    province: "อุตรดิตถ์",
    zipcode: 53110,
    district_code: 530405,
    amphoe_code: 5304,
    province_code: 53,
  },
  {
    tambon: "บ้านฝาย",
    amphoe: "น้ำปาด",
    province: "อุตรดิตถ์",
    zipcode: 53110,
    district_code: 530402,
    amphoe_code: 5304,
    province_code: 53,
  },
  {
    tambon: "ห้วยมุ่น",
    amphoe: "น้ำปาด",
    province: "อุตรดิตถ์",
    zipcode: 53110,
    district_code: 530406,
    amphoe_code: 5304,
    province_code: 53,
  },
  {
    tambon: "เด่นเหล็ก",
    amphoe: "น้ำปาด",
    province: "อุตรดิตถ์",
    zipcode: 53110,
    district_code: 530403,
    amphoe_code: 5304,
    province_code: 53,
  },
  {
    tambon: "แสนตอ",
    amphoe: "น้ำปาด",
    province: "อุตรดิตถ์",
    zipcode: 53110,
    district_code: 530114,
    amphoe_code: 5304,
    province_code: 53,
  },
  {
    tambon: "นาขุม",
    amphoe: "บ้านโคก",
    province: "อุตรดิตถ์",
    zipcode: 53180,
    district_code: 530603,
    amphoe_code: 5306,
    province_code: 53,
  },
  {
    tambon: "บ่อเบี้ย",
    amphoe: "บ้านโคก",
    province: "อุตรดิตถ์",
    zipcode: 53180,
    district_code: 530604,
    amphoe_code: 5306,
    province_code: 53,
  },
  {
    tambon: "บ้านโคก",
    amphoe: "บ้านโคก",
    province: "อุตรดิตถ์",
    zipcode: 53180,
    district_code: 530602,
    amphoe_code: 5306,
    province_code: 53,
  },
  {
    tambon: "ม่วงเจ็ดต้น",
    amphoe: "บ้านโคก",
    province: "อุตรดิตถ์",
    zipcode: 53180,
    district_code: 530601,
    amphoe_code: 5306,
    province_code: 53,
  },
  {
    tambon: "คอรุม",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530705,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "ท่ามะเฟือง",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530707,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "ท่าสัก",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53220,
    district_code: 530704,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "นายาง",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530711,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "นาอิน",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530710,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "บ้านดารา",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53220,
    district_code: 530702,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "บ้านหม้อ",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530706,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "บ้านโคน",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530708,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "พญาแมน",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530709,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "ในเมือง",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530701,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "ไร่อ้อย",
    amphoe: "พิชัย",
    province: "อุตรดิตถ์",
    zipcode: 53120,
    district_code: 530703,
    amphoe_code: 5307,
    province_code: 53,
  },
  {
    tambon: "บ้านเสี้ยว",
    amphoe: "ฟากท่า",
    province: "อุตรดิตถ์",
    zipcode: 53160,
    district_code: 530503,
    amphoe_code: 5305,
    province_code: 53,
  },
  {
    tambon: "ฟากท่า",
    amphoe: "ฟากท่า",
    province: "อุตรดิตถ์",
    zipcode: 53160,
    district_code: 530501,
    amphoe_code: 5305,
    province_code: 53,
  },
  {
    tambon: "สองคอน",
    amphoe: "ฟากท่า",
    province: "อุตรดิตถ์",
    zipcode: 53160,
    district_code: 530502,
    amphoe_code: 5305,
    province_code: 53,
  },
  {
    tambon: "สองห้อง",
    amphoe: "ฟากท่า",
    province: "อุตรดิตถ์",
    zipcode: 53160,
    district_code: 530504,
    amphoe_code: 5305,
    province_code: 53,
  },
  {
    tambon: "ชัยจุมพล",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53130,
    district_code: 530805,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "ด่านแม่คำมัน",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53210,
    district_code: 530808,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "ทุ่งยั้ง",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53210,
    district_code: 530807,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "นานกกก",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53130,
    district_code: 530803,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "ฝายหลวง",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53130,
    district_code: 530804,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "ศรีพนมมาศ",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53130,
    district_code: 530801,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "แม่พูล",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53130,
    district_code: 530802,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "ไผ่ล้อม",
    amphoe: "ลับแล",
    province: "อุตรดิตถ์",
    zipcode: 53210,
    district_code: 530806,
    amphoe_code: 5308,
    province_code: 53,
  },
  {
    tambon: "ขุนฝาง",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530116,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "คุ้งตะเภา",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530105,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "งิ้วงาม",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530109,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "ถ้ำฉลอง",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530117,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "ท่าอิฐ",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530101,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "ท่าเสา",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530102,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "น้ำริด",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530108,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "บ้านด่าน",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530111,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "บ้านด่านนาขาม",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530110,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "บ้านเกาะ",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530103,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "ป่าเซ่า",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530104,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "ผาจุก",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530112,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "วังกะพี้",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53170,
    district_code: 530106,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "วังดิน",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530113,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "หาดกรวด",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530107,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "หาดงิ้ว",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530115,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "แสนตอ",
    amphoe: "เมืองอุตรดิตถ์",
    province: "อุตรดิตถ์",
    zipcode: 53000,
    district_code: 530114,
    amphoe_code: 5301,
    province_code: 53,
  },
  {
    tambon: "ตลุกดู่",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610210,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "ทัพทัน",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610201,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "ทุ่งนาไทย",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610202,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "หนองกระทุ่ม",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610208,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "หนองกลางดง",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610207,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "หนองยายดา",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610206,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "หนองสระ",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610209,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "หนองหญ้าปล้อง",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610204,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "เขาขี้ฝอย",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610203,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "โคกหม้อ",
    amphoe: "ทัพทัน",
    province: "อุทัยธานี",
    zipcode: 61120,
    district_code: 610205,
    amphoe_code: 6102,
    province_code: 61,
  },
  {
    tambon: "คอกควาย",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61140,
    district_code: 610604,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "ทัพหลวง",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61140,
    district_code: 610602,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "บ้านบึง",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61140,
    district_code: 610611,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "บ้านใหม่คลองเคียน",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61180,
    district_code: 610612,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61140,
    district_code: 610601,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "วังหิน",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61180,
    district_code: 610605,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "หนองจอก",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61180,
    district_code: 610609,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "หนองบ่มกล้วย",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61180,
    district_code: 610613,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "หูช้าง",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61180,
    district_code: 610610,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "ห้วยแห้ง",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61140,
    district_code: 610603,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "เจ้าวัด",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61150,
    district_code: 610614,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "เมืองการุ้ง",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61180,
    district_code: 610606,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "แก่นมะกรูด",
    amphoe: "บ้านไร่",
    province: "อุทัยธานี",
    zipcode: 61140,
    district_code: 610607,
    amphoe_code: 6106,
    province_code: 61,
  },
  {
    tambon: "ทุ่งนางาม",
    amphoe: "ลานสัก",
    province: "อุทัยธานี",
    zipcode: 61160,
    district_code: 610706,
    amphoe_code: 6107,
    province_code: 61,
  },
  {
    tambon: "น้ำรอบ",
    amphoe: "ลานสัก",
    province: "อุทัยธานี",
    zipcode: 61160,
    district_code: 610705,
    amphoe_code: 6107,
    province_code: 61,
  },
  {
    tambon: "ประดู่ยืน",
    amphoe: "ลานสัก",
    province: "อุทัยธานี",
    zipcode: 61160,
    district_code: 610698,
    amphoe_code: 6107,
    province_code: 61,
  },
  {
    tambon: "ป่าอ้อ",
    amphoe: "ลานสัก",
    province: "อุทัยธานี",
    zipcode: 61160,
    district_code: 610697,
    amphoe_code: 6107,
    province_code: 61,
  },
  {
    tambon: "ระบำ",
    amphoe: "ลานสัก",
    province: "อุทัยธานี",
    zipcode: 61160,
    district_code: 610704,
    amphoe_code: 6107,
    province_code: 61,
  },
  {
    tambon: "ลานสัก",
    amphoe: "ลานสัก",
    province: "อุทัยธานี",
    zipcode: 61160,
    district_code: 610699,
    amphoe_code: 6107,
    province_code: 61,
  },
  {
    tambon: "บ่อยาง",
    amphoe: "สว่างอารมณ์",
    province: "อุทัยธานี",
    zipcode: 61150,
    district_code: 610305,
    amphoe_code: 6103,
    province_code: 61,
  },
  {
    tambon: "พลวงสองนาง",
    amphoe: "สว่างอารมณ์",
    province: "อุทัยธานี",
    zipcode: 61150,
    district_code: 610303,
    amphoe_code: 6103,
    province_code: 61,
  },
  {
    tambon: "สว่างอารมณ์",
    amphoe: "สว่างอารมณ์",
    province: "อุทัยธานี",
    zipcode: 61150,
    district_code: 610301,
    amphoe_code: 6103,
    province_code: 61,
  },
  {
    tambon: "หนองหลวง",
    amphoe: "สว่างอารมณ์",
    province: "อุทัยธานี",
    zipcode: 61150,
    district_code: 610302,
    amphoe_code: 6103,
    province_code: 61,
  },
  {
    tambon: "ไผ่เขียว",
    amphoe: "สว่างอารมณ์",
    province: "อุทัยธานี",
    zipcode: 61150,
    district_code: 610304,
    amphoe_code: 6103,
    province_code: 61,
  },
  {
    tambon: "ดงขวาง",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610509,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "ดอนกลอย",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610503,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "ทุ่งพึ่ง",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610505,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "ท่าโพ",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610506,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "หนองขาหย่าง",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610501,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610502,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "หมกแถว",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610507,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "หลุมเข้า",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610508,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "ห้วยรอบ",
    amphoe: "หนองขาหย่าง",
    province: "อุทัยธานี",
    zipcode: 61130,
    district_code: 610504,
    amphoe_code: 6105,
    province_code: 61,
  },
  {
    tambon: "ทุ่งพง",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610408,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "ทุ่งโพ",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610407,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "บ้านเก่า",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610405,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "หนองฉาง",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610401,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "หนองนางนวล",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610403,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "หนองยาง",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610402,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "หนองสรวง",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610404,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "อุทัยเก่า",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610406,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "เขากวางทอง",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61110,
    district_code: 610410,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "เขาบางแกรก",
    amphoe: "หนองฉาง",
    province: "อุทัยธานี",
    zipcode: 61170,
    district_code: 610409,
    amphoe_code: 6104,
    province_code: 61,
  },
  {
    tambon: "ทองหลาง",
    amphoe: "ห้วยคต",
    province: "อุทัยธานี",
    zipcode: 61170,
    district_code: 610802,
    amphoe_code: 6108,
    province_code: 61,
  },
  {
    tambon: "สุขฤทัย",
    amphoe: "ห้วยคต",
    province: "อุทัยธานี",
    zipcode: 61170,
    district_code: 610696,
    amphoe_code: 6108,
    province_code: 61,
  },
  {
    tambon: "ห้วยคต",
    amphoe: "ห้วยคต",
    province: "อุทัยธานี",
    zipcode: 61170,
    district_code: 610695,
    amphoe_code: 6108,
    province_code: 61,
  },
  {
    tambon: "ดอนขวาง",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610104,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "ทุ่งใหญ่",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610113,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "ท่าซุง",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610107,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "น้ำซึม",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610102,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "สะแกกรัง",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610103,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "หนองพังค่า",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610112,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "หนองเต่า",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610110,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "หนองแก",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610108,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "หนองไผ่แบน",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610111,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "หาดทนง",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610105,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "อุทัยใหม่",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610101,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "เกาะเทโพ",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610106,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "เนินแจง",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610114,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "โนนเหล็ก",
    amphoe: "เมืองอุทัยธานี",
    province: "อุทัยธานี",
    zipcode: 61000,
    district_code: 610109,
    amphoe_code: 6101,
    province_code: 61,
  },
  {
    tambon: "กาบิน",
    amphoe: "กุดข้าวปุ้น",
    province: "อุบลราชธานี",
    zipcode: 34270,
    district_code: 341204,
    amphoe_code: 3412,
    province_code: 34,
  },
  {
    tambon: "ข้าวปุ้น",
    amphoe: "กุดข้าวปุ้น",
    province: "อุบลราชธานี",
    zipcode: 34270,
    district_code: 341201,
    amphoe_code: 3412,
    province_code: 34,
  },
  {
    tambon: "หนองทันน้ำ",
    amphoe: "กุดข้าวปุ้น",
    province: "อุบลราชธานี",
    zipcode: 34270,
    district_code: 341205,
    amphoe_code: 3412,
    province_code: 34,
  },
  {
    tambon: "แก่งเค็ง",
    amphoe: "กุดข้าวปุ้น",
    province: "อุบลราชธานี",
    zipcode: 34270,
    district_code: 341203,
    amphoe_code: 3412,
    province_code: 34,
  },
  {
    tambon: "โนนสวาง",
    amphoe: "กุดข้าวปุ้น",
    province: "อุบลราชธานี",
    zipcode: 34270,
    district_code: 341202,
    amphoe_code: 3412,
    province_code: 34,
  },
  {
    tambon: "คำไฮใหญ่",
    amphoe: "ดอนมดแดง",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340110,
    amphoe_code: 3424,
    province_code: 34,
  },
  {
    tambon: "ดอนมดแดง",
    amphoe: "ดอนมดแดง",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340106,
    amphoe_code: 3424,
    province_code: 34,
  },
  {
    tambon: "ท่าเมือง",
    amphoe: "ดอนมดแดง",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340103,
    amphoe_code: 3424,
    province_code: 34,
  },
  {
    tambon: "เหล่าแดง",
    amphoe: "ดอนมดแดง",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340114,
    amphoe_code: 3424,
    province_code: 34,
  },
  {
    tambon: "กระเดียน",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341102,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "กุดยาลวน",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341121,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "กุศกร",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341104,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ขามเปี้ย",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341105,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ขุหลุ",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341101,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "คอนสาย",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341106,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "คำเจริญ",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341123,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ตระการ",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341111,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ตากแดด",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341112,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ถ้ำแข้",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341118,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ท่าหลวง",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341119,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "นาพิน",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341108,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "นาสะไม",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341109,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "บ้านแดง",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341122,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "สะพือ",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341116,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "หนองเต่า",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341117,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ห้วยฝ้ายพัฒนา",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341120,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "เกษม",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341103,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "เซเป็ด",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341115,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "เป้า",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341114,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "โคกจาน",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341107,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "โนนกุง",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341110,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "ไหล่ทุ่ง",
    amphoe: "ตระการพืชผล",
    province: "อุบลราชธานี",
    zipcode: 34130,
    district_code: 341113,
    amphoe_code: 3411,
    province_code: 34,
  },
  {
    tambon: "คำหว้า",
    amphoe: "ตาลสุม",
    province: "อุบลราชธานี",
    zipcode: 34330,
    district_code: 342006,
    amphoe_code: 3420,
    province_code: 34,
  },
  {
    tambon: "จิกเทิง",
    amphoe: "ตาลสุม",
    province: "อุบลราชธานี",
    zipcode: 34330,
    district_code: 342003,
    amphoe_code: 3420,
    province_code: 34,
  },
  {
    tambon: "ตาลสุม",
    amphoe: "ตาลสุม",
    province: "อุบลราชธานี",
    zipcode: 34330,
    district_code: 342001,
    amphoe_code: 3420,
    province_code: 34,
  },
  {
    tambon: "นาคาย",
    amphoe: "ตาลสุม",
    province: "อุบลราชธานี",
    zipcode: 34330,
    district_code: 342005,
    amphoe_code: 3420,
    province_code: 34,
  },
  {
    tambon: "สำโรง",
    amphoe: "ตาลสุม",
    province: "อุบลราชธานี",
    zipcode: 34330,
    district_code: 341513,
    amphoe_code: 3420,
    province_code: 34,
  },
  {
    tambon: "หนองกุง",
    amphoe: "ตาลสุม",
    province: "อุบลราชธานี",
    zipcode: 34330,
    district_code: 342004,
    amphoe_code: 3420,
    province_code: 34,
  },
  {
    tambon: "กุดเรือ",
    amphoe: "ทุ่งศรีอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340718,
    amphoe_code: 3426,
    province_code: 34,
  },
  {
    tambon: "นาห่อม",
    amphoe: "ทุ่งศรีอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 342606,
    amphoe_code: 3426,
    province_code: 34,
  },
  {
    tambon: "นาเกษม",
    amphoe: "ทุ่งศรีอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340714,
    amphoe_code: 3426,
    province_code: 34,
  },
  {
    tambon: "หนองอ้ม",
    amphoe: "ทุ่งศรีอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340707,
    amphoe_code: 3426,
    province_code: 34,
  },
  {
    tambon: "โคกชำแระ",
    amphoe: "ทุ่งศรีอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340722,
    amphoe_code: 3426,
    province_code: 34,
  },
  {
    tambon: "นาจะหลวย",
    amphoe: "นาจะหลวย",
    province: "อุบลราชธานี",
    zipcode: 34280,
    district_code: 340801,
    amphoe_code: 3408,
    province_code: 34,
  },
  {
    tambon: "บ้านตูม",
    amphoe: "นาจะหลวย",
    province: "อุบลราชธานี",
    zipcode: 34280,
    district_code: 340804,
    amphoe_code: 3408,
    province_code: 34,
  },
  {
    tambon: "พรสวรรค์",
    amphoe: "นาจะหลวย",
    province: "อุบลราชธานี",
    zipcode: 34280,
    district_code: 340803,
    amphoe_code: 3408,
    province_code: 34,
  },
  {
    tambon: "โนนสมบูรณ์",
    amphoe: "นาจะหลวย",
    province: "อุบลราชธานี",
    zipcode: 34280,
    district_code: 340723,
    amphoe_code: 3408,
    province_code: 34,
  },
  {
    tambon: "โนนสวรรค์",
    amphoe: "นาจะหลวย",
    province: "อุบลราชธานี",
    zipcode: 34280,
    district_code: 340806,
    amphoe_code: 3408,
    province_code: 34,
  },
  {
    tambon: "โสกแสง",
    amphoe: "นาจะหลวย",
    province: "อุบลราชธานี",
    zipcode: 34280,
    district_code: 340805,
    amphoe_code: 3408,
    province_code: 34,
  },
  {
    tambon: "กองโพน",
    amphoe: "นาตาล",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340502,
    amphoe_code: 3430,
    province_code: 34,
  },
  {
    tambon: "นาตาล",
    amphoe: "นาตาล",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340506,
    amphoe_code: 3430,
    province_code: 34,
  },
  {
    tambon: "พะลาน",
    amphoe: "นาตาล",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340509,
    amphoe_code: 3430,
    province_code: 34,
  },
  {
    tambon: "พังเคน",
    amphoe: "นาตาล",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340505,
    amphoe_code: 3430,
    province_code: 34,
  },
  {
    tambon: "นาดี",
    amphoe: "นาเยีย",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340719,
    amphoe_code: 3429,
    province_code: 34,
  },
  {
    tambon: "นาเยีย",
    amphoe: "นาเยีย",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340703,
    amphoe_code: 3429,
    province_code: 34,
  },
  {
    tambon: "นาเรือง",
    amphoe: "นาเยีย",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340705,
    amphoe_code: 3429,
    province_code: 34,
  },
  {
    tambon: "ขี้เหล็ก",
    amphoe: "น้ำขุ่น",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340119,
    amphoe_code: 3433,
    province_code: 34,
  },
  {
    tambon: "ตาเกา",
    amphoe: "น้ำขุ่น",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340902,
    amphoe_code: 3433,
    province_code: 34,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "น้ำขุ่น",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340910,
    amphoe_code: 3433,
    province_code: 34,
  },
  {
    tambon: "ไพบูลย์",
    amphoe: "น้ำขุ่น",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340908,
    amphoe_code: 3433,
    province_code: 34,
  },
  {
    tambon: "บุเปือย",
    amphoe: "น้ำยืน",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340906,
    amphoe_code: 3409,
    province_code: 34,
  },
  {
    tambon: "ยาง",
    amphoe: "น้ำยืน",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340903,
    amphoe_code: 3409,
    province_code: 34,
  },
  {
    tambon: "ยางใหญ่",
    amphoe: "น้ำยืน",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340909,
    amphoe_code: 3409,
    province_code: 34,
  },
  {
    tambon: "สีวิเชียร",
    amphoe: "น้ำยืน",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340907,
    amphoe_code: 3409,
    province_code: 34,
  },
  {
    tambon: "เก่าขาม",
    amphoe: "น้ำยืน",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340911,
    amphoe_code: 3409,
    province_code: 34,
  },
  {
    tambon: "โซง",
    amphoe: "น้ำยืน",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340901,
    amphoe_code: 3409,
    province_code: 34,
  },
  {
    tambon: "โดมประดิษฐ์",
    amphoe: "น้ำยืน",
    province: "อุบลราชธานี",
    zipcode: 34260,
    district_code: 340904,
    amphoe_code: 3409,
    province_code: 34,
  },
  {
    tambon: "คอแลน",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 341003,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 341004,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "บัวงาม",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 340715,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "บ้านแมด",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 341008,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "หนองสะโน",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 341005,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "ห้วยข่า",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 341002,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "โนนค้อ",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 341006,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "โพนงาม",
    amphoe: "บุณฑริก",
    province: "อุบลราชธานี",
    zipcode: 34230,
    district_code: 340720,
    amphoe_code: 3410,
    province_code: 34,
  },
  {
    tambon: "กุดชมภู",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341902,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "ดอนจิก",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341904,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "ทรายมูล",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341905,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "นาโพธิ์",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341004,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "บ้านแขม",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341919,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "พิบูล",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341901,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "ระเว",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341911,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "หนองบัวฮี",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341913,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "อ่างศิลา",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341914,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "โนนกลาง",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341907,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "โนนกาหลง",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341918,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "โพธิ์ศรี",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341910,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "โพธิ์ไทร",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341909,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "ไร่ใต้",
    amphoe: "พิบูลมังสาหาร",
    province: "อุบลราชธานี",
    zipcode: 34110,
    district_code: 341912,
    amphoe_code: 3419,
    province_code: 34,
  },
  {
    tambon: "ดุมใหญ่",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341403,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "นาเลิง",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341413,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "ม่วงสามสิบ",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341401,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "ยางสักกระโพหลุ่ม",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341407,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "ยางโยภาพ",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341411,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "หนองช้างใหญ่",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341404,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "หนองฮาง",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341410,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "หนองเมือง",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341405,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "หนองเหล่า",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 340418,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "หนองไข่นก",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341408,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "เตย",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341406,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "เหล่าบก",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341402,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "โพนแพง",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341414,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "ไผ่ใหญ่",
    amphoe: "ม่วงสามสิบ",
    province: "อุบลราชธานี",
    zipcode: 34140,
    district_code: 341412,
    amphoe_code: 3414,
    province_code: 34,
  },
  {
    tambon: "คำขวาง",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341515,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "คำน้ำแซบ",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341510,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "คูเมือง",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341507,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "ท่าลาด",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34310,
    district_code: 341504,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "ธาตุ",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341502,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "บุ่งหวาย",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34310,
    district_code: 341511,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "บุ่งไหม",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341526,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "วารินชำราบ",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341501,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "สระสมิง",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341508,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "หนองกินเพล",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341520,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "ห้วยขะยูง",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34310,
    district_code: 341524,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "เมืองศรีไค",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341522,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "แสนสุข",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341518,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "โนนผึ้ง",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341521,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "โนนโหนน",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341505,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "โพธิ์ใหญ่",
    amphoe: "วารินชำราบ",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341516,
    amphoe_code: 3415,
    province_code: 34,
  },
  {
    tambon: "คำไหล",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340208,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "ดอนใหญ่",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340211,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "ตะบ่าย",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340207,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "นาคำ",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340201,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "นาเลิน",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340210,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "ลาดควาย",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340205,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "วาริน",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340204,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "สงยาง",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340206,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "หนามแท่ง",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340209,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "เอือดใหญ่",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340203,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "แก้งกอก",
    amphoe: "ศรีเมืองใหม่",
    province: "อุบลราชธานี",
    zipcode: 34250,
    district_code: 340202,
    amphoe_code: 3402,
    province_code: 34,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "สว่างวีระวงศ์",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341503,
    amphoe_code: 3432,
    province_code: 34,
  },
  {
    tambon: "บุ่งมะแลง",
    amphoe: "สว่างวีระวงศ์",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341523,
    amphoe_code: 3432,
    province_code: 34,
  },
  {
    tambon: "สว่าง",
    amphoe: "สว่างวีระวงศ์",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341514,
    amphoe_code: 3432,
    province_code: 34,
  },
  {
    tambon: "แก่งโดม",
    amphoe: "สว่างวีระวงศ์",
    province: "อุบลราชธานี",
    zipcode: 34190,
    district_code: 341525,
    amphoe_code: 3432,
    province_code: 34,
  },
  {
    tambon: "ขามป้อม",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 340503,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "ค้อน้อย",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 341509,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "บอน",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 342208,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "สำโรง",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 341513,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "หนองไฮ",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 341512,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "โคกก่อง",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 341517,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "โคกสว่าง",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 341519,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "โนนกลาง",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 341907,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "โนนกาเล็น",
    amphoe: "สำโรง",
    province: "อุบลราชธานี",
    zipcode: 34360,
    district_code: 341506,
    amphoe_code: 3422,
    province_code: 34,
  },
  {
    tambon: "คันไร่",
    amphoe: "สิรินธร",
    province: "อุบลราชธานี",
    zipcode: 34350,
    district_code: 341903,
    amphoe_code: 3425,
    province_code: 34,
  },
  {
    tambon: "คำเขื่อนแก้ว",
    amphoe: "สิรินธร",
    province: "อุบลราชธานี",
    zipcode: 34350,
    district_code: 340306,
    amphoe_code: 3425,
    province_code: 34,
  },
  {
    tambon: "ช่องเม็ก",
    amphoe: "สิรินธร",
    province: "อุบลราชธานี",
    zipcode: 34350,
    district_code: 341916,
    amphoe_code: 3425,
    province_code: 34,
  },
  {
    tambon: "นิคมสร้างตนเองลำโดมน้อย",
    amphoe: "สิรินธร",
    province: "อุบลราชธานี",
    zipcode: 34350,
    district_code: 342504,
    amphoe_code: 3425,
    province_code: 34,
  },
  {
    tambon: "ฝางคำ",
    amphoe: "สิรินธร",
    province: "อุบลราชธานี",
    zipcode: 34350,
    district_code: 341908,
    amphoe_code: 3425,
    province_code: 34,
  },
  {
    tambon: "โนนก่อ",
    amphoe: "สิรินธร",
    province: "อุบลราชธานี",
    zipcode: 34350,
    district_code: 341917,
    amphoe_code: 3425,
    province_code: 34,
  },
  {
    tambon: "ขามป้อม",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340503,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "นาแวง",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340508,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "หนองนกทา",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340511,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "หนองผือ",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340507,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "หนองสิม",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340512,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "หัวนา",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340513,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "เขมราฐ",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340501,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "เจียด",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340504,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "แก้งเหนือ",
    amphoe: "เขมราฐ",
    province: "อุบลราชธานี",
    zipcode: 34170,
    district_code: 340510,
    amphoe_code: 3405,
    province_code: 34,
  },
  {
    tambon: "กลางใหญ่",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34320,
    district_code: 340413,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "ก่อเอ้",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340404,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "ค้อทอง",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340403,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "ชีทวน",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340406,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "ท่าไห",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340407,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "ธาตุน้อย",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340410,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "นาคำใหญ่",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340408,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "บ้านกอก",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34320,
    district_code: 340412,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "บ้านไทย",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34320,
    district_code: 340411,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "ยางขี้นก",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340415,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "ศรีสุข",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340416,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "สร้างถ่อ",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340402,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "สหธาตุ",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340417,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "หนองเหล่า",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340418,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "หัวดอน",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340405,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "เขื่องใน",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340401,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "แดงหม้อ",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34150,
    district_code: 340409,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "โนนรัง",
    amphoe: "เขื่องใน",
    province: "อุบลราชธานี",
    zipcode: 34320,
    district_code: 340414,
    amphoe_code: 3404,
    province_code: 34,
  },
  {
    tambon: "กลาง",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340711,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "กุดประทาย",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340709,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "คำครั่ง",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340716,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "ตบหู",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340710,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "ทุ่งเทิง",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340706,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "ท่าโพธิ์ศรี",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340713,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "นากระแซง",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340717,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "นาส่วง",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340702,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "นาเจริญ",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340704,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "บัวงาม",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340715,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "ป่าโมง",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340721,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "สมสะอาด",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340708,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "เมืองเดช",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340701,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "แก้ง",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340712,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "โนนสมบูรณ์",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340723,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "โพนงาม",
    amphoe: "เดชอุดม",
    province: "อุบลราชธานี",
    zipcode: 34160,
    district_code: 340720,
    amphoe_code: 3407,
    province_code: 34,
  },
  {
    tambon: "กระโสบ",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340113,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "กุดลาด",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340116,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "ขามใหญ่",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340108,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "ขี้เหล็ก",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340119,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "ปทุม",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340107,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "ปะอาว",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340120,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "หนองขอน",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340105,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "หนองบ่อ",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340111,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "หัวเรือ",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340104,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "แจระแม",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340109,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340101,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "ไร่น้อย",
    amphoe: "เมืองอุบลราชธานี",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340112,
    amphoe_code: 3401,
    province_code: 34,
  },
  {
    tambon: "หนองบก",
    amphoe: "เหล่าเสือโก้ก",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340117,
    amphoe_code: 3431,
    province_code: 34,
  },
  {
    tambon: "เหล่าเสือโก้ก",
    amphoe: "เหล่าเสือโก้ก",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340115,
    amphoe_code: 3431,
    province_code: 34,
  },
  {
    tambon: "แพงใหญ่",
    amphoe: "เหล่าเสือโก้ก",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340118,
    amphoe_code: 3431,
    province_code: 34,
  },
  {
    tambon: "โพนเมือง",
    amphoe: "เหล่าเสือโก้ก",
    province: "อุบลราชธานี",
    zipcode: 34000,
    district_code: 340102,
    amphoe_code: 3431,
    province_code: 34,
  },
  {
    tambon: "นาโพธิ์กลาง",
    amphoe: "โขงเจียม",
    province: "อุบลราชธานี",
    zipcode: 34220,
    district_code: 340303,
    amphoe_code: 3403,
    province_code: 34,
  },
  {
    tambon: "หนองแสงใหญ่",
    amphoe: "โขงเจียม",
    province: "อุบลราชธานี",
    zipcode: 34220,
    district_code: 340304,
    amphoe_code: 3403,
    province_code: 34,
  },
  {
    tambon: "ห้วยยาง",
    amphoe: "โขงเจียม",
    province: "อุบลราชธานี",
    zipcode: 34220,
    district_code: 340302,
    amphoe_code: 3403,
    province_code: 34,
  },
  {
    tambon: "ห้วยไผ่",
    amphoe: "โขงเจียม",
    province: "อุบลราชธานี",
    zipcode: 34220,
    district_code: 340305,
    amphoe_code: 3403,
    province_code: 34,
  },
  {
    tambon: "โขงเจียม",
    amphoe: "โขงเจียม",
    province: "อุบลราชธานี",
    zipcode: 34220,
    district_code: 340301,
    amphoe_code: 3403,
    province_code: 34,
  },
  {
    tambon: "ม่วงใหญ่",
    amphoe: "โพธิ์ไทร",
    province: "อุบลราชธานี",
    zipcode: 34340,
    district_code: 342102,
    amphoe_code: 3421,
    province_code: 34,
  },
  {
    tambon: "สองคอน",
    amphoe: "โพธิ์ไทร",
    province: "อุบลราชธานี",
    zipcode: 34340,
    district_code: 342104,
    amphoe_code: 3421,
    province_code: 34,
  },
  {
    tambon: "สารภี",
    amphoe: "โพธิ์ไทร",
    province: "อุบลราชธานี",
    zipcode: 34340,
    district_code: 342105,
    amphoe_code: 3421,
    province_code: 34,
  },
  {
    tambon: "สำโรง",
    amphoe: "โพธิ์ไทร",
    province: "อุบลราชธานี",
    zipcode: 34340,
    district_code: 341513,
    amphoe_code: 3421,
    province_code: 34,
  },
  {
    tambon: "เหล่างาม",
    amphoe: "โพธิ์ไทร",
    province: "อุบลราชธานี",
    zipcode: 34340,
    district_code: 342106,
    amphoe_code: 3421,
    province_code: 34,
  },
  {
    tambon: "โพธิ์ไทร",
    amphoe: "โพธิ์ไทร",
    province: "อุบลราชธานี",
    zipcode: 34340,
    district_code: 341909,
    amphoe_code: 3421,
    province_code: 34,
  },
  {
    tambon: "นรสิงห์",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150306,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "บางปลากด",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150301,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "บางเสด็จ",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150305,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "ป่าโมก",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150302,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "สายทอง",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150303,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "เอกราช",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150307,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "โผงเผง",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150308,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "โรงช้าง",
    amphoe: "ป่าโมก",
    province: "อ่างทอง",
    zipcode: 14130,
    district_code: 150304,
    amphoe_code: 1503,
    province_code: 15,
  },
  {
    tambon: "คลองขนาก",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150609,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ตลาดใหม่",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150615,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150605,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "บางจัก",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150607,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ม่วงเตี้ย",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150612,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ยี่ล้น",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150606,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ศาลเจ้าโรงทอง",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150602,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "สาวร้องไห้",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150604,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "สี่ร้อย",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150611,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "หลักแก้ว",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150614,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "หัวตะพาน",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150613,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ห้วยคันแหลน",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150608,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ไผ่จำศีล",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: false,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ไผ่ดำพัฒนา",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150603,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "ไผ่วง",
    amphoe: "วิเศษชัยชาญ",
    province: "อ่างทอง",
    zipcode: 14110,
    district_code: 150610,
    amphoe_code: 1506,
    province_code: 15,
  },
  {
    tambon: "มงคลธรรมนิมิต",
    amphoe: "สามโก้",
    province: "อ่างทอง",
    zipcode: 14160,
    district_code: 150705,
    amphoe_code: 1507,
    province_code: 15,
  },
  {
    tambon: "ราษฎรพัฒนา",
    amphoe: "สามโก้",
    province: "อ่างทอง",
    zipcode: 14160,
    district_code: 150702,
    amphoe_code: 1507,
    province_code: 15,
  },
  {
    tambon: "สามโก้",
    amphoe: "สามโก้",
    province: "อ่างทอง",
    zipcode: 14160,
    district_code: 150701,
    amphoe_code: 1507,
    province_code: 15,
  },
  {
    tambon: "อบทม",
    amphoe: "สามโก้",
    province: "อ่างทอง",
    zipcode: 14160,
    district_code: 150703,
    amphoe_code: 1507,
    province_code: 15,
  },
  {
    tambon: "โพธิ์ม่วงพันธ์",
    amphoe: "สามโก้",
    province: "อ่างทอง",
    zipcode: 14160,
    district_code: 150704,
    amphoe_code: 1507,
    province_code: 15,
  },
  {
    tambon: "คลองวัว",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150113,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "จำปาหล่อ",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150110,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "ตลาดกรวด",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150106,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "ตลาดหลวง",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150101,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150102,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "บ้านรี",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150112,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "บ้านอิฐ",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150108,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "บ้านแห",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150105,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "ป่างิ้ว",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150104,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "มหาดไทย",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150107,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "ย่านซื่อ",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150114,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "ศาลาแดง",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150103,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "หัวไผ่",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150109,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "โพสะ",
    amphoe: "เมืองอ่างทอง",
    province: "อ่างทอง",
    zipcode: 14000,
    district_code: 150111,
    amphoe_code: 1501,
    province_code: 15,
  },
  {
    tambon: "จำลอง",
    amphoe: "แสวงหา",
    province: "อ่างทอง",
    zipcode: 14150,
    district_code: 150507,
    amphoe_code: 1505,
    province_code: 15,
  },
  {
    tambon: "บ้านพราน",
    amphoe: "แสวงหา",
    province: "อ่างทอง",
    zipcode: 14150,
    district_code: 150503,
    amphoe_code: 1505,
    province_code: 15,
  },
  {
    tambon: "วังน้ำเย็น",
    amphoe: "แสวงหา",
    province: "อ่างทอง",
    zipcode: 14150,
    district_code: 150504,
    amphoe_code: 1505,
    province_code: 15,
  },
  {
    tambon: "ศรีพราน",
    amphoe: "แสวงหา",
    province: "อ่างทอง",
    zipcode: 14150,
    district_code: 150502,
    amphoe_code: 1505,
    province_code: 15,
  },
  {
    tambon: "สีบัวทอง",
    amphoe: "แสวงหา",
    province: "อ่างทอง",
    zipcode: 14150,
    district_code: 150505,
    amphoe_code: 1505,
    province_code: 15,
  },
  {
    tambon: "ห้วยไผ่",
    amphoe: "แสวงหา",
    province: "อ่างทอง",
    zipcode: 14150,
    district_code: 150506,
    amphoe_code: 1505,
    province_code: 15,
  },
  {
    tambon: "แสวงหา",
    amphoe: "แสวงหา",
    province: "อ่างทอง",
    zipcode: 14150,
    district_code: 150501,
    amphoe_code: 1505,
    province_code: 15,
  },
  {
    tambon: "คำหยาด",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150415,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "ทางพระ",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150412,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "บางพลับ",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150403,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "บางระกำ",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150406,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "บางเจ้าฉ่า",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150414,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "บ่อแร่",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150411,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "ยางช้าย",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150410,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "รำมะสัก",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150405,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "สามง่าม",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150413,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "หนองแม่ไก่",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150404,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "องครักษ์",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150408,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "อินทประมูล",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150402,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "อ่างแก้ว",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150401,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "โคกพุทรา",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150409,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "โพธิ์รังนก",
    amphoe: "โพธิ์ทอง",
    province: "อ่างทอง",
    zipcode: 14120,
    district_code: 150407,
    amphoe_code: 1504,
    province_code: 15,
  },
  {
    tambon: "จรเข้ร้อง",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150201,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "ชะไว",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150208,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "ชัยฤทธิ์",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150203,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "ตรีณรงค์",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150209,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "ราชสถิตย์",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150205,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "หลักฟ้า",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150207,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "เทวราช",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150204,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "ไชยภูมิ",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150202,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "ไชโย",
    amphoe: "ไชโย",
    province: "อ่างทอง",
    zipcode: 14140,
    district_code: 150206,
    amphoe_code: 1502,
    province_code: 15,
  },
  {
    tambon: "ต้า",
    amphoe: "ขุนตาล",
    province: "เชียงราย",
    zipcode: 57340,
    district_code: 570496,
    amphoe_code: 5714,
    province_code: 57,
  },
  {
    tambon: "ป่าตาล",
    amphoe: "ขุนตาล",
    province: "เชียงราย",
    zipcode: 57340,
    district_code: 570499,
    amphoe_code: 5714,
    province_code: 57,
  },
  {
    tambon: "ยางฮอม",
    amphoe: "ขุนตาล",
    province: "เชียงราย",
    zipcode: 57340,
    district_code: 570497,
    amphoe_code: 5714,
    province_code: 57,
  },
  {
    tambon: "ปงน้อย",
    amphoe: "ดอยหลวง",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570707,
    amphoe_code: 5718,
    province_code: 57,
  },
  {
    tambon: "หนองป่าก่อ",
    amphoe: "ดอยหลวง",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570709,
    amphoe_code: 5718,
    province_code: 57,
  },
  {
    tambon: "โชคชัย",
    amphoe: "ดอยหลวง",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570717,
    amphoe_code: 5718,
    province_code: 57,
  },
  {
    tambon: "ป่าแงะ",
    amphoe: "ป่าแดด",
    province: "เชียงราย",
    zipcode: 57190,
    district_code: 570597,
    amphoe_code: 5706,
    province_code: 57,
  },
  {
    tambon: "ป่าแดด",
    amphoe: "ป่าแดด",
    province: "เชียงราย",
    zipcode: 57190,
    district_code: 570599,
    amphoe_code: 5706,
    province_code: 57,
  },
  {
    tambon: "ศรีโพธิ์เงิน",
    amphoe: "ป่าแดด",
    province: "เชียงราย",
    zipcode: 57190,
    district_code: 570606,
    amphoe_code: 5706,
    province_code: 57,
  },
  {
    tambon: "สันมะค่า",
    amphoe: "ป่าแดด",
    province: "เชียงราย",
    zipcode: 57190,
    district_code: 570598,
    amphoe_code: 5706,
    province_code: 57,
  },
  {
    tambon: "โรงช้าง",
    amphoe: "ป่าแดด",
    province: "เชียงราย",
    zipcode: 57190,
    district_code: 570605,
    amphoe_code: 5706,
    province_code: 57,
  },
  {
    tambon: "ตาดควัน",
    amphoe: "พญาเม็งราย",
    province: "เชียงราย",
    zipcode: 57290,
    district_code: 571205,
    amphoe_code: 5712,
    province_code: 57,
  },
  {
    tambon: "เม็งราย",
    amphoe: "พญาเม็งราย",
    province: "เชียงราย",
    zipcode: 57290,
    district_code: 571204,
    amphoe_code: 5712,
    province_code: 57,
  },
  {
    tambon: "แม่ต๋ำ",
    amphoe: "พญาเม็งราย",
    province: "เชียงราย",
    zipcode: 57290,
    district_code: 571202,
    amphoe_code: 5712,
    province_code: 57,
  },
  {
    tambon: "แม่เปา",
    amphoe: "พญาเม็งราย",
    province: "เชียงราย",
    zipcode: 57290,
    district_code: 570498,
    amphoe_code: 5712,
    province_code: 57,
  },
  {
    tambon: "ไม้ยา",
    amphoe: "พญาเม็งราย",
    province: "เชียงราย",
    zipcode: 57290,
    district_code: 571203,
    amphoe_code: 5712,
    province_code: 57,
  },
  {
    tambon: "ดอยงาม",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570505,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "ทรายขาว",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570510,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "ทานตะวัน",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57280,
    district_code: 570514,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "ธารทอง",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57250,
    district_code: 570503,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "ป่าหุ่ง",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570508,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "ม่วงคำ",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570509,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "สันกลาง",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570511,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "สันติสุข",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570504,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "สันมะเค็ด",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570501,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "หัวง้ม",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570506,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "เจริญเมือง",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570507,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "เมืองพาน",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570513,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "เวียงห้าว",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570515,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "แม่อ้อ",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57120,
    district_code: 570502,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "แม่เย็น",
    amphoe: "พาน",
    province: "เชียงราย",
    zipcode: 57280,
    district_code: 570512,
    amphoe_code: 5705,
    province_code: 57,
  },
  {
    tambon: "ครึ่ง",
    amphoe: "เชียงของ",
    province: "เชียงราย",
    zipcode: 57140,
    district_code: 570303,
    amphoe_code: 5703,
    province_code: 57,
  },
  {
    tambon: "บุญเรือง",
    amphoe: "เชียงของ",
    province: "เชียงราย",
    zipcode: 57140,
    district_code: 570304,
    amphoe_code: 5703,
    province_code: 57,
  },
  {
    tambon: "ริมโขง",
    amphoe: "เชียงของ",
    province: "เชียงราย",
    zipcode: 57140,
    district_code: 570310,
    amphoe_code: 5703,
    province_code: 57,
  },
  {
    tambon: "ศรีดอนชัย",
    amphoe: "เชียงของ",
    province: "เชียงราย",
    zipcode: 57140,
    district_code: 570308,
    amphoe_code: 5703,
    province_code: 57,
  },
  {
    tambon: "สถาน",
    amphoe: "เชียงของ",
    province: "เชียงราย",
    zipcode: 57140,
    district_code: 570302,
    amphoe_code: 5703,
    province_code: 57,
  },
  {
    tambon: "ห้วยซ้อ",
    amphoe: "เชียงของ",
    province: "เชียงราย",
    zipcode: 57140,
    district_code: 570305,
    amphoe_code: 5703,
    province_code: 57,
  },
  {
    tambon: "เวียง",
    amphoe: "เชียงของ",
    province: "เชียงราย",
    zipcode: 57140,
    district_code: 570101,
    amphoe_code: 5703,
    province_code: 57,
  },
  {
    tambon: "บ้านแซว",
    amphoe: "เชียงแสน",
    province: "เชียงราย",
    zipcode: 57150,
    district_code: 570803,
    amphoe_code: 5708,
    province_code: 57,
  },
  {
    tambon: "ป่าสัก",
    amphoe: "เชียงแสน",
    province: "เชียงราย",
    zipcode: 57150,
    district_code: 570802,
    amphoe_code: 5708,
    province_code: 57,
  },
  {
    tambon: "ศรีดอนมูล",
    amphoe: "เชียงแสน",
    province: "เชียงราย",
    zipcode: 57150,
    district_code: 570804,
    amphoe_code: 5708,
    province_code: 57,
  },
  {
    tambon: "เวียง",
    amphoe: "เชียงแสน",
    province: "เชียงราย",
    zipcode: 57150,
    district_code: 570101,
    amphoe_code: 5708,
    province_code: 57,
  },
  {
    tambon: "แม่เงิน",
    amphoe: "เชียงแสน",
    province: "เชียงราย",
    zipcode: 57150,
    district_code: 570805,
    amphoe_code: 5708,
    province_code: 57,
  },
  {
    tambon: "โยนก",
    amphoe: "เชียงแสน",
    province: "เชียงราย",
    zipcode: 57150,
    district_code: 570806,
    amphoe_code: 5708,
    province_code: 57,
  },
  {
    tambon: "งิ้ว",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57160,
    district_code: 570402,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "ตับเต่า",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57160,
    district_code: 570409,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "ปล้อง",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57230,
    district_code: 570403,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "ศรีดอนไชย",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57230,
    district_code: 570412,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "สันทรายงาม",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57160,
    district_code: 570411,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "หงาว",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57160,
    district_code: 570410,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "หนองแรด",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57230,
    district_code: 570413,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "เชียงเคี่ยน",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57230,
    district_code: 570405,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "เวียง",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57160,
    district_code: 570101,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "แม่ลอย",
    amphoe: "เทิง",
    province: "เชียงราย",
    zipcode: 57230,
    district_code: 570404,
    amphoe_code: 5704,
    province_code: 57,
  },
  {
    tambon: "ดอยลาน",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570115,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ดอยฮาง",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570120,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ท่าสาย",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570118,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ท่าสุด",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57100,
    district_code: 570121,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "นางแล",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57100,
    district_code: 570104,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "บ้านดู่",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57100,
    district_code: 570103,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ป่าอ้อดอนชัย",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570116,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "รอบเวียง",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570102,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ริมกก",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57100,
    district_code: 570114,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "สันทราย",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570107,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ห้วยชมภู",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570112,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ห้วยสัก",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570113,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "เวียง",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570101,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "แม่กรณ์",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570111,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "แม่ข้าวต้ม",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57100,
    district_code: 570105,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "แม่ยาว",
    amphoe: "เมืองเชียงราย",
    province: "เชียงราย",
    zipcode: 57100,
    district_code: 570106,
    amphoe_code: 5701,
    province_code: 57,
  },
  {
    tambon: "ดอนศิลา",
    amphoe: "เวียงชัย",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570206,
    amphoe_code: 5702,
    province_code: 57,
  },
  {
    tambon: "ผางาม",
    amphoe: "เวียงชัย",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570197,
    amphoe_code: 5702,
    province_code: 57,
  },
  {
    tambon: "เมืองชุม",
    amphoe: "เวียงชัย",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570208,
    amphoe_code: 5702,
    province_code: 57,
  },
  {
    tambon: "เวียงชัย",
    amphoe: "เวียงชัย",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570198,
    amphoe_code: 5702,
    province_code: 57,
  },
  {
    tambon: "เวียงเหนือ",
    amphoe: "เวียงชัย",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570196,
    amphoe_code: 5702,
    province_code: 57,
  },
  {
    tambon: "บ้านโป่ง",
    amphoe: "เวียงป่าเป้า",
    province: "เชียงราย",
    zipcode: 57170,
    district_code: 571103,
    amphoe_code: 5711,
    province_code: 57,
  },
  {
    tambon: "ป่างิ้ว",
    amphoe: "เวียงป่าเป้า",
    province: "เชียงราย",
    zipcode: 57170,
    district_code: 571104,
    amphoe_code: 5711,
    province_code: 57,
  },
  {
    tambon: "สันสลี",
    amphoe: "เวียงป่าเป้า",
    province: "เชียงราย",
    zipcode: 57170,
    district_code: 571101,
    amphoe_code: 5711,
    province_code: 57,
  },
  {
    tambon: "เวียง",
    amphoe: "เวียงป่าเป้า",
    province: "เชียงราย",
    zipcode: 57170,
    district_code: 570101,
    amphoe_code: 5711,
    province_code: 57,
  },
  {
    tambon: "เวียงกาหลง",
    amphoe: "เวียงป่าเป้า",
    province: "เชียงราย",
    zipcode: 57260,
    district_code: 571105,
    amphoe_code: 5711,
    province_code: 57,
  },
  {
    tambon: "แม่เจดีย์",
    amphoe: "เวียงป่าเป้า",
    province: "เชียงราย",
    zipcode: 57260,
    district_code: 571106,
    amphoe_code: 5711,
    province_code: 57,
  },
  {
    tambon: "แม่เจดีย์ใหม่",
    amphoe: "เวียงป่าเป้า",
    province: "เชียงราย",
    zipcode: 57260,
    district_code: 571107,
    amphoe_code: 5711,
    province_code: 57,
  },
  {
    tambon: "ดงมหาวัน",
    amphoe: "เวียงเชียงรุ้ง",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570207,
    amphoe_code: 5717,
    province_code: 57,
  },
  {
    tambon: "ทุ่งก่อ",
    amphoe: "เวียงเชียงรุ้ง",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570192,
    amphoe_code: 5717,
    province_code: 57,
  },
  {
    tambon: "ป่าซาง",
    amphoe: "เวียงเชียงรุ้ง",
    province: "เชียงราย",
    zipcode: 57210,
    district_code: 570205,
    amphoe_code: 5717,
    province_code: 57,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "เวียงแก่น",
    province: "เชียงราย",
    zipcode: 57310,
    district_code: 571304,
    amphoe_code: 5713,
    province_code: 57,
  },
  {
    tambon: "ปอ",
    amphoe: "เวียงแก่น",
    province: "เชียงราย",
    zipcode: 57310,
    district_code: 570307,
    amphoe_code: 5713,
    province_code: 57,
  },
  {
    tambon: "ม่วงยาย",
    amphoe: "เวียงแก่น",
    province: "เชียงราย",
    zipcode: 57310,
    district_code: 570306,
    amphoe_code: 5713,
    province_code: 57,
  },
  {
    tambon: "หล่ายงาว",
    amphoe: "เวียงแก่น",
    province: "เชียงราย",
    zipcode: 57310,
    district_code: 570309,
    amphoe_code: 5713,
    province_code: 57,
  },
  {
    tambon: "จอมสวรรค์",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570713,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "จันจว้า",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57270,
    district_code: 570702,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "จันจว้าใต้",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57270,
    district_code: 570712,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "ท่าข้าวเปลือก",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570706,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "ป่าซาง",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570205,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "ป่าตึง",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570708,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "ศรีค้ำ",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570711,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "สันทราย",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570107,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "แม่คำ",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57240,
    district_code: 570703,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "แม่จัน",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570701,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "แม่ไร่",
    amphoe: "แม่จัน",
    province: "เชียงราย",
    zipcode: 57240,
    district_code: 570710,
    amphoe_code: 5707,
    province_code: 57,
  },
  {
    tambon: "เทอดไทย",
    amphoe: "แม่ฟ้าหลวง",
    province: "เชียงราย",
    zipcode: 57240,
    district_code: 571501,
    amphoe_code: 5715,
    province_code: 57,
  },
  {
    tambon: "แม่ฟ้าหลวง",
    amphoe: "แม่ฟ้าหลวง",
    province: "เชียงราย",
    zipcode: 57240,
    district_code: 571504,
    amphoe_code: 5715,
    province_code: 57,
  },
  {
    tambon: "แม่สลองนอก",
    amphoe: "แม่ฟ้าหลวง",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 570716,
    amphoe_code: 5715,
    province_code: 57,
  },
  {
    tambon: "แม่สลองใน",
    amphoe: "แม่ฟ้าหลวง",
    province: "เชียงราย",
    zipcode: 57110,
    district_code: 571502,
    amphoe_code: 5715,
    province_code: 57,
  },
  {
    tambon: "จอมหมอกแก้ว",
    amphoe: "แม่ลาว",
    province: "เชียงราย",
    zipcode: 57250,
    district_code: 570117,
    amphoe_code: 5716,
    province_code: 57,
  },
  {
    tambon: "ดงมะดะ",
    amphoe: "แม่ลาว",
    province: "เชียงราย",
    zipcode: 57250,
    district_code: 570109,
    amphoe_code: 5716,
    province_code: 57,
  },
  {
    tambon: "บัวสลี",
    amphoe: "แม่ลาว",
    province: "เชียงราย",
    zipcode: 57250,
    district_code: 570108,
    amphoe_code: 5716,
    province_code: 57,
  },
  {
    tambon: "ป่าก่อดำ",
    amphoe: "แม่ลาว",
    province: "เชียงราย",
    zipcode: 57250,
    district_code: 570110,
    amphoe_code: 5716,
    province_code: 57,
  },
  {
    tambon: "โป่งแพร่",
    amphoe: "แม่ลาว",
    province: "เชียงราย",
    zipcode: 57000,
    district_code: 570119,
    amphoe_code: 5716,
    province_code: 57,
  },
  {
    tambon: "ท่าก๊อ",
    amphoe: "แม่สรวย",
    province: "เชียงราย",
    zipcode: 57180,
    district_code: 571005,
    amphoe_code: 5710,
    province_code: 57,
  },
  {
    tambon: "ป่าแดด",
    amphoe: "แม่สรวย",
    province: "เชียงราย",
    zipcode: 57180,
    district_code: 570599,
    amphoe_code: 5710,
    province_code: 57,
  },
  {
    tambon: "วาวี",
    amphoe: "แม่สรวย",
    province: "เชียงราย",
    zipcode: 57180,
    district_code: 571006,
    amphoe_code: 5710,
    province_code: 57,
  },
  {
    tambon: "ศรีถ้อย",
    amphoe: "แม่สรวย",
    province: "เชียงราย",
    zipcode: 57180,
    district_code: 571004,
    amphoe_code: 5710,
    province_code: 57,
  },
  {
    tambon: "เจดีย์หลวง",
    amphoe: "แม่สรวย",
    province: "เชียงราย",
    zipcode: 57180,
    district_code: 571007,
    amphoe_code: 5710,
    province_code: 57,
  },
  {
    tambon: "แม่พริก",
    amphoe: "แม่สรวย",
    province: "เชียงราย",
    zipcode: 57180,
    district_code: 571003,
    amphoe_code: 5710,
    province_code: 57,
  },
  {
    tambon: "แม่สรวย",
    amphoe: "แม่สรวย",
    province: "เชียงราย",
    zipcode: 57180,
    district_code: 571001,
    amphoe_code: 5710,
    province_code: 57,
  },
  {
    tambon: "บ้านด้าย",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57220,
    district_code: 570908,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "ศรีเมืองชุม",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57130,
    district_code: 570905,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "ห้วยไคร้",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57220,
    district_code: 570902,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "เกาะช้าง",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57130,
    district_code: 570903,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "เวียงพางคำ",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57130,
    district_code: 570906,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "แม่สาย",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57130,
    district_code: 570901,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "โป่งงาม",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57130,
    district_code: 570909,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "โป่งผา",
    amphoe: "แม่สาย",
    province: "เชียงราย",
    zipcode: 57130,
    district_code: 570904,
    amphoe_code: 5709,
    province_code: 57,
  },
  {
    tambon: "บ้านจันทร์",
    amphoe: "กัลยาณิวัฒนา",
    province: "เชียงใหม่",
    zipcode: 58130,
    district_code: 500306,
    amphoe_code: false,
    province_code: 50,
  },
  {
    tambon: "แจ่มหลวง",
    amphoe: "กัลยาณิวัฒนา",
    province: "เชียงใหม่",
    zipcode: 58130,
    district_code: 500310,
    amphoe_code: false,
    province_code: 50,
  },
  {
    tambon: "แม่แดด",
    amphoe: "กัลยาณิวัฒนา",
    province: "เชียงใหม่",
    zipcode: 58130,
    district_code: 500309,
    amphoe_code: false,
    province_code: 50,
  },
  {
    tambon: "ข่วงเปา",
    amphoe: "จอมทอง",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500204,
    amphoe_code: 5002,
    province_code: 50,
  },
  {
    tambon: "ดอยแก้ว",
    amphoe: "จอมทอง",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500207,
    amphoe_code: 5002,
    province_code: 50,
  },
  {
    tambon: "บ้านหลวง",
    amphoe: "จอมทอง",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500203,
    amphoe_code: 5002,
    province_code: 50,
  },
  {
    tambon: "บ้านแปะ",
    amphoe: "จอมทอง",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 500206,
    amphoe_code: 5002,
    province_code: 50,
  },
  {
    tambon: "สบเตี๊ยะ",
    amphoe: "จอมทอง",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500205,
    amphoe_code: 5002,
    province_code: 50,
  },
  {
    tambon: "แม่สอย",
    amphoe: "จอมทอง",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 500209,
    amphoe_code: 5002,
    province_code: 50,
  },
  {
    tambon: "ตลาดขวัญ",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500507,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "ตลาดใหญ่",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500510,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "ป่าป้อง",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500504,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "ป่าลาน",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500506,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "ป่าเมี่ยง",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500513,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "ลวงเหนือ",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500503,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "สง่าบ้าน",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500505,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "สันปูเลย",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500502,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "สำราญราษฎร์",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500508,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "เชิงดอย",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500501,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "เทพเสด็จ",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500514,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "แม่คือ",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500509,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "แม่ฮ้อยเงิน",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500511,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "แม่โป่ง",
    amphoe: "ดอยสะเก็ด",
    province: "เชียงใหม่",
    zipcode: 50220,
    district_code: 500512,
    amphoe_code: 5005,
    province_code: 50,
  },
  {
    tambon: "ดอยหล่อ",
    amphoe: "ดอยหล่อ",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500208,
    amphoe_code: 5024,
    province_code: 50,
  },
  {
    tambon: "ยางคราม",
    amphoe: "ดอยหล่อ",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500201,
    amphoe_code: 5024,
    province_code: 50,
  },
  {
    tambon: "สองแคว",
    amphoe: "ดอยหล่อ",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500202,
    amphoe_code: 5024,
    province_code: 50,
  },
  {
    tambon: "สันติสุข",
    amphoe: "ดอยหล่อ",
    province: "เชียงใหม่",
    zipcode: 50160,
    district_code: 500210,
    amphoe_code: 5024,
    province_code: 50,
  },
  {
    tambon: "ดอยเต่า",
    amphoe: "ดอยเต่า",
    province: "เชียงใหม่",
    zipcode: 50260,
    district_code: 501701,
    amphoe_code: 5017,
    province_code: 50,
  },
  {
    tambon: "ท่าเดื่อ",
    amphoe: "ดอยเต่า",
    province: "เชียงใหม่",
    zipcode: 50260,
    district_code: 501702,
    amphoe_code: 5017,
    province_code: 50,
  },
  {
    tambon: "บงตัน",
    amphoe: "ดอยเต่า",
    province: "เชียงใหม่",
    zipcode: 50260,
    district_code: 501705,
    amphoe_code: 5017,
    province_code: 50,
  },
  {
    tambon: "บ้านแอ่น",
    amphoe: "ดอยเต่า",
    province: "เชียงใหม่",
    zipcode: 50260,
    district_code: 501704,
    amphoe_code: 5017,
    province_code: 50,
  },
  {
    tambon: "มืดกา",
    amphoe: "ดอยเต่า",
    province: "เชียงใหม่",
    zipcode: 50260,
    district_code: 501703,
    amphoe_code: 5017,
    province_code: 50,
  },
  {
    tambon: "โปงทุ่ง",
    amphoe: "ดอยเต่า",
    province: "เชียงใหม่",
    zipcode: 50260,
    district_code: 501706,
    amphoe_code: 5017,
    province_code: 50,
  },
  {
    tambon: "ม่อนปิ่น",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50110,
    district_code: 500903,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "สันทราย",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50110,
    district_code: 500906,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "เวียง",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50110,
    district_code: 500901,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "แม่ข่า",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50320,
    district_code: 500911,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "แม่คะ",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50110,
    district_code: 500910,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "แม่งอน",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50320,
    district_code: 500904,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "แม่สูน",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50110,
    district_code: 500905,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "โป่งน้ำร้อน",
    amphoe: "ฝาง",
    province: "เชียงใหม่",
    zipcode: 50110,
    district_code: 500912,
    amphoe_code: 5009,
    province_code: 50,
  },
  {
    tambon: "ทุ่งหลวง",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501102,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "น้ำแพร่",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501107,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "บ้านโป่ง",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501106,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "ป่าตุ้ม",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501103,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "ป่าไหน่",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501104,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "สันทราย",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 500906,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "เขื่อนผาก",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501108,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "เวียง",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 500901,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "แม่ปั๋ง",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501110,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "แม่แวน",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501109,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "โหล่งขอด",
    amphoe: "พร้าว",
    province: "เชียงใหม่",
    zipcode: 50190,
    district_code: 501111,
    amphoe_code: 5011,
    province_code: 50,
  },
  {
    tambon: "บ่อแก้ว",
    amphoe: "สะเมิง",
    province: "เชียงใหม่",
    zipcode: 50250,
    district_code: 500804,
    amphoe_code: 5008,
    province_code: 50,
  },
  {
    tambon: "ยั้งเมิน",
    amphoe: "สะเมิง",
    province: "เชียงใหม่",
    zipcode: 50250,
    district_code: 500805,
    amphoe_code: 5008,
    province_code: 50,
  },
  {
    tambon: "สะเมิงเหนือ",
    amphoe: "สะเมิง",
    province: "เชียงใหม่",
    zipcode: 50250,
    district_code: 500802,
    amphoe_code: 5008,
    province_code: 50,
  },
  {
    tambon: "สะเมิงใต้",
    amphoe: "สะเมิง",
    province: "เชียงใหม่",
    zipcode: 50250,
    district_code: 500801,
    amphoe_code: 5008,
    province_code: 50,
  },
  {
    tambon: "แม่สาบ",
    amphoe: "สะเมิง",
    province: "เชียงใหม่",
    zipcode: 50250,
    district_code: 500803,
    amphoe_code: 5008,
    province_code: 50,
  },
  {
    tambon: "ต้นเปา",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501312,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "ทรายมูล",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501302,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "บวกค้าง",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501304,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "ร้องวัวแดง",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501303,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "สันกลาง",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501202,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "สันกำแพง",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501301,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "ห้วยทราย",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 500706,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "ออนใต้",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501306,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "แช่ช้าง",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501305,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "แม่ปูคา",
    amphoe: "สันกำแพง",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501310,
    amphoe_code: 5013,
    province_code: 50,
  },
  {
    tambon: "ป่าไผ่",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501412,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "สันทรายน้อย",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501402,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "สันทรายหลวง",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501401,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "สันนาเม็ง",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501404,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "สันป่าเปา",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501405,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "สันพระเนตร",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501403,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "หนองจ๊อม",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501407,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "หนองหาร",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50290,
    district_code: 501408,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "หนองแหย่ง",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501406,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "เมืองเล็น",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50210,
    district_code: 501411,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "แม่แฝก",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50290,
    district_code: 501409,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "แม่แฝกใหม่",
    amphoe: "สันทราย",
    province: "เชียงใหม่",
    zipcode: 50290,
    district_code: 501410,
    amphoe_code: 5014,
    province_code: 50,
  },
  {
    tambon: "ทุ่งต้อม",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501210,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "ทุ่งสะโตก",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501208,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "ท่าวังพร้าว",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501203,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "น้ำบ่อหลวง",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501214,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501207,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "บ้านแม",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501206,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "มะขามหลวง",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501204,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "มะขุนหวาน",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501215,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "ยุหว่า",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501201,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "สันกลาง",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501202,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "แม่ก๊า",
    amphoe: "สันป่าตอง",
    province: "เชียงใหม่",
    zipcode: 50120,
    district_code: 501205,
    amphoe_code: 5012,
    province_code: 50,
  },
  {
    tambon: "ขัวมุง",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501905,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ชมภู",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501903,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ดอนแก้ว",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 500710,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ท่ากว้าง",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501908,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ท่าวังตาล",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501910,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ป่าบง",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501912,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ยางเนิ้ง",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501901,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "สันทราย",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 500906,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "สารภี",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501902,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "หนองผึ้ง",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501907,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "หนองแฝก",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501906,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ไชยสถาน",
    amphoe: "สารภี",
    province: "เชียงใหม่",
    zipcode: 50140,
    district_code: 501904,
    amphoe_code: 5019,
    province_code: 50,
  },
  {
    tambon: "ขุนคง",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501505,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "น้ำแพร่",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501107,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "บ้านปง",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501510,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "บ้านแหวน",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501507,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "สบแม่ข่า",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501506,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "สันผักหวาน",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501508,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "หนองควาย",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501509,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "หนองตอง",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50340,
    district_code: 501504,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "หนองแก๋ว",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501502,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "หางดง",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501501,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "หารแก้ว",
    amphoe: "หางดง",
    province: "เชียงใหม่",
    zipcode: 50230,
    district_code: 501503,
    amphoe_code: 5015,
    province_code: 50,
  },
  {
    tambon: "นาเกียน",
    amphoe: "อมก๋อย",
    province: "เชียงใหม่",
    zipcode: 50310,
    district_code: 501806,
    amphoe_code: 5018,
    province_code: 50,
  },
  {
    tambon: "ม่อนจอง",
    amphoe: "อมก๋อย",
    province: "เชียงใหม่",
    zipcode: 50310,
    district_code: 501804,
    amphoe_code: 5018,
    province_code: 50,
  },
  {
    tambon: "ยางเปียง",
    amphoe: "อมก๋อย",
    province: "เชียงใหม่",
    zipcode: 50310,
    district_code: 501802,
    amphoe_code: 5018,
    province_code: 50,
  },
  {
    tambon: "สบโขง",
    amphoe: "อมก๋อย",
    province: "เชียงใหม่",
    zipcode: 50310,
    district_code: 501805,
    amphoe_code: 5018,
    province_code: 50,
  },
  {
    tambon: "อมก๋อย",
    amphoe: "อมก๋อย",
    province: "เชียงใหม่",
    zipcode: 50310,
    district_code: 501801,
    amphoe_code: 5018,
    province_code: 50,
  },
  {
    tambon: "แม่ตื่น",
    amphoe: "อมก๋อย",
    province: "เชียงใหม่",
    zipcode: 50310,
    district_code: 501803,
    amphoe_code: 5018,
    province_code: 50,
  },
  {
    tambon: "นาคอเรือ",
    amphoe: "ฮอด",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 501606,
    amphoe_code: 5016,
    province_code: 50,
  },
  {
    tambon: "บ่อสลี",
    amphoe: "ฮอด",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 501605,
    amphoe_code: 5016,
    province_code: 50,
  },
  {
    tambon: "บ่อหลวง",
    amphoe: "ฮอด",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 501604,
    amphoe_code: 5016,
    province_code: 50,
  },
  {
    tambon: "บ้านตาล",
    amphoe: "ฮอด",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 501603,
    amphoe_code: 5016,
    province_code: 50,
  },
  {
    tambon: "หางดง",
    amphoe: "ฮอด",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 501501,
    amphoe_code: 5016,
    province_code: 50,
  },
  {
    tambon: "ฮอด",
    amphoe: "ฮอด",
    province: "เชียงใหม่",
    zipcode: 50240,
    district_code: 501602,
    amphoe_code: 5016,
    province_code: 50,
  },
  {
    tambon: "ทุ่งข้าวพวง",
    amphoe: "เชียงดาว",
    province: "เชียงใหม่",
    zipcode: 50170,
    district_code: 500407,
    amphoe_code: 5004,
    province_code: 50,
  },
  {
    tambon: "ปิงโค้ง",
    amphoe: "เชียงดาว",
    province: "เชียงใหม่",
    zipcode: 50170,
    district_code: 500406,
    amphoe_code: 5004,
    province_code: 50,
  },
  {
    tambon: "เชียงดาว",
    amphoe: "เชียงดาว",
    province: "เชียงใหม่",
    zipcode: 50170,
    district_code: 500401,
    amphoe_code: 5004,
    province_code: 50,
  },
  {
    tambon: "เมืองคอง",
    amphoe: "เชียงดาว",
    province: "เชียงใหม่",
    zipcode: 50170,
    district_code: 500405,
    amphoe_code: 5004,
    province_code: 50,
  },
  {
    tambon: "เมืองงาย",
    amphoe: "เชียงดาว",
    province: "เชียงใหม่",
    zipcode: 50170,
    district_code: 500403,
    amphoe_code: 5004,
    province_code: 50,
  },
  {
    tambon: "เมืองนะ",
    amphoe: "เชียงดาว",
    province: "เชียงใหม่",
    zipcode: 50170,
    district_code: 500402,
    amphoe_code: 5004,
    province_code: 50,
  },
  {
    tambon: "แม่นะ",
    amphoe: "เชียงดาว",
    province: "เชียงใหม่",
    zipcode: 50170,
    district_code: 500404,
    amphoe_code: 5004,
    province_code: 50,
  },
  {
    tambon: "ช้างคลาน",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50100,
    district_code: 500105,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "ช้างม่อย",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50300,
    district_code: 500104,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "ช้างเผือก",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50300,
    district_code: 500107,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "ท่าศาลา",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50000,
    district_code: 500112,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "ป่าตัน",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50300,
    district_code: 500115,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "ป่าแดด",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50100,
    district_code: 500110,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "พระสิงห์",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50200,
    district_code: 500102,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "ฟ้าฮ่าม",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50000,
    district_code: 500114,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "วัดเกต",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50000,
    district_code: 500106,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "ศรีภูมิ",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50200,
    district_code: 500101,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "สันผีเสื้อ",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50300,
    district_code: 500116,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "สุเทพ",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50200,
    district_code: 500108,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "หนองป่าครั่ง",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50000,
    district_code: 500113,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "หนองหอย",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50000,
    district_code: 500111,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "หายยา",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50100,
    district_code: 500103,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "แม่เหียะ",
    amphoe: "เมืองเชียงใหม่",
    province: "เชียงใหม่",
    zipcode: 50100,
    district_code: 500109,
    amphoe_code: 5001,
    province_code: 50,
  },
  {
    tambon: "เปียงหลวง",
    amphoe: "เวียงแหง",
    province: "เชียงใหม่",
    zipcode: 50350,
    district_code: 502002,
    amphoe_code: 5020,
    province_code: 50,
  },
  {
    tambon: "เมืองแหง",
    amphoe: "เวียงแหง",
    province: "เชียงใหม่",
    zipcode: 50350,
    district_code: 502001,
    amphoe_code: 5020,
    province_code: 50,
  },
  {
    tambon: "แสนไห",
    amphoe: "เวียงแหง",
    province: "เชียงใหม่",
    zipcode: 50350,
    district_code: 502003,
    amphoe_code: 5020,
    province_code: 50,
  },
  {
    tambon: "ขี้เหล็ก",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500603,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "ดอนแก้ว",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500710,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "ริมเหนือ",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500702,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "ริมใต้",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500701,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "สะลวง",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50330,
    district_code: 500705,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "สันโป่ง",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500703,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "ห้วยทราย",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500706,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "เหมืองแก้ว",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500711,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "แม่สา",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500709,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "แม่แรม",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500707,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "โป่งแยง",
    amphoe: "แม่ริม",
    province: "เชียงใหม่",
    zipcode: 50180,
    district_code: 500708,
    amphoe_code: 5007,
    province_code: 50,
  },
  {
    tambon: "ดอนเปา",
    amphoe: "แม่วาง",
    province: "เชียงใหม่",
    zipcode: 50360,
    district_code: 502205,
    amphoe_code: 5022,
    province_code: 50,
  },
  {
    tambon: "ทุ่งปี้",
    amphoe: "แม่วาง",
    province: "เชียงใหม่",
    zipcode: 50360,
    district_code: 501209,
    amphoe_code: 5022,
    province_code: 50,
  },
  {
    tambon: "ทุ่งรวงทอง",
    amphoe: "แม่วาง",
    province: "เชียงใหม่",
    zipcode: 50360,
    district_code: 501213,
    amphoe_code: 5022,
    province_code: 50,
  },
  {
    tambon: "บ้านกาด",
    amphoe: "แม่วาง",
    province: "เชียงใหม่",
    zipcode: 50360,
    district_code: 501211,
    amphoe_code: 5022,
    province_code: 50,
  },
  {
    tambon: "แม่วิน",
    amphoe: "แม่วาง",
    province: "เชียงใหม่",
    zipcode: 50360,
    district_code: 501212,
    amphoe_code: 5022,
    province_code: 50,
  },
  {
    tambon: "ทาเหนือ",
    amphoe: "แม่ออน",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501315,
    amphoe_code: 5023,
    province_code: 50,
  },
  {
    tambon: "บ้านสหกรณ์",
    amphoe: "แม่ออน",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501308,
    amphoe_code: 5023,
    province_code: 50,
  },
  {
    tambon: "ห้วยแก้ว",
    amphoe: "แม่ออน",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501309,
    amphoe_code: 5023,
    province_code: 50,
  },
  {
    tambon: "ออนกลาง",
    amphoe: "แม่ออน",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501316,
    amphoe_code: 5023,
    province_code: 50,
  },
  {
    tambon: "ออนเหนือ",
    amphoe: "แม่ออน",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501307,
    amphoe_code: 5023,
    province_code: 50,
  },
  {
    tambon: "แม่ทา",
    amphoe: "แม่ออน",
    province: "เชียงใหม่",
    zipcode: 50130,
    district_code: 501314,
    amphoe_code: 5023,
    province_code: 50,
  },
  {
    tambon: "ท่าตอน",
    amphoe: "แม่อาย",
    province: "เชียงใหม่",
    zipcode: 50280,
    district_code: 501005,
    amphoe_code: 5010,
    province_code: 50,
  },
  {
    tambon: "บ้านหลวง",
    amphoe: "แม่อาย",
    province: "เชียงใหม่",
    zipcode: 50280,
    district_code: 500203,
    amphoe_code: 5010,
    province_code: 50,
  },
  {
    tambon: "มะลิกา",
    amphoe: "แม่อาย",
    province: "เชียงใหม่",
    zipcode: 50280,
    district_code: 501007,
    amphoe_code: 5010,
    province_code: 50,
  },
  {
    tambon: "สันต้นหมื้อ",
    amphoe: "แม่อาย",
    province: "เชียงใหม่",
    zipcode: 50280,
    district_code: 501003,
    amphoe_code: 5010,
    province_code: 50,
  },
  {
    tambon: "แม่นาวาง",
    amphoe: "แม่อาย",
    province: "เชียงใหม่",
    zipcode: 50280,
    district_code: 500995,
    amphoe_code: 5010,
    province_code: 50,
  },
  {
    tambon: "แม่สาว",
    amphoe: "แม่อาย",
    province: "เชียงใหม่",
    zipcode: 50280,
    district_code: 500996,
    amphoe_code: 5010,
    province_code: 50,
  },
  {
    tambon: "แม่อาย",
    amphoe: "แม่อาย",
    province: "เชียงใหม่",
    zipcode: 50280,
    district_code: 500997,
    amphoe_code: 5010,
    province_code: 50,
  },
  {
    tambon: "กองแขก",
    amphoe: "แม่แจ่ม",
    province: "เชียงใหม่",
    zipcode: 50270,
    district_code: 500308,
    amphoe_code: 5003,
    province_code: 50,
  },
  {
    tambon: "ช่างเคิ่ง",
    amphoe: "แม่แจ่ม",
    province: "เชียงใหม่",
    zipcode: 50270,
    district_code: 500301,
    amphoe_code: 5003,
    province_code: 50,
  },
  {
    tambon: "ท่าผา",
    amphoe: "แม่แจ่ม",
    province: "เชียงใหม่",
    zipcode: 50270,
    district_code: 500302,
    amphoe_code: 5003,
    province_code: 50,
  },
  {
    tambon: "บ้านทับ",
    amphoe: "แม่แจ่ม",
    province: "เชียงใหม่",
    zipcode: 50270,
    district_code: 500303,
    amphoe_code: 5003,
    province_code: 50,
  },
  {
    tambon: "ปางหินฝน",
    amphoe: "แม่แจ่ม",
    province: "เชียงใหม่",
    zipcode: 50270,
    district_code: 500307,
    amphoe_code: 5003,
    province_code: 50,
  },
  {
    tambon: "แม่นาจร",
    amphoe: "แม่แจ่ม",
    province: "เชียงใหม่",
    zipcode: 50270,
    district_code: 500305,
    amphoe_code: 5003,
    province_code: 50,
  },
  {
    tambon: "แม่ศึก",
    amphoe: "แม่แจ่ม",
    province: "เชียงใหม่",
    zipcode: 50270,
    district_code: 500304,
    amphoe_code: 5003,
    province_code: 50,
  },
  {
    tambon: "กื้ดช้าง",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500612,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "ขี้เหล็ก",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500603,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "ช่อแล",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500604,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "บ้านช้าง",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500611,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "บ้านเป้า",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500607,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "ป่าแป๋",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500609,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "สบเปิง",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500606,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "สันป่ายาง",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50330,
    district_code: 500608,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "สันมหาพน",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500601,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "อินทขิล",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500613,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "เมืองก๋าย",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500610,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "แม่หอพระ",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500605,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "แม่แตง",
    amphoe: "แม่แตง",
    province: "เชียงใหม่",
    zipcode: 50150,
    district_code: 500602,
    amphoe_code: 5006,
    province_code: 50,
  },
  {
    tambon: "ปงตำ",
    amphoe: "ไชยปราการ",
    province: "เชียงใหม่",
    zipcode: 50320,
    district_code: 500902,
    amphoe_code: 5021,
    province_code: 50,
  },
  {
    tambon: "ศรีดงเย็น",
    amphoe: "ไชยปราการ",
    province: "เชียงใหม่",
    zipcode: 50320,
    district_code: 500907,
    amphoe_code: 5021,
    province_code: 50,
  },
  {
    tambon: "หนองบัว",
    amphoe: "ไชยปราการ",
    province: "เชียงใหม่",
    zipcode: 50320,
    district_code: 500909,
    amphoe_code: 5021,
    province_code: 50,
  },
  {
    tambon: "แม่ทะลบ",
    amphoe: "ไชยปราการ",
    province: "เชียงใหม่",
    zipcode: 50320,
    district_code: 500908,
    amphoe_code: 5021,
    province_code: 50,
  },
  {
    tambon: "ชะอำ",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760401,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "ดอนขุนห้วย",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760409,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "นายาง",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760403,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "บางเก่า",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760402,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "สามพระยา",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760408,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "หนองศาลา",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760405,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "ห้วยทรายเหนือ",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760406,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "เขาใหญ่",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760404,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "ไร่ใหม่พัฒนา",
    amphoe: "ชะอำ",
    province: "เพชรบุรี",
    zipcode: 76120,
    district_code: 760407,
    amphoe_code: 7604,
    province_code: 76,
  },
  {
    tambon: "กลัดหลวง",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760511,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "ท่าคอย",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760502,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "ท่ายาง",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760501,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "ท่าแลง",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760514,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "ท่าไม้รวก",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760506,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "บ้านในดง",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760515,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "ปึกเตียน",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760512,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "มาบปลาเค้า",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760505,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "ยางหย่อง",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760503,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "วังไคร้",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760507,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "หนองจอก",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760504,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "เขากระปุก",
    amphoe: "ท่ายาง",
    province: "เพชรบุรี",
    zipcode: 76130,
    district_code: 760513,
    amphoe_code: 7605,
    province_code: 76,
  },
  {
    tambon: "ตำหรุ",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760604,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ถ้ำรงค์",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760617,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ท่าช้าง",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760616,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ท่าเสน",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760607,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "บ้านทาน",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760603,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "บ้านลาด",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760601,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "บ้านหาด",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760602,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ลาดโพธิ์",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760610,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "สมอพลือ",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760605,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "สะพานไกร",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760611,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "หนองกระเจ็ด",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760608,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "หนองกะปุ",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760609,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ห้วยข้อง",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760615,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ห้วยลึก",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760618,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "โรงเข้",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760613,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ไร่มะขาม",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760606,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ไร่สะท้อน",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760614,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ไร่โคก",
    amphoe: "บ้านลาด",
    province: "เพชรบุรี",
    zipcode: 76150,
    district_code: 760612,
    amphoe_code: 7606,
    province_code: 76,
  },
  {
    tambon: "ท่าแร้ง",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760709,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "ท่าแร้งออก",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760710,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "บางขุนไทร",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760702,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "บางครก",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760708,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "บางตะบูน",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760706,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "บางตะบูนออก",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760707,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "บางแก้ว",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760704,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "บ้านแหลม",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760701,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "ปากทะเล",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76110,
    district_code: 760703,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "แหลมผักเบี้ย",
    amphoe: "บ้านแหลม",
    province: "เพชรบุรี",
    zipcode: 76100,
    district_code: 760705,
    amphoe_code: 7607,
    province_code: 76,
  },
  {
    tambon: "ท่าตะคร้อ",
    amphoe: "หนองหญ้าปล้อง",
    province: "เพชรบุรี",
    zipcode: 76160,
    district_code: 760304,
    amphoe_code: 7603,
    province_code: 76,
  },
  {
    tambon: "ยางน้ำกลัดเหนือ",
    amphoe: "หนองหญ้าปล้อง",
    province: "เพชรบุรี",
    zipcode: 76160,
    district_code: 760302,
    amphoe_code: 7603,
    province_code: 76,
  },
  {
    tambon: "ยางน้ำกลัดใต้",
    amphoe: "หนองหญ้าปล้อง",
    province: "เพชรบุรี",
    zipcode: 76160,
    district_code: 760303,
    amphoe_code: 7603,
    province_code: 76,
  },
  {
    tambon: "หนองหญ้าปล้อง",
    amphoe: "หนองหญ้าปล้อง",
    province: "เพชรบุรี",
    zipcode: 76160,
    district_code: 760299,
    amphoe_code: 7603,
    province_code: 76,
  },
  {
    tambon: "ทับคาง",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760204,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "บางเค็ม",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760203,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "สระพัง",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760202,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "หนองชุมพล",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760207,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "หนองชุมพลเหนือ",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760210,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "หนองปรง",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760206,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "หนองปลาไหล",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760205,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "ห้วยท่าช้าง",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760209,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "ห้วยโรง",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760208,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "เขาย้อย",
    amphoe: "เขาย้อย",
    province: "เพชรบุรี",
    zipcode: 76140,
    district_code: 760201,
    amphoe_code: 7602,
    province_code: 76,
  },
  {
    tambon: "คลองกระแชง",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760102,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ช่องสะแก",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760113,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ดอนยาง",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760122,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ต้นมะพร้าว",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760119,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ต้นมะม่วง",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760112,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ท่าราบ",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760101,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ธงชัย",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760105,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "นาพันสาม",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760104,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "นาวุ้ง",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760114,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "บางจาก",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760110,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "บางจาน",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760103,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "บ้านกุ่ม",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760106,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "บ้านหม้อ",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760111,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "วังตะโก",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760120,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "สำมะโรง",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760115,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "หนองขนาน",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760123,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "หนองพลับ",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760124,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "หนองโสน",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760107,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "หัวสะพาน",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760118,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "หาดเจ้าสำราญ",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76100,
    district_code: 760117,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "เวียงคอย",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760109,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "โพพระ",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760116,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "โพไร่หวาน",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760121,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ไร่ส้ม",
    amphoe: "เมืองเพชรบุรี",
    province: "เพชรบุรี",
    zipcode: 76000,
    district_code: 760108,
    amphoe_code: 7601,
    province_code: 76,
  },
  {
    tambon: "ป่าเด็ง",
    amphoe: "แก่งกระจาน",
    province: "เพชรบุรี",
    zipcode: 76170,
    district_code: 760804,
    amphoe_code: 7608,
    province_code: 76,
  },
  {
    tambon: "พุสวรรค์",
    amphoe: "แก่งกระจาน",
    province: "เพชรบุรี",
    zipcode: 76170,
    district_code: 760805,
    amphoe_code: 7608,
    province_code: 76,
  },
  {
    tambon: "วังจันทร์",
    amphoe: "แก่งกระจาน",
    province: "เพชรบุรี",
    zipcode: 76170,
    district_code: 760508,
    amphoe_code: 7608,
    province_code: 76,
  },
  {
    tambon: "สองพี่น้อง",
    amphoe: "แก่งกระจาน",
    province: "เพชรบุรี",
    zipcode: 76170,
    district_code: 760509,
    amphoe_code: 7608,
    province_code: 76,
  },
  {
    tambon: "ห้วยแม่เพรียง",
    amphoe: "แก่งกระจาน",
    province: "เพชรบุรี",
    zipcode: 76170,
    district_code: 760806,
    amphoe_code: 7608,
    province_code: 76,
  },
  {
    tambon: "แก่งกระจาน",
    amphoe: "แก่งกระจาน",
    province: "เพชรบุรี",
    zipcode: 76170,
    district_code: 760510,
    amphoe_code: 7608,
    province_code: 76,
  },
  {
    tambon: "ชนแดน",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67150,
    district_code: 670201,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "ซับพุทรา",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67150,
    district_code: 670208,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "ดงขุย",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67190,
    district_code: 670202,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "ตะกุดไร",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67190,
    district_code: 670209,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67150,
    district_code: 670203,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "บ้านกล้วย",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67190,
    district_code: 670206,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "พุทธบาท",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67150,
    district_code: 670204,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "ลาดแค",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67150,
    district_code: 670205,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "ศาลาลาย",
    amphoe: "ชนแดน",
    province: "เพชรบูรณ์",
    zipcode: 67150,
    district_code: 670210,
    amphoe_code: 6702,
    province_code: 67,
  },
  {
    tambon: "น้ำหนาว",
    amphoe: "น้ำหนาว",
    province: "เพชรบูรณ์",
    zipcode: 67260,
    district_code: 670499,
    amphoe_code: 6709,
    province_code: 67,
  },
  {
    tambon: "วังกวาง",
    amphoe: "น้ำหนาว",
    province: "เพชรบูรณ์",
    zipcode: 67260,
    district_code: 670903,
    amphoe_code: 6709,
    province_code: 67,
  },
  {
    tambon: "หลักด่าน",
    amphoe: "น้ำหนาว",
    province: "เพชรบูรณ์",
    zipcode: 67260,
    district_code: 670902,
    amphoe_code: 6709,
    province_code: 67,
  },
  {
    tambon: "โคกมน",
    amphoe: "น้ำหนาว",
    province: "เพชรบูรณ์",
    zipcode: 67260,
    district_code: 670904,
    amphoe_code: 6709,
    province_code: 67,
  },
  {
    tambon: "กันจุ",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670804,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "ซับสมอทอด",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670801,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "ซับไม้แดง",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670802,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "บึงสามพัน",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670809,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "พญาวัง",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670806,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "วังพิกุล",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67230,
    district_code: 670805,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "ศรีมงคล",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670807,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "สระแก้ว",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670808,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "หนองแจง",
    amphoe: "บึงสามพัน",
    province: "เพชรบูรณ์",
    zipcode: 67160,
    district_code: 670803,
    amphoe_code: 6708,
    province_code: 67,
  },
  {
    tambon: "ซับเปิบ",
    amphoe: "วังโป่ง",
    province: "เพชรบูรณ์",
    zipcode: 67240,
    district_code: 671003,
    amphoe_code: 6710,
    province_code: 67,
  },
  {
    tambon: "ท้ายดง",
    amphoe: "วังโป่ง",
    province: "เพชรบูรณ์",
    zipcode: 67240,
    district_code: 670298,
    amphoe_code: 6710,
    province_code: 67,
  },
  {
    tambon: "วังศาล",
    amphoe: "วังโป่ง",
    province: "เพชรบูรณ์",
    zipcode: 67240,
    district_code: 671005,
    amphoe_code: 6710,
    province_code: 67,
  },
  {
    tambon: "วังหิน",
    amphoe: "วังโป่ง",
    province: "เพชรบูรณ์",
    zipcode: 67240,
    district_code: 671004,
    amphoe_code: 6710,
    province_code: 67,
  },
  {
    tambon: "วังโป่ง",
    amphoe: "วังโป่ง",
    province: "เพชรบูรณ์",
    zipcode: 67240,
    district_code: 670299,
    amphoe_code: 6710,
    province_code: 67,
  },
  {
    tambon: "ซับน้อย",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67180,
    district_code: 670514,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "ซับสมบูรณ์",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67180,
    district_code: 670510,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "ท่าโรง",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670501,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "น้ำร้อน",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670114,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "บึงกระจับ",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670511,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "บ่อรัง",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670506,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "พุขาม",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67180,
    district_code: 670508,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "พุเตย",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67180,
    district_code: 670507,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "ภูน้ำหยด",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67180,
    district_code: 670509,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "ยางสาว",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670513,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "วังใหญ่",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67180,
    district_code: 670512,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "สระประดู่",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670502,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "สามแยก",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670503,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "โคกปรง",
    amphoe: "วิเชียรบุรี",
    province: "เพชรบูรณ์",
    zipcode: 67130,
    district_code: 670504,
    amphoe_code: 6705,
    province_code: 67,
  },
  {
    tambon: "คลองกระจัง",
    amphoe: "ศรีเทพ",
    province: "เพชรบูรณ์",
    zipcode: 67170,
    district_code: 670597,
    amphoe_code: 6706,
    province_code: 67,
  },
  {
    tambon: "นาสนุ่น",
    amphoe: "ศรีเทพ",
    province: "เพชรบูรณ์",
    zipcode: 67170,
    district_code: 670595,
    amphoe_code: 6706,
    province_code: 67,
  },
  {
    tambon: "ประดู่งาม",
    amphoe: "ศรีเทพ",
    province: "เพชรบูรณ์",
    zipcode: 67170,
    district_code: 670607,
    amphoe_code: 6706,
    province_code: 67,
  },
  {
    tambon: "ศรีเทพ",
    amphoe: "ศรีเทพ",
    province: "เพชรบูรณ์",
    zipcode: 67170,
    district_code: 670599,
    amphoe_code: 6706,
    province_code: 67,
  },
  {
    tambon: "สระกรวด",
    amphoe: "ศรีเทพ",
    province: "เพชรบูรณ์",
    zipcode: 67170,
    district_code: 670598,
    amphoe_code: 6706,
    province_code: 67,
  },
  {
    tambon: "หนองย่างทอย",
    amphoe: "ศรีเทพ",
    province: "เพชรบูรณ์",
    zipcode: 67170,
    district_code: 670606,
    amphoe_code: 6706,
    province_code: 67,
  },
  {
    tambon: "โคกสะอาด",
    amphoe: "ศรีเทพ",
    province: "เพชรบูรณ์",
    zipcode: 67170,
    district_code: 670605,
    amphoe_code: 6706,
    province_code: 67,
  },
  {
    tambon: "กองทูล",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670701,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "ท่าด้วง",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670713,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "ท่าแดง",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670704,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "นาเฉลียง",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67220,
    district_code: 670702,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "บัววัฒนา",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670709,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "บ่อไทย",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670706,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "บ้านโภชน์",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670703,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "ยางงาม",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67220,
    district_code: 670712,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "วังท่าดี",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670708,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "วังโบสถ์",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670711,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "หนองไผ่",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670710,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "ห้วยโป่ง",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67220,
    district_code: 670707,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "เพชรละคร",
    amphoe: "หนองไผ่",
    province: "เพชรบูรณ์",
    zipcode: 67140,
    district_code: 670705,
    amphoe_code: 6707,
    province_code: 67,
  },
  {
    tambon: "ช้างตะลูด",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670320,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ตาลเดี่ยว",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670303,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ท่าอิบุญ",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670308,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "น้ำก้อ",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670312,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "น้ำชุน",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670314,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "น้ำเฮี้ย",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670306,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "บุ่งคล้า",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670317,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "บุ่งน้ำเต้า",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670318,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670319,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "บ้านติ้ว",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670310,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "บ้านหวาย",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670323,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "บ้านโสก",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670309,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "บ้านไร่",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670321,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ปากช่อง",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670313,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ปากดุก",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670322,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ฝายนาแซง",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670304,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ลานบ่า",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670316,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "วัดป่า",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670302,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "สักหลง",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670307,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "หนองสว่าง",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670305,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "หนองไขว่",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670315,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "หล่มสัก",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670301,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ห้วยไร่",
    amphoe: "หล่มสัก",
    province: "เพชรบูรณ์",
    zipcode: 67110,
    district_code: 670311,
    amphoe_code: 6703,
    province_code: 67,
  },
  {
    tambon: "ตาดกลอย",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670409,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "นาซำ",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670402,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "นาเกาะ",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670408,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "นาแซง",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670406,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "บ้านเนิน",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670404,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "วังบาล",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670407,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "ศิลา",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670405,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "หล่มเก่า",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670401,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "หินฮาว",
    amphoe: "หล่มเก่า",
    province: "เพชรบูรณ์",
    zipcode: 67120,
    district_code: 670403,
    amphoe_code: 6704,
    province_code: 67,
  },
  {
    tambon: "ทุ่งสมอ",
    amphoe: "เขาค้อ",
    province: "เพชรบูรณ์",
    zipcode: 67270,
    district_code: 671101,
    amphoe_code: 6711,
    province_code: 67,
  },
  {
    tambon: "ริมสีม่วง",
    amphoe: "เขาค้อ",
    province: "เพชรบูรณ์",
    zipcode: 67270,
    district_code: 671104,
    amphoe_code: 6711,
    province_code: 67,
  },
  {
    tambon: "สะเดาะพง",
    amphoe: "เขาค้อ",
    province: "เพชรบูรณ์",
    zipcode: 67270,
    district_code: 671105,
    amphoe_code: 6711,
    province_code: 67,
  },
  {
    tambon: "หนองแม่นา",
    amphoe: "เขาค้อ",
    province: "เพชรบูรณ์",
    zipcode: 67270,
    district_code: 671106,
    amphoe_code: 6711,
    province_code: 67,
  },
  {
    tambon: "เขาค้อ",
    amphoe: "เขาค้อ",
    province: "เพชรบูรณ์",
    zipcode: 67270,
    district_code: 671103,
    amphoe_code: 6711,
    province_code: 67,
  },
  {
    tambon: "เข็กน้อย",
    amphoe: "เขาค้อ",
    province: "เพชรบูรณ์",
    zipcode: 67280,
    district_code: 671107,
    amphoe_code: 6711,
    province_code: 67,
  },
  {
    tambon: "แคมป์สน",
    amphoe: "เขาค้อ",
    province: "เพชรบูรณ์",
    zipcode: 67280,
    district_code: 670399,
    amphoe_code: 6711,
    province_code: 67,
  },
  {
    tambon: "ชอนไพร",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670110,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ดงมูลเหล็ก",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670108,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ตะเบาะ",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670102,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ท่าพล",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67250,
    district_code: 670107,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "นางั่ว",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670106,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "นาป่า",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670111,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "นายม",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67210,
    district_code: 670112,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "น้ำร้อน",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670114,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "บ้านโคก",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670109,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "บ้านโตก",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670103,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ป่าเลา",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670105,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ระวิง",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67210,
    district_code: 670117,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "วังชมภู",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67210,
    district_code: 670113,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "สะเดียง",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670104,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ห้วยสะแก",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67210,
    district_code: 670115,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ห้วยใหญ่",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670116,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "ในเมือง",
    amphoe: "เมืองเพชรบูรณ์",
    province: "เพชรบูรณ์",
    zipcode: 67000,
    district_code: 670101,
    amphoe_code: 6701,
    province_code: 67,
  },
  {
    tambon: "กกสะทอน",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420507,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "ด่านซ้าย",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420501,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "นาดี",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420503,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "นาหอ",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420510,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "ปากหมัน",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420502,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "วังยาว",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420509,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "อิปุ่ม",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420506,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "โคกงาม",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420504,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "โป่ง",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420508,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "โพนสูง",
    amphoe: "ด่านซ้าย",
    province: "เลย",
    zipcode: 42120,
    district_code: 420505,
    amphoe_code: 4205,
    province_code: 42,
  },
  {
    tambon: "ท่าลี่",
    amphoe: "ท่าลี่",
    province: "เลย",
    zipcode: 42140,
    district_code: 420801,
    amphoe_code: 4208,
    province_code: 42,
  },
  {
    tambon: "น้ำทูน",
    amphoe: "ท่าลี่",
    province: "เลย",
    zipcode: 42140,
    district_code: 420806,
    amphoe_code: 4208,
    province_code: 42,
  },
  {
    tambon: "น้ำแคม",
    amphoe: "ท่าลี่",
    province: "เลย",
    zipcode: 42140,
    district_code: 420804,
    amphoe_code: 4208,
    province_code: 42,
  },
  {
    tambon: "หนองผือ",
    amphoe: "ท่าลี่",
    province: "เลย",
    zipcode: 42140,
    district_code: 420802,
    amphoe_code: 4208,
    province_code: 42,
  },
  {
    tambon: "อาฮี",
    amphoe: "ท่าลี่",
    province: "เลย",
    zipcode: 42140,
    district_code: 420803,
    amphoe_code: 4208,
    province_code: 42,
  },
  {
    tambon: "โคกใหญ่",
    amphoe: "ท่าลี่",
    province: "เลย",
    zipcode: 42140,
    district_code: 420805,
    amphoe_code: 4208,
    province_code: 42,
  },
  {
    tambon: "ท่าสวรรค์",
    amphoe: "นาด้วง",
    province: "เลย",
    zipcode: 42210,
    district_code: 420204,
    amphoe_code: 4202,
    province_code: 42,
  },
  {
    tambon: "ท่าสะอาด",
    amphoe: "นาด้วง",
    province: "เลย",
    zipcode: 42210,
    district_code: 420203,
    amphoe_code: 4202,
    province_code: 42,
  },
  {
    tambon: "นาดอกคำ",
    amphoe: "นาด้วง",
    province: "เลย",
    zipcode: 42210,
    district_code: 420202,
    amphoe_code: 4202,
    province_code: 42,
  },
  {
    tambon: "นาด้วง",
    amphoe: "นาด้วง",
    province: "เลย",
    zipcode: 42210,
    district_code: 420201,
    amphoe_code: 4202,
    province_code: 42,
  },
  {
    tambon: "นาพึง",
    amphoe: "นาแห้ว",
    province: "เลย",
    zipcode: 42170,
    district_code: 420603,
    amphoe_code: 4206,
    province_code: 42,
  },
  {
    tambon: "นามาลา",
    amphoe: "นาแห้ว",
    province: "เลย",
    zipcode: 42170,
    district_code: 420604,
    amphoe_code: 4206,
    province_code: 42,
  },
  {
    tambon: "นาแห้ว",
    amphoe: "นาแห้ว",
    province: "เลย",
    zipcode: 42170,
    district_code: 420599,
    amphoe_code: 4206,
    province_code: 42,
  },
  {
    tambon: "เหล่ากอหก",
    amphoe: "นาแห้ว",
    province: "เลย",
    zipcode: 42170,
    district_code: 420605,
    amphoe_code: 4206,
    province_code: 42,
  },
  {
    tambon: "แสงภา",
    amphoe: "นาแห้ว",
    province: "เลย",
    zipcode: 42170,
    district_code: 420594,
    amphoe_code: 4206,
    province_code: 42,
  },
  {
    tambon: "ชมเจริญ",
    amphoe: "ปากชม",
    province: "เลย",
    zipcode: 42150,
    district_code: 420406,
    amphoe_code: 4204,
    province_code: 42,
  },
  {
    tambon: "ปากชม",
    amphoe: "ปากชม",
    province: "เลย",
    zipcode: 42150,
    district_code: 420401,
    amphoe_code: 4204,
    province_code: 42,
  },
  {
    tambon: "หาดคัมภีร์",
    amphoe: "ปากชม",
    province: "เลย",
    zipcode: 42150,
    district_code: 420403,
    amphoe_code: 4204,
    province_code: 42,
  },
  {
    tambon: "ห้วยบ่อซืน",
    amphoe: "ปากชม",
    province: "เลย",
    zipcode: 42150,
    district_code: 420404,
    amphoe_code: 4204,
    province_code: 42,
  },
  {
    tambon: "ห้วยพิชัย",
    amphoe: "ปากชม",
    province: "เลย",
    zipcode: 42150,
    district_code: 420405,
    amphoe_code: 4204,
    province_code: 42,
  },
  {
    tambon: "เชียงกลม",
    amphoe: "ปากชม",
    province: "เลย",
    zipcode: 42150,
    district_code: 420402,
    amphoe_code: 4204,
    province_code: 42,
  },
  {
    tambon: "ท่าช้างคล้อง",
    amphoe: "ผาขาว",
    province: "เลย",
    zipcode: 42240,
    district_code: 421003,
    amphoe_code: 4212,
    province_code: 42,
  },
  {
    tambon: "บ้านเพิ่ม",
    amphoe: "ผาขาว",
    province: "เลย",
    zipcode: 42240,
    district_code: 421205,
    amphoe_code: 4212,
    province_code: 42,
  },
  {
    tambon: "ผาขาว",
    amphoe: "ผาขาว",
    province: "เลย",
    zipcode: 42240,
    district_code: 421004,
    amphoe_code: 4212,
    province_code: 42,
  },
  {
    tambon: "โนนปอแดง",
    amphoe: "ผาขาว",
    province: "เลย",
    zipcode: 42240,
    district_code: 421009,
    amphoe_code: 4212,
    province_code: 42,
  },
  {
    tambon: "โนนป่าซาง",
    amphoe: "ผาขาว",
    province: "เลย",
    zipcode: 42240,
    district_code: 421006,
    amphoe_code: 4212,
    province_code: 42,
  },
  {
    tambon: "ผานกเค้า",
    amphoe: "ภูกระดึง",
    province: "เลย",
    zipcode: 42180,
    district_code: 421005,
    amphoe_code: 4210,
    province_code: 42,
  },
  {
    tambon: "ภูกระดึง",
    amphoe: "ภูกระดึง",
    province: "เลย",
    zipcode: 42180,
    district_code: 421007,
    amphoe_code: 4210,
    province_code: 42,
  },
  {
    tambon: "ศรีฐาน",
    amphoe: "ภูกระดึง",
    province: "เลย",
    zipcode: 42180,
    district_code: 421001,
    amphoe_code: 4210,
    province_code: 42,
  },
  {
    tambon: "ห้วยส้ม",
    amphoe: "ภูกระดึง",
    province: "เลย",
    zipcode: 42180,
    district_code: 421010,
    amphoe_code: 4210,
    province_code: 42,
  },
  {
    tambon: "ภูหอ",
    amphoe: "ภูหลวง",
    province: "เลย",
    zipcode: 42230,
    district_code: 420999,
    amphoe_code: 4211,
    province_code: 42,
  },
  {
    tambon: "หนองคัน",
    amphoe: "ภูหลวง",
    province: "เลย",
    zipcode: 42230,
    district_code: 420998,
    amphoe_code: 4211,
    province_code: 42,
  },
  {
    tambon: "ห้วยสีเสียด",
    amphoe: "ภูหลวง",
    province: "เลย",
    zipcode: 42230,
    district_code: 421104,
    amphoe_code: 4211,
    province_code: 42,
  },
  {
    tambon: "เลยวังไสย์",
    amphoe: "ภูหลวง",
    province: "เลย",
    zipcode: 42230,
    district_code: 421105,
    amphoe_code: 4211,
    province_code: 42,
  },
  {
    tambon: "แก่งศรีภูมิ",
    amphoe: "ภูหลวง",
    province: "เลย",
    zipcode: 42230,
    district_code: 421106,
    amphoe_code: 4211,
    province_code: 42,
  },
  {
    tambon: "ท่าศาลา",
    amphoe: "ภูเรือ",
    province: "เลย",
    zipcode: 42160,
    district_code: 420597,
    amphoe_code: 4207,
    province_code: 42,
  },
  {
    tambon: "ปลาบ่า",
    amphoe: "ภูเรือ",
    province: "เลย",
    zipcode: 42160,
    district_code: 420595,
    amphoe_code: 4207,
    province_code: 42,
  },
  {
    tambon: "ร่องจิก",
    amphoe: "ภูเรือ",
    province: "เลย",
    zipcode: 42160,
    district_code: 420593,
    amphoe_code: 4207,
    province_code: 42,
  },
  {
    tambon: "ลาดค่าง",
    amphoe: "ภูเรือ",
    province: "เลย",
    zipcode: 42160,
    district_code: 420705,
    amphoe_code: 4207,
    province_code: 42,
  },
  {
    tambon: "สานตม",
    amphoe: "ภูเรือ",
    province: "เลย",
    zipcode: 42160,
    district_code: 420706,
    amphoe_code: 4207,
    province_code: 42,
  },
  {
    tambon: "หนองบัว",
    amphoe: "ภูเรือ",
    province: "เลย",
    zipcode: 42160,
    district_code: 420598,
    amphoe_code: 4207,
    province_code: 42,
  },
  {
    tambon: "ทรายขาว",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420902,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "ปากปวน",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420905,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "ผาน้อย",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420906,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "ผาบิ้ง",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420910,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "วังสะพุง",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420901,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "ศรีสงคราม",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420913,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "หนองงิ้ว",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420904,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "หนองหญ้าปล้อง",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420903,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "เขาหลวง",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420911,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "โคกขมิ้น",
    amphoe: "วังสะพุง",
    province: "เลย",
    zipcode: 42130,
    district_code: 420912,
    amphoe_code: 4209,
    province_code: 42,
  },
  {
    tambon: "ตาดข่า",
    amphoe: "หนองหิน",
    province: "เลย",
    zipcode: 42190,
    district_code: 421011,
    amphoe_code: 4214,
    province_code: 42,
  },
  {
    tambon: "ปวนพุ",
    amphoe: "หนองหิน",
    province: "เลย",
    zipcode: 42190,
    district_code: 421002,
    amphoe_code: 4214,
    province_code: 42,
  },
  {
    tambon: "หนองหิน",
    amphoe: "หนองหิน",
    province: "เลย",
    zipcode: 42190,
    district_code: 421008,
    amphoe_code: 4214,
    province_code: 42,
  },
  {
    tambon: "จอมศรี",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420307,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "ธาตุ",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420302,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "นาซ่าว",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420303,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "บุฮม",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420306,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "ปากตม",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420305,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "หาดทรายขาว",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420308,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "เขาแก้ว",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420304,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "เชียงคาน",
    amphoe: "เชียงคาน",
    province: "เลย",
    zipcode: 42110,
    district_code: 420301,
    amphoe_code: 4203,
    province_code: 42,
  },
  {
    tambon: "กกดู่",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420104,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "กกทอง",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420114,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "กุดป่อง",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420101,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "ชัยพฤกษ์",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420111,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "นาดินดำ",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420109,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "นาอาน",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420107,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "นาอ้อ",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42100,
    district_code: 420103,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "นาแขม",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420112,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "นาโป่ง",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420108,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "น้ำสวย",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420110,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "น้ำหมาน",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420105,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "ศรีสองรัก",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42100,
    district_code: 420113,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "เมือง",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420102,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "เสี้ยว",
    amphoe: "เมืองเลย",
    province: "เลย",
    zipcode: 42000,
    district_code: 420106,
    amphoe_code: 4201,
    province_code: 42,
  },
  {
    tambon: "ทรัพย์ไพวัลย์",
    amphoe: "เอราวัณ",
    province: "เลย",
    zipcode: 42220,
    district_code: 420914,
    amphoe_code: 4213,
    province_code: 42,
  },
  {
    tambon: "ผาสามยอด",
    amphoe: "เอราวัณ",
    province: "เลย",
    zipcode: 42220,
    district_code: 420909,
    amphoe_code: 4213,
    province_code: 42,
  },
  {
    tambon: "ผาอินทร์แปลง",
    amphoe: "เอราวัณ",
    province: "เลย",
    zipcode: 42220,
    district_code: 420908,
    amphoe_code: 4213,
    province_code: 42,
  },
  {
    tambon: "เอราวัณ",
    amphoe: "เอราวัณ",
    province: "เลย",
    zipcode: 42220,
    district_code: 420907,
    amphoe_code: 4213,
    province_code: 42,
  },
  {
    tambon: "ทุ่งศรี",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540207,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "น้ำเลา",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540205,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "บ้านเวียง",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540206,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "ร้องกวาง",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540201,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "ร้องเข็ม",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540204,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "ห้วยโรง",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540213,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "แม่ทราย",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540214,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "แม่ยางตาล",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540208,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "แม่ยางร้อง",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540215,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "แม่ยางฮ่อ",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540209,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "ไผ่โทน",
    amphoe: "ร้องกวาง",
    province: "แพร่",
    zipcode: 54140,
    district_code: 540210,
    amphoe_code: 5402,
    province_code: 54,
  },
  {
    tambon: "ต้าผามอก",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540303,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "ทุ่งแล้ง",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540307,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "บ่อเหล็กลอง",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540308,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "บ้านปิน",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540302,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "ปากกาง",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540305,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "หัวทุ่ง",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540306,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "ห้วยอ้อ",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540301,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "เวียงต้า",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540304,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "แม่ปาน",
    amphoe: "ลอง",
    province: "แพร่",
    zipcode: 54150,
    district_code: 540309,
    amphoe_code: 5403,
    province_code: 54,
  },
  {
    tambon: "นาพูน",
    amphoe: "วังชิ้น",
    province: "แพร่",
    zipcode: 54160,
    district_code: 540704,
    amphoe_code: 5407,
    province_code: 54,
  },
  {
    tambon: "ป่าสัก",
    amphoe: "วังชิ้น",
    province: "แพร่",
    zipcode: 54160,
    district_code: 540706,
    amphoe_code: 5407,
    province_code: 54,
  },
  {
    tambon: "วังชิ้น",
    amphoe: "วังชิ้น",
    province: "แพร่",
    zipcode: 54160,
    district_code: 540701,
    amphoe_code: 5407,
    province_code: 54,
  },
  {
    tambon: "สรอย",
    amphoe: "วังชิ้น",
    province: "แพร่",
    zipcode: 54160,
    district_code: 540702,
    amphoe_code: 5407,
    province_code: 54,
  },
  {
    tambon: "แม่ป้าก",
    amphoe: "วังชิ้น",
    province: "แพร่",
    zipcode: 54160,
    district_code: 540703,
    amphoe_code: 5407,
    province_code: 54,
  },
  {
    tambon: "แม่พุง",
    amphoe: "วังชิ้น",
    province: "แพร่",
    zipcode: 54160,
    district_code: 540705,
    amphoe_code: 5407,
    province_code: 54,
  },
  {
    tambon: "แม่เกิ๋ง",
    amphoe: "วังชิ้น",
    province: "แพร่",
    zipcode: 54160,
    district_code: 540707,
    amphoe_code: 5407,
    province_code: 54,
  },
  {
    tambon: "ทุ่งน้าว",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540608,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "บ้านกลาง",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540602,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "บ้านหนุน",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540601,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "สะเอียบ",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540606,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "หัวเมือง",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540605,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "ห้วยหม้าย",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540603,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "เตาปูน",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540604,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "แดนชุมพล",
    amphoe: "สอง",
    province: "แพร่",
    zipcode: 54120,
    district_code: 540607,
    amphoe_code: 5406,
    province_code: 54,
  },
  {
    tambon: "ดอนมูล",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540404,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "น้ำชำ",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540103,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "บ้านกวาง",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540406,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "บ้านกาศ",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540408,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "บ้านปง",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540407,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "บ้านเหล่า",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540405,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "พระหลวง",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540412,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "ร่องกาศ",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540409,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "สบสาย",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540410,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "สูงเม่น",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540401,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "หัวฝาย",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54130,
    district_code: 540403,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "เวียงทอง",
    amphoe: "สูงเม่น",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540411,
    amphoe_code: 5404,
    province_code: 54,
  },
  {
    tambon: "ตำหนักธรรม",
    amphoe: "หนองม่วงไข่",
    province: "แพร่",
    zipcode: 54170,
    district_code: 540805,
    amphoe_code: 5408,
    province_code: 54,
  },
  {
    tambon: "ทุ่งแค้ว",
    amphoe: "หนองม่วงไข่",
    province: "แพร่",
    zipcode: 54170,
    district_code: 540806,
    amphoe_code: 5408,
    province_code: 54,
  },
  {
    tambon: "น้ำรัด",
    amphoe: "หนองม่วงไข่",
    province: "แพร่",
    zipcode: 54170,
    district_code: 540211,
    amphoe_code: 5408,
    province_code: 54,
  },
  {
    tambon: "วังหลวง",
    amphoe: "หนองม่วงไข่",
    province: "แพร่",
    zipcode: 54170,
    district_code: 540212,
    amphoe_code: 5408,
    province_code: 54,
  },
  {
    tambon: "หนองม่วงไข่",
    amphoe: "หนองม่วงไข่",
    province: "แพร่",
    zipcode: 54170,
    district_code: 540202,
    amphoe_code: 5408,
    province_code: 54,
  },
  {
    tambon: "แม่คำมี",
    amphoe: "หนองม่วงไข่",
    province: "แพร่",
    zipcode: 54170,
    district_code: 540114,
    amphoe_code: 5408,
    province_code: 54,
  },
  {
    tambon: "ปงป่าหวาย",
    amphoe: "เด่นชัย",
    province: "แพร่",
    zipcode: 54110,
    district_code: 540505,
    amphoe_code: 5405,
    province_code: 54,
  },
  {
    tambon: "ห้วยไร่",
    amphoe: "เด่นชัย",
    province: "แพร่",
    zipcode: 54110,
    district_code: 540504,
    amphoe_code: 5405,
    province_code: 54,
  },
  {
    tambon: "เด่นชัย",
    amphoe: "เด่นชัย",
    province: "แพร่",
    zipcode: 54110,
    district_code: 540501,
    amphoe_code: 5405,
    province_code: 54,
  },
  {
    tambon: "แม่จั๊วะ",
    amphoe: "เด่นชัย",
    province: "แพร่",
    zipcode: 54110,
    district_code: 540502,
    amphoe_code: 5405,
    province_code: 54,
  },
  {
    tambon: "ไทรย้อย",
    amphoe: "เด่นชัย",
    province: "แพร่",
    zipcode: 54110,
    district_code: 540503,
    amphoe_code: 5405,
    province_code: 54,
  },
  {
    tambon: "กาญจนา",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540120,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ช่อแฮ",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540118,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ทุ่งกวาว",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540115,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ทุ่งโฮ้ง",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540105,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ท่าข้าม",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540116,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "นาจักร",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540102,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "น้ำชำ",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540103,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "บ้านถิ่น",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540111,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ป่าแดง",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540104,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ป่าแมต",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540110,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ร่องฟอง",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540119,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "วังธง",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540107,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "วังหงษ์",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: false,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "สวนเขื่อน",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540112,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ห้วยม้า",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540109,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "เหมืองหม้อ",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540106,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "แม่คำมี",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540114,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "แม่ยม",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540117,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "แม่หล่าย",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540108,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ในเวียง",
    amphoe: "เมืองแพร่",
    province: "แพร่",
    zipcode: 54000,
    district_code: 540101,
    amphoe_code: 5401,
    province_code: 54,
  },
  {
    tambon: "ขุนยวม",
    amphoe: "ขุนยวม",
    province: "แม่ฮ่องสอน",
    zipcode: 58140,
    district_code: 580201,
    amphoe_code: 5802,
    province_code: 58,
  },
  {
    tambon: "เมืองปอน",
    amphoe: "ขุนยวม",
    province: "แม่ฮ่องสอน",
    zipcode: 58140,
    district_code: 580203,
    amphoe_code: 5802,
    province_code: 58,
  },
  {
    tambon: "แม่กิ๊",
    amphoe: "ขุนยวม",
    province: "แม่ฮ่องสอน",
    zipcode: 58140,
    district_code: 580205,
    amphoe_code: 5802,
    province_code: 58,
  },
  {
    tambon: "แม่ยวมน้อย",
    amphoe: "ขุนยวม",
    province: "แม่ฮ่องสอน",
    zipcode: 58140,
    district_code: 580204,
    amphoe_code: 5802,
    province_code: 58,
  },
  {
    tambon: "แม่อูคอ",
    amphoe: "ขุนยวม",
    province: "แม่ฮ่องสอน",
    zipcode: 58140,
    district_code: 580206,
    amphoe_code: 5802,
    province_code: 58,
  },
  {
    tambon: "แม่เงา",
    amphoe: "ขุนยวม",
    province: "แม่ฮ่องสอน",
    zipcode: 58140,
    district_code: 580202,
    amphoe_code: 5802,
    province_code: 58,
  },
  {
    tambon: "ถ้ำลอด",
    amphoe: "ปางมะผ้า",
    province: "แม่ฮ่องสอน",
    zipcode: 58150,
    district_code: 580703,
    amphoe_code: 5807,
    province_code: 58,
  },
  {
    tambon: "นาปู่ป้อม",
    amphoe: "ปางมะผ้า",
    province: "แม่ฮ่องสอน",
    zipcode: 58150,
    district_code: 580704,
    amphoe_code: 5807,
    province_code: 58,
  },
  {
    tambon: "ปางมะผ้า",
    amphoe: "ปางมะผ้า",
    province: "แม่ฮ่องสอน",
    zipcode: 58150,
    district_code: 580107,
    amphoe_code: 5807,
    province_code: 58,
  },
  {
    tambon: "สบป่อง",
    amphoe: "ปางมะผ้า",
    province: "แม่ฮ่องสอน",
    zipcode: 58150,
    district_code: 580108,
    amphoe_code: 5807,
    province_code: 58,
  },
  {
    tambon: "ทุ่งยาว",
    amphoe: "ปาย",
    province: "แม่ฮ่องสอน",
    zipcode: 58130,
    district_code: 580305,
    amphoe_code: 5803,
    province_code: 58,
  },
  {
    tambon: "เมืองแปง",
    amphoe: "ปาย",
    province: "แม่ฮ่องสอน",
    zipcode: 58130,
    district_code: 580306,
    amphoe_code: 5803,
    province_code: 58,
  },
  {
    tambon: "เวียงเหนือ",
    amphoe: "ปาย",
    province: "แม่ฮ่องสอน",
    zipcode: 58130,
    district_code: 580302,
    amphoe_code: 5803,
    province_code: 58,
  },
  {
    tambon: "เวียงใต้",
    amphoe: "ปาย",
    province: "แม่ฮ่องสอน",
    zipcode: 58130,
    district_code: 580301,
    amphoe_code: 5803,
    province_code: 58,
  },
  {
    tambon: "แม่นาเติง",
    amphoe: "ปาย",
    province: "แม่ฮ่องสอน",
    zipcode: 58130,
    district_code: 580303,
    amphoe_code: 5803,
    province_code: 58,
  },
  {
    tambon: "แม่ฮี้",
    amphoe: "ปาย",
    province: "แม่ฮ่องสอน",
    zipcode: 58130,
    district_code: 580304,
    amphoe_code: 5803,
    province_code: 58,
  },
  {
    tambon: "โป่งสา",
    amphoe: "ปาย",
    province: "แม่ฮ่องสอน",
    zipcode: 58130,
    district_code: 580307,
    amphoe_code: 5803,
    province_code: 58,
  },
  {
    tambon: "กองก๋อย",
    amphoe: "สบเมย",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580603,
    amphoe_code: 5806,
    province_code: 58,
  },
  {
    tambon: "ป่าโปง",
    amphoe: "สบเมย",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580605,
    amphoe_code: 5806,
    province_code: 58,
  },
  {
    tambon: "สบเมย",
    amphoe: "สบเมย",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580499,
    amphoe_code: 5806,
    province_code: 58,
  },
  {
    tambon: "แม่คะตวน",
    amphoe: "สบเมย",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580498,
    amphoe_code: 5806,
    province_code: 58,
  },
  {
    tambon: "แม่สวด",
    amphoe: "สบเมย",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580604,
    amphoe_code: 5806,
    province_code: 58,
  },
  {
    tambon: "แม่สามแลบ",
    amphoe: "สบเมย",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580606,
    amphoe_code: 5806,
    province_code: 58,
  },
  {
    tambon: "จองคำ",
    amphoe: "เมืองแม่ฮ่องสอน",
    province: "แม่ฮ่องสอน",
    zipcode: 58000,
    district_code: 580101,
    amphoe_code: 5801,
    province_code: 58,
  },
  {
    tambon: "ปางหมู",
    amphoe: "เมืองแม่ฮ่องสอน",
    province: "แม่ฮ่องสอน",
    zipcode: 58000,
    district_code: 580104,
    amphoe_code: 5801,
    province_code: 58,
  },
  {
    tambon: "ผาบ่อง",
    amphoe: "เมืองแม่ฮ่องสอน",
    province: "แม่ฮ่องสอน",
    zipcode: 58000,
    district_code: 580103,
    amphoe_code: 5801,
    province_code: 58,
  },
  {
    tambon: "หมอกจำแป่",
    amphoe: "เมืองแม่ฮ่องสอน",
    province: "แม่ฮ่องสอน",
    zipcode: 58000,
    district_code: 580105,
    amphoe_code: 5801,
    province_code: 58,
  },
  {
    tambon: "ห้วยปูลิง",
    amphoe: "เมืองแม่ฮ่องสอน",
    province: "แม่ฮ่องสอน",
    zipcode: 58000,
    district_code: 580109,
    amphoe_code: 5801,
    province_code: 58,
  },
  {
    tambon: "ห้วยผา",
    amphoe: "เมืองแม่ฮ่องสอน",
    province: "แม่ฮ่องสอน",
    zipcode: 58000,
    district_code: 580106,
    amphoe_code: 5801,
    province_code: 58,
  },
  {
    tambon: "ห้วยโป่ง",
    amphoe: "เมืองแม่ฮ่องสอน",
    province: "แม่ฮ่องสอน",
    zipcode: 58000,
    district_code: 580102,
    amphoe_code: 5801,
    province_code: 58,
  },
  {
    tambon: "ขุนแม่ลาน้อย",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580508,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "ท่าผาปุ้ม",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580503,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "สันติคีรี",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580507,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "ห้วยห้อม",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580505,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "แม่นาจาง",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580506,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "แม่ลาน้อย",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580501,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "แม่ลาหลวง",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580502,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "แม่โถ",
    amphoe: "แม่ลาน้อย",
    province: "แม่ฮ่องสอน",
    zipcode: 58120,
    district_code: 580504,
    amphoe_code: 5805,
    province_code: 58,
  },
  {
    tambon: "บ้านกาศ",
    amphoe: "แม่สะเรียง",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580401,
    amphoe_code: 5804,
    province_code: 58,
  },
  {
    tambon: "ป่าแป๋",
    amphoe: "แม่สะเรียง",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580408,
    amphoe_code: 5804,
    province_code: 58,
  },
  {
    tambon: "เสาหิน",
    amphoe: "แม่สะเรียง",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580406,
    amphoe_code: 5804,
    province_code: 58,
  },
  {
    tambon: "แม่คง",
    amphoe: "แม่สะเรียง",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580403,
    amphoe_code: 5804,
    province_code: 58,
  },
  {
    tambon: "แม่ยวม",
    amphoe: "แม่สะเรียง",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580405,
    amphoe_code: 5804,
    province_code: 58,
  },
  {
    tambon: "แม่สะเรียง",
    amphoe: "แม่สะเรียง",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580402,
    amphoe_code: 5804,
    province_code: 58,
  },
  {
    tambon: "แม่เหาะ",
    amphoe: "แม่สะเรียง",
    province: "แม่ฮ่องสอน",
    zipcode: 58110,
    district_code: 580404,
    amphoe_code: 5804,
    province_code: 58,
  },
];
