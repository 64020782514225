// import React, {useState} from 'react';
import React from 'react';
// import {Col, message, Row, Spin, Upload as AntUpload} from 'antd';
// import Icon from '@ant-design/icons';
import { Col, Icon, message, Row, Spin, Upload as AntUpload } from 'antd';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Text from 'app/components/text';

export const Upload = styled(AntUpload)`
  &.avatar-uploader.ant-upload-picture-card-wrapper {
    > .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: 100%;
      height: auto;
    }
  }
`;

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export function FormAvatarUploadInputComponent({
  onChange,
  loading,
  value,
  ...restProps
}) {
  const { t } = useTranslation(['register']);
  const uploadButton = (
    <Row justify='space-between' type='flex'>
      <Col>
        <Text className='ant-upload-text'>{t('register:add picture')}</Text>
      </Col>
      <Col />
      <Col>
        <Icon type={loading ? 'loading' : 'file-add'} />
      </Col>
    </Row>
  );

  return (
    <Spin spinning={loading}>
      <Upload
        {...restProps}
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={onChange}
      >
        {!!value ? (
          <img
            src={value}
            alt='avatar'
            style={{ width: '8rem', height: 'auto' }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </Spin>
  );
}
