import React, { useCallback, useEffect, useState } from "react";
// import {Alert, Button, Col, Row, Spin} from 'antd';
// import Icon from '@ant-design/icons';
import { Alert, Button, Col, Icon, Row, Spin } from "antd";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useRouter from "use-react-router";
import { useFormik } from "formik";
import { get } from "lodash";
import * as Yup from "yup";
import moment from "moment";

import Container from "../../../components/container";
import Text from "../../../components/text";

import Input from "../../../components/input";
import Form from "../../../components/form";
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

const ProvidersContainer = styled(Container)`
  padding: 1.5rem 0;
  background-color: ${(props) => props.theme["$white"]};
`;

const HeaderCol = styled(Col)`
  &.ant-col {
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 2rem;
  }
`;

const MarginBottomCol = styled(Col)`
  &.ant-col {
    margin-bottom: 1rem;
  }
`;

const SocialButton = styled(Button)`
  &.ant-btn {
    > img + span,
    span + img {
      margin-left: 0.5rem;
    }

    > img,
    .anticon {
      position: absolute;
      left: 1rem;
      top: 0.7rem;
    }
  }
`;

const TextCenterCol = styled(Col)`
  &.ant-col {
    text-align: center;
  }
`;

const MarginBottom2xAndTextRightCol = styled(Col)`
  &.ant-col {
    text-align: right;
    margin-bottom: 2rem;
  }
`;

const MarginBottom2xAndTextCenterCol = styled(Col)`
  &.ant-col {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export function ProvidersComponent() {
  const { t } = useTranslation(["common", "auth"]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  // const [providerSignInError, setProviderSignInError] = useState(null);
  const [providerSignInError] = useState(null);
  const auth = useSelector((state) => state.firebase.auth);
  // const profile = useSelector(state => state.firebase.profile);
  const {
    history: { replace, push },
  } = useRouter();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const signIn = async ({ email, password }, { setErrors }) => {
    try {
      setLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      setLoading(false);
      setErrors({ signIn: t("auth:email or password incorrect") });
    }
  };

  const signInWithFacebook = () =>
    firebase.login({ provider: "facebook", type: "redirect" });
  const signInWithGoogle = () =>
    firebase.login({ provider: "google", type: "redirect" });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("register:this field is invalid"))
      .required(t("register:this field can not be empty")),
    password: Yup.string().required(t("register:this field can not be empty")),
  });
  const { handleSubmit, handleChange, values, errors } = useFormik({
    onSubmit: signIn,
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
  });

  const onClickToSignUp = () => {
    push("/auth/sign-up/email");
  };

  const getFormItem = ({
    label,
    id,
    tag: Tag = Input,
    tagProps,
    formErrors,
    formValues,
    onChange,
  }) => (
    <Form.Item
      label={label}
      validateStatus={!!get(formErrors, id, "") ? "error" : "success"}
      help={get(formErrors, id, "")}
    >
      <Tag
        name={id}
        id={id}
        onChange={onChange}
        defaultValue={get(formValues, id, "")}
        {...tagProps}
      />
    </Form.Item>
  );

  const EmailInput = getFormItem({
    label: t("register:email"),
    id: "email",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  });
  const PasswordInput = getFormItem({
    label: t("auth:password"),
    id: "password",
    tag: Input.Password,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  });
  const rowProps = { type: "flex", justify: "center" };
  const colProps = { xs: 24, sm: 10, md: 10, lg: 8 };
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const getAuthRedirectResult = useCallback(async () => {
    const { user } = await firebase.auth().getRedirectResult();
    try {
      if (!!user) {
        const { displayName, uid, email, phoneNumber, providerId, photoURL } =
          user;
        await firestore
          .collection("users")
          .doc(`${user.uid}`)
          .set({ displayName, uid, email, phoneNumber, providerId, photoURL });
        replace("/registration");
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, [firebase, firestore, replace]);

  // Check if the registration period is currently active
  // eslint-disable-next-line no-unused-vars
  const checkRedirect = async () => {
    try {
      const contentTmeSnapshot = await firestore
        .collection("tatacademy-content")
        .doc("regisTME")
        .get();
      const dataTme = contentTmeSnapshot.data();

      var compareDate = moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY"); //
      var startDate = moment(dataTme.rangeDateStart, "DD/MM/YYYY").subtract(
        1,
        "seconds"
      );
      var endDate = moment(dataTme.rangeDateEnd, "DD/MM/YYYY").add(
        1,
        "seconds"
      ); //

      const datecompare = compareDate.isBetween(startDate, endDate);
      if (!datecompare) {
        window.location.assign(dataTme.detailsNoOpen);
        console.log("no compare");
        return "close";
      }

      if (isLoaded(auth) && !isEmpty(auth)) {
        replace("/registration");
      }
      setIsLoading(false);
      console.log("in date");
      return "open";
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    // Uncomment below for: PROD & UAT - Always check to redirect as long as it close date
    // checkRedirect();
    // ----------------------------------
    // Uncomment below for: PRIVATE - Skip checking the open & close date
    setIsLoading(false);
    if (isLoaded(auth) && !isEmpty(auth)) {
      setIsLoading(false);
      replace("/registration");
    }
    // ----------------------------------
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    getAuthRedirectResult();
  }, [getAuthRedirectResult]);

  return (
    <ProvidersContainer>
      <Spin spinning={isLoading} style={{ backgroundColor: "#fff" }}>
        {isLoading || loading ? (
          <div
            style={{
              minHeight: "220px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            &nbsp;
          </div>
        ) : (
          <Row>
            <Row {...rowProps}>
              <HeaderCol>
                <Text level={3} color="darker">
                  {t("common:signin")}
                </Text>
                <br />
                <Text level={5} color="darker">
                  {t("common:ttt")}
                </Text>
              </HeaderCol>
            </Row>

            {!!providerSignInError && !!providerSignInError.message && (
              <Row {...rowProps}>
                <MarginBottomCol span={24}>
                  <Alert message={providerSignInError.message} type="error" />
                </MarginBottomCol>
              </Row>
            )}
            <Row {...rowProps}>
              <MarginBottomCol {...colProps}>
                <SocialButton
                  type="primary"
                  block
                  size="large"
                  onClick={signInWithFacebook}
                >
                  <Icon theme="filled" type="facebook" />
                  {t("common:social facebook signin")}
                </SocialButton>
              </MarginBottomCol>
            </Row>
            <Row {...rowProps}>
              <MarginBottomCol {...colProps}>
                <SocialButton
                  type="primary"
                  ghost
                  block
                  size="large"
                  onClick={signInWithGoogle}
                >
                  <Icon type="google" />
                  {t("common:social google signin")}
                </SocialButton>
              </MarginBottomCol>
            </Row>

            <Row {...rowProps}>
              <MarginBottomCol>
                <Text level={6} color="darker">
                  {t("auth:or sign in with email and password")}
                </Text>
              </MarginBottomCol>
            </Row>
            {!!errors && !!errors.signIn && (
              <Row {...rowProps}>
                <MarginBottomCol {...colProps}>
                  <Alert message={errors.signIn} type="error" />
                </MarginBottomCol>
              </Row>
            )}

            <Form {...formItemLayout} onSubmit={handleSubmit}>
              <Row {...rowProps}>
                <Col {...colProps}>{EmailInput}</Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps}>{PasswordInput}</Col>
              </Row>
              <Row {...rowProps}>
                <MarginBottom2xAndTextRightCol {...colProps}>
                  <Button
                    type="link"
                    htmlType="button"
                    href="https://tatacademy.com/th/forgetpassword"
                    target="_blank"
                  >
                    {t("auth:forgot password")}
                  </Button>
                </MarginBottom2xAndTextRightCol>
              </Row>

              <Row {...rowProps}>
                <MarginBottom2xAndTextCenterCol {...colProps}>
                  <Button type="primary" htmlType="submit" size="large">
                    {t("common:signin")}
                  </Button>
                </MarginBottom2xAndTextCenterCol>
              </Row>

              <Row {...rowProps}>
                <TextCenterCol {...colProps}>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={onClickToSignUp}
                  >
                    {t("auth:click to register")}
                  </Button>
                </TextCenterCol>
              </Row>
            </Form>
          </Row>
        )}
      </Spin>
    </ProvidersContainer>
  );
}
