import React, { useCallback, useEffect, useReducer } from "react";
import { produce } from "immer";
import { get, merge } from "lodash";
import mapValues from "lodash/mapValues";
import moment from "moment";
import { Col, Layout, Modal, Row, Spin } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";
import { useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import * as Yup from "yup";
import { Redirect } from "react-router";

import TATHero from "app/components/tat-hero";
import Container from "app/components/container";
import REGISTRATION_STATUS from "app/consts/registration-status.const";
import Form1PersonalInfo from "./form-1-personal-info";
import Form2OccupationAndEducation from "./form-2-occupation-and-education";
import Form3ContactInfo from "./form-3-contact-info";
import { Wizard } from "./wizard/wizard.component";
import Form4Summary from "./form-4-summary";

const FormContainer = styled(Container)`
  padding: 1.5rem 0;
  background-color: ${(props) => props.theme["$white"]};
`;

const defaultFormDataValues = {
  regisdate: moment().format("DD/MM/YYYY HH:mm"),
  contact: {
    personal: {
      profilePic: "",
      title: "",
      name: "",
      nickname: "",
      dateOfBirth: "",
      nationalID: "",
      nationalIDCardType: "",
      nationalIDExpirationDate: "",
      governmentOfficialID: "",
      governmentOfficialIDExpirationDate: "",
      mobilePhone: "",
      email: "",
      lineID: "",
    },
    agency: {
      profilePic: "",
      title: "",
      name: "",
      nickname: "",
      dateOfBirth: "",
      nationalID: "",
      nationalIDExpirationDate: "",
      governmentOfficialID: "",
      governmentOfficialIDExpirationDate: "",
      mobilePhone: "",
      email: "",
      lineID: "",
    },
  },
  addresses: {
    present: {
      street: "",
      tambon: "",
      district: "",
      province: "",
      postcode: "",
      phone: "",
      phoneExtension: "",
      fax: "",
    },
    company: {
      street: "",
      tambon: "",
      district: "",
      province: "",
      postcode: "",
      phone: "",
      phoneExtension: "",
      fax: "",
      website: "",
    },
    other: {
      street: "",
      tambon: "",
      district: "",
      province: "",
      postcode: "",
      phone: "",
      phoneExtension: "",
      fax: "",
    },
  },
  businessSector: "",
  businessTypes: [],
  businessMoreType: "",
  vacancy: "",
  company: "",
  governmentAffiliation: "",
  occupationHistoryKeys: [],
  occupationHistories: {},
  educationHistoryKeys: [],
  educationHistories: {},
  contactPerson: "personal",
  contactAddress: "present",
  agreements: null,
};

const initialState = {
  initialFormData: {},
  initialPage: 0,
  wizardState: "pending",
};

function reducer(state, action) {
  const getFormData = (data) => {
    for (let i = 0; i < data["occupationHistoryKeys"]?.length; i++) {
      if (
        data["occupationHistories"][data["occupationHistoryKeys"][i]] ===
        undefined
      ) {
        data["occupationHistories"][data["occupationHistoryKeys"][i]] = {
          vacancy: "",
        };
      }
    }

    for (let i = 0; i < data["educationHistoryKeys"]?.length; i++) {
      if (
        data["educationHistories"][data["educationHistoryKeys"][i]] ===
        undefined
      ) {
        data["educationHistories"][data["educationHistoryKeys"][i]] = {
          course: "",
        };
      }
    }

    return produce(defaultFormDataValues, (newData) => {
      merge(newData, data);
      newData["businessTypes"] = !!data["businessTypes"]
        ? [...data["businessTypes"]]
        : [];
    });
  };
  const toFormStep = ({ status }) => {
    return (
      {
        [REGISTRATION_STATUS.PERSONAL_INFORMATION]: 0,
        [REGISTRATION_STATUS.OCCUPATION_AND_EDUCATION]: 1,
        [REGISTRATION_STATUS.CONTACT_INFORMATION]: 2,
        [REGISTRATION_STATUS.SUMMARY]: 3,
      }[status] || 0
    );
  };

  const { payload, type } = action;

  console.log(action);

  switch (type) {
    case "initializing": {
      return { ...state, wizardState: "initializing" };
    }
    case "pending": {
      return { ...state, wizardState: "pending" };
    }
    case "loaded": {
      return { ...state, wizardState: "loaded" };
    }
    case "done": {
      const { email } = payload.meta;
      return {
        ...state,
        wizardState: "done",
        initialFormData: { contact: { personal: { email } } },
      };
    }
    case "new":
      return {
        ...state,
        initialFormData: getFormData({}),
        wizardState: "loaded",
      };
    case "resume":
      return {
        ...state,
        initialFormData: getFormData(payload.draft),
        initialPage: toFormStep(payload.meta),
        wizardState: "loaded",
      };
    default:
      throw new Error();
  }
}

function toRegistrationStatus(step) {
  return (
    {
      0: REGISTRATION_STATUS.PERSONAL_INFORMATION,
      1: REGISTRATION_STATUS.OCCUPATION_AND_EDUCATION,
      2: REGISTRATION_STATUS.CONTACT_INFORMATION,
      3: REGISTRATION_STATUS.SUMMARY,
      4: REGISTRATION_STATUS.DONE,
    }[step] || null
  );
}

export function RegistrationContainer() {
  const [{ initialFormData, wizardState, initialPage }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const {
    history: { replace },
  } = useRouter();
  const { t } = useTranslation(["common", "register"]);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const rdb = firebase.database();
  const uid = useSelector((s) => get(s, "firebase.auth.uid", null));
  const showConfirmOnExitRegistration = () => {
    Modal.confirm({
      title: t("register:You are closing a form"),
      content: t("register:When clicked the OK your change will be lost"),
      cancelText: t("common:cancel"),
      okText: t("register:back to home"),
      onOk() {
        return firebase.logout();
      },
      onCancel() {},
    });
  };

  // const onBeforeUnload = useCallback((event) => {
  //   // Cancel the event as stated by the standard.
  //   event.preventDefault();
  //   // Chrome requires returnValue to be set.
  //   event.returnValue = t('register:You are closing a form');
  // }, [t]);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', onBeforeUnload);
  //   return () => {window.removeEventListener('beforeunload', onBeforeUnload);};
  // }, [onBeforeUnload]);

  const personalInfoSchema = Yup.object().shape({
    contact: Yup.object().shape({
      personal: Yup.object().shape({
        profilePic: Yup.string().required(
          t("register:this field can not be empty")
        ),
        title: Yup.string().required(t("register:this field can not be empty")),
        name: Yup.string().required(t("register:this field can not be empty")),
        nickname: Yup.string().required(
          t("register:this field can not be empty")
        ),
        dateOfBirth: Yup.string()
          .required(t("register:this field can not be empty"))
          .matches(
            /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
            t("register:this field is invalid")
          ),
        nationalID: Yup.string().required(
          t("register:this field can not be empty")
        ),
        nationalIDCardType: Yup.string().required(
          t("register:this field can not be empty")
        ),
        nationalIDExpirationDate: Yup.string().when("nationalIDCardType", {
          is: "temporary",
          then: Yup.string().required(
            t("register:this field can not be empty")
          ),
        }),
        mobilePhone: Yup.string().required(
          t("register:this field can not be empty")
        ),
        email: Yup.string()
          .email(t("register:this field is invalid"))
          .required(t("register:this field can not be empty")),
      }),
    }),
    addresses: Yup.object().shape({
      present: Yup.object().shape({
        street: Yup.string().required(
          t("register:this field can not be empty")
        ),
        tambon: Yup.string().required(
          t("register:this field can not be empty")
        ),
        district: Yup.string().required(
          t("register:this field can not be empty")
        ),
        province: Yup.string().required(
          t("register:this field can not be empty")
        ),
        postcode: Yup.string().required(
          t("register:this field can not be empty")
        ),
        phone: Yup.string(),
        phoneExtension: Yup.string(),
        fax: Yup.string(),
      }),
    }),
  });
  const occupationAndEducationValidationSchema = Yup.object().shape({
    businessSector: Yup.string().required(
      t("register:this field can not be empty")
    ),
    businessTypes: Yup.array().when("businessSector", {
      is: "private",
      then: Yup.array()
        .of(Yup.string())
        .required(t("register:this field can not be empty")),
    }),
    businessMoreType: Yup.string().when("businessTypes", {
      is: (businessTypes) => businessTypes.includes("$other"),
      then: Yup.string().required(t("register:this field can not be empty")),
    }),
    vacancy: Yup.string().required(t("register:this field can not be empty")),
    company: Yup.string().required(t("register:this field can not be empty")),
    addresses: Yup.object().shape({
      company: Yup.object().shape({
        street: Yup.string().required(
          t("register:this field can not be empty")
        ),
        tambon: Yup.string().required(
          t("register:this field can not be empty")
        ),
        district: Yup.string().required(
          t("register:this field can not be empty")
        ),
        province: Yup.string().required(
          t("register:this field can not be empty")
        ),
        postcode: Yup.string().required(
          t("register:this field can not be empty")
        ),
        phone: Yup.string().required(t("register:this field can not be empty")),
        phoneExtension: Yup.string(),
        fax: Yup.string(),
      }),
    }),
    occupationHistories: Yup.lazy((obj) =>
      Yup.object(
        mapValues(obj, (value, key) => {
          return Yup.object().shape({
            vacancy: Yup.string().required(
              t("register:this field can not be empty")
            ),
          });
        })
      )
    ),
    educationHistories: Yup.lazy((obj) =>
      Yup.object(
        mapValues(obj, (value, key) => {
          return Yup.object().shape({
            course: Yup.string().required(
              t("register:this field can not be empty")
            ),
          });
        })
      )
    ),
  });

  const contactValidationSchema = Yup.object().shape({
    contactPerson: Yup.string().required(
      t("register:this field can not be empty")
    ),
    contact: Yup.object()
      .shape({
        agency: Yup.object().shape({
          name: Yup.string(),
          mobilePhone: Yup.string(),
          email: Yup.string(),
          fax: Yup.string(),
          lineID: Yup.string(),
        }),
      })
      .when("contactPerson", {
        is: "agency",
        then: Yup.object().shape({
          agency: Yup.object().shape({
            name: Yup.string().required(
              t("register:this field can not be empty")
            ),
            mobilePhone: Yup.string().required(
              t("register:this field can not be empty")
            ),
            email: Yup.string().email(t("register:this field is invalid")),
            // .required(t('register:this field can not be empty')),
            fax: Yup.string(),
            lineID: Yup.string(),
          }),
        }),
      }),
    contactAddress: Yup.string().required(
      t("register:this field can not be empty")
    ),
    addresses: Yup.object()
      .shape({
        other: Yup.object().shape({
          street: Yup.string(),
          tambon: Yup.string(),
          district: Yup.string(),
          province: Yup.string(),
          postcode: Yup.string(),
          phone: Yup.string(),
          phoneExtension: Yup.string(),
          fax: Yup.string(),
        }),
      })
      .when("contactAddress", {
        is: "other",
        then: Yup.object().shape({
          other: Yup.object().shape({
            street: Yup.string().required(
              t("register:this field can not be empty")
            ),
            tambon: Yup.string().required(
              t("register:this field can not be empty")
            ),
            district: Yup.string().required(
              t("register:this field can not be empty")
            ),
            province: Yup.string().required(
              t("register:this field can not be empty")
            ),
            postcode: Yup.string().required(
              t("register:this field can not be empty")
            ),
            phone: Yup.string(),
            phoneExtension: Yup.string(),
            fax: Yup.string(),
          }),
        }),
      }),
    agreements: Yup.object().shape({
      term1: Yup.bool().required(t("register:this field can not be empty")),
      term2: Yup.bool().required(t("register:this field can not be empty")),
      term3: Yup.bool().required(t("register:this field can not be empty")),
      term4: Yup.bool().required(t("register:this field can not be empty")),
      term5: Yup.bool().required(t("register:this field can not be empty")),
      term6: Yup.bool().required(t("register:this field can not be empty")),
    }),
  });

  const fetchDraft = useCallback(async () => {
    try {
      dispatch({ type: "initializing" });
      const documentSnapshot = await firestore
        .collection("drafts")
        .doc(`${uid}`)
        .get();
      const dataSnapshot = await rdb.ref(`registrations/${uid}`).once("value");

      const draft = documentSnapshot.data();
      const meta = dataSnapshot.val();
      const { status } = meta;

      if (status === REGISTRATION_STATUS.DONE) {
        return dispatch({ type: "done", payload: { meta } });
      }

      if (!!draft) {
        return dispatch({ type: "resume", payload: { draft, meta } });
      }

      return dispatch({ type: "new" });
    } catch (e) {
      // TODO error notice
      // do nothing when no draft data found
      console.error(e);
      return dispatch({ type: "new" });
    }
  }, [firestore, rdb, dispatch, uid]);

  const saveDraft = async (v, page) => {
    try {
      // const sss = await contactValidationSchema.validate(v);

      dispatch({ type: "pending" });
      const draft = merge({}, defaultFormDataValues, v);

      await firestore.collection("drafts").doc(uid).set(draft);
      await rdb
        .ref(`registrations/${uid}`)
        .set({ status: toRegistrationStatus(page) });
    } catch (e) {
      // TODO display error
      console.log(e);
    } finally {
      dispatch({ type: "loaded" });
    }
  };

  const saveRegistration = async (v) => {
    try {
      dispatch({ type: "pending" });
      const registration = merge({}, defaultFormDataValues, v);
      await firestore.collection("registrations").doc(uid).set(registration);
      await firestore.collection("drafts").doc(uid).delete();

      await rdb
        .ref(`registrations/${uid}`)
        .set({ status: toRegistrationStatus(4) }); // DONE

      return replace(
        `/result/successfully-registration/${v.contact.personal.email}`
      );
    } catch (e) {
      // TODO display error
      console.log(e);
    } finally {
      dispatch({ type: "loaded" });
    }
    return null;
  };

  const onCancel = () => {
    showConfirmOnExitRegistration();
  };

  useEffect(() => {
    fetchDraft();
  }, [fetchDraft]);

  console.log("re rendering ");

  if (wizardState === "done") {
    return (
      <Redirect
        to={`/result/successfully-registration/${initialFormData.contact.personal.email}`}
      />
    );
  }

  return (
    <Layout.Content>
      <Spin
        spinning={["pending", "initializing"].includes(wizardState)}
        size="default"
        style={{ position: "fixed", maxHeight: "100%" }}
      >
        <Row>
          <Col span={24}>
            <TATHero />
          </Col>
        </Row>
        <FormContainer>
          {["pending", "loaded"].includes(wizardState) && (
            <Wizard
              initialValues={initialFormData}
              initialPage={initialPage}
              onSave={saveDraft}
              onNext={saveDraft}
              onCancel={onCancel}
              onSubmit={saveRegistration}
            >
              <Wizard.Page
                title={t("register:personal information")}
                validationSchema={personalInfoSchema}
              >
                <Form1PersonalInfo />
              </Wizard.Page>
              <Wizard.Page
                title={t("register:occupation and education")}
                validationSchema={occupationAndEducationValidationSchema}
              >
                <Form2OccupationAndEducation />
              </Wizard.Page>
              <Wizard.Page
                title={t("register:contact information")}
                validationSchema={contactValidationSchema}
              >
                <Form3ContactInfo />
              </Wizard.Page>
              <Wizard.Page title={t("register:revise and submit")}>
                <Form4Summary />
              </Wizard.Page>
            </Wizard>
          )}
        </FormContainer>
      </Spin>
    </Layout.Content>
  );
}
