import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  Avatar,
  Checkbox as AntCheckbox,
  Col,
  Descriptions,
  Divider,
  Row,
  Spin,
} from "antd";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import { get } from "lodash";

import Text from "app/components/text";
import { media } from "app/styles/media-query";
import { useFormikContext } from "formik";
import { useRegistrationFileStorage } from "app/hooks/use-registration-file-storage";

const RowMb1 = styled(Row)`
  margin-bottom: 1rem;
`;

const RowMb2 = styled(Row)`
  margin-bottom: 2rem;
`;

const AvatarCol = styled(Col)`
  text-align: center;
  border: 1px solid #d9e8f4;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${media.down("sm")} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Checkbox = styled(AntCheckbox)`
  &.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: default;

    > .ant-checkbox.ant-checkbox-disabled {
      cursor: default;
      > .ant-checkbox-input {
        cursor: default;
      }
    }

    > .ant-checkbox-disabled {
      + span {
        color: ${(props) => props.theme["$gray"]};
        cursor: default;
      }
    }
  }
`;

function toBuddhistDate(date) {
  if (!date) {
    return "";
  }

  const m = moment(date, "DD/MM/YYYY");
  const buddhistYear = m.year() + 543;
  return m.isValid() ? m.format(`DD/MM/${buddhistYear}`) : "";
}

export function Form4SummaryComponent() {
  const { t } = useTranslation(["common", "register"]);
  const [avatarLoaded, setAvatarLoaded] = useState(true);
  const [avatarDownloadURL, setAvatarDownloadURL] = useState(null);

  const { values } = useFormikContext();
  const { getFileDownloadURL } = useRegistrationFileStorage();

  // personal information
  const profilePic = get(values, "contact.personal.profilePic", "");
  const name = get(values, "contact.personal.name", "");
  const title = get(values, "contact.personal.title", "");
  const nickname = get(values, "contact.personal.nickname", "");
  const dateOfBirth = get(values, "contact.personal.dateOfBirth", "");
  const mobilePhone = get(values, "contact.personal.mobilePhone", "");
  const email = get(values, "contact.personal.email", "");
  const nationalID = get(values, "contact.personal.nationalID", "");
  const nationalIDCardType = get(
    values,
    "contact.personal.nationalIDCardType",
    ""
  );
  const nationalIDExpirationDate = get(
    values,
    "contact.personal.nationalIDExpirationDate",
    ""
  );
  const governmentOfficialID = get(
    values,
    "contact.personal.governmentOfficialID",
    ""
  );
  const governmentOfficialIDExpirationDate = get(
    values,
    "contact.personal.governmentOfficialIDExpirationDate",
    ""
  );

  // contact and education
  const businessSector = get(values, "businessSector", "");
  const businessTypes = get(values, "businessTypes", []);
  const businessMoreType = get(values, "businessMoreType", "");
  const vacancy = get(values, "vacancy", "");
  const company = get(values, "company", "");
  const governmentAffiliation = get(values, "governmentAffiliation", "");
  const governmentAffiliationCertificate = get(
    values,
    "governmentAffiliationCertificate",
    ""
  );
  const addresses = get(values, "addresses", {});
  const occupationHistoryKeys = get(values, "occupationHistoryKeys", []);
  const occupationHistories = get(values, "occupationHistories", {});
  const educationHistoryKeys = get(values, "educationHistoryKeys", []);
  const educationHistories = get(values, "educationHistories", {});
  const contactPerson = get(values, "contactPerson", "");
  const contactAddress = get(values, "contactAddress", "");

  const [isProfilePicUrlError, setIsProfilePicUrlError] = useState(false);

  const fetchImageUrl = useCallback(async () => {
    try {
      setAvatarLoaded(false);
      const downloadURL = await getFileDownloadURL(profilePic);
      setAvatarDownloadURL(downloadURL);
    } catch (e) {
      setIsProfilePicUrlError(true);
    } finally {
      setAvatarLoaded(true);
    }
  }, [profilePic, setAvatarLoaded, setAvatarDownloadURL, getFileDownloadURL]);

  useEffect(() => {
    fetchImageUrl();
  }, [fetchImageUrl]);

  return (
    <>
      <RowMb1>
        <Col>
          <Text level={4} className="text-darker">
            {t("register:revise and submit")}
          </Text>
        </Col>
      </RowMb1>

      <RowMb1>
        <Col>
          <Text level={5} className="text-darker">
            {t("register:personal information")}
          </Text>
        </Col>
      </RowMb1>

      <RowMb2 type="flex" gutter={[32, 0]}>
        <AvatarCol
          xs={22}
          sm={8}
          md={6}
          // style={{ margin: 'auto', marginBottom: '1rem' }}
        >
          <Spin spinning={!avatarLoaded}>
            {isProfilePicUrlError ? (
              <Avatar size={144} shape="square">
                Image not found
              </Avatar>
            ) : (
              <Avatar size={144} src={avatarDownloadURL} shape="square" />
            )}
          </Spin>
        </AvatarCol>

        <Col xs={24} sm={16} md={18}>
          <Descriptions layout="horizontal" colon={false} column={2}>
            <Descriptions.Item label={t("register:title name")} span={2}>
              {title}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:full name")} span={2}>
              {name}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:nickname")}>
              {nickname}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:date of birth")}>
              {toBuddhistDate(dateOfBirth)}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:present address")} span={2}>
              {[
                get(addresses, "present.street", ""),
                get(addresses, "present.tambon", ""),
                get(addresses, "present.district", ""),
                get(addresses, "present.province", ""),
                get(addresses, "present.postcode", ""),
              ].join(" ")}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:home phone")}>
              {get(addresses, "present.phone", "")}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:fax phone")}>
              {get(addresses, "present.fax", "")}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:mobile phone")}>
              {mobilePhone}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:email")}>
              {email}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:national id")} span={2}>
              {nationalID}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("register:national id card type")}
              span={2}
            >
              {t(`register:${nationalIDCardType}`)}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:expiration date")} span={2}>
              {toBuddhistDate(nationalIDExpirationDate)}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("register:government official id")}
              span={2}
            >
              {governmentOfficialID}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:expiration date")} span={2}>
              {toBuddhistDate(governmentOfficialIDExpirationDate)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </RowMb2>

      <RowMb1>
        <Col>
          <Text level={5} className="text-darker">
            {t("register:occupation and education")}
          </Text>
        </Col>
      </RowMb1>
      <RowMb1 type="flex" gutter={[32, 0]}>
        <Col xs={24}>
          <Descriptions layout="horizontal" colon={false} column={2}>
            <Descriptions.Item label={t("register:business sector")} span={2}>
              {{ government: "ภาครัฐ", private: "ภาคเอกชน" }[businessSector] ||
                ""}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:business type")} span={2}>
              {`${businessTypes
                .filter((v) => v !== "$other")
                .map(
                  (v) =>
                    ({
                      accommodations: "ที่พัก",
                      tour: "นำเที่ยว",
                      transportation: "คมนาคมและขนส่ง",
                      food: "อาหารและเครื่องดื่ม",
                      health: "สุขภาพและความงาม",
                      media: "สื่อมวลชน",
                      technology: "เทคโนโลยี",
                      property: "อสังหาริมทรัพย์",
                    }[v] || "")
                )
                .join(" ")} ${businessMoreType}`}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:vacancy")}>
              {vacancy}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:company")}>
              {company}
            </Descriptions.Item>
            {businessSector === "government" && (
              <>
                <Descriptions.Item
                  label={t("register:government affiliation")}
                  span={2}
                >
                  {governmentAffiliation}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("register:government affiliation certificate")}
                  span={2}
                >
                  {governmentAffiliationCertificate &&
                    governmentAffiliationCertificate.fileName}
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label={t("register:present address")} span={2}>
              {[
                get(addresses, "company.street", ""),
                get(addresses, "company.tambon", ""),
                get(addresses, "company.district", ""),
                get(addresses, "company.province", ""),
                get(addresses, "company.postcode", ""),
              ].join(" ")}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:phone")}>
              {`${get(addresses, "company.phone")} ${
                get(addresses, "company.phoneExtension", "").length > 0
                  ? `${t("tme:phone extension")} ${get(
                      addresses,
                      "company.phoneExtension",
                      ""
                    )}`
                  : ""
              }`}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:fax")}>
              {get(addresses, "company.fax")}
            </Descriptions.Item>
            <Descriptions.Item label={t("register:website")}>
              {get(addresses, "company.website")}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </RowMb1>

      <RowMb1>
        <Col>
          <Text level={5} className="text-darker">
            {t(
              "register:in case you have worked in the tourism industry for the past 3 years, please specify more"
            )}
          </Text>
        </Col>
      </RowMb1>

      {occupationHistoryKeys.length > 0 ? (
        occupationHistoryKeys.map((key, index) => (
          <Row type="flex" gutter={{ xs: [0, 32], sm: [32, 0] }} key={key}>
            <Col xs={2} sm={1} md={1}>
              <Text level={6}>{`${index + 1}.`}</Text>
            </Col>
            <Col xs={22} sm={23} md={23}>
              <Descriptions layout="horizontal" colon={false} column={2}>
                <Descriptions.Item label={t("register:vacancy")}>
                  {get(occupationHistories, `[${key}].vacancy`, "")}
                </Descriptions.Item>
                {/* <Descriptions.Item label={t("register:institution organizer")}>
                  {get(occupationHistories, `[${key}].company`, "")}
                </Descriptions.Item> */}
                {/* <Descriptions.Item
                  label={t("register:role and responsibility")}
                >
                  {get(occupationHistories, `[${key}].role`, "")}
                </Descriptions.Item> */}
              </Descriptions>
            </Col>
          </Row>
        ))
      ) : (
        <RowMb1>
          <Col>
            <Text level={6} color="light">
              - ไม่มี -
            </Text>
          </Col>
        </RowMb1>
      )}

      <RowMb1>
        <Col>
          <Text level={5} className="text-darker">
            {t("register:education history")}
          </Text>
        </Col>
      </RowMb1>

      {educationHistoryKeys.length > 0 ? (
        educationHistoryKeys.map((key, index) => (
          <Row type="flex" gutter={{ xs: [0, 32], sm: [32, 0] }} key={key}>
            <Col xs={2} sm={1} md={1}>
              <Text level={6}>{`${index + 1}.`}</Text>
            </Col>
            <Col xs={22} sm={23} md={23}>
              <Descriptions layout="horizontal" colon={false} column={2}>
                <Descriptions.Item label={t("register:course")}>
                  {get(educationHistories, `[${key}].course`, "")}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        ))
      ) : (
        <RowMb1>
          <Col>
            <Text level={6} color="light">
              - ไม่มี -
            </Text>
          </Col>
        </RowMb1>
      )}

      <RowMb1>
        <Col>
          <Text level={5} className="text-darker">
            {t("register:contact")}
          </Text>
        </Col>
      </RowMb1>
      <RowMb1 type="flex" gutter={16}>
        <Col>
          <Checkbox checked={contactPerson === "personal"} disabled>
            {t("register:directly candidate")}
          </Checkbox>
        </Col>
        <Col>
          <Checkbox checked={contactPerson === "agency"} disabled>
            {t("register:through the coordinator")}
          </Checkbox>
        </Col>
      </RowMb1>

      {contactPerson === "agency" && (
        <Row>
          <Col>
            <Col xs={24} sm={16} md={18}>
              <Descriptions layout="horizontal" colon={false} column={2}>
                <Descriptions.Item label={t("tme:full name")}>
                  {get(values, "contact.agency.name", "")}
                </Descriptions.Item>
                <Descriptions.Item label={t("tme:fax phone")}>
                  {get(values, "contact.agency.fax", "")}
                </Descriptions.Item>
                <Descriptions.Item label={t("tme:phone")}>
                  {get(values, "contact.agency.mobilePhone", "")}
                </Descriptions.Item>
                <Descriptions.Item label={t("tme:email")}>
                  {get(values, "contact.agency.email", "")}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Col>
        </Row>
      )}

      <RowMb1>
        <Col>
          <Text level={5} className="text-darker">
            {t("register:shipping address")}
          </Text>
        </Col>
      </RowMb1>
      <RowMb1 gutter={16} type="flex">
        <Col>
          <Checkbox checked={contactAddress === "present"} disabled>
            {t("register:present address")}
          </Checkbox>
        </Col>
        <Col>
          <Checkbox checked={contactAddress === "company"} disabled>
            {t("register:company address")}
          </Checkbox>
        </Col>
        <Col>
          <Checkbox checked={contactAddress === "other"} disabled>
            {t("register:other address")}
          </Checkbox>
        </Col>
      </RowMb1>

      {contactAddress === "other" && (
        <Row>
          <Col>
            <Col xs={24} sm={16} md={18}>
              <Descriptions layout="horizontal" colon={false} column={2}>
                <Descriptions.Item label={t("tme:shipping address")} span={2}>
                  {[
                    get(addresses, "present.street", ""),
                    get(addresses, "present.tambon", ""),
                    get(addresses, "present.district", ""),
                    get(addresses, "present.province", ""),
                    get(addresses, "present.postcode", ""),
                  ].join(" ")}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Col>
        </Row>
      )}

      <Divider />
      <RowMb1>
        <Col md={1} xs={2}>
          <Checkbox checked disabled />
        </Col>
        <Col md={21} xs={22} style={{ color: "#999" }}>
          {t("register:term and con 1")}
        </Col>
      </RowMb1>
      <RowMb1>
        <Col md={1} xs={2}>
          <Checkbox checked disabled />
        </Col>
        <Col md={21} xs={22} style={{ color: "#999" }}>
          {t("register:term and con 2")}
        </Col>
      </RowMb1>
      <RowMb2>
        <Col md={1} xs={2}>
          <Checkbox checked disabled />
        </Col>
        <Col md={21} xs={22} style={{ color: "#999" }}>
          {t("register:term and con 3")}
        </Col>
      </RowMb2>
      <RowMb2>
        <Col md={1} xs={2}>
          <Checkbox checked disabled />
        </Col>
        <Col md={21} xs={22} style={{ color: "#999" }}>
          <Trans i18nKey="register:term and con 4">
            ข้าพเจ้าได้อ่านและยอมรับ
            <a href="https://thai.tourismthailand.org/Information/นโยบายความเป็นส่วนตัว">
              นโยบายความเป็นส่วนตัว
            </a>{" "}
            นี้แล้ว ข้าพเจ้ายินยอมให้ ททท. เก็บรวบรวม
            ใช้และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์สำหรับการเข้ารับการอบรมหลักสูตรการบริหารการท่องเที่ยวสำหรับผู้บริหารระดับสูง
            รุ่นที่ 5 ประจำปี 2567 (Tourism Management Program for Executives:
            TME5)
            และเพื่อวัตถุประสงค์อื่นใดที่เกี่ยวเนื่องกันตามที่ปรากฏในนโยบายความเป็นส่วนตัวเท่านั้น
          </Trans>
        </Col>
      </RowMb2>
      <RowMb2>
        <Col md={1} xs={2}>
          <Checkbox checked disabled />
        </Col>
        <Col md={21} xs={22} style={{ color: "#999" }}>
          <Trans i18nKey="register:term and con 5">
            ข้าพเจ้าเข้าใจดีว่า
            ข้าพเจ้าสามารถยกเลิกความยินยอมสำหรับกิจกรรมข้างต้นเสียเมื่อใดก็ได้
            โดยส่งข้อความไปยัง ศูนย์พัฒนาวิชาการด้านตลาดการท่องเที่ยว (TAT
            Academy) email :
            <a href="mailto://sec.tatacademy@gmail.com">
              sec.tatacademy@gmail.com
            </a>
            เบอร์ติดต่อ 02 250 5500 ต่อ 4920-4
          </Trans>
        </Col>
      </RowMb2>
      <RowMb2>
        <Col md={1} xs={2}>
          <Checkbox checked disabled />
        </Col>
        <Col md={21} xs={22} style={{ color: "#999" }}>
          {t("register:term and con 6")}
        </Col>
      </RowMb2>
    </>
  );
}
