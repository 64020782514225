import mediaHelper from 'styled-media-helper';

export const media = mediaHelper({
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
});
