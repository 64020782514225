import {Col, Layout, Row} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {Redirect, Route, Switch} from 'react-router-dom';

import TATHero from '../../components/tat-hero';
import GlobalContainer from '../../components/container';
import PageNotFound from './page-not-found';
import SuccessfullyRegistration from './successfully-registration';

const Container = styled(GlobalContainer)`
  background-color: ${props => props.theme['$white']};
`;

export function ResultContainer() {
  return (
    <Layout.Content>
      <Row>
        <Col span={24}><TATHero/></Col>
      </Row>
      <Container>
        <Switch>
          <Route exact path='/result' render={() => <Redirect to='/result/page-not-found'/>}/>
          <Route exact path='/result/successfully-registration/:email' component={SuccessfullyRegistration}/>
          <Route exact path='/result/page-not-found' component={PageNotFound}/>
        </Switch>
      </Container>
    </Layout.Content>
  );
}
