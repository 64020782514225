import * as React from 'react';
import {Col, Row} from 'antd';
import styled from 'styled-components';

function Container({children, gutter = [0, 0], ...restProps}){
  return (
    <Row type='flex' justify='center' gutter={gutter} {...restProps}>
      <Col xs={22} sm={22} md={22} lg={20} xl={18}>
        {children}
      </Col>
    </Row>
  );
}

export default styled(Container)``;
