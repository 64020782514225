import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';
import 'moment/locale/en-gb';
import 'moment/locale/th';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    ns: ['common', 'register', 'auth'],
    fallbackLng: [
      'th', 'en'
    ],
    debug: false,
    detection: {
      order: ['localStorage'],
      caches: ['localStorage']
    },
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
      wait: true,
      useSuspense: false
    }
  });

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n.on('languageChanged', (lng) => moment.locale(lng));

export default i18n;
