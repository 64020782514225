import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import {Button, Col, Row} from 'antd';
// import Icon from '@ant-design/icons';
import { Button, Col, Icon, Row } from "antd";

import { v4 as uuidv4 } from "uuid";
import { useFormikContext } from "formik";

import styled from "styled-components";
import { get } from "lodash";

import Input from "app/components/input";
import Text from "app/components/text";
import Form from "app/components/form";
import FormRadioGroup from "app/components/form-radio-group";
import FormCheckboxGroup from "app/components/form-checkbox-group";
import FormFileUploadInput from "app/components/form-file-upload-input";
import FormCascadeLocationInput from "../../../components/form-cascade-location-input";
import FormItem from "app/components/form-item";
import { useRegistrationFileStorage } from "app/hooks/use-registration-file-storage";

const AddMoreButton = styled(Button)`
  &.ant-btn.ant-btn-dashed {
    border-color: ${(props) => props.theme["$blue"]};
    color: ${(props) => props.theme["$blue"]};
  }
`;

export function Form2OccupationAndEducationComponent() {
  // const {handleChange, values, errors, setFieldValue, initialValues} = useFormikContext();
  const { handleChange, values, errors, setFieldValue } = useFormikContext();
  const { uploadAttachedFileRequest } = useRegistrationFileStorage();

  const [
    governmentAffiliationCertificateLoaded,
    setGovernmentAffiliationCertificateLoaded,
  ] = useState(true);

  const [tambon, setTambon] = useState(
    get(values, "addresses.company.tambon", "")
  );
  const [district, setDistrict] = useState(
    get(values, "addresses.company.district", "")
  );
  const [province, setProvince] = useState(
    get(values, "addresses.company.province", "")
  );
  const [zipcode, setZipcode] = useState(
    get(values, "addresses.company.postcode", "")
  );

  const { t } = useTranslation(["common", "register"]);
  const rowProps = { gutter: [32, 0] };
  const leftColProps = { md: 12, lg: 11, xs: 24 };
  const rightColProps = { md: 12, lg: { offset: 2, span: 11 }, xs: 24 };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      span: 24,
    },
  };

  // React.useEffect(() => {
  //   console.log("values", values);
  // }, [values]);

  // React.useEffect(() => {
  //   console.log("formErrors", errors);
  // }, [errors]);

  const businessSectorInput = {
    label: t("register:business sector"),
    id: "businessSector",
    tag: FormRadioGroup,
    formErrors: errors,
    formValues: values,
    onChange: (e) => {
      setFieldValue("businessTypes", []);
      setFieldValue("businessMoreType", "");
      handleChange(e);
    },
    tagProps: {
      options: [
        {
          value: "government", // ภาครัฐ
          label: t("register:government sector"),
        },
        {
          value: "private", // ภาคเอกชน
          label: t("register:private sector"),
        },
      ],
    },
  };

  const businessTypeInput = {
    label: t("register:business type"),
    id: "businessTypes",
    id2: "businessMoreType",
    tag: FormCheckboxGroup,
    formErrors: errors,
    formValues: values,
    onChange: (checkList) => {
      setFieldValue("businessTypes", checkList);
      // console.log("checkList =", checkList);
    },
    tagProps: {
      onMoreInputChange: (v) => {
        setFieldValue("businessMoreType", v);
      },
      defaultMoreInputValue: values["businessMoreType"],
      options: [
        {
          value: "accommodations", // ที่พัก
          label: t("register:accommodations business"),
        },
        {
          value: "tour", // นำเที่ยว
          label: t("register:tour business"),
        },
        {
          value: "transportation", // คมนาคมและขนส่ง
          label: t("register:transportation business"),
        },
        {
          value: "food", // อาหารและเครื่องดื่ม
          label: t("register:food and beverage business"),
        },
        {
          value: "health", // สุขภาพและความงาม
          label: t("register:health and beauty business"),
        },
        {
          value: "media", // สื่อมวลชน
          label: t("register:media business"),
        },
        {
          value: "technology", // เทคโนโลยี
          label: t("register:technology business"),
        },
        {
          value: "property", // อสังหาริมทรัพย์
          label: t("register:property business"),
        },
      ],
    },
  };

  const vacancyInput = {
    label: t("register:vacancy"),
    id: "vacancy",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const companyInput = {
    label: t("register:company"),
    id: "company",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const governmentAffiliationInput = {
    label: (
      <Text>
        {t("register:government affiliation")}{" "}
        <Text color="gray">({t("register:government case")})</Text>
      </Text>
    ),
    id: "governmentAffiliation",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const governmentAffiliationCertificateInput = {
    label: (
      <Text>
        {t("register:government affiliation certificate")}{" "}
        <Text color="gray">
          ({t("register:or government affiliation register certificate")})
        </Text>
      </Text>
    ),
    id: "governmentAffiliationCertificate",
    tag: FormFileUploadInput,
    formErrors: errors,
    formValues: values,
    // onChange: url => {setFieldValue('governmentAffiliationCertificate', url);},
    onChange: async (info) => {
      if (info.file.status === "uploading") {
        setGovernmentAffiliationCertificateLoaded(false);
      } else if (info.file.status === "done") {
        setGovernmentAffiliationCertificateLoaded(true);
        // console.log(info.file);
        const fileUrl = get(info, "file.xhr.File.fullPath", null);
        const fileName = get(info, "file.name", null);
        if (!!fileUrl) {
          setFieldValue("governmentAffiliationCertificate", {
            fileName,
            fileUrl,
          });
        }
      }
    },
    tagProps: {
      loading: !governmentAffiliationCertificateLoaded,
      label: get(values, "governmentAffiliationCertificate.fileName", null),
      customRequest: async ({ onSuccess, onError, file }) => {
        try {
          const uploadTaskSnapshot = await uploadAttachedFileRequest(file);
          onSuccess(null, uploadTaskSnapshot);
        } catch (e) {
          onError(e);
        }
      },
    },
  };

  const addressInput = {
    label: t("register:company address"),
    id: "addresses.company.street",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const provinceAndDistrictInput = {
    id: "addresses.company.province",
    id2: "addresses.company.district",
    tag: FormCascadeLocationInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) => {},
    tagProps: {
      firstLocationType: "province",
      secondLocationType: "district",
      setProvince: setProvince,
      setDistrict: setDistrict,
      setTambon: setTambon,
      setZipcode: setZipcode,
      province: province,
      district: district,
      onProvinceChange: (v) => {
        // console.log("province=", v);
        setFieldValue("addresses.company.province", v);
      },
      onDistrictChange: (v) => {
        // console.log("district=", v);
        setFieldValue("addresses.company.district", v);
      },
    },
  };
  const tambonAndZipcodeInput = {
    id: "addresses.company.tambon",
    id2: "addresses.company.postcode",
    tag: FormCascadeLocationInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) => {},
    tagProps: {
      firstLocationType: "tambon",
      secondLocationType: "zipcode",
      setDistrict: setDistrict,
      setTambon: setTambon,
      setZipcode: setZipcode,
      district: district,
      tambon: tambon,
      zipcode: zipcode,
      onTambonChange: (v) => {
        // console.log("tambon=", v);
        setFieldValue("addresses.company.tambon", v);
      },
      onZipcodeChange: (v) => {
        // console.log("zipcode=", v);
        setFieldValue("addresses.company.postcode", v);
      },
    },
  };

  const phoneInput = {
    label: t("register:office phone"),
    id: "addresses.company.phone",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const phoneExtensionInput = {
    label: (
      <Text>
        {t("register:phone extension")}{" "}
        <Text color="gray">({t("register:optional")})</Text>
      </Text>
    ),
    id: "addresses.company.phoneExtension",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const faxPhoneInput = {
    label: (
      <Text>
        {t("register:fax phone")}{" "}
        <Text color="gray">({t("register:optional")})</Text>
      </Text>
    ),
    id: "addresses.company.fax",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const websiteInput = {
    label: (
      <Text>
        {t("register:website")}{" "}
        <Text color="gray">({t("register:optional")})</Text>
      </Text>
    ),
    id: "addresses.company.website",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const occupationHistoryItems = values.occupationHistoryKeys.map((k, idx) => {
    const occupationHistoryVacancyInput = {
      label: t("register:vacancy"),
      id: `occupationHistories['${k}'].vacancy`,
      tag: Input,
      formErrors: errors,
      formValues: values,
      onChange: handleChange,
      actionLabel: "ลบ",
      actionType: "danger",
      onAction: () => {
        const _occupationHistories = Object.assign(
          {},
          values["occupationHistories"]
        );
        delete _occupationHistories[k];
        setFieldValue(
          "occupationHistoryKeys",
          values["occupationHistoryKeys"].filter((o) => o !== k)
        );
        setFieldValue("occupationHistories", _occupationHistories);
      },
    };

    // const workHistoriesCompanyInput = {
    //   label: t("register:company"),
    //   id: `occupationHistories['${k}'].company`,
    //   tag: Input,
    //   formErrors: errors,
    //   formValues: values,
    //   onChange: handleChange,
    // };

    // const workHistoriesRoleAndResponsibilityInput = {
    //   label: t("register:role and responsibility"),
    //   id: `occupationHistories['${k}'].role`,
    //   tag: Input,
    //   formErrors: errors,
    //   formValues: values,
    //   onChange: handleChange,
    // };
    const workHistoryItemsProps = { sm: 23 };
    // const workHistoryItemsLeftColProps = { md: 10, lg: 10, xs: 20 };
    // const workHistoryItemsRightColProps = {
    //   md: { span: 12, offset: 0 },
    //   lg: { span: 12, offset: 0 },
    //   xs: { span: 20, offset: 4 },
    // };
    return (
      <div key={`occupation-histories-${k}`}>
        <Row {...rowProps}>
          <Col sm={1}>
            <Text level={4}>{idx + 1}</Text>
          </Col>
          <Col {...workHistoryItemsProps}>
            <FormItem {...occupationHistoryVacancyInput} />
          </Col>
        </Row>
      </div>
    );
  });

  const educationHistoryItems = values.educationHistoryKeys.map((k, idx) => {
    const courseInput = {
      label: t("register:course"),
      id: `educationHistories['${k}'].course`,
      tag: Input,
      formErrors: errors,
      formValues: values,
      onChange: handleChange,
      actionLabel: "ลบ",
      actionType: "danger",
      onAction: () => {
        const _educationHistories = Object.assign(
          {},
          values["educationHistories"]
        );
        delete _educationHistories[k];
        setFieldValue(
          "educationHistoryKeys",
          values["educationHistoryKeys"].filter((e) => e !== k)
        );
        setFieldValue("educationHistories", _educationHistories);
      },
    };

    // eslint-disable-next-line no-unused-vars
    const institutionInput = {
      label: t("register:institution organizer"),
      id: `educationHistories['${k}'].institution`,
      tag: Input,
      formErrors: errors,
      formValues: values,
      onChange: handleChange,
    };

    // eslint-disable-next-line no-unused-vars
    const graduatedYearInput = {
      label: t("register:graduated year"),
      id: `educationHistories['${k}'].graduatedYear`,
      tag: Input,
      formErrors: errors,
      formValues: values,
      onChange: handleChange,
    };

    const educationHistoryItemsProps = { sm: 23 };
    return (
      <div key={`education-histories-${k}`}>
        <Row {...rowProps}>
          <Col sm={1}>
            <Text level={4}>{idx + 1}</Text>
          </Col>
          <Col {...educationHistoryItemsProps}>
            <FormItem {...courseInput} />
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <>
      <Text level={4} style={{ display: "block", marginBottom: "2rem" }}>
        {t("register:occupation and education")}
      </Text>

      <Row {...rowProps}>
        <Col span={24}>
          <FormItem {...businessSectorInput} />
        </Col>
      </Row>

      {values.businessSector === "private" && (
        <Row {...rowProps}>
          <Col span={24}>
            <FormItem {...businessTypeInput} />
          </Col>
        </Row>
      )}

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...vacancyInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...companyInput} />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...governmentAffiliationInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...governmentAffiliationCertificateInput} />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...addressInput} />
        </Col>
        <Col {...rightColProps}>
          <Input.Group compact>
            <Row gutter={[16, 0]}>
              <Col span={16}>
                <FormItem {...phoneInput} />
              </Col>
              <Col span={8}>
                <FormItem {...phoneExtensionInput} />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...provinceAndDistrictInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...tambonAndZipcodeInput} />
        </Col>
      </Row>

      {/* <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...postcodeInput} />
        </Col>
        
      </Row> */}

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...faxPhoneInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...websiteInput} />
        </Col>
      </Row>

      <Text level={5}>
        {t(
          "register:in case you have worked in the tourism industry for the past 3 years, please specify more"
        )}
      </Text>

      {occupationHistoryItems}

      <Form.Item {...formItemLayoutWithOutLabel}>
        <AddMoreButton
          type="dashed"
          onClick={() => {
            const nextId = uuidv4();
            const nextKeys = values["occupationHistoryKeys"].concat(nextId);
            const _occupationHistories = Object.assign(
              {},
              values["occupationHistories"]
            );
            setFieldValue("occupationHistoryKeys", nextKeys);
            setFieldValue("occupationHistories", {
              ..._occupationHistories,
              [nextId]: { vacancy: "" },
            });
          }}
          ghost
          block
          htmlType="button"
        >
          <Icon type="plus" /> {t("register:add more")}
        </AddMoreButton>
      </Form.Item>

      <Text level={4}>{t("register:education history")}</Text>

      {educationHistoryItems}
      <Form.Item {...formItemLayoutWithOutLabel}>
        <AddMoreButton
          type="dashed"
          onClick={() => {
            const nextId = uuidv4();
            const nextKeys = values["educationHistoryKeys"].concat(nextId);
            const _educationHistories = Object.assign(
              {},
              values["educationHistories"]
            );
            setFieldValue("educationHistoryKeys", nextKeys);
            setFieldValue("educationHistories", {
              ..._educationHistories,
              [nextId]: { course: "" },
            });
          }}
          ghost
          block
          htmlType="button"
        >
          <Icon type="plus" /> {t("register:add more")}
        </AddMoreButton>
      </Form.Item>
    </>
  );
}
