import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "app/components/select";

export function FormCascadeDateInputComponent({
  onChange,
  value,
  dateFormat = "DD/MM/YYYY",
  dateOptions = new Array(31).fill(0).map((val, idx) => idx + 1),
  monthOptions = new Array(12).fill(0).map((val, idx) => idx + 1),
  yearOptions = new Array(100)
    .fill(0)
    .map((val, idx) => moment().get("year") - idx),
}) {
  const mDate = moment(value, dateFormat);
  const isValidMomentDate = mDate.isValid();
  const defaultDate = isValidMomentDate ? mDate.get("date") : "";
  const defaultMonth = isValidMomentDate ? mDate.get("month") + 1 : "";
  const defaultYear = isValidMomentDate ? mDate.get("year") : "";

  const [date, setDate] = useState(defaultDate);
  const [month, setMonth] = useState(defaultMonth);
  const [year, setYear] = useState(defaultYear);
  useEffect(() => {
    // init date
    setDate(defaultDate);
    setMonth(defaultMonth);
    setYear(defaultYear);
  }, [setDate, setMonth, setYear, defaultDate, defaultMonth, defaultYear]);

  const onSelectChange = (type) => (v) => {
    switch (type) {
      case "date": {
        setDate(v);
        const newMomentDate = moment(`${v}/${month}/${year}`, "DD/MM/YYYY");
        const newValue = newMomentDate.isValid()
          ? newMomentDate.format(dateFormat)
          : "";
        return onChange(newValue);
      }
      case "month": {
        setMonth(v);
        const newMomentDate = moment(`${date}/${v}/${year}`, "DD/MM/YYYY");
        const newValue = newMomentDate.isValid()
          ? newMomentDate.format(dateFormat)
          : "";
        return onChange(newValue);
      }
      case "year": {
        setYear(v);
        const newMomentDate = moment(`${date}/${month}/${v}`, "DD/MM/YYYY");
        const newValue = newMomentDate.isValid()
          ? newMomentDate.format(dateFormat)
          : "";
        return onChange(newValue);
      }
      default:
        break;
    }
  };

  const onChangeDate = onSelectChange("date");
  const onChangeMonth = onSelectChange("month");
  const onChangeYear = onSelectChange("year");

  return (
    <Row justify="space-between" type="flex" gutter={[32, 0]}>
      <Col xs={8}>
        <Select
          defaultValue={defaultDate}
          value={date}
          style={{ width: "100%" }}
          onChange={onChangeDate}
        >
          {dateOptions.map((val) => (
            <Select.Option value={val} key={`date-option-${val}`}>
              {val}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col xs={8}>
        <Select
          defaultValue={defaultMonth}
          value={month}
          style={{ width: "100%" }}
          onChange={onChangeMonth}
        >
          {monthOptions.map((val) => (
            <Select.Option value={val} key={`date-option-${val}`}>
              {moment.months()[val - 1]}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col xs={8}>
        <Select
          defaultValue={defaultYear}
          value={year}
          style={{ width: "100%" }}
          onChange={onChangeYear}
        >
          {yearOptions.map((val) => (
            <Select.Option value={val} key={`date-option-${val}`}>
              {val + 543}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}
