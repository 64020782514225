import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";

Modal.setAppElement("#root"); // replace '#root' with the id of your app's root element

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 4px;
  width: 300px;
  padding: 20px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: 600;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
  color: black;
`;

const Button = styled.button`
  display: block;
  width: 50%;
  padding: 10px;
  margin-top: 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }
`;

function LoginModal({ onAuthenticate }) {
  const [modalIsOpen] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (
      username === process.env.REACT_APP_BASIC_AUTHEN_USERNAME &&
      password === process.env.REACT_APP_BASIC_AUTHEN_PASSWORD
    ) {
      console.log("Access granted");
      onAuthenticate();
    } else {
      console.log("Access denied");
    }
  };

  return (
    <StyledModal isOpen={modalIsOpen}>
      <Title>Login</Title>
      <Input
        type="text"
        placeholder="Username"
        onChange={(e) => setUsername(e.target.value)}
      />
      <Input
        type="password"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button onClick={handleSubmit}>Submit</Button>
    </StyledModal>
  );
}

export default LoginModal;
