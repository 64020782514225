import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { Checkbox, Col, Divider, Form, Row } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { get } from "lodash";
import Input from "app/components/input";
import Text from "app/components/text";
import FormRadioGroup from "app/components/form-radio-group";
import FormCascadeLocationInput from "../../../components/form-cascade-location-input";
import FormItem from "app/components/form-item";

const TermAndConditionCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    > .ant-checkbox + span {
      padding-left: 32px;
    }
  }
`;

export function Form3ContactInfoComponent() {
  const { handleChange, values, errors, setFieldValue } = useFormikContext();
  const { t } = useTranslation(["common", "register"]);

  const [tambon, setTambon] = useState(
    get(values, "addresses.other.tambon", "")
  );
  const [district, setDistrict] = useState(
    get(values, "addresses.other.district", "")
  );
  const [province, setProvince] = useState(
    get(values, "addresses.other.province", "")
  );
  const [zipcode, setZipcode] = useState(
    get(values, "addresses.other.postcode", "")
  );

  const contactPersonInput = {
    label: t("register:contact person"),
    id: "contactPerson",
    tag: FormRadioGroup,
    formErrors: errors,
    formValues: values,
    onChange: (...props) => {
      setFieldValue(
        "contact.agency",
        {
          profilePic: "",
          title: "",
          name: "",
          nickname: "",
          dateOfBirth: "",
          nationalID: "",
          nationalIDExpirationDate: "",
          governmentOfficialID: "",
          governmentOfficialIDExpirationDate: "",
          mobilePhone: "",
          email: "",
          lineID: "",
        },
        true
      );
      handleChange(...props);
    },
    tagProps: {
      options: [
        {
          value: "personal",
          label: t("register:directly candidate"),
        },
        {
          value: "agency",
          label: t("register:through the coordinator"),
        },
      ],
    },
  };

  const agencyNameInput = {
    label: t("register:full name"),
    id: "contact.agency.name",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
    tagProps: {
      disabled: values["contactPerson"] !== "agency",
    },
  };

  const agencyPhoneInput = {
    label: t("register:phone"),
    id: "contact.agency.mobilePhone",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
    tagProps: {
      disabled: values["contactPerson"] !== "agency",
    },
  };

  const agencyFaxInput = {
    label: t("register:fax phone"),
    id: "contact.agency.fax",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
    tagProps: {
      disabled: values["contactPerson"] !== "agency",
    },
  };

  const agencyEmailInput = {
    label: t("register:email"),
    id: "contact.agency.email",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
    tagProps: {
      disabled: values["contactPerson"] !== "agency",
    },
  };

  const agencyLineIdInput = {
    label: t("register:LINE ID"),
    id: "contact.agency.lineID",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
    tagProps: {
      disabled: values["contactPerson"] !== "agency",
    },
  };

  const contactAddressInput = {
    label: t("register:contact person"),
    id: "contactAddress",
    tag: FormRadioGroup,
    formErrors: errors,
    formValues: values,
    onChange: (...props) => {
      setFieldValue(
        "addresses.other",
        {
          street: "",
          tambon: "",
          district: "",
          province: "",
          postcode: "",
          phone: "",
          phoneExtension: "",
          fax: "",
        },
        true
      );
      handleChange(...props);
    },
    tagProps: {
      options: [
        {
          value: "present",
          label: t("register:present address"),
        },
        {
          value: "company",
          label: t("register:company address"),
        },
        {
          value: "other",
          label: t("register:other address"),
        },
      ],
    },
  };

  const addressInput = {
    label: t("register:shipping address"),
    id: "addresses.other.street",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const provinceAndDistrictInput = {
    id: "addresses.other.province",
    id2: "addresses.other.district",
    tag: FormCascadeLocationInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) => {},
    tagProps: {
      firstLocationType: "province",
      secondLocationType: "district",
      setProvince: setProvince,
      setDistrict: setDistrict,
      setTambon: setTambon,
      setZipcode: setZipcode,
      province: province,
      district: district,
      onProvinceChange: (v) => {
        // console.log("province=", v);
        setFieldValue("addresses.other.province", v);
      },
      onDistrictChange: (v) => {
        // console.log("district=", v);
        setFieldValue("addresses.other.district", v);
      },
    },
  };
  const tambonAndZipcodeInput = {
    id: "addresses.other.tambon",
    id2: "addresses.other.postcode",
    tag: FormCascadeLocationInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) => {},
    tagProps: {
      firstLocationType: "tambon",
      secondLocationType: "zipcode",
      setDistrict: setDistrict,
      setTambon: setTambon,
      setZipcode: setZipcode,
      district: district,
      tambon: tambon,
      zipcode: zipcode,
      onTambonChange: (v) => {
        // console.log("tambon=", v);
        setFieldValue("addresses.other.tambon", v);
      },
      onZipcodeChange: (v) => {
        // console.log("zipcode=", v);
        setFieldValue("addresses.other.postcode", v);
      },
    },
  };

  const rowProps = { gutter: [32, 0] };
  const leftColProps = { md: 12, lg: 11, xs: 24 };
  const rightColProps = { md: 12, lg: { offset: 2, span: 11 }, xs: 24 };
  // const formItemLayout = {
  //   labelCol: {span: 24},
  //   wrapperCol: {span: 24}
  // };
  const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  useEffect(() => {
    setFieldValue("agreements", {});
  }, [setFieldValue]);
  return (
    <>
      <Text level={4} style={{ display: "block", marginBottom: "2rem" }}>
        {t("register:contact")}
      </Text>

      <Row {...rowProps}>
        <Col span={24}>
          <FormItem {...contactPersonInput} />
        </Col>
      </Row>

      {values.contactPerson === "agency" && (
        <>
          <Row {...rowProps}>
            <Col {...leftColProps}>
              <FormItem {...agencyNameInput} />
            </Col>
            <Col {...rightColProps}>
              <FormItem {...agencyPhoneInput} />
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...leftColProps}>
              <FormItem {...agencyFaxInput} />
            </Col>
            <Col {...rightColProps}>
              <FormItem {...agencyEmailInput} />
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...leftColProps}>
              <FormItem {...agencyLineIdInput} />
            </Col>
          </Row>
        </>
      )}

      <Text level={4} style={{ display: "block", marginBottom: "2rem" }}>
        {t("register:shipping address")}
      </Text>

      <Row {...rowProps}>
        <Col span={24}>
          <FormItem {...contactAddressInput} />
        </Col>
      </Row>

      {values.contactAddress === "other" && (
        <>
          <Row {...rowProps}>
            <Col {...leftColProps}>
              <FormItem {...addressInput} />
            </Col>
          </Row>

          <Row {...rowProps}>
            <Col {...leftColProps}>
              <FormItem {...provinceAndDistrictInput} />
            </Col>
            <Col {...rightColProps}>
              <FormItem {...tambonAndZipcodeInput} />
            </Col>
          </Row>
        </>
      )}

      <Divider />

      <Form.Item {...tailFormItemLayout}>
        <Row>
          <Col md={1} xs={2} style={{ marginTop: "-0.65rem" }}>
            <TermAndConditionCheckbox
              onChange={({ target: { checked } }) =>
                setFieldValue("agreements.term1", !!checked || null)
              }
              defaultChecked={false}
            />
          </Col>
          <Col lg={22} md={22} xs={20} style={{ lineHeight: "1.2rem" }}>
            {t("register:term and con 1")}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Row>
          <Col md={1} xs={2} style={{ marginTop: "-0.65rem" }}>
            <TermAndConditionCheckbox
              onChange={({ target: { checked } }) =>
                setFieldValue("agreements.term2", !!checked || null)
              }
              defaultChecked={false}
            />
          </Col>
          <Col lg={22} md={22} xs={20} style={{ lineHeight: "1.2rem" }}>
            {t("register:term and con 2")}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Row>
          <Col md={1} xs={2} style={{ marginTop: "-0.65rem" }}>
            <TermAndConditionCheckbox
              onChange={({ target: { checked } }) =>
                setFieldValue("agreements.term3", !!checked || null)
              }
              defaultChecked={false}
            />
          </Col>
          <Col lg={22} md={22} xs={20} style={{ lineHeight: "1.2rem" }}>
            {t("register:term and con 3")}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Row>
          <Col md={1} xs={2} style={{ marginTop: "-0.65rem" }}>
            <TermAndConditionCheckbox
              onChange={({ target: { checked } }) =>
                setFieldValue("agreements.term4", !!checked || null)
              }
              defaultChecked={false}
            />
          </Col>
          <Col lg={22} md={22} xs={20} style={{ lineHeight: "1.2rem" }}>
            <Trans i18nKey="register:term and con 4">
              ข้าพเจ้าได้อ่านและยอมรับ
              <a href="https://thai.tourismthailand.org/Information/นโยบายความเป็นส่วนตัว">
                นโยบายความเป็นส่วนตัว
              </a>{" "}
              นี้แล้ว ข้าพเจ้ายินยอมให้ ททท. เก็บรวบรวม
              ใช้และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์สำหรับการเข้ารับการอบรมหลักสูตรการบริหารการท่องเที่ยวสำหรับผู้บริหารระดับสูง
              รุ่นที่ 5 ประจำปี 2567 (Tourism Management Program for Executives:
              TME5)
              และเพื่อวัตถุประสงค์อื่นใดที่เกี่ยวเนื่องกันตามที่ปรากฏในนโยบายความเป็นส่วนตัวเท่านั้น
            </Trans>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Row>
          <Col md={1} xs={2} style={{ marginTop: "-0.65rem" }}>
            <TermAndConditionCheckbox
              onChange={({ target: { checked } }) =>
                setFieldValue("agreements.term5", !!checked || null)
              }
              defaultChecked={false}
            />
          </Col>
          <Col lg={22} md={22} xs={20} style={{ lineHeight: "1.2rem" }}>
            <Trans i18nKey="register:term and con 5">
              ข้าพเจ้าเข้าใจดีว่า
              ข้าพเจ้าสามารถยกเลิกความยินยอมสำหรับกิจกรรมข้างต้นเสียเมื่อใดก็ได้
              โดยส่งข้อความไปยัง ศูนย์พัฒนาวิชาการด้านตลาดการท่องเที่ยว (TAT
              Academy) email :
              <a href="mailto://sec.tatacademy@gmail.com">
                sec.tatacademy@gmail.com
              </a>
              เบอร์ติดต่อ 02 250 5500 ต่อ 4920-4
            </Trans>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Row>
          <Col md={1} xs={2} style={{ marginTop: "-0.65rem" }}>
            <TermAndConditionCheckbox
              onChange={({ target: { checked } }) =>
                setFieldValue("agreements.term6", !!checked || null)
              }
              defaultChecked={false}
            />
          </Col>
          <Col lg={22} md={22} xs={20} style={{ lineHeight: "1.2rem" }}>
            {t("register:term and con 6")}
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}
