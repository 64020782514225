import * as React from 'react';
// import { Button, Col, Layout, Row, Typography } from 'antd';
import { Layout, Row } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import Container from 'app/components/container';
import Text from 'app/components/text';
import { media } from 'app/styles/media-query';

// import footerImage from './assets/images/footer-site.jpg';
// import ccImage from './assets/images/cc.png';

import logo from './assets/images/LogoTATAC-White.svg';

const Footer = styled(Layout.Footer)`
  &.ant-layout-footer {
    /* background: ${(props) => props.theme['$black']}; */
    background-color: #003259;
    padding: 0 24px 24px;
  }
`;

const CopyRightWrapper = styled.div`
  font-family: 'TATSana SukSa';
  color: ${(props) => props.theme['$white']};
  text-align: center;
  padding: 2rem 0rem;
  opacity: 0.75;
`;

const Logo = styled.img`
  width: auto;
  height: 3.5rem;
  margin: 2rem 0;
  float: left;

  ${media.down('md')} {
    height: 3rem;
  }
`;

const ContactInformation = styled(Row)`
  flex-direction: column;
  & .ant-typography {
    font-family: 'TATSana Suksa';
    color: ${(props) => props.theme['$white']};
    text-align: center;
    margin-bottom: 0.5rem;
    opacity: 0.75;
  }
`;

// const SiteMapContainer = styled(Container)`
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   /* background: #141414 url(${footerImage}); */
// `;

// const NavParagraph = styled(Typography.Paragraph)`
//   &.ant-typography {
//     > ul {
//       > li {
//         list-style-type: none;
//         font-size: 0.75rem;
//         padding: 0.5rem 0 0.5rem 0.5rem;
//         color: ${(props) => props.theme['$gray']};

//         > a {
//           color: ${(props) => props.theme['$gray']};
//           text-decoration: none;
//           &:hover {
//             color: ${(props) => props.theme['$white']};
//           }
//         }
//       }
//     }
//   }
// `;

// const SocialButton = styled(Button)`
//   &.ant-btn-icon-only.ant-btn-lg {
//     font-size: 1.5rem;
//   }
// `;

// const NewsLetterButton = styled(Button)`
//   &.ant-btn {
//     background-color: ${(props) => props.theme['$gray-darker']};
//     border-color: ${(props) => props.theme['$gray-darker']};

//     &:hover {
//       background-color: ${(props) => props.theme['$gray-darker']};
//       border-color: ${(props) => props.theme['$gray-darker']};
//       color: ${(props) => props.theme['$gray-dark']};
//     }
//   }
// `;

// const CCICon = styled.img`
//   padding: 0 2rem;
//   width: 100%;
//   height: auto;
// `;

// const SiteMapCol = styled(Col)`
//   &.ant-col {
//     margin-bottom: 1rem;

//     ${media.up('sm')} {
//       &:not(:last-child) {
//         border-right: 1px solid ${(props) => props.theme['$gray']};
//       }
//     }

//     ${media.down('xs')} {
//       &:not(:last-child) {
//         border-bottom: 1px solid ${(props) => props.theme['$gray']};
//       }
//     }
//   }
// `;

export function TATFooterComponent() {
  const { t } = useTranslation(['common']);

  return (
    <Footer>
      <Row type='flex' justify='center'>
        <a href='https://tatacademy.com/th'>
          <Logo className='logo' src={logo} />
        </a>
      </Row>
      <ContactInformation type='flex' justify='center'>
        <Text>{t('common:tatac')}</Text>
        <Text>{t('common:tat')}</Text>
        <Text>{t('common:tat address')}</Text>
        <Text>
          <PhoneOutlined
            style={{
              display: 'inline-block',
              transform: 'rotate(90deg)',
              marginRight: '4px',
            }}
          />
          {t('common:tat phone')}
        </Text>
      </ContactInformation>
      {/* <SiteMapContainer>
        <Row gutter={[16, 0]} type='flex'> */}
      {/* <SiteMapCol sm={6} xs={24}>
            <NavParagraph>
              <ul>
                <li><a href='http://tatacademy.com/th/group/1'>{t('common:whats on')}</a></li>
                <li><a href='http://tatacademy.com/th/group/5'>{t('common:training')}</a></li>
                <li><a href='http://tatacademy.com/th/tme.php'>{t('common:tme')}</a></li>
                <li><a href='http://tatacademy.com/th/group/2'>{t('common:article')}</a></li>
                <li><a href='http://library.tourismthailand.org/'>{t('common:tat lib')}</a></li>
                <li><a
                  href='http://www.tatreviewmagazine.com/'>{t('common:tat review magazine')}</a>
                </li>
              </ul>
            </NavParagraph>
          </SiteMapCol> */}
      {/* <SiteMapCol sm={6} xs={24}>
            <NavParagraph>
              <ul>
                <li>{t('common:tat address')}</li>
                <li>{t('common:tat phone')}</li>
                <li>{t('common:tat phone2')}</li>
                <li>{t('common:tat email')}</li>
              </ul>
            </NavParagraph>
          </SiteMapCol>
          <SiteMapCol sm={6} xs={24}>
            <Row
              type='flex'
              justify='center'
              gutter={[8, 0]}
              style={{ marginBottom: '1rem' }}
            >
              <Col>
                <SocialButton
                  href='https://www.facebook.com/TATacademy/'
                  shape='circle'
                  icon='facebook'
                  size='large'
                />
              </Col>
              <Col>
                <SocialButton
                  href='https://www.youtube.com/channel/UC-XxyhVVK5Wqm6WZ0ru48cA'
                  shape='circle'
                  icon='youtube'
                  size='large'
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <NewsLetterButton block>
                  {t('common:news letter')}
                </NewsLetterButton>
              </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
              <Text>
                {t(
                  'common:join us today and you will receive lots of useful updates delivered directly on your e-mail'
                )}
              </Text>
            </Row>
          </SiteMapCol>
          <SiteMapCol sm={6} xs={24}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <CCICon src={ccImage} alt='cc' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>
                  {t(
                    'common:licensed under a Creative Commons Attribution-NonCommercial-Share Alike 3.0 license'
                  )}
                </Text>
              </Col>
            </Row>
          </SiteMapCol> */}
      {/* </Row>
      </SiteMapContainer> */}
      <CopyRightWrapper>{t('common:copyright')}</CopyRightWrapper>
    </Footer>
  );
}
