import { Button, Col, Result, Row } from "antd";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import useRouter from "use-react-router";
import styled from "styled-components";
import { useFirebase } from "react-redux-firebase";
import GlobalContainer from "../../../components/container";
import Text from "../../../components/text";

const Container = styled(GlobalContainer)`
  background-color: ${(props) => props.theme["$white"]};
`;

const ParagraphCol = styled(Col)`
  &.ant-col {
    margin-bottom: 1rem;
  }
`;

const BtnCol = styled(Col)`
  &.ant-col {
    margin-bottom: 2rem;
  }
`;
export function SuccessfullyRegistrationContainer() {
  const { t } = useTranslation(["common", "register", "auth"]);
  const {
    match: {
      params: { email },
    },
  } = useRouter();
  const firebase = useFirebase();
  const onClickBackToHome = async () => {
    await firebase.logout();
    window.location.href = "http://tatacademy.com";
  };

  return (
    <Container>
      <Result status="success" title={t("register:send application form")} />
      <Row type="flex" justify="center">
        <ParagraphCol xs={24} sm={20} md={16}>
          <Text level={6} color="dark">
            <Trans i18nKey="register:success paragraph 1" email={email}>
              ระบบได้ทำการบันทึกใบสมัครของท่านเรียบร้อยแล้ว
              และจะประกาศผลพิจารณาให้ท่านทราบผ่านทางเว็บไซต์
              <a href="https://tatacademy.com">tatacademy.com</a>
              โดยท่านที่ผ่านการพิจารณาคัดเลือกจะได้รับผลการพิจารณาและรายละเอียดการเข้าร่วมอบรมและการชำระค่าลงทะเบียนผ่านทางอีเมล
              <a href={{ email }}>{{ email }}</a> ตามที่แจ้งไว้ด้วย
            </Trans>
          </Text>
        </ParagraphCol>
      </Row>
      <Row type="flex" justify="center">
        <ParagraphCol xs={24} sm={20} md={16}>
          <Text level={6} color="dark">
            <Trans i18nKey="register:success paragraph 2" email={email}>
              หากมีข้อสงสัยเพิ่มเติม กรุณาติดต่อ
              ศูนย์พัฒนาวิชาการด้านตลาดการท่องเที่ยว การท่องเที่ยวแห่งประเทศไทย
              โทร 02 250 5500 ต่อ 4920-24 หรืออีเมล
              <a href="mailto://sec.tatacademy@gmail.com">
                sec.tatacademy@gmail.com
              </a>
            </Trans>
          </Text>
        </ParagraphCol>
      </Row>

      <Row type="flex" justify="center">
        <BtnCol>
          <Button htmlType="button" onClick={onClickBackToHome} type="primary">
            {t("register:back to home")}
          </Button>
        </BtnCol>
      </Row>
    </Container>
  );
}
