import React from 'react';
// import {Col, message, Row, Spin, Upload as AntUpload} from 'antd';
// import Icon from '@ant-design/icons';
import {Col, Icon, message, Row, Spin, Upload as AntUpload} from 'antd';

import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import Text from 'app/components/text';

export const Upload = styled(AntUpload)`
  &.avatar-uploader.ant-upload-picture-card-wrapper{
    >.ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: 100%;
      height: auto;
      margin-bottom: 0; 
    }
  }
`;

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg'
                     || file.type === 'image/png'
                     || file.type === 'application/pdf';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/PDF file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export function FormFileUploadInputComponent({onChange, loading, value, label, ...restProps}) {
  const {t} = useTranslation(['register']);

  const UploadButton = (
    <Row justify='space-between' type='flex'>
      <Col>
        <Text className='ant-upload-text'>{t('register:add file')}</Text>
      </Col>
      <Col/>
      <Col>
        <Icon type={loading ? 'loading' : 'file-add'}/>
      </Col>
    </Row>
  );

  const FileIcon = (
    <Row justify='space-between' type='flex'>
      <Col>
        <Text className='ant-upload-text'>{!!label ? label : value}</Text>
      </Col>
      <Col/>
      <Col>
        <Icon type='file'/>
      </Col>
    </Row>
  );

  // const handleChange = async info => {
  //   if (info.file.status === 'uploading') {
  //     setLoading(true);
  //     setMeta({contentType: info.file.type});
  //     setFileName(info.file.name);
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     setLoading(false);
  //     onChange({fileUrl, fileName});
  //   }
  // };

  // const customRequest = async ({onSuccess, onError, file}) => {
  //   const hashFileName = `${uuidv4()}`;
  //   const storagePath = `${auth.uid}/attached`;
  //   const dbPath = `files/${storagePath}`;
  //   try {
  //     const uploadTaskSnapshot = await firebase
  //     .uploadFile(storagePath, file, dbPath, {metadata: meta, name: hashFileName});
  //     setFileUrl(`${auth.uid}/attached/${hashFileName}`);
  //     onSuccess(null, uploadTaskSnapshot);
  //   } catch (e) {
  //     onError(e);
  //   }
  // };

  return (
    <Spin spinning={loading}>
      <Upload
        {...restProps}
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={onChange}
      >
        {!!value ? FileIcon : UploadButton}
      </Upload>
    </Spin>
  );
}

