import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import moment from "moment";
import { get } from "lodash";
import { useFormikContext } from "formik";

import Input from "../../../components/input";
import Text from "../../../components/text";
import FormRadioGroup from "app/components/form-radio-group";
import FormCascadeDateInput from "../../../components/form-cascade-date-input";
import FormCascadeLocationInput from "../../../components/form-cascade-location-input";
import FormAvatarUpload from "../../../components/form-avatar-upload-input";
import FormItem from "../../../components/form-item";
import { useRegistrationFileStorage } from "../../../hooks/use-registration-file-storage";

export function Form1PersonalInfoComponent() {
  const { handleChange, values, errors, setFieldValue } = useFormikContext();

  const { uploadAvatarFileRequest, getFileDownloadURL } =
    useRegistrationFileStorage();
  const [avatarLoaded, setAvatarLoaded] = useState(true);
  const [avatarDownloadURL, setAvatarDownloadURL] = useState(null);
  const { t } = useTranslation(["common", "register"]);
  const profilePicFullPath = get(values, "contact.personal.profilePic", null);

  const [tambon, setTambon] = useState(
    get(values, "addresses.present.tambon", "")
  );
  const [district, setDistrict] = useState(
    get(values, "addresses.present.district", "")
  );
  const [province, setProvince] = useState(
    get(values, "addresses.present.province", "")
  );
  const [zipcode, setZipcode] = useState(
    get(values, "addresses.present.postcode", "")
  );

  const profilePictureInput = {
    label: (
      <Text>
        {t("register:candidate picture")}{" "}
        <Text color="gray">
          ({t("register:candidate picture description")})
        </Text>
      </Text>
    ),
    id: "contact.personal.profilePic",
    tag: FormAvatarUpload,
    formErrors: errors,
    formValues: { contact: { personal: { profilePic: avatarDownloadURL } } },
    // onChange: url => {setFieldValue('contact.personal.profilePic', url);},
    onChange: async (info) => {
      if (info.file.status === "uploading") {
        setAvatarLoaded(false);
      } else if (info.file.status === "done") {
        setAvatarLoaded(true);
        // console.log(info.file);
        const fullPath = get(info, "file.xhr.File.fullPath", null);
        const downloadURL = get(info, "file.xhr.downloadURL", null);
        if (!!fullPath) {
          setFieldValue("contact.personal.profilePic", fullPath);
        }
        if (!!downloadURL) {
          setAvatarDownloadURL(downloadURL);
        }
      }
    },
    tagProps: {
      loading: !avatarLoaded,
      customRequest: async ({ onSuccess, onError, file }) => {
        try {
          const uploadTaskSnapshot = await uploadAvatarFileRequest(file);
          onSuccess(null, uploadTaskSnapshot);
        } catch (e) {
          onError(e);
        }
      },
    },
  };
  const titleInput = {
    label: t("register:title name"),
    id: "contact.personal.title",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const nameInput = {
    label: t("register:full name"),
    id: "contact.personal.name",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const nickNameInput = {
    label: t("register:nickname"),
    id: "contact.personal.nickname",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const dobInput = {
    label: t("register:date of birth"),
    id: "contact.personal.dateOfBirth",
    tag: FormCascadeDateInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) => setFieldValue("contact.personal.dateOfBirth", v),
  };
  const nationalIDInput = {
    label: t("register:national id"),
    id: "contact.personal.nationalID",
    tag: Input,
    tagProps: {},
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const nationalIDExpirationDateInput = {
    label: (
      <Text>
        {t("register:expiration date")}{" "}
        <Text color="gray">({t("register:national card")})</Text>
      </Text>
    ),
    id: "contact.personal.nationalIDExpirationDate",
    tag: FormCascadeDateInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) =>
      setFieldValue("contact.personal.nationalIDExpirationDate", v),
    tagProps: {
      yearOptions: new Array(50)
        .fill(0)
        .map((val, idx) => moment().get("year") + idx),
    },
  };
  const nationalIDCardTypeInput = {
    label: t("register:national id card type"),
    id: "contact.personal.nationalIDCardType",
    tag: FormRadioGroup,
    formErrors: errors,
    formValues: values,
    onChange: (e) => {
      setFieldValue("contact.personal.nationalIDCardType", e.target.value);
      handleChange(e);
    },
    tagProps: {
      options: [
        {
          value: "temporary", // ชั่วคราว
          label: t("register:temporary"),
        },
        {
          value: "permanent", // ตลอดชีพ
          label: t("register:permanent"),
        },
      ],
    },
  };
  const governmentOfficialIDInput = {
    label: (
      <Text>
        {t("register:government official id")}{" "}
        <Text color="gray">({t("register:optional")})</Text>
      </Text>
    ),
    id: "contact.personal.governmentOfficialID",
    tag: Input,
    tagProps: {},
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const governmentOfficialIDExpirationDateInput = {
    label: (
      <Text>
        {t("register:expiration date")}{" "}
        <Text color="gray">({t("register:government official card")})</Text>
      </Text>
    ),
    id: "contact.personal.governmentOfficialIDExpirationDate",
    tag: FormCascadeDateInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) =>
      setFieldValue("contact.personal.governmentOfficialIDExpirationDate", v),
    tagProps: {
      yearOptions: new Array(50)
        .fill(0)
        .map((val, idx) => moment().get("year") + idx),
    },
  };
  const provinceAndDistrictInput = {
    // label: <></>,
    id: "addresses.present.province",
    id2: "addresses.present.district",
    tag: FormCascadeLocationInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) => {},
    tagProps: {
      firstLocationType: "province",
      secondLocationType: "district",
      setProvince: setProvince,
      setDistrict: setDistrict,
      setTambon: setTambon,
      setZipcode: setZipcode,
      province: province,
      district: district,
      onProvinceChange: (v) => {
        // console.log("province=", v);
        setFieldValue("addresses.present.province", v);
      },
      onDistrictChange: (v) => {
        // console.log("district=", v);
        setFieldValue("addresses.present.district", v);
      },
    },
  };
  const tambonAndZipcodeInput = {
    // label: <></>,
    id: "addresses.present.tambon",
    id2: "addresses.present.postcode",
    tag: FormCascadeLocationInput,
    formErrors: errors,
    formValues: values,
    onChange: (v) => {
      // setTambon(v);
      // console.log("v=", v);
      // setFieldValue("contact.personal.tambon", v);
    },
    tagProps: {
      firstLocationType: "tambon",
      secondLocationType: "zipcode",
      setDistrict: setDistrict,
      setTambon: setTambon,
      setZipcode: setZipcode,
      district: district,
      tambon: tambon,
      zipcode: zipcode,
      onTambonChange: (v) => {
        // console.log("tambon=", v);
        setFieldValue("addresses.present.tambon", v);
      },
      onZipcodeChange: (v) => {
        // console.log("zipcode=", v);
        setFieldValue("addresses.present.postcode", v);
      },
    },
  };

  const addressInput = {
    label: t("register:present address"),
    id: "addresses.present.street",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const phoneInput = {
    label: t("register:home phone"),
    id: "addresses.present.phone",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const faxPhoneInput = {
    label: (
      <Text>
        {t("register:fax phone")}{" "}
        <Text color="gray">({t("register:optional")})</Text>
      </Text>
    ),
    id: "addresses.present.fax",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const mobilePhoneInput = {
    label: t("register:mobile phone"),
    id: "contact.personal.mobilePhone",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const emailInput = {
    label: t("register:email"),
    id: "contact.personal.email",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };
  const lineIDInput = {
    label: (
      <Text>
        {t("register:Line ID")}{" "}
        <Text color="gray">({t("register:optional")})</Text>
      </Text>
    ),
    id: "contact.personal.lineID",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  };

  const rowProps = { gutter: [32, 0] };
  const leftColProps = { md: 12, lg: 11, xs: 24 };
  const rightColProps = { md: 12, lg: { offset: 2, span: 11 }, xs: 24 };

  const getAvatarDownloadURL = useCallback(async () => {
    try {
      if (!!profilePicFullPath) {
        setAvatarLoaded(false);
        const downloadURL = await getFileDownloadURL(profilePicFullPath);
        setAvatarDownloadURL(downloadURL);
      }
    } catch (e) {
      // todo error
      console.log("getAvatarDownloadURL", e);
    } finally {
      setAvatarLoaded(true);
    }
  }, [
    setAvatarDownloadURL,
    setAvatarLoaded,
    profilePicFullPath,
    getFileDownloadURL,
  ]);
  // }, [getFileDownloadURL, setAvatarDownloadURL, setAvatarLoaded , profilePicFullPath]);

  useEffect(() => {
    getAvatarDownloadURL();
  }, [getAvatarDownloadURL]);
  return (
    <>
      <Text level={4} style={{ display: "block", marginBottom: "2rem" }}>
        {t("register:personal information")}
      </Text>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...profilePictureInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...titleInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...nameInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...nickNameInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...dobInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...nationalIDInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...nationalIDCardTypeInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...nationalIDExpirationDateInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...governmentOfficialIDInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...governmentOfficialIDExpirationDateInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...addressInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...phoneInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...provinceAndDistrictInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...tambonAndZipcodeInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...faxPhoneInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...mobilePhoneInput} />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...leftColProps}>
          <FormItem {...emailInput} />
        </Col>
        <Col {...rightColProps}>
          <FormItem {...lineIDInput} />
        </Col>
      </Row>
    </>
  );
}
