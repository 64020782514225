import { get } from "lodash";
import React from "react";

import Input from "app/components/input";
import Form from "app/components/form";
import styled from "styled-components";
import { Button, Col, Row } from "antd";

const Item = styled(Form.Item)`
  &.ant-form-item {
    > .ant-form-item-control-wrapper {
      > .ant-form-item-control {
        &.has-error {
          > .ant-form-item-children {
            > .ant-mentions {
              > textarea {
                border-color: ${(props) => props.theme["$red"]};
              }
            }
          }
        }
      }
    }
  }
`;

function FormItem({
  label,
  id,
  id2,
  tag: Tag = Input,
  tagProps,
  formErrors,
  formValues,
  onChange,
  onAction,
  actionLabel,
  actionType = 'default'
}) {
  const defaultValue = get(formValues, id, "");
  const errorId = id2 || id;
  return (
    <Item
      label={label}
      validateStatus={!!get(formErrors, errorId, "") ? "error" : "success"}
      help={get(formErrors, errorId, "")}
    >
      <Row gutter={[8, 0]} type="flex" justify="space-between">
        <Col style={{ flex: "1 1 0%" }}>
          <Tag
            name={id}
            id={id}
            onChange={onChange}
            value={defaultValue}
            defaultValue={defaultValue}
            {...tagProps}
          />
        </Col>
        {!!actionLabel && (
          <Col>
            <Button htmlType="button" type={actionType} onClick={onAction}>{actionLabel}</Button>
          </Col>
        )}
      </Row>
    </Item>
  );
}

export default FormItem;
