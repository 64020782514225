import React, { useState } from "react";
import { Checkbox, Col, Row } from "antd";
import { compact, filter, uniq } from "lodash";
import { useTranslation } from "react-i18next";
import Input from "app/components/input";

import { get } from "lodash";

export const OTHER_VALUE = "$other";

export function FormCheckboxGroupComponent({
  options = [],
  onChange,
  onMoreInputChange: onFormMoreInputChange,
  defaultValue = [],
  defaultMoreInputValue,
  moreInputFormErrors,
  moreInputId,
}) {
  const [values, setValues] = useState(defaultValue);
  const [moreInputValue, setMoreInputValue] = useState(defaultMoreInputValue);
  const { t } = useTranslation(["register"]);
  const onCheck =
    (value) =>
    ({ target: { checked } }) => {
      const newValues = checked
        ? uniq([...values, value])
        : filter(values, (v) => v !== value);
      setValues(compact([...newValues]));

      if (value === OTHER_VALUE && !checked) {
        setMoreInputValue("");
        onFormMoreInputChange("");
      }
      onChange(compact([...newValues]));
    };

  const onMoreInputChange = ({ target: { value } }) => {
    setMoreInputValue(value);
    onFormMoreInputChange(value);
  };

  return (
    <Row>
      {options.map(({ value, label }) => (
        <Col key={value} xs={12} sm={8} md={6} lg={6}>
          <Checkbox
            onChange={onCheck(value)}
            defaultChecked={values.includes(value)}
          >
            {label}
          </Checkbox>
        </Col>
      ))}
      <Col xs={24} sm={24} md={12} lg={12}>
        <Row type="flex">
          <Col>
            <Checkbox
              onChange={onCheck(OTHER_VALUE)}
              defaultChecked={values.includes(OTHER_VALUE)}
            >
              {t("register:other business")}
            </Checkbox>
          </Col>
          <Col>
            <Input
              validatestatus={
                !!get(moreInputFormErrors, moreInputId, "")
                  ? "error"
                  : "success"
              }
              help={get(moreInputFormErrors, moreInputId, "")}
              value={moreInputValue}
              onChange={onMoreInputChange}
              disabled={!values.includes(OTHER_VALUE)}
            />{" "}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
