import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Formik, isFunction} from 'formik';
import {Button, Col, Form, Row, Steps as AntSteps} from 'antd';
import styled from 'styled-components';
import {media} from 'app/styles/media-query';

import {Debug} from './debug';

const Steps = styled(AntSteps)`
  &.ant-steps{
    margin-bottom: 2rem;
  }
`;

const Step = styled(Steps.Step)`
  &.ant-steps-item{
    > .ant-steps-item-container {
      > .ant-steps-item-content {
        > .ant-steps-item-title {
          color: ${props => props.theme['$blue']}
        }
      }
    }
    &.ant-steps-item-wait {
      > .ant-steps-item-container {
        > .ant-steps-item-content {
          > .ant-steps-item-title {
            color: rgba(0, 0, 0, 0.45)         
          }
        }
      }
    }
    > .ant-steps-item-container{
      > .ant-steps-item-content{        
        >.ant-steps-item-title{
          font-size: 0.75rem;
        }
      }
      ${media.down('xs')} {
        > .ant-steps-item-tail{
          ${media.down('xs')} {
            margin: 0 0 0 30px;
          }
        }
        
        > .ant-steps-item-icon{
          margin: 0 0 0 27px;
        }
        
        > .ant-steps-item-content{
          width: 60px;
          
          >.ant-steps-item-title{
            font-size: 0.5rem;
          }
        }
      }
   }
  }
`;

export function Wizard({children, initialPage, initialValues, onSubmit, onSave, onCancel, onNext, onPrevious}) {

  const [page, setPage] = useState(initialPage);
  // const [values, setValues] = useState({});
  const {t} = useTranslation(['common']);

  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;
  const titles = React.Children.toArray(children).map((child) => child.props.title);

  const save = async (v) => {
    if (isFunction(onSave)) {
      await onSave(v, page);
    }
  };

  const next = async (v) => {
    const toPage = Math.min(page + 1, children.length - 1);
    if (isFunction(onNext)) {
      await onNext(v, toPage);
    }
    setPage(toPage);
  };

  const previous = async (v) => {
    const toPage = Math.max(page - 1, 0);
    if (isFunction(onPrevious)) {
      await onPrevious(v, toPage);
    }
    setPage(toPage);
  };

  const validate = v => {
    console.log('validate');
    return !!isFunction(activePage.props.validate) ? activePage.props.validate(v) : {};
  };

  const validationSchema = activePage.props.validationSchema || null;

  const handleSubmit = async (v, bag) => {
    if (isLastPage) {
      return onSubmit(v, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      return next(v);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values: v, handleSubmit: formikHandleSubmit, isSubmitting, isValid}) => (
        <Form labelCol={{span: 24}} wrapperCol={{span: 24}} onSubmit={formikHandleSubmit}>

          {!!titles[page] && (
            <Steps progressDot current={page}>
              {titles.map((title) => (<Step key={`page-indicator-${title}`} title={title}/>))}
            </Steps>
          )}

          {activePage}

          <Form.Item wrapperCol={{span: 24}}>
            <Row justify='center' type='flex' gutter={[32, 0]}>
              <Col xs={8} sm={5} md={5}>
                {page === 0 && (
                  <Button onClick={onCancel} block ghost type='primary' htmlType='button'
                          disabled={isSubmitting}>
                    {t('common:cancel')}
                  </Button>
                )}
                {page > 0 && (
                  <Button onClick={() => previous(v)} block ghost type='primary' htmlType='button'
                          disabled={isSubmitting}>
                    {t('common:back')}
                  </Button>
                )}
              </Col>

              <Col xs={8} sm={5} md={5}>
                <Button onClick={() => save(v)} block ghost type='primary' htmlType='button'
                        disabled={isSubmitting}>
                  {t('common:save')}
                </Button>
              </Col>
              <Col xs={8} sm={5} md={5}>
                {!isLastPage && (
                  <Button type='primary' htmlType='submit' block
                          disabled={!isValid || isSubmitting}>
                    {t('common:next')}
                  </Button>
                )}
                {isLastPage && (<Button type='primary' htmlType='submit' block
                                        disabled={!isValid || isSubmitting}>
                    {t('register:submit application')}
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
          <Debug/>
        </Form>
      )}
    </Formik>
  );
}

// Static variable shared by all instances
Wizard.Page = ({children}) => children;
Wizard.Page.propTypes = {
  title: PropTypes.string,
  validate: PropTypes.func,
  validationSchema: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object])
};

Wizard.propTyps = {
  children: PropTypes.element,
  initialPage: PropTypes.number,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func
};
