import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFirebase } from 'react-redux-firebase';
import GlobalContainer from '../../components/container';

const Container = styled(GlobalContainer)`
  background-color: ${(props) => props.theme['$white']};
`;

export function ForgetPasswordContainer() {
  const { t } = useTranslation(['common', 'register', 'auth']);
  const firebase = useFirebase();
  const onClickBackToHome = async () => {
    await firebase.logout();
    window.location.href = 'http://tatacademy.com';
  };

  return (
    <Container>
      <Result
        status='error'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <Button type='primary' onClick={onClickBackToHome}>
            {t('register:back to home')}
          </Button>
        }
      />
    </Container>
  );
}
