import { Select } from 'antd';
import styled from 'styled-components';

const SelectComponent = styled(Select)`
  &.ant-select > .ant-select-selection { 
    :focus {
      color: ${props => props.theme['$input-color-focus']} !important;
      background-color: ${props => props.theme['$input-bg-focus']} !important;
      border-color: ${props => props.theme['$blue']};
    }
    
    &.d-none{
      display: none;
    }
  }
`;


export default SelectComponent;
