import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import Select from "app/components/select";
import { location } from "./location-database";
import Form from "../form";

import styled from "styled-components";

const Item = styled(Form.Item)`
  &.ant-form-item {
    > .ant-form-item-control-wrapper {
      > .ant-form-item-control {
        &.has-error {
          > .ant-form-item-children {
            > .ant-mentions {
              > textarea {
                border-color: ${(props) => props.theme["$red"]};
              }
            }
          }
        }
      }
    }
  }
`;

export function FormCascadeLocationInputComponent({
  onChange,
  onProvinceChange,
  onDistrictChange,
  onTambonChange,
  onZipcodeChange,
  firstLocationType,
  secondLocationType,
  setProvince,
  setDistrict,
  setTambon,
  setZipcode,
  province,
  district,
  tambon,
  zipcode,
}) {
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [tambonOptions, setTambonOptions] = useState([]);
  const [zipcodeOptions, setZipcodeOptions] = useState([]);

  useEffect(() => {
    // Map the province options
    setProvinceOptions([...new Set(location.map((item) => item.province))]);
    // Reset district, tambon, and zipcode when province changes
    // if (province) {
    // setDistrict(null);
    // setTambon(null);
    // setZipcode(null);
    // }
  }, [province, setDistrict, setTambon, setZipcode]);

  useEffect(() => {
    // Map the amphoe (district) options based on the selected province
    const provinceData = location.filter((item) => item.province === province);
    setDistrictOptions([...new Set(provinceData.map((item) => item.amphoe))]);
    // Reset tambon and zipcode when district changes
    // if (district) {
    // setTambon(null);
    // setZipcode(null);
    // }
  }, [province, district, setTambon, setZipcode]);

  useEffect(() => {
    // Map the tambon options based on the selected amphoe (district)
    const selectedAmphoeData = location.filter(
      (item) => item.amphoe === district
    );
    setTambonOptions([
      ...new Set(selectedAmphoeData.map((item) => item.tambon)),
    ]);
    // Reset zipcode when tambon changes
    // if (tambon) {
    // setZipcode(null);
    // }
  }, [district, tambon, setZipcode]);

  useEffect(() => {
    // Map the zipcode options based on the selected tambon
    const tambonData = location.filter((item) => item.tambon === tambon);
    setZipcodeOptions([...new Set(tambonData.map((item) => item.zipcode))]);
  }, [tambon]);

  const getLocationOptions = (locationType) => {
    switch (locationType) {
      case "tambon":
        return tambonOptions;
      case "district":
        return districtOptions;
      case "province":
        return provinceOptions;
      case "zipcode":
        return zipcodeOptions;
      default:
        return [];
    }
  };

  const onSelectChange = (type) => (v) => {
    switch (type) {
      case "province": {
        setProvince(v);
        return onProvinceChange(v);
      }
      case "district": {
        setDistrict(v);
        return onDistrictChange(v);
      }
      case "tambon": {
        setTambon(v);
        return onTambonChange(v);
      }
      case "zipcode": {
        setZipcode(v);
        return onZipcodeChange(v);
      }
      default:
        break;
    }
  };

  return (
    <Row justify="space-between" type="flex" gutter={[16, 0]}>
      {firstLocationType === "province" &&
        secondLocationType === "district" && (
          <>
            <Col xs={10}>
              <Item label="จังหวัด">
                <Select
                  style={{ width: "100%" }}
                  onChange={onSelectChange(firstLocationType)}
                  value={province}
                >
                  {getLocationOptions(firstLocationType).map((val) => (
                    <Select.Option
                      value={val}
                      key={`location-option-${firstLocationType}-${val}`}
                    >
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={10}>
              <Item label="อำเภอ">
                <Select
                  style={{ width: "100%" }}
                  onChange={onSelectChange(secondLocationType)}
                  value={district}
                >
                  {getLocationOptions(secondLocationType).map((val) => (
                    <Select.Option
                      value={val}
                      key={`location-option-${secondLocationType}-${val}`}
                    >
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </>
        )}

      {firstLocationType === "tambon" && secondLocationType === "zipcode" && (
        <>
          <Col xs={10}>
            <Item label="ตำบล">
              <Select
                style={{ width: "100%" }}
                onChange={onSelectChange(firstLocationType)}
                value={tambon}
              >
                {getLocationOptions(firstLocationType).map((val) => (
                  <Select.Option
                    value={val}
                    key={`location-option-${firstLocationType}-${val}`}
                  >
                    {val}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col xs={10}>
            <Item label="รหัสไปรษณีย์">
              <Select
                style={{ width: "100%" }}
                onChange={onSelectChange(secondLocationType)}
                value={zipcode}
              >
                {getLocationOptions(secondLocationType).map((val) => (
                  <Select.Option
                    value={val}
                    key={`location-option-${secondLocationType}-${val}`}
                  >
                    {val}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </>
      )}
    </Row>
  );
}
