import React, { useCallback, useEffect, useState } from "react";
// import {Alert, Button, Col, Row, Spin} from 'antd';
// import Icon from '@ant-design/icons';
import { Alert, Button, Col, Icon, Row, Spin } from "antd";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useRouter from "use-react-router";
import { useFormik } from "formik";
import { get } from "lodash";
import * as Yup from "yup";
import {
  isLoaded,
  isEmpty,
  useFirebase,
  useFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

import Container from "../../../components/container";
import Text from "../../../components/text";

import Input from "../../../components/input";
import Form from "../../../components/form";

const ProvidersContainer = styled(Container)`
  padding: 1.5rem 0;
  background-color: ${(props) => props.theme["$white"]};
`;

const HeaderCol = styled(Col)`
  &.ant-col {
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 2rem;
  }
`;

const MarginBottomCol = styled(Col)`
  &.ant-col {
    margin-bottom: 1rem;
  }
`;

const SocialButton = styled(Button)`
  &.ant-btn {
    > img + span,
    span + img {
      margin-left: 0.5rem;
    }

    > img,
    .anticon {
      position: absolute;
      left: 1rem;
      top: 0.7rem;
    }
  }
`;

const TextCenterCol = styled(Col)`
  &.ant-col {
    text-align: center;
  }
`;

// const MarginBottom2xAndTextRightCol = styled(Col)`
//   &.ant-col {
//     text-align: right;
//     margin-bottom: 2rem;
//   }
// `;

const MarginBottom2xAndTextCenterCol = styled(Col)`
  &.ant-col {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export function EmailSignUpComponent() {
  const { t } = useTranslation(["common", "auth"]);
  const [loading, setLoading] = useState(true);

  // const [user, initializing] = useAuthState(Firebase.auth());
  const auth = useSelector((state) => state.firebase.auth);
  const authError = useSelector((state) => state.firebase.authError);
  // const profile = useSelector(state => state.firebase.profile);

  const firebase = useFirebase();
  const firestore = useFirestore();

  const signInWithFacebook = () =>
    firebase.login({ provider: "facebook", type: "redirect" });
  const signInWithGoogle = () =>
    firebase.login({ provider: "google", type: "redirect" });

  const {
    history: { replace, push },
  } = useRouter();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("register:this field is invalid"))
      .required(t("register:this field can not be empty")),
    password: Yup.string().required(t("register:this field can not be empty")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "auth:passwords must match")
      .required(t("register:this field can not be empty")),
  });

  const signUp = async ({ email, password }, { setErrors }) => {
    try {
      setLoading(true);
      await firebase.createUser(
        { email, password },
        { email, username: email }
      );
    } catch (e) {
      setLoading(false);
      setErrors({ signUp: t("auth:sorry can not create user") });
    }
  };
  const { handleSubmit, handleChange, values, errors } = useFormik({
    onSubmit: signUp,
    initialValues: { email: "", password: "", passwordConfirmation: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
  });

  const getFormItem = ({
    label,
    id,
    tag: Tag = Input,
    tagProps,
    formErrors,
    formValues,
    onChange,
  }) => (
    <Form.Item
      label={label}
      validateStatus={!!get(formErrors, id, "") ? "error" : "success"}
      help={get(formErrors, id, "")}
    >
      <Tag
        name={id}
        id={id}
        onChange={onChange}
        defaultValue={get(formValues, id, "")}
        {...tagProps}
      />
    </Form.Item>
  );

  const EmailInput = getFormItem({
    label: t("register:email"),
    id: "email",
    tag: Input,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  });
  const PasswordInput = getFormItem({
    label: t("auth:password"),
    id: "password",
    tag: Input.Password,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  });
  const PasswordConfirmationInput = getFormItem({
    label: t("auth:confirm password"),
    id: "passwordConfirmation",
    tag: Input.Password,
    formErrors: errors,
    formValues: values,
    onChange: handleChange,
  });
  const rowProps = { type: "flex", justify: "center" };
  const colProps = { xs: 24, sm: 10, md: 10, lg: 8 };
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onClickToSignIn = () => {
    push("/auth/providers");
  };

  const getAuthRedirectResult = useCallback(async () => {
    const { user } = await firebase.auth().getRedirectResult();
    try {
      if (!!user) {
        const { displayName, uid, email, phoneNumber, providerId, photoURL } =
          user;
        await firestore
          .collection("users")
          .doc(`${user.uid}`)
          .set({ displayName, uid, email, phoneNumber, providerId, photoURL });
        replace("/registration");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [firebase, firestore, replace]);

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      replace("/registration");
    }
  }, [auth, replace]);

  useEffect(() => {
    getAuthRedirectResult();
  }, [getAuthRedirectResult]);

  return (
    <ProvidersContainer>
      <Spin spinning={loading}>
        <Row type="flex" justify="center">
          <HeaderCol>
            <Text level={3} color="darker">
              {t("common:signup")}
            </Text>
            <br />
            <Text level={5} color="darker">
              {t("common:ttt")}
            </Text>
          </HeaderCol>
        </Row>

        {!!authError && !!authError.message && (
          <Row {...rowProps}>
            <MarginBottomCol span={24}>
              <Alert message={authError.message} type="error" />
            </MarginBottomCol>
          </Row>
        )}

        <Row type="flex" justify="center">
          <MarginBottomCol {...colProps}>
            <SocialButton
              type="primary"
              block
              size="large"
              onClick={signInWithFacebook}
            >
              <Icon theme="filled" type="facebook" />
              {t("common:social facebook signup")}
            </SocialButton>
          </MarginBottomCol>
        </Row>
        <Row type="flex" justify="center">
          <MarginBottomCol {...colProps}>
            <SocialButton
              type="primary"
              ghost
              block
              size="large"
              onClick={signInWithGoogle}
            >
              <Icon type="google" />
              {t("common:social google signup")}
            </SocialButton>
          </MarginBottomCol>
        </Row>

        <Row type="flex" justify="center">
          <Col>
            <Text level={6} color="darker">
              {t("auth:or sign in with email and password")}
            </Text>
          </Col>
        </Row>
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <Row {...rowProps}>
            <Col {...colProps}>{EmailInput}</Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>{PasswordInput}</Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>{PasswordConfirmationInput}</Col>
          </Row>
          {/* <Row {...rowProps}>
            <MarginBottom2xAndTextRightCol {...colProps}>
              <Button type='link' htmlType='button'>
                {t('auth:forgot password')}
              </Button>
            </MarginBottom2xAndTextRightCol>
          </Row> */}

          <Row {...rowProps}>
            <MarginBottom2xAndTextCenterCol {...colProps}>
              <Button type="primary" htmlType="submit" size="large">
                {t("common:signup")}
              </Button>
            </MarginBottom2xAndTextCenterCol>
          </Row>

          <Row {...rowProps}>
            <TextCenterCol {...colProps}>
              <Button type="link" htmlType="button" onClick={onClickToSignIn}>
                {t("auth:click to signin")}
              </Button>
            </TextCenterCol>
          </Row>
        </Form>
      </Spin>
    </ProvidersContainer>
  );
}
