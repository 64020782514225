import {useFirebase} from 'react-redux-firebase';
import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {get} from 'lodash';

export function useRegistrationFileStorage() {
  const firebase = useFirebase();
  const authId = useSelector(s => get(s, 'firebase.auth.uid', null));

  const uploadAvatarFileRequest = useMemo(() => (file) => {
    const imageName = `${uuidv4()}`;
    const storagePath = `${authId}/personal/avatar`;
    const dbPath = `files/${storagePath}`;
    return firebase.uploadFile(storagePath,
      file,
      dbPath,
      {metadata: {contentType: file.type}, name: imageName});
  }, [firebase, authId]);

  const uploadAttachedFileRequest = useMemo(() => (file) => {
    console.log('uploadAttachedFileRequest');
    console.log(file);
    const name = `${uuidv4()}`;
    const storagePath = `${authId}/attached`;
    const dbPath = `files/${storagePath}`;
    return firebase.uploadFile(storagePath,
      file,
      dbPath,
      {metadata: {contentType: file.type}, name, displayName: file.name});
  }, [firebase, authId]);

  const getFileDownloadURL = useMemo(() => (fullPath) => firebase.storage()
  .ref()
  .child(fullPath)
  .getDownloadURL()
    , [firebase]);

  return {
    uploadAvatarFileRequest,
    uploadAttachedFileRequest,
    getFileDownloadURL
  };
}
