import React from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { BackTop, Layout as AntLayout } from "antd";
import { createFirestoreInstance } from "redux-firestore";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import firebase from "app/firebase";

import store from "./store";
import Registration from "./pages/registration";
import Result from "./pages/result";
import Auth from "./pages/auth";
import ForgetPassword from "./pages/forget-password";
import i18n from "./i18n";
import TATHeader from "./components/tat-header";
import TATFooter from "./components/tat-footer";
import GlobalStyle from "./styles/global-styles";
import theme from "./styles/theme";
import LoginModal from "./components/login-modal";

const Layout = styled(AntLayout)`
  &.ant-layout {
    min-height: 100%;
  }
`;

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true, // Get custom claims along with the profile
  // profileParamsToPopulate: [
  //   {child: 'role', root: 'roles'} // populates user's role with matching role object from roles
  // ]
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    process.env.REACT_APP_BASIC_AUTHEN_USERNAME &&
      process.env.REACT_APP_BASIC_AUTHEN_PASSWORD
      ? false
      : true
  );

  if (!isAuthenticated) {
    return <LoginModal onAuthenticate={() => setIsAuthenticated(true)} />;
  } else if (isAuthenticated) {
    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
              <Router onUpdate={() => window.scrollTo(0, 0)}>
                <GlobalStyle />
                <Layout>
                  <TATHeader />
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={() => <Redirect to="/auth" />}
                    />
                    <Route
                      exact
                      path="/registration"
                      component={Registration}
                    />
                    <Route path="/auth" component={Auth} />
                    <Route path="/result" component={Result} />
                    <Route path="/forget-password" component={ForgetPassword} />
                    <Redirect to="/result/page-not-found" />
                  </Switch>
                  <TATFooter />
                </Layout>
                <BackTop />
              </Router>
            </ThemeProvider>
          </I18nextProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    );
  } else {
    return null;
  }
}
