import React from 'react';
import {Col, Radio, Row} from 'antd';
import styled from 'styled-components';

const RadioGroup = styled(Radio.Group)`
  &.ant-radio-group{
    width: 100%;
  }
`;
export function FormRadioGroupComponent({id, options = [], ...restProps}) {
  return (
    <RadioGroup id={id} name={id} {...restProps}>
      <Row>
        {options.map(({value, label}) => (
          <Col key={value} xs={24} sm={8} md={8} lg={8}>
            <Radio value={value}>{label}</Radio>
          </Col>
        ))}
      </Row>
    </RadioGroup>
  );
}

