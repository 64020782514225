import { Form } from 'antd';
import styled from 'styled-components';

const FormComponent = styled(Form)`
  .ant-form-item {
    .ant-form-item-label > label{
      color: ${props => props.theme['$gray']};
    }
  }
  
  .ant-form-item:focus-within {
    .ant-form-item-label > label{
      color: ${props => props.theme['$blue']};
    }
  }
`;


export default FormComponent;
